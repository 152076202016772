import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GridItemHeader from './GridItemHeader';
import styles from './GridItem.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  selectElement,
  selectElements,
  selectMode,
  selectSelectedElements,
} from 'store/dashboard.slice';
import { DashboardElement, DashboardElementType, DashboardModeType } from 'types/dashboard.types';
import { ActiveCardType } from 'types/financials.types';
import { financialsSlice } from 'store/financials.slice';

interface Props {
  item: number;
  style?: React.CSSProperties;
  className?: string;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onTouchEnd?: (e: React.TouchEvent<HTMLDivElement>) => void;
  children?: React.ReactNode;
}

const KEYS_FOR_MULTIPLE_SELECTION = [ 'Control', 'Meta' ];

const getActiveCardType = (type: DashboardElementType): ActiveCardType => {
  switch (type) {
    case DashboardElementType.TABLE:
      return 'table';
    case DashboardElementType.CHART:
      return 'chart';
    case DashboardElementType.BIG_NUMBER:
      return 'big_number';
    default:
      return null;
  }
};

const isSelectableCard = (element: DashboardElement):element is DashboardElement & {
  type: DashboardElementType.TABLE | DashboardElementType.CHART | DashboardElementType.BIG_NUMBER;
} => {
  switch (element.type) {
    case DashboardElementType.TABLE:
    case DashboardElementType.CHART:
    case DashboardElementType.BIG_NUMBER:
      return true;
    default:
      return false;
  }
};

const GridItem = React.forwardRef(({
  style,
  onMouseDown,
  onMouseUp,
  onTouchEnd,
  children,
  item,
  className = '',
  ...rest
}: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const selectedElements = useAppSelector(selectSelectedElements);
  const dispatch = useAppDispatch();
  const [ multipleSelectionKey, setMultipleSelectionKey ] = useState<string>(null);
  const mode = useAppSelector(selectMode);
  const elements = useAppSelector(selectElements);
  const element = useMemo(() => {
    return elements.find((e) => e.id === item);
  }, [ item, elements ]);

  const isSelectable = isSelectableCard(element);
  const isActiveCard = useAppSelector(state => {
    const active = state.financials.active;
    if (isSelectable) {
      return active?.templateId === element.element.template &&
        active?.type === getActiveCardType(element.type);
    }
  });
  const active = useMemo(() => {
    return (
      selectedElements.some(el => el === item) &&
      mode === DashboardModeType.EDIT
    ) || (
      isActiveCard &&
      mode === DashboardModeType.VIEW
    );
  }, [ selectedElements, item, isActiveCard ]);

  const selectActiveCard = useCallback(() => {
    const cardType = getActiveCardType(element.type);
    if (isSelectable) {
      dispatch(financialsSlice.actions.setActive({
        templateId: element.element.template,
        type: cardType
      }));
    }
  }, [ element ]);

  const onSelect = useCallback(() => {
    dispatch(selectElement(item, !!multipleSelectionKey));
    selectActiveCard();
  }, [ item, multipleSelectionKey ]);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (KEYS_FOR_MULTIPLE_SELECTION.includes(e.key) && !multipleSelectionKey) {
        setMultipleSelectionKey(e.key);
      }
    };

    const onKeyUp = (e: KeyboardEvent) => {
      if (multipleSelectionKey === e.key) {
        setMultipleSelectionKey(null);
      }
    };

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [ multipleSelectionKey ]);

  return (
    <div
      style={ style }
      className={ `
        ${ styles.item }
        ${ active ? styles.active : '' }
        ${ styles[ mode ] }
        ${ className }
        ${ element.type }
        ` }
      ref={ ref }
      onMouseDown={ onMouseDown }
      onMouseUp={ onMouseUp }
      onTouchEnd={ onTouchEnd }
      onClick={ onSelect }
      { ...rest }
    >
      <div className={ styles.editFade } />
      <GridItemHeader item={ element } />
      { children }
    </div>
  );
});

GridItem.displayName = 'GridItem';
export default GridItem;