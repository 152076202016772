import React from 'react';

import type { LeftPanelType, PanelType } from 'types/app.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { clearLeftPanel } from 'store/financials.slice';
import PanelWrapper from 'components/panels/elements/wrappers/PanelWrapper';
import LeftPanelHeader from 'components/panels/leftPanelHeader/LeftPanelHeader';
import LayoutControl from '../layout/LayoutControl';
import { appSlice } from 'store/app.slice';

const ItemsLayoutPanel = () => {
  const dispatch = useAppDispatch();
  const panelType = useAppSelector(state => state.app.leftPanel);

  const onClose = () => {
    dispatch(clearLeftPanel());
  };

  const onChange = (key: PanelType) => {
    dispatch(appSlice.actions.setLeftPanel(key));
  };

  return <PanelWrapper type='left' isActive={ panelType === 'layout' } >
    <LeftPanelHeader
      availableTabs={ [ 'layout' ] }
      onChange={ onChange }
      onClose={ onClose }
      activeKey={ panelType as LeftPanelType }
    />

    {
      panelType === 'layout' && (<LayoutControl />) 
    }
  </PanelWrapper>;
}; 

export default ItemsLayoutPanel;
