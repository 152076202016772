import { PaginatedResponse } from 'types/app.types';
import {
  BudgetItemAiCompletion,
  PlanSettingRequest,
  PlanSettingsResponse
} from 'types/planning.types';
import { UUID } from 'types/templates.types';
import { endpoints } from 'utils/config.utils';
import http from 'utils/http.utils';
import {
  Budget,
  BudgetClearDimensionsRequest,
  BudgetItem,
  BudgetItemParams,
  BudgetItemType,
  BudgetItemUpdateRequest,
} from '../types/budget.types';
import { TransactionLineRequestParams } from './statutory.service';

const getPlanning = () =>
  http.get<PaginatedResponse<PlanSettingsResponse>>(endpoints.planning.settings);

const setPlanning = (data: PlanSettingRequest) =>
  http.post<PlanSettingsResponse>(endpoints.planning.settings, data);

const restoreDefaultPlanning = () =>
  http.post<PlanSettingsResponse>(endpoints.planning.restoreDefaults);

const getBudgets = () =>
  http.get<PaginatedResponse<Budget>>(endpoints.planning.budgets);

const getBudgetItemTypes = () =>
  http.get<PaginatedResponse<BudgetItemType>>(endpoints.planning.budgetItemTypes);

const getBudgetItems = (params: BudgetItemParams) =>
  http.get<PaginatedResponse<BudgetItem>>(endpoints.planning.budgetItems.index, { params });

const getTableBudgetItems = (params?: TransactionLineRequestParams) =>
  http.get<BudgetItem[]>(endpoints.planning.budgetItems.table, { params });

const bulkDeleteBudgetItem = (ids: number[]) =>
  http.post(endpoints.planning.budgetItems.bulkDelete, ids);

const batchUpdateBudgetItems = (data: BudgetItemUpdateRequest[]) =>
  http.post<BudgetItem[]>(endpoints.planning.budgetItems.batchUpdate, data);

const clearDimensions = (data: BudgetClearDimensionsRequest) =>
  http.post<void>(endpoints.planning.budgetItems.clearDimensions, data);

const generatePayments = () =>
  http.post(endpoints.planning.generatePayments);

const promptAiCompletion = (prompt: string, sessionUuid: UUID) =>
  http.post<BudgetItemAiCompletion>(
    endpoints.planning.budgetItems.aiCompletion,
    { prompt, sessionUuid }
  );

const refreshPlan = () =>
  http.post(endpoints.planning.refreshPlan);

export const planningService = {
  getPlanning,
  setPlanning,
  restoreDefaultPlanning,
  getBudgets,
  getBudgetItemTypes,
  getBudgetItems,
  getTableBudgetItems,
  bulkDeleteBudgetItem,
  batchUpdateBudgetItems,
  clearDimensions,
  generatePayments,
  promptAiCompletion,
  refreshPlan,
};
