import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import styles from './ExternalClose.module.scss';

interface Props {
  onClose: () => void;
}
const ExternalClose = ({ onClose }: Props) => {
  return <GhostIconButton
    className={ styles.close }
    aria-label='close'
    onClick={ onClose }
    withSeparator
  >
    <CloseIcon />
  </GhostIconButton>;
};

export default ExternalClose;
