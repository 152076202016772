import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../../../elements/modal/Modal';
import styles from './DetailedViewModal.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DetailedViewType } from 'types/financials.types';
import { ReactComponent as OpenAsCardIcon } from 'assets/icons/open-as-card.svg';
import { clearDetailedViewSettings, updateDetailedViewSettings } from 'store/financials.slice';
import DetailedViewTable from '../table/DetailedViewTable';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { useFinancialTable } from 'context/FinancialTableContext';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { scrollViewItemIntoView } from 'utils/financials.utils';

interface Props {
  templateId: number;
  lastFocusedCell?: {
    row: number;
    col: string;
  };
  financialGridRef?: React.RefObject<AgGridReact>;
  onTableRefresh?: () => void;
}

const DetailedViewModal = (
  {
    templateId,
    financialGridRef,
    lastFocusedCell,
    onTableRefresh
  }: Props) => {
  const [ t ] = useTranslation('common');
  const {
    state: {
      customSettings: { detailedViewSettings: { floatingPanelDisabled } }
    }
  } = useFinancialTable();
  const dispatch = useAppDispatch();
  const detailedView = useAppSelector(state => state.financials.detailedView);

  const [ openedTimestamp, setOpenedTimestamp ] = useState(() => Date.now());
  const labelingTimestamp = useAppSelector(
    state => state.financials.tables[ templateId ]?.labelingTimestamp);

  const onModalClose = useCallback(() => {
    dispatch(clearDetailedViewSettings());
    if (labelingTimestamp > openedTimestamp) {
      onTableRefresh();
    }

    if (lastFocusedCell) {
      financialGridRef.current.api.setFocusedCell(lastFocusedCell.row, lastFocusedCell.col);
    }
  }, [ lastFocusedCell, labelingTimestamp, openedTimestamp, onTableRefresh ]);

  const isVisible = useMemo(() => {
    return detailedView.type === DetailedViewType.MODAL && templateId === detailedView.templateId;
  }, [ detailedView, templateId ]);

  useEffect(() => {
    if (isVisible) {
      setOpenedTimestamp(Date.now());
    }
  }, [ isVisible ]);

  const openDetailedViewAsCard = useCallback(() => {
    dispatch(updateDetailedViewSettings({
      type: DetailedViewType.FLOATING_PANEL,
    }));
    scrollViewItemIntoView('table', templateId);
  }, [ templateId ]);

  const headerTools = useMemo(() => ([ {
    key: 'open-card',
    button: <GhostIconButton
      tooltip={ t('redirect.show-as-panel') }
      disabled={ floatingPanelDisabled }
      onClick={ openDetailedViewAsCard }
    >
      <OpenAsCardIcon/>
    </GhostIconButton>,
    buttonPosition: 2,
  } ]), [ openDetailedViewAsCard ]);

  if (!isVisible) {
    return null;
  }

  return <Modal
    isVisible={ isVisible }
    onConfirm={ null }
    confirmDisabled={ true }
    onClose={ onModalClose }
    disableButtons={ true }
    closable={ false }
    keyboard={ false }
    maskClosable={ false }
    destroyOnClose={ true }
    focusTriggerAfterClose={ false }
    forceRender={ true }
    className={ styles.modal }
  >
    <DetailedViewTable
      onClose={ onModalClose }
      headerTools={ headerTools }
      isInModal
    />
  </Modal>;
};
export default DetailedViewModal;
