import { CadenceColumnsSettings, FinancialsRowData, RowType } from './financials.types';
import { DisplayName } from 'types/app.types';
import { Dimension, DimensionItem, FilterList } from './filterTable.types';
import { Cadence } from './form.types';
import { CounterpartyType } from './revenueRecognition.types';

export enum TemplateActionType {
  title = RowType.TITLE,
  total = RowType.TOTAL,
  subtotal = RowType.SUBTOTAL,
  formula = RowType.FORMULA,
  group = RowType.GROUP,
  spacer = RowType.SPACER,
  half_spacer = RowType.HALF_SPACER,
}

export enum TemplateActionImmutableType {
  spacer = RowType.SPACER
}

export enum BreakdownType {
  DIMENSION = 'dimension',
  COUNTERPARTY = 'counterparty',
}

export type TreeTags = {
  'all': TemplateNode[];
  'profit-and-loss': (TemplateNode & { type: RowType.FINANCIALS })[];
  'balance-sheet': (TemplateNode & { type: RowType.FINANCIALS })[];
  dimensions: TemplateNodeType<RowType.BREAKDOWN>[];
  formulas: TemplateNode[];
  layout: TemplateNode[];
};

export enum StatutoryRowCategory {
  ASSET = 'asset',
  PROFIT_AND_LOSS = 'profit_and_loss',
  EQUITY = 'equity',
  LIABILITY = 'liability',
}

export type FormulaElementType =
  | FormulaItemType.STATUTORY
  | FormulaItemType.OPERATOR
  | FormulaItemType.DIMENSION_ITEM
  | FormulaItemType.UNASSIGNED
  | FormulaItemType.FORMULA
  | FormulaItemType.CONSTANT
  | FormulaItemType.TEMPLATE; // Should be deleted when fully migrated to isolated formulas

export type FormulaFormatting = 'NOMINAL' | 'PERCENTAGE';
export type FormulaDecimals = 0 | 1 | 2;

export type CustomFormulaFormat = {
  formatting: FormulaFormatting;
  decimals: FormulaDecimals;
};

export enum FormulaPeriod {
  PREVIOUS_PERIOD = 'previous_period',
  PREVIOUS_YEAR = 'previous_year',
}

export type CustomFormula = {
  type: FormulaElementType;
  value?: string;
  offset?: FormulaPeriod;
  templateNode?: TemplateNodeId;
  statutoryRow?: TemplateNodeId;
  dimensionItem?: TemplateNodeId;
  dimension?: TemplateNodeId;
  nestedFormula?: TemplateNodeId;
  rollingAverage?: number;
};

type TitleNodeRowData = {
  name: string;
};

export type FormulaNodeRowData = {
  id: number;
  formulaElements: CustomFormula[];
  formatting: FormulaFormatting;
  decimals: FormulaDecimals;
  calculateDynamicTotals: boolean;
  calculateRowTotals: boolean;
  reverseChangesFormatting: boolean;
  name: string;
  formulaType?: 'total' | 'subtotal';
};

export type BreakdownGroupRowData = {
  name: DisplayName;
  breakdownType: BreakdownType;
};

export type CounterpartyTypeRowData = {
  name: DisplayName;
  counterpartyType: CounterpartyType;
};

type RowNodeType =
  | { type: RowType.FINANCIALS; rowData: FinancialsRowData }
  | { type: RowType.DIMENSION_ITEM; rowData: DimensionItem }
  | { type: RowType.BREAKDOWN; rowData: Dimension }
  | { type: RowType.BREAKDOWN_GROUP; rowData: BreakdownGroupRowData }
  | { type: RowType.COUNTERPARTY_TYPE; rowData: CounterpartyTypeRowData }
  | { type: RowType.TITLE; rowData: TitleNodeRowData }
  | { type: RowType.FINANCIAL_TITLE; rowData: TitleNodeRowData }
  | { type: RowType.TOTAL; rowData: TitleNodeRowData }
  | { type: RowType.SUBTOTAL; rowData: TitleNodeRowData }
  | { type: RowType.FORMULA; rowData: FormulaNodeRowData }
  | { type: RowType.GROUP; rowData: TitleNodeRowData }
  | { type: RowType.SPACER; rowData: TitleNodeRowData }
  | { type: RowType.HALF_SPACER; rowData: TitleNodeRowData }
  | { type: RowType.SUM_UP; rowData: TitleNodeRowData }
  | { type: RowType.UNASSIGNED; rowData: Dimension };

export const isBreakdownNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.BREAKDOWN } => {
    return node?.type === RowType.BREAKDOWN || node?.type === RowType.COUNTERPARTY_TYPE;
  };

export const isDimensionItemNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.DIMENSION_ITEM } => {
    return node?.type === RowType.DIMENSION_ITEM;
  };

export const isUnassignedDimensionItemNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.DIMENSION_ITEM } => {
    return node?.type === RowType.DIMENSION_ITEM && node?.rowData?.id == null;
  };

export const isUnassignedNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.UNASSIGNED } => {
    return node?.type === RowType.UNASSIGNED;
  };

export const isFinancialsNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.FINANCIALS } => {
    return node?.type === RowType.FINANCIALS;
  };

export const isFormulaNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.FORMULA } => {
    return node?.type === RowType.FORMULA;
  };

export const isSubtotalNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.SUBTOTAL } => {
    return node?.type === RowType.SUBTOTAL || (
      node?.type === RowType.FORMULA && node?.rowData?.formulaType === 'subtotal'
    );
  };

export const isTotalNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.TOTAL } => {
    return node?.type === RowType.TOTAL || (
      node?.type === RowType.FORMULA && node?.rowData?.formulaType === 'total'
    );
  };

export const isCounterpartyTypeNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.COUNTERPARTY_TYPE } => {
    return node?.type === RowType.COUNTERPARTY_TYPE;
  };

export const isGroupTypeNode =
  (node: TemplateNode): node is TemplateNode & { type: RowType.GROUP } => {
    return node?.type === RowType.GROUP;
  };

type UUIDBrand = {
  readonly UUID: unique symbol;
};

export type UUID = string & UUIDBrand;
export type TemplateNodeId = string | number;

export type TemplateNode = {
  id: TemplateNodeId;
  uuid: UUID;
  childrenNodes?: TemplateNode[];
  children: TemplateNodeId[];
  parent?: TemplateNodeId;
} & RowNodeType;

export type TemplateNodeType<TRowType extends RowType> = TemplateNode & { type: TRowType };

export type TemplateNodeMapping<TRowType extends RowType = undefined> =
  TRowType extends undefined ?
    { [ id: number | string ]: TemplateNode } :
    { [ id: number | string ]: TemplateNodeType<TRowType> };

export type Template = {
  id?: number;
  title: string;
  subtitle?: string;
  roots: TemplateNodeId[];
  nodes: TemplateNodeMapping;
  type?: TemplateType;
};

export type TemplateRequest = {
  id?: number;
  title: string;
  subtitle?: string;
  roots: TemplateNodeId[];
  nodes: TemplateNode[];
};

export interface TemplateResponse extends TemplateRequest {
  id: number;
}

export interface FavoriteTemplate {
  template: number;
  favorite: boolean;
}

export type TemplatesListResponse = {
  results: UserTemplateShort[];
};

export enum TemplateType {
  USER = 'USER',
  SYSTEM = 'SYSTEM',
  SYSTEM_DEFAULT = 'SYSTEM_DEFAULT',
  INPUTS = 'inputs',
}

export type UserTemplateShort = {
  id: number;
  title: string;
  recordTitle: string | null;
  subtitle: string;
  type: TemplateType;
  favorite: boolean;
};

export type ReportData = {
  [type in ReportType]: ReportDataValues;
};

export enum ReportType {
  ACTUAL = 'actual',
  PLAN = 'plan'
}

export interface ReportDataValues {
  [ key: string ]: number | string | ReportDataValues;

  total?: string | number;
}

export type ReportNode = TemplateNode & {
  data: ReportData;
  hasTransactions: boolean;
};

export type UserReport = Omit<Template, 'nodes'> & {
  nodes: ReportNode[];
  columnSettings: CadenceColumnsSettings;
  filters: FilterList;
};

export enum TemplateSections {
  ALL = 'all',
  DIMENSIONS = 'dimensions',
  FORMULAS = 'formulas',
  FINANCIALS = 'profit-and-loss',
  BALANCE_SHEET = 'balance-sheet',
  LAYOUT = 'layout'
}

export enum FormulaItemType {
  OPERATOR = 'OPERATOR',
  STATUTORY = 'STATUTORY',
  CONSTANT = 'CONSTANT',
  DIMENSION_ITEM = 'DIMENSION_ITEM',
  UNASSIGNED = 'UNASSIGNED',
  FORMULA = 'FORMULA',
  //Additional types for old formulas or validation
  LOGICAL_OPERATOR = 'LOGICAL_OPERATOR',
  TEMPLATE = 'TEMPLATE',
  BRACKET = 'BRACKET',
}

export type FormulaItem = {
  id?: number | string;
  value: string;
  type: FormulaItemType;
  prev: FormulaItemType | null;
  next: FormulaItemType | null;
  key: string;
  bold?: boolean;
  offset?: FormulaPeriod;
  rollingAverage?: number;
};

export type DimensionSplitParams = {
  dimensionItem: number;
  percentage: number;
};

export interface TransactionLinesBulkEdit {
  filters?: {
    transactionLines?: number[];
    accountId?: number;
    counterpartyId?: number;
    startDate?: string;
    endDate?: string;
    cadence?: Cadence;
    templateRowId?: number;
    productId?: number;
    dimensionItemIds?: number[];
    contractId?: number;
    unassignedDimensionIds?: number[];
  };
  dimensionSplit: DimensionSplitParams[];
  unassignDimensionIds: number[];
}

export type FinancialTagsExpanded<T> = {
  roots: (number | string)[];
  nodes: T;
  expandedNodeId: number | string;
};
