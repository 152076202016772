import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import Chat from 'components/collaboration/chat/Chat';
import { clearRightPanel, selectRightPanel } from 'store/financials.slice';
import PanelWrapper from 'components/panels/elements/wrappers/PanelWrapper';
import ResizableContainer from 'components/elements/resizableContainer/ResizableContainer';
import styles from './ChatPanel.module.scss';

const ChatPanel = () => {
  const dispatch = useAppDispatch();
  const panel = useAppSelector(selectRightPanel);
  const onClose = () => {
    dispatch(clearRightPanel());
  };

  return <PanelWrapper 
    type='right'
    isActive={ panel != null }
  >
    <ResizableContainer
      dimensionToResize='width'
      minSize={ 280 }
      maxSize={ 700 }
      linePlacement='left'
      defaultSize={ 350 }
      className={ styles.asidePanel }
      resizeLineClassName={ styles.resizeLine }
    >
      <Chat onClose={ onClose } />
    </ResizableContainer>
  </PanelWrapper>;
};

export default ChatPanel;
