import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Integration } from 'types/settings.types';
import XeroLogo from 'assets/logos/xero.png';
import Button from 'components/elements/button/Button';
import styles from '../Integrations.module.scss';
import IntegrationItem from 'components/settings/integrations/item/IntegrationItem';
import { integrateXero } from '../../../../services/integration.service';
import {
  notifyError,
  notifySuccess,
  notifyUnexpectedError
} from '../../../../utils/notifications.utils';
import { fetchAndSetIntegrations } from '../../../../store/app.slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { getUUID } from '../../../../utils/templates.utils';

interface Props {
  integration: Integration;
  disabled: boolean;
}

const XERO_STATE_KEY = 'xeroState';

const XeroIntegration = ({ integration, disabled }: Props) => {
  const [ t ] = useTranslation('settings');
  const [ params ] = useSearchParams();
  const canIntegrate = !integration && !disabled;
  const dispatch = useAppDispatch();
  const organization = useAppSelector(state => state.auth.organization);

  useEffect(() => {
    integrate();
  }, []);

  const clearParams = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  };

  const integrate = async () => {
    const xeroState = localStorage.getItem(XERO_STATE_KEY);
    if (params.get('state') == xeroState) {
      const code = params.get('code');
      if (code) {
        try {
          await integrateXero(code);
          await dispatch(fetchAndSetIntegrations(organization.id));
          clearParams();
          notifySuccess(
            t('notifications.message'),
            t('notifications.description', { system: 'Procountor' })
          );
        } catch (e) {
          if (e.response.data.detail) {
            notifyError(e.response.data.detail);
          } else {
            notifyUnexpectedError(e);
          }
        }
      }
    }
  };

  const onClick = () => {
    // eslint-disable-next-line no-undef
    const clientId = process.env.REACT_APP_XERO_CLIENT_ID;
    // eslint-disable-next-line no-undef
    const redirectUri = process.env.REACT_APP_XERO_REDIRECT_URI;
    // eslint-disable-next-line no-undef
    const baseUrl = process.env.REACT_APP_XERO_URL;

    const scopesList = [
      'offline_access',
      'accounting.transactions.read',
      'accounting.settings.read',
      'accounting.journals.read',
      'accounting.contacts.read',
      'accounting.attachments.read',
      'payroll.employees.read',
      'payroll.payruns.read',
      'payroll.payslip.read',
    ];
    const scopes = scopesList.join(' ');
    const state = getUUID();
    localStorage.setItem(XERO_STATE_KEY, state);
    const searchParams = new URLSearchParams({
      'response_type': 'code',
      'client_id': clientId,
      'redirect_uri': redirectUri,
      'scope': scopes,
      'state': state,
    });
    const url = `${ baseUrl }?${ searchParams.toString() }`;
    window.open(url);
  };

  return <IntegrationItem
    logo={ XeroLogo }
    integration={ integration }
    disabled={ disabled }
    label='XERO'>
    {
      canIntegrate ?
        <Button
          name={ t('integrate') }
          className={ styles.button }
          target='_blank'
          onClick={ onClick }
        /> : null
    }
  </IntegrationItem>;
};

export default XeroIntegration;
