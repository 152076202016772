import { TemplateNode } from 'types/templates.types';

const SECOND_ROW_HEIGHT = 90;

export const maximumTagsExpanded = (children: HTMLCollection) => {
  if (!children) return;
  const childrenArray = Array.prototype.slice.call(children);
  let amountOfTags = 0;

  childrenArray.forEach((child) => {
    if (child.offsetTop < SECOND_ROW_HEIGHT) {
      amountOfTags++;
    }
  });

  return amountOfTags;
};

export const maximumGroupTagLevel = (root: TemplateNode, maxLevel = 1) => {
  let level = maxLevel;

  root.childrenNodes?.forEach((child) => {
    const childLevel = maximumGroupTagLevel(child, maxLevel + 1);
    if (childLevel > level) {
      level = childLevel;
    }
  });

  return level;
};
