import { useMemo, useState } from 'react';
import { IRowNode } from 'ag-grid-community';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { CommandBarUtil } from '../../../commandBar/types/commandBar.types';
import useCommandBarEffect from '../../../commandBar/hooks/useCommandBarEffect';
import { defaults } from '../../../commandBar/context/CommandBarContext';
import { useTranslation } from 'react-i18next';

const saveUtil = defaults.find(u => u.key === 'save');

const useDelayedLabeling = ({ onRefresh, templateId }) => {
  const [ labeledNodes, setLabeledNodes ] = useState<IRowNode[]>([]);
  const isRefreshDelayed = useAppSelector(state => state.labeling.settings.delayRefresh);
  const [ t ] = useTranslation('financials');

  const addLabeledNodes = (nodes: IRowNode[]) => {
    setLabeledNodes((prev) => [ ...prev, ...nodes ]);
  };

  const isTableActive = useAppSelector(state => {
    const active = state.financials.active;
    if (!active) {
      return false;
    }
    return active.templateId === templateId && active.type === 'table';
  });

  const refreshButton: CommandBarUtil = useMemo(() => {
    const canUpdate = labeledNodes.length > 0;

    if (canUpdate) {
      return {
        ...saveUtil,
        hidden: false,
        disabled: false,
        name: t('command-bar.update'),
        active: canUpdate,
        type: 'button-text',
        buttonType: 'primary',
        tooltip: t('command-bar.update-tooltip'),
        onClick: async () => onRefresh()
      };
    } else {
      return {
        ...saveUtil,
        disabled: false,
        hidden: false,
        name: t('command-bar.refresh'),
        active: true,
        type: 'button-text',
        buttonType: 'default',
        tooltip: t('command-bar.refresh-tooltip'),
        onClick: () => onRefresh()
      };
    }

  }, [ labeledNodes ]);

  const utilsToUpdate: CommandBarUtil[] = useMemo(() => {
    if (!isTableActive) {
      return [];
    }
    return [ refreshButton ];
  }, [ isTableActive, refreshButton ]);

  useCommandBarEffect({
    utils: utilsToUpdate,
    hidden: false,
    clearOnUnmount: false
  });

  return {
    labeledNodes,
    addLabeledNodes,
    setLabeledNodes,
    isRefreshDelayed
  };
};

export default useDelayedLabeling;
