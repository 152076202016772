import React from 'react';
import styles from './HeaderBar.module.scss';
import SearchBar from 'components/elements/searchBar/SearchBar';

const HeaderBar = () => {
  return (
    <div className={ styles.headerBar }>
      <SearchBar
        className={ styles.searchBar }
        showResults={ false }
        showSeparator={ false }
      />
    </div>
  );
};

export default HeaderBar;
