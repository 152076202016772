import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/short-arrow.svg';

import styles from './DateRangeSignifier.module.scss';
import { Cadence } from 'types/form.types';
import { DATE_FORMATS } from '../../../utils/date.utils';

type Props = {
  startDate: number;
  endDate: number;
  cadence: Cadence;
  className?: string;
};

const DateRangeSignifier = ({ startDate, endDate, cadence, className }: Props) => {
  const unixStartDate = useMemo(() => dayjs.unix(startDate), [ startDate ]);
  const unixEndDate = useMemo(() => dayjs.unix(endDate), [ endDate ]);
  const format = useMemo(() => {
    if (cadence === Cadence.week) {
      return DATE_FORMATS[ 0 ];
    }
    return 'MMM YYYY';
  }, [ unixStartDate, unixEndDate, cadence ]);

  return (
    <div className={ `${ styles.dateRangeSignifier } ${ className ?? '' }` }>
      { dayjs(unixStartDate).format(format) }
      <ArrowIcon />
      { dayjs(unixEndDate).format(format) }
    </div>
  );
};

export default DateRangeSignifier;
