import React from 'react';
import styles from
  'components/financials/panels/planDriverPanel/incrementalButton/IncrementalButton.module.scss';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';

type Props = {
  onClick: () => void;
  isIncremental: boolean;
  className?: string;
};

const IncrementalButton = ({ onClick, isIncremental, className='' }: Props) => {
  return (
    <button
      type='button'
      onClick={ onClick }
      className={ `${ className } ${ styles.incrementalButton }` }>
      { isIncremental ? <PlusIcon /> : <MinusIcon /> }
    </button>
  );
};

export default IncrementalButton;