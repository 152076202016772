import { cloneDeep } from 'lodash';
import { Template, TemplateNode } from 'types/templates.types';
import { insertAfter } from 'utils/array.utils';
import { PlacementNode } from './TemplatePlacementService';

export const TemplateReplaceService = (
  node: TemplateNode,
  template: Template,
  placement: PlacementNode,
) => {
  const replaceNode = () => {
    if (placement.replacingDimension) {
      return replaceDimension();
    }
    if (placement.moveChildren) {
      return moveChildren();
    }
    const updatedTemplate = cloneDeep(template);
    if (!node.parent) {
      updatedTemplate.roots = updatedTemplate.roots.filter((id) => id !== node.id);
    } else {
      updatedTemplate.nodes[ node.parent ].children =
        updatedTemplate.nodes[ node.parent ].children.filter((id) => id !== node.id);
    }
    const { parent, nodeBefore, isRoot, isTop } = placement;
    if (isRoot) {
      if (isTop) {
        updatedTemplate.roots = [ node.id, ...updatedTemplate.roots ];
      } else {
        updatedTemplate.roots = insertAfter(updatedTemplate.roots, node.id, nodeBefore);
      }
    } else {
      if (isTop) {
        updatedTemplate.nodes[ parent ].children =
          [ node.id, ...updatedTemplate.nodes[ parent ].children ];
      } else {
        updatedTemplate.nodes[ parent ].children = insertAfter(
          updatedTemplate.nodes[ parent ].children,
          node?.id,
          nodeBefore,
        );
      }
    }
    updatedTemplate.nodes[ node.id ].parent = parent;

    return {
      updatedTemplate,
      node,
    };
  };

  const moveChildren = () => {
    const { parent } = placement;
    const updatedTemplate = cloneDeep(template);
    const oldParent = node.parent;

    if (!oldParent) {
      updatedTemplate.roots = updatedTemplate.roots.filter((id) => id !== node.id);
    } else {
      updatedTemplate.nodes[ oldParent ].children =
        updatedTemplate.nodes[ oldParent ].children.filter((id) => id !== node.id);
    }
    const parentChildren = updatedTemplate.nodes[ parent ].children;
    updatedTemplate.nodes[ parent ].children = [ node.id ];
    updatedTemplate.nodes[ node.id ].children = [ ...parentChildren, ...node.children ];
    updatedTemplate.nodes[ node.id ].parent = parent;
    return {
      updatedTemplate,
      node,
    };
  };

  const replaceDimension = () => {
    const updatedTemplate = cloneDeep(template);
    const { parent, nodeBefore, isRoot, isTop } = placement;
    const newNode = updatedTemplate.nodes[ node.id ];
    const newNodeParent = updatedTemplate.nodes[ newNode.parent ];
    const newNodeChildren = newNode.children;

    if (newNodeParent) {
      const indexOfNewNodeInOldParent = newNodeParent.children.indexOf(node.id);
      const newNodeParentChildren = newNodeParent.children.filter((child) => child !== node.id);
      newNodeParentChildren.splice(indexOfNewNodeInOldParent, 0, ...newNodeChildren);
      updatedTemplate.nodes[ newNodeParent.id ].children = newNodeParentChildren;
    } else {
      const indexOfNewNodeInOldRoots = updatedTemplate.roots.indexOf(node.id);
      const newRoots = updatedTemplate.roots.filter((child) => child !== node.id);
      newRoots.splice(indexOfNewNodeInOldRoots, 0, ...newNodeChildren);
      updatedTemplate.roots = newRoots;
    }
    newNodeChildren.forEach((child) => {
      updatedTemplate.nodes[ child ].parent = newNodeParent?.id;
    });
    if (isTop) {
      updatedTemplate.roots = [ node.id, ...updatedTemplate.roots ];
      updatedTemplate.nodes[ node.id ].parent = null;
      updatedTemplate.nodes[ node.id ].children = [];
    } else if (isRoot) {
      updatedTemplate.roots = insertAfter(updatedTemplate.roots, node.id, nodeBefore);
      updatedTemplate.nodes[ node.id ].parent = null;
      updatedTemplate.nodes[ node.id ].children = [];
    } else {
      updatedTemplate.nodes[ node.id ].parent = parent;
      updatedTemplate.nodes[ node.id ].children = updatedTemplate.nodes[ parent ].children;
      updatedTemplate.nodes[ parent ].children.forEach((child) => {
        updatedTemplate.nodes[ child ].parent = node.id;
      });
      updatedTemplate.nodes[ parent ].children = [ node.id ];
    }

    return {
      updatedTemplate,
      node,
    };
  };

  return {
    replaceNode,
  };
};
