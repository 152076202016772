import React, { useMemo } from 'react';
import { IRowNode } from 'ag-grid-community';
import CounterpartyLogo from 'components/elements/counterpartyLogo/CounterpartyLogo';

import styles from '../styles/FinancialTable.module.scss';
import { Counterparty } from '../../../../types/statutory.types';

type Props = {
  row: IRowNode;
};

const RowCounterpartyLogo = ({ row }: Props) => {
  const picture = useMemo(() => row.data?.rowData?.counterparty?.pictureUrl, [ row ]);

  const counterparty: Counterparty = row.data?.rowData?.counterparty;

  return (
    picture && picture !== '' ?
      <CounterpartyLogo url={ picture }
        size={ 22 }
        className={ styles.counterpartyLogo }
        showDefaultLogo={ false }
      /> : <CounterpartyLogo url={ picture }
        size={ 22 }
        className={ styles.counterpartyLogo }
        showDefaultLogo={ true }
        name={ counterparty?.name }
        type={ counterparty?.type }
      />
  );
};

export default RowCounterpartyLogo;
