import React, { useMemo } from 'react';
import { InteractionMessage, Message, MessageActionKey } from 'types/chat.types';
import styles from './MessageBubble.module.scss';
import Button from 'components/elements/button/Button';
import organizationsService from 'services/organizations.service';
import { useAppDispatch } from 'store/hooks/hooks';
import { chatSlice } from 'store/chat.slice';
import { clearRightPanel } from 'store/financials.slice';
import clsx from 'clsx';

interface Props {
  message: Message | InteractionMessage;
  children?: React.ReactNode;
}

const MessageBubble = ({ message, children }: Props) => {
  const actions = (message as InteractionMessage).actions;
  const dispatch = useAppDispatch();

  const actionsPalette = useMemo((): { [key in MessageActionKey]: () => void } => ({
    consent: async () => {
      await organizationsService.chatbotConsent(true);
      dispatch(chatSlice.actions.setConsent(true));
    },
    declineConsent: () => {
      dispatch(clearRightPanel());
    }
  }), []);

  return <div className={ clsx(styles.bubble, styles[ message.source ], styles[ message.type ]) }>
    { children ?? message.text }
    { actions && <div className={ styles.actions }>
      { actions?.map(
        (action) =>
          <Button
            type={ action.type }
            key={ action.id }
            onClick={ actionsPalette[ action.action ] }
          >
            { action.text }
          </Button>) }
    </div> }
  </div>;
};

export default MessageBubble;
