import { ContractWizard } from './../types/contracts.types';
/* eslint-disable camelcase */
import { Customer } from 'types/revenueRecognition.types';
import { Contract, ContractType } from './../types/contracts.types';
import { PaginatedResponse } from 'types/app.types';
import http from 'utils/http.utils';
import { endpoints } from 'utils/config.utils';
import { Cadence } from '../types/form.types';
import { Period } from '../types/financials.types';
import { UserReport } from '../types/templates.types';
import dayjs from 'dayjs';
import { LimitOffsetParams } from './params/pagination';

interface GetCustomersParams {
  limit?: number;
  offset?: number;
  start_date?: string;
  end_date?: string;
  cadence?: Cadence;
}

interface GetContractsParams extends LimitOffsetParams {
  search?: string;
  ordering?: string;
  customer?: number;
  status?: ContractType;
}

const getContracts = (params?: GetContractsParams) =>
  http.get<PaginatedResponse<Contract>>(endpoints.contracts.index, { params });

const addContract = (contract: Partial<Contract>) =>
  http.post<Contract>(endpoints.contracts.index, contract);

const deleteContract = (id: number) =>
  http.delete(endpoints.contracts.detail.replace('{id}', id.toString()));

const updateContract = (id: number, contract: Partial<Contract>) =>
  http.patch<Contract>(endpoints.contracts.detail.replace('{id}', id.toString()), contract);

const getCustomers = (params?: GetCustomersParams) =>
  http.get<PaginatedResponse<Customer>>(endpoints.contracts.customers, { params });

const getContractsReport = (customer: number, period: Period, signal: AbortSignal) => {
  return http.get<UserReport>(
    endpoints.contracts.contractsReport.replace('{id}', customer.toString()),
    {
      params: {
        start_date: dayjs.unix(period.startDate).utcOffset(0, true).format(),
        end_date: dayjs.unix(period.endDate).utcOffset(0, true).format(),
        cadence: period.cadence,
      },
      signal
    },
  );
};

const postPlanWizard = (data: ContractWizard) => {
  return http.post<ContractWizard>(endpoints.contracts.wizard, data);
};

const contractsService = {
  getContracts,
  addContract,
  deleteContract,
  updateContract,
  getCustomers,
  getContractsReport,
  postPlanWizard
};

export default contractsService;
