import { useCallback } from 'react';
import { IRowNode } from 'ag-grid-community';
import { DimensionItemRelation } from 'types/statutory.types';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { UNASSIGNED_ROW_ID } from
  'components/singleRevenueRecognition/invoicesTable/invoicesTable.utils';
const useAssignDimension = () => {
  const dimensionMap = useAppSelector(state => state.breakdowns.dimensionMap);

  const onAssignDimension = useCallback(<T extends Pick<DimensionItemRelation, 'dimensionItemIds'>>(
    node: IRowNode<T>,
    id: number,
    dimension: number
  ): T => {
    const currentDimensionItems = node.data?.dimensionItemIds;
    if (!currentDimensionItems) return null;

    const dimensionToAssign = dimensionMap[ dimension ];
    if (!dimensionToAssign) return null;
    const dimensionItems = dimensionToAssign.items.map(item => item.id);

    const differentDimensionItems = node.data.dimensionItemIds
      .filter(item => !dimensionItems.includes(item));

    const newDimensionItemIds = [ ...differentDimensionItems ];
    if (id !== UNASSIGNED_ROW_ID) {
      newDimensionItemIds.push(id);
    }

    return {
      ...node.data,
      dimensionItemIds: newDimensionItemIds
    };
  }, [ dimensionMap ]);

  return { onAssignDimension };
};

export default useAssignDimension;
