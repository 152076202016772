import { GetDetailRowDataParams, ValueFormatterParams } from 'ag-grid-community';
import useTableColumns from 'components/elements/tableWrapper/hooks/useTableColumns';
import useTableFormatter from 'components/elements/tableWrapper/hooks/useTableFormatter';
import useTableRenderer from 'components/elements/tableWrapper/hooks/useTableRenderer';
import { TableProps } from 'components/elements/tableWrapper/types/table.types';
import { HEADER_HEIGHT, ROW_HEIGHT } from 'components/elements/tableWrapper/utils/table.utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import { DimensionItemWithSplit, Transaction } from 'types/statutory.types';
import { getDimensionViewName } from 'utils/financials.utils';

interface Props {
  search: string;
}

const useMasterDetailSplits = ({ search }: Props): Partial<TableProps<Transaction>> => {
  const [ t ] = useTranslation('tableWrapper');
  const { renderField, amountRenderer } = useTableRenderer({ search });
  const { defaultColDef, numberColumnDef } = useTableColumns({ search });
  const { percentageFormatter } = useTableFormatter();
  const dimensions = useAppSelector(state => state.breakdowns.dimensions);

  const getDimensionName = (dimensionId: number) => {
    const dimension = dimensions.find(d => d.id === dimensionId);
    return getDimensionViewName(dimension);
  };

  const getDimensionItemName = (dimensionId: number, dimensionItemId: number) => {
    const dimension = dimensions.find(d => d.id === dimensionId);
    const item = dimension?.items.find(i => i.id === dimensionItemId);
    return getDimensionViewName(item);
  };

  const dimensionFormatter = (params: ValueFormatterParams<DimensionItemWithSplit>) => {
    return getDimensionName(params.data.dimension);
  };

  const dimensionItemFormatter = (params: ValueFormatterParams<DimensionItemWithSplit>) => {
    return getDimensionItemName(params.data.dimension, params.data.dimensionItem);
  };

  const isSplitDimensionItem = (dimensionItem: DimensionItemWithSplit) => {
    return dimensionItem.percentage !== '100.00';
  };

  const getSplitDimensionItems = (dimensionItems: DimensionItemWithSplit[]) => {
    return dimensionItems.filter(isSplitDimensionItem);
  };

  return useMemo((): Partial<TableProps<Transaction>> => ({
    masterDetail: true,
    isRowMaster: ({ dimensionItems }: Transaction) => {
      return getSplitDimensionItems(dimensionItems).length > 0;
    },
    groupDefaultExpanded: 0,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        defaultColDef: defaultColDef,
        headerHeight: HEADER_HEIGHT,
        rowHeight: ROW_HEIGHT,
        columnDefs: [
          {
            ...renderField('dimension', t('transactions-table.master-detail.dimension')),
            valueGetter: dimensionFormatter,
            keyCreator: dimensionFormatter,
            getQuickFilterText: dimensionFormatter,
            flex: 0,
          },
          {
            ...renderField('dimensionItem', t('transactions-table.master-detail.dimension-item')),
            valueGetter: dimensionItemFormatter,
            keyCreator: dimensionItemFormatter,
            getQuickFilterText: dimensionItemFormatter,
            flex: 0,
          },
          {
            ...renderField('value', t('transactions-table.master-detail.value')),
            ...numberColumnDef,
            flex: 0,
            width: 120,
            cellRenderer: amountRenderer
          },
          {
            ...renderField('percentage', t('transactions-table.master-detail.percentage')),
            ...numberColumnDef,
            valueFormatter: percentageFormatter,
            width: 120,
            flex: 0,
          },
        ]
      },
      getDetailRowData: (detailRowParams: GetDetailRowDataParams<Transaction>) => {
        return detailRowParams.successCallback(
          getSplitDimensionItems(detailRowParams.data.dimensionItems)
        );
      }
    }
  }), []);
};

export default useMasterDetailSplits;
