import { BreakdownCategoryType } from '../../../types/filterTable.types';
import { t } from 'i18next';

export type SectionInfo = {
  title: string | null;
  type: BreakdownCategoryType;
  canAdd: boolean;
};

const sections = [
  {
    title: null,
    type: BreakdownCategoryType.GLOBAL,
    canAdd: false,
  },
  {
    title: t('financials:left-panel.custom'),
    type: BreakdownCategoryType.USER,
    canAdd: true,
  },
  {
    title: t('financials:left-panel.external'),
    type: BreakdownCategoryType.PROCOUNTOR,
    canAdd: false,
  },
  {
    title: t('financials:left-panel.external'),
    type: BreakdownCategoryType.NETVISOR,
    canAdd: false,
  },
  {
    title: t('financials:left-panel.external'),
    type: BreakdownCategoryType.XERO,
    canAdd: false,
  },
];

export default sections;
