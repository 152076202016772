import { useEffect } from 'react';
import {
  useCommandBar
} from 'components/commandBar/context/CommandBarContext';
import {
  CommandBarUtil,
} from 'components/commandBar/types/commandBar.types';
import { FilterList } from 'types/filterTable.types';

interface Props {
  utils?: CommandBarUtil[];
  hidden?: boolean;
  clearOnUnmount?: boolean;
  filterCapsules?: FilterList;
}

const useCommandBarEffect = ({
  utils = [],
  hidden = false,
  clearOnUnmount = false,
  filterCapsules,
}: Props) => {
  const { dispatch } = useCommandBar();

  useEffect(() => {
    dispatch({ type: 'SET_UTILS', payload: utils });

    return () => {
      dispatch({ type: 'CLEAR_UTILS', payload: utils.map(el => el.key) });
    };
  }, [ utils ]);

  useEffect(() => {
    dispatch({ type: 'SET_HIDDEN', payload: hidden });
  }, [ hidden ]);

  useEffect(() => {
    dispatch({ type: 'ADD_CAPSULES_FILTER', payload: filterCapsules || [] });
  }, [ filterCapsules ]);

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        dispatch({ type: 'CLEAR_SETTINGS' });
      }
    };
  }, []);

  return undefined;
};

export default useCommandBarEffect;
