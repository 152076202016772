import React from 'react';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import useReportQuery from '../../financialTable/hooks/useReportQuery';
import { useFinancialTable } from '../../../../context/FinancialTableContext';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { selectPeriod } from '../../../../store/financials.slice';
import { useTranslation } from 'react-i18next';

const RefreshTableButton = () => {

  const { state: { templateId } } = useFinancialTable();
  const period = useAppSelector(selectPeriod(templateId));
  const { t } = useTranslation('financials');

  const { refetch } = useReportQuery({ templateId,
    period,
    shouldFetch: true
  });

  const onRefresh = () => {
    refetch();
  };

  return <GhostIconButton
    aria-label='refresh'
    onClick={ onRefresh }
    tooltip={ t('actions.refresh') }
  >
    <RefreshIcon />
  </GhostIconButton>;
};

export default RefreshTableButton;
