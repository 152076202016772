import React from 'react';
import CommandBarButton from 'components/commandBar/components/button/CommandBarButton';
import CommandBarDropdown from
  'components/commandBar/components/common/dropdown/CommandBarDropdown';
import { CommandBarCustom } from 'components/commandBar/types/commandBar.types';

interface Props {
  button: CommandBarCustom;
}

const CommandBarCustomButton = ({ button }: Props) => {
  return <CommandBarDropdown button={ button }>
    <CommandBarButton button={ button } />
  </CommandBarDropdown>;
};

export default CommandBarCustomButton;