import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBar from './headerBar/HeaderBar';
import LeftPanel from './leftPanel/LeftPanel';
import styles from './SingleRevenueRecognition.module.scss';
import InvoicesTable from 'components/singleRevenueRecognition/invoicesTable/InvoicesTable';
import { useParams } from 'react-router-dom';
import { RevenueRecognitionProvider } from 'context/RevenueRecognitionContext';
import DeleteProductModal from './deleteProductModal/DeleteProductModal';
import { SearchableContextProvider } from 'context/SearchableContext';
import OrganizationSelect from '../elements/organizationSelect/OrganizationSelect';

type RouteParams = 'id';

const SingleRevenueRecognition = () =>{
  const [ t ] = useTranslation('revenueRecognition');
  const header = t('links.manage.revenue-recognition', { ns: 'navigation' });
  const { id } = useParams<RouteParams>();

  return (
    <RevenueRecognitionProvider>
      <DeleteProductModal/>
      <div className={ styles.singleRevenueRecognition }>
        <h1 className={ styles.header }>
          <OrganizationSelect />
          { header }
        </h1>
        <HeaderBar id={ id }/>
        <div className={ styles.invoices }>
          <SearchableContextProvider>
            <LeftPanel/>
          </SearchableContextProvider>
          <InvoicesTable id={ +id } />
        </div>
      </div>
    </RevenueRecognitionProvider>

  );
};

export default SingleRevenueRecognition;