import { Tooltip } from 'antd';
import IntegrationStatus from 'components/settings/integrations/status/IntegrationStatus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Integration } from 'types/settings.types';
import styles from '../Integrations.module.scss';

interface Props {
  integration: Integration;
  disabled: boolean;
  children: React.ReactNode;
  logo: string;
  label?: string;
}

const IntegrationItem = ({ integration, disabled, children, logo, label }: Props) => {
  const [ t ] = useTranslation('settings');
  const active = integration?.lastParsedStatus === 'success';
  const error = integration?.lastParsedStatus === 'fail';

  return <Tooltip trigger='hover' title={ disabled ? t('integration-disabled') : null }>
    <span>
      <li className={ `
      ${ styles.item }
      ${ active ? styles.checked : '' }
      ${ disabled ? styles.disabled : '' }
      ${ error ? styles.error : '' }
    ` }
      >
        <div className={ styles.logoAndLabel }>
          <img
            src={ logo }
            alt='logo'
            className={ `${ styles.logo } ${ styles.netvisor }` }
          />
          { label && <div className={ styles.label }>
            { label }
          </div> }
        </div>

        <div className={ styles.integrations }>
          { children }
          <IntegrationStatus integration={ integration }/>
        </div>
      </li>
    </span>
  </Tooltip>;
};

export default IntegrationItem;
