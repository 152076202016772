import { useEffect } from 'react';

const useUnsavedChangesWarning = (unsavedChanges: boolean) => {

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        event.preventDefault();

        event.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [ unsavedChanges ]);

  return null;
};

export default useUnsavedChangesWarning;