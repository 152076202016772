import React, { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { isEqual } from 'lodash';
import clsx from 'clsx';
import { IRowNode } from 'ag-grid-community';

import type { FilterList } from 'types/filterTable.types';
import type { LeftPanelType } from 'types/app.types';
import type {
  AssignFunction,
  UnassignFunction,
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import { SortIndex } from 'components/elements/tableWrapper/types/table.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  clearFilters,
  clearLeftPanel, financialsSlice, selectPeriod, setLeftPanel, toggleFilteredElements
} from 'store/financials.slice';
import { NodeSortingSetting, type SortingType } from 'utils/sorting.utils';
import { gridSortOptionsValues, isTransactionLevelSorting } from 'utils/financials.utils';
import SearchBar from 'components/elements/searchBar/SearchBar';
import DimensionsView
  from 'components/elements/dimensionLeftPanel/listView/dimensionsView/DimensionsView';
import FinancialFooter from 'components/elements/dimensionLeftPanel/footer/FinancialFooter';
import { useFinancialTable } from 'context/FinancialTableContext';
import PanelWrapper from 'components/panels/elements/wrappers/PanelWrapper';
import LeftPanelHeader
  from 'components/panels/leftPanelHeader/LeftPanelHeader';
import GroupAndSort from 'components/panels/groupAndSort/GroupAndSort';
import { ColumnSortSettings, type FormType } from 'components/panels/sortSelection/SortSelection';

import panelStyles from 'components/panels/Panels.module.scss';
import styles from './FinancialsLeftPanel.module.scss';
import { useClearFiltersMutation, useSetFiltersMutation } from '../../../../store/api/reports.api';
import useReportQuery from '../../financialTable/hooks/useReportQuery';

const allTabs: LeftPanelType[] = [ 'filter', 'label', 'sort' ];

interface Props {
  node: IRowNode | null;
  gridRef: MutableRefObject<AgGridReact>;
  assignLabels: AssignFunction;
  unassignLabels: UnassignFunction;
  disableTabs?: LeftPanelType[];
}
const FinancialsLeftPanel = ({
  node, gridRef, assignLabels, unassignLabels, disableTabs = []
}: Props) => {
  const { state: { templateId } } = useFinancialTable();
  const dispatch = useAppDispatch();
  const panelType = useAppSelector(state => state.app.leftPanel);
  const activeItem = useAppSelector(state => state.financials.active);
  const filter = useAppSelector(state => state.financials.tables[ templateId ]?.filter);
  const [ stagingFilter, setStagingFilter ] = useState<FilterList>();
  const sorting = useAppSelector((state) => state.financials.tables[ templateId ]?.sorting);
  const [ clearFiltersMutation, clearFiltersResult ] = useClearFiltersMutation();
  const [ setFilters, setFiltersResult ] = useSetFiltersMutation();
  const period = useAppSelector(selectPeriod(templateId));
  const { refetch } = useReportQuery({ templateId, period });

  const availableTabs = useMemo(
    () => allTabs.filter(tab => !disableTabs.includes(tab)), [ disableTabs ]
  );

  useEffect(() => {
    if (filter) {
      setStagingFilter(filter);
    }
  }, [ filter ]);

  const isActive = useMemo(() => {
    return (availableTabs as unknown[]).includes(panelType) &&
      templateId === activeItem?.templateId;
  }, [ templateId, panelType, activeItem?.templateId, availableTabs ]);

  const onChange = useCallback((key: LeftPanelType | undefined | null) => {
    if (key == null) clearLeftPanel();

    dispatch(setLeftPanel({ ...(activeItem ?? {}), panelType: key as LeftPanelType }));
  }, [ templateId ]);

  const onClose = () => {
    dispatch(clearLeftPanel());
  };

  const defaultSortSettings: Partial<FormType> = useMemo(() => {
    return getDefaultSortFormValues(sorting, node);
  }, [ node, sorting ]);

  const sortOptions: { label: string; value: number | string }[] = useMemo(() => {
    if (!node || !node.parent) return [];

    const isToSort = isTransactionLevelSorting(node.parent);
    return gridSortOptionsValues(isToSort);
  }, [ node ]);

  const onSort = useCallback((sortSetting: Partial<FormType>) => {
    if (!node || !node.parent || !node.parent.id) return;

    const newSettings = getNewSettingsFromForm(sortSetting);

    if (isEqual(newSettings, sorting[ node.parent.id ])) return;

    dispatch(financialsSlice.actions.setSorting({
      templateId,
      nodeId: node.parent.id,
      settings: newSettings
    }));
  }, [ node, sorting, templateId, dispatch ]);

  const onClearFilters = async () => {
    await clearFiltersMutation({ id: templateId });
    dispatch(clearFilters(templateId));
    refetch();
  };

  const onApplyFilters = async () => {
    await setFilters({ id: templateId, data: stagingFilter });
    dispatch(toggleFilteredElements(stagingFilter));
    refetch();
  };

  return <PanelWrapper type='left' isActive={ isActive } >
    <LeftPanelHeader
      availableTabs={ availableTabs }
      onChange={ onChange }
      onClose={ onClose }
      activeKey={ panelType as LeftPanelType }
    />

    {
      (panelType == 'label' || panelType == 'filter') && (
        <SearchBar
          className={ panelStyles.searchBox }
          showSeparator={ false }
          showResults={ false }
        />
      )
    }

    {
      panelType === 'sort' && (
        <div className={ clsx(panelStyles.panelBody, styles.sort) }>
          <GroupAndSort
            defaultSortSettings={ templateId ? defaultSortSettings : {} }
            sortOptions={ templateId ? sortOptions : null }
            customSortHandler={ templateId ? onSort : null }
            gridRef={ gridRef }
            availableTabs={ (templateId && sortOptions.length || !templateId) ? [ 'sort' ] : [] }
          />
        </div>
      )
    }

    { (panelType === 'label' || panelType === 'filter') && (
      <DimensionsView
        templateId={ templateId }
        stagingFilter={ stagingFilter }
        setStagingFilter={ setStagingFilter }
        classContainer={ styles.dimensionsList }
        mode={ panelType }
        gridRef={ gridRef }
        defaultUnassignNodesCallback={ unassignLabels }
        assignLabels={ assignLabels }
      />
    ) }

    {
      panelType === 'filter' && (
        <FinancialFooter
          onClearFilters={ onClearFilters }
          onApplyFilters={ onApplyFilters }
          clearLoading={ clearFiltersResult.isLoading }
          applyLoading={ clearFiltersResult.isLoading || setFiltersResult.isLoading }
          filters={ filter }
          stagingFilter={ stagingFilter }
        />
      )
    }

  </PanelWrapper>;
};

export default FinancialsLeftPanel;

type SortingSettings = { isDescending: boolean; type: SortingType };
type ColumnState = { colId: string | number; sort: 'asc' | 'desc'; sortIndex: SortIndex };

function convertToColumnState(index: SortIndex, sortSetting: SortingSettings): ColumnState;
function convertToColumnState(
  index: SortIndex, sortSetting?: SortingSettings
): ColumnState | undefined {
  if (!sortSetting) {
    return undefined;
  }

  return {
    colId: sortSetting.type as unknown as number,
    sort: (sortSetting.isDescending ? 'desc' : 'asc'),
    sortIndex: index
  };
}

function convertToRowSorting(
  sortSetting: ColumnSortSettings
) {
  return {
    type: sortSetting.colId as unknown as SortingType,
    isDescending: sortSetting.sort === 'desc'
  };
}

function getNewSettingsFromForm(
  sortSetting: Partial<FormType>,
) {
  return {
    ...(sortSetting?.primary ? {
      primary: convertToRowSorting(sortSetting.primary)
    } : {}),
    ...(sortSetting?.secondary ? {
      secondary: convertToRowSorting(sortSetting.secondary)
    } : {})
  };
}

function getDefaultSortFormValues (
  sorting: Record<string, NodeSortingSetting>, node: IRowNode | null
): Partial<FormType> {
  const sortSettings: Partial<FormType> = {};

  if (!node || !node.parent || !node.parent.id || !sorting) return sortSettings;

  if (sorting[ node.parent.id ]?.primary) {
    sortSettings[ SortIndex.PRIMARY ] = convertToColumnState(
      SortIndex.PRIMARY, sorting[ node.parent.id ].primary as SortingSettings
    );

    if (sorting[ node.parent.id ]?.secondary) {
      sortSettings[ SortIndex.SECONDARY ] = convertToColumnState(
        SortIndex.SECONDARY, sorting[ node.parent.id ].secondary as SortingSettings
      );
    }
  }

  return sortSettings;
}
