import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { reportsService } from 'services/reports.services';
import { clearSelectedElements, saveElement } from 'store/dashboard.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { selectAllTemplates } from 'store/templates.slice';
import {
  DashboardElementMapping,
  DashboardElementType,
  TemplateElement,
  TemplateElementType
} from 'types/dashboard.types';
import { TemplateType } from 'types/templates.types';
import { IGNORE_NODES } from 'utils/chart.utils';
import { getDisplayName } from 'utils/common.utils';
import styles from '../Forms.module.scss';
import TemplateSelect
  from 'components/dashboard/panel/cardSettingsPage/forms/common/TemplateSelect';
import FlatSelect from 'components/elements/flatSelect/FlatSelect';
import ElementName from 'components/dashboard/panel/cardSettingsPage/forms/common/ElementName';
import { RowType } from 'types/financials.types';
import { getDimensionViewName } from 'utils/financials.utils';
import dashboardService from 'services/dashboard.service';
import { formatUnixDate } from 'utils/period.utils';
import FormWrapper from 'components/dashboard/panel/cardSettingsPage/forms/common/FormWrapper';
import { selectViewPeriod } from 'store/topBar.slice';
import NodeSelect from '../../../../../elements/nodeSelect/NodeSelect';
import useTemplateNode from '../../../../../../hooks/useTemplateNode';

interface Props {
  item: DashboardElementMapping<DashboardElementType.BIG_NUMBER>;
}

interface FormValues {
  name: string;
  element: TemplateElement;
}

const TemplateElementForm = ({ item }: Props) => {
  const [ t ] = useTranslation('dashboard');
  const templates = useAppSelector(selectAllTemplates);
  const period = useAppSelector(selectViewPeriod);
  const activeDashboardId = useAppSelector((state) => state.dashboard.activeDashboardId);
  const [ templateLoading, setTemplateLoading ] = useState(false);
  const [ showCustomName, setShowCustomName ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);

  const dispatch = useAppDispatch();
  const { control, handleSubmit, reset, setValue } = useForm<FormValues>({
    defaultValues: {
      name: item.name,
      element: item.element
    }
  });
  const values = useWatch({ control });

  const selectedTemplate = useMemo(() => {
    return templates.find((temp) => temp.id === values.element?.template);
  }, [ values.element?.template, templates ]);

  const [ templateNodes, setTemplateNodes ] = useState<{label: string; value: string}[]>([]);
  const { getNodeOptions, findOption } = useTemplateNode(
    { templateId: values.element?.template, type: 'chart' }
  );
  const options = useMemo(() => getNodeOptions(), [ getNodeOptions, values.element?.template ]);

  useEffect(() => {
    if (item) {
      reset({
        name: item.name,
        element: item.element,
      });
    }
  }, [ item ]);

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }
    setTemplateLoading(true);
    const request = selectedTemplate.type === TemplateType.USER ?
      reportsService.getUserTemplate :
      reportsService.getSystemTemplate;
    request(selectedTemplate.id).then(({ data }) => {
      const nodes = data.nodes.
        filter(node => {
          return !IGNORE_NODES.includes(node.type);
        }).map(node => ({
          label: node.type === RowType.BREAKDOWN || node.type === RowType.DIMENSION_ITEM ?
            getDimensionViewName(node.rowData) :
            getDisplayName(node.rowData.name),
          value: node.uuid
        }));
      setTemplateNodes(nodes);
    }).finally(() => {
      setTemplateLoading(false);
    });
  }, [ selectedTemplate ]);

  useEffect(() => {
    if (!showCustomName) {
      const node = templateNodes.find((_node) => _node.value === values.element?.templateNode);
      setValue('name', node?.label);
    }
  }, [ values.element.templateNode ]);

  useEffect(() => {
    setValue('name', item.name);
    if (!item.name) {
      setValue('name', '');
    }
  }, [ selectedTemplate ]);

  useEffect(() => {
    if (item.name) {
      return;
    }
    setValue('name', '');
  }, [ values.element.template ]);

  const onSubmit = useCallback((data: FormValues) => {
    setSubmitting(true);
    const template = templates?.find((temp) => temp.id === data.element?.template);
    let name = data.name;
    if (template && !name.length) {
      const node = templateNodes.find((_node) => _node.value === data.element?.templateNode);
      name = node?.label || '';
    }

    const updatedElement = {
      ...item,
      ...data,
      name
    };

    dashboardService.getElementPreview(updatedElement, {
      startDate: formatUnixDate(period.startDate),
      endDate: formatUnixDate(period.endDate),
      cadence: period.cadence,
      dashboard: activeDashboardId,
    }).then((res) => {
      dispatch(saveElement({
        ...res.data,
        id: item.id,
      }));
    }).catch(() => {
      dispatch(saveElement(updatedElement));
    }).finally(() => {
      setSubmitting(false);
      dispatch(clearSelectedElements());
    });
  }, [ templates, templateNodes, item, period ]);

  const valueTypeOptions = useMemo(() => {
    // t(`panel.card-settings.value-type-values.cumulative`)
    // t(`panel.card-settings.value-type-values.last_period`)
    return Object.values(TemplateElementType).map((valueType) => ({
      value: valueType,
      label: t(`panel.card-settings.value-type-values.${ valueType }`)
    }));
  }, []);

  useEffect(() => {
    setShowCustomName(false);
  }, [ values.element?.template ]);

  const resetTemplateNode = useCallback((templateId: number) => {
    reset({
      ...values,
      element: {
        ...values.element,
        template: templateId,
        templateNode: null
      }
    });
  }, [ values ]);

  const getValue = useCallback((value: string) => {
    if (templateLoading || !templateNodes.length) return undefined;
    // Show error message if template node doesn't exist
    if (!templateNodes.filter(tn => tn.value === value).length)
      return t('panel.card-settings.template-row-missing');
    return value;
  }, [ templateLoading, templateNodes ]);

  return (
    <FormWrapper loading={ submitting } onSubmit={ handleSubmit(onSubmit) }>
      <TemplateSelect
        control={ control }
        name='element.template'
        value={ selectedTemplate?.title }
        onChange={ resetTemplateNode }
      />
      <Controller
        control={ control }
        name='element.templateNode'
        render={ ({ field }) => {
          return <div className={ styles.container }>
            <div className={ styles.label }>
              { t('panel.card-settings.template-row') }
            </div>
            <NodeSelect
              className={ styles.input }
              disabled={ templateLoading || !values.element?.template }
              value={ getValue(field.value) }
              nodeOptions={ options }
              onChange={ (e) => setValue('element.templateNode', findOption(options, e).value) }
            />
          </div>;
        } }
      />
      <Controller
        control={ control }
        name='name'
        render={ ({ field }) => (
          <ElementName
            name={ field.value }
            onChangeName={ field.onChange }
            showCustomName={ showCustomName }
            setShowCustomName={ setShowCustomName }
          />
        ) }
      />
      <Controller
        control={ control }
        name='element.valueType'
        render={ ({ field }) => (
          <div className={ styles.container }>
            <div className={ styles.label }>
              { t('panel.card-settings.value-type') }
            </div>
            <FlatSelect
              { ...field }
              options={ valueTypeOptions }
              className={ styles.input }
            />
          </div>
        ) }
      />
    </FormWrapper>
  );
};

export default TemplateElementForm;
