import { IRowNode } from 'ag-grid-community';
import { RefObject } from 'react';
import { AgGridReact } from 'ag-grid-react';

interface Props {
  gridRef: RefObject<AgGridReact>;
}

const useFinancialTableDragSelection = ({ gridRef }: Props) => {

  const getRowsToLabel = (nodes: IRowNode[]): IRowNode[] => {
    let _nodes = nodes;
    // ! HACK: Fixes issue with drag PP ignores selection
    if (nodes.length === 1 && nodes.at(0).data == null && nodes.at(0).isSelected()) {
      _nodes = gridRef.current.api.getSelectedNodes();
    }
    return _nodes;
  };

  return {
    getRowsToLabel,
  };

};

export default useFinancialTableDragSelection;
