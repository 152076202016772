import { useCallback, useEffect, useMemo, useState } from 'react';
import { BudgetItem, BudgetItemType } from 'types/budget.types';
import { TransactionLineRequestParams } from 'services/statutory.service';
import { planningService } from 'services/planning.service';
import { noop, uniqBy } from 'lodash';
import { useImmer } from 'use-immer';
import { Period } from 'types/financials.types';
import { addPeriod } from 'utils/period.utils';
import { UUID } from 'types/templates.types';
import { getUUID } from 'utils/templates.utils';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import useTrackChanges from 'hooks/useTrackChanges';
import { isBudgetItemValid } from 'components/budget/utils/budget.utils';
import { notifyError, notifyUnexpectedError } from 'utils/notifications.utils';
import { setAutoFreeze } from 'immer';
import { ChatTaskType } from 'types/chat.types';
import { chatSlice } from 'store/chat.slice';
import { InvoicingFrequency } from 'types/contracts.types';
import { updateDetailedViewSettings } from '../../../../store/financials.slice';

interface Props {
  period: Period;
  onBudgetItemsUpdated?: () => void;
}

export interface InternalBudgetItem extends Partial<BudgetItem> {
  id: number;
  internalId?: UUID;
}

const isInternalBudgetItem = (
  item: BudgetItem | InternalBudgetItem
): item is InternalBudgetItem => 'internalId' in item;

const useBudgetItems = ({ period, onBudgetItemsUpdated = noop }: Props) => {
  const dispatch = useAppDispatch();
  const budgetItemTypes = useAppSelector(state => state.budget.budgetItemTypesMap);
  const accounts = useAppSelector(state => state.breakdowns.accounts);
  const counterparties = useAppSelector(state => state.breakdowns.counterpartiesMap);
  const budgetSettings = useAppSelector(state => state.budget.budgetSettings);
  const tasks = useAppSelector(state => {
    return state.chat.tasks.filter(task => task.type === ChatTaskType.BUDGET_ITEM_COMPLETION);
  });

  const [ loading, setLoading ] = useState(false);
  const [ updating, setUpdating ] = useState(false);
  const [ items, setItems ] = useImmer<InternalBudgetItem[]>([]);
  setAutoFreeze(false);
  const [ isDataFetched, setIsDataFetched ] = useState(false);

  const {
    changes,
    clear: clearChanges,
    trackChanges,
    remove: removeChange
  } = useTrackChanges<InternalBudgetItem>({ items, isValid: isBudgetItemValid });

  const fetchBudgetItems = useCallback(async (
    _params: TransactionLineRequestParams[],
    _period?: Period
  ) => {
    setLoading(true);
    setIsDataFetched(false);
    const paramsWithPeriod = addPeriod(_params, _period ?? period).map(param => ({
      ...param,
      'start_date': param[ 'plan_start_date' ],
      'end_date': param[ 'plan_end_date' ]
    }));

    const requests = paramsWithPeriod.map(param => planningService.getTableBudgetItems(param));
    try {
      const res = await Promise.all(requests);
      const allBudgetItems = res.reduce((acc, { data }) => {
        return [ ...acc, ...data ];
      }, []);

      const uniqueBudgetItems = uniqBy(allBudgetItems, 'id');
      setItems(uniqueBudgetItems);
      setIsDataFetched(true);
      return uniqueBudgetItems;
    } finally {
      setLoading(false);
    }
  }, [ period ]);

  const fetchBudgetItemsByItemType = useCallback(async (itemType: BudgetItemType) => {
    setLoading(true);
    setIsDataFetched(false);
    try {
      const { data } = await planningService.getBudgetItems({
        itemType: itemType.id,
      });
      const uniqueBudgetItems = uniqBy(data.results, 'id');
      setItems(uniqueBudgetItems);
      setIsDataFetched(true);
      return uniqueBudgetItems;
    } finally {
      setLoading(false);
    }
  }, []);

  const update = useCallback((budgetItem: BudgetItem | InternalBudgetItem, track=true) => {
    setItems(draft => {
      const itemIndex = draft.findIndex(b => {
        if (isInternalBudgetItem(budgetItem)) {
          return b[ 'internalId' ] === budgetItem.internalId;
        }
        return b.id === budgetItem.id;
      });
      if (itemIndex === -1) {
        draft.unshift(budgetItem);
      } else {
        draft[ itemIndex ] = budgetItem;
      }

      return draft;
    });
    if (track) {
      trackChanges([ budgetItem ]);
      onBudgetItemsUpdated();
    } else {
      clearChanges();
    }
  }, []);

  const remove = useCallback((budgetItem: number | UUID) => {
    setItems(draft => {
      const deletedItemIndex = draft.findIndex((item) => {
        if (typeof budgetItem === 'number') {
          return item.id === budgetItem;
        } else {
          return item.internalId === budgetItem;
        }
      });
      if (deletedItemIndex !== -1) {
        draft.splice(deletedItemIndex, 1);
        removeChange(budgetItem);
      }
    });
  }, []);

  const duplicate = useCallback((item: InternalBudgetItem) => {
    const budgetItem: InternalBudgetItem = {
      ...item,
      id: undefined,
      internalId: getUUID(),
    };
    setItems(draft => {
      draft.splice(0, 0, budgetItem);
      trackChanges([ budgetItem ]);
    });
  }, []);

  const getDefaultBudgetItem = useCallback((budgetItemType: BudgetItemType) => {
    const itemTypeRelatedSettings: Partial<BudgetItem> = budgetItemType != null ? {
      memo: budgetItemType.memo,
      accounts: {
        primary: budgetItemType.defaultAccounts.primary,
      }
    }: {};

    return {
      ...itemTypeRelatedSettings,
      itemType: budgetItemType?.id,
      dimensionItems: [],
      dimensionItemIds: [],
      startDate: budgetSettings?.general?.planStart
    };
  }, [ accounts, budgetSettings ]);

  const addItem = useCallback((
    budgetItemType: number,
    setting: Partial<InternalBudgetItem> = {}
  ) => {
    const itemType = budgetItemTypes[ budgetItemType ];
    const budgetItem = getDefaultBudgetItem(itemType);
    const newItem = {
      id: undefined,
      internalId: setting.internalId ?? getUUID(),
      ...budgetItem,
      ...setting,
      memo: setting.memo ?? budgetItem.memo,
      inputs: {},
      dimensionSplit: []
    } as BudgetItem;

    setItems(draft => {
      draft.splice(0, 0, newItem);
    });
    trackChanges([ newItem ]);
    // Clear budget item type that is used to add new item on opening the detailed view
    //  so that it doesn't open again after item was added.
    dispatch(updateDetailedViewSettings({ budgetItemType: null }));
    return newItem;
  }, [ budgetItemTypes, getDefaultBudgetItem ]);

  const onUpdate = useCallback(() => {
    setUpdating(true);
    const budgetItemsData = changes.map(b => ({
      ...b,
      accounts: { ...b.accounts } ,
      counterparty: b.counterparty?.id > 0 ? b.counterparty.id : null,
    }));

    const newItems = changes.filter(b => b.id == null && b.internalId != null);
    planningService.batchUpdateBudgetItems(budgetItemsData).then((res) => {
      res.data.forEach((item) => update(item, false));
      newItems.forEach((item) => remove(item.internalId));
      clearChanges();
    }).catch((e) => {
      const key = Object.keys(e.response.data)[ 0 ];
      if (key) {
        const values = Object.values(e.response.data[ key ]);
        if (values) {
          notifyError(values[ 0 ] as unknown as string);
        }
      } else {
        notifyUnexpectedError(e);
      }
    }).finally(() => {
      setUpdating(false);
    });
  }, [ changes ]);

  const clear = () => {
    setItems(() => []);
    clearChanges();
  };

  const getFrequency = (frequency: string): InvoicingFrequency => {
    if (Object.values(InvoicingFrequency).includes(frequency as InvoicingFrequency)) {
      return frequency as InvoicingFrequency;
    }
    return null;
  };

  const resetChatSessionIfItemValid = (budgetItem: BudgetItem) => {
    if (isBudgetItemValid(budgetItem)) {
      dispatch(chatSlice.actions.resetSession());
    }
  };

  /**
   * @param budgetItemType
   * @param setting
   * @param canProceed Determines if it is ok to reset chat session if the item is valid
   */
  const addOrUpdateChatItem = (
    budgetItemType: number,
    setting: Partial<InternalBudgetItem> = {},
    canProceed = false,
  ) => {
    const addedItem = items.find(item => item.internalId === setting.internalId);
    if (addedItem) {
      const itemType = budgetItemTypes[ budgetItemType ];
      const budgetItem = {
        ...addedItem,
        ...setting,
        memo: setting.memo ?? getDefaultBudgetItem(itemType).memo,
      };
      update(budgetItem);
      if (canProceed) resetChatSessionIfItemValid(budgetItem as BudgetItem);
    } else {
      const newItem = addItem(budgetItemType, setting);
      if (canProceed) resetChatSessionIfItemValid(newItem);
    }
  };

  useEffect(() => {
    if (tasks.length) {
      const task = tasks[ 0 ];
      const sessionUuid = task.payload.sessionUuid;
      const budgetItemData = task.payload.budgetItemData;
      addOrUpdateChatItem(budgetItemData.budgetItemType, {
        internalId: sessionUuid,
        counterparty: counterparties[ budgetItemData.counterparty ],
        invoicingFrequency: getFrequency(budgetItemData.frequency),
        invoicingDate: budgetItemData.invoicingDay,
        startDate: budgetItemData.startDate,
        endDate: budgetItemData.endDate,
        amountFormula: budgetItemData.amountFormula,
        memo: budgetItemData.memo,
      }, task.canProceed);
      dispatch(chatSlice.actions.removeTask(task.id));
    }
  }, [ tasks ]);

  return useMemo(() => ({
    items,
    loading,
    updating,
    canApply: changes.length > 0 && !updating,
    isDataFetched,
    fetchBudgetItems,
    fetchBudgetItemsByItemType,
    update,
    remove,
    setItems,
    duplicate,
    clear,
    addItem,
    onUpdate,
    clearChanges
  }), [ items, loading, isDataFetched, updating, onUpdate, changes, addItem ]);
};

export default useBudgetItems;
