import React, { useCallback } from 'react';
import { Dropdown } from 'antd';
import styles from './SelectSort.module.scss';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { DownOutlined } from '@ant-design/icons';
import { useRevenueRecognition } from 'context/RevenueRecognitionContext';
import i18n from '../../../locales/i18n';
import { SortMenuItem } from 'types/revenueRecognition.types';

const { t } = i18n;

const SelectSort = () => {
  const { dispatch } = useRevenueRecognition();

  const {
    state: { sort },
  } = useRevenueRecognition();

  const menuList: SortMenuItem[] = [
    {
      field: 'name',
      direction: 'asc',
      title: t('revenueRecognition:sort.name-asc'),
    },
    {
      field: 'name',
      direction: 'desc',
      title: t('revenueRecognition:sort.name-desc'),
    },
    {
      field: 'totalRevenue',
      direction: 'desc',
      title:t('revenueRecognition:sort.revenue-desc'),
    },
    {
      field: 'totalRevenue',
      direction: 'asc',
      title:t('revenueRecognition:sort.revenue-asc'),
    }
  ];

  const handleChangeMenu = ( item: SortMenuItem ) =>{
    dispatch({ type: 'SORT', payload: item });
  };

  const isOptionSelected = useCallback((el) => el.field === sort.field &&
    el.direction === sort.direction, [ sort ]);

  const sortBy = menuList.find(isOptionSelected);

  return (
    <Dropdown
      placement='bottomRight'
      dropdownRender={ () =>
        <div className={ styles.menuList }>
          <span className={ styles.header }>Name</span>
          {
            menuList.slice(0,2).map(el => (
              <div
                onClick={ () => handleChangeMenu(el) } key={ el.field + el.direction }
                className={ ` ${ styles.menuItem }
                  ${ isOptionSelected(el) ? styles.isActive : '' } `
                }>
                <span>{ el.title.split('/')[ 1 ] }</span>
                {
                  isOptionSelected(el) && <CheckIcon className={ styles.checkIcon }/>
                }
              </div>
            ))
          }
          <div className={ styles.separator }></div>
          <span className={ styles.header }>Revenue</span>
          {
            menuList.slice(2,4).map(el => (
              <div key={ el.field + el.direction } onClick={ () => handleChangeMenu(el) }
                className={ ` ${ styles.menuItem }
                  ${ sortBy.title === el.title ? styles.isActive : '' } `
                }>
                <span onClick={ () => handleChangeMenu(el) }>{ el.title.split('/')[ 1 ] }</span>
                {
                  sortBy.title === el.title && <CheckIcon className={ styles.checkIcon }/>
                }
              </div>
            ))
          }

        </div>
      }>
      <div className={ styles.wrapper }>
        Sort By:
        <span>{ sortBy.title.split('/')[ 0 ] }</span>
        <DownOutlined />
      </div>
    </Dropdown>
  );
};

export default SelectSort;
