import Button from 'components/elements/button/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DimensionFilter, FilterList } from 'types/filterTable.types';
import styles from './FinancialFooter.module.scss';
import { isEqual } from 'lodash';

interface Props {
  stagingFilter: FilterList | undefined;
  filters: FilterList;
  clearLoading?: boolean;
  applyLoading?: boolean;
  onClearFilters?: () => void;
  onApplyFilters?: () => void;
}

const FinancialFooter = (
  {
    clearLoading = false,
    applyLoading = false,
    stagingFilter,
    filters,
    onApplyFilters,
    onClearFilters
  }: Props) => {
  const [ t ] = useTranslation('common');

  const stagingIsClear = isEqual(filters, stagingFilter);
  const allFiltersIsClear = filters && filters.every(isFilterEmpty);

  const _onClearFilters = async () => {
    if (onClearFilters) {
      return onClearFilters();
    }
  };

  const _onApplyFilters = async () => {
    if (onApplyFilters) {
      return onApplyFilters();
    }
  };

  return <div className={ styles.footer }>
    {
      !allFiltersIsClear && (
        <Button
          onClick={ _onClearFilters }
          className={ styles.clearButton }
          loading={ clearLoading }
          type='link'
        >
          { t('form.clear-all-filters') }
        </Button>
      )
    }

    <Button
      disabled={ stagingIsClear }
      onClick={ _onApplyFilters }
      loading={ applyLoading }
      className={ `${ styles.applyButton }` }
    >
      { t('form.apply') }
    </Button>

  </div>;
};

export default FinancialFooter;

function isFilterEmpty(filter: DimensionFilter) {
  return filter?.excludeUnassigned === false && filter?.dimension === 0;
}
