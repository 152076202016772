import { RefObject, useCallback, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { IRowNode } from 'ag-grid-community';
import { useAppSelector } from 'store/hooks/hooks';
import { RowType } from 'types/financials.types';
import { isDataEmpty } from 'utils/financials.utils';
import {
  UNASSIGNED_ROW_NAME
} from 'components/singleRevenueRecognition/invoicesTable/invoicesTable.utils';

const SHOW_ALWAYS_TYPES: RowType[] = [
  RowType.SPACER,
  RowType.HALF_SPACER,
  RowType.TITLE,
  RowType.NEW_BUDGET_ITEM
];

const useGridFilter = (
  gridRef: RefObject<AgGridReact>,
  templateId: number
) => {
  const showAll = useAppSelector(state => state.financials.tables[ templateId ].state.accounts);

  useEffect(() => {
    gridRef?.current?.api?.onFilterChanged();
  }, [ showAll ]);

  const nonEmptyNodesFilter = useCallback((node: IRowNode) => {
    return (node?.data?.actual && !isDataEmpty(node.data.actual)) ||
      (node?.data?.plan && !isDataEmpty(node.data.plan)) ||
      (node?.data?.total && !isDataEmpty(node.data));
  }, []);

  const doesExternalFilterPass = useCallback((node: IRowNode): boolean => {
    if (SHOW_ALWAYS_TYPES.includes(node.data?.type)) {
      return true;
    }

    const isInUnassignedFolder = node.data.filePath.includes(UNASSIGNED_ROW_NAME);
    if (!isInUnassignedFolder && showAll) {
      return true;
    }
    return nonEmptyNodesFilter(node);
  }, [ showAll ]);

  const isExternalFilterPresent = useCallback(() => {
    return true;
  }, []);

  return {
    isExternalFilterPresent,
    doesExternalFilterPass
  };
};

export default useGridFilter;
