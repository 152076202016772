import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { FeatureFlag } from '../types/auth.types';

const useFeatureFlag = () => {
  const featureFlags = useSelector((state: RootState) => state.auth.user?.featureFlags);

  const isFeatureEnabled = (flag: FeatureFlag): boolean => {
    return featureFlags.includes(flag);
  };

  return {
    isFeatureEnabled,
  };
};

export default useFeatureFlag;
