import React from 'react';
import { useChartContext } from 'components/charts/context/ChartContext';
import styles from './BigNumberIndicator.module.scss';
import { separateThousands } from 'utils/financials.utils';
import { Tooltip } from 'antd';

const BigNumberIndicator = () => {
  const { state: { hoverValue, tooltipText } } = useChartContext();
  const value = hoverValue ? separateThousands(hoverValue) : 0;

  return (
    <Tooltip
      title={ tooltipText }
      placement='bottom'
    >
      <div className={ styles.bigNumber }>
        { value }
        <span>&euro;</span>
      </div>
    </Tooltip>
  );
};

export default BigNumberIndicator;
