import Cookies from 'js-cookie';
import { history } from 'utils/history.utils';
import { routes } from 'utils/config.utils';

const TOKEN_KEY = '__token__';
const REFRESH_TOKEN_KEY = '__refresh_token__';
const REMEMBER_KEY = '__remember__';
const ACCESS_DAYS_PERIOD = 0.041666667;
const REFRESH_DAYS_PERIOD = 1;

const ORGANIZATION_KEY = '__org__';
const BUDGET_KEY = '__budget__';

const setRememberCookie = (): string =>
  Cookies.set(REMEMBER_KEY, 'true', { expires: ACCESS_DAYS_PERIOD });

const getRememberCookie = (): string | null => Cookies.get(REMEMBER_KEY);
const removeRememberCookie = (): void => Cookies.remove(REMEMBER_KEY);

const getToken = (): string | null => Cookies.get(TOKEN_KEY);
const getRefreshToken = (): string | null => Cookies.get(REFRESH_TOKEN_KEY);

const setTokens = (token: string, refreshToken: string, shouldSave?: boolean): void => {
  const shouldRemember = shouldSave || getRememberCookie();
  Cookies.set(TOKEN_KEY, token, {
    expires: shouldRemember ? ACCESS_DAYS_PERIOD : null
  });
  Cookies.set(REFRESH_TOKEN_KEY, refreshToken, {
    expires: shouldRemember ? REFRESH_DAYS_PERIOD : null
  });
};

const setActiveOrganizationIdCookie = (organizationId: string | number): void => {
  Cookies.set(ORGANIZATION_KEY, organizationId.toString(), { expires: null });
};

const getActiveOrganizationIdCookie = (): string | null => Cookies.get(ORGANIZATION_KEY);

const setActiveBudgetIdCookie = (budgetId: string | number): void => {
  Cookies.set(BUDGET_KEY, budgetId.toString(), { expires: null });
};

const getActiveBudgetIdCookie = (): string | null => Cookies.get(BUDGET_KEY);

const removeTokens = (): void => {
  Cookies.remove(TOKEN_KEY);
  Cookies.remove(REFRESH_TOKEN_KEY);
};

const handleLogin = (token: string, refreshToken: string, shouldSave: boolean) => {
  shouldSave ? setRememberCookie() : removeRememberCookie();
  setTokens(token, refreshToken, shouldSave);
};

const handleLogout = () => {
  removeTokens();
  removeRememberCookie();
  history.push(routes.login);
  location.reload();
};

export {
  getToken, getRefreshToken, setTokens, removeTokens,
  setRememberCookie, getRememberCookie, removeRememberCookie,
  handleLogout, handleLogin, getActiveOrganizationIdCookie, setActiveOrganizationIdCookie,
  setActiveBudgetIdCookie, getActiveBudgetIdCookie
};
