import React from 'react';
import { useTranslation } from 'react-i18next';
import WarningModal from 'components/elements/modals/warnings/WarningModal';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  amount: number;
  okText?: string;
}

const WarnBeforeExitModal = ({ isVisible, onClose, onConfirm, amount, okText }: Props) => {
  const [ t ] = useTranslation('common');

  return <WarningModal
    isVisible={ isVisible }
    onClose={ onClose }
    onConfirm={ onConfirm }
    title={ t('delete') }
    description={ t('warnings.delete-items', { amount }) }
    okText={ okText }
  />;
};

export default WarnBeforeExitModal;
