import React from 'react';
import styles from '../DynamicColumnModal.module.scss';
import { useTranslation } from 'react-i18next';
import { DynamicColumnSettings } from '../../../../../types/financials.types';

interface Props {
  title: string;
  chooseOption: string;
  width: number;
  optionList: DynamicColumnSettings[];
  setChooseOption: React.Dispatch<React.SetStateAction<string>>;
  bold?: boolean;
}

const SettingsComponent = (
  {
    optionList,
    chooseOption,
    setChooseOption,
    title,
    width,
    bold
  }: Props
) => {

  const [ t ] = useTranslation('dynamicColumns');

  return (
    <div className={ `${ styles.settingsContainer }` } style={ { width } }>
      <div className={ styles.title }>
        { title }
      </div>
      <div className={ styles.optionList }>
        {
          optionList.map(el =>
            <div key={ el.id } className={ styles.optionContainer }>
              <div
                onClick={ () => setChooseOption(el.value) }
                className={
                  `${ styles.option } ${ el.value === chooseOption ? styles.isActive : '' }`
                }
                style={ { fontWeight: bold ? 500 : 400 } }
              >
                { t(el.name) }
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default SettingsComponent;
