import { useMemo } from 'react';

export interface Abort {
  signal: AbortSignal;
  controller: AbortController;
}

const useAbort = (): Abort => {
  const controller = useMemo(() => new AbortController(), []);
  const signal = useMemo(() => controller.signal, []);
  return {
    signal,
    controller
  };
};

export default useAbort;