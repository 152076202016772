import { defaults } from 'components/commandBar/context/CommandBarContext';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import useSelectDetailedView from 'components/financials/detailedView/hooks/useSelectDetailedView';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  clearRightPanel,
  selectActive,
  selectRightPanel,
  setRightPanel
} from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DetailedViewType } from 'types/financials.types';

const chatButton = defaults.find((button) => button.key === 'chat');

interface Props {
  disabled: boolean;
}

const useChatbotCommandBar = ({ disabled = false }: Props) => {
  const [ t, i18n ] = useTranslation('financials');
  const dispatch = useAppDispatch();
  const activeTable = useAppSelector(selectActive);
  const rightPanel = useAppSelector(selectRightPanel);
  const { onSelect } = useSelectDetailedView({
    templateId: activeTable?.templateId,
    type: DetailedViewType.FLOATING_PANEL
  });

  const chatBotState: CommandBarUtil[] = useMemo(() => {
    const enabled = activeTable?.templateId != null && activeTable?.type === 'table';
    return [ {
      ...chatButton,
      type: 'button',
      hidden: false,
      newBadge: true,
      disabled: !enabled || disabled,
      icon: chatButton.icon,
      active: rightPanel != null,
      tooltip: t('common:command-bar.tooltip.chat'),
      onClick: () => {
        if (rightPanel) {
          dispatch(clearRightPanel());
        } else {
          dispatch(setRightPanel(activeTable?.templateId));
          onSelect(null);
        }
      }

    } ];
  }, [ activeTable, rightPanel, disabled, i18n.language ]);

  return useCommandBarEffect({ utils: chatBotState });
};

export default useChatbotCommandBar;
