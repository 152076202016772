import React, { useMemo } from 'react';

import { Select, Tooltip } from 'antd';
import DimensionViewLabel
  from 'components/elements/dimensionLeftPanel/listView/dimensionViewLabel/DimensionViewLabel';
import { SearchableSelectWithoutSearchCtx }
  from 'components/elements/searchableSelect/SearchableSelect';
import { useSearchable, SearchableContextProvider } from 'context/SearchableContext';
import { useTranslation } from 'react-i18next';
import { Dimension } from 'types/filterTable.types';
import { getDisplayName } from 'utils/common.utils';
import { isDimensionDisabled } from '../../split.utils';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import styles from '../DimensionSplit.module.scss';

interface DimensionSearchableSelectProps {
  dimensions: Dimension[];
  onChange: (value: string) => void;
  value: number;
  index: number;
  autoClearSearchValue?: boolean;
}

const DimensionSearchableSelect = ({ dimensions, onChange, value, index, autoClearSearchValue }:
DimensionSearchableSelectProps) => {
  const [ t ] = useTranslation('financials');

  const { state: { search } } = useSearchable();

  const filteredOptions = useMemo(() => {
    if (search === '') return dimensions;
    return dimensions?.filter((option) => {
      if (typeof getDisplayName(option.customName) === 'string') {
        return getDisplayName(option.customName).toLowerCase()
          .includes(search.trim().toLowerCase());
      } else {
        return true;
      }
    });
  }, [ dimensions, search ]);

  return <SearchableSelectWithoutSearchCtx
    id={ 'dimension_' + index }
    className={ styles.searchableSelect }
    showAction={ [ 'click', 'focus' ] }
    defaultValue={ value }
    autoClearSearchValue={ autoClearSearchValue }
    optionLabelProp='children'
    placeholder={ t('dimension-split.modal.select-dimension') }
    getPopupContainer={
      () => document.getElementById('dimension_' + index + '_cont') as HTMLElement
    }
    popupClassName='ag-custom-component-popup'
    onChange={ onChange }            
  >
    <>
      {
        filteredOptions.map((option) => {
          const isDisabled = !isDimensionDisabled(option);
          return <Select.Option
            key={ option.id }
            className={ styles.selectOption }
            disabled={ isDisabled }
            value={ option.id }>
            <span className={ styles.disabledLabel }>{ getDisplayName(option.customName) }</span>
            <span className={ styles.disabledInfo }>
              <DimensionViewLabel dimension={ option } />
              { isDisabled ?
                // TODO: only external tooltip
                <Tooltip
                  title={ t('dimension-split.modal.external-dimension') }
                >
                  <InfoIcon className={ styles.infoIcon } />
                </Tooltip> : null
              }
            </span>
          </Select.Option>;
        })
      }
    </>
  </SearchableSelectWithoutSearchCtx>;
};

const SearchableWrapper = ({ ...props }: DimensionSearchableSelectProps) => {
  return <SearchableContextProvider>
    <DimensionSearchableSelect { ...props } />
  </SearchableContextProvider>;
};

export default SearchableWrapper;
