import { Layout } from 'react-grid-layout';
import {
  ChartElement,
  DashboardElement,
  DashboardElementType,
  DatasetSettings,
  DEFAULT_STACKS,
} from 'types/dashboard.types';
import { ChartSettings, ChartSource, ChartStyle } from 'types/chart.types';
import Cookies from 'js-cookie';
import { formatUnixDate } from './period.utils';
import { Period } from 'types/financials.types';
import { UUID } from 'types/templates.types';

export const COLS_WIDTH = 6;
export const ROW_HEIGHT = 64;
const HEIGHT_MULTIPLIER = 2;
export const NEW_ELEMENT_ID = '__dropping-elem__';
export const DASHBOARD_PANEL_ID = 'dashboard-panel';
export const DASHBOARD_PERIOD_ID = -99;
const UNLIMITED_HEIGHT = 100 * HEIGHT_MULTIPLIER;
export const CHART_DATASETS = 4;

export const getDefaultChartDatasets = (amount: number = CHART_DATASETS, activeFirst = false) => {
  return Array.from( { length: amount }).map((_el, index) => {
    return {
      templateNode: null,
      active: activeFirst && index === 0,
      template: null,
      style: ChartStyle.BAR,
      datePickerSource: ChartSource.BOTH,
      stacks: DEFAULT_STACKS,
      axisPosition: 'left' as const,
      reverseValues: false,
    };
  });
};

type Boundary = {
  minH: number;
  minW: number;
  maxW: number;
  maxH: number;
  defaultW?: number;
  defaultH?: number;
};

export const boundaries: { [ key in DashboardElementType ]: Boundary} = {
  [ DashboardElementType.BIG_NUMBER ]: {
    minH: HEIGHT_MULTIPLIER,
    minW: 1,
    maxW: 2,
    maxH: HEIGHT_MULTIPLIER,
  },
  [ DashboardElementType.CHART ]: {
    minH: 2 * HEIGHT_MULTIPLIER,
    minW: 2,
    maxW: COLS_WIDTH,
    maxH: 5 * HEIGHT_MULTIPLIER,
  },
  [ DashboardElementType.TITLE ]: {
    minH: 1,
    minW: COLS_WIDTH,
    maxW: COLS_WIDTH,
    maxH: 1,
  },
  [ DashboardElementType.SUBTITLE ]: {
    minH: 1,
    minW: COLS_WIDTH,
    maxW: COLS_WIDTH,
    maxH: 1,
  },
  [ DashboardElementType.TABLE ]: {
    minH: 3 * HEIGHT_MULTIPLIER,
    maxH: UNLIMITED_HEIGHT,
    minW: 2,
    maxW: COLS_WIDTH,
    defaultH: 5 * HEIGHT_MULTIPLIER,
    defaultW: COLS_WIDTH,
  }
};

export const elementToLayout = (element: DashboardElement, isStatic: boolean): Layout => ({
  i: element.id.toString(),
  x: element.x,
  y: element.y,
  w: element.width,
  h: element.height,
  ...boundaries[ element.type ],
  static: isStatic
});

export const mapElementsToLayout = (elements: DashboardElement[], isStatic: boolean): Layout[] => {
  return elements.map((element) => {
    return elementToLayout(element, isStatic);
  });
};

export const isBigNumberElement =
  (node: DashboardElement): node is DashboardElement & {
    type: DashboardElementType.BIG_NUMBER;
  } => {
    return node.type === DashboardElementType.BIG_NUMBER;
  };

export const isChartElement =
  (node: DashboardElement): node is DashboardElement & { type: DashboardElementType.CHART } => {
    return node.type === DashboardElementType.CHART;
  };

export const isTitleElement =
(node: DashboardElement): node is DashboardElement & {
  type: DashboardElementType.TITLE | DashboardElementType.SUBTITLE;
} => {
  return node.type === DashboardElementType.TITLE || node.type === DashboardElementType.SUBTITLE;
};

export const mapChartElementToSettings = (element: ChartElement): ChartSettings => {
  // Get elements that exists in the backend, and fill the rest with default values up to 4 datasets
  const defaultsToCreate = CHART_DATASETS - (element.datasets?.length || 0);
  const defaults = getDefaultChartDatasets(
    defaultsToCreate, defaultsToCreate === CHART_DATASETS);

  return {
    datasets: [ ...element.datasets, ...defaults ],
    other: {
      legend: element.legend,
      dataLabels: {
        bar: element.barLabels,
        stacked: element.stackedLabels,
      },
      cumulativeValues: element.cumulativeValues,
      leftAxisFormat: element.leftAxisFormat,
      rightAxisFormat: element.rightAxisFormat,
    }
  };
};

const LATEST_DASHBOARD_KEY = '__latest_dashboard__';

export const setLatestDashboardIdCookie = (id: number | string) => {
  Cookies.set(LATEST_DASHBOARD_KEY, id.toString(), { expires: null });
};

export const getLatestDashboardIdCookie = (): string | null => Cookies.get(LATEST_DASHBOARD_KEY);

export const getDateParams = (period: Period) => ({
  startDate: formatUnixDate( period.startDate),
  endDate: formatUnixDate(period.endDate),
  cadence: period.cadence
});

export const getDatasetTemplateNodeUuids = (datasets: DatasetSettings[]) =>
  datasets
    .filter(dataset => dataset.templateNode)
    .map(dataset => dataset.templateNode);

export const getChartElements = (elements: DashboardElement[]) =>
  elements.filter(isChartElement).map(({ element }) => element);

/**
 * Get a mapping of template ID to a set of template node UUIDs used by all charts in the dashboard
 */
export const getPerTemplateNodeUuids = (elements: ChartElement[]) => {
  const perTemplateNodes: Record<number, Set<UUID>> = {};
  for (const element of elements) {
    if (!perTemplateNodes[ element.template ]) {
      perTemplateNodes[ element.template ] = new Set();
    }
    const elementTemplateNodes = element.datasets
      .map(dataset => dataset.templateNode)
      .filter(Boolean);
    for (const node of elementTemplateNodes) {
      perTemplateNodes[ element.template ].add(node);
    }
  }
  return perTemplateNodes;
};
