/* eslint-disable no-undef */
import { EnvironmentType } from 'types/app.types';

export const getProcountorIntegrationUrl =
  (redirectUrl: string, state?: string) => { 
    const params = {
      'response_type': 'code',
      'client_id': process.env.REACT_APP_PROCOUNTOR_CLIENT_ID,
      'redirect_uri':  
        process.env.REACT_APP_ENVIRONMENT === EnvironmentType.local ? 
          '' : redirectUrl,
      state: state
    };

    return process.env.REACT_APP_PROCOUNTOR_URL + '?' + 
        new URLSearchParams(params).toString();
  };