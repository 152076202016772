import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks/hooks';
import { setActiveBudgetIdCookie, setActiveOrganizationIdCookie } from 'utils/auth.utils';
import FlatSelect from '../flatSelect/FlatSelect';
import { setLatestDashboardIdCookie } from 'utils/dashboard.utils';

const OrganizationSelect = () => {
  const organizations = useAppSelector(state => state.auth.user.organizations);
  const organization = useAppSelector(state => state.auth.organization);

  const options = useMemo(() => {
    return organizations.map(org => ({
      label: org.name,
      value: org.id
    }));
  }, [ organizations ]);

  const onOrganizationChange = useCallback((org: number) => {
    setActiveOrganizationIdCookie(org);
    setActiveBudgetIdCookie('');
    setLatestDashboardIdCookie('');
    window.location.reload();
  }, []);

  return (
    <FlatSelect
      options={ options }
      defaultValue={ organization?.id }
      onChange={ onOrganizationChange }
    />
  );
};

export default OrganizationSelect;
