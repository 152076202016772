import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { useAppSelector } from 'store/hooks/hooks';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import { CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import { useTranslation } from 'react-i18next';
import { selectMode } from 'store/dashboard.slice';
import { DashboardModeType } from 'types/dashboard.types';

interface Props {
  templateId: number;
  gridRef: React.MutableRefObject<AgGridReact>;
}

const exportUtil = defaults.find(u => u.key === 'export');

const useGridCommandBar = ({ templateId, gridRef }: Props) => {
  const [ t ] = useTranslation('financials');
  const isTableActive = useAppSelector(state => {
    const active = state.financials.active;
    return active?.templateId === templateId && active?.type === 'table';
  });
  const workspaceMode = useAppSelector(selectMode);
  const exportButton: CommandBarUtil = useMemo(() => {
    return {
      ...exportUtil,
      disabled: workspaceMode === DashboardModeType.EDIT,
      hidden: false,
      tooltip: t('common:command-bar.tooltip.export'),
      type: 'button-dropdown',
      options: [
        {
          label: t('command-bar.export-to-excel'),
          type: 'default',
          onClick: () => gridRef.current.api.exportDataAsExcel(),
        },
        {
          label: t('command-bar.export-to-csv'),
          type: 'default',
          onClick: () => gridRef.current.api.exportDataAsCsv(),
        }
      ]
    };
  }, [ workspaceMode ]);

  const utilsToUpdate: CommandBarUtil[] = useMemo(() => {
    if (!isTableActive) {
      return [];
    }
    return [ exportButton ];
  }, [ isTableActive, exportButton ]);

  return useCommandBarEffect({
    utils: utilsToUpdate,
    hidden: false,
    clearOnUnmount: false
  });
};

export default useGridCommandBar;