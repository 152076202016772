import { notifyError } from './notifications.utils';
import { t } from 'i18next';

export const handleError = async (callback: () => void) => {
  try {
    await callback();
  } catch (e) {
    if (e.response?.data?.nonFieldErrors)
      notifyError(e.response.data.nonFieldErrors);
    else
      notifyError(
        t('notifications.unexpected-error.message',
          { ns: 'common' }
        )
      );
    throw e;
  }
};
