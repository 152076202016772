import dayjs from 'dayjs';
import { t } from 'i18next';
import { ChartSettings, ChartStyle } from 'types/chart.types';
import { Cadence } from 'types/form.types';
import { ReportType } from 'types/templates.types';
import { numberFormatter } from 'utils/common.utils';

export const dateFormatter = (date: string, cadence: Cadence) => {
  switch (cadence) {
    case Cadence.year:
      return dayjs(date).format('YYYY');
    case Cadence.quarter:
      return dayjs(date).format("[Q]Q [']YY");
    case Cadence.month:
      return dayjs(date).format("MMM [']YY");
    case Cadence.week:
      return dayjs(date).format("[W]W [']YY");
  }  
};

export const xAxisFormatter = (date: string, cadence: Cadence) => {
  return dateFormatter(date, cadence);
};

export const yAxisFormatter = (value: number, axisIndex: number, settings: ChartSettings) => {
  const axisPosition = {
    0: 'left',
    1: 'right',
  };
  const position = axisPosition[ axisIndex ];
  const axisFormat = settings.other[ `${ position }AxisFormat` ];
  if (axisFormat === 'percentage') {
    return `${ value } %`;
  }
  return numberFormatter(value, axisFormat === 'currency');
};

export const getReportTypeFromSeries = (seriesName: string) => {
  return seriesName.split('__')[ 0 ];
};

export const getDatasetIndexFromSeries = (seriesName: string): number => {
  return +seriesName.split('__')[ 1 ];
};

export const formatSeriesName = (name: string) => {
  const indexOfBreak = name.lastIndexOf('__');
  const type = getReportTypeFromSeries(name);
  const suffix = type === ReportType.ACTUAL ?
    t('financials:actuals') : t('financials:budget');
  return `${ name.slice(indexOfBreak + 2) } / ${ suffix }`;
};

export const isChartStacked = (style: ChartStyle) => {
  return [ ChartStyle.PERCENTAGE, ChartStyle.STACKED ].includes(style);
};

export const getSeriesFormat = (settings: ChartSettings, datasetIndex: number) => {
  const datasetAxisPosition = settings.datasets[ datasetIndex ].axisPosition;
  const datasetAxisFormat = settings.other[ `${ datasetAxisPosition }AxisFormat` ];
  return datasetAxisFormat;
};
