import { getUUID } from 'utils/templates.utils';
import {
  ChatStore,
  ChatTask,
  InteractionMessage,
  Message,
  MessageSource,
  MessageType
} from './../types/chat.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { UUID } from 'types/templates.types';

const initialMessage: Message = {
  id: getUUID(),
  text: t('collaboration:chat.initialMessage'),
  source: MessageSource.SYSTEM,
  type: MessageType.MESSAGE,
};

const consentMessage: InteractionMessage = {
  id: getUUID(),
  text: t('collaboration:chat.consentMessage'),
  source: MessageSource.SYSTEM,
  type: MessageType.QUESTION,
  actions: [
    {
      id: getUUID(),
      text: t('collaboration:chat.consentAction'),
      type: 'primary',
      action: 'consent'
    },
    {
      id: getUUID(),
      type: 'ghost',
      text: t('collaboration:chat.declineAction'),
      action: 'declineConsent'
    }
  ]
};

const initialState: ChatStore = {
  sessionId: getUUID(),
  history: [],
  tasks: [],
  isConsentGiven: false
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      state.history.push(action.payload);
    },
    setMessage: (state, action: PayloadAction<Message>) => {
      const index = state.history.findIndex((message) => message.id === action.payload.id);
      state.history[ index ] = action.payload;
    },
    clearHistory: (state) => {
      state.history = [ getInitialMessage(state.isConsentGiven) ];
      state.sessionId = getUUID();
    },
    resetSession: (state) => {
      state.sessionId = getUUID();
    },
    addTask: (state, action: PayloadAction<ChatTask>) => {
      state.tasks.push(action.payload);
    },
    removeTask: (state, action: PayloadAction<UUID>) => {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload);
    },
    setConsent: (state, action: PayloadAction<boolean>) => {
      state.history = [ getInitialMessage(action.payload) ];
      state.isConsentGiven = action.payload;
    }
  }
});

const getInitialMessage = (isConsentGiven: boolean): Message => {
  return isConsentGiven ? initialMessage : consentMessage;
};

export const chatReducer = chatSlice.reducer;
