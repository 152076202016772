import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/elements/card/Card';
import CustomTemplate from './customTemplate/CustomTemplate';

import styles from './Templates.module.scss';
import useDragTag from 'hooks/useDragTag';
import { fetchAndSetSystemTemplate, fetchAndSetTemplateList } from 'store/templates.slice';
import { TemplatesContextProvider } from 'context/TemplatesContext';
import FormulaBuilderSlider from './formulaBuilderSlider/FormulaBuilderSlider';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import TempBuilderHeaderTitle from './tempBuilderHeaderTitle/TempBuilderHeaderTitle';
import FinancialBottomHeader from './bottomHeader/FinancialBottomHeader';
import { TemplateSections, UserTemplateShort } from 'types/templates.types';
import SearchBar from 'components/elements/searchBar/SearchBar';
import TemplateBuilderDimensionList
  from './templateBuilderDimensionList/TemplateBuilderDimensionList';
import ResizableContainer from '../elements/resizableContainer/ResizableContainer';
import { getLastTemplateId } from '../../utils/template.utils';
import { routes } from '../../utils/config.utils';
import useTemplateService from './hooks/useTemplateService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { selectTemplate } from '../../store/template.slice';
import { topBarSlice } from 'store/topBar.slice';
import useCommandBarDefaults from 'components/commandBar/hooks/useCommandBarDefaults';
import DeleteTemplateModal from 'components/templates/deleteTemplateModal/DeleteTemplateModal';
import NewTemplateModal from 'components/templates/newTemplateModal/NewTemplateModal';
import useTemplateCommandBar from 'components/templates/hooks/useTemplateCommandBar';
import { clearCustomFormula, selectCustomFormula } from 'store/formula.slice';
import { SearchableContextProvider } from 'context/SearchableContext';

const DEFAULT_WIDTH = 480;

const Templates = () => {
  const [ t ] = useTranslation('navigation');
  const { isLoading, treeTags } = useDragTag();
  const dispatch = useAppDispatch();
  const customFormula = useAppSelector(selectCustomFormula);
  const activeTemplate = useAppSelector(selectTemplate);
  const areTemplatesFetched = useAppSelector((state) => {
    return state.templates.templateList.length > 0 &&
      state.templates.systemTemplateList.length > 0;
  });
  const [ widthPanel, setWidthPanel ] = useState(DEFAULT_WIDTH);
  const [ heightPanel, setHeightPanel ] = useState('calc(100vh - 60px)');
  const [ activeSection, setActiveSection ] =
    useState<TemplateSections>(TemplateSections.DIMENSIONS);
  const [ isLoadingTemplate, setIsLoadingTemplate ] = useState(false);
  const { handleTemplateChange } = useTemplateService();
  const { id: defaultTemplateId } = useParams();
  const initialLoad = useRef(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [ newTemplateVisible, setNewTemplateVisible ] = useState(false);
  const [ templateToDelete, setTemplateToDelete ] = useState<UserTemplateShort>();
  useCommandBarDefaults({ keys: [ 'cta' ], mode: 'show' });
  useTemplateCommandBar({
    onCreateNewTemplate: () => setNewTemplateVisible(true),
    onDeleteTemplate: setTemplateToDelete
  });

  useEffect(() => {
    if (areTemplatesFetched) return;
    dispatch(fetchAndSetTemplateList());
    dispatch(fetchAndSetSystemTemplate());
  }, []);

  useEffect(() => {
    if (!areTemplatesFetched) return;

    const lastTemplateId = Number(getLastTemplateId());
    const isLastTemplateProvided = lastTemplateId != null && lastTemplateId > 0;
    let redirectTemplateId = null;
    if (defaultTemplateId) {
      redirectTemplateId = +defaultTemplateId;
    } else if (isLastTemplateProvided) {
      redirectTemplateId = lastTemplateId;
    }

    if (redirectTemplateId) {
      setIsLoadingTemplate(true);
      handleTemplateChange(redirectTemplateId, true).finally(() => {
        setIsLoadingTemplate(false);
      });
    }
  }, [ areTemplatesFetched ]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    const path = activeTemplate.id ?
      routes.report.templateBuilder + '/' + activeTemplate.id : routes.report.templateBuilder;

    if (location.pathname !== path)
      navigate(path);
  }, [ activeTemplate ]);

  useEffect(() => {
    dispatch(
      topBarSlice.actions.setTitle(t('links.report.template-builder', { ns: 'navigation' }) )
    );
    return () => {
      dispatch(clearCustomFormula());
      dispatch(topBarSlice.actions.clear());
    };
  }, []);

  return (
    <TemplatesContextProvider>
      <div className={ styles.templates }>
        <div className={ styles.content }>
          <FormulaBuilderSlider
            isLoading={ isLoading || isLoadingTemplate }
            treeTags={ treeTags }
            widthLeftPanel={ widthPanel }
            setHeightPanel={ setHeightPanel }
            heightPanel={ heightPanel }
          />
          <ResizableContainer
            dimensionToResize='width'
            minSize={ 350 }
            maxSize={ 700 }
            defaultSize={ widthPanel }
            onSizeChange={ setWidthPanel }
          >
            <SearchableContextProvider>
              <Card className={ styles.left }>
                <TempBuilderHeaderTitle/>
                <SearchBar
                  className={ styles.searchBox }
                  showSeparator={ false }
                  showResults={ false }
                />
                <FinancialBottomHeader
                  activeSection={ activeSection }
                  setActiveSection={ setActiveSection }
                />
                <TemplateBuilderDimensionList
                  isLoading={ isLoading || isLoadingTemplate }
                  activeSection={ activeSection }
                  setActiveSection={ setActiveSection }
                  treeTags={ treeTags }
                />
              </Card>
            </SearchableContextProvider>
          </ResizableContainer>
          <Card className={
            `${ styles.right } ${ customFormula !== null ? styles.openFormula : '' }` }
          >
            <CustomTemplate onDeleteTemplate={ setTemplateToDelete }/>
          </Card>
        </div>
      </div>
      <DeleteTemplateModal
        template={ templateToDelete }
        onClose={ () => {
          setTemplateToDelete(null);
          navigate(routes.report.templateBuilder);
        } }
      />
      <NewTemplateModal
        isVisible={ newTemplateVisible }
        onClose={ () => setNewTemplateVisible(false) }
      />
    </TemplatesContextProvider>
  );
};

export default Templates;
