import React, { useMemo } from 'react';
import SearchBar from '../../searchBar/SearchBar';
import Capsule from '../../capsule/Capsule';
import styles from './TopBar.module.scss';
import { useTranslation } from 'react-i18next';
import { DropdownData, DropdownGroup } from '../types/sectionDropdown.types';

interface Props<T extends DropdownData> {
  activeGroupKey: string;
  groups: DropdownGroup<T>[];
  setGroup: (group: string) => void;
  total: number;
  title?: React.ReactNode;
}

const TopBar = <T extends DropdownData>({
  groups,
  setGroup,
  total,
  activeGroupKey,
  title
}: Props<T>) => {
  const [ t ] = useTranslation('financials');

  const totalTitle = useMemo(() => {
    return `${ t('template-dropdown.total') } ${ total }`;
  }, [ total ]);

  return <>
    <div className={ styles.top }>
      { title ?? totalTitle }
    </div>
    <SearchBar showSeparator={ false } showResults={ false } autoFocus={ true } />
    <div role='sections' className={ styles.sections }>
      {
        groups.map((group) => (
          <Capsule
            key={ group.key }
            isActive={ group.key === activeGroupKey }
            onClick={ () => setGroup(group.key) }
            aria-hidden={ group.hidden }
            className={ group.hidden ? styles.hidden : '' }
          >
            {
              group.icon ? <span className={ styles.capsuleIcon }>{ group.icon }</span> : null
            }
            { group.hidden }
            { group.label }
          </Capsule>
        ))
      }
    </div>
  </>;
};

export default TopBar;
