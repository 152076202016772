import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { PanelType } from 'types/app.types';
import type { CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { appSlice } from 'store/app.slice';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import useCommandBarDefaults from 'components/commandBar/hooks/useCommandBarDefaults';

const sort = defaults.find(util => util.key === 'sort');
const columns = defaults.find(util => util.key === 'columns');

const useLabelingRulesCommandBar = () => {
  const [ t, i18n ] = useTranslation('common');

  const [ utilsState, setUtilsState ] = useState<CommandBarUtil[]>([]);
  const dispatch = useAppDispatch();
  const activePanelType = useAppSelector(store => store.app.leftPanel);

  useCommandBarDefaults({ keys: [ 'sort', 'columns' ], mode: 'show' });

  const onButtonClick = useCallback((key: PanelType) => {
    if (activePanelType === key) {
      return dispatch(appSlice.actions.setLeftPanel(null));
    } else {
      dispatch(appSlice.actions.setLeftPanel(key));
    }
  }, [ activePanelType, dispatch ]);

  useEffect(() => {
    setUtilsState([
      {
        key: 'sort',
        type: 'button',
        active: activePanelType === 'sort',
        hidden: false,
        disabled: false,
        placement: 'left',
        tooltip: t('command-bar.tooltip.sort'),
        icon: sort.icon,
        onClick: () => onButtonClick('sort')
      },
      {
        key: 'columns',
        type: 'button',
        active: activePanelType === 'columns',
        hidden: false,
        disabled: false,
        placement: 'left',
        tooltip: t('command-bar.tooltip.columns'),
        icon: columns.icon,
        onClick: () => onButtonClick('columns')
      }
    ]);
  }, [ i18n.language, activePanelType, onButtonClick ]);

  useCommandBarEffect({ utils: utilsState });
  return undefined;
};

export default useLabelingRulesCommandBar;