import { Divider, Space, Dropdown } from 'antd';
import Modal from 'components/elements/modal/Modal';
import React, { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import dashboardService from 'services/dashboard.service';
import { dashboardSlice } from 'store/dashboard.slice';
import styles from './WorkspaceDropdown.module.scss';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import SearchBar from 'components/elements/searchBar/SearchBar';
import { useForm } from 'react-hook-form';
import Input from 'components/elements/input/Input';
import { notifyError } from 'utils/notifications.utils';
import { useTranslation } from 'react-i18next';
import { getDateParams, setLatestDashboardIdCookie } from 'utils/dashboard.utils';
import { useSearchable } from 'context/SearchableContext';
import { Dashboard, DashboardModeType } from 'types/dashboard.types';
import { selectViewPeriod } from 'store/topBar.slice';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';

export const WorkspaceDropdown = () => {
  const {
    dashboards,
    activeDashboardId,
    activeDashboard
  } = useAppSelector((state) => state.dashboard);
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ isEditModalVisible, setIsEditModalVisible ] = useState(false);
  const { control, watch, reset, formState: { errors } } = useForm();
  const [ dashboardId, setDashboardId ] = useState(undefined);
  const period = useAppSelector(selectViewPeriod);
  const dispatch = useAppDispatch();
  const name = watch('name');
  const [ t ] = useTranslation();
  const [ isDropdownOpened, setIsDropdownOpened ] = useState(false);
  const { state: { search } } = useSearchable();

  const dashboardsOptions = useMemo(() => {
    return dashboards
      .map((dashboard) => ({ key: dashboard.id, label: `${ dashboard.name }` }));
  }, [ dashboards ]);

  // Called when position in dropdown is changed and when new dashboard is added
  const onChangeDashboard = useCallback((e: number, newDashboard?: Dashboard) => {
    if (activeDashboardId === e) return;
    dispatch(dashboardSlice.actions.clear());

    if (newDashboard) {
      dispatch(dashboardSlice.actions.setActiveDashboard({ ...newDashboard, elements: [] }));
      dispatch(dashboardSlice.actions.setMode(DashboardModeType.EDIT));
    } else {
      dashboardService.getDashboard(e, getDateParams(period))
        .then((res) => {
          dispatch(dashboardSlice.actions.setActiveDashboard(res.data));
          dispatch(dashboardSlice.actions.setElements(res.data.elements));
          if (res.data.elements.length === 0) {
            dispatch(dashboardSlice.actions.setMode(DashboardModeType.EDIT));
          }
        });
    }

    setIsDropdownOpened(false);
    setLatestDashboardIdCookie(e);
  }, [ activeDashboardId, period ]);

  const onAddDashboard = useCallback((newDashboard: Dashboard) => {
    dispatch(dashboardSlice.actions.addDashboard(newDashboard));
    onChangeDashboard(newDashboard.id, newDashboard);
  }, []);

  const addWorkspaceHandler = useCallback(() => {
    dashboardService.addDashboard(name)
      .then((res) => {
        reset({ name: '' });
        onAddDashboard(res.data);
      })
      .catch((err) => {
        if (err.response.data.nonFieldErrors) {
          notifyError('Workspace name must be unique');
        }
      });
  }, [ name ]);

  const editWorkspaceHandler = useCallback(() => {
    dashboardService.changeDashboardName(dashboardId, name)
      .then((res) => {
        const newDashboardDetails = res.data;
        dispatch(dashboardSlice.actions.setDashboards(dashboards.map(d => {
          if (d.id === dashboardId) {
            return { ...d, name: res.data.name };
          }
          return d;
        })));
        dispatch(dashboardSlice.actions.setActiveDashboard(
          { ...activeDashboard, ...newDashboardDetails, }));
      })
      .catch((err) => {
        if (err.response.data.nonFieldErrors) {
          notifyError(t('dashboard:workspace.modal.error'));
        }
      });

  }, [ dashboardId, name ]);

  const activeDashboardName = useMemo(() => {
    return activeDashboard?.name;
  }, [ activeDashboardId, activeDashboard ]);

  const filteredOptions = useMemo(() => {
    if (!search) {
      return dashboardsOptions;
    }
    return dashboardsOptions?.filter((option) => {
      return option.label.toLowerCase().includes(search.trim().toLowerCase());
    });
  }, [ search, dashboardsOptions ]);

  return (
    <>
      <div>
        {
          activeDashboardId && activeDashboardName ?
            <Dropdown
              className={ styles.select }
              trigger={ [ 'click' ] }
              open={ isDropdownOpened && !isEditModalVisible && !isModalVisible }
              onOpenChange={ (e) => setIsDropdownOpened(e) }
              menu={ { items: dashboardsOptions } }
              dropdownRender={ () => (
                <div className={ styles.dropdown }>
                  <SearchBar
                    showSeparator={ true }
                    showResults={ false }
                    autoFocus={ true }
                  />
                  <Space style={ { padding: 7 } }>
                    <button
                      onClick={ () => {
                        setIsModalVisible(true);
                        setIsDropdownOpened(false);
                      } }
                      className={ styles.button }
                    >
                      <PlusIcon />
                      { t('dashboard:workspace.modal.add-new') }
                    </button>
                  </Space>
                  <Divider style={ { margin: '0 0 5px 0' } } />
                  {
                    filteredOptions.map((el) => {
                      return <div
                        onClick={ () => onChangeDashboard(el.key) }
                        className={ styles.option }
                        key={ el.key }>
                        <span
                        >
                          { el.label }
                        </span>
                        <button
                          onClick={ (e) => {
                            reset({ name: el.label });
                            setIsEditModalVisible(true);
                            setDashboardId(el.key);
                            setIsDropdownOpened(false);
                            e.stopPropagation();
                          }
                          }
                        >
                          <EditIcon />
                        </button>
                      </div>;
                    })
                  }
                </div>
              ) }
            >
              <div className={ styles.dropdownName }>
                { activeDashboardName }
                <ChevronDown/>
              </div>
            </Dropdown> : t('navigation:links.report.name-loading')
        }
      </div>
      <Modal
        className={ styles.modal }
        isVisible={ isModalVisible || isEditModalVisible }
        title={
          <div className={ styles.modalTitle }>
            {
              isModalVisible && t('dashboard:workspace.modal.create-new')
            }
            {
              isEditModalVisible && (
                <>
                  <EditIcon />
                  { t('dashboard:workspace.modal.edit-name') }
                </>
              )
            }
          </div>
        }
        destroyOnClose={ true }
        confirmDisabled={ !name }
        onConfirm={ () => {
          isModalVisible && addWorkspaceHandler();
          isEditModalVisible && editWorkspaceHandler();
        } }
        onClose={ () => {
          setIsEditModalVisible(false);
          setIsModalVisible(false);
          reset({ name: '' });
        } }
      >
        <Input
          control={ control }
          errors={ errors }
          name='name'
          placeholder='Workspace name'
        />
      </Modal>
    </>
  );
};
