import React, { useState } from 'react';
import { Select as AntdSelect } from 'antd';
import { ReactComponent as CheckItemIcon } from 'assets/icons/check-item.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/chevron-down.svg';

import styles from './Select.module.scss';
import { SelectValueType } from 'types/form.types';

const { Option } = AntdSelect;

interface Props {
  options: SelectValueType[];
  defaultValue: string;
  onSelect: (value: string) => void;
  isActive: (value: string) => boolean;
  noValueIdentifier?: string;
  showActiveStyles?: boolean;
  showSuffixIcon?: boolean;
  className?: string;
  prefix?: React.ReactNode;
  listHeight?: number;
}

const Select = ({
  options,
  defaultValue,
  onSelect,
  isActive,
  noValueIdentifier,
  showActiveStyles = true,
  showSuffixIcon = true,
  className = '',
  prefix,
  listHeight = 256,
}: Props) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ value, setValue ] = useState(defaultValue);

  const renderOption = (option: SelectValueType) => {
    return <span className={ `
    ${ styles.option }
    ${ isActive(value) && showActiveStyles ? styles.optionActive : '' }`
    }>
      <span className={ styles.icon }>
        { option.icon }
      </span>
      { option.label }
    </span>;
  };

  return (
    <AntdSelect
      className={ `${ styles.select }
      ${ isOpen ? styles.selectOpen : '' }
      ${ isActive(value) && showActiveStyles ? styles.selectActive : '' }
      ${ className }` }
      value={ value }
      showArrow={ true }
      listHeight={ listHeight }
      dropdownMatchSelectWidth={ false }
      bordered={ true }
      popupClassName={ `${ styles.selectDropdown }
        ${ isOpen ? styles.selectDropdownOpen : '' }` }
      onDropdownVisibleChange={ () => setIsOpen(current => !current) }
      onSelect={ (v) => {
        onSelect(v);
        setValue(curr => curr === v && noValueIdentifier ? noValueIdentifier : v);
      } }
      suffixIcon={ showSuffixIcon ? <ArrowDown
        className={ `${ styles.selectArrow } ${ isOpen ? styles.selectArrowOpen : '' }` }/> : null
      }
      optionLabelProp='label'
      menuItemSelectedIcon={ <CheckItemIcon/> }>
      {
        options.map((option) => (
          <Option
            value={ option.value }
            key={ option.value }
            label={ <div className={ styles.label }>
              { prefix }
              { renderOption(option) }
            </div> }
          >
            { renderOption(option) }
          </Option>
        ))
      }
    </AntdSelect>
  );
};

export default Select;
