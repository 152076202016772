import React from 'react';
import { Message, MessageSource } from 'types/chat.types';
import MessageBubble from
  'components/collaboration/chat/messages/history/message/messageBubble/MessageBubble';

interface Props {
  message: Message & {source: MessageSource.USER};
}

const UserMessage = ({ message }: Props) => {
  return <MessageBubble message={ message } />;
};

export default UserMessage;