import { useEffect, useState } from 'react';
import { setDimensions, setFinancialTags } from 'store/templates.slice';
import { TemplateNode, TemplateNodeType, TemplateSections, TreeTags } from 'types/templates.types';
import { createDimensionItemNode } from 'utils/template.utils';
import { formulasTags, getUUID, layoutTags } from 'utils/templates.utils';
import { StatutoryTemplate } from 'types/statutory.types';
import { RowType } from 'types/financials.types';
import statutoryService from '../services/statutory.service';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { Dimension } from 'types/filterTable.types';

const useDragTag = () => {
  const [ isLoading, setIsLoading ] = useState(true);
  const dimensions = useAppSelector(state => state.breakdowns.dimensions);

  const defaultValue = {
    [ TemplateSections.ALL ]: [],
    [ TemplateSections.FINANCIALS ]: [],
    [ TemplateSections.BALANCE_SHEET ]: [],
    [ TemplateSections.DIMENSIONS ]: [],
    [ TemplateSections.FORMULAS ]: formulasTags,
    [ TemplateSections.LAYOUT ]: layoutTags
  };
  const [ treeTags, setTreeTags ] = useState<TreeTags>(defaultValue);
  const dispatch = useAppDispatch();

  const processDimension = (d: Dimension): TemplateNodeType<RowType.BREAKDOWN> => {
    const childrenNodes = d.items.map(createDimensionItemNode);
    return {
      id: d.id,
      uuid: getUUID(),
      children: d.items.map(node => node.id),
      childrenNodes,
      rowData: {
        ...d,
        name: d.customName,
        type: d.type,
        id: d.id,
      },
      type: RowType.BREAKDOWN,
    };
  };

  useEffect(() => {
    setTreeTags(defaultValue);
    setIsLoading(true);
    const dimensionNodes = dimensions.map(processDimension);
    setTreeTags((prev) => ({
      ...prev,
      [ TemplateSections.DIMENSIONS ]:
        [ ...dimensionNodes, ...prev[ TemplateSections.DIMENSIONS ] ]
    }));

    dispatch(setDimensions(dimensionNodes));
    setIsLoading(false);
  }, [ dimensions ]);

  const processTemplateNodes = (data: StatutoryTemplate, type: TemplateSections) => {
    const handleNode =
      (id: number | string): TemplateNode => {
        const index = data.nodes.findIndex(n => n.id === id);
        const node: TemplateNode = { ...data?.nodes[ index ] };
        if (node?.children?.length !== 0) {
          node.childrenNodes = [];
          node.children.forEach(childId => {
            node.childrenNodes.push(handleNode(childId));
          });
        }
        return node;
      };

    const array: TemplateNode[] = [];
    data.roots.forEach(rootId => {
      const node = handleNode(rootId);
      if (node.type !== RowType.FINANCIAL_TITLE) {
        array.push(node);
      }
    });

    setTreeTags((prev) => ({
      ...prev,
      [ type ]: array,
    }));
  };

  useEffect(() => {
    if (!dimensions.length) return;

    const templates: StatutoryTemplate[] = [];
    const getTemplates = [
      TemplateSections.FINANCIALS, TemplateSections.BALANCE_SHEET
    ].map(type => {
      return statutoryService.getTemplateByType(type)
        .then(({ data }) => {
          processTemplateNodes(data, type);
          templates.push(data);
        });
    });

    Promise.all(getTemplates).then(() => {
      dispatch(setFinancialTags(templates));
    });
  },[ dimensions ]);

  return { isLoading, treeTags };
};

export default useDragTag;
