import React from 'react';
import { useDrag } from 'react-dnd';

import { Account, DnDStatutoryTypes } from 'types/statutory.types';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import styles from './UnassignedAccountDrag.module.scss';
import { getDisplayName } from '../../../utils/common.utils';

type Props = {
  account: Account;
};

const UnassignedAccountDrag = ({ account }: Props) => {

  const [ { isDragging }, drag ] = useDrag(() => ({
    type: DnDStatutoryTypes.unassigned,
    item: account ,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div
      key={ account.id }
      ref={ drag }
      role={ DnDStatutoryTypes.unassigned }
      className={ `${ styles.row } ${
        isDragging ? styles.dragging : '' 
      }` }
    >
      <DragIcon className={ styles.dragIcon }/>
      <span className={ styles.number }>{ account.number }</span>
      <span className={ styles.name }>{ getDisplayName(account.name) }</span>
    </div>
  );
};

export default UnassignedAccountDrag;
