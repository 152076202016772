import { SettingsType } from 'types/chart.types';
import { t } from 'i18next';

const colors = [
  '#267278',
  '#0EA7B0',
  '#8AD4EB',
  '#FE9666',
  '#F3D864',
  '#DFBFBF',
  '#A0797B',
];

const primaryColors = [
  '#0A1A43',
  '#213771',
  '#3B4C7C',
  '#495C8D',
  '#6374A1',
  '#7384B0',
  '#92A2CD',
  '#B2BFE3',
  '#D5DEF5',
];

const secondaryColors = [
  '#3F0703',
  '#581808',
  '#733010',
  '#904719',
  '#AC6022',
  '#CB7A38',
  '#E9944F',
  '#F4AE66',
  '#F7C87E',
];

const budgetColors = [
  '#E5E5E5',
  '#CBCBCB',
  '#B0B0B0',
  '#929292',
  '#828282',
  '#6B6B6B',
  '#5B5B5B',
  '#494949',
  '#272727',
];

const dataIndexMap = {
  0: SettingsType.PRIMARY,
  1: SettingsType.SECONDARY
};

const singleBarColors = {
  [ SettingsType.PRIMARY ]: '#213771',
  [ SettingsType.SECONDARY ]: '#F4AE66',
};

const budgetSingleBarColors = {
  [ SettingsType.PRIMARY ]: '#CBCBCB',
  [ SettingsType.SECONDARY ]: '#F4AE66',
};

const otherColors = {
  [ SettingsType.PRIMARY ]: {
    [ t('financials:charts.summed-rows') ]: '#213771',
    [ 'Unassigned' ]: '#E8E8E8',    
  },
  [ SettingsType.SECONDARY ]: {
    [ t('financials:charts.summed-rows') ]: '#581808',
    [ 'Unassigned' ]: '#E8E8E8'
  },
  budget: {
    [ t('financials:charts.summed-rows') ]: '#494949',
    [ 'Unassigned' ]: '#E8E8E8',   
  }
};

export {
  colors,
  budgetColors,
  singleBarColors,
  budgetSingleBarColors,
  otherColors,
  primaryColors,
  secondaryColors,
  dataIndexMap
};
