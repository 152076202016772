import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './store';

export interface EventStore{
  altKey: boolean;
  backspaceKey: boolean;
}

const initialState: EventStore = {
  altKey: false,
  backspaceKey: false,
};

export const eventSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setAltKey: (state, action: PayloadAction<boolean>) => {
      state.altKey = action.payload;
    },
    setBackspaceKey: (state, action: PayloadAction<boolean>) => {
      state.backspaceKey = action.payload;
    }
  }
});

export const toggleAltKey = (isUp: boolean) => (dispatch: AppDispatch) => {
  dispatch(actions.setAltKey(isUp));
};

export const toggleBackspaceKey = (isUp: boolean) => (dispatch: AppDispatch) => {
  dispatch(actions.setBackspaceKey(isUp));
};

const actions = eventSlice.actions;

export const eventsReducer = eventSlice.reducer;

export const selectAltKey = (state: RootState) => state.events.altKey;
export const selectBackspaceKey = (state: RootState) => state.events.backspaceKey;
