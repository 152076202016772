import React from 'react';
import { CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import CommandBarUtilButton from
  'components/commandBar/components/button/utilButton/CommandBarUtilButton';
import CommandBarDropdownButton from
  'components/commandBar/components/button/dropdownButton/CommandBarDropdownButton';
import CommandBarCascadeButton from
  'components/commandBar/components/button/cascadeButton/CommandBarCascadeButton';
import CommandBarSelect from 'components/commandBar/components/select/CommandBarSelect';
import CommandBarCustomButton from
  'components/commandBar/components/button/customButton/CommandBarCustomButton';
import CommandBarTextButton from
  'components/commandBar/components/button/textButton/CommandBarTextButton';
import CommandBarDivider from '../other/divider/CommandBarDivider';

interface Props {
  util: CommandBarUtil;
}

const CommandBarProxyButton = ({ util }: Props) => {
  if (util.type === 'button') {
    return <CommandBarUtilButton button={ util } />;
  }
  if (util.type === 'button-dropdown') {
    return <CommandBarDropdownButton button={ util } />;
  }
  if (util.type === 'button-cascade') {
    return <CommandBarCascadeButton button={ util } />;
  }
  if (util.type === 'button-custom') {
    return <CommandBarCustomButton button={ util } />;
  }
  if (util.type === 'button-text') {
    return <CommandBarTextButton button={ util } />;
  }
  if (util.type === 'select') {
    return <CommandBarSelect select={ util } />;
  }
  if (util.type === 'divider') {
    return <CommandBarDivider />;
  }

  const exhaustiveCheck: never = util;
  throw new Error(`Unhandled util case ${ exhaustiveCheck }`);
};

export default CommandBarProxyButton;