import React, { useMemo } from 'react';
import { useDrop } from 'react-dnd';

import { TemplateNode } from 'types/templates.types';

import styles from './DropPosition.module.scss';
import { canBeDroppedBetween, isDimensionGroup } from 'utils/template.utils';
import { batchCreateNode, createNode, moveNode } from 'store/template.slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { selectCustomFormula } from 'store/formula.slice';

type Props = {
  position: 'top' | 'bottom';
  node?: TemplateNode;
  disabled?: boolean;
};

const DropPosition = ({ position, node, disabled = false }: Props) => {
  const dispatch = useAppDispatch();

  const customFormula = useAppSelector(selectCustomFormula);
  const customFormulaIsOpen = useMemo(() => customFormula !== null ,[ customFormula ]);

  const [ { isOver, canDrop }, drop ] = useDrop(() => ({
    accept: 'tag',
    canDrop: (tag: TemplateNode) => canBeDroppedBetween(tag) && !customFormulaIsOpen,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: async (item: TemplateNode) => {
      if (isDimensionGroup(item)) {
        return dispatch(batchCreateNode(item, node, position));
      }
      dispatch(createNode(item, node, position));
    }
  }),[ customFormulaIsOpen ]);

  const [ { isOver: isOverNode, canDrop: canDropNode }, dropNode ] = useDrop(() => ({
    accept: 'node',
    canDrop: (draggedNode: TemplateNode) => canBeDroppedBetween(draggedNode),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop: async (droppedNode: TemplateNode) => {
      dispatch(moveNode(droppedNode, node, position));
    }
  }));

  return <div
    ref={ (el) => {
      drop(el);
      dropNode(el);
    } }
    className={ `${ styles.dropOverlay } ${ styles[ position ] }
    ${ (isOver && canDrop) ||
      (isOverNode && canDropNode) ? styles.isOver : '' }
    ${ disabled ? styles.disabled : '' } `
    }
  >
    <div className={ styles.dropLine }></div>
  </div>;
};

export default DropPosition;
