import { ButtonProps, Tooltip } from 'antd';
import GhostIconButton from '../../elements/button/ghostIcon/GhostIconButton';
import React, { useCallback, useState } from 'react';
import {
  clearFilters,
  removeDimensionFromFilters,
  selectPeriod,
} from '../../../store/financials.slice';
import { useClearFiltersMutation } from '../../../store/api/reports.api';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ClearIcon } from 'assets/icons/clear-filters.svg';
import { ReactComponent as FiltersActiveIcon } from 'assets/icons/filters-active.svg';

import useReportQuery from '../financialTable/hooks/useReportQuery';
import { inputsSlice } from '../../../store/inputs.slice';

interface Props {
  templateId: number;
  dimensionIdToClear?: number;
  defaultToClearState?: boolean;
}

export const ClearFiltersIcon = (
  {
    templateId,
    dimensionIdToClear,
    defaultToClearState = false,
    ...props
  }: ButtonProps & React.RefAttributes<HTMLElement> & Props) => {

  const dispatch = useAppDispatch();
  const [ clearFiltersMutation ] = useClearFiltersMutation();
  const [ t ] = useTranslation('financials');
  const filters = useAppSelector((state) => state.financials.tables[ templateId ]?.filter);
  const period = useAppSelector(selectPeriod(templateId));
  const { refetch } = useReportQuery({ templateId: templateId, period });

  const activeTable = useAppSelector((state) => state.financials.active);

  const [ icon, setIcon ] = useState(defaultToClearState ? <ClearIcon/> : <FiltersActiveIcon/>);

  const dimension = useAppSelector((state) =>
    state.breakdowns.dimensionMap[ dimensionIdToClear ]);

  const clearDimensionFilters = async () => {
    if (!templateId || !filters) return;
    dispatch(removeDimensionFromFilters({ templateId, dimensionId: dimension.id }));
    refetch();
  };

  const clearInputsFilters = async () => {
    dispatch(inputsSlice.actions.setFilters([]));
  };

  const clearTableFilters = useCallback(async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (activeTable.type === 'inputs') {
      await clearInputsFilters();
    } else if (!templateId) return;
    if (dimensionIdToClear) {
      await clearDimensionFilters();
    } else {
      await clearFiltersMutation({ id: templateId });
      dispatch(clearFilters(templateId));
      refetch();
    }
  }, [ templateId ]);

  const onMouseOver = () => {
    setIcon(<ClearIcon/>);
  };

  const onMouseOut = () => {
    setIcon(defaultToClearState ? <ClearIcon/> : <FiltersActiveIcon/>);
  };

  return <GhostIconButton
    onClick={ clearTableFilters }
    onMouseOver={ onMouseOver }
    onMouseOut={ onMouseOut }
    { ...props }
  >
    <Tooltip title={ t('left-panel.clear-filters') }>
      { icon }
    </Tooltip>
  </GhostIconButton>;
};
