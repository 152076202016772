import { ValidationRules } from 'types/form.types';
import i18n from '../locales/i18n';

export const getValidationRules = (rules: ValidationRules, fieldName?: string) => ({
  required: rules.required ? {
    value: true,
    message: i18n.t('notifications.required.message', { 
      ns: 'common',
      field: fieldName || 'Field'
    }),
  } : {},
  maxLength: rules.maxLength ? { 
    value: rules.maxLength,
    message: i18n.t('notifications.maxLength.message', { 
      ns: 'common',
      field: fieldName || 'Field', length: rules.maxLength 
    }),
  } : {},
  pattern: rules.isEmail ? {
    // eslint-disable-next-line max-len
    value: /^[a-zA-Z0-9]+([._-]{1}[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([._-]{1}[a-zA-Z0-9]+)*[.][a-zA-Z0-9]+([._-]{1}[a-zA-Z0-9]+)*[a-zA-Z0-9]+$/,
    message: i18n.t('notifications.email.message', { ns: 'common' }),
  } : {},
});