import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSettings.module.scss';
import { Radio } from 'antd';
import { Language, STORAGE_LANGUAGE_KEY } from '../../../../locales/i18n';
import Modal from '../../../elements/modal/Modal';

const LanguageSettings = () => {
  const { t, i18n } = useTranslation('settings');
  const [ modalVisible, setModalVisible ] = useState(false);
  const [ selectedLanguage, setSelectedLanguage ] = useState<Language>(i18n.language as Language);

  const labels = { fi: t('language.labels.fi'), en: t('language.labels.en') };

  const onLangChange = async (lang: Language) => {
    setSelectedLanguage(lang);
    setModalVisible(true);
  };

  const onLangChangeConfirm = async () => {
    setModalVisible(false);
    await i18n.changeLanguage(selectedLanguage);
    localStorage.setItem(STORAGE_LANGUAGE_KEY, selectedLanguage);
  };

  return (
    <div>
      <Modal
        onConfirm={ onLangChangeConfirm }
        width={ 650 }
        closeText={ t('language.modal.close') }
        onClose={ () => setModalVisible(false) }
        title={ t('language.modal.title') }
        isVisible={ modalVisible } okText={ t('language.modal.ok') }
        description={ t('language.modal.description', { language: labels[ selectedLanguage ] }) }/>
      <h2 className={ styles.languageSettingsHeader }>
        { t('language.header') }
      </h2>
      <div className={ styles.languageSettingsDescription }>
        { t('language.description') }
      </div>
      <Radio.Group
        value={ i18n.language }
        optionType='default'
        onChange={ (e) => onLangChange(e.target.value) }
      >
        { Object.keys(labels).map(option => (
          <Radio.Button
            key={ option }
            value={ option }
          >
            { labels[ option ] }
          </Radio.Button>
        )) }
      </Radio.Group>
    </div>
  );
};

export default LanguageSettings;
