import React from 'react';
import useTemplateService from 'components/templates/hooks/useTemplateService';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionCreators } from 'redux-undo';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { selectTemplate } from 'store/template.slice';
import { UserTemplateShort } from 'types/templates.types';
import { notifyInfo } from 'utils/notifications.utils';
import { ReactComponent as Bin } from 'assets/icons/bin.svg';
import styles from './DeleteTemplateModal.module.scss';
import Modal from 'components/elements/modal/Modal';

interface Props {
  template: UserTemplateShort;
  onClose: () => void;
}

const DeleteTemplateModal = ({ template, onClose }: Props) => {
  const [ t ] = useTranslation('financials');
  const activeTemplate = useAppSelector(selectTemplate);
  const { handleDelete } = useTemplateService();
  const dispatch = useAppDispatch();

  const onDeleteConfirm = useCallback(async () => {
    await handleDelete(template.id);
    if (activeTemplate.id === template.id) {
      dispatch(ActionCreators.clearHistory());
    }
    notifyInfo(
      t('notifications.template-deleted.message', {
        title: template.title,
      }),
    );
    onClose();
  }, [ template, handleDelete ]);

  return (
    <Modal
      aria-label='delete-template-modal'
      title={
        <>
          <Bin className={ styles.icon } />
          <span>{ t('notifications.delete-template.message') }</span>
        </>
      }
      isVisible={ Boolean(template) }
      onClose={ onClose }
      onConfirm={ onDeleteConfirm }
      okText={ t('common:delete') }
    >
      <div>{ t('notifications.delete-template.description', { title: template?.title }) }</div>
    </Modal>
  );
};

export default DeleteTemplateModal;
