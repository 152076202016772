import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Modal from '../../elements/modal/Modal';
import FinancialTable from '../../financials/financialTable/FinancialTable';
import { clearPanels, financialsSlice } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { useFinancialTable } from 'context/FinancialTableContext';
import PanelCard from 'components/panels/elements/card/PanelCard';
import { LEFT_PANEL_PORTAL_ID } from 'components/panels/utils/panels.utils';
import ResizableContainer from 'components/elements/resizableContainer/ResizableContainer';

import modalStyles from '../Modals.module.scss';
import styles from './FinancialTableModal.module.scss';
interface Props {
  isVisible: boolean;
}

const FinancialTableModal = ({ isVisible }: Props) => {
  const dispatch = useAppDispatch();
  const { state: { onClose }, dispatch: tableDispatch } = useFinancialTable();

  const leftPanel = useAppSelector(state => state.app.leftPanel);

  const [ showLeftPanel, setShowLeftPanel ] = useState(false);

  useEffect(() => {
    dispatch(financialsSlice.actions.setCloseOtherPanelsOnOpen(false));
    tableDispatch({ type: 'setType', payload: 'modal' });

    return () => {
      dispatch(clearPanels());
      dispatch(financialsSlice.actions.clearActiveTable());
    };
  }, []);

  // ? HACK: This delays loading left panel so it is placed in portal correctly
  useEffect(() => {
    setTimeout(() => {
      setShowLeftPanel(true);
    });
  }, []);

  return <Modal
    onConfirm={ onClose }
    onClose={ onClose }
    isVisible={ isVisible }
    disableButtons={ true }
    destroyOnClose
    focusTriggerAfterClose={ false }
    className={ modalStyles.modal }
  >
    <div className={ styles.modalContent }>
      <ResizableContainer
        dimensionToResize='width'
        minSize={ 370 }
        maxSize={ 700 }
        linePlacement='right'
        defaultSize={ 370 }
        className={ clsx(styles.resizablePanel, { [ styles.hidden ]: !leftPanel }) }
      >
        <PanelCard className={ clsx(styles.leftPanel ) }>
          <div className={ styles.portal } id={ LEFT_PANEL_PORTAL_ID } />
        </PanelCard>
      </ResizableContainer>
      
      <div className={ styles.tableWrapper }>
        <FinancialTable isInModal={ showLeftPanel } useCommandBar={ false } />
      </div>
    </div>
  </Modal>;
};

export default FinancialTableModal;
