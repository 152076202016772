import React from 'react';
import styles from './FormulaCursor.module.scss';

type Props = {
  disabled: boolean;
};
const FormulaCursor = ({ disabled }: Props) => {
  return (
    <div
      className={ `${ styles.cursor } ${ disabled ? styles.cursorHidden : '' }` }
    />
  );
};

export default FormulaCursor;