import React, { MutableRefObject, RefObject, useCallback, useMemo, useState } from 'react';
import { Redirect, TableHookProps } from 'components/elements/tableWrapper/types/table.types';
import { Transaction } from 'types/statutory.types';
import { AgGridReact } from 'ag-grid-react';
import {
  GridColumnsChangedEvent,
  IRowNode,
  IServerSideGetRowsParams,
  SelectionChangedEvent,
} from 'ag-grid-community';
import useTransactionsTable
  from 'components/financials/detailedView/transactionsTable/hooks/useTransactionsTable';
import CostLabelerModal from 'components/financials/costLabelerModal/CostLabelerModal';
import { TransactionLineRequestParams } from 'services/statutory.service';
import { FilterList } from 'types/filterTable.types';
import { Period } from 'types/financials.types';
import useMasterDetailSplits
  from 'components/financials/detailedView/transactionsTable/hooks/useMasterDetailSplits';
import { getDisplayName } from '../../../../../utils/common.utils';
import { getUUID } from '../../../../../utils/templates.utils';
import dayjs from 'dayjs';
import organizationsService, { SSRMParams } from '../../../../../services/organizations.service';
import { useAppSelector } from '../../../../../store/hooks/hooks';
import { GatheredLabels } from '../../../../../hooks/useLabeler';

interface Props {
  isGridReady: boolean;
  gridRef: RefObject<AgGridReact>;
  templateId: number;
  params: TransactionLineRequestParams[];
  filter: FilterList;
  search: string;
  period: Period;
  sectionKey: string;
  setRedirect: React.Dispatch<React.SetStateAction<Redirect>>;
  setIsAnySelected?: MutableRefObject<(areAllLoaded?: boolean) => void>;
}

interface TransactionTableProps {
  costLabeler: React.ReactNode;
}

const useTransactionsTableProps = (
  {
    isGridReady,
    gridRef,
    templateId,
    params,
    filter,
    search,
    period,
    sectionKey,
    setRedirect,
    setIsAnySelected
  }: Props): TableHookProps<Transaction, TransactionTableProps> => {
  const [ selectedRows, setSelectedRows ] = useState<IRowNode[]>([]);
  // const [ breakdownToAssign, setBreakdownToAssign ] = useState<BreakdownToAssign>();
  const [ gatheredLabels, setGatheredLabels ] = useState<GatheredLabels>();
  const [ selectAllActive, setSelectAllActive ] = useState(false);
  const [ currentRequestParams, setCurrentRequestParams ] = useState<SSRMParams>({});
  const [ toggledTransactionIds, setToggledTransactionIds ] = useState<number[]>([]);
  const masterDetailProps = useMasterDetailSplits({ search });
  const {
    params: detailedViewParams,
  } = useAppSelector(state => state.financials.detailedView);

  const [ totalRowsCount, setTotalRowsCount ] = useState<number | null>(null);

  const { dimensionItemMap } = useAppSelector(state => state.breakdowns);

  const tableKey = 'detailed-view-transactions';

  const baseRequestParams = useMemo(() => {
    // Params determined from what is passed to detail view from financial table.
    //  It depends on the structure of the table and the date of selected range.
    const param = detailedViewParams?.actual[ 0 ];
    if (!param) {
      return {};
    }
    const startDate = dayjs.unix(period.startDate);
    const endDate = dayjs.unix(period.endDate);
    const startDatePlan = dayjs.unix(period.startDatePlan);
    const endDatePlan = dayjs.unix(period.endDatePlan);

    const dateParams = {};
    const dateFormat = 'YYYY-MM-DD';

    if (period.actualsOpen) {
      dateParams[ 'start_date' ] = startDate.format(dateFormat);
      dateParams[ 'end_date' ] = endDate.format(dateFormat);
    }
    if (period.planOpen) {
      dateParams[ 'plan_start_date' ] = startDatePlan.format(dateFormat);
      dateParams[ 'plan_end_date' ] = endDatePlan.format(dateFormat);
    }

    return {
      filterModel: {
        ...param,
        ...dateParams,
        search,
      }
    };
  }, []);

  const onShowCostLabelerModal = useCallback((
    nodes: IRowNode[],
    labels: GatheredLabels,
    // breakdownItem: BreakdownToAssign
  ) => {
    setSelectedRows(nodes);
    setGatheredLabels(labels);
    // setBreakdownToAssign(breakdownItem);
  }, []);

  const onCloseCostLabelerModal = useCallback(() => {
    setSelectedRows([]);
    setGatheredLabels(null);

    // setBreakdownToAssign(null);
  }, []);

  const {
    columnDefs: transactionColumnDefs,
    panels,
    groupOptions,
    fetchTransactions,
    loading,
  } = useTransactionsTable({
    search,
    isGridReady,
    gridRef,
    onShowCostLabelerModal,
    filter,
    params,
    period,
    templateId,
    setRedirect,
    sectionKey,
    tableKey,
    baseRequestParams,
  });

  const onGridColumnsChanged = useCallback((e: GridColumnsChangedEvent) => {
    gridRef.current.api.setPinnedTopRowData();

    const colIds = transactionColumnDefs.map(col => col.field);
    e.columnApi.setColumnsVisible(colIds, true);
  }, [ transactionColumnDefs ]);

  const costLabeler = useMemo(() => {
    if (!templateId) {
      return null;
    }
    return <CostLabelerModal
      isVisible={ selectedRows.length > 0 && gatheredLabels != null }
      onClose={ onCloseCostLabelerModal }
      onConfirm={ async () => {
        onCloseCostLabelerModal();
        await fetchTransactions({ filter, params });
      } }
      selectedRows={ selectedRows }
      useServerSide={ selectAllActive }
      serverSideRequestParams={ currentRequestParams }
      excludeTransactionIds={ toggledTransactionIds }
      gatheredLabels={ gatheredLabels }
      // breakdownsToAssign={ [ breakdownToAssign ] }
      templateId={ templateId }
    />;
  }, [
    templateId,
    params,
    filter,
    selectedRows,
    // breakdownToAssign
    gatheredLabels
  ]);

  const getRows = useCallback(async (serverSideParams: IServerSideGetRowsParams) => {
    const request = serverSideParams.request;
    if (!detailedViewParams) {
      serverSideParams.success({ rowData: [] });
      return;
    }
    const combinedRowData = [];

    const getSuccessResponse = (response) => {
      if (response) {
        const groupCol = request.groupKeys.length ?
          request.rowGroupCols.at(-1) : request.rowGroupCols.at(0);

        const isDimension = groupCol?.id.includes('dimension');

        const groupRows = response.data[ 'groupRows' ]
          .filter(
            (el: string) => {
              // Handle first-letter filter that can be applied for groupings
              if (request.filterModel[ groupCol.id ]) {
                const groupFilter = request.filterModel[ groupCol.id ];
                // Dimension items are stored in redux and only id is provided as a group,
                // so we need to get the name to compare with filter.
                if (isDimension) {
                  const displayName = getDisplayName(dimensionItemMap[ el ]?.name);
                  if (displayName) {
                    return displayName.startsWith(groupFilter.filter);
                  }
                }
                return el.startsWith(groupFilter.filter);
              }
              return true;
            }
          ).map((el: string) => {
            return {
              id: getUUID(),
              [ groupCol.id ]: el,
            };
          });
        const transactionLines = response.data[ 'transactionLines' ];

        return groupRows.length > 0 ? groupRows : transactionLines;
      }
    };

    const SSRMRequestParams = {
      ...request,
      filterModel: {
        ...request.filterModel,
        ...baseRequestParams.filterModel,
        search,
      }
    };

    setCurrentRequestParams(SSRMRequestParams);

    const response = await organizationsService.getTransactionLinesSSRM(
      SSRMRequestParams, null);
    combinedRowData.push(getSuccessResponse(response));
    
    if (gridRef.current.props.rowModelType === 'serverSide') {
      gridRef.current?.api?.setPinnedBottomRowData([ { 'amount': response.data.total } ]);
    }

    setTotalRowsCount(response.data.count);

    serverSideParams.success(
      {
        rowData: combinedRowData.flat(),
      }
    );
  }, [ detailedViewParams, search ]);

  const serverSideDatasource = useMemo(() => {
    return {
      getRows
    };
  }, [ getRows ]);

  const table = useMemo(() => ({
    columnDefs: transactionColumnDefs,
    rowModelType: 'serverSide',
    serverSideDatasource,
    onGridColumnsChanged,
    groupByOptions: groupOptions,
    panels,
    onSelectionChanged(event: SelectionChangedEvent) {
      const serverSideSelectionState = event.api.getServerSideSelectionState();
      const isSelectAll = !!serverSideSelectionState[ 'selectAll' ];
      setSelectAllActive(isSelectAll);
      const toggledTransactionLineIds = serverSideSelectionState[ 'toggledNodes' ].map(
        nodeId => +event.api.getRowNode(nodeId)?.data?.id
      );
      setToggledTransactionIds(toggledTransactionLineIds);

      const count = event.api.getDisplayedRowCount();
      setIsAnySelected?.current?.(totalRowsCount <= count);
    },
    isLoading: loading,
    rowDragMultiRow: true,
    rowDragEntireRow: true,
    onModelUpdated: event => {
      setTimeout(() => {
        event.columnApi.autoSizeAllColumns();
      }, 100);

      const count = event.api.getDisplayedRowCount();
      setIsAnySelected?.current?.(totalRowsCount <= count);
    },
    tableKey,
    ...masterDetailProps
  }), [ transactionColumnDefs, groupOptions, panels, totalRowsCount ]);

  const other = useMemo(() => ({
    costLabeler,
    toggledTransactionIds,
    fetchTransactions,
  }), [
    costLabeler,
    transactionColumnDefs,
    groupOptions,
    panels,
    loading
  ]);

  return { table, other } as TableHookProps<Transaction, TransactionTableProps>;
};

export default useTransactionsTableProps;
