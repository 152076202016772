import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle.svg';
import styles from './AutoSave.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
}

const AutoSave = ({ show }: Props) => {
  const [ t ] = useTranslation('financials');

  return <div className={ ` ${ styles.autoSave } ${ !show ? styles.disabled : '' }` }>
    <CheckIcon className={ styles.icon } width={ 18 } height={ 18 }/>
    <span className={ styles.text }>{ t('templates.changes-saved') }</span>
  </div>;
};

export default AutoSave;