import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';

import { SettingsNav } from 'types/navigation.types';
import { routes } from 'utils/config.utils';
import useBreakpoint from 'hooks/useBreakpoint';
import MobileSettingsNav from 'components/settings/mobileSettingsNav/MobileSettingsNav';
import Card from 'components/elements/card/Card';
import styles from './Settings.module.scss';
import { useAppDispatch } from 'store/hooks/hooks';
import { topBarSlice } from 'store/topBar.slice';

const Settings = () => {
  const [ t ] = useTranslation('settings');
  const { isTabletDown } = useBreakpoint();
  const dispatch = useAppDispatch();

  const navItems: SettingsNav[] = [
    {
      name: t('links.settings.global'),
      path: routes.settings.global
    },
    {
      name:  t('links.settings.integrations'),
      path: routes.settings.integrations
    }
  ];

  useEffect(() => {
    dispatch(topBarSlice.actions.setTitle(t('links.settings.index')));

    return () => {
      dispatch(topBarSlice.actions.clear());
    };
  }, []);

  return (
    <div className={ styles.settings }>
      <Card className={ styles.card }>
        { isTabletDown ? (
          <MobileSettingsNav navItems={ navItems }/>
        ) : (
          <ul className={ styles.navList }>
            { navItems.map(item => item.path && (
              <li key={ item.name }>
                <NavLink
                  to={ item.path }
                  className={ ({ isActive }) =>
                    `${ styles.item } ${ isActive ? styles.active : '' }`
                  }>
                  <span>
                    { item.name }
                  </span>
                </NavLink>
              </li>
            )) }
          </ul>
        ) }
        <div className={ styles.content }>
          <Outlet />
        </div>
      </Card>
    </div>
  );
};

export default Settings;
