import React from 'react';
import styles from './Card.module.scss';

type Props = {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
};

const Card = ({ children, className = '', onClick, style }: Props) => (
  <div style={ style } className={ `${ styles.card } ${ className }` } onClick={ onClick }>
    { children }
  </div>
);

export default Card;