import React, { useEffect } from 'react';
import { useFinancialTable } from '../../../context/FinancialTableContext';
interface Props {
  children: React.ReactNode;
  templateId: number;
}
const DynamicTableWrapper = ({ children, templateId }: Props) => {
  const { dispatch: dispatchTable } = useFinancialTable();
  useEffect(() => {
    dispatchTable({ type: 'setTemplateId', payload: templateId });
  }, [ templateId ]);

  return <>
    { children }
  </>;
};

export default DynamicTableWrapper;