import React, { useMemo } from 'react';
import TemplateSelect from
  'components/dashboard/panel/cardSettingsPage/forms/common/TemplateSelect';
import { useForm, useWatch } from 'react-hook-form';
import { DashboardElementMapping, DashboardElementType, TableElement } from 'types/dashboard.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { selectAllTemplates } from 'store/templates.slice';
import { clearSelectedElements, saveElement } from 'store/dashboard.slice';
import FormWrapper from 'components/dashboard/panel/cardSettingsPage/forms/common/FormWrapper';

interface Props {
  item: DashboardElementMapping<DashboardElementType.TABLE>;
}

interface FormValues {
  element: TableElement;
}

const BasicTemplateForm = ({ item }: Props) => {
  const dispatch = useAppDispatch();
  const templates = useAppSelector(selectAllTemplates);
  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: { element: item.element }
  });
  const values = useWatch({ control });
  const selectedTemplate = useMemo(() => {
    return templates.find((temp) => temp.id === values.element?.template);
  }, [ values.element?.template, templates ]);

  const onSubmit = async (data: FormValues) => {
    dispatch(saveElement({
      ...item,
      element: data.element
    }));
    dispatch(clearSelectedElements());
  };

  return <FormWrapper onSubmit={ handleSubmit(onSubmit) }>
    <TemplateSelect
      control={ control }
      name='element.template'
      value={ selectedTemplate?.title }
    />
  </FormWrapper>;
};

export default BasicTemplateForm;