import React, { ReactNode, useEffect } from 'react';
import { Button, Modal as AntdModal, ModalProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Close } from 'assets/icons/toast-close.svg';
import styles from './Modal.module.scss';
import { useAppDispatch } from '../../../store/hooks/hooks';
import { appSlice } from '../../../store/app.slice';
import useKeyPressListener from 'hooks/useKeyPressListener';

export type Props = {
  onConfirm: () => void;
  onClose: () => void;
  isVisible: boolean;
  description?: string;
  className?: string;
  okText?: string;
  okButtonType?: 'primary' | 'default';
  closeText?: string;
  // eslint-disable-next-line no-undef
  children?: JSX.Element;
  disableButtons?: boolean;
  closeIcon?: ReactNode;
  confirmDisabled?: boolean;
  closeOnConfirm?: boolean;
  isDangerous?: boolean;

  onAdditionalAction?: () => void;
  additionalButtonText?: string;
  additionalButtonIcon?: ReactNode;
  additionalButtonClassName?: string;
} & ModalProps;

const Modal = (
  {
    isVisible,
    onClose,
    onConfirm,
    description = '',
    className = '',
    okText,
    children,
    disableButtons,
    closeIcon,
    confirmDisabled,
    closeText,
    onAdditionalAction,
    additionalButtonIcon,
    additionalButtonText,
    additionalButtonClassName = '',
    closeOnConfirm = true,
    okButtonType = 'primary',
    isDangerous = false,
    ...modalProps
  }: Props) => {
  const [ t ] = useTranslation('common');
  const dispatch = useAppDispatch();

  useKeyPressListener({
    code: 'Enter',
    cb: () => {
      onConfirm();
      if (closeOnConfirm) {
        onClose();
      }
    },
    enabled: isVisible && !confirmDisabled && !!onConfirm,
  });

  useEffect(() => {
    dispatch(appSlice.actions.setAnyModalOpened(isVisible));
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      dispatch(appSlice.actions.setAnyModalOpened(false));
      document.body.style.overflow = 'unset';
    };
  }, [ isVisible ]);

  const getFooter = () => {
    if (disableButtons) return null;

    return <div className={ styles.footer }>
      <div>
        {
          onAdditionalAction ?
            <Button
              type='text'
              aria-label='additional-action'
              icon={ additionalButtonIcon }
              className={ `${ styles.buttonAdditional } ${ additionalButtonClassName }` }
              shape='round'
              key='additional'
              onClick={ onAdditionalAction }>
              { additionalButtonText || t('modal.buttons.default-additional') }
            </Button> : null
        }
      </div>
      <div>
        <Button type='text' shape='round' key='back' onClick={ onClose } aria-label='modal-close'>
          { closeText || t('modal.buttons.default-close') }
        </Button>
        <Button
          aria-label='modal-ok'
          shape='round'
          key='submit'
          danger={ isDangerous }
          type={ okButtonType }
          disabled={ confirmDisabled }
          loading={ modalProps.confirmLoading }
          onClick={ async () => {
            await onConfirm();
            closeOnConfirm && onClose();
          } }
          { ...modalProps.okButtonProps }
        >
          { okText || t('modal.buttons.default-ok') }
        </Button>
      </div>

    </div>;

  };

  return (
    <AntdModal
      { ...modalProps }
      open={ isVisible }
      onCancel={ onClose }
      closeIcon={ closeIcon ? closeIcon : <Close/> }
      className={ `${ styles.modal } ${ className }` }
      centered={ true }
      cancelButtonProps={ { shape: 'round', type: 'text' } }
      okText={ okText || t('form.confirm') }
      footer={ getFooter() }
      bodyStyle={
        {
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 120px)',
          ...modalProps.bodyStyle
        }
      }
    >
      {
        description ? <div className={ styles.description }>
          { description }
        </div> : null
      }
      { children }
    </AntdModal>
  );
};

export default Modal;
