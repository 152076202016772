import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { selectAllTemplates } from 'store/templates.slice';
import { DashboardElementMapping, DashboardElementType } from 'types/dashboard.types';
import styles from '../Forms.module.scss';
import { useTranslation } from 'react-i18next';
import { ChartSettings, ChartSource, ChartStyle } from 'types/chart.types';
import DatasetSettings from 'components/charts/chartSettings/datasetSettings/DatasetSettings';
import { clearSelectedElements, saveElement, selectSelectedElements } from 'store/dashboard.slice';
import OtherSettings from 'components/charts/chartSettings/otherSettings/OtherSettings';
import { mapChartElementToSettings } from 'utils/dashboard.utils';
import TemplateSelect
  from 'components/dashboard/panel/cardSettingsPage/forms/common/TemplateSelect';
import ElementName from 'components/dashboard/panel/cardSettingsPage/forms/common/ElementName';
import LoadingWrapper from 'components/wrappers/loadingWrapper/LoadingWrapper';
import useTemplateNode, { NodeOption } from 'hooks/useTemplateNode';
import FormWrapper from 'components/dashboard/panel/cardSettingsPage/forms/common/FormWrapper';

interface Props {
  item: DashboardElementMapping<DashboardElementType.CHART>;
}

type FormValues = {
  name: string;
  template: number;
} & ChartSettings;

const getDefaultChartValues = (item: DashboardElementMapping<DashboardElementType.CHART>) => {
  return {
    name: item.name,
    template: item.element.template,
    ...mapChartElementToSettings(item.element)
  };
};

const ChartElementForm = ({ item }: Props) => {
  const templates = useAppSelector(selectAllTemplates);
  const selectedElement = useAppSelector((state) => {
    return selectSelectedElements(state)[ 0 ];
  });
  const [ t ] = useTranslation('dashboard');
  const [ showCustomName, setShowCustomName ] = useState<boolean>(false);
  const [ dataNamePrimary, setDataNamePrimary ] = useState<string>('');
  const [ dataNameSecondary, setDataNameSecondary ] = useState<string>('');
  const dispatch = useAppDispatch();
  const methods = useForm<FormValues>({
    defaultValues: getDefaultChartValues(item)
  });
  const control = useMemo(() => methods.control, []);
  const values = useWatch({ control });
  const templateId = useMemo(() => values.template, [ values.template ]);
  const { uniqueRoots, report, isFetching } = useTemplateNode({ templateId, type: 'chart' });
  const datasetSettingsTitles = {
    0: t('financials:charts.settings.primary'),
    1: t('financials:charts.settings.secondary'),
    2: t('financials:charts.settings.third'),
    3: t('financials:charts.settings.fourth'),
  };

  useEffect(() => {
    if (dataNamePrimary && values.datasets[ 0 ]?.active && !showCustomName) {
      methods.setValue('name', dataNamePrimary);
      return;
    }
    if (
      dataNameSecondary &&
      values.datasets[ 1 ]?.active &&
      !values.datasets[ 0 ].active &&
      !showCustomName
    ) {
      methods.setValue('name', dataNameSecondary);
    }
  }, [
    values,
    dataNamePrimary,
    dataNameSecondary
  ]);

  const onSubmit = useCallback((data: FormValues) => {
    const element: DashboardElementMapping<DashboardElementType.CHART> = {
      ...item,
      name: data.name,
      element: {
        template: data.template,
        datasets: data.datasets,
        legend: data.other.legend,
        barLabels: data.other.dataLabels.bar,
        stackedLabels: data.other.dataLabels.stacked,
        leftAxisFormat: data.other.leftAxisFormat,
        rightAxisFormat: data.other.rightAxisFormat,
        cumulativeValues: data.other.cumulativeValues,
      }
    };
    dispatch(saveElement(element));
    dispatch(clearSelectedElements());
  }, [ item ]);

  const selectedTemplateName = useMemo(() => {
    return templates.find((temp) => temp.id === values?.template)?.title;
  }, [ values, templates ]);

  useEffect(() => {
    setShowCustomName(false);
  }, [ values.template ]);

  const resetChartSettings = (template: number) => {
    methods.reset({
      ...values,
      template,
      datasets: item.element.datasets.map((_dataset, index) => {
        return {
          templateNode: undefined,
          active: index === 0,
          stacks: 5,
          datePickerSource: ChartSource.BOTH,
          style: ChartStyle.BAR,
          axisPosition: 'left',
        };
      }),

    });
  };

  useEffect(() => {
    setDataNamePrimary('');
    setDataNameSecondary('');
  }, [ templateId ]);

  const onTemplateNodeChange = useCallback((datasetIndex: number, node: NodeOption) => {
    if (!node || showCustomName) return;
    const firstActiveDataset = values.datasets.findIndex((dataset) => dataset.active);
    if (datasetIndex > firstActiveDataset) return;
    methods.setValue('name', node.label);
  }, [ values ]);

  useEffect(() => {
    methods.reset(getDefaultChartValues(item));
  }, [ selectedElement ]);

  return (
    <FormWrapper onSubmit={ methods.handleSubmit(onSubmit) }>
      <TemplateSelect
        control={ control }
        name='template'
        value={ selectedTemplateName }
        onChange={ resetChartSettings }
      />
      <Controller
        control={ control }
        name='name'
        render={ ({ field }) => (
          <ElementName
            name={ field.value }
            onChangeName={ field.onChange }
            showCustomName={ showCustomName }
            setShowCustomName={ setShowCustomName }
          />
        ) }
      />
      <FormProvider { ...methods }>
        <LoadingWrapper loading={ isFetching }>
          <div className={ styles.chartSettings }>
            {
              templateId &&
              report?.id === templateId &&
              uniqueRoots.length > 0 &&
              (
                <>
                  {
                    values.datasets.map(
                      (_dataset, index) => {
                        return (
                          <DatasetSettings
                            key={ index }
                            index={ index }
                            title={ datasetSettingsTitles[ index ] }
                            templateId={ templateId }
                            onTemplateNodeChange={ (node) => onTemplateNodeChange(index, node) }
                          />
                        );
                      }
                    )
                  }
                  <OtherSettings/>
                </>
              )
            }
          </div>
        </LoadingWrapper>
      </FormProvider>
    </FormWrapper>
  );
};

export default ChartElementForm;
