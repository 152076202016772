import React, { useCallback, useMemo } from 'react';
import { SelectProps } from 'antd';
import { NodeOption } from 'hooks/useTemplateNode';
import styles from './NodeSelect.module.scss';
import { getDisplayName } from 'utils/common.utils';
import { UUID } from 'types/templates.types';
import FlatSelect from 'components/elements/flatSelect/FlatSelect';

interface Props extends Omit<SelectProps, 'options'> {
  nodeOptions: NodeOption[];
  disabled?: boolean;
}

interface Option {
  value: UUID;
  label: React.ReactNode;
}

interface NestedOption extends Option {
  children: Option[];
}

const NodeSelect = ({ nodeOptions, ...rest }: Props) => {
  const getOption = (nodeOption: NodeOption, indentation = 0): Option => ({
    value: nodeOption.value,
    label: <div
      className={ styles.nodeLabel }
      style={ { paddingLeft: `${ indentation * 12 }px` } }
    >
      { getDisplayName(nodeOption.label) }
    </div>
  });

  const getNestedSelectOptions = (options: NodeOption[], indentation = 0): NestedOption[] => {
    return options.map(option => ({
      ...getOption(option, indentation),
      children: getNestedSelectOptions(option.children, indentation + 1)
    }));
  };

  const flatNestedOption = (option: NestedOption): Option[] => {
    return [ option, ...option.children.flatMap(flatNestedOption) ];
  };

  const flatSelectOptions = useCallback((nestedOptions: NestedOption[]): Option[] => {
    return nestedOptions.reduce((acc, option) => {
      return [ ...acc, ...flatNestedOption(option) ];
    }, []);
  }, [ ]);

  const selectOptions = useMemo(() => {
    const nestedOptions = getNestedSelectOptions(nodeOptions);
    return flatSelectOptions(nestedOptions);
  }, [ nodeOptions ]);

  return <FlatSelect
    { ...rest }
    className={ `${ styles.select } ${ rest.className ?? '' }` }
    options={ selectOptions }
  />;
};

export default NodeSelect;
