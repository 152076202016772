import React, { useState } from 'react';
import Modal from 'components/elements/modal/Modal';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { NetvisorIntegration } from
  'components/settings/integrations/netvisor/types/netvisor.types';
import { Input } from 'antd';
import { integrateNetvisor, updateNetvisor } from 'services/integration.service';
import { notifyError, notifySuccess, notifyUnexpectedError } from 'utils/notifications.utils';
import styles from './NetvisorIntegrationModal.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { fetchAndSetIntegrations } from 'store/app.slice';
import { Integration } from '../../../../../types/settings.types';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  integration: Integration;
}

const NetvisorIntegrationModal = ({ isVisible, onClose, integration }: Props) => {
  const [ t ] = useTranslation('settings');
  const { control } = useForm<NetvisorIntegration>();
  const data = useWatch({ control });
  const [ isLoading, setIsLoading ] = useState(false);
  const dispatch = useAppDispatch();
  const organization = useAppSelector(state => state.auth.organization);
  const isFormValid = (values: typeof data): values is NetvisorIntegration => {
    return !!(values.userId && values.businessId && values.apiKey);
  };

  const update = (d: NetvisorIntegration) => {
    return updateNetvisor(integration.id, d);
  };

  const onSubmit = () => {
    if (!isFormValid(data)) {
      return;
    }
    setIsLoading(true);
    const request = integration ? update : integrateNetvisor;

    request(data)
      .then(() => {
        dispatch(fetchAndSetIntegrations(organization.id));
        notifySuccess(
          t('notifications.message'),
          t('notifications.description', { system: 'Netvisor' })
        );
        onClose();
      })
      .catch((e) => {
        if (e.response.data.error) {
          notifyError(e.response.data.error);
        } else {
          notifyUnexpectedError(e);
        }
      }).finally(() => {
        setIsLoading(false);
      });
  };

  const renderInput = (
    label: string,
    value: string,
    onChange: (...event: unknown[]) => void,
    disabled = false
  ) => {
    return <div className={ styles.inputRow }>
      <label>{ label }</label>
      <Input disabled={ disabled } type='text' value={ value } onChange={ onChange }/>
    </div>;
  };

  return <Modal
    isVisible={ isVisible }
    onClose={ onClose }
    onConfirm={ onSubmit }
    closeOnConfirm={ false }
    title='Netvisor'
    confirmLoading={ isLoading }
    confirmDisabled={ !isFormValid(data) }
  >
    <div className={ styles.form }>
      <Controller
        control={ control }
        name='userId'
        render={ ({ field }) => renderInput(
          t('netvisor.integration.userId'),
          field.value,
          field.onChange
        ) }
      />
      <Controller
        control={ control }
        name='businessId'
        render={ ({ field }) => renderInput(
          t('netvisor.integration.businessId'),
          field.value,
          field.onChange,
        ) }
      />
      <Controller
        control={ control }
        name='apiKey'
        render={ ({ field }) => renderInput(
          t('netvisor.integration.apiKey'),
          field.value,
          field.onChange
        ) }
      />
    </div>
  </Modal>;
};

export default NetvisorIntegrationModal;
