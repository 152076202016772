import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import styles from './Editors.module.scss';
import { DATE_FORMATS } from 'utils/date.utils';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface FiledProps {
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
}

interface ValidationProps {
  comparison: string;
  type: 'after' | 'before';
  comparisonValue: string;
  comparisonFieldName: string;
}

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const DatePickerCellEditor = (props: FiledProps & ValidationProps) => {
  const [ t ] = useTranslation('common');
  const [ value, setValue ] = useState<Dayjs | null>(
    props.value ? dayjs(props.value) : null
  );
  const [ valid, setValid ] = useState(true);
  const refContainer = useRef<HTMLDivElement>(null);
  const [ open, setOpen ] = useState(false);

  const getDateInFormat = useCallback((date: Dayjs): string => {
    return date?.format('YYYY-MM-DD');
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (props.value !== value?.format('YYYY-MM-DD')) {
      setValue(props.value ? dayjs(props.value) : undefined);
    }
  }, [ props.value ]);

  const isValidDate = useCallback((date: Dayjs) => {
    if (!props.comparisonValue) {
      return true;
    }

    if (props.type === 'after') {
      return date?.isAfter(dayjs(props.comparisonValue));
    }
    return date?.isBefore(dayjs(props.comparisonValue));
  }, [ props.comparisonValue ]);

  const onSelect = useCallback((date: Dayjs) => {
    if (date == null) {
      setValue(null);
      props.setValue(null);
      return;
    }
    if (!isValidDate(date)) {
      setValid(false);
      return;
    }
    setValid(true);
    setValue(date);
    props.setValue(getDateInFormat(date));
    onClose();
  }, []);

  const validationTitle = useMemo(() => {
    if (props.type === 'after') {
      return t('validation.date.after', { field: props.comparisonFieldName });
    }
    return t('validation.date.before', { field: props.comparisonFieldName });
  }, [ props.type, props.comparisonFieldName ]);

  return (
    <div ref={ refContainer }>
      <Tooltip open={ !valid } title={ validationTitle }>
        <DatePicker
          onFocus={ () => setOpen(true) }
          onOpenChange={ setOpen }
          disabled={ props.disabled }
          open={ open }
          value={ value }
          placeholder={ props.placeholder }
          format={ DATE_FORMATS }
          status={ valid ? null : 'error' }
          getPopupContainer={ trigger => trigger }
          onSelect={ onSelect }
          onChange={ onSelect }
          popupClassName='ag-custom-component-popup'
          className={ styles.datePicker }
        />
      </Tooltip>
    </div>
  );
};

DatePickerCellEditor.displayName = 'DatePickerCellEditor';
export default DatePickerCellEditor;
