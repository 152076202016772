import { debounce } from 'lodash';
import { useEffect, useCallback, useState } from 'react';
interface Props<T> {
  onSave: (items: T[]) => void;
  items: T[];
  timeout: number;
}

const useAutoSaveEffect = <T>({ items, onSave, timeout }: Props<T>) => {
  const [ isAutoSaved, setIsAutoSaved ] = useState(false);

  const debouncedSave = useCallback(debounce((_items: T[]) => {
    onSave(_items);
    setIsAutoSaved(true);
  }, timeout), [ onSave ]);

  useEffect(() => {
    if (items.length > 0) {
      debouncedSave(items);
    }
  }, [ items ]);

  return { isAutoSaved, clearAutoSave: () => setIsAutoSaved(false) };
};

export default useAutoSaveEffect;