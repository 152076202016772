import React, { MouseEvent } from 'react';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as StarFilledIcon } from 'assets/icons/star-filled.svg';
import clsx from 'clsx';

import styles from './FavouriteStar.module.scss';

/**
 * To use hover effect, add global class 'starContainer' to the parent element
 */

interface Props {
  isFavourite: boolean;
  onClick?: () => void;
  hideStar?: boolean;
  className?: string;
}

const FavouriteStar = ({ isFavourite, onClick, hideStar = false, className = '' }: Props) => {
  const toggleFavourite = (e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    onClick?.();
  };

  if (isFavourite) {
    return <StarFilledIcon
      aria-label='favourite-star-filled'
      className={ clsx(styles.star, styles.filledStar, { [ styles.hidden ]: hideStar }, className) }
      onClick={ (e) => toggleFavourite(e) }
    />;
  }

  return <StarIcon
    aria-label='favourite-star-empty'
    className={ clsx(styles.star, { [ styles.hidden ]: hideStar }, className) }
    onClick={ (e) => toggleFavourite(e) }
  />;

};

export default FavouriteStar;
