import React from 'react';
import { ButtonProps } from 'antd';
import { CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import styles from './CommandBarButton.module.scss';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  button: CommandBarUtil;
  children?: React.ReactNode;
}

const CommandBarButton = ({ button, children, ...props }: Props) => {
  const [ t ] = useTranslation();

  return <div className={ styles.buttonBadgeContainer }>
    <GhostIconButton
      disabled={ button.disabled }
      tooltip={ button.tooltip }
      activeType='outlined'
      className={ button.className }
      active={ button.type === 'button' && button.active }
      { ...props }
    >
      { children ?? button.icon }
    </GhostIconButton>
    { button.newBadge && <div className={ styles.newBadge }>
      { t('common:command-bar.badges.new') }
    </div> }
  </div>;
};

export default CommandBarButton;
