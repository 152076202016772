import React from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as MultiplicationIcon } from 'assets/icons/deleteIcon.svg';
import { ReactComponent as DivisionIcon } from 'assets/icons/slash.svg';
import { ReactComponent as LeftBracketIcon } from 'assets/icons/leftBracket.svg';
import { ReactComponent as RightBracketIcon } from 'assets/icons/rightBracket.svg';
import { FormulaItemType, isFormulaNode, Template, TemplateNode } from 'types/templates.types';
import { RowType } from 'types/financials.types';

export const FORMULA_PANEL_MIN_HEIGHT = 244;
export interface OperatorsType {
  id: number;
  // eslint-disable-next-line no-undef
  component: JSX.Element;
  type: FormulaItemType;
  value: string;
  menuValue?: string;
}

export interface FormulaBuilderProps {
  formulaValidation: boolean;
  setFormulaValidation: (value: boolean) => void;
  setHeightPanel: (value: string) => void;
}

export const operators: OperatorsType[] = [
  {
    id: 0,
    component: <PlusIcon/>,
    type: FormulaItemType.OPERATOR,
    value: '+',
  },
  {
    id: 1,
    component: <MinusIcon/>,
    type: FormulaItemType.OPERATOR,
    value: '-'
  },
  {
    id: 2,
    component: <MultiplicationIcon/>,
    type: FormulaItemType.OPERATOR,
    value: '*',
  },
  {
    id: 3,
    component: <DivisionIcon/>,
    type: FormulaItemType.OPERATOR,
    value: '/'
  },
  {
    id: 4,
    component: <LeftBracketIcon/>,
    type: FormulaItemType.BRACKET,
    value: '('
  },
  {
    id: 5,
    component: <RightBracketIcon/>,
    type: FormulaItemType.BRACKET,
    value: ')'
  },
  {
    id: 6,
    component: <div>AND</div>,
    type: FormulaItemType.LOGICAL_OPERATOR,
    value: '&',
    menuValue: 'AND',
  },
  {
    id: 7,
    component: <div>OR</div>,
    type: FormulaItemType.LOGICAL_OPERATOR,
    value: '|',
    menuValue: 'OR',
  }
];

export const DECIMAL_SEPARATOR = '.';
export const SAFE_KEYS = [
  'ArrowLeft', 'ArrowRight', DECIMAL_SEPARATOR, ...operators.map((item) => item.value)
];

const canDropFormulaInside = (
  item: TemplateNode,
  customFormulaId: number | string,
  template: Template
) => {
  if (!isFormulaNode(item)) return true;
  if (item.id === customFormulaId) return false;
  const formulaNode = template.nodes[ item.id ];
  if (!formulaNode || !isFormulaNode((formulaNode))) return false;
  const customFormulaNode = template.nodes[ customFormulaId ];
  if (formulaNode.type !== customFormulaNode.type) return false;

  const elements = formulaNode.rowData.formulaElements;
  for (const el of elements) {
    if (el.type === FormulaItemType.TEMPLATE && el.templateNode === customFormulaId) {
      return false;
    }
    if (el.templateNode) {
      const templateNode = template.nodes[ el.templateNode ];
      if (templateNode && isFormulaNode(templateNode)) {
        return canDropFormulaInside(templateNode, customFormulaId, template);
      }
    }
  }

  return true;
};

export const canDropOnFormulaFromBottomPanel = (item: TemplateNode) => {
  return item?.type === RowType.DIMENSION_ITEM || item?.type === RowType.FINANCIALS;
};

export const canDropOnFormulaFromCanvas = (
  item: TemplateNode,
  customFormulaId: number | string,
  template: Template
) => {
  const isExcludedType = [
    RowType.TITLE,
    RowType.SPACER,
    RowType.HALF_SPACER,
    RowType.BREAKDOWN
  ].includes(item?.type);
  if (isExcludedType) {
    return false;
  }
  // New formula is nestable only when it has id provided from backend
  if (item.type === RowType.FORMULA && !item.rowData.id) {
    return false;
  }
  if (isFormulaNode(item)) {
    return canDropFormulaInside(item, customFormulaId, template);
  }

  return true;
};

export const isTemplateNodeFromCanvas = (item: TemplateNode) => item?.uuid != null;
