import React, { createContext, useContext, useReducer } from 'react';
import { ContextType } from 'types/app.types';
import { CompleteCounter, FilterItem, SortMenuItem } from 'types/revenueRecognition.types';
import i18n from '../locales/i18n';

const { t } = i18n;

type RevenueRecognitionContextType = {
  sort: SortMenuItem;
  filterCustomer: FilterItem;
  filterProduct: FilterItem;
  deleteProduct:number;
  completeCounter: CompleteCounter;
  addProduct: boolean;
};

const defaultState: RevenueRecognitionContextType = {
  completeCounter: {
    complete: 0,
    max: 0
  },
  sort: {
    field: 'totalRevenue',
    direction: 'desc',
    title: t('revenueRecognition:sort:revenue-desc'),
  },
  filterCustomer:{
    name:'All',
    index: 0,
    isFilter: false,
  },
  filterProduct:{
    name:'All',
    index: 0,
    isFilter: false,
  },
  deleteProduct: -1,
  addProduct: false,
};

interface ActionSort {
  type: 'SORT';
  payload: SortMenuItem;
}

interface ActionFilter {
  type: 'FILTER_CUSTOMER' | 'FILTER_PRODUCT';
  payload: FilterItem;
}

interface ActionDeleteProduct {
  type: 'DELETE_PRODUCT';
  payload: number;
}

interface ActionComplete {
  type: 'COMPLETE';
  payload: CompleteCounter;
}

interface ActionAddProduct {
  type: 'ADD_PRODUCT';
  payload: boolean;
}

type DispatchTypes =
  | ActionSort
  | ActionFilter
  | ActionDeleteProduct
  | ActionComplete
  | ActionAddProduct;

const RevenueRecognitionContext =
  createContext<ContextType<RevenueRecognitionContextType, DispatchTypes>>(undefined);

const revenueRecognitionReducer = (state: RevenueRecognitionContextType, action: DispatchTypes) => {
  switch (action.type) {
    case 'SORT': {
      return { ...state, sort: action.payload };
    }
    case 'FILTER_CUSTOMER': {
      return { ...state, filterCustomer: action.payload };
    }
    case 'FILTER_PRODUCT': {
      return { ...state, filterProduct: action.payload };
    }
    case 'DELETE_PRODUCT': {
      return { ...state, deleteProduct: action.payload };
    }
    case 'COMPLETE': {
      return { ...state, completeCounter: action.payload };
    }
    case 'ADD_PRODUCT': {
      return { ...state, addProduct: action.payload };
    }
  }
};

const RevenueRecognitionProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(revenueRecognitionReducer, defaultState);
  const value = { state, dispatch };

  return <RevenueRecognitionContext.Provider value={ value }>
    { children }
  </RevenueRecognitionContext.Provider>;
};

const useRevenueRecognition = () => {
  const context = useContext(RevenueRecognitionContext);
  if (context === undefined) {
    throw new Error('useRevenueRecognition must be used within Provider');
  }
  return context;
};

export { RevenueRecognitionProvider, useRevenueRecognition };
