import React, { useState } from 'react';
import { CommandBarCascade } from 'components/commandBar/types/commandBar.types';
import ContextMenu from 'components/elements/contextMenu/ContextMenu';
import CommandBarButton from 'components/commandBar/components/button/CommandBarButton';
import styles from './CommandBarCascadeButton.module.scss';
import dropdownStyles from '../../common/dropdown/CommandBarDropdown.module.scss';
import optionStyles from '../../common/dropdown/option/CommandBarDropdownOption.module.scss';

interface Props {
  button: CommandBarCascade;
}

const CommandBarCascadeButton = ({ button }: Props) => {
  const [ visible, setVisible ] = useState(false);
  return <ContextMenu
    trigger='click'
    isVisible={ visible }
    onVisibleChange={ (_visible) => {
      setVisible(_visible);
      button.onVisibleChange(_visible);
    } }
    offset={ [ 0, 0 ] }
    overlayClassName={ `${ dropdownStyles.dropdown } ${ styles.dropdown }` }
    itemClassName={ `${ optionStyles.option } ${ styles.option }` }
    items={ button.menu }
  >
    <CommandBarButton button={ button } className={ visible ? styles.activeDropdown : '' } />
  </ContextMenu>;
};

export default CommandBarCascadeButton;