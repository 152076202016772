/* eslint-disable no-empty-pattern */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  CustomFormula,
  FormulaItemType,
  isCounterpartyTypeNode,
  isDimensionItemNode,
  isFormulaNode,
  isTotalNode,
  TemplateNode,
  TemplateNodeType,
} from 'types/templates.types';
import {
  batchCreateNode,
  createNode,
  editTitleTemplateNode,
  moveNode,
  nodeSelector,
  removeNode,
  selectTemplate,
} from 'store/template.slice';
import {
  getDefaultRowName,
  getTemplateStyle,
  getUUID,
  isActionType,
  isImmutableActionType,
} from 'utils/templates.utils';
import useEscapeListener from 'hooks/useEscapeListener';
import DropPosition from 'components/templates/templateRow/dropPosition/DropPosition';
import TemplateRows from 'components/templates/templateRow/TemplateRows';
import EditText from 'components/templates/templateRow/editText/EditText';

import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/close.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as FxIcon } from 'assets/icons/fx-icon.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import styles from './TemplateRow.module.scss';
import { canBeDroppedOnto, getNodeName, isDimensionGroup, isSpacer } from 'utils/template.utils';
import {
  selectFinancialTags,
  selectHoverDimension,
} from 'store/templates.slice';
import { getDisplayName, isDisplayNameEmpty } from 'utils/common.utils';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { capitalize } from 'lodash';
import { RowType } from 'types/financials.types';
import { useTemplatesContext } from 'context/TemplatesContext';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import Tooltip from 'components/elements/tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { selectBreakdowns } from 'store/breakdowns.slice';
import {
  changeCustomFormulaFormat,
  changeSelectCustomFormulaId,
  clearCustomFormula,
  formulaSlice,
  selectCustomFormula,
  selectCustomFormulaId,
  updateCustomFormula
} from 'store/formula.slice';
import { getUnassignedNodeName } from '../../../utils/financials.utils';

type Props = {
  nodeId: number | string;
  level?: number;
  selectedNodes: (number | string)[];
  indexedRows: (number | string)[];
  isFirstRow: boolean;
  onSelectionChange: (a: number, b: boolean, c: boolean) => void;
  clearSelection: () => void;
};

const TemplateRow = ({
  nodeId,
  level = 1,
  selectedNodes,
  indexedRows,
  onSelectionChange,
  isFirstRow,
  clearSelection
}: Props) => {
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();
  const node = useAppSelector(nodeSelector(nodeId));
  const template = useAppSelector(selectTemplate);
  const selectHoverDimensions = useAppSelector(selectHoverDimension);
  const breakdowns = useAppSelector(selectBreakdowns);
  const financialTags = useAppSelector(selectFinancialTags);
  const [ isDeleting, setIsDeleting ] = useState(false);
  const [ editModeState, setIsEditModeState ] = useState(false);
  const rowContentRef = useRef(null);
  const { dispatch: templateContextDispatch } = useTemplatesContext();
  const selectFormula = useAppSelector(selectCustomFormulaId);
  const customFormula = useAppSelector(selectCustomFormula);
  useEscapeListener(rowContentRef, () => toggleEditMode(false), editModeState);
  const toggleEditMode = (value: boolean) => {
    setIsEditModeState(value);
    templateContextDispatch({ type: 'editMode', payload: value });
    if (value === true) {
      dispatch(clearCustomFormula());
    }
  };
  const isNewFormulaAndNotFetched = node?.type === RowType.FORMULA && !node?.rowData?.id;

  const customFormulaIsOpen = useMemo(() => customFormula !== null, [ customFormula ]);

  const nodeName = useMemo(() => {
    if (node) {
      return getNodeName(node, breakdowns.dimensions);
    }
    return '';
  }, [ node, breakdowns ]);

  useEffect(() => {
    if (editModeState) {
      clearSelection();
    }
  }, [ editModeState ]);

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, [ template ]);

  const [ { isOver, canDrop }, drop ] = useDrop(() => ({
    accept: 'tag',
    canDrop: (item: TemplateNode) => canBeDroppedOnto(item) && !customFormulaIsOpen,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
      canDrop: monitor.canDrop(),
    }),
    drop: async (item: TemplateNode) => {
      if (!customFormulaIsOpen) {
        if (isDimensionGroup(item)) {
          return dispatch(batchCreateNode(item, node, 'none'));
        }
        dispatch(createNode(item, node, 'none'));
      }
    }
  }), [ customFormulaIsOpen ]);

  const [ { isDragging, }, dragRow, dragPreview ] = useDrag(() => ({
    type: 'node',
    item: node,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    }),
  }), [ node ]);

  const [ { isOver: isOverNode, canDrop: canDropNode }, dropRow ] = useDrop(() => ({
    accept: 'node',
    canDrop: (droppedNode: TemplateNode) => canBeDroppedOnto(droppedNode),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
      canDrop: monitor.canDrop(),
    }),
    drop: (droppedNode: TemplateNode) => {
      !customFormulaIsOpen && dispatch(moveNode(droppedNode, node, 'none'));
    }
  }), [ template, customFormulaIsOpen ]);

  const getFormulaItemName = useCallback((el: CustomFormula) => {
    if (el.value) return { name: el.value, bold: false };
    else if (el.statutoryRow) {
      const tag = financialTags.nodes[ el.statutoryRow ];
      if (!isDisplayNameEmpty(tag?.rowData?.shortName)) {
        return { name: getDisplayName(tag?.rowData?.shortName) };
      }
      return { name: getDisplayName(tag?.rowData?.name) };
    } else if (el.templateNode) {
      const _node = Object.values(template.nodes).find(z => z.id === el.templateNode);
      return { name: getNodeName(_node, breakdowns.dimensions) };
    } else if (el.dimensionItem) {
      const dimensionId = el.dimensionItem;
      const element = breakdowns.dimensionItemMap[ dimensionId ] ||
        breakdowns.accountMap[ dimensionId ];

      return {
        name: getDisplayName(element?.name) || '',
        bold: false
      };
    } else if (el.nestedFormula) {
      const _node = Object.values(template.nodes).find(z =>
        z.type === RowType.FORMULA &&
        z.rowData.id === el.nestedFormula
      );
      if (!_node) return { name: t('formula-not-found') };
      return { name: getNodeName(_node, breakdowns.dimensions) };
    } else if (el.type === FormulaItemType.UNASSIGNED) {
      const dimension = breakdowns.dimensionMap[ el.dimension ];
      return { name: getUnassignedNodeName(dimension) };
    }
  }, [ template, financialTags, breakdowns ]);

  const getElementType = (elements: CustomFormula[], index: number) => {
    const element = elements[ index ];
    if (element.value === '(' || element.value === ')') {
      return FormulaItemType.BRACKET;
    }
    if (element.value === '&' || element.value === '|') {
      return FormulaItemType.LOGICAL_OPERATOR;
    }
    return FormulaItemType[ element.type ];
  };

  const mapResponseFormula = () => {
    if (!isFormulaNode(node)) return;
    return node.rowData.formulaElements.map((el, index) => {
      const { name, bold } = getFormulaItemName(el);
      return {
        id: +el.templateNode ||
          +el.statutoryRow ||
          +el.dimensionItem ||
          +el.dimension ||
          +el.nestedFormula ||
          +el.value,
        value: name,
        prev: index === 0 ? null : getElementType(node?.rowData?.formulaElements, index - 1),
        next: node?.rowData?.formulaElements.length === index + 1 ? null :
          getElementType(node?.rowData?.formulaElements, index + 1),
        type: getElementType(node?.rowData?.formulaElements, index),
        key: getUUID(),
        bold: bold,
        offset: el.offset,
        rollingAverage: el.rollingAverage,
      };
    });
  };

  const swapNameInFormula = (newTitle: string) => {
    if (customFormula !== null) {
      const updatedFormula = customFormula.map(el => {
        if (el.id === node.id) {
          return { ...el, value: newTitle };
        }
        return el;
      });
      dispatch(updateCustomFormula(updatedFormula));
    }
  };

  const onClick = (e: React.MouseEvent) => {
    if (!editModeState) {
      onSelectionChange(indexedRows.indexOf(nodeId), e.metaKey || e.ctrlKey, e.shiftKey);
    } else {
      return;
    }
    if (isFormulaNode(node) && selectFormula !== node.id) {
      if (isNewFormulaAndNotFetched) {
        return null;
      }
      dispatch(changeSelectCustomFormulaId(node.id));
      dispatch(changeCustomFormulaFormat({
        formatting: node.rowData.formatting,
        decimals: node.rowData.decimals
      }));
      dispatch(
        formulaSlice.actions.setCalculateDynamicTotals(node.rowData.calculateDynamicTotals)
      );
      dispatch(formulaSlice.actions.setCalculateRowTotals(node.rowData.calculateRowTotals));
      dispatch(
        formulaSlice.actions.setReverseChangesFormatting(node.rowData.reverseChangesFormatting)
      );
      dispatch(updateCustomFormula(mapResponseFormula()));
    }
  };

  const handleDeleteRow = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isNewFormulaAndNotFetched) {
      return;
    }

    const closeAll = () => {
      dispatch(removeNode(node?.id));
      dispatch(clearCustomFormula());
    };

    const isCustomFormulaInTemplate =
      Object.values(template.nodes).some(item => item.type === RowType.FORMULA);

    if (isCustomFormulaInTemplate) {
      const searchElement = Object.values(template.nodes)
        .filter(isFormulaNode)
        .find(item => item.rowData.formulaElements
          .find(el => node.id === el?.templateNode));

      if (!searchElement) {
        closeAll();
      }
    } else {
      closeAll();
    }
  };

  const formatTypeAffix = (type: string) => {
    return type === 'total' ? type.toUpperCase() : capitalize(type);
  };

  const getTitleAffix = () => {
    const type = node.type;

    if (node.rowData?.name === getDefaultRowName(type)) {
      return null;
    }
    return <span className={ styles.totalAffix }>
      &bull;
      { ' ' }
      { formatTypeAffix(type) }
    </span>;
  };

  const isElementInFormula = useMemo(() => {
    const nodes = Object.values(template.nodes);
    const formulas =
      nodes.filter(n => n.type === RowType.FORMULA) as TemplateNodeType<RowType.FORMULA>[];

    return formulas.some((formula) => {
      return formula.rowData.formulaElements.some((element) => {
        return element.templateNode === node.id;
      });
    });
  }, [ template, node ]);

  const onRename = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isNewFormulaAndNotFetched) {
      toggleEditMode(true);
    }
  };

  const getContent = () => {
    if (!node) return null;

    switch (node.type) {
      case RowType.FINANCIALS:
      case RowType.GROUP:
      case RowType.BREAKDOWN:
      case RowType.DIMENSION_ITEM:
      case RowType.COUNTERPARTY_TYPE:
      case RowType.SUBTOTAL:
      case RowType.FORMULA:
      case RowType.TOTAL:
      case RowType.SPACER:
      case RowType.TITLE:
        return (
          <div
            style={ { paddingLeft: `${ level * 25 + 30 }px` } }
            className={ styles.content }
            ref={ (el) => {
              drop(el);
              dropRow(el);
            } }
          >
            { isSpacer(node.type) && <DragIcon className={ styles.dragIcon } /> }

            <span
              className={ styles.text }
              style={ getTemplateStyle(node) }
              ref={ rowContentRef }
            >
              {
                !isSpacer(node.type) &&
                <DragIcon className={ `
                    ${ styles.dragIcon }
                    ${ isTotalNode(node) ? styles.hiddenIcon : '' }` }
                />
              }
              { isActionType(node?.type) ? (
                <>
                  {
                    !isImmutableActionType(node?.type) && editModeState ? (
                      <EditText
                        name={ nodeId + node.type }
                        setEditMode={ toggleEditMode }
                        editMode={ editModeState }
                        defaultTitle={ getDisplayName(node.rowData?.name) }
                        onSave={ (newTitle: string) => {
                          dispatch(editTitleTemplateNode(nodeId, newTitle));
                          swapNameInFormula(newTitle);
                        } }
                        className={ `${ styles[ node.type ] } ${ styles.isEditing }` }
                      />
                    ) : (
                      <>
                        {
                          isFormulaNode(node) ? (
                            <>
                              <div className={ styles.formula }>
                                <div className={ styles.icon }>
                                  <FxIcon />
                                </div>
                                <div className={ styles.name }>
                                  { nodeName }
                                </div>
                              </div>
                              {
                                !isImmutableActionType(node?.type) && (
                                  <button
                                    className={ styles.buttonIcon }
                                    onClick={ onRename }
                                  >
                                    <EditIcon />
                                  </button>
                                )
                              }
                            </>
                          ) : (
                            <>
                              <span
                                className={ styles[ node.type ] }
                                onDoubleClick={ onRename }
                                onClick={ onClick }
                              >
                                { nodeName }
                                { isFormulaNode(node) && getTitleAffix() }
                              </span>
                              {
                                !isImmutableActionType(node?.type) && (
                                  <button
                                    className={ styles.buttonIcon }
                                    onClick={ (e) => {
                                      e.stopPropagation();
                                      toggleEditMode(true);
                                    } }
                                  >
                                    <EditIcon />
                                  </button>
                                )
                              }
                            </>
                          )
                        }

                      </>
                    ) }
                </>
              ) : (
                <span className={ `${ styles[ node.type ] }
              ${ isCounterpartyTypeNode(node) ? styles.dimension_item : '' }
              ${ selectHoverDimensions !== null
                    && isDimensionItemNode(node)
                    && selectHoverDimensions.includes(node.rowData.id)
                  ? styles.hoverDimension : '' }`
                }>
                  { nodeName }
                </span>
              ) }
              <button
                onClick={ handleDeleteRow }
                className={ styles.buttonIcon }
                disabled={ isElementInFormula }
              >
                <DeleteIcon
                  onMouseEnter={ () => setIsDeleting(true) }
                  onMouseLeave={ () => setIsDeleting(false) }
                />
              </button>
              {
                isElementInFormula && (
                  <Tooltip
                    trigger={ [ 'hover' ] }
                    mouseEnterDelay={ 0.1 }
                    title={ t('templates.lock-deletion') }
                  >
                    <LockIcon className={ styles.lockIcon } />
                  </Tooltip>
                )
              }
            </span>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div
        style={ { position: 'relative' } }
        className={ styles.rowContainer }
        ref={ (el) => {
          dragRow(el);
        } }
      >
        <div className={ `${ styles.row
        } ${ node?.children?.length <= 0 ? styles.leaf : ''
        } ${ level === 1 ? styles.root : ''
        } ${ (isOver && canDrop) || (isOverNode && canDropNode) ? styles.isOver : '' }
        ${ isDragging ? styles.isDragging : '' }
        ${ isDeleting ? styles.rowDeleting : '' }
        ${ editModeState ? styles.editing : '' }
        ${ selectedNodes.includes(nodeId) ? styles.selected : '' }
        ${ isSpacer(node?.type) ? styles.rowSpacer : '' }`
        } onClick={ onClick }
        >
          { isFirstRow && <DropPosition position='top' node={ node } /> }
          <DropPosition position='bottom' node={ node } />
          { getContent() }
        </div>
      </div>
      <TemplateRows
        items={ node?.children }
        level={ level + 1 }
        indexedRows={ indexedRows }
        selectedNodes={ selectedNodes }
        onSelectionChange={ onSelectionChange }
        clearSelection={ clearSelection }
      />
    </>
  );
};

export default TemplateRow;
