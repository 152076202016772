import React from 'react';
import { CounterpartyType } from 'types/revenueRecognition.types';
import DefaultLogo from '../defaultLogo/DefaultLogo';

type Props = {
  size: number;
  url: string;
  className?: string;
  showDefaultLogo?: boolean;
  name?: string;
  type?: CounterpartyType;
};

const CounterpartyLogo = ({ url, size, className='', showDefaultLogo=true, name, type }: Props) => {
  const hasLogo = url !== '' && url !== null;

  return <>
    { hasLogo ?
      <img
        style={ { height: size, width: size } }
        src={ url }
        alt='Counterparty logo'
        className={ className }
      /> :
      showDefaultLogo ?
        <DefaultLogo 
          size={ size } 
          name={ name }
          type={ type }
          className={ className }
        /> 
        : null
    }
  </>;
};

export default CounterpartyLogo;