import React, { useCallback, useEffect, useRef, useState } from 'react';
import Card from 'components/elements/card/Card';
import { useFinancialTable } from 'context/FinancialTableContext';
import styles from './DynamicColumnDropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from 'assets/icons/short-arrow.svg';
import {
  ActionButtonOptions,
  CadenceColumnsSettings,
  ColumnType,
  DynamicSettings,
  DynamicSettingsConfiguration,
} from 'types/financials.types';
import DynamicColumnGroup from './dynamicColumnGroup/DynamicColumnGroup';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  getRenderElements,
  mapColumnSettings
} from '../dynamicColumns.utils';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { selectPeriod, toggleActionsButton } from 'store/financials.slice';
import Button from 'components/elements/button/Button';
import { Switch } from 'antd';
import useUpdateColumnsSettings from
  'components/financials/dynamicColumns/hooks/useUpdateColumnsSettings';
import useReportQuery from '../../financialTable/hooks/useReportQuery';

interface Props {
  onClose: () => void;
  visible: boolean;
  currentSettings: CadenceColumnsSettings;
}

const DropdownContent = ({ visible, onClose, currentSettings }: Props) => {
  const [ t, i18n ] = useTranslation('dynamicColumns');
  const dispatch = useAppDispatch();
  const {
    dispatch: dispatchContext,
    state: { templateId }
  } = useFinancialTable();
  const { cadence } = useAppSelector(state => state.financials.tables[ templateId ].period);
  const { dynamicColumns } = useAppSelector(state => state.financials.tables[ templateId ].state);
  const [ showDynamicColumns, setShowDynamicColumns ] = useState(false);
  const { reset, handleSubmit, control } = useForm<DynamicSettings>({
    defaultValues: mapColumnSettings(currentSettings, 'dropdown')
  });
  const dynamicSettings = useWatch({ control });
  const initialRef = useRef(true);
  const applied = useRef(false);
  const { update, isLoading } = useUpdateColumnsSettings({ templateId });

  const period = useAppSelector(selectPeriod(templateId));

  const { refetch } = useReportQuery({ templateId, period });

  useEffect(() => {
    if (visible) {
      applied.current = false;
    }
    return () => {
      if (!applied.current) {
        reset(mapColumnSettings(currentSettings, 'dropdown'));
      }
    };
  }, [ visible ]);

  useEffect(() => {
    setShowDynamicColumns(dynamicColumns);
  }, [ dynamicColumns ]);

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false;
      return;
    }
    reset(mapColumnSettings(currentSettings, 'dropdown'));
  }, [ currentSettings ]);

  const showDynamicModal = useCallback(() => {
    dispatchContext({ type: 'setModalDynamicColumn' });
    onClose();
  }, []);

  const updateSettings = (data: DynamicSettings) => {
    if (dynamicColumns !== showDynamicColumns) {
      dispatch(toggleActionsButton(templateId, ActionButtonOptions.DYNAMIC_COLUMNS));
    }

    update(data)
      .finally(() => {
        initialRef.current = true;
        applied.current = true;
        refetch();
        onClose();
      });
  };

  const checkActive = useCallback((active: boolean, index: number) => {
    const settings = dynamicSettings;
    settings[ cadence ].columns[ index ].active = active;
    reset(settings);
  }, [ cadence, dynamicSettings ]);

  const checkOptions = (options: string[], index: number) => {
    const settings = dynamicSettings;
    settings[ cadence ].columns[ index ].options = options;
    reset(settings);
  };

  const onElementClick = useCallback((element: DynamicSettingsConfiguration) => {
    const elements = dynamicSettings[ cadence ].columns;
    const indexOfElement = elements.findIndex(el => el.id === element.id);
    const active = !element.active;
    checkActive(active, indexOfElement);
    checkOptions(active ? element.optionsList : [], indexOfElement);
  }, [ checkActive, checkOptions, cadence, dynamicSettings ]);

  const getElementsByType = useCallback((type: ColumnType) => {
    const elements = dynamicSettings[ cadence ].columns as DynamicSettingsConfiguration[];
    return getRenderElements(elements, type);
  }, [ cadence, dynamicSettings, i18n.language ]);

  return (
    <Card className={ styles.content }>
      <div className={ styles.title }>
        { t('title') }
        <Switch checked={ showDynamicColumns } onChange={ setShowDynamicColumns } />
      </div>
      <Controller
        control={ control }
        name={ `${ cadence }.conditionalFormatting` }
        render={ ({ field: { value, onChange } }) => {
          return <div className={ `${ styles.title } ${ styles.subtitle }` }>
            { t('conditionalFormatting') }
            <Switch checked={ value } onChange={ onChange } />
          </div>;
        } }
      />
      <form onSubmit={ handleSubmit(updateSettings) }>
        <div>
          {
            Object.values(ColumnType).map(type => (
              <DynamicColumnGroup
                key={ `dynamic__column__${ type }` }
                type={ type }
                values={ getElementsByType(type) }
                onClick={ onElementClick }
                cadence={ cadence }
                disableOptions={ !showDynamicColumns }
              />
            ))
          }
        </div>
        <div className={ styles.footer }>
          <Button className={ styles.allSettings } type='link' onClick={ showDynamicModal }>
            { t('dropdown.all-settings') }
            <ArrowIcon />
          </Button>
          <Button
            aria-label='modal-ok'
            htmlType='submit'
            className={ styles.applyButton }
            loading={ isLoading }
          >
            { t('common:form.apply') }
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default DropdownContent;
