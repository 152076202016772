import { useRevenueRecognition } from 'context/RevenueRecognitionContext';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styles from './FilterIndicator.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/deleteIcon.svg';
import Capsule from 'components/elements/capsule/Capsule';

const FilterIndicator = () => {
  const {
    state: { filterCustomer, filterProduct },
  } = useRevenueRecognition();

  const { id: existId } = useParams();

  const nameSort = existId ? filterProduct : filterCustomer;

  const { dispatch } = useRevenueRecognition();

  const onClick = useCallback(() => {
    const payload = { ...filterProduct, name: 'All', index: 0 };
    if (existId) {
      dispatch({ type: 'FILTER_PRODUCT', payload });
    } else {
      dispatch({ type: 'FILTER_CUSTOMER', payload });
    }
  }, [ existId ]);

  return (
    nameSort.name !== 'All'
      ? <Capsule isActive={ true } onClick={ () => null }>
        { nameSort.name }
        <CloseIcon onClick={ onClick } className={ styles.icon }/>
      </Capsule>
      : null
  );
};

export default FilterIndicator;