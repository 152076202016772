import React from 'react';
import Modal from '../../elements/modal/Modal';
import styles from '../Modals.module.scss';
import WindowChart from '../../charts/windowChart/WindowChart';
import { ChartSettings } from 'types/chart.types';

interface Props {
  onClose: () => void;
  isVisible: boolean;
  defaultSettings: ChartSettings | null;
}
const ChartModal = ({ onClose, isVisible, defaultSettings }: Props) => {
  return <Modal
    onConfirm={ onClose }
    onClose={ onClose }
    isVisible={ isVisible }
    disableButtons={ true }
    focusTriggerAfterClose={ false }
    className={ styles.modal }
  >
    <WindowChart
      className={ styles.chart }
      disableClose={ false }
      defaultSettings={ defaultSettings }
    />
  </Modal>;
};
export default ChartModal;