import React, { useCallback, useEffect, useState } from 'react';
import SearchInput from 'components/elements/searchBar/searchInput/SearchInput';
import { useSearchable } from 'context/SearchableContext';
import { useTranslation } from 'react-i18next';
import styles from './SearchBar.module.scss';
import { debounce } from 'lodash';

type Props = {
  showSeparator?: boolean;
  className?: string;
  showResults?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  callback?: (e) => void;
};
const SearchBar = ({
  placeholder,
  showSeparator = true,
  className = '',
  showResults = true,
  callback,
  autoFocus = false,
}: Props) => {
  const [ t ] = useTranslation('common');
  const { dispatch, state: { search, results, clear } } = useSearchable();
  const [ value, setValue ] = useState(search);

  const debounceSetSearch = useCallback(debounce((_value: string) => {
    dispatch({ type: 'search', payload: _value });
  }, 200), []);

  useEffect(() => {
    debounceSetSearch(value);
  }, [ value ]);

  useEffect(() => {
    if (clear) {
      setValue('');
    }
  }, [ clear ]);

  return (
    <div className={ `
      ${ styles.searchBar }
      ${ !showSeparator ? styles.hideSeparator : '' }
      ${ className }` }
    >
      <SearchInput
        value={ value }
        callback={ callback }
        autoFocus={ autoFocus }
        onClear={ () => setValue('') }
        onChange={ (e) => setValue(e.target.value) }
        placeholder={ placeholder || t('search.placeholder') }
      />
      {
        showResults ?
          <div className={ `${ styles.results } ${ search === '' ? styles.resultsHidden : '' } ` }>
            { `${ results } ${ t('search.results') }` }
          </div> : null
      }
    </div>
  );
};

export default SearchBar;
