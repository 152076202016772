import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { InputNumber, Tooltip } from 'antd';
import styles from './Editors.module.scss';
import { inRange } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormulaRow } from 'components/formulaRow/FormulaRowContext';

interface FieldProps {
  value: number;
  field: string;
  headerName: string;
  disabled?: boolean;
  placeholder?: string;
  setValue: (value: number) => void;
}

interface ValidationProps {
  min: number;
  max: number;
  disallowZero?: boolean;
  disableDecimals?: boolean;
  optional?: boolean;
}

type ValidationState =
  | 'valid'
  | 'range-error'
  | 'zero-error'
  | 'required-error';

const NumericCellEditor = (props: FieldProps & ValidationProps) => {
  const [ t ] = useTranslation('common');
  const [ value, setValue ] = useState(+props.value);
  const refInput = useRef<HTMLInputElement>(null);
  const field = props.headerName;

  const { dispatch, state: { open } } = useFormulaRow();

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  const valid: ValidationState = useMemo(() => {
    if (value == null && !props.optional) {
      return 'required-error';
    }
    if (props.optional && (+value === 0 || isNaN(value))) {
      return 'valid';
    }
    if (props.disallowZero && +value === 0) {
      return 'zero-error';
    }
    if (props.min && props.max && !inRange(+value, props.min, props.max + 1)) {
      return 'range-error';
    }
    return 'valid';
  }, [ value, props ]);

  const validationTitle = useMemo(() => {
    if (valid === 'required-error') {
      return t('validation.requiredField', { field });
    }
    if (valid === 'zero-error') {
      return t('validation.zeroField', { field });
    }
    if (valid === 'range-error') {
      return t('validation.outOfRangeField', { field, min: props.min, max: props.max });
    }
    return undefined;
  }, [ valid ]);

  const onChange = (val: number) => {
    setValue(val);
    props.setValue(val);
  };

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
      props.setValue(props.value);
    }
  }, [ props.value ]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isInteger = /^[0-9]+$/;
    const isNegativeIdentifier = e.key === '-';
    const isStartOfFormula = props.field === 'amount' && e.key === '=';

    if (isStartOfFormula && !open) {
      dispatch({ type: 'OPEN' });
    }
    if (props.disableDecimals &&
        !(isInteger.test(e.key.toString()) ||
        isNegativeIdentifier || isStartOfFormula)) {
      e.preventDefault();
    }
  };

  return (
    <Tooltip
      open={ validationTitle != null }
      title={ validationTitle }
    >
      <InputNumber
        ref={ refInput }
        value={ value }
        disabled={ props.disabled }
        className={ styles.numericCell }
        placeholder={ props.placeholder }
        status={ valid === 'valid' ? null : 'error' }
        onChange={ onChange }
        decimalSeparator=','
        onKeyPress={ onKeyPress }
      />
    </Tooltip>
  );
};

NumericCellEditor.displayName = 'NumericCellEditor';
export default NumericCellEditor;
