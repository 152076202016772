import {
  ChangePasswordRequest,
  ForgotPasswordRequest,
  LoginCredentials,
  TokenRefreshRequest,
  TokenRefreshResponse,
  User
} from 'types/auth.types';
import { endpoints } from 'utils/config.utils';
import http, { httpNoInterceptors } from 'utils/http.utils';
import { WebsocketTokenResponse } from '../websockets/types/service.types';

const login = (data: LoginCredentials) => 
  httpNoInterceptors.post(endpoints.users.login, data);

const logoutUser = () => http.post(endpoints.users.logout);

const getUserData = () => http.get<User>(endpoints.users.me);

const tokenRefresh = (data: string) =>
  httpNoInterceptors.post<TokenRefreshResponse>(
    endpoints.users.token.refresh, 
    { refresh: data } as TokenRefreshRequest
  );

const resetPassword = (data: ForgotPasswordRequest) =>
  httpNoInterceptors.post(endpoints.users.password.reset, data);

const changePassword = (data: ChangePasswordRequest) =>
  httpNoInterceptors.post(endpoints.users.password.confirm, data);

const getWebsocketToken = () => http.post<WebsocketTokenResponse>(endpoints.users.websocket.token);

export { 
  login, logoutUser, getUserData, tokenRefresh, resetPassword, changePassword, getWebsocketToken
};
