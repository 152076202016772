import React, { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import ReactECharts from 'echarts-for-react';
import { ChartSettings, ChartSource } from 'types/chart.types';
import { EChartsCoreOption } from 'echarts';
import styles from './Chart.module.scss';
import { separateThousands } from 'utils/financials.utils';
import { useFinancialTable } from 'context/FinancialTableContext';
import isBetween from 'dayjs/plugin/isBetween';
import globalColors from 'utils/colors.utils';
import { useFormContext, useWatch } from 'react-hook-form';
import Loader from 'components/elements/loader/Loader';
import { useChartContext } from '../context/ChartContext';
import { useResizeDetector } from 'react-resize-detector';
import { ReportType } from 'types/templates.types';
import useChartSeries from 'components/charts/hooks/useChartSeries';
import {
  dateFormatter,
  formatSeriesName,
  getDatasetIndexFromSeries,
  getReportTypeFromSeries,
  xAxisFormatter,
  yAxisFormatter
} from 'components/charts/chart/utils/chart.utils';
import useChartTooltip from 'components/charts/hooks/useChartTooltip';
import useTemplateNode from 'hooks/useTemplateNode';
import { ChartData } from 'components/charts/types/chart.types';
import _ from 'lodash';
import { Period } from 'types/financials.types';
import { isLineChart } from 'utils/chart.utils';
import { useAppSelector } from '../../../store/hooks/hooks';
import { CHART_DATASETS } from '../../../utils/dashboard.utils';

dayjs.extend(isBetween);

interface Props {
  className?: string;
  period: Period;
  shouldAdjustNumberOfSplitLines?: boolean;
}

interface SeriesData {
  label: string;
  value: number;
  itemStyle: {
    color: number;
  };
}

const Chart = ({ className = '', period, shouldAdjustNumberOfSplitLines = true }: Props) => {
  const { control } = useFormContext<ChartSettings>();
  const settings = useWatch({ control }) as ChartSettings;
  const { state: { templateId } } = useFinancialTable();
  const tableLoaded = useAppSelector((state) => state.financials.tablesLoadingState[ templateId ]);

  const { dispatch } = useChartContext();
  const { findOption, getNodeOptions } = useTemplateNode({ templateId, type: 'chart' });
  const datasetOptions = useMemo(() => getNodeOptions(), [ getNodeOptions ]);
  const chartRef = React.useRef<ReactECharts>(null);
  const { ref } = useResizeDetector<HTMLDivElement>({
    refreshMode: 'debounce',
    refreshRate: 50,
    onResize: () => {
      const instance = chartRef.current && chartRef.current.getEchartsInstance();
      if (instance) {
        instance.resize();
      }
    }
  });
  const { getSeries, values, dates, barStacks } = useChartSeries(
    { period, settings, templateId, cumulative: settings.other.cumulativeValues });
  const { tooltipFormatter } = useChartTooltip(
    { settings, period, templateId, cumulative: settings.other.cumulativeValues });

  const tooltipDataset = useMemo(() => {
    let datasetIndex = 0;
    const primaryDataset = settings.datasets[ 0 ];
    const secondaryDataset = settings.datasets[ 1 ];

    if (primaryDataset.active && primaryDataset.templateNode) {
      datasetIndex = 0;
    } else if (secondaryDataset.active && secondaryDataset.templateNode) {
      datasetIndex = 1;
    }

    return findOption(datasetOptions, settings.datasets[ datasetIndex ].templateNode)?.label;
  }, [ settings, datasetOptions ]);

  useEffect(() => {
    const value = values.at(-1);
    const date = dates.at(-1);
    const tooltipText = bigNumberTooltipFormatter(date);
    updateHoverValue(Math.round(value));
    updateTooltipText(tooltipText);
  }, [ tooltipDataset ]);

  const axisFontStyles = useMemo(() => ({
    fontSize: 16,
    fontFamily: 'Roboto',
    color: globalColors[ 'indigoGray700' ]
  }), []);

  const series = useMemo(() => {
    return Array.from({ length: CHART_DATASETS } , (_el, index) => {
      return getSeries(index);
    }).flat();
  }, [ getSeries ]);

  const primarySeries = useMemo(() => {
    return [
      ...getSeries(0)
    ];
  }, [ settings, getSeries, values ]);

  const getSeriesAxis = useCallback((seriesName: string): 'left' | 'right' => {
    const seriesIndex = getDatasetIndexFromSeries(seriesName);
    return settings.datasets[ seriesIndex ]?.axisPosition ?? 'left';
  }, [ settings, series ]);

  const checkedSeries = useMemo(() => {

    const bothActive = settings.datasets[ 0 ].active && settings.datasets[ 1 ].active;
    const bothLine =
      isLineChart(settings.datasets[ 0 ].style) && isLineChart(settings.datasets[ 1 ].style);

    const primarySerie = getSeries(0) || [];
    const secondarySerie = getSeries(1) || [];

    if (bothActive && bothLine) {
      const isPrimaryActual = settings.datasets[ 0 ].datePickerSource === ChartSource.ACTUALS;
      const isSecondaryPlan = settings.datasets[ 1 ].datePickerSource === ChartSource.BUDGET;
      if (isPrimaryActual && isSecondaryPlan) {
        const secondaryData = secondarySerie?.[ 0 ]?.data;

        const lastNotNullIndex =
              _.findLastIndex(primarySerie?.[ 0 ]?.data, ({ value }) => value !== null);
        const lastNullIndex = _.findIndex(secondaryData, ({ value }) => value !== null) - 1;

        const shouldConnect = lastNullIndex === lastNotNullIndex;

        if (shouldConnect) {
          const dataModified =
                    secondaryData
                      ?.map((el, idx) =>
                        (idx === lastNullIndex) ?
                          primarySerie?.[ 0 ]?.data?.[ lastNotNullIndex ] : el);
          if (secondarySerie[ 0 ]?.data) {
            secondarySerie[ 0 ].data = dataModified;
          }
        }
      }
    }

    return [ ...series ];
  }, [ settings, getSeries, settings ]);

  const getSplitLinesNumber = useCallback(() => {
    if (!shouldAdjustNumberOfSplitLines) {
      return;
    }

    const height = ref?.current?.getBoundingClientRect().height;
  
    if (height < 330) {
      return 2;
    } else if (height < 400) {
      return 3;
    } else {
      return 5;
    }
  }, [ ref?.current?.getBoundingClientRect().height ]);

  const option: EChartsCoreOption = useMemo(() => {
    return ({
      alignTicks: true,
      xAxis: [ {
        type: 'category',
        data: dates,
        axisLabel: {
          formatter: (date) => xAxisFormatter(date, period.cadence),
          ...axisFontStyles,
          fontSize: 14,
        },
      } ],
      yAxis: [
        {
          type: 'value',
          position: 'left',
          splitNumber: getSplitLinesNumber(),
          axisLabel: {
            formatter: (value) => yAxisFormatter(value, 0, settings),
            ...axisFontStyles,
          },
        },
        {
          type: 'value',
          position: 'right',
          splitNumber: getSplitLinesNumber(),
          axisLabel: {
            formatter: (value) => yAxisFormatter(value, 1, settings),
            ...axisFontStyles,
          },
        }
      ],
      series: checkedSeries.
        map((serie, index) => {
          return {
            ...serie,
            data: serie.data.map( (data: SeriesData, i: number) => {
              if (serie.stack) {
                let stack = null;
                const serieType = getDatasetIndexFromSeries(serie.name);
                if (serieType === 0) {
                  stack = barStacks[ 0 ][ i ][ index ];
                } else if (serieType === 1) {
                  stack = barStacks[ 1 ][ i ][ index - primarySeries.length ];
                }
                const axisPosition = getSeriesAxis(serie.name);
                const formatting = axisPosition === 'left' ?
                  settings.other.leftAxisFormat : settings.other.rightAxisFormat;

                if (stack?.value === null) {
                  return null;
                }
                return {
                  ...stack,
                  itemStyle: {
                    ...stack?.itemStyle,
                    borderColor: 'white',
                    borderWidth: 0.5,
                  },
                  value: formatting === 'percentage' ? stack?.value * 100 || null : stack.value,
                };
              }
              return data;

            }),
            barMinHeight: 0,
            yAxisIndex: (() => {
              const datasetIndex = getDatasetIndexFromSeries(serie.name);

              return settings.datasets[ datasetIndex ]?.axisPosition === 'left' ? 0 : 1;
            })(),

          };
        }),
      tooltip: {
        confine: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          z: -1,
          shadowStyle: {
            shadowColor: globalColors[ 'shadow' ],
          }
        },
        formatter: tooltipFormatter,
        valueFormatter: (value: number) => separateThousands(Math.round(value)),
        extraCssText: 'border-radius: 2px;',
      },
      legend: {
        show: settings.other.legend,
        type: 'scroll',
        top: 'bottom',
        formatter: formatSeriesName,
        textStyle: {
          ...axisFontStyles,
          fontSize: 14,
        },
        itemWidth: 12,
        itemHeight: 12,
        itemGap: 8,
        data: series.sort((a, b) => {
          const aType = getReportTypeFromSeries(a.name);
          const bType = getReportTypeFromSeries(b.name);
          if (aType === bType) return a.name.localeCompare(b.name);

          return aType === ReportType.ACTUAL ? -1 : 1;
        }).map((s) => {
          const notEmptyData = s.data.filter((d) => d.value !== null);
          return {
            name: s.name,
            itemStyle: notEmptyData[ 0 ]?.itemStyle ?? s.data[ 0 ]?.itemStyle,
          };
        })
      }
    });
  }, [ 
    settings, 
    checkedSeries, 
    series, 
    values,
    yAxisFormatter, 
    period?.cadence, 
    barStacks, 
    getSplitLinesNumber,
  ]);

  const updateHoverValue = useCallback((value: number) => {
    dispatch({
      type: 'UPDATE_HOVER_VALUE',
      payload: value
    });
  }, []);

  const updateTooltipText = useCallback((tooltipText: string) => {
    dispatch({
      type: 'UPDATE_TOOLTIP',
      payload: tooltipText,
    });
  }, []);

  const bigNumberTooltipFormatter = useCallback((date: string) => {
    const tooltipText = `${ tooltipDataset } ${ dateFormatter(date, period.cadence) }`;
    return tooltipText;
  }, [ settings, tooltipDataset, period.cadence ]);

  const onEvents = useMemo(() => ({
    highlight: (params) => {
      let primaryTotal = 0;
      let secondaryTotal = 0;

      const date = params.batch && dates[ params.batch[ 0 ].dataIndex ];

      if (params.batch) {
        for (const b of params.batch) {
          const data = series[ b.seriesIndex ]?.data[ b.dataIndex ] as ChartData;
          if (data.hideValue) continue;

          if (getDatasetIndexFromSeries(series[ b.seriesIndex ].name) === 0) {
            primaryTotal += data.value;
          } else {
            secondaryTotal += data.value;
          }
        }
      }

      primaryTotal = settings.datasets[ 0 ].style === 'grouped' ?
        primaryTotal / 2 : primaryTotal;
      secondaryTotal = settings.datasets[ 1 ].style === 'grouped' ?
        secondaryTotal / 2 : secondaryTotal;

      if (settings.datasets[ 0 ].active !== settings.datasets[ 1 ].active) {
        if (settings.datasets[ 0 ].active) {
          updateHoverValue(Math.round(primaryTotal));
          updateTooltipText(bigNumberTooltipFormatter(date));
        } else {
          updateHoverValue(Math.round(secondaryTotal));
          updateTooltipText(bigNumberTooltipFormatter(date));
        }
      } else {
        updateHoverValue(Math.round(primaryTotal));
        updateTooltipText(bigNumberTooltipFormatter(date));

      }
    },
    globalout: () => {
      const value = values.at(-1);
      const date = dates.at(-1);
      updateHoverValue(Math.round(value));
      updateTooltipText(bigNumberTooltipFormatter(date));
    }
  }), [ series ]);

  const chartHeight = useMemo(() => {
    const isPrimaryActive = settings.datasets[ 0 ].active;
    const isSecondaryActive = settings.datasets[ 1 ].active;
    const primaryHeight = isPrimaryActive ? 80 : 0;
    const secondaryHeight = isSecondaryActive ? 70 : 0;
    const offset = isPrimaryActive && isSecondaryActive ? 30 : 0;
    return primaryHeight + secondaryHeight - offset;
  }, [ settings ]);

  return <div
    ref={ ref }
    style={ { height: `calc(100% - ${ chartHeight }px)` } }
    className={ `${ styles.chart } ${ className }` }
  >
    <Loader isActive={ !tableLoaded }/>
    {
      tableLoaded &&
      <ReactECharts
        option={ option }
        opts={ { renderer: 'svg' } }
        notMerge={ true }
        lazyUpdate={ true }
        shouldSetOption={ () => true }
        onEvents={ onEvents }
        ref={ chartRef }
      />
    }

  </div>;
};

export default Chart;
