import React, { forwardRef } from 'react';
import { Select, SelectProps } from 'antd';
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import styles from './FlatSelect.module.scss';
import SpinningLoader from 'components/elements/spinning-loader/SpinningLoader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FlatSelect = forwardRef<any, SelectProps>((props, selectRef) => {
  return <Select
    ref={ selectRef }
    // This is disabling a virtualization. You must set a width for large datasets.
    dropdownMatchSelectWidth={ false }
    className={ `${ styles.select } ${ props.className ?? '' }` }
    suffixIcon={ props.loading ?
      <SpinningLoader size={ 12 } className={ styles.loader } /> :
      <Chevron className={ `${ styles.arrow } ${ props.disabled ? styles.disabled : '' }` } />
    }
    { ...props }
  />;
});

FlatSelect.displayName = 'FlatSelect';

export default FlatSelect;
