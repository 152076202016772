import React from 'react';
import { useTranslation } from 'react-i18next';
import InlineStatement from 'components/elements/inlineStatement/InlineStatement';

interface Props {
  display: boolean;
}

const OverlappingColumnsInfo = ({ display }: Props) => {
  const [ t ] = useTranslation('dynamicColumns');

  if (!display) return null;
  return (
    <InlineStatement type='info'>
      { t('overlappingColumnsInfo') }
    </InlineStatement>
  );
};

export default OverlappingColumnsInfo;
