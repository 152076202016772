import React, { useCallback, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';
import {
  addProductList,
  addSelectProduct,
  editListProduct,
  removeSelectProduct,
  selectAllProduct,
  selectProduct,
  selectProductsList,
} from '../../../store/revenueRecognition.slice';
import { Input, Popover } from 'antd';
import styles from './Products.module.scss';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/bin.svg';
import { useRevenueRecognition } from 'context/RevenueRecognitionContext';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import Capsule from 'components/elements/capsule/Capsule';

interface Props{
  name: string;
  editIcon?: boolean;
  id: number;
}

const Products = ({ name, id }: Props) => {
  const [ t ] = useTranslation('revenueRecognition');
  const dispatch = useAppDispatch();
  const selectProducts = useAppSelector(selectProduct);
  const productsList = useAppSelector(selectProductsList);
  const isSelected = useAppSelector(state =>
    state.revenueRecognition.products.selectProductsList.some(el => el.id === id)
  );
  const isEmpty = name.length === 0 ;
  const [ editMode, setEditMode ] = useState(isEmpty);
  const [ editInputValue, setEditInputValue ] = useState(name);
  const [ visibleMenu, setVisibleMenu ] = useState(false);

  const { dispatch: dispatchContext } = useRevenueRecognition();

  const handleOnClick = () => {
    if (editMode) {
      return;
    }
    if (isSelected) {
      if (id === -1) dispatch(selectAllProduct([]));
      else {
        dispatch(removeSelectProduct({ name, id }));
        dispatch(removeSelectProduct({ name: 'All', id: -1 }));
      }
    } else {
      if (id === -1) {
        const allSelected = [ ...selectProducts, { name, id } ];
        selectProducts.length > 0 && dispatch(selectAllProduct(allSelected));
      } else {
        dispatch(addSelectProduct({ name,id }));
        productsList.length === selectProducts.length -1
          && dispatch(addSelectProduct({ name: 'All',id: -1 }));
      }
    }
  };

  const handleAddOrEdit = () => {
    setEditMode(true);
    setVisibleMenu(false);
  };

  const handleAccept = () => {
    if (isEmpty) {
      if (editInputValue.length) {
        dispatch(addProductList(editInputValue));
      }
    } else {
      editInputValue.length > 0
        ? dispatch(editListProduct({ id, name: editInputValue }))
        : setEditInputValue(name);
    }
    setEditMode(false);
    closeAddProduct();
  };

  const closeAddProduct = useCallback(() => {
    dispatchContext({ type: 'ADD_PRODUCT', payload: false });
  }, []);

  const onOutsideClick = () => {
    editMode && handleAccept();
  };

  const handleDeleteProduct = () => {
    setVisibleMenu(false);
    dispatchContext({ type: 'DELETE_PRODUCT', payload: id });
  };

  const contextMenuList = [
    {
      id: 0,
      name: t('common:edit'),
      icon: <EditIcon />,
      event: handleAddOrEdit
    },
    {
      id: 1,
      name: t('common:delete'),
      icon: <DeleteIcon />,
      event: handleDeleteProduct,
    }
  ];

  const contextMenu = (
    <div className={ styles.contextMenu } data-shadow='false'>
      {
        contextMenuList.map(el => <div className={ styles.option } key={ el.id }>
          <span onClick={ el.event }>
            { el.icon }
            { el.name }
          </span>
        </div>
        )
      }
    </div>
  );

  return (
    <OutsideClickHandler onOutsideClick={ onOutsideClick }>
      <Popover
        open={ visibleMenu }
        onOpenChange={ () => setVisibleMenu(prev => !prev) }
        placement='bottomLeft'
        content={ id !== -1 ? contextMenu : null }
        trigger='contextMenu'
      >
        <span>
          <Capsule
            className={ styles.capsule }
            isActive={ isSelected || editMode }
            onClick={ handleOnClick }
            id={ id.toString() }
          >
            {
              editMode || isEmpty
                ? <Input
                  autoFocus
                  className={ styles.editInput }
                  bordered={ false }
                  placeholder={ t('singleRevenueRecognition.input-placeholder-new') }
                  maxLength={ 500 }
                  value={ editInputValue }
                  onChange={ (e) => setEditInputValue(e.target.value) }
                  onPressEnter={ handleAccept }
                />
                : <span className={ styles.tagName } >{ name }</span>
            }
          </Capsule>
        </span>
      </Popover>
    </OutsideClickHandler>
  );
};

export default Products;
