import React from 'react';
import SpinningLoader from 'components/elements/spinning-loader/SpinningLoader';
import styles from './LoadingWrapper.module.scss';

interface Props {
  children: React.ReactNode;
  loading: boolean;
}

const LoadingWrapper = ({ children, loading }: Props) => {
  if (loading) {
    return <div className={ styles.loadingContainer }>
      <SpinningLoader />
    </div>;
  }

  return <>
    { children }
  </>;
};

export default LoadingWrapper;
