import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/toast-close.svg';
import styles from './Header.module.scss';
import BurgerDropdown, { BurgerOption } from 'components/elements/burgerDropdown/BurgerDropdown';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { chatSlice } from 'store/chat.slice';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import organizationsService from 'services/organizations.service';

interface Props {
  onClose: () => void;
}

const Header = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('collaboration');
  const hasConsent = useAppSelector(state => state.chat.isConsentGiven);

  const options = useMemo((): BurgerOption[] => {
    const _options: BurgerOption[] = [
      {
        title: t('chat.utils.clear-history'),
        onClick: () => dispatch(chatSlice.actions.clearHistory())
      },

    ];
    if (hasConsent) {
      _options.push({
        title: t('chat.utils.revokeConsent'),
        onClick: () => {
          organizationsService.chatbotConsent(false);
          dispatch(chatSlice.actions.setConsent(false));
        }
      });
    }
    return _options;
  }, [ hasConsent ]);

  return <div className={ styles.header }>
    <h3>
      { t('chat.title') }
    </h3>
    <div className={ styles.utils }>
      <BurgerDropdown
        options={ options }
        placement='bottom'
      />
      <GhostIconButton className={ styles.close } onClick={ onClose } withSeparator>
        <CloseIcon height={ 24 } width={ 24 } />
      </GhostIconButton>
    </div>
  </div>;
};

export default Header;
