import Breadcrumbs from 'components/elements/breadcrumbs/Breadcrumbs';
import DefaultLogo from 'components/elements/defaultLogo/DefaultLogo';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import {
  fetchAndSetCounterparty,
  resetCounterparty,
  selectSingleCounterparty
} from 'store/revenueRecognition.slice';

import styles from './CounterpartyBreadcrumbs.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useTranslation } from 'react-i18next';

type Props = {
  counterpartyId: number;
  onGoBack: () => void;
};

const CounterpartyBreadcrumbs = ({ counterpartyId, onGoBack }: Props) => {
  const dispatch = useAppDispatch();
  const singleCounterparty = useAppSelector(selectSingleCounterparty);
  const [ t ] = useTranslation();

  useEffect(() => {
    dispatch(fetchAndSetCounterparty(counterpartyId));
    return () => {
      dispatch(resetCounterparty());
    };
  } ,[]);

  const formatName = (name: string) => {
    // t('common:counterparty-type.employee')
    // t('common:counterparty-type.customer')
    // t('common:counterparty-type.supplier')
    return capitalize(t('common:counterparty-type.' + name.toLowerCase()));
  };

  return (
    <Breadcrumbs onGoBack={ onGoBack }>
      {
        singleCounterparty && (
          <>
            {
              singleCounterparty.pictureUrl !== '' ? (
                <img
                  className={ styles.logo }
                  alt='icon' src={ singleCounterparty.pictureUrl }/>
              ) :
                <DefaultLogo
                  className={ styles.defaultLogo }
                  size={ 35 }
                  name={ singleCounterparty.name }
                  type={ singleCounterparty.type }/>
            }
            <span className={ styles.name } >{ singleCounterparty.name }</span>
            <span className={ styles.separator }>&bull;</span>
            <span className={ styles.type }>{ formatName(singleCounterparty.type) }</span>
          </>
        )
      }
    </Breadcrumbs>
  );
};

export default CounterpartyBreadcrumbs;
