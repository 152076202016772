import React, { useMemo } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-rounded.svg';
import styles from './ContractsData.module.scss';
import BurgerDropdown from 'components/elements/burgerDropdown/BurgerDropdown';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useModalContext } from 'context/ModalContext';
import { useTranslation } from 'react-i18next';
import ContractForm from './contractForm/ContractForm';
import { useAppDispatch } from '../../../../store/hooks/hooks';
import { DimensionItem } from '../../../../types/filterTable.types';
import organizationsService from '../../../../services/organizations.service';
import { breakdownsActions } from '../../../../store/breakdowns.slice';
import { getDimensionViewName } from 'utils/financials.utils';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

interface Props {
  item: DimensionItem;
  showAddButton: boolean;
}

const Contract = ({ item, showAddButton }: Props) => {
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('contracts');
  const { dispatch: dispatchContext } = useModalContext();

  const burgerOptions = useMemo(() => (
    [
      {
        title: t('common:edit'),
        icon: <EditIcon />,
        onClick: () => dispatchContext({ type: 'SHOW_EDIT_MODAL', payload: item }),
      },
      {
        title: t('common:delete'),
        icon: <BinIcon />,
        onClick: () => organizationsService.deleteDimensionItem(item.id).then(() => {
          dispatch(breakdownsActions.deleteDimensionItem({ itemId: item.id }));
        }),
      }
    ]
  ), [ item ]);

  return (
    <>
      <div className={ styles.contract }>
        <div className={ styles.header }>
          <div className={ styles.nameHeader }>
            <h1>{ getDimensionViewName(item) }</h1>
            <span className={ styles.identifier }>
              ID
              { ' ' }
              { item.id }
            </span>
            <BurgerDropdown
              options={ burgerOptions }
              placement='bottomLeft'
              optionClassName={ styles.option }
            />
          </div>
          {
            showAddButton && <GhostIconButton
              onClick={ () => dispatchContext({ type: 'SHOW_ADD_MODAL' }) }
              className={ styles.addButton }
            >
              <PlusIcon />
            </GhostIconButton>
          }
        </div>
        <ContractForm contract={ item } />
      </div>
    </>
  );
};

export default Contract;
