import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store/store';
import {
  TemplateNodeMapping,
  TemplateNodeType,
  UserTemplateShort,
  isFinancialsNode,
  TemplateNode,
  FavoriteTemplate,
  FinancialTagsExpanded,
} from 'types/templates.types';
import { reportsService } from 'services/reports.services';
import { StatutoryTemplate } from 'types/statutory.types';
import { RowType } from 'types/financials.types';
import { arrayToMap } from 'utils/mapping.utils';

type TemplatesStore = {
  financialTags: FinancialTagsExpanded<TemplateNodeMapping<RowType.FINANCIALS>>;
  dimensions: FinancialTagsExpanded<TemplateNodeMapping<RowType.BREAKDOWN>>;
  templateList: UserTemplateShort[];
  systemTemplateList: UserTemplateShort[];
  presets: UserTemplateShort[];
  errors: string[];
  hoverDimension: (number | string)[] | null;
};

export const initialState: TemplatesStore = {
  financialTags: {
    roots: [],
    nodes: {},
    expandedNodeId: 0
  },
  dimensions: {
    roots: [],
    nodes: {},
    expandedNodeId: 0
  },
  templateList: [],
  systemTemplateList: [],
  hoverDimension: null,
  presets: [],
  errors: [],
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setHoverDimension: (state, action: PayloadAction<Array<number | string> | null>) => {
      state.hoverDimension = action.payload;
    },
    addTemplate: (state, action: PayloadAction<UserTemplateShort>) => {
      state.templateList = [ ...state.templateList, action.payload ];
    },

    setFinancialTags: (
      state,
      action: PayloadAction<FinancialTagsExpanded<TemplateNodeMapping<RowType.FINANCIALS>>>) => {
      state.financialTags = action.payload;
    },
    setSystemTemplateList: (state, action: PayloadAction<UserTemplateShort[]>) => {
      state.systemTemplateList = action.payload;
    },
    setTemplateList: (state, action: PayloadAction<UserTemplateShort[]>) => {
      state.templateList = action.payload;
    },
    setPresetList: (state, action: PayloadAction<UserTemplateShort[]>) => {
      state.presets = action.payload;
    },
    clearErrors: (state) => {
      state.errors = [];
    },
    addError: (state, action: PayloadAction<string>) => {
      state.errors = [ ...state.errors, action.payload ];
    },
    removeError: (state, action: PayloadAction<string>) => {
      state.errors = state.errors.filter(error => error !== action.payload);
    },
    setDimensions: (
      state,
      action: PayloadAction<FinancialTagsExpanded<TemplateNodeMapping<RowType.BREAKDOWN>>>
    ) => {
      state.dimensions = action.payload;
    },
    setFavoriteTemplate: (state, action: PayloadAction<FavoriteTemplate>) => {
      const { template, favorite } = action.payload;
      let found = false;
      const templateList = state.templateList.map(item => {
        if (item.id === template) {
          found = true;
          return {
            ...item,
            favorite
          };
        }
        return item;
      });
      if (found) {
        state.templateList = templateList;
        return;
      }
      state.systemTemplateList = state.systemTemplateList.map(item => {
        if (item.id === template) {
          found = true;
          return {
            ...item,
            favorite
          };
        }
        return item;
      });
    }
  }
});

export const setTemplateList = (templateList: UserTemplateShort[]) => (dispatch: AppDispatch) => {
  dispatch(actions.setTemplateList(templateList));
};

export function fetchAndSetTemplateList(statutoryList: UserTemplateShort[] = []) {
  return async (dispatch: AppDispatch) => {
    const { data: { results } } = await reportsService.getUserTemplates({ limit: 9999 });
    const list =
      statutoryList ? [ ...statutoryList, ...results ] : results;
    dispatch(actions.setTemplateList(list));
  };
}

export function fetchAndSetSystemTemplate() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { templates: { systemTemplateList } } = getState();
    if (systemTemplateList.length > 0) return;

    const { data: { results } } = await reportsService.getSystemTemplates();
    dispatch(actions.setSystemTemplateList(results));
  };
}

export const addError = (error) => (dispatch: AppDispatch) => {
  dispatch(actions.addError(error));
};

export const removeError = (error) => (dispatch: AppDispatch) => {
  dispatch(actions.removeError(error));
};

export const setHoverDimension = (
  data: Array<number | string> | null) =>
  (dispatch: AppDispatch) => dispatch(actions.setHoverDimension(data));

export const setFinancialTags = (templates: StatutoryTemplate[]) =>
  (dispatch: AppDispatch) => {
    const mapping = {};
    let nodes: TemplateNode[] = [];
    let roots: number[] = [];
    templates.forEach(t => {
      nodes = [ ...nodes, ...t.nodes ];
      roots = [ ...roots, ...t.roots ];
    });
    for (const node of nodes) {
      mapping[ node.id ] = node;
    }

    const handleNode = (id: number | string): TemplateNodeType<RowType.FINANCIALS> => {
      const index = nodes.findIndex(n => n.id === id);
      const originalNode: TemplateNode = nodes[ index ];

      if (isFinancialsNode(originalNode)) {
        const node = {
          rowData: originalNode.rowData,
          id: originalNode.id,
          uuid: originalNode.uuid,
          type: RowType.FINANCIALS as const,
          children: originalNode.children,

        };
        mapping[ node.id ] = node;
        if (node?.children?.length !== 0) {
          node.children.forEach(childId => {
            handleNode(childId);
          });
        }
        return node;
      }

    };

    roots.forEach(rootId => {
      handleNode(rootId);
    });

    dispatch(actions.setFinancialTags({
      roots,
      nodes: mapping,
      expandedNodeId: 0,
    }));
  };

export const setDimensions = (dimensions: TemplateNodeType<RowType.BREAKDOWN>[]) =>
  (dispatch: AppDispatch) => {
    const mapping: TemplateNodeMapping<RowType.BREAKDOWN> = arrayToMap(dimensions, 'id');

    dispatch(actions.setDimensions({
      roots: dimensions.map(dimension => dimension.id),
      nodes: mapping,
      expandedNodeId: 0,
    }));
  };

export const setExpandedNode = (expandedNodeId: number | string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const financialTags = getState().templates.financialTags;

    dispatch(actions.setFinancialTags({
      ...financialTags,
      expandedNodeId
    }));
  };

export const errorSelector = (error: string) => createSelector(
  [ (state: RootState) => state.templates.errors ],
  n => n.find(item => item === error)
);

export const templatesReducer = templatesSlice.reducer;
const actions = templatesSlice.actions;
export const { setFinancialTags: setRawFinancialTags } = templatesSlice.actions;

export const selectFinancialTags = (state: RootState) => state.templates.financialTags;
export const selectTemplateList = (state: RootState) => state.templates.templateList;
export const selectAllTemplates = (state: RootState) =>
  [ ...state.templates.templateList, ...state.templates.systemTemplateList ];
export const selectExpandedFinancialTag = (state: RootState) =>
  state.templates.financialTags.expandedNodeId;
export const selectHoverDimension = (state: RootState) => state.templates.hoverDimension;
export const selectSystemTemplate = (state: RootState) => state.templates.systemTemplateList;