import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';

const Tooltip = ( { children, title, ...rest }: TooltipPropsWithTitle) => (
  <AntdTooltip
    title={ title }
    arrowPointAtCenter={ true }
    mouseEnterDelay={ 1 }
    { ...rest }
  >
    { children }
  </AntdTooltip>
);

export default Tooltip;
