import React, { useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import styles from './GroupFilterRow.module.scss';
import { GroupOption } from '../../types/table.types';
import Tabs from '../../../tabs/Tabs';
import { getUppercaseAlphabet } from 'utils/common.utils';

interface Props {
  availableChoices: string[];
  gridRef: React.MutableRefObject<AgGridReact>;
  selectedGrouping: GroupOption;
  sectionKey;
}

const GROUP_ALL = 'All';

const GroupFilterRow = ({
  availableChoices,
  gridRef,
  selectedGrouping,
  sectionKey,
}: Props) => {
  const filterOptions = [ GROUP_ALL ].concat(
    availableChoices ? availableChoices : getUppercaseAlphabet()
  );
  const [ selectedFilter, setSelectedFilter ] = React.useState<string>(GROUP_ALL);

  const setFilterModel = async (item: string) => {
    const model = item === GROUP_ALL ? null : {
      [ selectedGrouping.field ]: {
        type: 'startsWith',
        filter: item,
      }
    };
    gridRef.current.api.setFilterModel(model);
    gridRef.current.api.onFilterChanged();
    setSelectedFilter(item);
  };

  const sections = filterOptions.map(item => (
    {
      key: item,
      label: item,
    }
  ));

  useEffect(() => {
    setSelectedFilter(GROUP_ALL);
  }, [ sectionKey, selectedGrouping ]);
  
  return (
    <div className={ styles.rowContainer }>
      <Tabs
        className={ styles.tabs }
        centered={ true }
        defaultActiveKey={ selectedFilter }
        activeKey={ selectedFilter }
        items={ sections }
        onChange={ setFilterModel }
      />
    </div>

  );
};

export default GroupFilterRow;
