import React from 'react';
import { Transaction } from 'types/statutory.types';
import TransactionRow from './TransactionRow';
import { separateThousands, sumTransactions } from 'utils/financials.utils';

import styles from './LabelPage.module.scss';
import DimensionCapsule from 'components/elements/dimensionCapsule/DimensionCapsule';
import CounterpartyLogo from 'components/elements/counterpartyLogo/CounterpartyLogo';
import { ReactComponent as UnassignedIcon } from 'assets/icons/unassigned.svg';
import { useTranslation } from 'react-i18next';
import {
  UNASSIGNED_ROW_NAME
} from '../../../../singleRevenueRecognition/invoicesTable/invoicesTable.utils';

type Props = {
  nodes: Transaction[];
  counterpartyName: string;
  onRemove: (id: number) => void;
};

const CounterpartyGroup = ({ nodes, counterpartyName, onRemove }: Props) => {
  const [ t ] = useTranslation('labeling');
  const counterparty = nodes[ 0 ].counterparty;
  const amountOfTransactions = nodes.length;

  const isUnassigned = !counterparty || counterpartyName === UNASSIGNED_ROW_NAME;
  return (
    <>
      <div className={ styles.counterparty }>
        <DimensionCapsule icon={
          !isUnassigned ? <CounterpartyLogo
            size={ 24 }
            name={ counterparty.name }
            type={ counterparty.type }
            className={ styles.counterpartyLogo }
            url={ counterparty.pictureUrl }
          /> : <UnassignedIcon className={ styles.icon } />
        }
        dimension={ counterparty?.type?.toLocaleLowerCase() ||
          t('labeling.unassigned-counterparty') }
        dimensionItem={ counterpartyName }
        />

        <span className={ styles.counterpartySeparator }>&bull;</span>
        <span className={ styles.counterpartyTransactionsAmount }>
          {
            amountOfTransactions <= 1 ?
              t('label.transaction-rows-single', { amount: amountOfTransactions }) :
              t('label.transaction-rows-multi', { amount: amountOfTransactions })
          }
        </span>
      </div>
      <div className={ styles.transactionsHeaderRow }>
        <div>{ t('label.header.date') }</div>
        <div>{ t('label.header.description') }</div>
        <div>{ t('label.header.amount') }</div>
      </div>
      <div className={ styles.transactions }>
        {
          nodes.map(node => <TransactionRow
            onRemove={ () => onRemove(node.id) }
            key={ node.id }
            node={ node }/>)
        }
      </div>
      <div className={ styles.transactionsFooterRow }>
        <div>
          <span>{ t('label.total') }</span>
          <span className={ styles.totalAmount }>
            { `${ separateThousands(sumTransactions(nodes)) } ` }
            &euro;
          </span>
        </div>
      </div>
    </>
  );
};

export default CounterpartyGroup;
