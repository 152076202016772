import { useEffect, useState } from 'react';
import { IRowNode } from 'ag-grid-community';
import { useAppSelector } from 'store/hooks/hooks';
import { CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { isTransactionRow } from 'utils/financials.utils';

interface Props {
  templateId: number;
  setInvoiceTransaction: (node: IRowNode) => void;
  node: IRowNode;
}

const pdfButton = defaults.find((button) => button.key === 'pdf');

const useNodeCommandBar = ({ templateId, setInvoiceTransaction, node: selectedNode }: Props) => {
  const isTableActive = useAppSelector(state => {
    const active = state.financials.active;
    return active?.templateId === templateId && active?.type === 'table';
  });
  const [ utilsState, setUtilsState ] = useState<CommandBarUtil[]>([]);

  const hydrateButtons = (node: IRowNode) => {
    const hasInvoice = isTransactionRow(node) && node.data.rowData.hasInvoice;
    setUtilsState([
      {
        key: 'pdf',
        type: 'button',
        disabled: !hasInvoice,
        onClick: () => setInvoiceTransaction(node),
        hidden: false,
        icon: pdfButton.icon,
        tooltip: pdfButton.tooltip,
        active: false,
        placement: pdfButton.placement
      }
    ]);
  };

  useEffect(() => {
    if (!isTableActive || !selectedNode) {
      return setUtilsState([]);
    }
    hydrateButtons(selectedNode);
  }, [ selectedNode, isTableActive ]);

  return useCommandBarEffect({ utils: utilsState });
};

export default useNodeCommandBar;