import React, {
  forwardRef, useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { InputNumber, Tooltip } from 'antd';
import { ICellEditorParams } from 'ag-grid-community';
import styles from './Editors.module.scss';
import { inRange } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFormulaRow } from 'components/formulaRow/FormulaRowContext';

interface ValidationProps {
  min: number;
  max: number;
  disallowZero?: boolean;
  disableDecimals?: boolean;
  optional?: boolean;
}

type ValidationState =
  | 'valid'
  | 'range-error'
  | 'zero-error'
  | 'required-error';

const NumericCellEditor = forwardRef((props: ICellEditorParams & ValidationProps, ref) => {
  const [ t ] = useTranslation('common');
  const [ value, setValue ] = useState(+props.value);
  const refInput = useRef<HTMLInputElement>(null);
  const field = props.column.getColDef().headerName;

  const { dispatch, state: { open } } = useFormulaRow();

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  const getValueOrLimit = useCallback((val: number) => {
    if (val > props.max) {
      return props.max;
    }
    if (val < props.min) {
      return props.min;
    }
    return val;
  }, [ props ]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (value == null && props.optional) {
          return undefined;
        }
        return isNaN(+value) ? undefined : getValueOrLimit(+value);
      },
    };
  });

  const valid: ValidationState = useMemo(() => {
    if (value == null && !props.optional) {
      return 'required-error';
    }
    if (props.optional && +value === 0) {
      return 'valid';
    }
    if (props.disallowZero && +value === 0) {
      return 'zero-error';
    }
    if (props.min && props.max && !inRange(+value, props.min, props.max + 1)) {
      return 'range-error';
    }
    return 'valid';
  }, [ value, props ]);

  const validationTitle = useMemo(() => {
    if (valid === 'required-error') {
      return t('validation.requiredField', { field });
    }
    if (valid === 'zero-error') {
      return t('validation.zeroField', { field });
    }
    if (valid === 'range-error') {
      return t('validation.outOfRangeField', { field, min: props.min, max: props.max });
    }
    return undefined;
  }, [ valid ]);

  const onChange = (val: number) => {
    setValue(val);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isInteger = /^[0-9]+$/;
    const isNegativeIdentifier = e.key === '-';
    const isStartOfFormula = props.column.getColId() === 'amount' && e.key === '=';

    if (isStartOfFormula && !open) {
      dispatch({ type: 'OPEN' });
    }
    if (props.disableDecimals &&
        !(isInteger.test(e.key.toString()) ||
        isNegativeIdentifier || isStartOfFormula)) {
      e.preventDefault();
    }
  };

  return (
    <Tooltip
      open={ validationTitle != null }
      title={ validationTitle }
    >
      <InputNumber
        ref={ refInput }
        value={ value }
        className={ styles.numericCell }
        status={ valid === 'valid' ? null : 'error' }
        onChange={ onChange }
        decimalSeparator=','
        onKeyPress={ onKeyPress }
      />
    </Tooltip>
  );
});

NumericCellEditor.displayName = 'NumericCellEditor';
export default NumericCellEditor;
