import clsx from 'clsx';
import Card from 'components/elements/card/Card';
import React, { PropsWithChildren } from 'react';
import styles from '../../Panels.module.scss';

interface Props {
  className?: string;
}

const PanelCard = ({ children, className = '' }: PropsWithChildren<Props>) => {
  return <Card className={ clsx(styles.card, className) }>
    { children }
  </Card>;
};

export default PanelCard;