import React, { useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import { ChartSettings, ChartStyle } from 'types/chart.types';
import styles from './DatasetDropdown.module.scss';
import Card from 'components/elements/card/Card';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import GhostBreakdown from './ghostBreakdown/GhostBreakdown';
import { getDisplayName } from '../../../../utils/common.utils';
import useTemplateNode from 'hooks/useTemplateNode';
import { capitalize } from 'lodash';

interface Props {
  index: number;
  templateId: number;
}

const DatasetDropdown = ({ index, templateId }: Props) => {
  const { control, reset } = useFormContext<ChartSettings>();
  const settings = useWatch({ control }) as ChartSettings;
  const [ open, setOpen ] = useState(false);
  const { getNodeOptions, findOption } = useTemplateNode({ templateId, type: 'chart' });
  const datasetOptions = useMemo(() => getNodeOptions(), [ getNodeOptions, ]);

  const dataset = useMemo(() => {
    if (!settings.datasets[ index ].templateNode) return null;
    return findOption(datasetOptions, settings.datasets[ index ].templateNode)?.label || '';
  }, [ datasetOptions, settings ]);

  return (
    <div className={ styles.container }>
      <Dropdown
        open={ open }
        onOpenChange={ setOpen }
        trigger={ [ 'click' ] }
        overlayClassName={ styles.dropdownWrapper }
        overlayStyle={ {
          minWidth: 0
        } }
        dropdownRender={
          () => <Card className={ styles.dropdown }>
            <Controller
              control={ control }
              name={ `datasets.${ index }.templateNode` }
              render={ ({ field }) => (
                <>
                  {
                    datasetOptions.map(option => (
                      <button
                        key={ option.value }
                        onClick={ () => {
                          field.onChange(option.value);
                          setOpen(false);
                          const newSettings = settings;
                          newSettings.datasets[ index ].style = ChartStyle.BAR;
                          newSettings.datasets[ index ].templateNode = option.value;
                          reset(newSettings);
                        } }
                        className={ field.value === option.value ? styles.active : '' }
                      >
                        { getDisplayName(option.label) }
                      </button>
                    ))
                  }
                </>
              ) }
            />
          </Card>
        }
      >
        <button className={ `
      ${ styles.dataset }
      ${ index === 0 ? styles.primary : styles.secondary }
      ${ open ? styles.active : '' }
      ` }>
          { capitalize(getDisplayName(dataset)) }
        </button>
      </Dropdown>
      <GhostBreakdown index={ index } />
    </div>
  );
};

export default DatasetDropdown;
