import React from 'react';
import { NumberSign } from 'types/financials.types';
import styles from '../styles/Badge.module.scss';

interface Props {
  value: string;
  sign: NumberSign;
  size?: 'extra-small' | 'small' | 'medium';
  className?: string;
}

const ValueBadge = ({ value, sign, size = 'medium', className='' }: Props) => {
  return <span className={ `
    ${ styles.badge }
    ${ styles[ size ] }
    ${ styles[ sign ] }
    ${ className }` }
  >
    <div className={ styles.value }>
      { value }
    </div>
  </span>;
};

export default ValueBadge;
