import React, { useCallback } from 'react';
import { useChartContext } from 'components/charts/context/ChartContext';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styles from './TopBar.module.scss';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

const TopBar = () => {
  const [ t ] = useTranslation('financials');
  const { dispatch } = useChartContext();

  const closeSettings = useCallback(() => {
    dispatch({ type: 'CLOSE_SETTINGS_PANEL' });
  }, []);

  return (
    <div className={ styles.topBar }>
      <h3>
        { t('charts.settings.title') }
      </h3>
      <GhostIconButton onClick={ closeSettings }>
        <CloseIcon />
      </GhostIconButton>
    </div>
  );
};

export default TopBar;