import { IRowNode } from 'ag-grid-community';
import dayjs from 'dayjs';
import { FilterTypeProduct, Product, RowType } from 'types/revenueRecognition.types';
import { Transaction } from 'types/statutory.types';

export const UNASSIGNED_ROW_NAME = 'Unassigned';

export const UNASSIGNED_ROW_ID = 0;

export const isUnassignedNode = (node: IRowNode) => {
  return node.data?.memo === UNASSIGNED_ROW_NAME;
};

export const getProductsToUpdate =
  (nodes: IRowNode[], selectedProducts: Product[], selectedProductsIds: number[]) => {
    const nodesToRemove: IRowNode[] = [];
    const productNodes = nodes.filter((node) => {
      return node.data?.type === RowType.PRODUCT && !isUnassignedNode(node);
    });
    productNodes.forEach(node => {
      const isNodeInTable = selectedProductsIds.includes(node?.data?.id);
      if (!isNodeInTable || !selectedProducts.length) {
        nodesToRemove.push(node);
      } else if (isNodeInTable) {
        const product = selectedProducts.find(p => p.id === node?.data?.id);
        if (product?.name !== node?.data?.memo) {
          nodesToRemove.push(node);
        }
      }
    });
    const productIds = productNodes.map(node => node.data?.id);
    const productNames = productNodes.map(node => node.data?.memo);
    const nodesToAdd: Product[] = selectedProducts.filter(product => {
      return !productIds.includes(product.id) || !productNames.includes(product.name);
    });
    return { nodesToRemove, nodesToAdd };
  };

export const createProductRows = (products: Product[]) => {
  return products.filter(el => el.id !== -1).map(p => ({
    id: p.id,
    filePath: [ p.id.toString() ],
    memo: p.name,
    counterparty: null,
    amount: 0,
    type: RowType.PRODUCT,
  }));
};

export const getTransactionFilePath = (
  transaction: Transaction, defaultPath: string[], groupByProxy = false) => {
  const unassignedPath = [ ...defaultPath ];
  if (groupByProxy) {
    unassignedPath.push(transaction.productProxy);
  }
  unassignedPath.push(transaction.id.toString());
  return transaction.product ?
    [ transaction.product.id, transaction.id ] : unassignedPath;

};

export const transactionColumnField = (transaction: Transaction): string => {
  if (!transaction) return '';
  
  const transactionDate = transaction.transactionDate;
  const dateKeys = Object.keys(transaction)
    .filter(key => dayjs(key).isValid() &&
    !dayjs(key).isSame(dayjs(transactionDate), 'month'));
  if (!dateKeys.length) {
    return dayjs(dayjs(transactionDate).startOf('month')).format('YYYY-MM-DD') + 'T00:00:00';
  }
  dateKeys.sort((a, b) => dayjs(a).diff(b));
  return dateKeys[ 0 ];
};

export const mapTransactions = (
  transactions: Transaction[], defaultFilePath: string[]) =>{

  const counts = {};
  transactions.filter(t => t.productProxy && !t.product).forEach(t => {
    counts[ t.productProxy ] = counts[ t.productProxy ] ? counts[ t.productProxy ] + 1 : 1;
  });

  return [ ...transactions.map(transaction => {
    const groupByProxy = counts[ transaction.productProxy ] > 1;
    const data = {};
    if (transaction?.recognizedLines?.length) {
      for (const r of transaction.recognizedLines) {
        data[ dayjs(r.transactionDate).format('YYYY-MM-DD') + 'T00:00:00' ] = r.amount;
      }
    }
    const originalTransactionColumn = transactionColumnField(transaction);
    const isOriginalTransactionOverlapping = Object.keys(data).includes(originalTransactionColumn);
    const isRecognized = !!transaction?.recognizedLines?.length;
    data[ 'isOriginalTransactionOverlapping' ] = isOriginalTransactionOverlapping;
    if (!isOriginalTransactionOverlapping) {
      data[ transactionColumnField(transaction) ] = transaction.amount;
    }
    return { ...transaction,
      isRecognized,
      filePath: getTransactionFilePath(transaction, defaultFilePath, groupByProxy),
      type: RowType.INVOICE,
      ...data };
  }) ];
};

export const isProductRow = (node: IRowNode) =>
  node?.data?.type === RowType.PRODUCT;

export const filterInvoiceTable = (node: IRowNode, sort: FilterTypeProduct) => {

  if (isProductRow(node)) return true;

  if ( sort === FilterTypeProduct.UNRECOGNIZED ) {
    return node?.data?.recognitionType === 'UNRECOGNIZED';
  } else if ( sort === FilterTypeProduct.RECOGNIZED_USER ) {
    return node?.data?.recognitionType === 'USER';
  } else if ( sort === FilterTypeProduct.RECOGNIZED_SYSTEM ) {
    return node?.data?.recognitionType === 'SYSTEM';
  }
  return true;

};
