import React from 'react';
import { useRouteError } from 'react-router-dom';

import styles from '../ErrorBoundary.module.scss';

const RouterErrorBoundary = () => {
  useRouteError();

  const resetErrorBoundary = () => {
    window.location.reload();
  };

  return <div className={ styles.container }>
    <div className={ styles.section }>
      <h1>Something went wrong</h1>
      <button onClick={ resetErrorBoundary }>Try again</button>
    </div>
  </div>;
};

export default RouterErrorBoundary;