import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: React.ReactNode;
  portalId: string;
}

const Portal = ({ children, portalId }: Props) => {
  const element = useMemo(() => {
    return document.getElementById(portalId);
  }, [ portalId ]);

  if (!element) {
    return <>{ children }</>;
  }

  return <>{ createPortal(children, element) }</>;
};

export default Portal;
