import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { integrateProcountor } from 'services/integration.service';
import { Integration, IntegrationEnum } from 'types/settings.types';
import { notifyError, notifySuccess, notifyUnexpectedError } from 'utils/notifications.utils';
import { getProcountorIntegrationUrl } from 'utils/settings.utils';
import ProcountorLogo from 'assets/logos/procountor.png';
import Button from 'components/elements/button/Button';
import styles from '../Integrations.module.scss';
import IntegrationItem from 'components/settings/integrations/item/IntegrationItem';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { appActions, fetchAndSetIntegrations } from 'store/app.slice';

interface Props {
  integration: Integration;
  disabled: boolean;
}

const ProcountorIntegration = ({ integration, disabled }: Props) => {
  const [ t ] = useTranslation('settings');
  const [ params ] = useSearchParams();
  const canIntegrate = !integration && !disabled;
  const dispatch = useAppDispatch();
  const organization = useAppSelector(state => state.auth.organization);

  useEffect(() => {
    integrate();
  }, []);

  const integrate = async () => {
    if (params.get('state') === IntegrationEnum.procountor) {
      const code = params.get('code');
      if (code) {
        dispatch(appActions.setProcountorCode(params.get('code')));
        try {
          await integrateProcountor(code);
          await dispatch(fetchAndSetIntegrations(organization.id));
          notifySuccess(
            t('notifications.message'),
            t('notifications.description', { system: 'Procountor' })
          );
        } catch (e) {
          if (e.response.data.detail) {
            notifyError(e.response.data.detail);
          } else {
            notifyUnexpectedError(e);
          }
        }
      }
    }
  };

  const onClick = () => {
    const url = getProcountorIntegrationUrl(
      window?.location?.href, IntegrationEnum.procountor);
    window.open(url, '_blank');
  };

  return <IntegrationItem logo={ ProcountorLogo } integration={ integration } disabled={ disabled }>
    {
      canIntegrate ?
        <Button
          name={ t('integrate') }
          className={ styles.button }
          target='_blank'
          onClick={ onClick }
        /> : null
    }
  </IntegrationItem>;
};

export default ProcountorIntegration;
