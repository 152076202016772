import { Period } from '../../../../types/financials.types';
import { useGetReportQuery, useStreamReportQuery } from 'store/api/reports.api';
import { UUID } from 'types/templates.types';

interface Props {
  templateId: number;
  period: Period;
  shouldFetch?: boolean;
  templateNodeUuids?: UUID[];
}

const useReportQuery = (
  { templateId, period, shouldFetch = true, templateNodeUuids }: Props
) => {
  let query = useGetReportQuery;
  // If templateNodeUuids is set, we shouldn't use report streaming
  if (!templateNodeUuids) {
    query = useStreamReportQuery;
  }
  return query({
    id: templateId,
    period,
    templateNodeUuids,
  }, {
    skip: !templateId || !period || !shouldFetch
  });
};

export default useReportQuery;
