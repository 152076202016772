import React from 'react';
import { selectMode } from 'store/dashboard.slice';
import { useAppSelector } from 'store/hooks/hooks';
import { DashboardModeType } from 'types/dashboard.types';
import { COLS_WIDTH } from 'utils/dashboard.utils';
import styles from './DashboardGridPlaceholder.module.scss';

const DashboardGridPlaceholder = () => {
  const mode = useAppSelector(selectMode);

  if (mode === DashboardModeType.VIEW) {
    return null;
  }

  return (
    <div className={ styles.gridPlaceholder }>
      {
        Array.from({ length: COLS_WIDTH }).map((_, index) => (
          <div key={ index } />
        ))
      }
    </div>
  );
};

export default DashboardGridPlaceholder;