import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import styles from './IncrementalNumber.module.scss';
import Button from 'components/elements/button/Button';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';

const STEP = 1;

interface ButtonProps {
  onChange: (val: number) => void;
  type: 'increment' | 'decrement';
}

const IncrementalButton = ({ onChange, type }: ButtonProps) => {
  const onClick = () => {
    onChange((type === 'increment' ? STEP : -STEP));
  };

  return <Button
    type='primary'
    onClick={ onClick }
    className={ styles.button }
  >
    { type === 'increment' ? <PlusIcon /> : <MinusIcon /> }
  </Button>;
};

const IncrementalNumber = ({ ...props }: InputNumberProps<number>) => {
  if (!props.onChange) throw new Error('onChange is required for IncrementalNumber');

  const onIncrementChange = (step: number) => {
    props.onChange((props.value || 0) + step);
  };

  return <div className={ styles.input }>
    <IncrementalButton
      onChange={ onIncrementChange }
      type='decrement'
    />
    <InputNumber
      { ...props }
      className={ styles.number }
    />
    <IncrementalButton
      onChange={ onIncrementChange }
      type='increment'
    />
  </div>;
};

export default IncrementalNumber;
