import React, { createContext, useContext, useReducer } from 'react';
import { GroupByStatusList } from 'types/contracts.types';

type ContractsContextType = {
  groupById: number;
  sortById: number;
  parameterGroupBy: string;
  counter: null | number;
};

const defaultState: ContractsContextType = {
  groupById: 0,
  sortById: 0,
  parameterGroupBy: GroupByStatusList.ALL,
  counter: null
};

const ContractsContext = createContext(undefined);

const contractsReducer = (state, action) => {
  switch (action.type) {
    case 'setGroupBy': {
      return { ...state , groupById: action.payload };
    }
    case 'setCounter': {
      return { ...state , counter: action.payload };
    }
    case 'setSortBy': {
      return { ...state , sortById: action.payload };
    }
    case 'selectParameterGroupBy': {
      return { ...state , parameterGroupBy: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${ action.type }`);
    }
  }
};

const ContractsContextProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(contractsReducer, defaultState);
  const value = { state, dispatch };

  return <ContractsContext.Provider value={ value }>
    { children }
  </ContractsContext.Provider>;
};

const useContractsContext = () => {
  const context = useContext(ContractsContext);
  if (context === undefined) {
    throw new Error('useContractsContext must be used within Provider');
  }
  return context;
};

export { ContractsContextProvider, useContractsContext };
