import { cloneDeep } from 'lodash';
import { Template, TemplateNodeMapping, TemplateNode } from 'types/templates.types';
import { isDimensionItem, isDimensionType, isGroup } from 'utils/template.utils';
import { TemplateRulesService } from './TemplateRulesService';
import { RowType } from '../../types/financials.types';

export const TemplateRemoveService = (
  template: Template,
  financialNodes: TemplateNodeMapping<RowType.FINANCIALS>
) => {

  const removeRaw = (
    newTemplate: Template,
    nodeId: number | string,
    parentId: number | string): Template => {
    let updatedTemplate = cloneDeep(newTemplate);

    if (!parentId) {
      updatedTemplate.roots = updatedTemplate.roots.filter((id) => id !== nodeId);
    } else {
      updatedTemplate.nodes[ parentId ].children =
        updatedTemplate.nodes[ parentId ].children.filter((id) => id !== nodeId);
    }
    const nodeChildren = updatedTemplate.nodes[ nodeId ]?.children;
    nodeChildren?.forEach((childId) => {
      updatedTemplate = removeRaw(updatedTemplate, childId, nodeId);
    });

    delete updatedTemplate.nodes[ nodeId ];
    return updatedTemplate;
  };

  const removeParent = (nodeId: number | string): Template => {
    const updatedTemplate = cloneDeep(template);
    const node = updatedTemplate.nodes[ nodeId ];
    const children = node?.children;
    const parent = node?.parent;
    if (parent) {
      const indexOfParent = updatedTemplate.nodes[ parent ].children.indexOf(nodeId);
      updatedTemplate.nodes[ parent ].children =
        updatedTemplate.nodes[ parent ].children.filter((id) => id !== nodeId);
      if (children) {
        updatedTemplate.nodes[ parent ].children.splice(indexOfParent, 0, ...children);
      }
    } else {
      const indexOfParent = updatedTemplate.roots.indexOf(nodeId);
      updatedTemplate.roots = updatedTemplate.roots.filter((id) => id !== nodeId);
      if (children) {
        updatedTemplate.roots.splice(indexOfParent, 0, ...children);
      }
    }
    children?.forEach((child) => {
      updatedTemplate.nodes[ child ].parent = parent;
    });
    delete updatedTemplate.nodes[ nodeId ];
    return updatedTemplate;
  };

  const canDeleteOnlyParent = (node: TemplateNode) => {
    const parentId = node?.parent;
    if (isGroup(node)) {
      return false;
    }
    if (isDimensionItem(node) || isDimensionType(node) || !parentId) {
      return true;
    }
    const parentChildren = template.nodes[ parentId ]?.children;
    if (parentChildren?.length === 1) {
      return true;
    }
    return false;
  };

  const removeParentWithChildrenValidation = (nodeId: number | string): Template => {
    const node = template.nodes[ nodeId ];
    const children = node?.children;
    let updatedTemplate = cloneDeep(removeParent(nodeId));
    children.forEach((child) => {
      const { validate } =
        TemplateRulesService(child, updatedTemplate, financialNodes);
      updatedTemplate = validate();
    });

    return updatedTemplate;
  };

  const remove = (nodeId: number | string): Template => {
    const node = template.nodes[ nodeId ];
    const parentId = node?.parent;
    if (isGroup(node) || isDimensionType(node)) {
      return removeParentWithChildrenValidation(nodeId);
    }
    if (canDeleteOnlyParent(node)) {
      return removeParent(nodeId);
    }
    return removeRaw(template, nodeId, parentId);
  };

  return {
    remove,
    removeRaw
  };
};
