import React, { useMemo, useState } from 'react';
import styles from './GridItem.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/thick-close.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/chartIcon.svg';
import { ReactComponent as TableIcon } from 'assets/icons/table.svg';

import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  dashboardSlice,
  removeElement, selectElement,
  selectMode,
} from 'store/dashboard.slice';
import { DashboardElement, DashboardModeType } from 'types/dashboard.types';
import { useTranslation } from 'react-i18next';
import {
  isBigNumberElement,
  isChartElement,
  mapChartElementToSettings,
} from 'utils/dashboard.utils';
import FinancialTableModal from '../../../modals/financialTable/FinancialTableModal';
import ChartModal from '../../../modals/charts/ChartModal';
import {
  defaultCustomSettings,
  FinancialTableContextProvider,
} from 'context/FinancialTableContext';
import DynamicTableWrapper from '../../../wrappers/dynamicTableWrapper/DynamicTableWrapper';
import { Tooltip } from 'antd';
import useFinancialReport from 'components/dashboard/hooks/useFinancialReport';

interface Props {
  item: DashboardElement;
}

const GridItemHeader = ({ item }: Props) => {
  const [ t ] = useTranslation('dashboard');
  const mode = useAppSelector(selectMode);
  const dispatch = useAppDispatch();
  const [ showChart, setShowChart ] = useState(false);
  const [ showTable, setShowTable ] = useState(false);

  const dynamicItemsButtons = useMemo(() => {
    if (!isChartElement(item) && !isBigNumberElement(item)) return [];

    return [
      {
        icon: <Tooltip title={ t('card-chart') }>
          <ChartIcon className={ styles.icon } />
        </Tooltip>,
        onClick: () => setShowChart(prev => !prev),
        hide: !item.element.template || mode === DashboardModeType.EDIT,
      },
      {
        icon: <Tooltip title={ t('card-table') }>
          <TableIcon className={ styles.icon } />
        </Tooltip>,
        onClick: () => setShowTable(prev => !prev),
        hide: !item.element.template || mode === DashboardModeType.EDIT,
      }
    ];
  }, [ item ]);

  const buttons = useMemo(() => {
    return [
      {
        icon: <Tooltip title={ t('card-edit') }>
          <EditIcon className={ styles.icon } />
        </Tooltip>,
        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          dispatch(dashboardSlice.actions.setMode(DashboardModeType.EDIT));
          dispatch(selectElement(item.id, false));
        },
        hide: mode === DashboardModeType.EDIT,
      },
      ...dynamicItemsButtons,
      {
        icon: <CloseIcon className={ styles.icon } />,
        onClick: () => dispatch(removeElement(item.id)),
        hide: mode === DashboardModeType.VIEW,
      }
    ];
  }, [ item, mode ]);

  const settings = useMemo(() => {
    if (!isChartElement(item)) return null;

    return mapChartElementToSettings(item.element);
  }, [ item ]);

  return (
    <div className={ styles.header }>
      {
        isBigNumberElement(item) ?
          <div className={ styles.badge }>
            { t(`panel.card-settings.value-type-values.${ item.element?.valueType }`) }
          </div> : null
      }
      <div className={ `${ styles.section } ${ styles[ mode ] }` }>
        {
          buttons.map((button, index) => (
            <button
              className={ button.hide ? styles.hidden : '' }
              key={ index }
              onClick={ (e) => {
                e.stopPropagation();
                button.onClick(e);
              } }
            >
              { button.icon }
            </button>
          ))
        }
      </div>
      {
        (isChartElement(item) || isBigNumberElement(item)) ? (
          <FinancialTableContextProvider
            templateId={ item.element?.template }
            customSettings={ {
              ...defaultCustomSettings,
              adjustHeight: false,
              disableStickyHeader: true,
              panelSettings: {
                ...defaultCustomSettings.panelSettings,
                chart: {
                  ...defaultCustomSettings.panelSettings.chart,
                  disabled: true,
                },
                filterLabel: {
                  ...defaultCustomSettings.panelSettings.filterLabel,
                  className: styles.panelInDashboard
                }
              },
              detailedViewSettings: {
                disabled: true,
                floatingPanelDisabled: true,
              }
            } }
            onClose={ () => {
              setShowTable(false);
              setShowChart(false);
            } }
          >
            {
              (showTable || showChart) ? (
                <LoadBigNumberTable templateId={ item.element.template } />
              ) : null
            }
            
            <DynamicTableWrapper
              templateId={ item.element?.template }
            >
              <FinancialTableModal
                isVisible={ showTable }
              />
              <ChartModal
                onClose={ () => setShowChart(false) }
                isVisible={ showChart }
                defaultSettings={ isChartElement(item) ? settings : null }
              />
            </DynamicTableWrapper>
          </FinancialTableContextProvider>
        ) : null
      }
    </div>
  );
};

export default GridItemHeader;

// ? Load big number table only when needed
const LoadBigNumberTable = ({ templateId }) => {
  useFinancialReport({ templateId, isDashboard: true });
  return null;
};
