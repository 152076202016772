import { useCallback } from 'react';
import { clearTemplate, duplicateTemplate, setMappedTemplate } from '../../../store/template.slice';
import { ActionCreators } from 'redux-undo';
import {
  getLastTemplateId,
  removeLastTemplateId,
  setLastTemplateId,
} from '../../../utils/template.utils';
import { routes } from '../../../utils/config.utils';
import { notifyError } from '../../../utils/notifications.utils';
import { reportsService } from '../../../services/reports.services';
import {
  selectSystemTemplate,
  selectTemplateList,
  setTemplateList,
} from '../../../store/templates.slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useTemplateService = () => {
  const [ t ] = useTranslation('financials');
  const systemTemplates = useAppSelector(selectSystemTemplate);
  const templateList = useAppSelector(selectTemplateList);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isSystemTemplate = useCallback(
    (templateId: number) => {
      return systemTemplates.some((el) => el.id === templateId);
    },
    [ systemTemplates ],
  );

  const handleTemplateChange = useCallback(
    async (templateId: number, isDefault?: boolean) => {
      const getTemplate = async (getter) => {
        getter(templateId)
          .then(({ data }) => {
            dispatch(setMappedTemplate(data));
            dispatch(ActionCreators.clearHistory());
          })
          .catch((error) => {
            if (isDefault) {
              removeLastTemplateId();
              navigate(routes.report.templateBuilder);
            } else if (error.response.data.detail) {
              notifyError(error.response.data.detail);
            } else {
              notifyError(t('notifications.unexpected-error.message', { ns: 'common' }));
              throw error;
            }
          });
      };

      if (isSystemTemplate(templateId)) {
        return getTemplate(reportsService.getSystemTemplate);
      } else {
        return getTemplate(reportsService.getUserTemplate);
      }
    },
    [ isSystemTemplate ],
  );

  const handleDelete = useCallback(
    (templateId: number) => {
      return reportsService
        .deleteUserTemplate(templateId)
        .then(() => {
          dispatch(clearTemplate());
          const lastTemplateId = getLastTemplateId();
          +lastTemplateId === templateId && setLastTemplateId(-1);
          const filterList = templateList.filter((el) => el.id !== templateId);

          dispatch(setTemplateList(filterList));
        })
        .catch((error) => {
          if (error.response.data.detail) notifyError(error.response.data.detail);
          else {
            notifyError(t('notifications.unexpected-error.message', { ns: 'common' }));
            throw error;
          }
        });
    },
    [ templateList ],
  );

  const copyTemplate = useCallback(
    (templateId: number) => {
      const getTemplate = isSystemTemplate(templateId)
        ? reportsService.getSystemTemplate
        : reportsService.getUserTemplate;

      getTemplate(templateId).then(({ data }) => {
        dispatch(duplicateTemplate(data));
      });
    },
    [ systemTemplates ],
  );

  return {
    handleTemplateChange,
    handleDelete,
    copyTemplate,
    isSystemTemplate,
  };
};

export default useTemplateService;
