import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Dropdown } from 'antd';

import { SettingsNav } from 'types/navigation.types';
import { ReactComponent as Arrow } from 'assets/icons/chevron-down.svg';

import styles from './MobileSettingsNav.module.scss';

type Props = {
  navItems: SettingsNav[];
};

const MobileSettingsNav = ({ navItems }: Props) => {
  const [ activePanel, setActivePanel ] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    const slug = location.pathname?.split('/')?.pop() || 
      navItems.find(item => item.path).name;
    slug !== activePanel && setActivePanel(slug);
  }, [ location ]);

  const getMenuItems = () => {

    return navItems.map(
      item => {
        return {
          key: item.name,
          label: <NavLink to={ item.path } >
            { item.name }
          </NavLink>
        };
      }
    );
  };

  return (
    <Dropdown 
      className={ styles.dropdownMobile }
      menu={ { items: getMenuItems() } }
    >
      <button className={ styles.dropdownButton } >
        <span>
          { activePanel ? activePanel : '' }
        </span>
        <Arrow />
      </button>
    </Dropdown> 
  );
};

export default MobileSettingsNav;
