export const AG_GRID_LOCALE = { 
  'en' : {
    copy: 'Copy',
    copyWithHeaders: 'Copy With Headers',
    copyWithGroupHeaders: 'Copy with Group Headers',
  },
  'fi' : {
    copy: 'Kopioi',
    copyWithHeaders: 'Kopioi otsikkotietojen kanssa',
    copyWithGroupHeaders: 'Kopioi ryhmän otsikkotietojen kanssa'
  }
};