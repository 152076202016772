import { mapDynamicSettings } from '../dynamicColumns.utils';
import { DynamicSettings } from '../../../../types/financials.types';
import { isEqual } from 'lodash';
import { selectPeriod } from '../../../../store/financials.slice';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { useSetColumnsSettingsMutation } from 'store/api/reports.api';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';

interface Props {
  templateId: number;
}

const useUpdateColumnsSettings = ({ templateId }: Props) => {
  const period = useAppSelector(selectPeriod(templateId));
  const { data: report } = useReportQuery({ templateId, period });
  const [ setColumns, { isLoading } ] = useSetColumnsSettingsMutation();

  const update = (settings: DynamicSettings) => {
    const data = mapDynamicSettings(settings);
    const shouldUpdate = !isEqual(data, report?.columnSettings);
    if (!shouldUpdate) {
      return Promise.resolve();
    }
    return setColumns({ id: templateId, data });
  };

  return { update, isLoading };
};

export default useUpdateColumnsSettings;