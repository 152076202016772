import { useMemo } from 'react';
import { ColumnApi } from 'ag-grid-community';
import * as yup from 'yup';

export interface ColumnSetting {
  field: string;
  visible: boolean;
  position: number;
}

interface Props {
  key: string;
}

const settingSchema = yup.object().shape({
  field: yup.string().required(),
  visible: yup.boolean().required(),
  position: yup.number().required()
});

const isSettingValid = (setting: Partial<ColumnSetting>): setting is ColumnSetting => {
  try {
    settingSchema.validateSync(setting);
    return true;
  } catch {
    return false;
  }
};

const removeBrokenSettings = (setting: Partial<ColumnSetting>[]): ColumnSetting[] => {
  return setting.filter(isSettingValid);
};

const useColumnStorage = ({ key }: Props) => {
  const storageKey = `table-column-settings-${ key }`;

  const save = (api: ColumnApi) => {
    const columns = api.getAllGridColumns();
    const columnSettings: ColumnSetting[] = [];
    columns.forEach((column, index) => {
      columnSettings.push({
        field: column.getColDef().field,
        visible: column.isVisible(),
        position: index
      });
    });
    localStorage.setItem(storageKey, JSON.stringify(columnSettings));
  };

  const deleteStored = () => {
    localStorage.removeItem(storageKey);
  };

  const get = (): ColumnSetting[] => {
    const settingsOrEmpty = localStorage.getItem(storageKey) || '[]';
    const storedSetting = JSON.parse(settingsOrEmpty);
    return removeBrokenSettings(storedSetting);
  };

  const apply = (settings: ColumnSetting[], api: ColumnApi) => {
    removeBrokenSettings(settings).forEach((column) => {
      const col = api?.getColumn(column.field);
      if (!col || col.getColDef().suppressMovable) return;
      api.moveColumn(col, column.position);
      api.setColumnVisible(column.field, column.visible);
    });
  };

  const getAndApply = (api: ColumnApi) => {
    const setting = get();
    apply(setting, api);
  };

  return useMemo(() => ({
    save,
    get,
    apply,
    getAndApply,
    deleteStored,
  }), [ key ]);
};

export default useColumnStorage;
