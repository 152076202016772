import React, { useMemo } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { clearLeftPanel } from 'store/financials.slice';
import styles from 'components/financials/panels/planDriverPanel/titleBar/TitleBar.module.scss';
import { useTranslation } from 'react-i18next';
import BurgerDropdown from 'components/elements/burgerDropdown/BurgerDropdown';
import { useFormContext } from 'react-hook-form';
import { PlanSettings } from 'types/planning.types';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import { planningService } from 'services/planning.service';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle-thick.svg';
import { notifyError, notifyUnexpectedError } from 'utils/notifications.utils';
import SpinningLoader from 'components/elements/spinning-loader/SpinningLoader';
import { useAppDispatch } from 'store/hooks/hooks';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

type Props = {
  isSubmitSuccessful: boolean;
  isSubmitting: boolean;
  onReset: () => void;
};

const TitleBar = ({ isSubmitSuccessful, isSubmitting, onReset }: Props) => {
  const dispatch = useAppDispatch();
  const {
    formState: {
      isDirty,
      isValid,
      isSubmitted
    }
  } = useFormContext<PlanSettings>();
  const [ t ] = useTranslation('planning');
  const onClose = () => {
    dispatch(clearLeftPanel());
  };

  const burgerOptions = useMemo(() => {
    return [
      {
        title: t('plan-drivers.reset'),
        onClick: async () => {
          try {
            await planningService.restoreDefaultPlanning();
            onReset();
          } catch (e) {
            if (e.response.data.detail) {
              notifyError(e.response.data.detail);
            } else {
              notifyUnexpectedError(e);
            }
          }
        }
      },
      {
        title: t('plan-drivers.recreate-payments'),
        onClick: async () => {
          try {
            await planningService.generatePayments();
          } catch (e) {
            if (e.response.data.detail) {
              notifyError(e.response.data.detail);
            } else {
              notifyUnexpectedError(e);
            }
          }
        }
      }
    ];
  }, [ onReset ]);

  return (
    <div className={ styles.title }>
      <div>
        <h2>{ t('plan-drivers.title') }</h2>
        <div className={ styles.updateSection }>
          {
            !isSubmitted && !isSubmitting ? (
              <GhostIconButton
                tooltip={ t('plan-drivers.update-plan') }
                htmlType='submit'
                disabled={ !isDirty || !isValid }
                className={ `${ styles.update }
                ${ !isDirty || !isValid ? styles.submitHidden : '' }` }
              >
                <ReloadIcon/>
              </GhostIconButton>
            ) : null
          }
          {
            isSubmitting ? <div className={ styles.submitting }>
              <SpinningLoader className={ styles.submittingLoader }/>
              <span>{ t('plan-drivers.submitting') }</span>
            </div> : null
          }
          {
            isSubmitSuccessful ? <div className={ styles.submittingSuccessful }>
              <CheckIcon/>
              <span>{ t('plan-drivers.submitting-successful') }</span>
            </div> : null
          }
        </div>
      </div>

      <div>
        <BurgerDropdown options={ burgerOptions }/>
        <GhostIconButton onClick={ onClose } className={ styles.close } withSeparator>
          <CloseIcon/>
        </GhostIconButton>
      </div>
    </div>
  );
};

export default TitleBar;
