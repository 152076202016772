import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PanelType } from 'types/app.types';
import Button from 'components/elements/button/Button';
import {
  clearLeftPanel,
  clearFilters as clearFiltersStore,
  setLeftPanel,
} from 'store/financials.slice';
import { useFinancialTable } from 'context/FinancialTableContext';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useClearFiltersMutation } from 'store/api/reports.api';
import { areFiltersActive } from 'utils/financials.utils';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

import styles from './FilterLabelButton.module.scss';

interface Props{
  section: PanelType;
  icon: React.ReactNode;
  text: string;
  activeCloseButton: boolean;
  suppressHover?: boolean;
}

const FilterLabelButton = ({ section, icon, text, activeCloseButton, suppressHover }: Props) => {
  const { state: {
    templateId,
    customSettings: { panelSettings: { filterLabel } }
  } } = useFinancialTable();
  const table = useMemo(() => templateId, [ templateId ]);
  const activeTable = useAppSelector(state => state.financials.active?.templateId);
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation();
  const filters = useAppSelector(state => state.financials.tables[ table ]?.filter);
  const panel = useAppSelector(state => state.app.leftPanel);
  const [ clearFilters ] = useClearFiltersMutation();

  useEffect(() => {
    if (filterLabel.defaultOpen) {
      dispatch(setLeftPanel({ panelType: section, templateId }));
    }
  }, [ templateId, section ]);

  if (filterLabel.disabled) {
    return null;
  }
  const isActive = useMemo(() => panel === section && table === activeTable, [ panel, table ]);

  const onClick = () => {
    if (isActive) {
      return dispatch(clearLeftPanel());
    }
    if (filterLabel.onOpen) {
      return filterLabel.onOpen(section);
    } else {
      dispatch(setLeftPanel({ panelType: section, templateId }));
    }
  };

  const onClearFilters = async () => {
    dispatch(clearFiltersStore(table));
    if (table)
      await clearFilters({ id: table });
  };
  return (
    <>
      {
        section === 'filter' && (
          <Button
            onClick={ onClearFilters }
            className={
              `${ styles.clearFilters }
          ${ !areFiltersActive(filters) ? styles.clearFiltersHidden : '' }
          ${ !text ? styles.emptyText : '' }
          ${ suppressHover ? styles.dashboardFilter : '' }`
            }
            type='text'>
            { text }
          </Button>
        )
      }
      <GhostIconButton
        tooltip={ t(`common:left-panel.navigation.${ section.toLocaleLowerCase() }`) }
        active={ isActive }
        activeType='outlined'
        onClick={ activeCloseButton ? onClick : undefined }
        className={ suppressHover ? styles.dashboardFilter : '' }
      >
        { icon }
      </GhostIconButton>
    </>
  );
};

export default FilterLabelButton;
