import { useSearchable } from 'context/SearchableContext';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RowType } from 'types/financials.types';
import {
  isBreakdownNode,
  TemplateNode,
  TemplateNodeType,
  TemplateSections,
  TreeTags,
} from 'types/templates.types';
import { getDisplayName } from 'utils/common.utils';
import { tagNamesComparator } from 'utils/templates.utils';
import DragTag from './dragTag/DragTag';
import styles from './DragTagSection.module.scss';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import DimensionItem
  // eslint-disable-next-line max-len
  from '../../../elements/dimensionLeftPanel/listView/dimensionDetails/detailsList/DimensionItem/DimensionItem';
import AddEditDimensionModal
  from '../../../elements/dimensionLeftPanel/addEditDimensionModal/AddEditDimensionModal';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { displayNameComparator } from '../../../../utils/sorting.utils';
import useDimension from '../../../elements/dimensionLeftPanel/hooks/useDimension';
import sections, { SectionInfo } from '../../../elements/dimensionLeftPanel/sections';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import clsx from 'clsx';

interface Props {
  formulaBuilderIsOpen: boolean;
  isCombinedSections: boolean;
  treeTags: TreeTags;
  section: TemplateSections;
  setDimensionDetails: React.Dispatch<React.SetStateAction<number | string>>;
}

const DragTagSection = (
  {
    formulaBuilderIsOpen,
    isCombinedSections,
    treeTags,
    section,
    setDimensionDetails
  }: Props) => {
  const { state: { search } } = useSearchable();
  const [ t ] = useTranslation('financials');

  const [ dimensionModalVisible, setDimensionModalVisible ] = useState(false);

  const isDimensionSectionSelected = useMemo(
    () => section === TemplateSections.DIMENSIONS, [ section ]
  );

  const renderDimensionItemNodes = (nodes: TemplateNode[]) => {
    if (!nodes || nodes.length === 0) {
      return null;
    }

    const filteredNodes = nodes?.filter(node => {
      const name = getDisplayName(node.rowData.name)?.toLowerCase();
      return name?.includes(search.toLowerCase());
    });

    return filteredNodes.map((node: TemplateNodeType<RowType.DIMENSION_ITEM>) => {
      if (!node.childrenNodes || node.childrenNodes.length === 0) {
        const item = node.rowData;
        return <DimensionItem
          onEdit={ () => null }
          onDelete={ () => null }
          item={ item }
          key={ `${ item.dimensionId }__${ item.id }` }
          isTemplateBuilder={ true }
        />;
      }
    });
  };

  const tagOrChildrenContainSearch = useCallback((tag: TemplateNode, searchValue: string) => {
    if (tagNamesComparator(tag, searchValue)) {
      return true;
    }
    if (!tag.childrenNodes?.length) {
      return false;
    }

    return tag.childrenNodes.find((childTag) => {
      return tagOrChildrenContainSearch(childTag, searchValue);
    });
  }, [ search ]);

  const tags = useMemo(() => {
    const sectionTags = treeTags[ section ];
    if (search) {
      if (search === '') {
        return sectionTags;
      }
      const searchValue = search.toLowerCase();
      return sectionTags.filter((tag) => {
        return tagOrChildrenContainSearch(tag, searchValue);
      });
    }
    return sectionTags;
  }, [ search, section, treeTags ]);

  const isCustomTags = useMemo(() => {
    const customSections =
      [ TemplateSections.LAYOUT, TemplateSections.FORMULAS ];

    return customSections.includes(section);
  }, [ section ]);

  const dimensions = useMemo(() => {
    return tags?.filter(isBreakdownNode).sort((a, b) =>
      displayNameComparator(a.rowData.name, b.rowData.name)
    );
  }, [ tags ]);

  const { onAddDimension } = useDimension({ dimension: null });

  const dimensionSections = useMemo(() => {
    return sections;
  }, [ dimensions ]);

  const renderSection = useCallback((item: SectionInfo) => {
    const filteredDimensions = dimensions.filter(di => di.rowData.type === item.type);
    if (!filteredDimensions.length) {
      return null;
    }
    return (
      <React.Fragment key={ item.type }>
        { item.title &&
            (<div className={ styles.dimensionCustom }>
              <span className={ styles.customTitle }>{ item.title }</span>
              {
                item.canAdd &&
                  <GhostIconButton onClick={ () => setDimensionModalVisible(true) }>
                    <Plus/>
                  </GhostIconButton>
              }
            </div>) }

        {
          filteredDimensions
            .map(di => (
              <div
                key={ `${ di.id }` }
                className={ styles.wrapper }
                onClick={ () => {
                  setDimensionDetails(di.id);
                } }
              >
                <DragTag section={ section } item={ di }/>
                {
                  search !== '' && (
                    <div className={ styles.dimensionFilterContainer }>
                      { renderDimensionItemNodes(di.childrenNodes) }
                    </div>
                  )
                }
              </div>
            ))
        }
      </React.Fragment>);
  }, [ dimensions, section, setDimensionDetails, search ]);

  return <div
    className={ clsx(
      styles.dragTagsSection,
      {
        [ styles.openBuilderFormula ]: formulaBuilderIsOpen && !isCombinedSections,
        [ styles.combinedSections ]: isCombinedSections,
        [ styles.emptySection ]: tags.length === 0
      }
    ) }
  >

    {
      isCombinedSections &&
        tags.length > 0 ? (
          <span className={ styles.title }>
            { t(`templates.sections.${ section }`) }
          </span>
        ) : null
    }
    <div className={ `${ styles.content } ${ isCustomTags ? styles.customContent : '' }` }>
      {
        !isDimensionSectionSelected && tags?.map(item => (
          <DragTag section={ section } key={ `${ item.type }__${ item.id }` } item={ item }/>
        ))
      }

      {
        isDimensionSectionSelected && dimensionSections.map(item => renderSection(item))
      }

    </div>
    <AddEditDimensionModal
      isVisible={ dimensionModalVisible }
      onClose={ () => setDimensionModalVisible(false) }
      onConfirm={ async (name) => {
        await onAddDimension(name);
        setDimensionModalVisible(false);
      } }
      title={ <div>
        <EditIcon/>
        { t('left-panel.add-dimension.create-dimension-label') }
      </div> }
      label={ t('left-panel.add-dimension.custom-dimension-name') }
      okText={ t('left-panel.add-dimension.ok-text') }
      defaultValue={ t('left-panel.add-dimension.new-label') }
    />
  </div>;
};

export default DragTagSection;
