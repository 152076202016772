import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// eslint-disable-next-line no-undef
const { REACT_APP_SENTRY_DSN, REACT_APP_ENVIRONMENT } = process.env;

export function initSentry() {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [ new Integrations.BrowserTracing() ],
    tracesSampleRate: 0.1,
    environment: REACT_APP_ENVIRONMENT
  });

}
