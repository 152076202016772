import { User } from '../types/auth.types';

interface ClarityAPI {
  (
    method: 'identify',
    customId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string
  ): Promise<{ id: string; session: string; page: string; userHint: string }>;
}

declare global {
  interface Window {
    clarity: ClarityAPI;
  }
}

export const connectMicrosoftClarity = (user: User) => {

  // eslint-disable-next-line no-undef
  const { REACT_APP_MICROSOFT_PROJECT_ID } = process.env;

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${ REACT_APP_MICROSOFT_PROJECT_ID }");
  `;
  const firstScript = document.getElementsByTagName('script')[ 0 ];
  firstScript?.parentNode.insertBefore(script, firstScript);
  window.clarity('identify', user.email);
};
