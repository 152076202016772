import React from 'react';
import styles from './Loader.module.scss';

type Props = {
  isActive: boolean;
  className?: string;
};

const Loader = ({ isActive, className = '' }: Props) => (
  <div className={ `${ styles.loaderWrapper }
    ${ isActive ? styles.active : '' }
    ${ className }` }
  >
    { isActive && (
      <div className={ styles.loader }>
      </div>
    ) }
  </div>
);

export default Loader;