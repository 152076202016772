import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  CellDoubleClickedEvent,
  CellFocusedEvent,
  CellRange,
  Column,
  EditableCallbackParams,
  IRowNode,
  IsGroupOpenByDefaultParams,
  RangeSelectionChangedEvent,
  RowClickedEvent,
  RowDataUpdatedEvent,
  RowDragEndEvent,
  RowDragMoveEvent,
  RowGroupOpenedEvent,
  RowSelectedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {
  DetailedViewType,
  FinancialNode,
  FinancialRow,
  GridColumns,
  Period,
  RowType,
} from 'types/financials.types';
import useGridRef from 'hooks/useGrid';

import Card from 'components/elements/card/Card';
import Loader from 'components/elements/loader/Loader';
import TopBar from 'components/financials/topBar/TopBar';
import {
  addLazyLoadingRowToExpand,
  financialsSlice,
  selectPeriod,
  setFiltersTable,
  updateDetailedViewSettings,
} from 'store/financials.slice';
import {
  canBeAssignedDimension,
  canDimensionItemBeChanged,
  getHeightForRowType,
  isLazyLoadingRow,
  isLazyLoadingRowLoaded,
  isTransactionRow,
} from 'utils/financials.utils';
import CostLabelerModal from '../costLabelerModal/CostLabelerModal';
import InvoiceModal from '../invoiceModal/InvoiceModal';
import { ReportType, UserReport } from 'types/templates.types';
import { getMaxIndentation } from 'hooks/processNodes';
import { useFinancialTable } from 'context/FinancialTableContext';
import useAdjustHeight from './hooks/useAdjustHeight';
import useGridFilter from './hooks/useGridFilter';
import { AG_GRID_AUTO_COLUMN } from 'utils/grid.utils';
import DynamicColumnModal from '../dynamicColumns/dynamicColumnModal/DynamicColumnModal';
import useLabeler, { GatheredLabels } from '../../../hooks/useLabeler';
import PlanDriverPanel from '../panels/planDriverPanel/PlanDriverPanel';
import useGridExport from './hooks/useGridExport';
import useGridStyles from './hooks/useGridStyles';
import useGridContextMenu from './hooks/useGridContextMenu';
import {
  addExpandRowsToStorage,
  existingStorageExpandRows,
  getKeyExpandRows,
  removeExpandRowsToStorage,
} from 'utils/storage.utils';
import useStickyHeader from './hooks/useStickyHeader';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { useResizeDetector } from 'react-resize-detector';
import { isEmpty } from 'lodash';
import { Dimension, DimensionItem, FilterList } from 'types/filterTable.types';
import { AG_GRID_LOCALE } from 'locales/agGridTransaltions/locale';
import i18n from 'locales/i18n';
import 'styles/agGridTheme.scss';
import './styles/agGrid.scss';
import styles from './styles/FinancialTable.module.scss';
import useGridFocusEffect from './hooks/useGridFocusEffect';
import useLazyLoadingRow from './hooks/useLazyLoadingRow';
import DetailedViewModal from '../detailedView/modal/DetailedViewModal';
import FinancialsLeftPanel
  from 'components/financials/panels/financialsLeftPanel/FinancialsLeftPanel';
import { getDefaultPeriod, isReportPeriodLocked } from 'utils/period.utils';
import useGridCommandBar
  from 'components/financials/financialTable/hooks/commandBar/useGridCommandBar';
import useNodeCommandBar
  from 'components/financials/financialTable/hooks/commandBar/useNodeCommandBar';
import useDetailedViewModal from 'components/financials/financialTable/hooks/useDetailedViewModal';
import useCellCommandBar
  from 'components/financials/financialTable/hooks/commandBar/useCellCommandBar';
import { useParams } from 'react-router-dom';
import { selectViewPeriod } from 'store/topBar.slice';
import DetailedViewFloatingPanel
  from 'components/financials/detailedView/panel/DetailedViewFloatingPanel';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import useRefetchOnBudgetGeneration
  from 'components/financials/financialTable/hooks/useRefetchOnBudgetGeneration';
import { useTranslation } from 'react-i18next';
import useKeyPressListener from 'hooks/useKeyPressListener';
import useClickOnAnotherTable from './hooks/useClickOnAnotherTable';
import clsx from 'clsx';
import useChatbotCommandBar from 'components/collaboration/chat/hooks/useChatbotCommandBar';
import useFinancialsCommandBar from '../hooks/useFinancialsCommandBar';
import useCommandBarDefaults from 'components/commandBar/hooks/useCommandBarDefaults';
import useGenerateTable from './hooks/commandBar/useGenerateTable';
import { sectionKeys } from '../detailedView/utils/detailedView.utils';
import ConfirmRowsDelete from 'components/elements/tableInlineEdit/confirmDelete/ConfirmRowsDelete';
import {
  getRowDataForDetailedView,
  insertNewRow,
  NO_ADD_BUTTON_ROW_TYPES
} from '../utils/addRow.utils';
import useFinancialTableDragSelection from '../../../hooks/useFinancialTableDragSelection';
import DimensionItemSelectorModal from '../dimensionItemSelectorModal/DimensionItemSelectorModal';
import { selectAltKey } from '../../../store/events.slice';
import useDelayedLabeling from './hooks/useDelayedLabeling';
import { getCellsWithValueInSelection } from '../utils/selection.utils';

dayjs.extend(duration);
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(utc);

interface Props {
  setPanelSize?: React.Dispatch<React.SetStateAction<number>>;
  isInModal?: boolean;
  useCommandBar?: boolean; // If financial table is displayed in the context of other command bar,
  // this should be false so that it is not overridden by the financial command bar settings.
}

const FinancialTable = ({ setPanelSize, isInModal, useCommandBar = true }: Props) => {
  const {
    state: {
      customSettings,
      templateId,
      title,
    },
    dispatch: tableDispatch
  } = useFinancialTable();

  const [ t ] = useTranslation('financials');
  const table = useAppSelector(state => state.financials.tables[ templateId ]);

  const anyModalOpened = useAppSelector(state => state.app.anyModalOpened);
  const isTableActive = useAppSelector(state => {
    const active = state.financials.active;
    return active?.templateId === templateId &&
      active?.type === 'table' &&
      customSettings.type === 'reports';
  });

  const detailedView = useAppSelector(state => state.financials.detailedView);

  const { _viewId } = useParams();
  const gridRef = useRef<AgGridReact>(null);

  // Report query and period settings

  const reportDefaultPeriod = useAppSelector(selectPeriod(templateId));
  const [ reportPeriod, setReportPeriod ] = useState<Period>(reportDefaultPeriod);
  const [ isPeriodLocked, setIsPeriodLocked ] =
    useState<boolean | null>(isReportPeriodLocked(templateId));
  const [ isPeriodEstablished, setIsPeriodEstablished ] = useState(false);
  const pagePeriod = useAppSelector(selectViewPeriod);
  const {
    data: report,
    isFetching,
    refetch
  } = useReportQuery({ templateId, period: reportPeriod });
  useRefetchOnBudgetGeneration({ templateId, period: reportPeriod });
  const [ rowData, setRowData ] = useState<FinancialRow[]>(null);

  useEffect(() => {
    setLabeledNodes([]);

    // ! HACK: When the user clicks on the body or a button, the focused cell is lost
    const focused = gridRef.current?.api?.getFocusedCell();
    if (focused) {
      setTimeout(() => {
        const activeElement = document.activeElement;
        if (activeElement.tagName === 'BODY' || activeElement.tagName === 'BUTTON') {
          gridRef.current?.api?.setFocusedCell(focused.rowIndex, focused.column, null);
        }
      }, 1000);
    }

  }, [ report ]);
  // Grid related hooks and settings

  const useGrid = useGridRef({
    templateId,
    period: reportPeriod,
    showDynamicColumns: table.state.dynamicColumns
  }, styles);
  const { isExternalFilterPresent, doesExternalFilterPass } = useGridFilter(gridRef, templateId);
  const { width: tableWidth, ref: tableRef } = useResizeDetector<HTMLDivElement>({
    refreshMode: 'debounce',
    refreshRate: 100
  });
  const { tableHeight, getTableHeight } = useAdjustHeight({
    gridRef,
    disabled: !customSettings.adjustHeight
  });
  const { isSticky } = useStickyHeader({ tableRef, tableWidth });
  const {
    defaultCsvExportParams,
    defaultExcelExportParams,
    excelStyles
  } = useGridExport({ title });
  const [ maxRowDepth, setMaxRowDepth ] = useState(null);

  const [ maxRowIndentation, setMaxRowIndentation ] = useState(null);

  // Labeling
  const [ dragOverNodeId, setDragOverNodeId ] = useState(null);
  const [ selectedRows, setSelectedRows ] = useState([]);
  const { labelNodes } = useLabeler(templateId);
  const { labeledNodes, isRefreshDelayed, setLabeledNodes } = useDelayedLabeling({
    onRefresh: refetch, templateId
  });

  const labeledNodeIds = useMemo(() => labeledNodes.map(n => n.id), [ labeledNodes ]);

  const { getRowsToLabel } = useFinancialTableDragSelection({ gridRef });

  const {
    defaultColDef,
    icons,
    columnTypes,
    getRowClass,
    autoGroupColumnDef,
    resizeColumns
  } = useGridStyles({
    styles,
    gridRef,
    templateId,
    maxRowDepth,
    maxRowIndentation,
    dragOverNodeId,
    grayedOutNodeIds: labeledNodeIds,
  });
  const {
    dimensionItemMap,
    dimensionMap,
    accountMap
  } = useAppSelector(state => {
    return state.breakdowns;
  });
  const { addTransactionsToNode } = useLazyLoadingRow(gridRef, templateId);

  const [ columnDefs, setColumnDefs ] = useState<GridColumns>();

  const dispatch = useAppDispatch();

  const [ rowsForRemove, setRowsForRemove ] = useState<FinancialNode[]>([]);

  const [ isTableEdited, setIsTableEdited ] = useState<object | null>(null);
  const [ invoiceTransaction, setInvoiceTransaction ] = useState(null);
  const [ isCostLabelerModalVisible, setIsCostLabelerModalVisible ] = useState(false);
  const [ isDimensionItemSelectVisible, setIsDimensionItemSelectVisible ] = useState(false);
  const [
    isDimensionItemSelectInvokedWithSkip,
    setIsDimensionItemSelectInvokedWithSkip
  ] = useState(false);

  const [ gatheredLabels, setGatheredLabels ] = useState<GatheredLabels>(null);
  const altKey = useAppSelector(selectAltKey);
  const skipConfirmationSetting = useAppSelector(state => state.labeling.settings.skipConfirmation);

  const labelingSkipConfirmation = useMemo(() => {
    return altKey || skipConfirmationSetting;
  }, [ altKey, skipConfirmationSetting ]);

  const [ dimensionForItemSelection, setDimensionForItemSelection ] = useState(null);

  const isDimensionLabelsVisible =
    useAppSelector((state) => state.financials.tables[ templateId ].state.dimensionLabels);
  const tableLoaded = useAppSelector((state) => state.financials.tablesLoadingState[ templateId ]);
  const overviewDropdown = useAppSelector(state => state.financials.overviewDropdown);

  const budgetItemTypes = useAppSelector(state => state.budget.budgetItemTypes);

  const [ lastFocusedCell, setLastFocusedCell ] = useState(null);
  const [ lastSelectedNode, setLastSelectedNode ] = useState<IRowNode>(null);
  const [ lastSelectedCellRange, setLastSelectedCellRange ] = useState<CellRange[]>(null);
  const [ lastNodesSelected, setLastNodesSelected ] = useState<IRowNode[]>([]);

  const { focusOnFirstCell } = useGridFocusEffect({
    gridRef,
    triggerCondition: !anyModalOpened
  });
  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tableLoaded) {
      gridRef?.current?.columnApi.autoSizeAllColumns(true);
    }
  }, [ tableLoaded ]);

  useEffect(() => {
    setReportPeriod(reportDefaultPeriod);
  }, [ reportDefaultPeriod ]);

  useEffect(() => {
    if (pagePeriod && !isPeriodLocked) {
      setReportPeriod(pagePeriod);
      dispatch(financialsSlice.actions.setPeriod({
        templateId,
        period: pagePeriod,
      }));
    } else {
      dispatch(financialsSlice.actions.setPeriod({
        templateId,
        period: getDefaultPeriod(templateId),
      }));
    }
    setIsPeriodEstablished(true);

  }, [ pagePeriod ]);

  const { setDetailedViewData } = useDetailedViewModal({ templateId, gridRef, setLastFocusedCell });

  useKeyPressListener({
    code: 'Enter',
    cb: (event: KeyboardEvent) => {
      const cellRange = gridRef.current?.api?.getCellRanges();
      const rowsSelected = gridRef.current?.api?.getSelectedNodes();
      if (!cellRange?.length && !rowsSelected?.length) return;

      if (!event.shiftKey) {
        if (detailedView.type !== null) return;

        setDetailedViewData(cellRange?.length ? cellRange : rowsSelected);
      } else {
        const cellsWithValue = getCellsWithValueInSelection(
          cellRange?.length ? cellRange : rowsSelected, gridRef.current
        );

        const overviewFor = cellsWithValue
          ?.map(({ node, column }) => ({
            node: node.id,
            column: column.getColId()
          }))
          .reduce((acc, item) => {
            acc.nodes.add(item.node);
            acc.columns.add(item.column);
            return acc;
          }, { nodes: new Set<string>(), columns: new Set<string>() });

        dispatch(financialsSlice.actions.setOverviewDropdown({
          nodes: Array.from(overviewFor.nodes),
          columns: Array.from(overviewFor.columns)
        }));
      }
    },
    enabled: !anyModalOpened && !customSettings.detailedViewSettings.disabled && !overviewDropdown
  });

  useKeyPressListener({
    key: '+',
    cb: () => {
      if (lastSelectedNode) {
        // ! HACK: When all parent elements are selected in PP agGrid returns only children
        if (lastSelectedNode?.parent?.isSelected()) {
          lastSelectedNode?.parent?.setExpanded(true);
        }
        clearSelection();
        addRow(lastSelectedNode);
      } else if (lastSelectedCellRange?.at(-1)?.startRow) {
        addRow(
          gridRef.current?.api?.getDisplayedRowAtIndex(
            lastSelectedCellRange?.at(-1)?.startRow?.rowIndex
          )
        );
      }
    },
    enabled: (lastSelectedNode || lastSelectedCellRange) && !anyModalOpened
  });

  const clearSelection = useCallback(() => {
    setLastSelectedNode(null);
    setLastNodesSelected([]);
    setLastFocusedCell(null);
    gridRef.current?.api?.clearRangeSelection();
    gridRef.current?.api?.deselectAll();
    gridRef.current?.api?.clearFocusedCell();
  }, []);

  useKeyPressListener({ code: 'Escape', cb: clearSelection, enabled: detailedView.type !== null });
  useClickOnAnotherTable(
    tableContainerRef,
    `item__table__${ templateId }`,
    isTableActive,
    clearSelection
  );

  useEffect(() => {
    if (!isPeriodEstablished) {
      return;
    }

    if (!reportPeriod.startDate && !reportPeriod.endDate)
      dispatch(financialsSlice.actions.setActualsOpen({ templateId, value: false }));

    if (reportPeriod.startDatePlan && reportPeriod.endDatePlan)
      dispatch(financialsSlice.actions.setPlanOpen({ templateId, value: true }));
  }, [ isPeriodEstablished ]);

  const setReportData = async (_report: UserReport) => {
    setFilters(_report?.filters);
    const res = useGrid.getRows(_report);
    const settings = table?.state?.dynamicColumns ? _report.columnSettings : null;
    const openPlan = !!(table.period.startDatePlan && table.period.endDatePlan); 
    const openActuals = !!(table.period.startDate && table.period.endDate);

    const editable = (p: EditableCallbackParams) => {
      if (!p.node.data || p.colDef.headerComponentParams?.isDynamicColumn) return false;
      if (NO_ADD_BUTTON_ROW_TYPES.includes(p.node.data.type)) return false;
      if (p.column.getColId().startsWith(ReportType.PLAN)) {
        const entry = p.node.data.plan?.[ p.column.getColId().split('__').at(-1) ];
        if (entry != null) return false;
        return true;
      }

      return false;
    };
    
    const conditionalFormatting = settings ?
      settings[ table.period.cadence ]?.conditionalFormatting : false;
    const colDefs = useGrid.getColumnDefs(
      settings,
      openActuals,
      openPlan,
      editable,
      conditionalFormatting
    );
    setColumnDefs(colDefs);

    tableDispatch({
      type: 'setTitle',
      payload: { title: _report.title, subtitle: _report.subtitle }
    });
    setMaxRowDepth(res.maxDepth);
    setMaxRowIndentation(getMaxIndentation(_report));
    if (report.nodes.length === 0 && report?.roots.length !== 0) {
      return;
    }
    setRowData(res.rows);
  };

  useEffect(() => {
    if (!report) {
      return;
    }
    setReportData(report);
    gridRef.current?.api?.forEachNode(n => {
      if (table.expansionState[ n.id ]) {
        n.setExpanded(true);
      }
    });
    gridRef.current?.api?.refreshCells({
      force: true,
      columns: [ AG_GRID_AUTO_COLUMN ]
    });
  }, [ report, table?.state?.dynamicColumns, isDimensionLabelsVisible ]);

  const setFilters = useCallback((filters: FilterList) => {
    if (isEmpty(filters)) {
      const defaultFilters: FilterList = [];
      dispatch(setFiltersTable(templateId, defaultFilters));
    } else {
      const defaultFilters: FilterList = filters;

      dispatch(setFiltersTable(templateId, defaultFilters));
    }
  }, [ templateId, dimensionItemMap, accountMap ]);

  const expandNodeAfterLabeling = useCallback((node: IRowNode, labeledChild: IRowNode) => {
    const isProxy = !node.data;
    if (isProxy) {
      return expandNodeAfterLabeling(node.parent, node);
    }
    if (isLazyLoadingRowLoaded(node)) {
      node.setExpanded(false);
      dispatch(addLazyLoadingRowToExpand(templateId, {
        nodeId: node.id,
        expandedChildren: [ labeledChild.key ]
      }));
    }
  }, [ templateId ]);

  const expandAfterLabeling = useCallback((nodes: IRowNode[]) => {
    if (!nodes.length) return;

    const nodesWithDifferentParent = nodes
      .filter(node => node.parent !== nodes[ 0 ].parent)
      .concat(nodes[ 0 ]);
    nodesWithDifferentParent.forEach(node => {
      const isProxy = !node.data;
      const isTransaction = isTransactionRow(node);
      if (!isProxy && !isTransaction) return;

      expandNodeAfterLabeling(node.parent, node);
    });
  }, [ expandNodeAfterLabeling ]);

  const showCostLabelerModal = useCallback((
    nodes: IRowNode[],
    labels: GatheredLabels,
  ) => {
    setSelectedRows(nodes);
    setGatheredLabels(labels);
    setIsCostLabelerModalVisible(true);
  }, []);

  const assignLabels = useCallback(async (
    nodes: IRowNode[],
    dimensionItem: DimensionItem,
    showModal: boolean
  ) => {
    if (!dimensionItem) return;

    const assignableNodes = nodes
      // Remove parent nodes if they have children in the list to prevent double labeling
      .filter((node, _, array) => {
        const firstChild = node.childrenAfterFilter.at(0);
        if (firstChild && array.find(n => n.id === firstChild.id)) {
          return false;
        }
        return true;
      })
      // Unpack product proxies as they don't have data where the dimension item is stored
      .flatMap(node => !node.data ? node.childrenAfterFilter : node)
      .filter(node => {
        return canBeAssignedDimension(node) && canDimensionItemBeChanged(node, dimensionItem);
      });

    if (!assignableNodes.length) return;

    if (showModal) {
      showCostLabelerModal(assignableNodes, { dimensionItems: [ dimensionItem.id ] } );
    } else {
      await labelNodes(assignableNodes, { dimensionItems: [ dimensionItem.id ] });
      setLabeledNodes(state => [ ...state, ...assignableNodes ]);
      if (!isRefreshDelayed) {
        refetch();
      }

      expandAfterLabeling(assignableNodes);
    }
  }, [ table.period, dimensionItemMap, expandAfterLabeling, isRefreshDelayed ]);

  const unassignLabels = useCallback(async (
    nodes: IRowNode[],
    dimension: Dimension,
    showModal: boolean
  ) => {
    if (showModal) {
      showCostLabelerModal(nodes, { unassignedDimensions: [ dimension.id ] });
    } else {
      await labelNodes(nodes, { unassignedDimensions: [ dimension.id ] });
      setLabeledNodes(state => [ ...state, ...nodes ]);
      if (!isRefreshDelayed) {
        refetch();
      }
      expandAfterLabeling(nodes);
    }
  }, [ table?.period, expandNodeAfterLabeling, isRefreshDelayed ]);

  const addRow = useCallback((node: IRowNode) => {
    setLastSelectedNode(null);
    setLastNodesSelected([]);
    gridRef.current?.api?.clearRangeSelection();
    gridRef.current?.api?.deselectAll();
    insertNewRow(gridRef.current, node, budgetItemTypes);
  }, []);

  const openOverviewDropdown = useCallback((node: IRowNode, colDef: Column) => {
    dispatch(financialsSlice.actions.setOverviewDropdown({
      columns: [ colDef.getColId() ], nodes: [ node.id ]
    }));
  }, [ templateId ]);

  const openDetailedView = useCallback((node: IRowNode, columnName: string) => {
    const budgetItems = getRowDataForDetailedView(node, [ columnName ]);

    dispatch(updateDetailedViewSettings({
      type: DetailedViewType.MODAL,
      templateId,
      sectionKey: sectionKeys.budgeting,
      data: {
        budgetItems: budgetItems
      }
    }));
  }, [ templateId ]);

  const removeRow = useCallback((nodes: IRowNode[]) => {
    setRowsForRemove(nodes.map(n => n.data));
  }, []);

  const confirmRowsRemove = useCallback(() => {
    gridRef.current?.api?.applyTransaction({ remove: rowsForRemove });
    setRowsForRemove([]);
    clearSelection();
  }, [ rowsForRemove ]);

  const cancelRowsRemove = useCallback(() => {
    setRowsForRemove([]);
  }, []);

  const { getContextMenuItems } = useGridContextMenu({
    styles,
    callbacks: {
      addRow,
      removeRow,
      openDetailedView,
      openOverviewDropdown
    }
  });

  const isInvoiceVisible = useMemo(() => invoiceTransaction != null, [ invoiceTransaction ]);

  const getRowHeight = useCallback(getHeightForRowType, []);

  const onRowGroupOpen = useCallback((params: RowGroupOpenedEvent) => {
    if (params.data) {
      dispatch(financialsSlice.actions.setExpansionState({
        templateId,
        nodeId: params.data.id,
        expanded: params.expanded,
      }));
    }

    if (params.event && isDimensionLabelsVisible) {
      params.node.childrenAfterGroup.forEach((childNode) => {
        if (childNode?.data?.type === RowType.BREAKDOWN) {
          childNode.setExpanded(true);
        }
      });
    }

    if (!isLazyLoadingRow(params.node)) {
      const rowsList = existingStorageExpandRows(getKeyExpandRows(templateId)) ?? [];
      if (params.node.expanded && params.node?.data?.type !== RowType.BREAKDOWN) {
        !rowsList.includes(params.node.id) && addExpandRowsToStorage(templateId, params.node.id);
      } else {
        removeExpandRowsToStorage(templateId, params.node.id);
        if (params.node.allLeafChildren.length > 0) {
          params.node.allLeafChildren.forEach(item =>
            removeExpandRowsToStorage(templateId, item.id));
        }
      }
    }

    gridRef?.current?.api?.onFilterChanged();
  }, [ templateId, getTableHeight ]);

  const selectRowNode = useCallback((e: RowClickedEvent) => {
    const selectedIRowNodes = e.api.getSelectedNodes();
    if (selectedIRowNodes.length > 0) {
      const firstRowType = e.data?.type;
      for (const row of selectedIRowNodes) {
        row.data?.type !== firstRowType && row.setSelected(false);
      }
    }
  }, []);

  const onRowClicked = useCallback((e: RowClickedEvent) => {
    if (!e.eventPath
      .some((el: Element) => el?.classList?.contains('ag-pinned-left-cols-container'))) {
      selectRowNode(e);
    }
  }, []);

  const onCellDoubleClicked = useCallback((e: CellDoubleClickedEvent) => {
    e.event.preventDefault();
    e.event.stopPropagation();
    if (!e.node.data) return;

    if (e.column.getColId().startsWith(ReportType.PLAN) &&
      e.node.data.plan[ e.column.getColId().split('__').at(-1) ] != null) {
      openOverviewDropdown(e.node, e.column);
    }
  }, []);

  const onRowDoubleClicked = useCallback((params: RowClickedEvent) => {
    const isExpanded = params.node.expanded;

    if (params.node.data?.type === RowType.BREAKDOWN) {
      return;
    }

    if (isExpanded) {
      params.node.setExpanded(false);
      params.node.allLeafChildren.forEach((child) => child.setExpanded(false));
    } else {
      if (isLazyLoadingRow(params.node)) {
        addTransactionsToNode(params.node);
      }
      params.node.setExpanded(true);
      params.node.childrenAfterGroup.forEach((child) => {
        if (child?.data?.type === RowType.BREAKDOWN) {
          child.setExpanded(true);
        }
      });
    }
  }, [ addTransactionsToNode ]);

  const popupParent = useMemo(() => {
    return document.querySelector('body');
  }, []);

  const onSelectionChanged = useCallback((params: SelectionChangedEvent) => {
    const nodes = params.api.getSelectedNodes();

    if (nodes.length) {
      setLastSelectedNode(nodes.at(0));
    } else {
      setLastSelectedNode(null);
    }
  }, [ ]);

  const onRowDataChanged = useCallback((event: RowDataUpdatedEvent) => {
    setIsTableEdited({});

    // expand all breakdowns
    event?.api?.forEachNode((row) => {
      if (row.data?.type === RowType.BREAKDOWN && !row.expanded) {
        row.setExpanded(true);
      }
    });

    const rowsList: string[] = existingStorageExpandRows(getKeyExpandRows(templateId));

    if (rowsList !== null) {
      rowsList.forEach(item => {
        const rowNode = gridRef?.current?.api?.getRowNode(item);
        if (rowNode)
          rowNode.setExpanded(true);
      });
    } else {
      // BUG? by default 2 levels are expanded,
      // if the user expands one of lower level nodes, only that one will be saved
      // after refresh other nodes will be collapsed
      // TODO: save current expand structure?
    }

    resizeColumns();
    gridRef?.current?.api?.onFilterChanged();
    if (!table?.gridReady) {
      dispatch(financialsSlice.actions.setGridReady({ templateId, value: true }));
    }
  }, [ table?.gridReady ]);

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE[ i18n.language ];
  }, [ i18n.language ]);

  const onGridReady = useCallback(() => {
    dispatch(financialsSlice.actions.setGridReady({ templateId, value: true }));
  }, []);

  const onFirstDataRendered = useCallback(() => {
    _viewId && focusOnFirstCell();
  }, [ _viewId ]);

  const onRangeSelectionChanged = useCallback((e: RangeSelectionChangedEvent) => {
    const cellRanges = e.api.getCellRanges();
    if (cellRanges?.at(0)?.columns?.at(0)?.getColId() !== 'ag-Grid-AutoColumn') {
      setLastSelectedCellRange([ ...cellRanges ]);
    }
    const isDuringSelection = !e.finished;
    if (!isDuringSelection) {
      return;
    }

    setLastNodesSelected(null);

    const selectedNodes = e.api.getSelectedNodes();

    selectedNodes.forEach((row) => row.setSelected(false));
  }, [ columnDefs ]);

  const onRowSelection = useCallback((params: RowSelectedEvent) => {
    if (params.source === 'checkboxSelected' || params.source === 'api') {
      params.api.clearRangeSelection();
      setLastSelectedCellRange(null);
      setLastNodesSelected(params.api.getSelectedNodes());
    }
  }, []);

  const onCellFocused = useCallback((e: CellFocusedEvent) => {
    setLastFocusedCell(e);
    dispatch(financialsSlice.actions.addActionToQueue({ templateId, type: 'focus', payload: {
      rowIndex: e.rowIndex,
      colId: typeof e.column === 'string' ? e.column : e.column.getColId()
    } }));
  }, []);

  const onModelUpdated = useCallback(() => {
    setTimeout(() => {
      setLastNodesSelected(prev => Array.isArray(prev) ? [ ...prev ] : prev);
      setLastSelectedCellRange(prev => Array.isArray(prev) ? [ ...prev ] : prev);
    }, 100);
  }, []);

  const noRowsOverlayComponent = useCallback(() => {
    return <span className={ styles.noDataText }>
      { !rowData ? t('table.loading') : t('table.noData') }
    </span>;
  }, [ rowData ]);

  const isGroupExpandedByDefault = useCallback((params: IsGroupOpenByDefaultParams) => {
    if (params.rowNode?.data?.type === RowType.BREAKDOWN) {
      return true;
    }

    // We use identifiers lower than 0 for temporary tables that are just generated, but
    // not kept in database. For these tables saving expansion will not work as node
    // ids are not static
    if (templateId > 0) {
      const rowsList = existingStorageExpandRows(getKeyExpandRows(templateId));
      if (rowsList !== null) return null;
    }
    return params.level < 2;
  }, [ ]);

  const onRowDragEnd = useCallback(async (e: RowDragEndEvent) => {
    setDragOverNodeId(null);
    const allowedTypes = [ RowType.DIMENSION_ITEM, RowType.BREAKDOWN ];
    if (!allowedTypes.includes(e.overNode.data?.type)) {
      return;
    }

    const overNode = e.overNode;
    const _nodes = getRowsToLabel(e.nodes);
    if (_nodes.some(node => overNode.id === node.id)) {
      return;
    }

    const _gatheredLabels: GatheredLabels = {
      dimensionItems: [],
      unassignedDimensions: []
    };

    let currentNode = overNode;
    while (currentNode) {
      if (currentNode.data?.type === RowType.DIMENSION_ITEM) {
        if (currentNode.data.rowData.id === 'Unassigned') {
          _gatheredLabels.unassignedDimensions.push(currentNode.data.rowData.dimensionId);
        } else {
          _gatheredLabels.dimensionItems.push(currentNode.data.rowData.id);
        }
      }
      currentNode = currentNode.parent;
    }
    if (e.overNode.data?.type === RowType.BREAKDOWN) {
      setSelectedRows(_nodes);
      setGatheredLabels(_gatheredLabels);
      setDimensionForItemSelection(dimensionMap[ e.overNode.data.rowData.id ]);
      setIsDimensionItemSelectVisible(true);
      setIsDimensionItemSelectInvokedWithSkip(labelingSkipConfirmation);
    } else {
      if (labelingSkipConfirmation) {
        await labelNodes(_nodes, _gatheredLabels);
        setLabeledNodes(state => [ ...state, ..._nodes ]);
        if (!isRefreshDelayed) {
          refetch();
        }
      } else {
        showCostLabelerModal(_nodes, _gatheredLabels);
      }

    }

  }, [ labelingSkipConfirmation, isRefreshDelayed ]);

  const onRowDragMove = useCallback((e: RowDragMoveEvent) => {
    if (!e.overNode) {
      setDragOverNodeId(null);
      return;
    }
    if (e.overNode?.id !== dragOverNodeId) {
      if ([ RowType.DIMENSION_ITEM, RowType.BREAKDOWN ].includes(e.overNode.data?.type)) {
        setDragOverNodeId(e.overNode.id);
      } else {
        setDragOverNodeId(null);
      }

    }
    const _nodes = getRowsToLabel(e.nodes);
    // if dragged nodes is not one of the selected - unselect current nodes and select the one
    // that is dragged
    if (_nodes.length === 1 || !_nodes.some(node => e.node.id === node.id)) {
      gridRef.current?.api.deselectAll();
      e.node.setSelected(true);
    }
  }, []);

  const gridComponent = useCallback(() => {
    if (!columnDefs) return null;
    return (
      <AgGridReact
        onRowDragEnd={ onRowDragEnd }
        onRowDragMove={ onRowDragMove }
        onRowDragLeave={ () => setDragOverNodeId(null) }
        localeText={ localeText }
        ref={ gridRef }
        rowData={ rowData }
        onGridReady={ onGridReady }
        rowBuffer={ 40 }
        columnDefs={ columnDefs.columnDefs }
        context={ { openContextMenu: [] } }
        getDataPath={ (row) => row.filePath }
        popupParent={ popupParent }
        treeData={ true }
        getRowId={ (row) => row.data.id }
        animateRows={ true }
        suppressDragLeaveHidesColumns={ true }
        suppressMovableColumns={ true }
        excludeChildrenWhenTreeDataFiltering={ true }
        rowDragMultiRow={ true }
        suppressRowClickSelection={ true }
        onFirstDataRendered={ onFirstDataRendered }
        isGroupOpenByDefault={ isGroupExpandedByDefault }
        getRowHeight={ getRowHeight }
        rowSelection='multiple'
        groupSelectsChildren={ true }
        onRowDoubleClicked={ onRowDoubleClicked }
        suppressClickEdit={ true }
        onCellDoubleClicked={ onCellDoubleClicked }
        onRowDataUpdated={ onRowDataChanged }
        onRowGroupOpened={ onRowGroupOpen }
        onFilterChanged={ () => getTableHeight(isSticky) }
        onRowClicked={ onRowClicked }
        onSelectionChanged={ onSelectionChanged }
        onRowSelected={ onRowSelection }
        onCellFocused={ onCellFocused }
        onModelUpdated={ onModelUpdated }
        columnTypes={ columnTypes }
        defaultColDef={ defaultColDef }
        isExternalFilterPresent={ isExternalFilterPresent }
        doesExternalFilterPass={ doesExternalFilterPass }
        getRowClass={ getRowClass }
        onColumnGroupOpened={ params => params.columnApi.autoSizeAllColumns(true) }
        noRowsOverlayComponent={ noRowsOverlayComponent }
        loadingOverlayComponent={ noRowsOverlayComponent }
        getContextMenuItems={ getContextMenuItems }
        icons={ icons }
        autoGroupColumnDef={ autoGroupColumnDef }
        defaultExcelExportParams={ defaultExcelExportParams }
        excelStyles={ excelStyles }
        defaultCsvExportParams={ defaultCsvExportParams }
        enableRangeSelection={ true }
        suppressMultiRangeSelection={ true }
        enableRangeHandle={ true }
        onRangeSelectionChanged={ onRangeSelectionChanged }
      >
      </AgGridReact>

    );
  }, [ columnDefs,
    table.sorting,
    defaultColDef,
    rowData,
    table.state.accountsNumber,
    table.state.accounts,
    onFirstDataRendered,
    isSticky,
    onSelectionChanged,
    noRowsOverlayComponent,
    labelingSkipConfirmation,
    autoGroupColumnDef,
  ]);

  return (
    <div
      data-view-item-id={ `item__table__${ templateId }` }
      className={ clsx( styles.tableContainer, {
        [ styles.tableAutoContainer ]: !customSettings.adjustHeight,
        [ styles.tableList ]: !isInModal
      })
      }
    >
      { isTableActive && useCommandBar && <FinancialCommandBar
        isTableEdited={ isTableEdited }
        templateId={ templateId }
        gridRef={ gridRef }
        node={ lastSelectedNode }
        setRowsForRemove={ setRowsForRemove }
        setInvoiceTransaction={ setInvoiceTransaction }
        lastNodesSelected={ lastNodesSelected }
        lastSelectedCellRange={ lastSelectedCellRange }
        addRow={ addRow }
        setLastFocusedCell={ setLastFocusedCell } />
      }
      { !customSettings.panelSettings.plan.panelDisabled ? <PlanDriverPanel /> : null }
      {
        (isInModal || isTableActive) &&
        !customSettings.panelSettings.filterLabel.panelDisabled ?
          (<div>
            <FinancialsLeftPanel
              disableTabs={ isInModal ? [ 'layout' ] : undefined }
              node={ lastSelectedNode }
              gridRef={ gridRef }
              assignLabels={ assignLabels }
              unassignLabels={ unassignLabels }
            />
          </div>) : null
      }
      <Card
        onClick={ () => dispatch(financialsSlice.actions.setActive({
          templateId,
          type: 'table'
        })) }
        className={ `
          ${ styles.financialTableCard }
          ${ customSettings.classNames.card }
        ` }>
        <Loader isActive={ isFetching }/>
        {
          report != undefined ?
            <>
              <CostLabelerModal
                isVisible={ isCostLabelerModalVisible }
                onClose={ () => {
                  setGatheredLabels(null);
                  setIsCostLabelerModalVisible(false);

                } }
                gatheredLabels={ gatheredLabels }
                onConfirm={ async () => {
                  setLabeledNodes(state => [ ...state, ...selectedRows ]);
                  if (!isRefreshDelayed) {
                    refetch();
                  }
                  expandAfterLabeling(selectedRows);
                } }
                selectedRows={ selectedRows }
                templateId={ templateId }
              />
              <InvoiceModal
                isVisible={ isInvoiceVisible }
                onClose={ () => setInvoiceTransaction(null) }
                transaction={ invoiceTransaction?.data?.rowData }
              />
              <DynamicColumnModal
                templateId={ templateId }
              />
              <DetailedViewModal
                templateId={ templateId }
                financialGridRef={ gridRef }
                lastFocusedCell={ lastFocusedCell }
                onTableRefresh={ refetch }
              />
              <ConfirmRowsDelete
                isVisible={ rowsForRemove.length > 0 }
                rows={ rowsForRemove }
                onClose={ cancelRowsRemove }
                onConfirm={ confirmRowsRemove }
              />
              { dimensionForItemSelection && <DimensionItemSelectorModal
                dimension={ dimensionForItemSelection }
                isVisible={ isDimensionItemSelectVisible }
                onClose={ () => setIsDimensionItemSelectVisible(false) }
                onConfirm={ async (dimensionItemId: number | 'unassigned') => {
                  const _gatheredLabels: GatheredLabels = {
                    ...gatheredLabels
                  };
                  if (dimensionItemId === 'unassigned') {
                    _gatheredLabels.unassignedDimensions.push(dimensionForItemSelection.id);
                  } else {
                    _gatheredLabels.dimensionItems.push(dimensionItemId);
                  }

                  if (isDimensionItemSelectInvokedWithSkip) {
                    await labelNodes(selectedRows, _gatheredLabels);
                    setLabeledNodes(state => [ ...state, ...selectedRows ]);
                    if (!isRefreshDelayed) {
                      refetch();
                    }
                  } else {
                    showCostLabelerModal(selectedRows, _gatheredLabels);
                  }
                } }
              /> }
            </>
            : null
        }
        <div className={ `${ styles.tableTop } ${ isTableActive ? 'active' : '' }` }
          ref={ tableContainerRef }>
          { report ? <TopBar
            header={ title?.title }
            description={ title?.subtitle }
            setIsPeriodLocked={ setIsPeriodLocked }
            isPeriodLocked={ isPeriodLocked }
            period={ reportPeriod }
          /> : null }
          <div
            className={
              `ag-theme-alpine
              ${ isTableActive ? styles.activeTable : '' }
              ${ styles.table }`
            }
            style={ { height: customSettings.adjustHeight ? `${ tableHeight }px` : null } }
            ref={ tableRef }
          >
            { columnDefs && gridComponent() }
            {
              detailedView.type === DetailedViewType.FLOATING_PANEL &&
              detailedView.templateId === templateId &&
                <DetailedViewFloatingPanel
                  onSizeChange={ setPanelSize }
                  templateId={ templateId }
                  onTableRefresh={ refetch }
                />
            }
          </div>
        </div>
      </Card>
    </div>
  );
};

const FinancialTableWrapper = ({ ...props }: Props) => {
  const { state: { templateId } } = useFinancialTable();
  const isTablePresent = useAppSelector(state => !!state.financials.tables[ templateId ]);

  return templateId && isTablePresent ? <FinancialTable { ...props } /> : null;
};

export default FinancialTableWrapper;

const FinancialCommandBar = ({
  templateId,
  gridRef,
  node,
  setInvoiceTransaction,
  lastSelectedCellRange,
  lastNodesSelected,
  setLastFocusedCell,
  isTableEdited,
  addRow,
  setRowsForRemove
}) => {
  useCommandBarDefaults({
    keys: [
      'filter', 'label', 'sort', 'layout',
      'divider.1',
      'detailed-view',
      'pdf',
      'export',
      'settings',
      'overviewDetails', 'addRow', 'removeRow',
      'chat',
      'save'
    ],
    mode: 'show' as const,
    showCapsulesFilter: true
  });
  useChatbotCommandBar({ disabled: false });
  useGridCommandBar({ templateId, gridRef });
  useNodeCommandBar({ templateId, setInvoiceTransaction, node });
  useGenerateTable({ templateId, gridRef, isTableEdited });
  useCellCommandBar({
    templateId,
    gridRef,
    cellRange: lastSelectedCellRange,
    selectedNodes: lastNodesSelected,
    setLastFocusedCell,
    addRow,
    setRowsForRemove
  });
  useFinancialsCommandBar({ disabled: false });

  return null;
};
