import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardElementMapping, DashboardElementType } from 'types/dashboard.types';
import { ReactComponent as ChartIcon } from 'assets/icons/chart-placeholder.svg';
import styles from './ChartRenderer.module.scss';
import { ChartContextProvider } from 'components/charts/context/ChartContext';
import { ChartSettings } from 'types/chart.types';
import { FormProvider, useForm } from 'react-hook-form';
import { FinancialTableContextProvider, useFinancialTable } from 'context/FinancialTableContext';
import {
  mapChartElementToSettings,
  getPerTemplateNodeUuids,
  getChartElements
} from 'utils/dashboard.utils';
import Chart from '../../../../charts/chart/Chart';
import LoadingWrapper from 'components/wrappers/loadingWrapper/LoadingWrapper';
import { ReactComponent as FilterIcon } from 'assets/icons/filterv2.svg';
import { setLeftPanel } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import FilterLabelButton from 'components/financials/filterLabelButton/FilterLabelButton';
import { Tooltip } from 'antd';
import { selectViewPeriod } from 'store/topBar.slice';
import useFinancialReport from 'components/dashboard/hooks/useFinancialReport';
interface Props {
  item: DashboardElementMapping<DashboardElementType.CHART>;
}

const ChartRenderer = ({ item }: Props) => {
  const [ t ] = useTranslation('dashboard');
  const dispatch = useAppDispatch();
  const templateId = useMemo(() => item.element?.template, [ item.element?.template ]);

  const showPlaceholder = useMemo(() => {
    return !item.element.datasets.some(dataset => dataset.active);
  }, [ item ]);

  const title = useMemo(() => {
    return item.name || t('panel.card.placeholder.name');
  }, [ item ]);

  if (showPlaceholder) {
    return <div className={ styles.placeholder }>
      <h4 className={ styles.chartTitle }>{ title }</h4>
      <ChartIcon />
    </div>;
  }

  const showFilterTableHandler = () => {
    dispatch(setLeftPanel({
      templateId,
      panelType: 'filter',
      type: 'chart'
    }));
  };

  return <FinancialTableContextProvider templateId={ templateId }>
    <div className={ styles.chartContainer }>
      <div className={ styles.titleContainer }>
        <Tooltip
          title={ title }
          mouseEnterDelay={ .4 }
        >
          <h4 className={ styles.chartTitle }>
            { title || null }
          </h4>
        </Tooltip>
        <span onClick={ () => showFilterTableHandler() } className={ styles.filterButtonWrapper }>
          <FilterLabelButton
            activeCloseButton={ false }
            section='filter'
            icon={ <FilterIcon/> }
            text={ null }
            suppressHover
          />
        </span>
      </div>
      <ChartWrapper item={ item } />
    </div>
  </FinancialTableContextProvider>;
};

const ChartWrapper = ({ item }: Props) => {
  const methods = useForm<ChartSettings>({
    defaultValues: mapChartElementToSettings(item.element)
  });
  const { state: { templateId } } = useFinancialTable();
  const period = useAppSelector(selectViewPeriod);
  const dashboardElements = useAppSelector(state => state.dashboard.elements);
  const templateNodeUuids = useMemo(() => {
    const perTemplateNodes = getPerTemplateNodeUuids(getChartElements(dashboardElements));
    return Array.from(perTemplateNodes[ item.element.template ] || [])
      .sort((a, b) => a.localeCompare(b));
  }, [ dashboardElements ]);
  const { loading } = useFinancialReport({ templateId, isDashboard: true, templateNodeUuids });
  const table = useAppSelector(state => state.financials.tables[ templateId ]);
  useEffect(() => {
    if (!item.element) return;

    methods.reset(mapChartElementToSettings(item.element));
  }, [ item.element ]);

  return <LoadingWrapper loading={ loading }>
    <FormProvider { ...methods }>
      <ChartContextProvider templateNodeUuids={ templateNodeUuids }>
        {
          table ? <Chart
            className={ styles.chart }
            period={ period }/>
            : null
        }
      </ChartContextProvider>
    </FormProvider>
  </LoadingWrapper>;
};

export default ChartRenderer;
