import { defaults } from 'components/commandBar/context/CommandBarContext';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { clearLeftPanel, selectActive, setLeftPanel } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';

const layoutButton = defaults.find((button) => button.key === 'layout');

interface Props {
  disabled: boolean;
}

const useLayoutCommandBar = ({ disabled = false }: Props) => {
  const [ t, i18n ] = useTranslation('financials');

  const appDispatch = useAppDispatch();
  const activeItem = useAppSelector(selectActive);
  const leftPanel = useAppSelector(state => state.app.leftPanel);

  const layoutState: CommandBarUtil[] = useMemo(() => {
    return [ {
      ...layoutButton,
      type: 'button',
      hidden: false,
      disabled: disabled,
      icon: layoutButton.icon,
      active: leftPanel === 'layout',
      tooltip: t('common:command-bar.tooltip.layout'),
      onClick: () => {
        if (leftPanel === 'layout') {
          appDispatch(clearLeftPanel());
        } else {
          appDispatch(setLeftPanel({ panelType: 'layout', ...activeItem }));
        }
      }

    } ];
  }, [ activeItem, leftPanel, disabled, i18n.language, appDispatch ]);

  return useCommandBarEffect({ utils: layoutState });
};

export default useLayoutCommandBar;