import React from 'react';
import { Tabs as AntdTabs, TabsProps } from 'antd';
import styles from './Tabs.module.scss';

type Props = TabsProps & {
  fullWidth?: boolean;
};

const Tabs = ({ className='', animated=true, fullWidth=true, ...tabsProps }: Props) => {
  return (
    <AntdTabs
      { ...tabsProps }
      animated={ animated }
      moreIcon={ <></> }
      className={ `${ styles.tabs } ${ className } ` + (fullWidth ? styles.fullWidth : '') }
    />
  );
};

export default Tabs;
