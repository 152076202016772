import { hotjar } from 'react-hotjar';

// eslint-disable-next-line no-undef
const { REACT_APP_HOTJAR_ID, REACT_APP_HOTJAR_SV } = process.env;

export const connectHotjar = (user) => {

  hotjar.initialize(Number(REACT_APP_HOTJAR_ID), Number(REACT_APP_HOTJAR_SV));

  hotjar.event('button-click');
    
  if (hotjar.initialized()) {
    hotjar.identify( 'USER_ID', { userProperty: user.email });
  }

};

export const changeHotjarState = (location: string) => {
  if (hotjar.initialized()) {
    hotjar.stateChange(location);
  }
};