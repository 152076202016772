import React from 'react';
import { useFinancialTable } from 'context/FinancialTableContext';
import { useEffect } from 'react';

const DashboardTable = ({ children }) => {
  const { dispatch } = useFinancialTable();

  useEffect(() => {
    dispatch({ type: 'setType', payload: 'dashboard' });
  }, []);

  return <>{ children }</>;
};

export default DashboardTable;