import http from 'utils/http.utils';
import { apiUrl } from 'utils/config.utils';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError } from 'axios';

interface HttpBaseQuery { baseUrl: string }

export const httpBaseQuery = ({ baseUrl }: HttpBaseQuery = { baseUrl: apiUrl }): BaseQueryFn<
{
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
},
unknown,
unknown
> =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await http({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
