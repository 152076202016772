import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'components/wrappers/notFoundWrapper/NotFoundWrapper.module.scss';

interface Props {
  children: React.ReactNode;
  found: boolean;
}

const NotFoundWrapper = ({ children, found }: Props) => {
  const [ t ] = useTranslation('common');
  if (found) {
    return <div className={ styles.notFound }>{ t('not-found') }</div>;
  }

  return <>{ children }</>;
};

export default NotFoundWrapper;