import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { Control, Controller, FieldErrors } from 'react-hook-form';

type Props = {
  name: string;
  label: string;
  errors: FieldErrors;
  control: Control;
};

const Checkbox = ({ name, label, control }: Props) => (
  <Controller
    name={ name }
    control={ control }
    render={ ({ field }) => (
      <AntdCheckbox 
        { ...field }
        type='checkbox'
        checked={ field.value }
      >
        { label }
      </AntdCheckbox>
    ) }
  />
);

export default Checkbox;
