import React, { useCallback, useRef, useState } from 'react';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { Popover } from 'antd';
import { noop } from 'lodash';
import { PropsWithChildren } from 'react';
import { financialsSlice } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import BudgetItemPopover from '../budgetItemPopover/BudgetItemPopover';

import styles from './BudgetCellRenderer.module.scss';
import { FinancialNode } from 'types/financials.types';
import ConfirmRowsDelete from '../confirmDelete/ConfirmRowsDelete';
import WarningModal from 'components/elements/modals/warnings/WarningModal';
import { useTranslation } from 'react-i18next';
import useOutsideClickListener from 'hooks/useOutsideClickListener';
import { useDrag, useDrop } from 'react-dnd';
import dayjs from 'dayjs';

interface MoveProps {
  srcDate: string;
  targetDate: string;
  node: IRowNode;
}

interface DraggableItem {
  node: IRowNode;
  column: string;
}

const BudgetCellRenderer = (props: PropsWithChildren<ICellRendererParams>) => {
  const [ t ] = useTranslation('common');
  const appDispatch = useAppDispatch();
  const [ isConfirmCloseModalOpen, setIsConfirmCloseModalOpen ] = useState(false);
  const popoverRef = useRef(null);

  const [ confirmMove, setConfirmMove ] = useState<MoveProps | null>(null);

  const [ , drag ] = useDrag(() => {
    return {
      type: 'entry',
      item: {
        node: props.node,
        column: props.colDef.field,
      },
      canDrag: () => {
        const entryId = props.column.getColId().split('__').at(-1);
        return props.node.data.rowData.entries?.[ entryId ]?.amountFormula;
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    };
  }, [ props.colDef.field ]);

  const [ { isOver }, drop ] = useDrop(() => ({
    accept: 'entry',
    canDrop: (item: DraggableItem) => {
      return item.node.id === props.node.id && item.column !== props.colDef.field;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
      canDrop: monitor.canDrop(),
    }),
    drop: ({ node, column }) => {
      if (node.id === props.node.id) {
        const srcDate = column.split('__').at(-1);
        const targetDate = props.colDef.field.split('__').at(-1);

        setConfirmMove({ srcDate, targetDate, node });
      }
    }
  }), [ ]);

  const [ rowsForDelete, setRowsForDelete ] = useState<FinancialNode[]>([]);

  const cadence = useAppSelector(
    state => state.financials.tables[ state.financials.active.templateId ]?.period.cadence
  );
  const overviewDropdown = useAppSelector(state => state.financials.overviewDropdown);

  const isOpen = overviewDropdown?.columns?.at(0) === props.colDef.field &&
    overviewDropdown?.nodes?.at(0) === props.node.id;

  const nodes = overviewDropdown?.nodes.map(node => props.api.getRowNode(node)) || [];

  const close = () => {
    appDispatch(financialsSlice.actions.setOverviewDropdown(null));
    props.api.setFocusedCell(props.node.rowIndex, props.colDef.field, null);
  };

  const confirmDelete = () => {
    props.api.applyTransaction({ remove: rowsForDelete });
  };

  const cancelDelete = () => {
    setRowsForDelete([]);
  };

  const onRowDelete = (deleteNodes: FinancialNode[]) => {
    setRowsForDelete(deleteNodes);
  };

  const cellValue = <div style={ { height: '2rem' } }>
    { props.children }
  </div>;

  const onClose = (message?: string) => {
    if (typeof message === 'string') {
      close();
    } else {
      onOutsideClick();
    }
  };

  const onOutsideClick = useCallback(() => {
    setIsConfirmCloseModalOpen(true);
  }, []);

  useOutsideClickListener({
    ref: popoverRef,
    cb: onOutsideClick,
    enabled: isOpen
  });

  const onMoveConfirm = () => {
    if (!confirmMove) {
      return;
    }

    const entries = {
      ...(props.node.data.rowData.entries ?? {})
    };
    const plan = {
      ...(props.node.data.plan ?? {})
    };

    const { srcDate, targetDate } = confirmMove;

    delete plan[ srcDate ];
    delete entries[ srcDate ];

    const update = {
      ...props.node.data,
      plan: {
        ...plan,
        [ targetDate ]: props.node.data.plan[ srcDate ],
      },
      rowData: {
        ...props.node.data.rowData,
        entries: {
          ...entries,
          [ targetDate ]: {
            ...props.node.data.rowData.entries[ srcDate ],
            startDate: targetDate,
          }
        },
      }
    };

    props.api.applyTransaction({
      update: [ update ],
    });

    setConfirmMove(null);
  };

  if (rowsForDelete.length) {
    return <>
      <ConfirmRowsDelete
        rows={ rowsForDelete }
        isVisible onConfirm={ confirmDelete }
        onClose={ cancelDelete } />
      { cellValue }
    </>;
  }

  if (isOpen) {
    return <>
      <WarningModal
        isVisible={ isConfirmCloseModalOpen }
        onClose={ () => setIsConfirmCloseModalOpen(false) }
        onConfirm={ close }
        title={ t('warnings.confirm-close-title') }
        wrapClassName={ styles.confirmCloseWrap }
        enforceButtons
        description={ t('warnings.confirm-close-description') }
      />
      <Popover
        placement='right'
        showArrow={ false }
        destroyTooltipOnHide
        open={ isOpen }
        onOpenChange={ noop }
        key={ `${ props.node.id }_${ isOpen }` }
        getPopupContainer={ () => document.body as HTMLElement }
        content={
          <BudgetItemPopover
            gridApi={ props.api }
            popoverRef={ popoverRef }
            nodes={ nodes }
            columns={ overviewDropdown?.columns }
            onRowDelete={ onRowDelete }
            cadence={ cadence }
            onClose={ onClose }
          />
        }
      >
        { cellValue }
      </Popover>
      
    </>;
  }

  return <>
    <div ref={ drop } className={ isOver ? 'newRowOver' : '' }>
      <div ref={ drag }>
        { cellValue }
      </div>
    </div>
    {
      (isOver || confirmMove) && <WarningModal
        isVisible={ confirmMove != null }
        onClose={ () => setConfirmMove(null) }
        onConfirm={ onMoveConfirm }
        title={ t('warnings.confirm-close-title') }
        wrapClassName={ styles.confirmCloseWrap }
        enforceButtons
        description={
          t('warnings.confirm-move-description',
            { 
              srcDate: confirmMove ? getSrcDate(confirmMove.node, confirmMove.srcDate): '',
              targetDate: confirmMove ? dayjs(confirmMove.targetDate).format('DD.MM.YYYY') : ''
            })
        }
      />
    }
  </>;
};

export default BudgetCellRenderer;

function getSrcDate(node: IRowNode, columnDate: string) {
  let date = '';
  if (!columnDate) return date;

  date = node.data.rowData.entries?.[ columnDate ]?.startDate;

  if (!date) {
    date = columnDate;
  }

  return dayjs(date).format('DD.MM.YYYY');
}
