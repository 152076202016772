import Capsule from 'components/elements/capsule/Capsule';
import React from 'react';
import styles from './ExpandButton.module.scss';

interface Props{
  number: number;
  onClick: () => void;
  hide: boolean;
}

const ExpandButton = ({ hide, number, onClick }: Props) => {
  return (
    <>
      {
        hide ? (
          <Capsule
            isActive={ true }
            onClick={ onClick }
            className={ styles.expandButton }
          >
            { `+${ number }` }
          </Capsule>
        ): null
      }
    </>
  );
};

export default ExpandButton;