import { useTranslation } from 'react-i18next';
import { Message, MessageSource, MessageType } from 'types/chat.types';
import { getUUID } from 'utils/templates.utils';

const useChatbotResponse = () => {
  const [ t ] = useTranslation('collaboration');

  const getErrorMessage = (): Message => {
    return {
      id: getUUID(),
      text: t('chatbot.response.error'),
      source: MessageSource.SYSTEM,
      type: MessageType.ERROR
    };
  };

  return {
    getErrorMessage
  };
};

export default useChatbotResponse;
