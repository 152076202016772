import { useCallback } from 'react';
import { RowDragMoveEvent } from 'ag-grid-community';
import { getHtmlRows } from 'utils/financials.utils';

let overNodeId = '';

const useGridDragStyles = () => {
  const clearHoveredRow = useCallback(() => {
    const htmlRowRemove = getHtmlRows(overNodeId);
    htmlRowRemove.forEach(element => {
      const divEl = element as HTMLDivElement;
      divEl.classList.remove('rowOver');
    });
    overNodeId = '';
  }, []);

  const onRowDragMove = useCallback((e: RowDragMoveEvent) => {
    if (e.overNode == null || e.node === e.overNode || overNodeId === e.overNode.id) {
      return;
    }
    clearHoveredRow();
    const htmlRowAdd = getHtmlRows(e.overNode.id);
    htmlRowAdd.forEach(element => {
      const divEl = element as HTMLDivElement;
      const doesHave = divEl.classList.contains('rowOver');
      if (!doesHave) {
        divEl.classList.add('rowOver');
      }
    });
    overNodeId = e.overNode.id;
  }, []);

  const onRowDragEnd = useCallback(() => {
    clearHoveredRow();
  }, []);

  return {
    clearHoveredRow,
    onRowDragMove,
    onRowDragEnd
  };
};

export default useGridDragStyles;
