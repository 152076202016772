import React, { useEffect, useRef } from 'react';
import { Input, InputRef } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styles from './SearchInput.module.scss';

type Props = {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  onClear: () => void;
  isOpen?: boolean;
  autoFocus?: boolean;
  callback?: (e) => void;
};

const SearchInput = ({
  value,
  onChange,
  placeholder,
  onClear,
  isOpen = true,
  autoFocus = false,
  callback,
}: Props) => {
  const ref = useRef<InputRef>();

  useEffect(() => {
    if (autoFocus) {
      // Delay is used to prevent losing focus on cell in ag-grid Table
      setTimeout(() => {
        ref.current?.focus({
          preventScroll: true
        });
      }, 200);
    }
  }, [ autoFocus ]);

  return (
    <Input
      ref={ ref }
      onKeyDown={ (e) => callback && callback(e) }
      prefix={ <SearchOutlined className={ styles.inputIcon } /> }
      suffix={ <CloseIcon
        onClick={ onClear }
        className={ `${ styles.closeIcon } ${ value === '' ? styles.closeIconHidden : '' }` }
      /> }
      className={ `${ styles.searchInput } ${ !isOpen ? styles.closePanel : '' }` }
      value={ value }
      onChange={ onChange }
      placeholder={ placeholder }
    />
  );
};

export default SearchInput;
