import React from 'react';
import { Button as AntdButton, ButtonProps } from 'antd';

import styles from './Button.module.scss';
import Tooltip from 'components/elements/tooltip/Tooltip';
import clsx from 'clsx';

export interface Props {
  tooltip?: string | null;
}

const Button = (
  {
    htmlType = 'button',
    className = '',
    type = 'primary',
    size = 'middle',
    id,
    loading = false,
    tooltip = '',
    ...buttonProps
  }: ButtonProps & React.RefAttributes<HTMLElement> & Props) =>
  (<Tooltip title={ tooltip }>
    <AntdButton
      { ...buttonProps }
      data-testid={ id }
      type={ type }
      loading={ loading }
      shape='round'
      size={ size }
      disabled={ false }
      onClick={ buttonProps.disabled ? undefined : buttonProps.onClick }
      className={ clsx(styles.button, { [ 'disabled' ] : buttonProps.disabled }, className) }
      htmlType={ htmlType }
    >
      { buttonProps.children || buttonProps.name }
    </AntdButton>
  </Tooltip>
  );

export default Button;
