import { useCallback } from 'react';
import { Column, GetContextMenuItemsParams, IRowNode } from 'ag-grid-community';

import type { ImportedStyles } from 'types/app.types';
import { haveRevenueRecognition, canOpenOverviewDropdown } from 'utils/financials.utils';

import ExternalLinkIcon from 'assets/icons/external-link.svg';
import PlusIcon from 'assets/icons/plus.svg';
import DeleteIcon from 'assets/icons/trash-bin.svg';
import LensIcon from 'assets/icons/text-lens.svg';
import DetailedViewIcon from 'assets/icons/detailed-view.svg';

import dayjs from 'dayjs';
import { routes } from 'utils/config.utils';
import { useTranslation } from 'react-i18next';
import { ReportType } from 'types/templates.types';
import { RowType } from 'types/financials.types';
import { NO_ADD_BUTTON_ROW_TYPES } from 'components/financials/utils/addRow.utils';

type CallbacksType = {
  addRow: (node: IRowNode) => void;
  openOverviewDropdown: (node: IRowNode, colDef: Column) => void;
  openDetailedView: (node: IRowNode, columnName: string) => void;
  removeRow: (nodes: IRowNode[]) => void;
};

type Props = {
  styles: ImportedStyles;
  callbacks: CallbacksType;
};

const useGridContextMenu = ({ styles, callbacks }: Props) => {
  const [ t ] = useTranslation([ 'financials', 'sorting' ]);

  const {
    addRow,
    removeRow,
    openOverviewDropdown,
    openDetailedView
  } = callbacks;

  const canAddNewRow = (node: IRowNode, column: Column) => {
    if (!column.getColId().startsWith(ReportType.PLAN)) {
      return false;
    }
    return !NO_ADD_BUTTON_ROW_TYPES.includes(node.data?.type);
  };

  const navigateToRevenueRecognition = useCallback((node: IRowNode) => {
    const counterpartyId = node.data?.rowData?.counterparty?.id;
    const rawDate = node.data?.rowData?.transactionDate;
    const transactionDate = rawDate ? dayjs(rawDate).format('YYYY-MM-DD') : '';
    const revenueRecognition = routes.manage.revenueRecognition;
    const url = `${ revenueRecognition }${ counterpartyId }?date=${ transactionDate }`;
    window.open(url, '_blank');
  }, []);

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    let selectedNodes = params.api.getSelectedNodes();
    if (!selectedNodes.some(n => n.id === params.node.id)) {
      // AG GRID is not deselecting nodes when opening context menu on item outside of selection
      selectedNodes.forEach(n => n.setSelected(false));
      selectedNodes = [ params.node ];
    }
    if (selectedNodes[ 0 ]?.data?.type !== params.node?.data?.type) {
      selectedNodes.forEach(n => n.setSelected(false));
      selectedNodes = [];
    }
    if (!selectedNodes.includes(params.node)) {
      selectedNodes.forEach(n => n.setSelected(false));
    }
    params.node.setSelected(true);
    params.api.clearRangeSelection();

    return [
      ...canAddNewRow(params.node, params.column) ? [ {
        name: 'Add new row',
        cssClasses: [ styles.showRevenueRecognition ],
        icon: `<img src=${ PlusIcon } alt="add"/>`,
        action: () => addRow(params.node)
      } ] : [],
      ...canOpenOverviewDropdown(params.node) ? [
        {
          name: t('context-menu.open-overview-dropdown'),
          action: () => openOverviewDropdown(params.node, params.column),
          icon: `<img src=${ LensIcon } class="${ styles.contextMenuIcon }" alt="lens"/>`
        },
        {
          name: t('context-menu.delete-row'),
          action: () => removeRow(
            params.api.getSelectedNodes()
              .filter((node) => node.data?.type === RowType.NEW_BUDGET_ITEM)
          ),
          icon: `<img src=${ DeleteIcon } class="${ styles.contextMenuIcon }" alt="delete"/>`
        },
        {
          name: t('context-menu.open-details-view'),
          action: () => openDetailedView(params.node, params.column.getColId()),
          icon: `<img src=${ DetailedViewIcon } class="${ styles.contextMenuIcon }" alt="details"/>`
        }
      ] : [],
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'separator',
      'export',
      haveRevenueRecognition(params.node) && {
        name: t('context-menu.show-revenue-recognition'),
        cssClasses: [ styles.showRevenueRecognition ],
        icon: `<img src=${ ExternalLinkIcon }
          class="${ styles.contextMenuIcon } ${ styles.externalLinkIcon }"
          alt=""/>`,
        action: () => navigateToRevenueRecognition(params.node)
      }
    ];
  };

  return {
    getContextMenuItems
  };
};

export default useGridContextMenu;
