import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { LEFT_PANEL_PORTAL_ID } from 'components/panels/utils/panels.utils';
import Navbar from 'components/layout/navbar/Navbar';
import { selectViewId } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import ScrollUpButton from '../financials/scrollUpButton/ScrollUpButton';
import { TopBar } from './topBar/TopBar';
import {
  CommandBarContextProvider,
  useCommandBar
} from 'components/commandBar/context/CommandBarContext';
import { FormulaRowContextProvider, useFormulaRow } from 'components/formulaRow/FormulaRowContext';
import { FormulaRow } from 'components/formulaRow/FormulaRow';
import CommandBar from 'components/commandBar/CommandBar';
import ChatPanel from 'components/financials/panels/chatPanel/ChatPanel';
import ResizableContainer from 'components/elements/resizableContainer/ResizableContainer';
import PanelCard from 'components/panels/elements/card/PanelCard';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';
import useMutationObserver from 'hooks/useMutationObserver';
import { DASHBOARD_PANEL_ID } from 'utils/dashboard.utils';

import styles from './Layout.module.scss';
import { appSlice } from '../../store/app.slice';

const OPTIONS = { childList: true };

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const viewId = useAppSelector(selectViewId);

  const { state: { hidden: commandBarHidden } } = useCommandBar();
  const { dispatch: formulaRowDispatch } = useFormulaRow();

  const isDashboardView = location.pathname.includes('dashboard');
  const isReportView = location.pathname.includes('view');

  const leftPortalRef = useRef<HTMLDivElement | null>(null);

  const [ isLeftPanelOpen, setIsLeftPanelOpen ] = useState(false);

  const onMutation = useCallback((mutationsList: MutationRecord[]) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        setIsLeftPanelOpen(leftPortalRef.current?.children.length > 0);
      }
    }
  }, [ setIsLeftPanelOpen ]);

  useMutationObserver(leftPortalRef?.current, onMutation, OPTIONS);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    formulaRowDispatch({ type: 'CLOSE' });
    dispatch(appSlice.actions.setLeftPanel(null));
  }, [ location.pathname, formulaRowDispatch, dispatch ]);

  return (
    <div className={ clsx(styles.layout,
      { [ styles.stickyLayout ]: viewId != null, [ styles.dashboardView ]: isDashboardView }
    ) }>
      <div className={ styles.container }>
        <Navbar/>
        <div className={ styles.contentContainer }>
          <div className={ styles.layoutHeader } id='layout-header'>
            <TopBar />
          </div>

          <div className={ styles.layoutBody }>
            <ResizableContainer
              dimensionToResize='width'
              minSize={ 280 }
              maxSize={ 700 }
              linePlacement='right'
              defaultSize={ 280 }
              style={ { display: isLeftPanelOpen ? 'block' : 'none' } }
              className={ clsx( styles.asidePanel, styles.leftPanel) }
              resizeLineClassName={ styles.resizeLine }
            >
              <PanelCard>
                <div
                  ref={ leftPortalRef }
                  className={ styles.portal }
                  id={ isDashboardView ? DASHBOARD_PANEL_ID : LEFT_PANEL_PORTAL_ID } />
                  
              </PanelCard>
            </ResizableContainer>
            
            <div className={ clsx(styles.mainContent) }>
              <span id='inner-content'>
                <CommandBar />
                {
                  isReportView || isDashboardView ?
                    <FormulaRow /> : null
                }
              </span>
              { !isDashboardView && <div className={ styles.fakePadding }></div> }
              <DynamicScrollableContainer
                scrollContainerProps={ { id: 'scrollContainer' } }
                otherScrollRef={ scrollContainerRef }>
                <div
                  className={ clsx(styles.innerContent, {
                    [ styles.commandBarHidden ]: commandBarHidden,
                    [ styles.dashboardContent ]: isDashboardView
                  }) }
                >
                  { children }
                </div>
              </DynamicScrollableContainer>
            </div>
            <ChatPanel />
          </div>
        </div>

      </div>
      <ScrollUpButton scrollContainerRef={ scrollContainerRef }/>
    </div>
  );

};

const Wrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <FormulaRowContextProvider>
      <CommandBarContextProvider>
        <Layout>
          { children }
        </Layout>
      </CommandBarContextProvider>
    </FormulaRowContextProvider>
  );
};

export default Wrapper;
