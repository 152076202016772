import React, { RefObject, useState, useEffect } from 'react';
import { useScroll } from 'hooks/useScroll';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-circle.svg';

import styles from './ScrollDown.module.scss';
import clsx from 'clsx';

const useCanBeScrolled = (ref: RefObject<HTMLElement>) => {
  const [ canBeScrolled, setCanBeScrolled ] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      setCanBeScrolled(ref.current?.scrollHeight > ref.current?.clientHeight);
    });
      
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [ ref.current ]);

  return canBeScrolled;
};

interface ScrollDownButtonProps {
  scrollableRef: RefObject<HTMLElement>;
  distanceToScroll: number;
  className?: string;
}

const ScrollDownButton = ({
  scrollableRef,
  distanceToScroll,
  className = ''
}: ScrollDownButtonProps) => {
  const scrollDown = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: scrollableRef.current.scrollTop + distanceToScroll,
        behavior: 'smooth'
      });
    }
  };

  const scroll = useScroll(scrollableRef);

  if (scroll > 4) {
    return null;
  }

  return (
    <button
      type='button'
      className={ clsx(styles.scrollButton, className) }
      onClick={ scrollDown }
    >
      <ArrowDown />
    </button>
  );
};

interface Props {
  scrollableElementRef: RefObject<HTMLElement>;
  distanceToScroll?: number;
  className?: string;
}

const ScrollDown = ({ scrollableElementRef, distanceToScroll = 200, className = '' }: Props) => {
  const canBeScrolled = useCanBeScrolled(scrollableElementRef);

  if (!canBeScrolled) {
    return null;
  }

  return (
    <ScrollDownButton
      className={ className }
      scrollableRef={ scrollableElementRef }
      distanceToScroll={ distanceToScroll }
    />
  );
};

export default ScrollDown;
