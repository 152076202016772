import { Progress } from 'antd';
import { useRevenueRecognition } from 'context/RevenueRecognitionContext';
import React from 'react';
import styles from '../RevenueRecognition.module.scss';

const ProgressBar = () => {

  const {
    state: { completeCounter },
  } = useRevenueRecognition();

  return (
    <div className={ styles.progressWrapper }>
      <div className={ styles.progressInformation }>
        <span>{ `${ completeCounter.complete }/${ completeCounter.max }` }</span>
        <span className={ styles.percent }>
          { `${ Math.round( ( completeCounter.complete / completeCounter.max ) * 100) }%` }
        </span>
      </div>
      <Progress 
        className={ styles.progress } 
        strokeColor='#4CAF50' 
        percent={ ( completeCounter.complete / completeCounter.max ) * 100 } 
        showInfo={ false } 
      />
    </div> 
  );
};

export default ProgressBar;