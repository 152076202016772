import { ActionButtonOptions } from 'types/financials.types';

const getActionButtonName = (templateId: number, option: ActionButtonOptions) => {
  return `show_${ option }_${ templateId }`;
};

export const toggleActionOption = (templateId: number, option: ActionButtonOptions) => {
  const name = getActionButtonName(templateId, option);
  const value: string | null = localStorage.getItem(name);

  if (value === null) {
    localStorage.setItem(name, 'true');
  } else if (value === 'true') {
    localStorage.setItem(name, 'false');
  } else if (value === 'false')
    localStorage.setItem(name, 'true');
};

export const getValueFromStorage = (
  templateId: number,
  option: ActionButtonOptions,
  defaultValue = false
) => {
  const name = getActionButtonName(templateId, option);
  const value: string | null = localStorage.getItem(name);

  if (value === null) {
    localStorage.setItem(name, defaultValue ? 'true' : 'false');
    return defaultValue;
  } else if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  }
};
