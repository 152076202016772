import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ChartSettings } from 'types/chart.types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styles from './GhostBreakdown.module.scss';
import { useFinancialTable } from 'context/FinancialTableContext';
import { useAppSelector } from 'store/hooks/hooks';
import { getChartBreakdown, getDimensionViewName, } from 'utils/financials.utils';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import { UUID } from '../../../../../types/templates.types';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-rounded.svg';
import { selectPeriod } from 'store/financials.slice';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import { useChartContext } from 'components/charts/context/ChartContext';

interface Props {
  index: number;
}

const GhostBreakdown = ({ index }: Props) => {
  const { control } = useFormContext<ChartSettings>();
  const [ t ] = useTranslation('financials');
  const { state: { templateId } } = useFinancialTable();
  const period = useAppSelector(selectPeriod(templateId));
  const { state: { templateNodeUuids } } = useChartContext();
  const { data: report } = useReportQuery({ templateId, period, templateNodeUuids });
  const settings = useWatch({ control });
  const style = settings.datasets[ index ].style;
  const dataset = settings.datasets[ index ].templateNode;
  const breakdown = useMemo(() => {
    return getChartBreakdown(report, dataset as UUID);
  }, [ dataset, report ]);
  const breakdownDisplayName = useMemo(() => {
    if (!breakdown) return null;
    return getDimensionViewName(breakdown?.rowData);
  }, [ breakdown ]);

  if (!breakdown) {
    return null;
  }

  if (style === 'bar') {
    return <Controller
      control={ control }
      name={ `datasets.${ index }.style` }
      render={ ({ field }) => (
        <Tooltip
          title={ t('charts.ghost.add-breakdown') }
        >
          <button
            onClick={ () => field.onChange('stacked') }
            className={ `${ styles.ghost } ${ styles[ index ] } ${ styles.ghostAdd }` }
          >
            <PlusIcon/>
          </button>
        </Tooltip>
      ) }
    />;
  }

  return (
    <Controller
      control={ control }
      name={ `datasets.${ index }.style` }
      render={ ({ field }) => (
        <div className={ styles.buttonWrapper }>
          <div className={ `${ styles.ghost } ${ styles[ index ] }` }>
            { capitalize(t('charts.settings.style.by')) }
            { ' ' }
            { breakdownDisplayName }
          </div>
          <Tooltip
            title={ t('charts.ghost.remove-breakdown') }
          >
            <button
              className={ styles.closeIcon }
              onClick={ () => field.onChange('bar') }
            >
              <CloseIcon />
            </button>
          </Tooltip>
        </div>
      ) }
    />
  );
};

export default GhostBreakdown;
