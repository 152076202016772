import React, { MouseEvent, useRef, useMemo } from 'react';
import type { Dimension, DimensionFilter } from 'types/filterTable.types';
import { Tooltip } from 'antd';
import { noop } from 'lodash';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { dimensionToggleFavourite } from 'store/breakdowns.slice';
import { toggleAltKey } from 'store/events.slice';
import { useAppDispatch } from 'store/hooks/hooks';
import { setHoverDimension } from 'store/templates.slice';
import { EXTERNAL_BREAKDOWN_TYPES } from 'types/filterTable.types';
import { getDimensionDisplayName } from 'utils/financials.utils';
import { getUUID } from 'utils/templates.utils';
import { RowType } from 'types/financials.types';
import clsx from 'clsx';
import HighlightedText from 'components/elements/highlightedText/HighlightedText';
import DimensionViewLabel from '../../dimensionViewLabel/DimensionViewLabel';
import FavouriteStar from 'components/elements/favouriteStar/FavouriteStar';

import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/thick-close.svg';

import styles from './DimensionRow.module.scss';

interface DimensionRowProps {
  dimension: Dimension;
  setDimensionDetails: (value: number) => void;
  search?: string;
  isContractView?: boolean;
  isLabeling?: boolean;
  templateId?: number;
  isTemplateBuilder?: boolean;
  category: string;
  filters?: DimensionFilter;
  onClearFilters?: (event: MouseEvent, dimensionId?: number) => void;
}

const DimensionRow = ({
  dimension,
  setDimensionDetails,
  search,
  isContractView,
  isLabeling,
  isTemplateBuilder = false,
  category,
  onClearFilters,
  filters
}: DimensionRowProps) => {
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();

  const isFavouritesCategory = category === t('left-panel.favourites');

  const toggleFavourite = () => {
    dispatch(dimensionToggleFavourite({
      dimensionId: dimension.id, favorite: !dimension.favorite
    }));
  };

  const dropId = useRef(getUUID());

  const [ { isDragging }, drag ] = useDrag(() => {
    return {
      type: 'tag',
      item:  { uuid: dropId, id: dimension.id, rowData: dimension, type: RowType.BREAKDOWN },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    };
  });

  const onMouseLeaveDragTag = () => {
    dispatch(setHoverDimension(null));
  };

  const onMouseEnterDragTag = (event: React.MouseEvent) => {
    dispatch(toggleAltKey(event.altKey));

    if (!isDragging) {
      dispatch(setHoverDimension([ dimension?.id ]));
    } else if (isDragging)
      dispatch(setHoverDimension(null));
  };

  const filtersCount = useMemo(() => {
    if (!filters) return null;

    const excludedItems = filters.excludedItems.length +
      (filters.excludeUnassigned ? 1 : 0);
    
    const canBeUnassigned = dimension.canBeUnassigned ? 1 : 0 ||
      EXTERNAL_BREAKDOWN_TYPES.includes(dimension?.type);

    return (dimension.items.length + (canBeUnassigned ? 1 : 0)) - excludedItems;
  }, [ dimension, filters ]);

  const activeFilter = () => {
    if (!filters) return false;

    return filters.excludedItems.length > 0 || filters.excludeUnassigned;
  };

  return <button
    onMouseLeave={ isTemplateBuilder ? onMouseLeaveDragTag : noop }
    onMouseEnter={ isTemplateBuilder ? onMouseEnterDragTag : noop }
    ref={ isTemplateBuilder ? drag : null }
    type='button'
    onClick={ () => {
      setDimensionDetails(dimension.id);
    } }
    className={ clsx(styles.dimension, 'starContainer') }
    key={ dimension.id }
  >
    <div className={ styles.dimensionNameContainer }>
      <HighlightedText
        text={ getDimensionDisplayName(dimension) }
        highlight={ search }
        className={ clsx(styles.dimensionName) }
      />
      <DimensionViewLabel dimension={ dimension } />

      {
        !isContractView && activeFilter() && !isLabeling &&
        <Tooltip title={ t('left-panel.clear-filter') }>
          <span
            className={ clsx(
              styles.filterCount,
              { [ styles.countSizeM ]: filtersCount > 9 },
              { [ styles.countSizeL ]: filtersCount > 99 }
            ) }
            onClick={ (event) => onClearFilters(event, dimension.id) }>
            <span className={ styles.count }>
              { filtersCount >= 0 ? filtersCount : 0 }
            </span>
            <span className={ styles.clear }><CloseIcon /></span>
          </span>
        </Tooltip>
      }
      <FavouriteStar
        isFavourite={ dimension.favorite }
        onClick={ () => toggleFavourite() }
        hideStar={ !isFavouritesCategory }
      />
    </div>
    <Chevron className={ styles.arrow }/>
  </button>;
};

export default DimensionRow;
