import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ReportType } from 'types/templates.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { BudgetItem, BudgetItemType } from 'types/budget.types';
import { TransactionLineRequestParams } from 'services/statutory.service';
import dayjs from 'dayjs';
import { Period } from 'types/financials.types';
import { updateDetailedViewSettings } from 'store/financials.slice';
import { Cadence } from 'types/form.types';
import { InternalBudgetItem } from 'components/budget/budgetItemTable/hooks/useBudgetItems';

interface Props {
  fetchBudgetItems: (
    params: TransactionLineRequestParams[],
    period?: Period
  ) => Promise<BudgetItem[]>;
  fetchBudgetItemsByItemType: (budgetItemType: BudgetItemType) => Promise<BudgetItem[]>;
  setBudgetItems: React.Dispatch<React.SetStateAction<InternalBudgetItem[]>>;
}

const useDetailedView = ({
  fetchBudgetItems,
  fetchBudgetItemsByItemType,
  setBudgetItems,
}: Props) => {
  const dispatch = useAppDispatch();
  const {
    budgetItemType,
    params,
    filter,
    period,
    data
  } = useAppSelector(state => state.financials.detailedView);
  const isDataFetched = useRef(false);
  const isInitialFetch = useRef(true);
  const isBudgetItemsFetched = useMemo(() => data.budgetItems.length > 0, [ data.budgetItems ]);

  useEffect(() => {
    if (isInitialFetch.current) {
      isDataFetched.current = isBudgetItemsFetched;
      setBudgetItems(data.budgetItems);
    }
  }, [ isBudgetItemsFetched ]);

  const fetchFullRangeBudgetItems = useCallback(() => {
    fetchBudgetItems([ {} ], period);
  }, [ period, params ]);

  const fetchDetailedViewData = useCallback(async () => {
    if (period?.fullRange) {
      return fetchFullRangeBudgetItems();
    }
    if (!params) {
      return;
    }

    const requests = [];
    if (params[ ReportType.PLAN ].length > 0) {
      requests.push(fetchBudgetItems(params[ ReportType.PLAN ]));
    }
    await Promise.all(requests);
  }, [
    fetchBudgetItems,
    params,
    filter,
    period
  ]);

  useEffect(() => {
    if (isInitialFetch.current && isDataFetched.current) {
      isInitialFetch.current = false;
      return;
    }
    (async () => {
      await fetchDetailedViewData();
      isInitialFetch.current = false;
    })();
  }, [ params, filter, period ]);

  useEffect(() => {
    if (isInitialFetch.current && isDataFetched.current) {
      isInitialFetch.current = false;
      return;
    }
    if (budgetItemType != null) {
      (async () => {
        await fetchBudgetItemsByItemType(budgetItemType);
        isInitialFetch.current = false;
      })();
    }
  }, [ budgetItemType ]);

  useEffect(() => {
    if (isInitialFetch.current && isDataFetched.current) {
      isInitialFetch.current = false;
    }
  }, []);

  useEffect(() => {
    if (!period) {
      const defaultPeriod: Period = {
        actualsOpen: true,
        planOpen: false,
        startDate: dayjs().subtract(1, 'year').unix(),
        endDate: dayjs().unix(),
        startDatePlan: dayjs().subtract(1, 'year').unix(),
        endDatePlan: dayjs().unix(),
        cadence: Cadence.month,
        isManuallySet: false,
      };
      dispatch(updateDetailedViewSettings({ period: defaultPeriod }));
    }
  }, []);

  return null;
};

export default useDetailedView;
