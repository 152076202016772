import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Navigate, Outlet } from 'react-router-dom';
import { selectAuthentication } from 'store/auth.slice';
import { useAppSelector } from 'store/hooks/hooks';
import { routes } from 'utils/config.utils';
import WebsocketProvider from 'websockets/providers/WebsocketProvider';

const ProtectAuth = () => {
  const { user } = useAppSelector(selectAuthentication);

  if (!user) {
    return <Navigate to={ routes.login } />;
  }

  return <DndProvider backend={ HTML5Backend }>
    <WebsocketProvider>
      <Outlet />
    </WebsocketProvider>
  </DndProvider>;
};

export default ProtectAuth;
