import React, { useMemo } from 'react';
import { NotificationType } from 'types/app.types';
import styles from './InlineStatement.module.scss';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

interface Props {
  children: React.ReactNode;
  type: Extract<NotificationType, 'info' | 'warning' | 'error'>;
  className?: string;
}

const InlineStatement = ({ children, type, className }: Props) => {
  const icon = useMemo(() => {
    return {
      info: <InfoIcon />,
      warning: <WarningIcon />,
      error: <WarningIcon />,
    }[ type ];
  }, [ type ]);

  return <div className={ `${ styles.statement } ${ styles[ type ] } ${ className }` }>
    <div className={ styles.icon }>{ icon }</div>
    <div className={ styles.text }>{ children }</div>
  </div>;
};

export default InlineStatement;
