import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styles from '../ErrorBoundary.module.scss';

const ErrorBoundaryRenderer = ({ resetErrorBoundary }) => {
  return (
    <div className={ styles.container }>
      <div className={ styles.section }>
        <h1>Something went wrong</h1>
        <button onClick={ resetErrorBoundary }>Try again</button>
      </div>
    </div>

  );
};

const RootErrorBoundary = ({ children }) => {
  return <ErrorBoundary fallbackRender={ ErrorBoundaryRenderer }>
    { children }
  </ErrorBoundary>;
};

export default RootErrorBoundary;
