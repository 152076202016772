import React from 'react';
import {
  CommandBarDropdown as TCommandBarDropdown
} from 'components/commandBar/types/commandBar.types';
import CommandBarDropdown from
  'components/commandBar/components/common/dropdown/CommandBarDropdown';
import CommandBarButton from 'components/commandBar/components/button/CommandBarButton';

interface Props {
  button: TCommandBarDropdown;
}

const CommandBarDropdownButton = ({ button }: Props) => {
  return <CommandBarDropdown button={ button }>
    <CommandBarButton button={ button } />
  </CommandBarDropdown>;
};

export default CommandBarDropdownButton;