import React from 'react';
import CommandBarButton from 'components/commandBar/components/button/CommandBarButton';
import {
  CommandBarDefaultButton
} from 'components/commandBar/types/commandBar.types';

interface Props {
  button: CommandBarDefaultButton;
}

const CommandBarUtilButton = ({ button }: Props) => {
  return <CommandBarButton
    button={ button }
    disabled={ button.disabled || !button.onClick }
    onClick={ () => button.onClick && button.onClick() }
  >
    { button.icon }
  </CommandBarButton>;
};

export default CommandBarUtilButton;
