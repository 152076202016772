import React from 'react';
import { ButtonProps } from 'antd';
import Button, { Props as AppButtonProps } from 'components/elements/button/Button';
import styles from './GhostIconButton.module.scss';

interface Props extends AppButtonProps {
  active?: boolean;
  activeType?: 'filled' | 'outlined';
  withSeparator?: boolean;
}

const GhostIconButton = ({
  className = '',
  active = false,
  activeType = 'filled',
  withSeparator = false,
  ...props
}: ButtonProps & React.RefAttributes<HTMLElement> & Props) => {
  return <Button
    type='ghost'
    size='middle'
    className={ `
      ${ styles.button }
      ${ styles[ activeType ] }
      ${ withSeparator ? styles.withSeparator : '' }
      ${ active ? styles.active : '' }
      ${ className }
    ` }
    { ...props }
  >
    { props.children }
  </Button>;
};

export default GhostIconButton;
