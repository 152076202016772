import {
  budgetColors,
  budgetSingleBarColors,
  dataIndexMap,
  otherColors,
  primaryColors,
  secondaryColors,
  singleBarColors
} from 'components/charts/chart/colors';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartSettings } from 'types/chart.types';
import { ReportType } from 'types/templates.types';
import globalColors from 'utils/colors.utils';

interface Props {
  settings: ChartSettings;
}

interface GetColorProps {
  datasetIndex: number;
  keys: string[];
  key: string;
  reportType: ReportType;
  value: number;
  dataIndex?: number;
}

const useChartColors = ({ settings }: Props) => {
  const [ t ] = useTranslation('financials');

  const isSimpleBar = useCallback((datasetIndex: number) => {
    const isOtherActive = settings.datasets.some(
      (set, index) => index !== datasetIndex && set.active);
    const dataset = settings.datasets[ datasetIndex ];
    return (dataset.style === 'bar' || dataset.style.startsWith('line')) && !isOtherActive;
  }, [ settings ]);

  const getSimpleItemColor = useCallback((negative: boolean, reportType: ReportType) => {
    return negative ? globalColors[ 'red' ] : {
      [ ReportType.ACTUAL ]: globalColors[ 'indigoGray700' ],
      [ ReportType.PLAN ]: globalColors[ 'gray' ],
    }[ reportType ];
  }, [ settings ]);

  const isCustomColumn = useCallback((key: string) => {
    return [
      t('financials:charts.summed-rows'),
      'Unassigned'
    ].includes(key);
  }, []);

  const getColorIndex = useCallback((keys: string[], colorKey: string) => {
    let colorIndex = keys.indexOf(colorKey);
    const keysBeforeColorKey = keys.slice(0, colorIndex);

    for (const key of keysBeforeColorKey) {
      if (isCustomColumn(key)) {
        colorIndex--;
      }
    }
    return colorIndex;
  }, []);

  const getColor = useCallback(({
    keys,
    key,
    reportType,
    datasetIndex,
    value,
  }: GetColorProps) => {
    if (isSimpleBar(datasetIndex)) {
      const isValueReversed = settings.datasets[ datasetIndex ].reverseValues;
      return getSimpleItemColor(isValueReversed ? value > 0 : value < 0, reportType);
    }
    const index = getColorIndex(keys, key);

    const isSingleBar = 
      settings.datasets[ datasetIndex ].style === 'bar' ||
      settings.datasets[ datasetIndex ].style.startsWith('line');
    if (isSingleBar) {
      return reportType === ReportType.ACTUAL ?
        singleBarColors[ dataIndexMap[ datasetIndex ] ] :
        budgetSingleBarColors[ dataIndexMap[ datasetIndex ] ];
    }
    if (isCustomColumn(key)) {
      if (reportType === ReportType.ACTUAL) {
        if (datasetIndex === 0) {
          return otherColors[ 'primary' ][ key ];
        } else {
          return otherColors[ 'secondary' ][ key ];
        }
      } else {
        return otherColors[ 'budget' ][ key ];
      }
    }

    const typeColors =
      datasetIndex ===0 && reportType === ReportType.ACTUAL ? primaryColors :
        reportType === ReportType.PLAN ?
          budgetColors : secondaryColors;

    const keysLengthToColors = {
      2: [ 1, 8 ],
      3: [ 0, 4, 9 ],
      4: [ 0, 2, 4, 8 ],
      5: [ 0, 2, 4, 6, 8 ]
    };

    const colors = keysLengthToColors[ keys.length ];

    if (colors) {
      return typeColors.at(colors[ index ]);
    }
    
    if (typeColors.length <= index) return typeColors.at(-1);
    return typeColors.at(index);
  }, [ settings ]);

  return {
    getColor,
    isCustomColumn,
  };
};

export default useChartColors;
