import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterList } from '../types/filterTable.types';
import { AppDispatch, RootState } from './store';

export interface InputsStore {
  filters: FilterList;
}

const INPUTS_FILTERS_KEY = 'inputsFilters_';

const getInitialState = (): InputsStore => {
  return {
    filters: JSON.parse(localStorage.getItem(INPUTS_FILTERS_KEY))
  };

};

export const inputsSlice = createSlice({
  name: 'inputs',
  initialState: getInitialState(),
  reducers: {
    setFilters: (state, action: PayloadAction<FilterList>) => {
      state.filters = action.payload || [];
      localStorage.setItem(INPUTS_FILTERS_KEY, JSON.stringify(action.payload));
    },
    clearFilters: (state) => {
      state.filters = [];
    },
  }
});

export const clearDimensionFilter = (dimensionId: number) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const filters = getState().inputs.filters;
    const remainingFilters = filters.filter(
      filter => filter.dimension !== dimensionId
    );
    dispatch(inputsSlice.actions.setFilters(remainingFilters));
  };

export const inputsReducer = inputsSlice.reducer;
