import auth from './auth.json';
import common from './common.json';
import settings from './settings.json';
import accountMapping from './accountMapping.json';
import financials from './financials.json';
import revenueRecognition from './revenueRecognition.json';
import contracts from './contracts.json';
import navigation from './navigation.json';
import dynamicColumns from './dynamicColumns.json';
import planning from './planning.json';
import sorting from './sorting.json';
import tableWrapper from './tableWrapper.json';
import labeling from './labeling.json';
import dashboard from './dashboard.json';
import budget from './budget.json';
import inputs from './inputs.json';
import collaboration from './collaboration.json';

export default {
  auth,
  navigation,
  common,
  settings,
  accountMapping,
  financials,
  revenueRecognition,
  dynamicColumns,
  contracts,
  planning,
  labeling,
  sorting,
  tableWrapper,
  dashboard,
  budget,
  inputs,
  collaboration
};
