import { useCallback } from 'react';
import { isFormulaNode, TemplateNode } from 'types/templates.types';
import { RowType } from 'types/financials.types';
import { useAppSelector } from 'store/hooks/hooks';
import { isTemplateNodeFromCanvas } from '../utils/formulaBuilder.utils';
import { selectTemplate } from 'store/template.slice';

const useNodeToTag = () => {
  const { financialTags, dimensions } = useAppSelector(state => state.templates);
  const template = useAppSelector(selectTemplate);

  /** Allows for mapping node from template to correct tag as if it would be from formula builder */
  const getTemplateTagFromNode = useCallback((node: TemplateNode): TemplateNode => {
    if (!isTemplateNodeFromCanvas(node) || isFormulaNode(node)) {
      return node;
    }
    if (node.type === RowType.FINANCIALS) {
      return {
        ...financialTags.nodes[ node.rowData.id ],
        childrenNodes: getChildrenTemplateTags(node),
      };
    }
    if (node.type === RowType.DIMENSION_ITEM) {
      const dimension = dimensions.nodes[ node.rowData.dimensionId ];
      const dimensionItemNode = dimension.childrenNodes.find((el) => el.id === node.rowData.id);
      if (node.rowData.id === null) {
        // In template builder unassigned nodes are represented as dimension items with null id.
        // We are converting it back to unassigned node.
        return {
          ...dimension,
          type: RowType.UNASSIGNED,
        };
      }
      if (dimensionItemNode) {
        return {
          ...dimensionItemNode,
          childrenNodes: getChildrenTemplateTags(node),
        };
      }
    }
    return null;
  }, [ dimensions, financialTags ]);

  const getChildrenTemplateTags = useCallback((node: TemplateNode) => {
    return node.children.map((child) => {
      const childNode = template.nodes[ child ];
      return getTemplateTagFromNode(childNode);
    }).filter(n => n);
  }, [ template, getTemplateTagFromNode ]);

  return {
    getTemplateTagFromNode
  };
};

export default useNodeToTag;
