import Button from 'components/elements/button/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SelectButton.module.scss';

type Props = {
  hide?: boolean;
  showSelectAll: boolean;
  onClick: () => void;
  className?: string;
};

const SelectButton = ({ hide = false, showSelectAll, onClick, className = '' }: Props) => {
  const [ t ] = useTranslation('financials');

  return (
    <Button
      type='text'
      className={ `${ styles.selectAll } ${ hide ? styles.selectAllHidden : '' } ${ className }` }
      onClick={ onClick }
    >
      { showSelectAll ? t('left-panel.select-all') : t('left-panel.unselect-all') }
    </Button>
  );
};

export default SelectButton;
