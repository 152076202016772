import React from 'react';
import RangePickerModal from 'components/elements/rangePicker/RangePickerModal';
import styles from './TopBar.module.scss';
import SelectSort from '../selectSort/SelectSort';
import { useLocation } from 'react-router-dom';
import SelectFilter from '../selectFilter/SelectFilter';
import FilterIndicator from '../filterIndicator/FilterIndicator';
import { revenueRecognitionSlice } from '../../../store/revenueRecognition.slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { Cadence } from 'types/form.types';
import { useFinancialTable } from 'context/FinancialTableContext';

type Props = {
  header: string;
  description: string;
  className?: string;
};

const TopBar = ({ header, description, className }: Props) => {
  const location = useLocation();
  const period = useAppSelector(state => state.revenueRecognition.table.period);
  const showSort = location.pathname === '/manage/revenue-recognition/';
  const dispatch = useAppDispatch();
  const { state: { templateId } } = useFinancialTable();

  return (
    <div className={ `${ styles.top } ${ className ?? '' }` }>
      <div className={ styles.leftSection }>
        <h2 className={ styles.title }>{ header }</h2>
        <span className={ styles.text }>{ description }</span>
      </div>
      <div className={ styles.rightSection }>
        <FilterIndicator/>
        {
          showSort && (
            <SelectSort/>
          )
        }
        <RangePickerModal
          cadence={ Cadence.month }
          defaultPeriod={ period }
          actualsIsOpen={ true }
          onSubmit={ (_period) => {
            dispatch(revenueRecognitionSlice.actions.setPeriod(_period));
          } }
          templateId={ templateId }
        />
        <SelectFilter/>
      </div>
    </div>
  );
};

export default TopBar;
