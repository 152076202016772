import { ReportSource } from './financials.types';

export enum CounterpartyType {
  SUPPLIER = 'SUPPLIER',
  EMPLOYEE = 'EMPLOYEE',
  CUSTOMER = 'CUSTOMER'
}

export type Customer = {
  address: Address;
  businessRegNumber: string;
  id: number;
  name: string;
  pictureUrl: string;
  type: CounterpartyType;
  url: string;
  ttmRevenue: number;
  source: ReportSource;
  contractStatus: string;
};

export type Address = {
  city: string;
  country: string;
  street: string;
  zip: string;
};

export type Product = {
  id: number;
  name: string;
};

export enum RowType {
  INVOICE = 'INVOICE',
  PRODUCT = 'PRODUCT'
}

export enum DealType {
  NEW = 'NEW',
  RENEWAL = 'RENEWAL',
  UPSELL = 'UPSELL',
  REACTIVATION = 'REACTIVATION',
  DOWNGRADE = 'DOWNGRADE',
}

export enum FilterTypeCustomer{
  ALL,
  RESOLVED,
  UNRESOLVED,
}

export enum FilterTypeProduct {
  ALL,
  UNRECOGNIZED,
  RECOGNIZED_USER,
  RECOGNIZED_SYSTEM,
}

export interface FilterItem {
  name: string;
  index: number;
  isFilter: boolean;
}

export interface CompleteCounter {
  complete: number;
  max: number;
}

export type SortMenuItem = {
  field: 'name' | 'totalRevenue';
  direction: 'asc' | 'desc';
  title: string;
};
