export type ContextMenuItem = {
  name: string;
  // eslint-disable-next-line no-undef
  icon?: JSX.Element;
  // eslint-disable-next-line no-undef
  render?: () => JSX.Element;
  action?: () => void;
  subMenu?: ContextMenuItem[];
  active?: boolean;
  disabled?: boolean;
  suppressHide?: boolean;
};

export enum ContextMenuType {
  FORMULA_ITEM,
  CAPSULE,
}