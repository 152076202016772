import { MutableRefObject, useCallback, useEffect } from 'react';

interface Args {
  ref?: MutableRefObject<HTMLElement | Document>;
  cb: (event: KeyboardEvent) => void;
  enabled?: boolean;
} 

interface ArgsCode extends Args {
  code: KeyboardEvent['code'];
  key?: never;
}

interface ArgsKey extends Args {
  code?: never;
  key: KeyboardEvent['key'];
}

const useKeyPressListener = ({ code, key, ref, cb, enabled = true }: ArgsCode | ArgsKey) => {
  const _ref = ref ?? { current: document };

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (key && event.key === key && _ref?.current?.contains(event.target as Node)) {
      event.preventDefault();
      cb(event);
    }
    if (event.code === code && _ref?.current?.contains(event.target as Node)) {
      event.preventDefault();
      cb(event);
    }

    return undefined;
  }, [ cb, code, key ]);

  useEffect(() => {
    if (enabled) {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [ enabled, onKeyDown ]);
};

export default useKeyPressListener;
