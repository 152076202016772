import { useEffect, useState } from 'react';

const DEFAULT_OPTIONS = {
  attributes: true,
  childList: true,
  subtree: true,
};
function useMutationObserver(
  targetEl: Element | null,
  cb: (mutationsList: MutationRecord[]) => void,
  options: Partial<typeof DEFAULT_OPTIONS> = DEFAULT_OPTIONS
) {
  const [ observer, setObserver ] = useState<MutationObserver>(null);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [ cb, options, setObserver ]);

  useEffect(() => {
    if (!observer || !targetEl) return;
    observer.observe(targetEl, options);
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [ observer, targetEl, options ]);
}

export default useMutationObserver;
