/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line max-len
// sauce: https://github.com/uidotdev/usehooks/blob/dfa6623fcc2dcad3b466def4e0495b3f38af962b/index.js#L616
import { useSyncExternalStore, useCallback, useEffect, useState } from 'react';

function dispatchStorageEvent(key: string, newValue: string) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
}

const setLocalStorageItem = <T = any>(key: string, value: T) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

const getLocalStorageItem = (key: string) => {
  return window.localStorage.getItem(key);
};

const useLocalStorageSubscribe = (callback: (e: StorageEvent) => void) => {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
};

const getLocalStorageServerSnapshot = () => {
  throw Error('useLocalStorage is a client-only hook');
};

export function useLocalStorage<T = any>(key: string, initialValue?: T) {
  const getSnapshot = () => getLocalStorageItem(key);

  const deserializer = useCallback<(value: string) => T>(
    value => {
      // Support 'undefined' as a value
      if (value === 'undefined') {
        return undefined as unknown as T;
      }

      const defaultValue =
        initialValue instanceof Function ? initialValue() : initialValue;

      let parsed: unknown;
      try {
        parsed = JSON.parse(value);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return defaultValue; // Return initialValue if parsing fails
      }

      return parsed as T;
    },
  [ initialValue ],
  );

  const readValue = useCallback((): T => {
    const initialValueToUse =
      initialValue instanceof Function ? initialValue() : initialValue;

    try {
      const raw = window.localStorage.getItem(key);
      return raw ? deserializer(raw) : initialValueToUse;
    } catch (error) {
      return initialValueToUse;
    }
  }, [ initialValue, key, deserializer ]);

  const [ storedValue, setStoredValue ] = useState<T>(() => {
    if (initialValue != null) {
      return readValue();
    }
    return initialValue instanceof Function ? initialValue() : initialValue;
  });

  const store = useSyncExternalStore(
    useLocalStorageSubscribe,
    getSnapshot,
    getLocalStorageServerSnapshot
  );

  const setState = useCallback(
    (v: T) => {
      try {
        const nextState = typeof v === 'function' ? v(JSON.parse(store)) : v;

        if (nextState === undefined || nextState === null) {
          removeLocalStorageItem(key);
        } else {
          setLocalStorageItem(key, nextState);
        }
        setStoredValue(nextState);
      } catch (e) {
        console.error(e);
      }
    },
    [ key, store ]
  );

  useEffect(() => {
    setStoredValue(readValue());
  }, [ store ]);

  useEffect(() => {
    if (
      getLocalStorageItem(key) === null &&
      typeof initialValue !== 'undefined'
    ) {
      setLocalStorageItem(key, initialValue);
    }

    setStoredValue(readValue());
  }, [ key ]);

  return [ storedValue, setState ] as const;
}
