import React from 'react';
import MessageBubble from
  'components/collaboration/chat/messages/history/message/messageBubble/MessageBubble';
import { Message } from 'types/chat.types';
import styles from './LoaderMessage.module.scss';

interface Props {
  message: Message;
}

const LoaderMessage = ({ message }: Props) => {
  return <MessageBubble message={ message }>
    <div className={ styles.wrapper }>
      <div className={ styles.loader } />
    </div>
  </MessageBubble>;
};

export default LoaderMessage;