import { inRange } from 'lodash';
import { Cadence } from 'types/form.types';
import { InputDataType } from '../types/inputs.types';
import * as yup from 'yup';
import { InputRowWithValues } from 'components/inputs/types/inputs.types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { t } from 'i18next';
dayjs.extend(timezone);

export const INPUT_TEMPLATE_ID = -999;
export const INPUT_NONE_GROUP_ID = -1;
export const INPUT_NONE_GROUP_NAME = t('common:none');

const valueSchema = yup.string().required()
  .test({
    test: function(value) {
      return !isNaN(+value);
    },
    message: 'Value must be a number',
  })
  .test({
    test: function(value) {
      if (this.from.at(-1).value.dataType === InputDataType.DATE) {
        return inRange(+value, 1, 32);
      }
      return true;
    },
    message: 'Date value must be a day of the month',
  })
  .test({
    test: function(value) {
      const offsetDataTypes = [
        InputDataType.DATE_OFFSET_DAYS,
        InputDataType.DATE_OFFSET_MONTHS,
      ];
      if (offsetDataTypes.includes(this.from.at(-1).value.dataType)) {
        return +value >= -500 && +value <= 500;
      }
      return true;
    },
    message: 'Date offset value must be a positive number',
  })
  .test({
    test: function(value) {
      const integerDataTypes = [
        InputDataType.INTEGER,
        InputDataType.DATE,
        InputDataType.DATE_OFFSET_DAYS,
        InputDataType.DATE_OFFSET_MONTHS,
      ];
      if (integerDataTypes.includes(this.from.at(-1).value.dataType)) {
        return +value % 1 === 0;
      }
      return true;
    },
    message: 'Value must be an integer',
  });

const valuesSchema = yup.array(
  yup.object().shape({
    period: yup.string().required().test({
      test: function(value) {
        return dayjs(value).isValid();
      },
      message: 'Date must have valid format',
    }),
    value: valueSchema,
  }));

const inputValidationSchema = yup.object().shape({
  name: yup.object().shape({
    en: yup.string().required(),
    fi: yup.string().optional().nullable(),
    sv: yup.string().optional().nullable(),
  }),
  category: yup.number().min(1).required(),
  cadence: yup.string().oneOf(Object.values(Cadence)).optional(),
  dataType: yup.string().oneOf(Object.values(InputDataType)).required(),
  defaultValue: valueSchema,
  actualValues: valuesSchema,
  planValues: valuesSchema,
});

export const isInputValid = (input: InputRowWithValues): boolean => {
  inputValidationSchema.isValid(input).catch((error) => {
    console.error(error);
  });
  return inputValidationSchema.isValidSync(input);
};
