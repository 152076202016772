import generatePicker from 'antd/lib/date-picker/generatePicker';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useRef, useState } from 'react';
import { Popover } from 'antd';
import enGB from 'antd/lib/locale/en_GB';
import 'dayjs/locale/en-gb';
import { Control, Controller } from 'react-hook-form';
import { PlanSettings } from 'types/planning.types';
import styles from './InputCalendar.module.scss';

interface Props{
  valueDate: Dayjs;
  className?: string;
  control: Control<PlanSettings>;
}

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const InputCalendar = ({ valueDate, className='', control }: Props) => {
  const [ visibleCalendar, setVisibleCalendar ] = useState(false);

  const ref = useRef();

  const handleVisibilityChange = () => {
    setVisibleCalendar(!visibleCalendar);
  };

  return (
    <Popover
      open={ visibleCalendar }
      trigger='click'
      placement='bottomLeft'
      onOpenChange={ handleVisibilityChange }
      content={
        <div ref={ ref }>
          <Controller
            control={ control }
            name='general.planStart.value'
            render={ ({ field }) => {
              return (
                <DatePicker
                  locale={ enGB.DatePicker }
                  getPopupContainer={ () => ref.current }
                  picker='month'
                  open={ true }
                  bordered={ false }
                  onChange={ (date) => {
                    field.onChange(dayjs(date));
                    handleVisibilityChange();
                  } }
                  size='small'
                  value={ dayjs(field.value) as Dayjs }
                  className={ styles.picker }
                />
              );
            } }
          />
        </div>
   
      }
    >
      <span 
        className={ className }
      >
        { valueDate.format('MMM YYYY') }
      </span>
    </Popover>
  );
};

export default InputCalendar;