import {
  CustomFormula,
  FormulaItem,
  FormulaItemType,
  isBreakdownNode,
  isCounterpartyTypeNode,
  isDimensionItemNode,
  isFinancialsNode,
  isTotalNode,
  Template,
  TemplateNode,
  TemplateNodeMapping,
  TemplateNodeType,
} from 'types/templates.types';
import { cloneDeep } from 'lodash';
import { RowType } from 'types/financials.types';
import { createDisplayName, getDisplayName } from './common.utils';
import { Dimension, DimensionItem } from 'types/filterTable.types';
import { getUUID } from './templates.utils';
import { getDimensionViewName, getUnassignedNodeName } from 'utils/financials.utils';
import { t } from 'i18next';

const lastTemplateKey = 'last__template';

const getTemplateKey = () => {
  return `${ lastTemplateKey }`;
};

export const setLastTemplateId = (id: number) => {
  localStorage.setItem(getTemplateKey(), String(id));
};

export const removeLastTemplateId = () => {
  localStorage.removeItem(getTemplateKey());
};

export const getLastTemplateId = () => {
  return localStorage.getItem(getTemplateKey());
};

export const isSpacer = (type: RowType) => type === RowType.SPACER;

export const isTitleRow = (type: RowType) => type === RowType.TITLE;

export const isGroupType = (node: TemplateNode) =>
  isGroup(node) || isBreakdownNode(node) || isDimensionItemNode(node);

export const isGroup = (node: TemplateNode) => {
  return node?.type === RowType.GROUP;
};

export const isSumUpRow = (node: TemplateNode) => {
  return node?.type === RowType.SUM_UP;
};

export const getBreakdownName = (
  rowData: Dimension | DimensionItem,
  data: Dimension | DimensionItem
) => {
  if (data) {
    return getDimensionViewName(data);
  }
  if (rowData) {
    return getDimensionViewName(rowData);
  }
  return '';
};

export const getNodeName = (node: TemplateNode, dimensions: Dimension[]) => {
  if (isCounterpartyTypeNode(node)) {
    return getDisplayName(node.rowData.name);
  }
  if (isBreakdownNode(node)) {
    const dimensionNode = dimensions.find((d) => d.id === node.rowData?.id);
    return getBreakdownName(node.rowData, dimensionNode);
  }
  if (isDimensionItemNode(node)) {
    const dimensionNode = dimensions.find((d) => d.id === node.rowData?.dimensionId);
    if (!node.rowData.id) {
      return getUnassignedNodeName(dimensionNode);
    }
    const child = dimensionNode?.items.find((c) => c.id === node.rowData?.id);
    return getBreakdownName(node.rowData, child);
  }
  return getDisplayName(node.rowData.name) || '';
};

export const isFinancialType = (node: TemplateNode) =>
  node?.type === RowType.FINANCIALS;

export const isDimensionType = (node: TemplateNode) => {
  return node?.type === RowType.BREAKDOWN || node?.type === RowType.COUNTERPARTY_TYPE;
};

export const isDimensionItem = (node: TemplateNode) =>
  node?.type === RowType.DIMENSION_ITEM;

export const isDimensionGroup = (tag: TemplateNode) =>
  tag?.type === RowType.BREAKDOWN_GROUP;

export const createDimensionItemNode =
  (dimension: DimensionItem): TemplateNodeType<RowType.DIMENSION_ITEM> => ({
    id: dimension.id,
    uuid: getUUID(),
    rowData: {
      ...dimension,
    },
    children: [],
    childrenNodes: [],
    type: RowType.DIMENSION_ITEM,
  });

/**
 * This is used to create a node for unassigned dimension item. Unassigned is stored as a separate
 * type in backend, but for our use case it behaves as a dimension item.
 */
export const createUnassignedDimensionItem =
  (dimension: Dimension): DimensionItem => ({
    id: null,
    customName: t('common:unassigned'),
    name: createDisplayName(t('common:unassigned')),
    dimensionId: dimension.id,
    group: null,
    relation: null,
    account: null,
    counterparty: null,
    product: null,
    contract: null,
  });

export const compareNodes = (a: TemplateNode, b: TemplateNode) => {
  if (a?.rowData[ 'id' ] || b?.rowData[ 'id' ]) {
    return a?.rowData[ 'id' ] === b?.rowData[ 'id' ];
  }
  return getDisplayName(a?.rowData?.name) === getDisplayName(b?.rowData?.name);
};

export const canBeDroppedBetween = (node: TemplateNode) => {
  return !isTotalNode(node);
};

export const canBeDroppedOnto = (node: TemplateNode) => {
  const excludedTypes = [ RowType.TITLE ];
  return !(excludedTypes.includes(node?.type) || isTotalNode(node));
};

export const isLastChild = (template: Template, node: TemplateNode) => {
  return !node?.parent ? false :
    template.nodes[ node.parent ].children.at(-1) === node.id;
};

export const isLastChildShallow = (template: Template, node: TemplateNode) => {
  const parent = template.nodes[ node?.parent ];
  if (!parent) {
    return true;
  }
  return parent?.children.at(-1) === node.id;
};

export const isLastChildInBranch = (template: Template, node: TemplateNode) => {
  const parent = template.nodes[ node?.parent ];
  if (!parent) {
    return true;
  }
  if (parent?.children.at(-1) !== node.id) {
    return false;
  }
  return isLastChildInBranch(template, parent);
};

export const templateHelpers = (
  template: Template,
  financialNodes: TemplateNodeMapping<RowType.FINANCIALS>,
  dimensionNodes: TemplateNodeMapping<RowType.BREAKDOWN>,
) => {

  const isAncestor = (
    firstItem: TemplateNodeType<RowType.FINANCIALS>,
    secondItem: TemplateNodeType<RowType.FINANCIALS>,
  ) => {
    return firstItem?.rowData.nodesPath?.some(
      id => secondItem.rowData.nodesPath?.includes(id),
    );
  };

  const getAllNodesFromBranch = (nodeId: number | string, nodes: TemplateNode[]) => {
    const node = template.nodes[ nodeId ];
    if (node) {
      nodes.push(node);
      for (const child of node.children) {
        getAllNodesFromBranch(template.nodes[ child ]?.id, nodes);
      }
    }
    return nodes;
  };

  const getLastAncestorInTemplate = (ancestorIds: (number | string)[], nodeId: number | string) => {
    const branchNodes = getAllNodesFromBranch(nodeId, []);
    for (const ancestor of ancestorIds.reverse()) {
      for (const child of branchNodes.reverse()) {
        if (isFinancialsNode(child) && child?.rowData?.id === ancestor) {
          if (child?.rowData?.id === ancestor) {
            return ancestor;
          }
          return ancestor;
        }
      }
    }

    return null;
  };

  const getCommonParent = (
    firstItem: TemplateNodeType<RowType.FINANCIALS>,
    secondItem: TemplateNodeType<RowType.FINANCIALS>,
    rootId: number | string
  ) => {
    const firstItemParents = firstItem?.rowData.nodesPath?.slice(0, -1).map(n => +n);
    const secondItemAncestors = cloneDeep(secondItem?.rowData.nodesPath);
    const rootParentTag = financialNodes[ secondItemAncestors.at(0) ];
    const ancestors = [ rootParentTag.id, ...rootParentTag.children ];
    const commonParents = firstItemParents?.filter((parent) => ancestors?.includes(parent));

    return getLastAncestorInTemplate(commonParents, rootId);
  };

  const getGreatParent = (node: TemplateNode): TemplateNode => {
    if (!node?.parent) {
      return node;
    }
    return getGreatParent(template.nodes[ node.parent ]);
  };

  const getCommonParentNode = (
    nodeId: number | string,
    commonParentId: number | string
  ): TemplateNode => {
    const node = template.nodes[ nodeId ];
    if (isFinancialsNode(node)) {
      if (node?.rowData?.id === commonParentId) {
        return node;
      }
    }
    if (!node.children || node.children.length === 0) {
      return null;
    }
    for (const childId of node.children) {
      const commonParentNode = getCommonParentNode(childId, commonParentId);
      if (commonParentNode) {
        return commonParentNode;
      }
    }
    return null;
  };

  const isDimensionChild = (
    dimensionNode: TemplateNodeType<RowType.BREAKDOWN>,
    child: TemplateNodeType<RowType.DIMENSION_ITEM>) => {
    const node = Object.values(dimensionNodes).find(
      (n) => n.id === dimensionNode?.rowData?.id,
    );
    if (!node || !child) {
      return false;
    }
    return node.id === child.rowData.dimensionId && node.type === RowType.BREAKDOWN;
  };

  const areDimensionsSibling = (
    dimensionNode: TemplateNodeType<RowType.DIMENSION_ITEM>,
    dimensionTag: TemplateNodeType<RowType.DIMENSION_ITEM>
  ) => {
    return dimensionNode.rowData.dimensionId === dimensionTag.rowData.dimensionId;
  };
  const checkDimensionItemSiblingRelation = (
    node: TemplateNode,
    dimensionTag: TemplateNodeType<RowType.DIMENSION_ITEM>,
    areSiblings = true,
  ) => {
    if (!node?.children) {
      return false;
    }
    for (const child of node.children) {
      const dimensionChildNode = template.nodes[ child ];
      if (isDimensionItemNode(dimensionChildNode)) {
        const compareResult = areSiblings ?
          areDimensionsSibling(dimensionChildNode, dimensionTag) :
          !areDimensionsSibling(dimensionChildNode, dimensionTag);
        if (compareResult) {
          return true;
        }
      }
    }

    return false;
  };

  const isDroppingOnSameBranch = (droppedNode: TemplateNode, overNode: TemplateNode): boolean => {
    if (droppedNode?.id === overNode?.id) {
      return true;
    }
    if (!overNode?.parent || !isFinancialType(droppedNode)) {
      return false;
    }
    return isDroppingOnSameBranch(droppedNode, template.nodes[ overNode.parent ]);
  };

  return {
    isAncestor,
    getCommonParent,
    getCommonParentNode,
    getGreatParent,
    checkDimensionItemSiblingRelation,
    isDimensionChild,
    areDimensionsSibling,
    isDroppingOnSameBranch
  };
};

export const getTemplateTagFormulaType = (node: TemplateNode) => {
  if (isDimensionItemNode(node)) {
    return FormulaItemType.DIMENSION_ITEM;
  }

  return FormulaItemType.STATUTORY;
};

export const getFormulaItemTypeFromNode = (node: TemplateNode) => {
  if (isDimensionItemNode(node)) {
    return FormulaItemType.DIMENSION_ITEM;
  }
  if (node.type === RowType.FORMULA) {
    return FormulaItemType.FORMULA;
  }
  return FormulaItemType.STATUTORY;
};

export const isFormulaItemNumberProvider = (type: FormulaItemType) => {
  const numberProviderTypes = [
    FormulaItemType.DIMENSION_ITEM,
    FormulaItemType.STATUTORY,
    FormulaItemType.FORMULA,
  ];
  return numberProviderTypes.includes(type);
};

export const mapFormulaToNode = (formula: FormulaItem[]): CustomFormula[] => {
  return formula.map((el) => {
    switch (el.type) {
      case FormulaItemType.TEMPLATE:
        return {
          type: el.type,
          templateNode: el.id,
          statutoryRow: undefined,
          offset: el.offset,
        };
      case FormulaItemType.STATUTORY:
        return {
          type: el.type,
          statutoryRow: el.id,
          templateNode: undefined,
          value: undefined,
          offset: el.offset,
        };
      case FormulaItemType.OPERATOR:
      case FormulaItemType.BRACKET:
      case FormulaItemType.LOGICAL_OPERATOR:
        return {
          type: FormulaItemType.OPERATOR,
          value: el.value,
          templateNode: undefined,
          statutoryRow: undefined,
        };
      case FormulaItemType.CONSTANT:
        return {
          type: el.type,
          value: el.value,
          templateNode: undefined,
          statutoryRow: undefined,
        };
      case FormulaItemType.DIMENSION_ITEM:
        return {
          type: el.type,
          value: undefined,
          dimensionItem: el.id,
          offset: el.offset,
        };
      case FormulaItemType.UNASSIGNED:
        return {
          type: el.type,
          value: undefined,
          dimension: el.id,
          offset: el.offset,
        };
      case FormulaItemType.FORMULA:
        return {
          type: FormulaItemType.FORMULA,
          value: undefined,
          nestedFormula: el.id,
          rollingAverage: el.rollingAverage,
        };
      default:
        throw new Error('Unknown formula element type');
    }
  });
};
export const isNewTemplate = (template: Template) => {
  if (!template) return false;

  return template.id === null && template.title !== '';
};
