import React, { useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  type UseFormSetValue,
  useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input, InputNumber } from 'antd';
import clsx from 'clsx';

import type { FormType } from '../dimensionSplit.types';

import Button from 'components/elements/button/Button';
import SearchableSelect from 'components/elements/searchableSelect/SearchableSelect';
import { useAppSelector } from 'store/hooks/hooks';
import { getDisplayName } from 'utils/common.utils';
import { CURRENCY_SYMBOL, emptyDimension, emptyLabel } from '../split.utils';

import { ReactComponent as AddIcon } from 'assets/icons/plus.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/close.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle-thick.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import { sumLabels } from '../validation';

import DimensionSearchableSelect from './dimensionSearchableSelect/DimensionSearchableSelect';

import styles from './DimensionSplit.module.scss';
import Tooltip from 'components/elements/tooltip/Tooltip';

interface Props {
  index: number;
  control: Control<FormType>;
  errors: FieldErrors<FormType>;
  nominalValue?: number | null;
  isDraft?: boolean;
  getValues: () => FormType;
  reset: (value: Partial<FormType>) => void;
  removeDimension: (index: number) => void;
  setValue: UseFormSetValue<FormType>;
}

const DimensionSplit = ({
  index,
  control,
  errors,
  nominalValue,
  isDraft,
  removeDimension,
  getValues,
  reset,
  setValue
}: Props) => {
  const [ t ] = useTranslation('financials');

  const dimensions = useAppSelector(state => state.breakdowns.dimensions);

  const selectedDimension = useWatch({
    control,
    name: `dimensions.${ index }.dimension`,
  });

  const labelValues = useWatch({
    control,
    name: `dimensions.${ index }.labels`,
  });

  const onDimensionChange = (dimensionId: number) => {
    const prevValue = getValues();

    if (
      dimensionId &&
      prevValue.dimensions[ index ].dimension !== dimensionId &&
      prevValue.dimensions[ index ].labels &&
      prevValue.dimensions[ index ].labels.some(item => item.dimensionItemId)
    ) {

      const newEmptyDimension = {
        ...emptyDimension,
        dimension: dimensionId
      };

      prevValue.dimensions[ index ] = newEmptyDimension;

      reset({ ...prevValue });
    }
  };

  const labelsValue = useWatch({
    control,
    name: `dimensions.${ index }.labels`
  });

  const percentageSum = useMemo(() => sumLabels(labelsValue), [ labelsValue ]);
  const nominalValueSum = useMemo(() => {
    if (!nominalValue) return 0;
    return (sumLabels(labelsValue) / 100) * nominalValue ?? 0;
  }, [ labelsValue, nominalValue ]);

  const labelsOptions = useMemo(() => {
    if (!selectedDimension) {
      return [];
    }

    const dimension = dimensions.find(item => item.id === selectedDimension);
    if (!dimension) {
      return [];
    }

    return dimension.items.map(item => ({
      label: getDisplayName(item.customName),
      value: item.id
    }));
  }, [ selectedDimension, dimensions ]);

  const { fields, append, remove } = useFieldArray({
    name: `dimensions.${ index }.labels`,
    control
  });

  const addLabel = () => {
    append({ ...emptyLabel }, { shouldFocus: true });

    setTimeout(() => {
      const lastLabel = document.getElementById(
        `dimensions_${ index }_labels_${ fields.length }_dimensionItemId`
      );

      lastLabel?.focus();
    }, 100);
  };

  const canUnassign = (poz: number) => {
    const formPart = getValues().dimensions[ poz ];
    const dimension = formPart.dimension;

    if (!dimension) return true;

    return dimensions.find(item => item.id === dimension)?.canBeUnassigned;
  };

  return <>
    <div className={ styles.dimensionSection }>
      <div className={ styles.formField }>
        <label htmlFor={ 'dimension_' + index } className={ styles.label }>
          { t('dimension-split.modal.dimension') }
        </label>

        <div className={ styles.dimensionRow } id={ 'dimension_' + index + '_cont' }>
          <Controller
            control={ control }
            name={ `dimensions.${ index }.dimension` }
            render={ ({ field: { value, onChange } } ) => 
              <DimensionSearchableSelect
                value={ value as number }
                dimensions={ dimensions }
                autoClearSearchValue
                onChange={ (e) => {
                  onDimensionChange(e as unknown as number);
                  onChange(e);
                } }
                index={ index }
              />
            }
          />
          <Tooltip title={
            !isDraft && !canUnassign(index) ? 
              t('dimension-split.modal.cannot-unassign-warning') : ''
          }
          mouseEnterDelay={ .3 }>
            <Button
              className={ styles.removeButton }
              type='link'
              disabled={ !isDraft && !canUnassign(index) }
              tabIndex={ -1 }
              onClick={ () => removeDimension(index) }
            >
              <RemoveIcon />
            </Button>
          </Tooltip>
        </div>
      </div>

      { 
        selectedDimension ? <>
          <label className={ clsx(styles.label, styles.labelsLabel ) }>
            { t('dimension-split.modal.labels') }
          </label>

          {
            fields.map((field, labelIndex) => {
              return <div key={ field.id }>
                <div className={ styles.dimensionLabelRow }>
                  <div id={ 'dimension_' + index + '_labels_' + labelIndex + '_cont' }>
                    <Controller
                      control={ control }
                      name={ `dimensions.${ index }.labels.${ labelIndex }.dimensionItemId` }
                      render={ ({ field: { value, onChange } } ) => <SearchableSelect
                        labelInValue={ true }
                        id={ 'dimensions_' + index + '_labels_' + labelIndex + '_dimensionItemId' }
                        className={
                          clsx(styles.searchableSelect, { [ styles.withNominal ]: !!nominalValue })
                        }
                        autoClearSearchValue
                        placeholder={ t('dimension-split.modal.select-label') }
                        disabled={ !selectedDimension }
                        showAction={ [ 'click', 'focus' ] }
                        getPopupContainer={
                          () => document.getElementById(
                            'dimension_' + index + '_labels_' + labelIndex + '_cont'
                          ) as HTMLElement
                        }
                        autoFocus={ false }
                        options={ labelsOptions }
                        defaultValue={ value }
                        popupClassName='ag-custom-component-popup'
                        onChange={ (val) => {
                          onChange(val.value);
                        } }
                      /> }
                    />
                  </div>
                  <Controller
                    control={ control }
                    name={ `dimensions.${ index }.labels.${ labelIndex }.percentage` }
                    render={ ({ field: { value, onChange } }) =>
                      <Input
                        className={ styles.percentageInput }
                        id={ `dimensions_${ index }_labels_${ labelIndex }_percentage` }
                        type='number'
                        suffix='%'
                        disabled={ !labelsValue.at(labelIndex)?.dimensionItemId }
                        value={ value }
                        onChange={ (e) => {
                          onChange(e);
                          const newNominal = (parseFloat(e.target.value) / 100) * nominalValue;
                          setValue(
                            `dimensions.${ index }.labels.${ labelIndex }.nominalValue`,
                            Math.round((newNominal + Number.EPSILON) * 100) / 100
                          );
                        } }
                      />
                    }
                  />
                  { !!nominalValue && <Controller
                    control={ control }
                    name={ `dimensions.${ index }.labels.${ labelIndex }.nominalValue` }
                    render={ ({ field: { value, onChange } }) =>
                      <InputNumber
                        className={ styles.nominalInput }
                        id={ `dimensions_${ index }_labels_${ labelIndex }nominalValue` }
                        disabled={ !labelsValue.at(labelIndex)?.dimensionItemId }
                        value={ value }
                        prefix={ CURRENCY_SYMBOL }
                        controls={ false }
                        precision={ 2 }
                        formatter={
                          newValue => `${ newValue }`.replaceAll(/\B(?=(\d{3})+(?!\d))/g, ' ')
                        }
                        parser={ newValue => Number(newValue.replaceAll(/\s€?|(,*)/g, '')) }
                        onChange={ (newValue) => {
                          onChange(newValue);
                          const newPercentage = (newValue / nominalValue) * 100;
                          setValue(
                            `dimensions.${ index }.labels.${ labelIndex }.percentage`,
                            Math.round((newPercentage + Number.EPSILON) * 100) / 100
                          );
                        } }
                      />
                    }
                  /> }
                  
                  <Button
                    className={ styles.removeButton }
                    type='link'
                    tabIndex={ -1 }
                    onClick={ () => remove(labelIndex) }
                  >
                    <RemoveIcon />
                  </Button>
                </div>
                { errors?.dimensions?.[ index ]?.labels?.[ labelIndex ]?.percentage &&
                  <span className={ styles.formError }>
                    { errors?.dimensions[ index ]?.labels?.[ labelIndex ].percentage?.message }
                  </span> }
              </div>;
            }) 
          }

        </> : null
      }

    </div>

    { selectedDimension ? <>
      <div>
        <Button
          className={ styles.addLabelButton }
          onClick={ addLabel }
          type='link'
          icon={ <AddIcon /> }>
          { t('dimension-split.modal.add-label') }
        </Button>
      </div>

      { labelValues.some(label => typeof label.dimensionItemId !== 'string' ) ? <div className={
        clsx(styles.validation, percentageSum === 100 ? styles.success : styles.error )
      }>
        <span className={ styles.validationLabel }>
          { percentageSum === 100 ? <CheckIcon /> : <WarningIcon /> }
          { t('dimension-split.modal.total') }
        </span>
        <span className={ styles.validationValue }>
          { parseFloat(percentageSum?.toFixed(2) ?? '') }
          %
        </span>
        {
          !!nominalValue && <span className={ styles.validationValue }>
            { parseFloat(nominalValueSum?.toFixed(2) ?? '') }
            { CURRENCY_SYMBOL }
          </span>
        }
      </div> : null }
    </> : null }

  </>;
};

export default DimensionSplit;
