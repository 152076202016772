import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DashboardGridPlaceholder from './placeholder/DashboardGridPlaceholder';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  addElement, batchRemoveElements,
  selectElements,
  selectMode,
  updateElementsLayout,
} from 'store/dashboard.slice';
import styles from './DashboardGrid.module.scss';
import {
  boundaries, CHART_DATASETS,
  COLS_WIDTH,
  mapElementsToLayout,
  NEW_ELEMENT_ID,
  ROW_HEIGHT,
} from 'utils/dashboard.utils';
import {
  DashboardElement,
  DashboardElementType,
  DashboardModeType,
  DEFAULT_STACKS,
  TemplateElementType,
} from 'types/dashboard.types';
import GridItemRenderer from './renderers/GridItemRenderer';
import GridItem from '../wrappers/gridItem/GridItem';
import WarnBeforeDeletionModal from 'components/elements/modals/warnings/WarnBeforeDeletionModal';
import { useTranslation } from 'react-i18next';
import { ChartStyle, ChartSource } from 'types/chart.types';
import GridItemErrorBoundary
  from '../wrappers/gridItem/gridItemErrorBoundary/GridItemErrorBoundary';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ResponsiveGridLayout = WidthProvider(Responsive) as any;

interface Props {
  widthRef: React.RefObject<HTMLDivElement>;
}

const DashboardGrid = ({ widthRef }: Props) => {
  const [ t ] = useTranslation('common');
  const mode = useAppSelector(selectMode);
  const elements = useAppSelector(selectElements);
  const selectedElements = useAppSelector((state) => state.dashboard.selectedElements);
  const [ showDeleteElementsModal, setShowDeleteElementsModal ] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [ mode ]);

  const getUniqueElementId = useCallback(() => {
    if (!elements.length) return 1;
    const ids = elements.map(item => item.id);
    return Math.max(...ids) + 1;
  }, [ elements ]);

  const getBaseSize = useCallback((type: DashboardElementType) => {
    const maxMinBoundaries = boundaries[ type ];
    return {
      width: maxMinBoundaries.defaultW || maxMinBoundaries.minW,
      height: maxMinBoundaries.defaultH || maxMinBoundaries.minH
    };
  }, []);

  const handleAddElement = useCallback((
    type: DashboardElementType,
    layoutElement: Layout
  ): DashboardElement => {
    const id = getUniqueElementId();
    const base = {
      id,
      x: layoutElement.x,
      y: layoutElement.y,
      ...getBaseSize(type),
      name: '',
    };
    switch (type) {
      case DashboardElementType.BIG_NUMBER:
        return {
          ...base,
          type,
          element: {
            template: null,
            templateNode: null,
            valueType: TemplateElementType.CUMULATIVE
          },
          value: null,
        };
      case DashboardElementType.TABLE:
        return {
          ...base,
          type,
          element: {
            template: null
          }
        };
      case DashboardElementType.TITLE:
      case DashboardElementType.SUBTITLE:
        return {
          ...base,
          type,
        };
      case DashboardElementType.CHART:
        return {
          ...base,
          type,
          element: {
            template: null,
            datasets: Array.from({ length: CHART_DATASETS }, () => ({
              active: false,
              templateNode: null,
              style: ChartStyle.BAR,
              datePickerSource: ChartSource.BOTH,
              stacks: DEFAULT_STACKS,
              reverseValues: false,
            })),
            legend: true,
            barLabels: false,
            stackedLabels: false,
            cumulativeValues: false,

            leftAxisFormat: 'nominal',
            rightAxisFormat: 'percentage',
          }
        };
    }
  }, [ getUniqueElementId ]);

  const onDrop = useCallback((_layout: Layout[], _, e: React.DragEvent) => {
    const type = e.dataTransfer.getData('text/plain') as DashboardElementType;
    const newLayoutElement = _layout.find(item => item.i === NEW_ELEMENT_ID);
    const newElement = handleAddElement(type, newLayoutElement);
    dispatch(addElement(newElement));
  }, [ elements ]);

  const layout = useMemo(() => {
    return mapElementsToLayout(elements, mode === DashboardModeType.VIEW);
  }, [ elements, mode ]);

  const onLayoutChange = useCallback((_layout: Layout[], allLayouts: Layouts) => {
    const layoutLength = allLayouts.lg.length;
    const update = !Object.keys(allLayouts).some(key => {
      return Object.values(allLayouts[ key ]).length !== layoutLength;
    });
    if (update) {
      dispatch(updateElementsLayout(_layout));
    }
  }, [ elements ]);
  const children = useMemo(() => {
    return elements.map((item) => (
      <GridItem key={ item.id } item={ item.id }>
        <GridItemErrorBoundary>
          <GridItemRenderer item={ item } />
        </GridItemErrorBoundary>
      </GridItem>
    ));
  }, [ elements ]);

  const heightWithProportion = useMemo(() => {
    const deviceWidth = window.innerWidth;
    const settingsPanelWidth = mode === DashboardModeType.EDIT ? 300 : 0;
    const dashboardArea = deviceWidth - settingsPanelWidth;
    return Math.round((dashboardArea / deviceWidth) * ROW_HEIGHT);
  }, [ mode ]);

  useEffect(() => {
    if (mode === DashboardModeType.VIEW || selectedElements.length === 0) return;
    const onKeyDown = (e: KeyboardEvent) => {
      const keysToTriggerDelete = [ 'Backspace', 'Delete' ];
      const isInputChanging = e.target instanceof HTMLInputElement;
      if (keysToTriggerDelete.includes(e.key) && !isInputChanging) {
        setShowDeleteElementsModal(true);
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [ mode, selectedElements ]);

  const onDeleteSelectedElements = useCallback(() => {
    dispatch(batchRemoveElements(selectedElements));
  }, [ selectedElements ]);

  return (
    <div className={ styles.grid } ref={ widthRef }>
      <DashboardGridPlaceholder />
      <ResponsiveGridLayout
        className={ `
          ${ styles.layout }
          ${ mode === DashboardModeType.VIEW ? styles.layoutView : '' }
        ` }
        layouts={ { lg: layout, md: layout, sm: layout, xs: layout, xxs: layout } }
        margin={ [ 16, 16 ] }
        cols={ { lg: COLS_WIDTH, md: COLS_WIDTH, sm: COLS_WIDTH, xs: COLS_WIDTH, xxs: COLS_WIDTH } }
        rowHeight={ heightWithProportion }
        resizeHandles={ [ 's', 'e', 'se' ] }
        onLayoutChange={ onLayoutChange }
        isDroppable={ true }
        preventCollision={ false }
        onDrop={ onDrop }
      >
        { children }
      </ResponsiveGridLayout>
      <WarnBeforeDeletionModal
        isVisible={ showDeleteElementsModal }
        onClose={ () => setShowDeleteElementsModal(false) }
        onConfirm={ onDeleteSelectedElements }
        amount={ selectedElements.length }
        okText={ t('delete') }
      />
    </div>
  );
};

export default DashboardGrid;
