import React, { useCallback, useState } from 'react';
import { selectCapsuleList, updateCapsuleList, } from 'store/financials.slice';
import TableSelectItem from './tableSelectItem/TableSelectItem';
import {
  Active, DndContext, DragOverlay, Over, PointerSensor, useSensor, useSensors
} from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';

import styles from './TableSelect.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { getCapsuleId } from './tableSelect.utils';

const TableSelect = () => {
  const dispatch = useAppDispatch();
  const capsuleList = useAppSelector(selectCapsuleList);
  const [ activeId, setActiveId ] = useState<string | null>(null);
  const [ t ] = useTranslation('financials');
  
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    })
  );

  const renderTemplateList = useCallback(() => {
    return capsuleList.map(
      tl => <TableSelectItem item={ tl } key={ `capsules__${ tl.type }__${ tl.templateId }` } />
    );
  },[ capsuleList ]);

  const handleDragEnd = async ( { active, over }: { active: Active | null; over: Over | null } ) =>{
    if (!(over && active )) return;
    if ( active.id !== over.id) {

      const oldIndex = capsuleList.findIndex((item) => getCapsuleId(item) === active.id);
      const newIndex = capsuleList.findIndex((item) => getCapsuleId(item) === over.id);
      const orderedTemplateList = arrayMove(capsuleList, oldIndex, newIndex);

      dispatch(updateCapsuleList(orderedTemplateList));
    }
  };

  const isAnyTemplateSelected = capsuleList.length > 0;

  return (
    <>
      <DndContext
        modifiers={ [ restrictToParentElement ] }
        sensors={ sensors }
        onDragEnd={ handleDragEnd }
        onDragStart={ (e) => setActiveId(e.active.id) }
        onDragOver={ () => setActiveId(null) }
        onDragCancel={ () => setActiveId(null) }
      >
        <SortableContext
          strategy={ rectSortingStrategy }
          items={ capsuleList.map(e => getCapsuleId(e)) || [] }
        >
          { !isAnyTemplateSelected ?
            <span className={ styles.emptyTemplates }>
              { t('table-select.add-templates') }
            </span> : renderTemplateList()
          }
        </SortableContext>
        <DragOverlay
          dropAnimation={ {
            duration: 1,
            easing: 'linear',
          } }
        >
          {
            activeId && (
              <div style={ { opacity: 0 } } >
                <div>overlay</div>
              </div>
            )
          }
        </DragOverlay>
      </DndContext>
    </>
  );
};

export default TableSelect;
