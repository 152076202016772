import { ErrorBoundary } from 'react-error-boundary';
import React from 'react';

const ErrorBoundaryRenderer = () => {
  // TODO: Add proper error renderer
  return (
    <div></div>
  );
};

const ErrorBoundaryView = ({ children }) => {
  return <ErrorBoundary fallbackRender={ ErrorBoundaryRenderer }>
    { children }
  </ErrorBoundary>;
};

export default ErrorBoundaryView;
