import { ALL_STACKS } from './../types/dashboard.types';
import { ChartSettings, ChartStyle } from './../types/chart.types';
import { ReportDataValues, ReportType, UUID } from './../types/templates.types';
import dayjs from 'dayjs';
import { isObject } from 'lodash';
import { ChartNode, ChartValues, DateValue, SettingsType } from 'types/chart.types';
import { FinancialRow, RowType } from 'types/financials.types';
import { t } from 'i18next';
import { CHART_DATASETS, getDefaultChartDatasets } from './dashboard.utils';

export const IGNORE_NODES: RowType[] = [
  RowType.TITLE,
  RowType.SPACER,
  RowType.HALF_SPACER,
  RowType.TRANSACTION,
  RowType.NEW_BUDGET_ITEM
];

export const getBiggestTotal = (
  actualData: ReportDataValues,
  planData: ReportDataValues
): number => {
  if (!planData || !actualData) {
    return +actualData?.total || +planData?.total;
  }
  const actualTotal = actualData?.total !== undefined ? actualData?.total : -Infinity;
  const planTotal = planData?.total !== undefined ? planData.total : - Infinity;

  return Math.max(+actualTotal, +planTotal);
};

export const getValuesByDate = (data: ReportDataValues): DateValue[] => {
  const results: DateValue[] = [];
  Object.entries(data)
    .filter(e => dayjs(e[ 0 ]).isValid())
    .forEach(e => {
      const value = Number(e[ 1 ]);
      if (!isNaN(value)) {
        results.push({
          date: e[ 0 ],
          value
        });
      }
    });
  return results;
};

export const getObjectValuesByDate = (data: ReportDataValues): DateValue[] => {
  const results: DateValue[] = [];
  Object.values(data)
    .filter(e => isObject(e))
    .forEach(el => {
      const entries = Object.entries(el);
      entries
        .filter(e => dayjs(e[ 0 ]).isValid())
        .forEach(e => {
          const value = Number(e[ 1 ]);
          if (!isNaN(value)) {
            results.push({
              date: e[ 0 ],
              value
            });
          }
        });
    });
  return results;
};

export const sumValuesByDate = (
  values: DateValue[],
  type: string,
  reportType: ReportType,
  formatting?: 'NOMINAL' | 'PERCENTAGE',
): ChartValues[] => {
  const results: ChartValues[] = [];
  const dates = values.map(el => el.date);
  const uniqueDates = Array.from(new Set(dates)).sort((a, b) => {
    return dayjs(a).diff(dayjs(b));
  });
  uniqueDates.forEach(date => {
    const valuesByDate = values.filter(el => el.date === date);
    const value = valuesByDate.reduce((acc, el) => acc + el.value, 0);
    results.push({
      date,
      value,
      type,
      reportType,
      formatting,
    });
  });
  return results;
};

const mapDataToValues = (data: ReportDataValues) => {
  return Object.entries(data)
    .filter(([ key ]) => dayjs(key).isValid())
    .map(([ key, value ]) => ({ [ key ]: value })) as unknown as ReportDataValues;
};

const groupFinancialRowValues = (rows: FinancialRow[], type: ReportType) => {
  return rows.reduce((acc, curr) => {
    const keyEntries = Object.entries(curr[ type ] || {})
      .filter(([ key ]) => dayjs(key).isValid());
    keyEntries.forEach(([ key, value ]) => {
      acc[ key ] = acc[ key ] ? acc[ key ] + +value : +value;
    });
    return acc;
  }, {});
};

export const groupRowsByTotal = (rows: FinancialRow[], limit = 10): ChartNode[] => {
  const isSumNegative = rows.reduce(
    (acc, curr) => acc + getBiggestTotal(curr.actual, curr.plan), 0
  ) < 0;
  const multiplier = isSumNegative ? -1 : 1;
  const sortedRows = rows.filter(row => {
    return getBiggestTotal(row.actual, row.plan) !== 0;
  }).sort((a, b) => {
    const aTotal = getBiggestTotal(a.actual, a.plan);
    const bTotal = getBiggestTotal(b.actual, b.plan);
    return (bTotal - aTotal) * multiplier;
  });
  
  const biggestRows = (limit === ALL_STACKS ? sortedRows : sortedRows.slice(0, limit)).map(el => ({
    type: el.type,
    rowData: el.rowData,
    data: {
      [ ReportType.ACTUAL ]: mapDataToValues(el[ ReportType.ACTUAL ] || {}),
      [ ReportType.PLAN ]: mapDataToValues(el[ ReportType.PLAN ] || {}),
    }
  }));
  const otherRows = limit === ALL_STACKS ? [] : sortedRows.slice(limit);
  if (!otherRows.length) {
    return biggestRows;
  }
  const planData = groupFinancialRowValues(otherRows, ReportType.PLAN);
  const actualData = groupFinancialRowValues(otherRows, ReportType.ACTUAL);

  const otherRow = {
    type: RowType.FINANCIALS,
    rowData: {
      name: t('financials:charts.summed-rows'),
    },
    data: {
      [ ReportType.ACTUAL ]: mapDataToValues(actualData),
      [ ReportType.PLAN ]: mapDataToValues(planData),
    }
  };
  return [ ...biggestRows, otherRow ];
};

interface DefaultChartSettingParams {
  primary?: UUID;
  secondary?: UUID;
}

export const getDefaultChartSettings = ({
  primary = null,
  secondary = null
}: DefaultChartSettingParams): ChartSettings => {

  const datasets = getDefaultChartDatasets(CHART_DATASETS, true);
  if (primary) {
    datasets[ 0 ].templateNode = primary;
  }
  if (secondary) {
    datasets[ 1 ].templateNode = secondary;
  }
  return {
    datasets,
    [ SettingsType.OTHER ]: {
      dataLabels: {
        bar: true,
        stacked: true,
      },
      cumulativeValues: false,
      legend: true,
      leftAxisFormat: 'nominal',
      rightAxisFormat: 'percentage',
    }
  };
};

export const isLineChart = (style: ChartStyle): boolean => {
  return [ ChartStyle.LINE, ChartStyle.LINE_WITH_BACKGROUND ].includes(style);
};
