import { Dropdown } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './SelectFilter.module.scss';
import { ReactComponent as FilterIcon } from 'assets/icons/filterv2.svg';
import { useRevenueRecognition } from 'context/RevenueRecognitionContext';
import { FilterItem, FilterTypeCustomer, FilterTypeProduct } from 'types/revenueRecognition.types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Capsule from 'components/elements/capsule/Capsule';

const SelectFilter = () => {
  const { id: existId } = useParams();

  const [ visible, setVisible ] = useState(false);

  const { dispatch } = useRevenueRecognition();

  const [ t ] = useTranslation('revenueRecognition');

  const {
    state: { filterCustomer, filterProduct },
  } = useRevenueRecognition();

  const optionFilterCustomers: FilterItem[] = [
    {
      index: FilterTypeCustomer.ALL,
      name: t('filter-customers-menu.filter-all'),
      isFilter: false,
    },
    {
      index: FilterTypeCustomer.RESOLVED,
      name: t('filter-customers-menu.filter-resolved'),
      isFilter: true,
    },
    {
      index: FilterTypeCustomer.UNRESOLVED,
      name: t('filter-customers-menu.filter-unresolved'),
      isFilter: true,
    }
  ];

  const optionFilterProducts: FilterItem[] = [
    {
      index: FilterTypeProduct.ALL,
      name: t('filter-products-menu.filter-all'),
      isFilter: false,
    },
    {
      index: FilterTypeProduct.RECOGNIZED_SYSTEM,
      name: t('filter-products-menu.recognized-by-system'),
      isFilter: true,
    },
    {
      index: FilterTypeProduct.RECOGNIZED_USER,
      name: t('filter-products-menu.recognized-by-user'),
      isFilter: true,
    },
    {
      index: FilterTypeProduct.UNRECOGNIZED,
      name: t('filter-products-menu.unrecognized'),
      isFilter: true,
    },
  ];

  const mapFilter = useMemo(() => {
    return existId ? optionFilterProducts : optionFilterCustomers;
  }, []);

  const onFilter = useCallback((element: FilterItem) => {
    const payload = { ...element };
    if (existId) {
      dispatch({ type: 'FILTER_PRODUCT', payload });
    } else {
      dispatch({ type: 'FILTER_CUSTOMER', payload });
    }
  }, [ existId ]);

  return (
    <Dropdown
      placement='bottomRight'
      open={ visible }
      onOpenChange={ () => setVisible(prev => !prev) }
      dropdownRender={ () =>
        <div className={ styles.menuList }>
          <span className={ styles.header }>{ t('filter-title') }</span>
          <div className={ `${ styles.capsuleContainer }
           ${ existId ? styles.customerStyle : styles.productStyle } ` }>
            {
              mapFilter.map(el => {
                const chooseFilter = existId ? filterProduct.index : filterCustomer.index;
                const isCheck = el.index === chooseFilter;
                return <Capsule
                  key={ el.index }
                  isActive={ isCheck }
                  onClick={ () => onFilter(el) }>
                  { el.name }
                </Capsule>;
              })

            }
          </div>
        </div>
      }>
      <span className={ `${ styles.iconButton } ${
        visible ? styles.active : '' }`
      }>
        <FilterIcon />
      </span>
    </Dropdown>
  );
};

export default SelectFilter;
