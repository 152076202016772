import { GetDetailRowDataParams, ValueFormatterParams } from 'ag-grid-community';
import useTableColumns from 'components/elements/tableWrapper/hooks/useTableColumns';
import useTableFormatter from 'components/elements/tableWrapper/hooks/useTableFormatter';
import useTableRenderer from 'components/elements/tableWrapper/hooks/useTableRenderer';
import { TableProps } from 'components/elements/tableWrapper/types/table.types';
import { HEADER_HEIGHT, ROW_HEIGHT } from 'components/elements/tableWrapper/utils/table.utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import { BudgetItem } from 'types/budget.types';
import { getDimensionViewName } from 'utils/financials.utils';

interface BISplit {
  dimensionItem: number;
  percentage: number;
  value: number;
}

interface Props {
  search: string;
}

const useMasterDetailSplits = ({ search }: Props): Partial<TableProps<BudgetItem>> => {
  const [ t ] = useTranslation('tableWrapper');
  const { renderField, amountRenderer } = useTableRenderer({ search });
  const { defaultColDef, numberColumnDef } = useTableColumns({ search });
  const { percentageFormatter } = useTableFormatter();
  const dimensionItemMap = useAppSelector(state => state.breakdowns.dimensionItemMap);
  const dimensionMap = useAppSelector(state => state.breakdowns.dimensionMap);

  const getDimensionName = (dimensionItemId: number) => {
    const item = dimensionItemMap[ dimensionItemId ];
    const dimension = dimensionMap[ item.dimensionId ];
    return getDimensionViewName(dimension);
  };

  const getDimensionItemName = (dimensionItemId: number) => {
    const item = dimensionItemMap[ dimensionItemId ];
    return getDimensionViewName(item);
  };

  const dimensionFormatter = (params: ValueFormatterParams<BISplit>) => {
    return getDimensionName(params.data.dimensionItem);
  };

  const dimensionItemFormatter = (params: ValueFormatterParams<BISplit>) => {
    return getDimensionItemName(params.data.dimensionItem);
  };

  const isSplitDimensionItem = (split: BISplit) => {
    return Number(split.percentage) !== 100;
  };

  const getSplitDimensionItems = (splits: BISplit[]) => {
    return splits?.filter(isSplitDimensionItem);
  };

  return useMemo((): Partial<TableProps<BudgetItem>> => ({
    masterDetail: true,
    isRowMaster: ({ dimensionSplit }: BudgetItem) => {
      if (!dimensionSplit) return false;

      return getSplitDimensionItems(dimensionSplit as BISplit[])?.length > 0;
    },
    groupDefaultExpanded: 0,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: {
        defaultColDef: defaultColDef,
        headerHeight: HEADER_HEIGHT,
        rowHeight: ROW_HEIGHT,
        columnDefs: [
          {
            ...renderField('dimension', t('transactions-table.master-detail.dimension')),
            valueGetter: dimensionFormatter,
            keyCreator: dimensionFormatter,
            getQuickFilterText: dimensionFormatter,
            flex: 0,
          },
          {
            ...renderField('dimensionItem', t('transactions-table.master-detail.dimension-item')),
            valueGetter: dimensionItemFormatter,
            keyCreator: dimensionItemFormatter,
            getQuickFilterText: dimensionItemFormatter,
            flex: 0,
          },
          {
            ...renderField('value', t('transactions-table.master-detail.value')),
            ...numberColumnDef,
            valueGetter: (params: ValueFormatterParams<BISplit>) => params.data.value,
            flex: 0,
            width: 120,
            cellRenderer: amountRenderer
          },
          {
            ...renderField('percentage', t('transactions-table.master-detail.percentage')),
            ...numberColumnDef,
            valueFormatter: percentageFormatter,
            width: 120,
            flex: 0,
          },
        ]
      },
      getDetailRowData: (detailRowParams: GetDetailRowDataParams<BudgetItem>) => {
        return detailRowParams.successCallback(
          getSplitDimensionItems(
            detailRowParams.data.dimensionSplit.map((split: BISplit) => ({
              dimensionItem: split.dimensionItem,
              percentage: split.percentage,
              value: +detailRowParams.data.amountFormula * (split.percentage / 100)
            }))
          )
        );
      }
    }
  }), []);
};

export default useMasterDetailSplits;
