import React, { useCallback, useEffect, useMemo } from 'react';
import InlineDatePicker from 'components/elements/inlineDatePicker/InlineDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useForm } from 'react-hook-form';
import { ContractType, InvoicingFrequency } from 'types/contracts.types';
import styles from './ContractForm.module.scss';
import Select from 'components/elements/select/Select';
import { ReactComponent as ActiveIcon } from 'assets/icons/contracts/active.svg';
import { ReactComponent as ChurnedIcon } from 'assets/icons/contracts/churned.svg';
import { ReactComponent as BookedIcon } from 'assets/icons/contracts/booked.svg';
import { ReactComponent as PlannedIcon } from 'assets/icons/contracts/planned.svg';
import { ReactComponent as PausedIcon } from 'assets/icons/contracts/paused.svg';
import { ReactComponent as TrialIcon } from 'assets/icons/contracts/trial.svg';

import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store/hooks/hooks';
import { DimensionItem } from '../../../../../types/filterTable.types';
import organizationsService from '../../../../../services/organizations.service';
import { breakdownsActions } from '../../../../../store/breakdowns.slice';
import { DATE_FORMATS } from '../../../../../utils/date.utils';

interface Props {
  contract: DimensionItem;
}

const ContractForm = ({ contract }: Props) => {
  const { watch, setValue } = useForm({
    defaultValues: {
      status: contract.contract.status,
      startDate: dayjs(contract.contract.startDate),
      endDate: dayjs(contract.contract.endDate),
      invoicingFrequency: contract.contract.invoicingFrequency
    }
  });
  const [ t ] = useTranslation('contracts');
  const dispatch = useAppDispatch();
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const status = watch('status');
  const invoicingFrequency = watch('invoicingFrequency');

  useEffect(() => {
    const subscription = watch(async (value) => {
      const response = await organizationsService.editDimensionsItem(contract.id, {
        contract: {
          ...value,
          startDate: (value.startDate as dayjs.Dayjs).format(),
          endDate: (value.endDate as dayjs.Dayjs).format(),
        }
      });
      dispatch(breakdownsActions.editDimensionItem(response.data));
    });

    return () => subscription.unsubscribe();

  }, [ watch ]);

  const getIcon = useCallback((type: ContractType) => {
    return {
      [ ContractType.ACTIVE ]: <ActiveIcon/>,
      [ ContractType.CHURNED ]: <ChurnedIcon/>,
      [ ContractType.BOOKED ]: <BookedIcon/>,
      [ ContractType.PLANNED ]: <PlannedIcon/>,
      [ ContractType.PAUSED ]: <PausedIcon/>,
      [ ContractType.TRIAL ]: <TrialIcon/>,
      [ ContractType.PILOT ]: <TrialIcon/>,
    }[ type ];
  }, []);

  const statusOptions = useMemo(() => {
    return Object.values(ContractType).map((type) => {
      return {
        value: type,
        label: capitalize(t('contracts:type.' + type)),
        icon: getIcon(type),
      };
    });
  }, []);

  const invoicingFrequencyOptions = useMemo(() => {
    return Object.values(InvoicingFrequency).map((type) => {
      return {
        value: type,
        label: capitalize(t('contracts:frequency.' + type)),
      };
    });
  }, []);

  const renderDatePicker = useCallback(
    (prefix: string, name: 'startDate' | 'endDate', value: Dayjs) => {
      return (
        <InlineDatePicker
          disabled={ false }
          value={ value }
          format={ (val) => val.format(DATE_FORMATS[ 0 ]) }
          onChange={ (val) => setValue(name, val) }
          getPopupContainer={ (node) => node }
          showToday={ false }
          prefix={ prefix }
        />
      );
    }, []);

  return (
    <form className={ styles.contractForm }>
      { renderDatePicker(t('contract.starting-date'), 'startDate', startDate) }
      { renderDatePicker(t('contract.ending-date'), 'endDate', endDate) }
      <div className={ styles.selectContainer }>
        <Select
          options={ statusOptions }
          defaultValue={ status }
          onSelect={ (val) => setValue('status', val as ContractType) }
          isActive={ (val) => val === status }
          showActiveStyles={ false }
          showSuffixIcon={ false }
          className={ styles.select }
          listHeight={ 280 }
          prefix={ <span className={ styles.labelPrefix }>
            { t('contract.status') }
          </span> }
        />
      </div>
      <div className={ styles.selectContainer }>
        <Select
          options={ invoicingFrequencyOptions }
          defaultValue={ invoicingFrequency }
          onSelect={ (val) => setValue('invoicingFrequency', val as InvoicingFrequency) }
          isActive={ (val) => val === invoicingFrequency }
          showActiveStyles={ false }
          showSuffixIcon={ false }
          className={ styles.select }
          listHeight={ 280 }
          prefix={ <span className={ styles.labelPrefix }>
            { t('contract.invoicingFrequency') }
          </span> }
        />
      </div>
    </form>
  );
};

export default ContractForm;
