import dayjs, { Dayjs, QUnitType } from 'dayjs';
import { Cadence } from 'types/form.types';
import i18n from 'locales/i18n';
const { t } = i18n;

export const DATE_FORMATS = [ 'D.M.YYYY', 'DD.MM.YYYY' ];

export const getLastDayOfWeek = (date: Dayjs) => {
  const offset = date.weekday() === 0 ? -1 : 0;
  return dayjs(date.add(offset, 'day')).weekday(7);
};

export const getFirstDayOfWeek = (date: Dayjs) => {
  const offset = date.weekday() === 0 ? -1 : 0;
  return dayjs(date.add(offset, 'day')).weekday(1);
};

export const getPickerStartDate = (date: Dayjs, cadence: Cadence) => {
  if (cadence === Cadence.week) {
    return getFirstDayOfWeek(date);
  }
  return date.startOf(cadence as QUnitType);
};

export const getPickerEndDate = (date: Dayjs, cadence: Cadence) => {
  if (cadence === Cadence.week) {
    return getLastDayOfWeek(date);
  }
  return date.endOf(cadence as QUnitType);
};

export const getTimeWithUnit = (timeInS: number) => {
  const min = Math.floor(timeInS / 60);
  const hour = Math.floor(min / 60);
  const day = Math.floor(hour / 24);
  const week = Math.floor(day / 7);
  const month = Math.floor(day / 30);
  const year = Math.floor(day / 365);
  const listOfFormats = [
    { unit: t('short-time.year', { ns: 'common' }), value: year },
    { unit: t('short-time.month', { ns: 'common' }), value: month },
    { unit: t('short-time.week', { ns: 'common' }), value: week },
    { unit: t('short-time.day', { ns: 'common' }), value: day },
    { unit: t('short-time.hour', { ns: 'common' }), value: hour },
    { unit: t('short-time.min', { ns: 'common' }), value: min },
    { unit: t('short-time.second', { ns: 'common' }), value: timeInS }
  ];

  const format = listOfFormats.find((f) => f.value > 0);
  return format ?
    `${ format.value } ${ format.unit }` :
    `0 ${ t('short-time.second', { ns: 'common' }) }`;
};

export const lastDayOfCadence = (date: Dayjs, cadence: Cadence) => {
  if (cadence !== Cadence.week) {
    return date.endOf(cadence as QUnitType);
  }
  return date;
};

export const firstDayOfCadence = (date: Dayjs, cadence: Cadence) => {
  if (cadence !== Cadence.week) {
    return date.startOf(cadence as QUnitType);
  }
  return date;
};

export const getDatePickerInputName = (name: string) => {
  switch (name) {
    case 'startDateActuals':
      return 'startDateActualsInput';
    case 'endDateActuals':
      return 'endDateActualsInput';
    case 'startDatePlan':
      return 'startDatePlanInput';
    case 'endDatePlan':
      return 'endDatePlanInput';
    default:
      return 'startDateActualsInput';
  }
};
