/* eslint-disable no-undef */

// eslint-disable-next-line no-undef
const { REACT_APP_ANALYTICS_KEY } = process.env;

export const connectAnalytics = (user) => {
  window.heap = window.heap || [];

  if (Array.isArray(window.heap)) {
    heap.load = function (appId, heapConfig) {
      window.heap.appid = appId;
      window.heap.config = heapConfig || {};
  
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.heapanalytics.com/js/heap-' + appId + '.js';
      
      const firstScript = document.getElementsByTagName('script')[ 0 ];
      firstScript?.parentNode.insertBefore(script, firstScript);
      
      const cloneArray = (arrayLike) => Array.prototype.slice.call(arrayLike, 0);
  
      const createMethod = function (method) {
        return function () {
          heap.push([
            method,
            ...cloneArray(arguments)
          ]);
        };
      };
  
      const methods = [
        'addEventProperties',
        'addUserProperties',
        'clearEventProperties',
        'identify',
        'resetIdentity',
        'removeEventProperty',
        'setEventProperties',
        'track',
        'unsetEventProperty',
      ];
      
      for (const method of methods) {
        heap[ method ] = createMethod(method);
      }
    };
   
    heap.load(REACT_APP_ANALYTICS_KEY);
    heap.identify(user.email);
    heap.addUserProperties({ 'email': user.email, });
  }
   
};
