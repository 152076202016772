import React, { useCallback, useMemo, useRef, useState } from 'react';
import styles from './LeftPanel.module.scss';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Products from '../products/Products';
import { useTranslation } from 'react-i18next';
import { selectProduct } from '../../../store/revenueRecognition.slice';
import { ReactComponent as ChevronBack } from 'assets/icons/chevron-right-dark.svg';
import Card from 'components/elements/card/Card';
import { useAppSelector } from '../../../store/hooks/hooks';
import ExpandButton from '../../elements/expandButton/ExpandButton';
import useEscapeListener from 'hooks/useEscapeListener';
import { useRevenueRecognition } from 'context/RevenueRecognitionContext';
import SearchBar from 'components/elements/searchBar/SearchBar';
import { useSearchable } from 'context/SearchableContext';
import { displayNameComparator } from '../../../utils/sorting.utils';

const PRODUCTS_LIMIT = 25;

const LeftPanel = () => {
  const [ numberToShow, setNumberToShow ] = useState(PRODUCTS_LIMIT);
  const [ openPanel, setOpenPanel ] = useState(true);
  const { state: { addProduct }, dispatch } = useRevenueRecognition();
  const { state: { search } } = useSearchable();

  const ref = useRef(null);
  useEscapeListener(ref, () => setNumberToShow(PRODUCTS_LIMIT), numberToShow !== PRODUCTS_LIMIT);
  const [ t ] = useTranslation('revenueRecognition');
  const products = useAppSelector(selectProduct);

  const handleAddProduct = useCallback(() => {
    dispatch({ type: 'ADD_PRODUCT', payload: !addProduct });
  }, [ addProduct ]);

  const handleChangePanel = useCallback(() => {
    setOpenPanel(prev => !prev);
  }, []);

  const filteredProducts = useMemo(() => {
    const clonedProducts = [ ...products ];
    return clonedProducts
      .sort((a, b) => displayNameComparator(a.name, b.name))
      .slice(0, numberToShow)
      .filter((element) => element.name.toLowerCase().includes(search.toLowerCase()));
  }, [ numberToShow, search, products ]);

  return (
    <Card
      onClick={ () => !openPanel && handleChangePanel() }
      className={ ` ${ styles.panel }
       ${ openPanel ? styles.openPanel : styles.closePanel }`
      }>
      <div className={ `${ styles.headerWrapper } ${ !openPanel ? styles.closePanel : '' }` }>
        <div>
          <span>{ t('singleRevenueRecognition.products') }</span>
          <span>&bull;</span>
          <span>{ products.length }</span>
        </div>
        <span className={ styles.iconWrapper } onClick={ handleAddProduct }>
          <PlusIcon className={ styles.iconPlus } />
        </span>
      </div>
      <SearchBar className={ styles.searchBar } showSeparator={ false } showResults={ false } />
      <div ref={ ref } className={ styles.productSection }>
        <div className={ `${ styles.productWrapper } ${ !openPanel ? styles.closePanel : '' }` }>
          {
            addProduct && <Products id={ -100 } name='' editIcon={ true } />
          }
          <Products id={ -1 } name='All' editIcon={ true } />
          {
            filteredProducts.map((el) => <Products id={ el.id } key={ el.id } name={ el.name }/>)
          }
          <ExpandButton
            onClick={ () => setNumberToShow(prev => prev + 25) }
            number={ products.length - numberToShow }
            hide={ products.length - numberToShow > 0 } />
        </div>
        <div>
          <div className={ styles.collapseContainer }
            onClick={ () => openPanel && handleChangePanel() }>
            <ChevronBack
              className={
                ` ${ styles.collapseIcon } ${ openPanel ? styles.openPanel : styles.closePanel }`
              }
            />
          </div>
        </div>
      </div>

    </Card>
  );
};

export default LeftPanel;