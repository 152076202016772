import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './ResizableContainer.module.scss';
import { throttle } from 'lodash';
import clsx from 'clsx';

type DimensionType = 'width' | 'height';
type LinePlacement = 'bottom' | 'top' | 'left' | 'right';

type Props = {
  children: React.ReactNode;
  defaultSize?: number;
  className?: string;
  minSize?: number;
  maxSize?: number;
  otherDimensionSize?: number;
  dimensionToResize?: DimensionType;
  onSizeChange?: (newSize: number) => void;
  linePlacement?: LinePlacement;
  style?: CSSProperties;
  resizeLineClassName?: string;
};

const ResizableContainer = ({
  children,
  defaultSize = 200,
  minSize = 0,
  maxSize = 1400,
  className = '',
  dimensionToResize = 'width',
  otherDimensionSize,
  onSizeChange,
  linePlacement= 'bottom',
  resizeLineClassName = '',
  style={}
}: Props) => {
  const [ size, setSize ] = useState(0);
  const [ isResizing, setIsResizing ] = useState(false);
  const [ dragStartPoint, setDragStartPoint ] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const resizableDivRef = useRef(null);
  useEffect(() => {
    setSize(defaultSize);
    onSizeChange && onSizeChange(defaultSize);
    return () => onSizeChange && onSizeChange(0);
  }, [ defaultSize ]);

  const throttledSetSize = useCallback(throttle(setSize, 20), []);

  useEffect(() => {
    const resizableDiv = resizableDivRef.current;

    const onMouseMove = (e) => {
      if (!isResizing || !dragStartPoint) {
        return;
      }
      let offset: number;
      let newSize: number;

      if (dimensionToResize == 'width') {
        offset = dragStartPoint.x - e.clientX;
      } else {
        offset = dragStartPoint.y - e.clientY;
      }
      if (linePlacement === 'left' || linePlacement === 'top') {
        newSize = size + offset;
      } else {
        newSize = size - offset;
      }

      if (newSize < minSize) {
        newSize = minSize;
      } else if (newSize > maxSize) {
        newSize = maxSize;
      }
      throttledSetSize(newSize);
      onSizeChange && onSizeChange(newSize);
    };

    const onMouseDown = (e) => {
      setIsResizing(true);
      setDragStartPoint({ x: e.clientX, y: e.clientY });
    };

    const onMouseUp = () => {
      setIsResizing(false);
      setDragStartPoint(null);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    resizableDiv.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      resizableDiv.removeEventListener('mousedown', onMouseDown);
    };

  }, [ dimensionToResize, minSize, maxSize, dragStartPoint, isResizing, linePlacement ]);

  const onDoubleClick = useCallback(() => {
    setSize(defaultSize);
    onSizeChange && onSizeChange(defaultSize);
  }, []);

  const resizeStyle = useMemo((): CSSProperties => {
    if (dimensionToResize == 'width') {
      return {
        width: '100%',
        maxWidth: `${ size }px`,
        minWidth: `${ size }px`,
        minHeight: otherDimensionSize ? `${ otherDimensionSize }px` : 'none'
      };
    } else {
      return {
        height: `${ size }px`,
        maxHeight: `${ maxSize }px`,
        minHeight: `${ minSize }px`
      };
    }
  }, [ size, otherDimensionSize, maxSize, minSize, dimensionToResize ]);

  const resizerClass = useMemo(() => {
    if (dimensionToResize === 'width') {
      if (linePlacement === 'left') {
        return styles.resizeLineHorizontalLeft;
      }
      return styles.resizeLineHorizontalRight;
    } else {
      if (linePlacement === 'top') {
        return styles.resizeLineVerticalTop;
      }
      return styles.resizeLineVerticalBottom;
    }
  }, [ dimensionToResize, linePlacement ]);

  return (
    <div
      className={ clsx(styles.resizeContainer, className) }
      style={ { ...resizeStyle, ...style } }
    >
      { children }
      <div
        onDoubleClick={ onDoubleClick }
        ref={ resizableDivRef }
        role='separator'
        aria-label='Resize'
        className={ clsx(
          styles.resizeLine,
          resizerClass,
          { [ styles.resizeLineActive ]: isResizing },
          resizeLineClassName
        ) }
      />
    </div>
  );
};

export default memo(ResizableContainer);
