import { Dropdown, Progress, Tooltip } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import styles from './BottomBar.module.scss';

import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-rounded.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import {
  BreakdownCategoryType,
  Dimension,
  DimensionItem as DimensionItemType,
  FilterList
} from 'types/filterTable.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { RowType } from 'types/financials.types';
import { TemplateNode } from 'types/templates.types';
import _, { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import Modal from 'components/elements/modal/Modal';
import AddEditDimensionModal
  from 'components/elements/dimensionLeftPanel/addEditDimensionModal/AddEditDimensionModal';
import SelectButton from './selectButton/SelectButton';
import { useDrag } from 'react-dnd';
import { isDimensionGroup, isDimensionType, } from 'utils/template.utils';
import { selectAltKey, toggleAltKey } from 'store/events.slice';
import { setHoverDimension } from 'store/templates.slice';
import { createNode, selectTemplate } from 'store/template.slice';
import { FinancialTableContext } from '../../../../../../context/FinancialTableContext';
import { getUUID } from '../../../../../../utils/templates.utils';
import { getDimensionDisplayName, getDimensionViewName } from 'utils/financials.utils';
import { selectCustomFormula } from 'store/formula.slice';
import Capsule from 'components/elements/capsule/Capsule';
import DimensionViewLabel from '../../dimensionViewLabel/DimensionViewLabel';
import { findDimensionFilter } from '../dimensionDetails.util';

interface Props {
  dimension?: Dimension | null;
  items?: DimensionItemType[];
  isUnassignedSearched?: boolean;
  stagingFilter?: FilterList;
  setStagingFilter?: React.Dispatch<React.SetStateAction<FilterList>>;
  setActiveUnassigned?: React.Dispatch<React.SetStateAction<boolean>>;
  onAddDimensionItem: (name: string) => Promise<void>;
  onDimensionDelete: () => Promise<void>;
  onDimensionEdit: (name: string) => Promise<void>;
  isTemplateBuilder: boolean;
  dimensionType: BreakdownCategoryType;
  isLabeling: boolean;
}

const BottomBar = (
  {
    dimension,
    items,
    isUnassignedSearched=true,
    stagingFilter,
    setStagingFilter,
    onAddDimensionItem,
    setActiveUnassigned,
    onDimensionDelete,
    onDimensionEdit,
    dimensionType,
    isTemplateBuilder,
    isLabeling
  }: Props) => {
  const templateNode: TemplateNode = {
    id: getUUID(), uuid: getUUID(), rowData: dimension, children: [],
    childrenNodes: [], type: RowType.BREAKDOWN
  };

  const [ t ] = useTranslation('financials');

  const countTransactionLines = useAppSelector(state => state.breakdowns.countTransactionLines);
  const dimensionMap = useAppSelector((state) => state.breakdowns.dimensionMap);
  const altKey = useAppSelector(selectAltKey);
  const customFormula = useAppSelector(selectCustomFormula);
  const customFormulaIsOpen = useMemo(() => customFormula !== null, [ customFormula ]);
  const template = useAppSelector(selectTemplate);

  const [ modalDimensionItem, setModalDimensionItem ] = useState(false);
  const [ dropdown, setDropdown ] = useState(false);
  const [ modalEditDimensionTitle, setModalEditDimensionTitle ] = useState(false);

  const [ modalDeleteDimensionItem, setModalDeleteDimensionItem ] = useState(false);

  const financialTableContext = useContext(FinancialTableContext);
  const customerId = financialTableContext?.state.customSettings.panelSettings.customerId;

  const dispatch = useAppDispatch();

  const isActiveTemplate = useMemo(() => {
    return template.title !== '';
  }, [ template ]);

  const canAddDimensionItem = useMemo(() => {
    if (dimension?.relation === 'CONTRACT') {
      return !!customerId;
    }
    return dimension?.canAddItems;
  }, [ dimension ]);

  const onClickAdd = useCallback(() => {
    setModalDimensionItem(prev => !prev);
  }, []);

  const isAnyFiltered = useMemo(() => {
    if (!stagingFilter) return false;

    for (const filter of stagingFilter) {
      for (const item of items) {
        if (filter.excludedItems.includes(item.id)) {
          return true;
        }
      }
    }
  }, [ stagingFilter, items ]);

  const onClickSelectAll = useCallback(() => {
    // isAnyFiltered === true => "Select All"
    //   - remove items from excluded
    //   - excludeUnassigned = false
    // isAnyFiltered === false => "Unselect All"
    //   - add items to excluded
    //   - excludeUnassigned = true
    setActiveUnassigned(isAnyFiltered);
    const itemsToModify = items.map((item) => item.id);
    setStagingFilter(prev => {
      const newFilters = cloneDeep(prev);
      const dimensionFiltersIndex = findDimensionFilter(newFilters, dimension.id);

      let newExcludedItems: number[];
      if (isAnyFiltered) {
        newExcludedItems = _.difference(
          newFilters[ dimensionFiltersIndex ].excludedItems,
          itemsToModify
        );
      } else {
        newExcludedItems = _.union(
          newFilters[ dimensionFiltersIndex ]?.excludedItems ?? [],
          itemsToModify
        );
      }

      if (dimensionFiltersIndex > -1) {
        newFilters[ dimensionFiltersIndex ] = {
          dimension: dimension.id,
          excludeUnassigned: !isAnyFiltered,
          excludedItems: newExcludedItems
        };
        return newFilters;
      } else {
        newFilters.push({
          dimension: dimension.id,
          excludeUnassigned: !isAnyFiltered,
          excludedItems: itemsToModify
        });
        return newFilters;
      }
    });
  }, [
    isAnyFiltered,
    items,
    isUnassignedSearched,
    dimension,
    stagingFilter,
    setActiveUnassigned,
    setStagingFilter,
    dimensionMap
  ]);

  const dragSingleItem = useMemo(() => {
    return !isDimensionGroup(templateNode) && (altKey || (isDimensionType(templateNode)));
  }, [ altKey, dimension, templateNode ]);

  const [ { isDragging }, drag ] = useDrag(() => {
    return {
      type: 'tag',
      item: templateNode,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    };
  }, [ dragSingleItem, dimension ]);

  const progressBar = useCallback((item: Dimension) => (
    <>
      <Progress
        strokeColor={ styles.progressColor }
        className={ styles.progressBar }
        strokeWidth={ 12 }
        percent={
          Math.round((item.transactionLines / countTransactionLines) * 100)
        }/>
      {
        Math.round((item.transactionLines / countTransactionLines) * 100) > 99
        && <CheckIcon className={ styles.acceptIcon }/>
      }
    </>
  ), [ countTransactionLines ]);

  const handleDoubleClick = () => {
    if (isActiveTemplate && !customFormulaIsOpen) {
      dispatch(createNode(templateNode, null, 'none'));
    }
  };

  const onMouseEnterDragTag = useCallback((event) => {
    dispatch(toggleAltKey(event.altKey));

    if (!isDragging) {
      dispatch(setHoverDimension([ dimension?.id ]));
    } else if (isDragging)
      dispatch(setHoverDimension(null));

  }, []);

  const onMouseLeaveDragTag = () => {
    dispatch(setHoverDimension(null));
  };

  return (
    <div className={ styles.bottomBar }>
      <Modal
        title={
          <div className={ styles.modalTitle }>
            <BinIcon className={ styles.icon }/>
            { t('left-panel.delete-dimension.delete-label') }
          </div>
        }
        okButtonProps={ { danger: true } }
        description={ t('left-panel.delete-dimension.description',
          { name: getDimensionViewName(dimension) }) }
        isVisible={ modalDeleteDimensionItem }
        className={ styles.modalDelete }
        closeText={ t('left-panel.delete-dimension.cancel') }
        okText={ t('left-panel.delete-dimension.delete') }
        onClose={ () => setModalDeleteDimensionItem(false) }
        onConfirm={ async () => {
          await onDimensionDelete();
          setDropdown(false);
        } }
      />
      <AddEditDimensionModal
        isVisible={ modalDimensionItem }
        onClose={ () => setModalDimensionItem(false) }
        onConfirm={ async (name) => {
          await onAddDimensionItem(name);
          setModalDimensionItem(false);
        } }
        title={ <div className={ styles.modalHeader }>
          <PlusIcon/>
          {
            t('left-panel.add-dimension.title', {
              name: getDimensionViewName(dimension)?.toLowerCase()
            })
          }
        </div> }
        label={ t('left-panel.add-dimension.custom-dimension-name') }
        okText={ t('left-panel.add-dimension.ok-text') }
        defaultValue={ t('left-panel.add-dimension.new-label') }
      />
      <AddEditDimensionModal
        isVisible={ modalEditDimensionTitle }
        onClose={ () => setModalEditDimensionTitle(false) }
        onConfirm={ async (name) => {
          await onDimensionEdit(name);
          setModalEditDimensionTitle(false);
        } }
        title={ <div>
          <EditIcon/>
          { t('left-panel.edit-dimension-label') }
        </div> }
        label={ t('left-panel.add-dimension.custom-dimension-name') }
        okText={ t('left-panel.edit') }
        defaultValue={ getDimensionViewName(dimension) }
      />
      <div className={ styles.left }>
        <Dropdown
          open={ dropdown }
          className={ styles.dropdown }
          onOpenChange={ (visible) => setDropdown(visible) }
          trigger={ [ 'contextMenu' ] }
          dropdownRender={ () => (
            dimensionType === BreakdownCategoryType.USER ||
            dimension?.canEditName
              ? <div className={ styles.contextMenu }>
                {
                  dimensionType === BreakdownCategoryType.USER && (
                    <div
                      className={ styles.dropdownElement }
                      onClick={ () => {
                        setModalDeleteDimensionItem(true);
                        setDropdown(false);
                      } }
                    >
                      <BinIcon width={ 15 } height={ 15 } className={ styles.icon }/>
                      { t('left-panel.delete-label') }
                    </div>
                  )
                }
                {
                  dimension?.canEditName && (
                    <div
                      onClick={ () => {
                        setModalEditDimensionTitle(true);
                        setDropdown(false);
                      } }
                      className={ styles.dropdownElement }>
                      <EditIcon width={ 15 } height={ 15 } className={ styles.icon }/>
                      { t('left-panel.edit-name') }
                    </div>
                  )
                }
              </div>
              : <></>
          )
          }
        >
          <div
            ref={ isTemplateBuilder && drag }
            onDoubleClick={ isTemplateBuilder ? handleDoubleClick : null }
            onMouseEnter={ isTemplateBuilder ? onMouseEnterDragTag : null }
            onMouseLeave={ isTemplateBuilder ? onMouseLeaveDragTag : null }
          >
            <Capsule isActive={ false } className={ styles.dimensionTitle }>
              { getDimensionDisplayName(dimension) }
            </Capsule>
            <DimensionViewLabel dimension={ dimension }/>
          </div>
        </Dropdown>
        {
          canAddDimensionItem && (
            <Tooltip title='Create dimension label'>
              <button className={ styles.newDimensionButton } onClick={ onClickAdd }>
                <Plus/>
              </button>
            </Tooltip>
          )
        }
      </div>
      <div className={ styles.right }>
        {
          !isLabeling && !isTemplateBuilder && (
            <SelectButton
              showSelectAll={ isAnyFiltered }
              onClick={ onClickSelectAll }
              className={ styles.selectAll }
            />
          )
        }
        {
          isLabeling && !isTemplateBuilder && dimension?.canBeUnassigned && progressBar(dimension)
        }
      </div>
    </div>
  );
};

export default BottomBar;
