import Tabs from 'components/elements/tabs/Tabs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateSections } from 'types/templates.types';
import styles from './FinancialBottomHeader.module.scss';

type Props = {
  activeSection: TemplateSections;
  setActiveSection: (section: TemplateSections) => void;
};

const FinancialBottomHeader = ({ activeSection, setActiveSection }: Props) => {
  const [ t ] = useTranslation('financials');
  const sections = useMemo(() => {
    return Object.values(TemplateSections).map((section) => ({
      label: t(`templates.sections.${ section }`),
      key: section,
    }));
  }, []);

  return (
    <Tabs
      activeKey={ activeSection }
      className={ styles.tabs }
      onChange={ (section) => setActiveSection(section as TemplateSections) }
      items={ sections }
    />
  );
};

export default FinancialBottomHeader;