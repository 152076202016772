import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import labelingService from 'services/labeling.service';
import { Period } from 'types/financials.types';
import { Cadence } from 'types/form.types';
import { Transaction } from 'types/statutory.types';
import { TableColDef } from '../../../elements/tableWrapper/types/table.types';
import Table from '../../../elements/tableWrapper/table/Table';
import { ColumnState } from 'ag-grid-community';
import useTableRenderer from '../../../elements/tableWrapper/hooks/useTableRenderer';
import useTableColumns from '../../../elements/tableWrapper/hooks/useTableColumns';

type Props = {
  ruleId: number;
};

const LabelingRulesTransactions = ({ ruleId }: Props) => {
  const [ t ] = useTranslation('labeling');

  const [ transactions, setTransactions ] = useState<Transaction[]>([]);
  const [ loading, setLoading ] = useState(false);
  const [ search, setSearch ] = useState('');
  const { renderField, amountRenderer } = useTableRenderer({ search });
  const { numberColumnDef, getDateColDef } = useTableColumns({ search });

  const [ period, setPeriod ] = useState<Period>({
    endDate: dayjs(new Date()).endOf('month').unix(),
    startDate: dayjs(new Date()).subtract(12, 'month').unix(),
    actualsOpen: true,
    cadence: Cadence.month,
    isManuallySet: false,
  });

  useEffect(() => {
    if (ruleId) {
      setLoading(true);
      labelingService.getTransactionLines(ruleId, {
        // eslint-disable-next-line camelcase
        end_date: dayjs.unix(period.endDate).format(),
        // eslint-disable-next-line camelcase
        start_date: dayjs.unix(period.startDate).format(),
      }).then(response => {
        setTransactions(response.data);
        setLoading(false);
      });
    }

  }, [ ruleId, period ]);

  const columnDefs = useMemo((): TableColDef[] => {
    return [
      {
        ...renderField('counterparty.name', t('labeling.unassigned-counterparty')),
        flex: 0,
        width: 200,
        cellDataType: 'text',
      },
      {
        ...renderField('memo', t('table.column.memo')),
        flex: 1,
        cellRendererParams: {
          group: true,
        },
        cellDataType: 'text',
      },
      {
        ...renderField('dimensionLabel', t('table.column.dimension-label')),
        flex: 0,
        width: 200,
        cellDataType: 'text',
      },
      {
        ...renderField('amount', t('table.column.amount')),
        flex: 0,
        width: 130,
        ...numberColumnDef,
        cellRenderer: amountRenderer,
        showTotal: true,
        showSubtotal: true,
      },
      {
        ...renderField('ruleType', t('table.column.rule-type')),
        flex: 0,
        width: 130,
        cellDataType: 'text',
      },
      {
        ...getDateColDef('lastApplied', t('table.column.last-applied')),
        width: 130,
      },
      {
        ...getDateColDef('transactionDate', t('table.column.transaction-date')),
        width: 200,
      },
    ];
  }, [ search ]);

  const defaultSort: ColumnState[] = useMemo(() => ([
    {
      colId: 'transactionDate',
      sort: 'desc',
      sortIndex: 0,
    }
  ]), []);

  return (
    <Table
      tableKey='labeling-rules-transactions'
      columnDefs={ columnDefs }
      rowData={ transactions }
      showSearch={ true }
      isLoading={ loading }
      name={ t('transaction-lines-table-name') }
      period={ period }
      onSearch={ setSearch }
      onPeriodChange={ setPeriod }
      defaultSort={ defaultSort }
    />
  );
};

export default LabelingRulesTransactions;
