import { PeriodSectionsMode } from '../../../../types/financials.types';
import React from 'react';
import { ColDef, ColumnState, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { Period } from 'types/financials.types';
import { TablePanelProps } from
  'components/financials/detailedView/transactionsTable/panels/types/panel.types';

export type GroupSettings = {
  primary: GroupOption;
  secondary: GroupOption;
};

export type GroupOption = {
  field: string;
  name: string;
  groupName?: string;
  availableChoices?: string[];
};

export type SortType = 'asc' | 'desc';

export enum SortIndex {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export type Action ={
  label: string;
  onClick: () => void;
  disabled?: boolean;
  icon?: React.ReactNode;
};

export type PanelSetting<T> = {
  key: string;
  button: React.ReactNode;
  additionalButton?: React.ReactNode;
  buttonPosition: number;
  render: (props: TablePanelProps<T>) => React.ReactNode;
  defaultWidth?: number;
  onActivate?: () => void;
  onDeactivate?: () => void;
};

export interface HeaderTool {
  key: string;
  button: React.ReactNode;
  buttonPosition: number;
}

export type DefaultTool = 'sort' | 'group' | 'column' | 'period';

export type TableProps<T> = AgGridReactProps<T> & {
  name: string;
  tableKey: string;
  showSearch: boolean;
  groupByOptions?: GroupOption[];
  tableStyle?: string;
  actions?: Action[];
  gridRef?: React.MutableRefObject<AgGridReact>;
  period?: Period;
  onPeriodChange?: (period: Period) => void;
  periodSections?: PeriodSectionsMode;
  disableCadence?: boolean;
  isLoading?: boolean;
  onSearch?: (search: string) => void;
  onClose?: () => void;
  blockSearch?: boolean;
  panels?: PanelSetting<T>[];
  defaultSort?: ColumnState[];
  columnDefs: TableColDef[];
  sections?: TableSection<T>[];
  activeSection?: TableSection<T>;
  changeSection?: (sectionKey: string) => void;
  redirect?: Redirect;
  headerTools?: HeaderTool[];
  disabledTools?: DefaultTool[];
  isRowValid?: (row: T) => boolean;
  onGridReady?: (params: GridReadyEvent) => void;
  isFloatingPanel?: boolean;
  isAutoSaved?: boolean;
};

export interface Redirect {
  sectionKey: string;
  rowNodeIds: number[];
  clear: () => void;
}

export interface TableSection<T> {
  key: string;
  label: string;
  tableProps: SectionTableProps<T>;
  onChange: (sectionKey: string) => void;
  disabled?: boolean;
}
export type SectionTableProps<T> = Omit<TableProps<T>,
'name' | 'showSearch' | 'defaultSectionKey' | 'sections' | 'onClose'
>;

export type TableColDef<T = {}> = ColDef<T> & {
  suppressHide?: boolean;
  group?: string;
  cellDataType?: string;
  showTotal?: boolean;
  showSubtotal?: boolean;
  compulsory?: boolean;
  sectionKey?: string;
  sourceBadge?: boolean;
};

export type TableHookProps<T, O extends {}> = {
  table: Omit<TableProps<T>, 'name' | 'showSearch'>;
  other: O;
};
