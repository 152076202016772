import cloneDeep from 'lodash/cloneDeep';

export function moveItemAtEnd<T>(array: T[], nodeId: T) {
  array.push(array.splice(array.indexOf(nodeId), 1)[ 0 ]);
}

export function mapArrayToProperty<T, K extends keyof T>(array: T[], property: K) {
  return array.map(val => val[ property ]);
}

export function insertAfter<T>(array: T[], item: T, itemBefore: T): T[] {
  const changedArray = cloneDeep(array);
  const indexOfItem = changedArray.indexOf(item);
  if (indexOfItem !== -1) {
    changedArray.splice(indexOfItem, 1);
  }
  const indexOfItemBefore = changedArray.indexOf(cloneDeep(itemBefore));
  if (indexOfItemBefore === -1) {
    changedArray.push(item);
  } else {
    changedArray.splice(indexOfItemBefore + 1, 0, item);
  }
  return changedArray;
}

export const range = (start: number, end: number) => {
  let _start = start;
  let _end = end;
  if (start > end) {
    _start = end;
    _end = start;
  }
  return Array(_end - _start + 1).fill(null).map((_, idx) => _start + idx);
};
