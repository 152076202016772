import React, { ReactNode } from 'react';
import { ReactComponent as ChevronBack } from 'assets/icons/chevron-right-dark.svg';
import styles from './Breadcrumbs.module.scss';

type Props = {
  onGoBack: () => void;
  children?: ReactNode;
  className?: string;
};

const Breadcrumbs = ({ onGoBack, children, className = '' }: Props) => {
  return (
    <div className={ `${ styles.breadcrumbs } ${ className }` }>
      <div className={ styles.backWrapper } onClick={ onGoBack }>
        <ChevronBack className={ styles.backIcon } />
      </div>
      { children }
    </div>
  );
};

export default Breadcrumbs;
