import React, { useMemo } from 'react';
import { BreakdownCategoryType, Dimension } from 'types/filterTable.types';

import styles from './DimensionViewLabel.module.scss';
import { useAppSelector } from '../../../../../store/hooks/hooks';
import { IntegrationEnum } from '../../../../../types/settings.types';

export const DimensionViewLabel = ({ dimension }: { dimension: Dimension }) => {
  if (!dimension) {
    return <></>;
  }
  const { integrations } = useAppSelector(state => state.app);

  const dimensionType = useMemo(() => {
    const isIntegrationType = [
      BreakdownCategoryType.PROCOUNTOR,
      BreakdownCategoryType.NETVISOR
    ].includes(dimension.type);

    const isIntegrationSourced = [ 'ACCOUNT', 'COUNTERPARTY' ].includes(dimension.relation);
    const integrationSourceToType = {
      [ IntegrationEnum.netvisor ]: BreakdownCategoryType.NETVISOR,
      [ IntegrationEnum.procountor ]: BreakdownCategoryType.PROCOUNTOR,
      [ IntegrationEnum.xero ]: BreakdownCategoryType.XERO,
    };

    if (integrations.length === 0) {
      return null;
    }

    if (isIntegrationType) {
      return dimension.type;
    } else if (isIntegrationSourced) {
      return integrationSourceToType[ integrations[ 0 ].type ];
    }
  }, [ dimension, integrations ]);

  if ('items' in dimension && dimensionType) {
    return <span className={ styles.dimensionLabel }>{ dimensionType }</span>;
  }

  return <></>;
};

export default DimensionViewLabel;
