import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { selectApp } from 'store/app.slice';
import { useAppSelector } from 'store/hooks/hooks';
import { getDisplayName } from 'utils/common.utils';
import { changeHotjarState } from 'utils/hotjar.utils';

const DocumentTitle = ({ children, title }) => {

  const navigationList = useAppSelector(selectApp).navigationList;
  const location = useLocation();

  useEffect(() => {
    changeHotjarState(location.pathname);
  }, [ location.pathname ]);

  const getReportSubmenu = () =>{
    if (title === null) {
      const submenu =
        navigationList.find(item =>
          item.views.find(el => `/view/${ el.id }` === location.pathname ));
      return submenu ? `Caleido - ${ getDisplayName(submenu.name) }` : 'Caleido';
    }
  };

  const titleTab = title || getReportSubmenu();

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{ titleTab }</title>
      </Helmet>
      { children }
    </>
  );
};

export default DocumentTitle;
