import React, { createContext, useContext, useReducer } from 'react';
import { Contract } from 'types/contracts.types';

type ModalContextType = {
  isAddModalVisible: boolean;
  isEditModalVisible: boolean;
  editModalItem: Contract;
};

const defaultState: ModalContextType = {
  isAddModalVisible: false,
  isEditModalVisible: false,
  editModalItem: null,
};

const ModalContext = createContext(undefined);

const contractsReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_ADD_MODAL': {
      return { ...state , isAddModalVisible: true };
    }
    case 'HIDE_ADD_MODAL': {
      return { ...state , isAddModalVisible: false };
    }
    case 'SHOW_EDIT_MODAL': {
      return { ...state , isEditModalVisible: true, editModalItem: action.payload };
    }
    case 'HIDE_EDIT_MODAL': {
      return { ...state , isEditModalVisible: false, editModalItem: null };
    }
    default: {
      throw new Error(`Unhandled action type: ${ action.type }`);
    }
  }
};

const ModalContextProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(contractsReducer, defaultState);
  const value = { state, dispatch };

  return <ModalContext.Provider value={ value }>
    { children }
  </ModalContext.Provider>;
};

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within Provider');
  }
  return context;
};

export { ModalContextProvider, useModalContext };