import { PaginatedResponse } from 'types/app.types';
import {
  CounterpartyLabelingRule,
  CounterpartyLabelingRuleCreate,
  CounterpartyLabelingRuleRemove,
  CounterpartyLabelingRuleElement
} from 'types/labeling.types';
import { Transaction } from 'types/statutory.types';
import { endpoints } from 'utils/config.utils';
import http from 'utils/http.utils';
import { LimitOffsetParams } from './params/pagination';

interface GetRulesParams extends LimitOffsetParams {
  search?: string;
}

const getRules = (params: GetRulesParams) =>
  http.get<PaginatedResponse<CounterpartyLabelingRuleElement>>(
    endpoints.labeling.rules.index, {
      params
    }
  );

interface TransactionsParams {
  start_date?: string;
  end_date?: string;
  startDatePlan?: number;
  endDatePlan?: number;
}

const getTransactionLines = (id: number, params: TransactionsParams ) =>
  http.get<Transaction[]>(
    endpoints.labeling.rules.transactionLines.replace('{id}', id.toString() ), { params }
  );

const getRule = (id: number) =>
  http.get<CounterpartyLabelingRuleElement>(
    endpoints.labeling.rules.detail.replace('{id}', id.toString())
  );

const createBulkRule = (data: CounterpartyLabelingRuleCreate[]) =>
  http.post<CounterpartyLabelingRule[]>(endpoints.labeling.rules.bulkCreate, data);

const deleteBulkRule = (ids: number[]) =>
  http.delete(endpoints.labeling.rules.bulkDelete, { data:{ ids } });

const removeDimensionRule = (data: CounterpartyLabelingRuleRemove) =>
  http.post<CounterpartyLabelingRuleRemove>(endpoints.labeling.rules.remove, data);

const labelingService = {
  getRules,
  getRule,
  deleteBulkRule,
  removeDimensionRule,
  createBulkRule,
  getTransactionLines
};

export default labelingService;
