import Message from 'components/collaboration/chat/messages/history/message/Message';
import React, { useEffect, useRef } from 'react';
import { Message as IMessage } from 'types/chat.types';
import styles from './MessageHistory.module.scss';

interface Props {
  messages: IMessage[];
}

const MessageHistory = ({ messages }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
  }, [ messages ]);

  return <div ref={ scrollRef } className={ styles.history }>
    <div className={ styles.scrollArea }>
      { messages.map(message => <Message key={ message.id } message={ message } />) }
    </div>
  </div>;
};

export default MessageHistory;