import React from 'react';
import Modal from 'components/elements/modal/Modal';
import { useTranslation } from 'react-i18next';
import { FinancialNode } from 'types/financials.types';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-bin.svg';

import styles from './ConfirmRowsDelete.module.scss';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { getDisplayName } from '../../../../utils/common.utils';

interface Props {
  isVisible: boolean;
  rows: FinancialNode[];
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmRowsDelete = ({ rows, isVisible, onClose, onConfirm }: Props) => {
  const [ t ] = useTranslation('common');

  let description = '';

  if (rows.length === 1) {
    description = t('warnings.delete-rows', { memo: rows.at(0).rowData.memo ?? '...' });
  } else {
    description = t('warnings.delete-rows-multiple', { count: rows.length });
  }

  const { accountMap } = useAppSelector((state) => state.breakdowns );

  const getAccountDisplay = (id: string) => {
    const account = accountMap[ id ];
    if (!account) return '';
    return `${ account?.number } ${ getDisplayName(account?.name) }`;
  };

  return <Modal
    isVisible={ isVisible }
    onClose={ onClose }
    onConfirm={ onConfirm }
    okText={ t('delete') }
    isDangerous
    title={ <div className={ styles.title }>
      <TrashIcon />
      { t('warnings.delete-rows-title', { count: rows.length }) }
    </div> }
    description={ description }
  >
    <div className={ styles.modalContent }>
      <table className={ styles.table }>
        <thead>
          <tr>
            <th>{ t('date') }</th>
            <th>{ t('financials:inline-edit.fields.account') }</th>
            <th>{ t('financials:inline-edit.fields.counterparty') }</th>
            <th>{ t('financials:inline-edit.fields.memo') }</th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row) => (
              <tr key={ row.id }>
                <td>{ row.rowData.startDate }</td>
                <td>{ getAccountDisplay(row.rowData?.accounts?.primary) }</td>
                <td>{ row.rowData.counterparty?.name }</td>
                <td>{ row.rowData.memo }</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  </Modal>;
};

export default ConfirmRowsDelete;
