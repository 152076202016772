import React, { useEffect, useMemo, useState } from 'react';
import { SelectProps } from 'antd';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { SearchableContextProvider, useSearchable } from '../../../context/SearchableContext';
import SearchBar from '../searchBar/SearchBar';
import styles from './SearchableSelect.module.scss';
import FlatSelect from 'components/elements/flatSelect/FlatSelect';

interface DropdownProps {
  menu: React.ReactElement;
  visible: boolean;
  callback?: (e) => void;
}

type SelectExtendedProps = SelectProps & {
  callback?: (e) => void;
};

const SelectDropdown = ({ menu, callback, visible }: DropdownProps) => {

  const ref = React.useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    if (visible && ref.current) {
      setTimeout(() => {
        ref.current.querySelector('input')?.focus();
      }, 150);
    }
  }, [ visible ]);

  return <div className={ styles.dropdown } ref={ ref }>
    <SearchBar
      showSeparator={ true }
      showResults={ false }
      autoFocus={ true }
      className={ styles.search }
      callback={ callback }
    /> 
    { menu }
  </div>;
};

// ! When using alone need to wrap in SearchableContextProvider
export const SearchableSelectWithoutSearchCtx = ({ options, ...props }: SelectExtendedProps) => {
  const { state: { search }, dispatch } = useSearchable();

  // ? Temporary fix for not focusing search input on dropdown open
  const [ isVisible, setIsVisible ] = useState(null);

  const filteredOptions = useMemo(() => {
    if (search === '') return options;
    return options?.filter((option) => {
      if (typeof option.label === 'string') {
        return option.label.toLowerCase().includes(search.trim().toLowerCase());
      } else {
        return true;
      }
    });
  }, [ options, search ]);

  const { callback, onChange, onDropdownVisibleChange, ...rest } = props;

  return <FlatSelect
    { ...rest }
    onChange={ (e, _options) => {
      if (props.autoClearSearchValue) {
        dispatch({ type: 'clear' });
      }

      onChange?.(e, _options);
    } }
    onDropdownVisibleChange={ (open) => {
      setIsVisible(open);

      onDropdownVisibleChange?.(open);
    } }
    options={ filteredOptions }
    className={ `${ styles.select } ${ props.className }` }
    showSearch={ false }
    dropdownRender={ menu => <SelectDropdown
      visible={ isVisible }
      menu={ menu }
      callback= { callback }
    /> }
    menuItemSelectedIcon={ <div className={ styles.active }>
      <CheckIcon className={ styles.icon } />
    </div> }
  />;
};

const SearchableWrapper = ({ ...props }: SelectExtendedProps) => {
  return <SearchableContextProvider>
    <SearchableSelectWithoutSearchCtx { ...props } />
  </SearchableContextProvider>;
};

export default SearchableWrapper;
