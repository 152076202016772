/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo } from 'react';
import styles from '../DynamicColumnModal.module.scss';
import { Checkbox, Switch } from 'antd';
import {
  ColumnType,
  DynamicSettings,
  DynamicSettingsConfiguration,
} from 'types/financials.types';
import { UseFormSetValue } from 'react-hook-form';
import { Cadence } from 'types/form.types';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import {
  getRenderElements,
  isElementDisabled,
  isOptionsDisabled
} from '../../dynamicColumns.utils';
import OverlappingColumnsInfo
  from 'components/financials/dynamicColumns/overlappingColumnsInfo/OverlappingColumnsInfo';
import { BVA_COLUMNS } from 'utils/grid.utils';

interface Props {
  cadence: Cadence | 'all';
  setValue: UseFormSetValue<DynamicSettings>;
  values: DynamicSettings;
  disableOptions: boolean;
}

const ConfigComponent = ({ cadence, setValue, values, disableOptions }: Props) => {
  const [ t ] = useTranslation('dynamicColumns');

  const getElementsByType = useCallback((type: ColumnType) => {
    return getRenderElements(values[ cadence ].columns, type);
  }, [ cadence, values ]);

  const elements = useMemo(() => values[ cadence ], [ cadence ]);
  const columns = useMemo(() => elements.columns, [ elements ]);

  const getActiveName = useCallback((cad: Cadence | 'all', index: number) => {
    return `${ cad }.columns.${ index }.active` as const;
  }, []);

  const getOptionsName = useCallback((cad: Cadence | 'all', index: number) => {
    return `${ cad }.columns.${ index }.options` as const;
  }, []);

  const onActiveChange = useCallback((active: boolean, index: number) => {
    if (cadence === 'all') {
      Object.values(Cadence).forEach(cad => {
        if (!isElementDisabled(cad, columns[ index ])) {
          const activeName = getActiveName(cad, index);
          setValue(activeName, active);
        }
      });
      const activeName = getActiveName('all', index);
      setValue(activeName, active);
      return;
    }

    if (isElementDisabled(cadence, columns[ index ])) return;
    const activeName = getActiveName(cadence, index);
    setValue(activeName, active);
  }, [ elements, cadence ]);

  const onOptionsChange = useCallback((options: string[], index: number) => {
    if (cadence === 'all') {
      Object.values(Cadence).forEach(cad => {
        if (!isOptionsDisabled(cad, columns[ index ])) {
          const optionsName = getOptionsName(cad, index);
          setValue(optionsName, options);
        }
      });
      const optionsName = getOptionsName('all', index);
      setValue(optionsName, options);
      return;
    } else {
      const optionsName = getOptionsName(cadence, index);
      setValue(optionsName, options);
    }

    if (isOptionsDisabled(cadence, columns[ index ])) return;
    const optionsName = getOptionsName(cadence, index);
    setValue(optionsName, options);
  }, [ elements, cadence ]);

  const isIndeterminateActive = useCallback((element: DynamicSettingsConfiguration) => {
    if (cadence !== 'all') {
      return false;
    }
    const isAnyActive = Object.values(Cadence).some(cad => {
      const elementIndex = columns.findIndex(el => el.value === element.value);
      return values[ cad ][ elementIndex ]?.active;
    });

    const isEveryActive = Object.values(Cadence).every(cad => {
      const elementIndex = columns.findIndex(el => el.value === element.value);
      return values[ cad ][ elementIndex ]?.active || isElementDisabled(cad, element);
    });

    return isAnyActive && !isEveryActive;
  }, [ values, cadence ]);

  const isIndeterminateOption =
    useCallback((element: DynamicSettingsConfiguration, option: string) => {
      if (cadence !== 'all') {
        return false;
      }
      const isAnyActive = Object.values(Cadence).some(cad => {
        const elementIndex = columns.findIndex(el => el.value === element.value);
        const options = values[ cad ][ elementIndex ]?.options;
        return options?.includes(option);
      });

      const isEveryActive = Object.values(Cadence).every(cad => {
        const elementIndex = columns.findIndex(el => el.value === element.value);
        const options = values[ cad ][ elementIndex ]?.options;
        return options?.includes(option) || isOptionsDisabled(cad, element);
      });

      return isAnyActive && !isEveryActive;
    }, [ values, cadence ]);

  const renderCheckbox = useCallback((element: DynamicSettingsConfiguration, index: number) => {
    return <>
      <Checkbox
        onChange={ (event) => {
          const active = event.target.checked;
          onActiveChange(active, index);
          if (element.optionsList) {
            onOptionsChange(active ? element.optionsList : [], index);
          }
        } }
        className={ styles.radioElement }
        value={ element.value }
        disabled={ disableOptions }
        checked={ element.active }
        indeterminate={ isIndeterminateActive(element) }
      >
        <span>
          { element.title }
        </span>
        <span className={ `${ styles.subtitle }` }>
          { element.subtitle }
        </span>
      </Checkbox>
      {
        element.optionsList ?
          <div className={ styles.subSwitchContainer }>
            {
              element.optionsList.map(option => (
                <Checkbox
                  key={ `option__${ option }` }
                  onChange={ () => {
                    const options = element.options.includes(option) ?
                      element.options.filter(opt => opt !== option) :
                      [ ...element.options, option ];
                    onOptionsChange(options, index);
                    if (options.length === 0) {
                      onActiveChange(false, index);
                    }
                  } }
                  disabled={ disableOptions ? true : !element.active }
                  value={ option }
                  checked={ element.options.includes(option) }
                  indeterminate={ isIndeterminateOption(element, option) }
                >
                  { option }
                </Checkbox>
              ))
            }
          </div>
          : null
      }
    </>;
  }, [ onActiveChange, onOptionsChange, isIndeterminateOption, disableOptions ]);

  const isBvaSelected = useMemo(() => {
    const cadenceValues = values[ cadence ];
    return cadenceValues.columns.some(
      (element) => element.active && BVA_COLUMNS.includes(element.value)
    );
  }, [ values, cadence ]);

  const onChangeConditionalFormatting = useCallback((value: boolean) => {
    setValue(`${ cadence }.conditionalFormatting`, value);
  }, [ cadence ]);

  return (
    <>
      <div className={ styles.configContainer }>
        <div className={ styles.infoContainer }>
          {
            cadence === 'all' ?
              t('modal.info-plural') :
              t('modal.info', { cadence: t(`common:cadence.${ cadence }`).toLowerCase() })
          }
        </div>
        <div className={ styles.conditionalFormatting }>
          <span className={ styles.text }>
            { t('conditionalFormatting') }
          </span>
          <Switch
            checked={ elements.conditionalFormatting }
            onChange={ onChangeConditionalFormatting }
          />
        </div>
        {
          Object.values(ColumnType).map(type => (
            <div key={ `type__${ type }` } className={ styles.group }>
              <div className={ styles.titleContainer }>
                { capitalize(t(`columnType.${ type }`)) }
              </div>
              {
                getElementsByType(type).map((element, i) => {
                  const columnType = element.columnType;
                  const elementIndex = columns.findIndex(el => el.value === element.value);
                  return <div
                    key={ `${ cadence }-${ columnType }-${ i }` }
                    className={
                      cadence !== 'all' && isElementDisabled(cadence, element)
                        ? styles.disabled : ''
                    }
                  >
                    <div key={ `${ cadence }-${ columnType }-${ i }-${ element.id }` }>
                      <div className={ styles.rowContainer }>
                        {
                          renderCheckbox(element, elementIndex)
                        }
                      </div>
                    </div>
                  </div>;
                })
              }
              <OverlappingColumnsInfo
                display={ type === ColumnType.BUDGET_VARIANCE && isBvaSelected }
              />
            </div>
          ))
        }
      </div>
    </>
  );
};

export default ConfigComponent;
