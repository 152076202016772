import React from 'react';
import Button from 'components/elements/button/Button';
import { useTranslation } from 'react-i18next';
import { FilterList } from 'types/filterTable.types';
import styles from './FinancialFooter.module.scss';
import { isEqual } from 'lodash';
import { defaultFilter } from '../../../../utils/financials.utils';

interface Props {
  filter: FilterList;
  stagingFilter: FilterList;
  setFilters: (filter: FilterList) => void;
}

const DetailedViewFooter = ({
  filter,
  stagingFilter,
  setFilters,
}: Props) => {
  const [ t ] = useTranslation('common');

  const stagingIsClear = isEqual(filter, stagingFilter);
  const canClearFilters = !isEqual(filter, defaultFilter);

  return <div className={ `${ styles.footer } ${ styles.detailedViewFooter }` }>
    {
      canClearFilters && (
        <Button
          onClick={ () => setFilters(defaultFilter) }
          type='link'
        >
          { t('form.clear-all-filters') }
        </Button>
      )
    }
    <Button
      disabled={ stagingIsClear }
      onClick={ () => setFilters(stagingFilter) }
      className={ `${ styles.applyButton }` }
      name={ t('form.apply') }
    />
  </div>;
};

export default DetailedViewFooter;
