import React, { useCallback, useMemo } from 'react';
import type { ContentProps } from './types/templateDropdown.types';
import { Checkbox, DropdownProps as AntdDropdownProps } from 'antd';
import styles from './TemplateDropdown.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { selectAllTemplates, templatesSlice } from '../../../store/templates.slice';
import { TemplateType, UserTemplateShort } from '../../../types/templates.types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NewBrowser } from 'assets/icons/newBrowser.svg';
import SectionDropdown from '../sectionDropdown/SectionDropdown';
import {
  DropdownGroup,
  DropdownSection, ItemRenderer, RowHoverAction,
} from '../sectionDropdown/types/sectionDropdown.types';
import HighlightedText from '../highlightedText/HighlightedText';
import { ReactComponent as StarIcon } from 'assets/icons/star.svg';
import { ReactComponent as StarFilledIcon } from 'assets/icons/star-filled.svg';
import { ReactComponent as StarExitIcon } from 'assets/icons/star-exit.svg';
import { reportsService } from '../../../services/reports.services';
import { routes } from 'utils/config.utils';
import { INPUT_TEMPLATE_ID } from 'components/inputs/utils/inputs.utils';

type DropdownProps = ContentProps & AntdDropdownProps & {
  showOther?: boolean;
  children: React.ReactNode;
};

const TemplateDropdown = (
  {
    isSelected,
    onSelect,
    isDisabled,
    rowHoverActions,
    multiSelect,
    showOther = true,
    ...rest
  }: DropdownProps) => {
  const [ t ] = useTranslation('financials');
  const storeTemplates = useAppSelector(selectAllTemplates);
  const dispatch = useAppDispatch();

  const input: UserTemplateShort = useMemo(() => ({
    id: null,
    title: t('financials:template-dropdown.element.input'),
    recordTitle: null,
    subtitle: 'yes',
    type: TemplateType.INPUTS,
    favorite: false,
  }), []);

  const templates = useMemo(() => [ ...storeTemplates, input ], [ storeTemplates ]);

  const templateSections: DropdownSection<UserTemplateShort>[] = useMemo(() => {
    const sections = [
      {
        key: 'selected',
        label: t('template-dropdown.tab.selected'),
        items: templates.filter(template => isSelected(template.id)),
        hidden: !multiSelect,
      },
      {
        key: 'favorites',
        label: t('template-dropdown.tab.favorites'),
        items: templates.filter(template => template.favorite),
        icon: <StarFilledIcon/>
      },
      {
        key: 'default',
        label: t('template-dropdown.tab.default'),
        items: templates.filter(template => template.type !== TemplateType.USER),
      },
      {
        key: 'user',
        label: t('template-dropdown.tab.user'),
        items: templates.filter(template => template.type === TemplateType.USER),
      },
    ];

    if (showOther) {
      sections.push(
        {
          key: 'other',
          label: t('template-dropdown.tab.other'),
          items: [ input ]
        });
    }

    return sections;
  }, [ isSelected, templates ]);

  const templateGroups: DropdownGroup<UserTemplateShort>[] = useMemo(() => {
    return [
      {
        key: 'all',
        label: t('template-dropdown.tab.all'),
        sections: templateSections,
      },
      ...templateSections.map(section => ({
        key: section.key,
        label: section.label,
        hidden: section.hidden,
        icon: section.icon,
        sections: [ section ],
      }))
    ];
  }, [ templateSections ]);

  const itemRenderer: ItemRenderer = useCallback(({ item, active, search, disabled }) => {
    const template = templates.find(_temp => _temp.id === item.id);
    return <>
      {
        multiSelect ? <Checkbox checked={ active } disabled={ disabled }/> : null
      }
      <h5><HighlightedText text={ template.title } highlight={ search }/></h5>
      {
        template.favorite ? <StarFilledIcon/> : null
      }
      {
        template.type === TemplateType.SYSTEM_DEFAULT ? <span className={ styles.systemDefault }>
          { t('template-dropdown.element.default') }
        </span> : null
      }
    </>;
  }, [ templates ]);

  const baseActions: RowHoverAction[] = useMemo(() => {
    return [
      {
        getIcon: (id: number) => {
          const template = templates.find(_temp => _temp.id === id);
          return template?.favorite ? <StarExitIcon className={ styles.bigIcon }/> : <StarIcon/>;
        },
        onClick: (id: number) => reportsService.toggleFavoriteTemplate(id)
          .then(() => {
            const template = templates.find(_temp => _temp.id === id);
            dispatch(templatesSlice.actions.setFavoriteTemplate({
              template: id,
              favorite: !template?.favorite
            }));
          })
      }
    ];
  }, [ templates ]);

  const hoverActions = useCallback((id: number | null) => {
    if (id == null) {
      return [
        {
          getIcon: () => <NewBrowser/>,
          onClick: () => {
            window.open(`${ routes.financialView }?template=${ INPUT_TEMPLATE_ID }`, '_blank');
          }
        },
      ];
    }

    return [
      ...baseActions,
      ...rowHoverActions
    ];
  }, [ baseActions, rowHoverActions ]);

  return <SectionDropdown
    isSelected={ isSelected }
    onSelect={ onSelect }
    isDisabled={ isDisabled }
    rowHoverActions={ hoverActions }
    groups={ templateGroups }
    total={ templates.length }
    itemRenderer={ itemRenderer }
    { ...rest }
  />;
};

export default TemplateDropdown;
