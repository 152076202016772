import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

import styles from './DynamicScrollableContainer.module.scss';

interface Props {
  className?: string;
  scrollContainerProps?: React.HTMLProps<HTMLDivElement>;
  innerScrollContainerProps?: React.HTMLProps<HTMLDivElement>;
  otherScrollRef?: React.RefObject<HTMLDivElement>;
  innerScrollRef?: React.RefObject<HTMLDivElement>;
}

const DynamicScrollableContainer = ({
  children,
  className = '',
  otherScrollRef,
  innerScrollRef,
  scrollContainerProps = {},
  innerScrollContainerProps = {}
}: PropsWithChildren<Props>) => {
  return (<>
    <div
      className={ clsx(styles.scrollOuterContainer, className) }
      ref={ otherScrollRef }
      { ...scrollContainerProps }
    >
      <div
        className={ styles.scrollContentContainer }
        ref={ innerScrollRef }
        { ...innerScrollContainerProps }
      >
        { children }
      </div>
    </div>
  </>
  );
};

export default DynamicScrollableContainer;
