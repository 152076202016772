export const autoGroupColumnDef = (innerRenderer, styles) => ({
  pinned: true,
  flex: 1,
  minWidth: 300,
  maxWidth: 600,
  resizable: true,
  cellClass: styles.rowTitle,
  autoHeight: true,
  cellRendererParams: {
    suppressCount: true,
    innerRenderer: innerRenderer,
  },
  headerComponent: () => null,
});

export const defaultColDef = (styles) => ({
  type: 'rightAligned',
  aggFunc: null,
  cellClass: styles.cell,
  width: 100,
});

export const columnTypes = () => ({
  valueColumn: {
    aggFunc: null,
  },
});
