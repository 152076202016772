import { capitalize } from 'lodash';
import React from 'react';
import { useDragLayer } from 'react-dnd';
import { getNodeName } from 'utils/template.utils';
import styles from './TemplateRow.module.scss';
import { useAppSelector } from '../../../store/hooks/hooks';

const CustomDragLayerRow = () => {
  const {
    itemType,
    isDragging,
    item,
    currentOffset
  } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }));
  const dimensions = useAppSelector(state => state.breakdowns.dimensions);

  if (!isDragging) {
    return null;
  }

  if (itemType === 'node' && currentOffset && isDragging ) {
    return (
      <div
        style={ { transform: `translate(${ currentOffset.x }px, ${ currentOffset.y }px)`, } }
        className={ styles.dragLayer }
      >
        { getNodeName(item, dimensions) || capitalize(item.type) }
      </div>
    );
  } else return null;

};

export default CustomDragLayerRow;