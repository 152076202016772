import { intersect } from 'utils/common.utils';
import { DynamicSettingsConfiguration } from 'types/financials.types';
import { CadenceColumnsSettings, DynamicSettings, ColumnType } from 'types/financials.types';
import { Cadence } from 'types/form.types';
import { configModalElements, configDropdownElements } from './config';

type DynamicColumnDisplayType = 'modal' | 'dropdown';

export const getElements = (cadence: Cadence | 'all', type: DynamicColumnDisplayType): {
  columns: DynamicSettingsConfiguration[];
} => {
  const configElements = type === 'modal' ? configModalElements : configDropdownElements;

  if (cadence === 'all') {
    return {
      columns: configElements(Cadence.month)
    };
  }
  return {
    columns: configElements(cadence)
  };
};

export const getRenderElements = (elements: DynamicSettingsConfiguration[], type: ColumnType) => {
  return elements.filter(c => c.columnType === type);
};

export const mapDynamicSettings =
  (settings: DynamicSettings): CadenceColumnsSettings => {

    const cadenceSettings = {};
    Object.values(Cadence).forEach(cadence => {
      const activeColumns = settings[ cadence ]?.columns.filter(
        c => c.active && !isElementDisabled(cadence, c)
      );

      cadenceSettings[ cadence ] = {
        columns: activeColumns.map(c => ({
          type: c.value,
          options: c.options ?? null
        })) || [],
        conditionalFormatting: settings[ cadence ]?.conditionalFormatting ?? false
      };
    });
    return cadenceSettings as unknown as CadenceColumnsSettings;
  };

export const isElementDisabled =
  (cad: Cadence, element: DynamicSettingsConfiguration) => {
    return element.disabledCadences?.includes(cad);
  };

export const isOptionsDisabled =
  (cad: Cadence, element: DynamicSettingsConfiguration) => {
    return isElementDisabled(cad, element) || !element.optionsList;
  };

const getCommonOptions = (
  element: DynamicSettingsConfiguration,
  settings: CadenceColumnsSettings
): string[] => {
  if (!element.optionsList) {
    return [];
  }
  let options: string[] = element.optionsList;
  Object.values(Cadence).forEach(cadence => {
    const settingElement = settings[ cadence ]?.columns.find(c => c.type === element.value);
    if (settingElement) {
      options = intersect(options, settingElement.options);
    }
  });

  return options;
};

export const mapColumnSettings = (
  settings: CadenceColumnsSettings,
  type: DynamicColumnDisplayType
) => {
  const dynamicSettings = {};
  if (!settings) {
    return null;
  }

  for (const cadence of Object.values(Cadence)) {
    dynamicSettings[ cadence ] = {
      columns: getElements(cadence, type).columns.map(el => {
        const element = settings[ cadence ]?.columns.find(c => c.type === el.value);
        const options = element?.options || [];
        const active = element != null;
        return {
          ...el,
          active,
          options,
        };
      }),
      conditionalFormatting: settings[ cadence ]?.conditionalFormatting || false
    };
  }
  dynamicSettings[ 'all' ] = {
    columns: getElements('all', type).columns.map((el) => {
      const isActive = Object.values(Cadence)
        .every(cad => {
          const cadenceElement = dynamicSettings[ cad ].columns.find(c => c.value === el.value);
          return cadenceElement?.active || isElementDisabled(cad, el);
        });
      return {
        ...el,
        active: isActive,
        options: isActive ? getCommonOptions(el, settings) : []
      };
    }),
    conditionalFormatting: Object.values(Cadence).every(cad => {
      return dynamicSettings[ cad ].conditionalFormatting;
    })
  };

  return dynamicSettings as unknown as DynamicSettings;
};
