import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

type DeviceConfig = {
  isTabletDown: boolean;
  isDesktop: boolean;
};

const getDeviceConfig = (width): DeviceConfig => ({
  isTabletDown: width <= 768,
  isDesktop: width > 992
});

const useBreakpoint = () => {
  const [ breakpoint, setBreakpoint ] = 
    useState(() => getDeviceConfig(window.innerWidth));
  
  useEffect(() => {
    const calcInnerWidth = debounce(function() {
      setBreakpoint(getDeviceConfig(window.innerWidth));
    }, 200); 
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakpoint;
};

export default useBreakpoint;
