import { AxiosResponse } from 'axios';
import React, { createContext, useContext, useReducer } from 'react';
import type { Dimension } from 'types/filterTable.types';
import type { Period, TableTitle } from 'types/financials.types';
import type { PanelType } from 'types/app.types';
import type { UserReport } from 'types/templates.types';
import type { AssignFunction } from 'components/elements/dimensionLeftPanel/labelingTypes';
import type { ChartSettings } from 'types/chart.types';

type DetailedViewSettings = {
  disabled: boolean;
  floatingPanelDisabled: boolean;
};

type FinancialTableType = 'reports' | 'dashboard' | 'modal';

type FinancialTableAction =
  | { type: 'setTemplateId'; payload: number }
  | { type: 'changePopover' }
  | { type: 'setModalDynamicColumn' }
  | { type: 'setDimensions'; payload: Dimension[] }
  | { type: 'setTitle'; payload: { title?: string; subtitle?: string } }
  | { type: 'setAssignFunction'; payload: AssignFunction }
  | { type: 'setChartVisible'; payload: boolean }
  | { type: 'setChartSettings'; payload: ChartSettings }
  | { type: 'setType'; payload: FinancialTableType};

type FinancialTableContextValues = {
  state: FinancialTableContextType;
  dispatch: React.Dispatch<FinancialTableAction>;
};

type PanelSettings = {
  defaultOpen: boolean;
  disabled: boolean;
  panelDisabled: boolean;
  isCloseable: boolean;
  className?: string;
  onOpen: (type: PanelType) => void;
};

export type CustomSettings = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getReport: (period: Period, signal: AbortSignal) => Promise<AxiosResponse<UserReport, any>>;
  panelSettings: {
    chart: PanelSettings;
    filterLabel: PanelSettings;
    plan: PanelSettings;
    onlyDimensionIds: number[];
    customerId: number | null;
  };
  detailedViewSettings: DetailedViewSettings;
  classNames: {
    card: string;
  };
  isDynamicColumn: boolean;
  disableStickyHeader: boolean;
  adjustHeight: boolean;
  type: FinancialTableType;
};

const defaultPanelSettings: PanelSettings = {
  defaultOpen: false,
  disabled: false,
  panelDisabled: false,
  isCloseable: true,
  className: '',
  onOpen: undefined
};

export const defaultCustomSettings: CustomSettings = {
  getReport: null,
  panelSettings: {
    chart: defaultPanelSettings,
    filterLabel: defaultPanelSettings,
    plan: defaultPanelSettings,
    onlyDimensionIds: [],
    customerId: null,
  },
  detailedViewSettings: {
    disabled: false,
    floatingPanelDisabled: false,
  },
  classNames: {
    card: ''
  },
  isDynamicColumn: true,
  adjustHeight: true,
  disableStickyHeader: false,
  type: 'reports',
};

type FinancialTableContextType = {
  showPopover: boolean;
  showModalDynamicColumn: boolean;
  templateId: number;
  title: TableTitle;
  customSettings: CustomSettings;
  chartVisible: boolean;
  chartSettings: ChartSettings | null;
  onClose?: () => void;
};

const defaultState = (
  templateId: number,
  customSettings: CustomSettings,
  onClose?: () => void
): FinancialTableContextType => ({
  showPopover: false,
  showModalDynamicColumn: false,
  templateId,
  customSettings,
  title: {
    title: '',
    subtitle: '',
  },
  chartVisible: false,
  chartSettings: null,
  onClose
});

const FinancialTableContext = createContext<FinancialTableContextValues>(undefined);

const financialTableReducer = (
  state: FinancialTableContextType, action: FinancialTableAction): FinancialTableContextType => {
  switch (action.type) {
    case 'setTemplateId': {
      return { ...state, templateId: action.payload };
    }
    case 'changePopover': {
      return { ...state, showPopover: !state.showPopover };
    }
    case 'setModalDynamicColumn': {
      return { ...state, showModalDynamicColumn: !state.showModalDynamicColumn };
    }
    case 'setTitle': {
      const title = action.payload.title ?? state.title.title;
      const subtitle = action.payload.subtitle ?? state.title.subtitle;
      return {
        ...state,
        title: {
          title,
          subtitle
        }
      };
    }
    case 'setChartVisible': {
      if (action.payload === false) {
        return {
          ...state,
          chartVisible: action.payload,
          chartSettings: null
        };
      }
      return {
        ...state,
        chartVisible: action.payload
      };
    }
    case 'setChartSettings': {
      return {
        ...state,
        chartSettings: action.payload
      };
    }
    case 'setType': {
      return {
        ...state,
        customSettings: {
          ...state.customSettings,
          type: action.payload
        }
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${ action.type }`);
    }
  }
};

type Props = {
  children: React.ReactNode;
  templateId: number;
  customSettings?: CustomSettings;
  onClose?: () => void;
};

const FinancialTableContextProvider =
  ({ children, templateId, customSettings = defaultCustomSettings, onClose }: Props) => {
    const [ state, dispatch ] = useReducer(
      financialTableReducer,
      defaultState(templateId, customSettings, onClose)
    );
    const value = { state, dispatch };

    return <FinancialTableContext.Provider value={ value }>
      { children }
    </FinancialTableContext.Provider>;
  };

const useFinancialTable = () => {
  const context = useContext(FinancialTableContext);
  if (context === undefined) {
    throw new Error('useFinancialTable must be used within Provider');
  }
  return context;
};

export { FinancialTableContextProvider, useFinancialTable, FinancialTableContext };
