import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks/hooks';

const Redirect: React.FC = () => {

  const navigate = useNavigate();
  const navigationList = useAppSelector(state => state.app.navigationList);

  useEffect(() => {
    const createURL = (
      id: number,
    ) => `/view/${ id }`;

    for (const section of navigationList) {
      for (const item of section.views) {
        if (item.default) {
          navigate(item.url || createURL(item.id));
        }
      }
    }

  }, [ navigationList ]);

  return null;
};

export default Redirect;
