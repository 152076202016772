import React from 'react';
import { CounterpartyType } from 'types/revenueRecognition.types';

interface Props{
  name: string;
  type: CounterpartyType;
  size: number;
  className: string;
}

const DefaultLogo = ({ name, type, size, className }:Props) => {

  const getName = (ownerName) => {
    const firstLetters = ownerName
      .split(' ')
      .map(word => word[ 0 ])
      .join('');

    if (type === CounterpartyType.CUSTOMER || type === CounterpartyType.SUPPLIER)
      return name[ 0 ];
    else return firstLetters.slice(0,2);
  };

  const getBackground = (ownerType) => {
    if (ownerType === CounterpartyType.CUSTOMER)
      return '#A5E0C4';
    else if (ownerType === CounterpartyType.SUPPLIER)
      return '#FFD2A8';
    else return '#EBC7E3';
  };

  return <div 
    style={ { background: getBackground(type), minWidth: size, minHeight: size, } } 
    className={ className }>
    { getName(name) }
  </div>;
};

export default DefaultLogo;