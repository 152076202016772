import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useReactWebsocket from 'react-use-websocket';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { websockets, websocketUrl } from '../../utils/config.utils';
import { WSMessage, WSTypes } from '../types/events.types';
import { getWebsocketToken } from '../../services/auth.service';
import useBudgetMessage from './useBudgetMessage';
import { appActions, fetchAndSetIntegrations } from 'store/app.slice';

const MAX_RECONNECT_TIME_IN_SECONDS = 60_000;
const BASE_RECONNECT_TIME_IN_SECONDS = 1_000;

const useWebsocket = () => {
  const organization = useAppSelector(state => state.auth.organization);
  const { integrations } = useAppSelector(state => state.app);
  const { processMessage } = useBudgetMessage();
  const [ token, setToken ] = useState<string>();
  const prevToken = useRef<string>();
  const reconnectAttempt = useRef<number>(0);
  const dispatch = useAppDispatch();

  const url = useMemo(() => {
    return `${ websocketUrl }${ websockets.events.replace('{id}', organization.id.toString()) }`;
  }, [ organization ]);
  const shouldConnect = useMemo(() => {
    return token != null;
  }, [ token ]);

  const onOpen = useCallback(() => {
    console.info('Websocket connection established');
  }, []);

  useEffect(() => {
    if (!organization || token != null) return;
    
    getWebsocketToken().then(res => {
      setToken(res.data.token);
      prevToken.current = res.data.token;
    }).catch(() => {
      console.error('Failed to get websocket token');
    });
  }, [ organization, token ]);

  const getReconnectInterval = useCallback((attempt: number) => {
    const delay = Math.min(Math.pow(1.5, attempt) *
      BASE_RECONNECT_TIME_IN_SECONDS, MAX_RECONNECT_TIME_IN_SECONDS);
    const jitter = Math.random() * delay;
    return delay + jitter;
  }, []);

  const setTokenDelayed = useCallback(() => {
    const delayTime = getReconnectInterval(reconnectAttempt.current);
    setTimeout(() => {
      reconnectAttempt.current += 1;
      setToken(null);
    }, delayTime);
  }, []);

  const { lastMessage } = useReactWebsocket(
    url,
    {
      queryParams: { 'token': `${ token }` },
      onOpen,
      onError: () => setTokenDelayed(),
      shouldReconnect: () => true,
    },
    shouldConnect,
  );

  const handleMessage = useCallback(async (message: WSMessage) => {
    switch (message.type) {
      case WSTypes.BUDGET:
        processMessage(message);
        return;
      case WSTypes.SYNCHRONIZATION_PROGRESS:
        if (integrations.length === 0) {
          await dispatch(fetchAndSetIntegrations(organization.id));
        }
        dispatch(appActions.setIntegrationInitialSyncState(message.event));

        return;
      default:
        console.info('Unknown type of message: ', message);
    }
  }, [ processMessage ]);

  useEffect(() => {
    if (lastMessage == null) return;
    handleMessage(JSON.parse(lastMessage.data));
  }, [ lastMessage ]);

  return {
    lastMessage,
  };
};

export default useWebsocket;
