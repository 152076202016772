import React, { useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

interface Props {
  gridRef: React.RefObject<AgGridReact>;
  enabled: boolean;
}

const DEFAULT_HEIGHT = 630;
const DATEPICKER_HEIGHT = 290;
const ROW_HEIGHT = 35;
const HEADER_HEIGHT = 60;
const PADDING_BOTTOM = 20;

const useAutoHeight = ({ gridRef, enabled }: Props) => {
  const [ height, setHeight ] = useState<number>(DEFAULT_HEIGHT);

  const getTableHeight = useCallback(() => {
    if (!enabled) {
      setHeight(null);
      return;
    }
    const displayedRowCount = gridRef.current.api.getDisplayedRowCount();
    const pinnedTopRowData = gridRef.current.api.getPinnedTopRowCount();
    const pinnedBottomRowData = gridRef.current.api.getPinnedBottomRowCount();

    const rowCount = displayedRowCount + pinnedTopRowData + pinnedBottomRowData;
    const _height = rowCount * ROW_HEIGHT + HEADER_HEIGHT + PADDING_BOTTOM;
    setHeight(_height < DEFAULT_HEIGHT ? _height + DATEPICKER_HEIGHT : _height);
  }, [ enabled ]);

  return { height, getTableHeight };
};

export default useAutoHeight;
