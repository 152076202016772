import { notification } from 'antd';
import i18n from 'locales/i18n';
import { AxiosError } from 'axios';
import React from 'react';
import { NotificationPlacement } from 'antd/lib/notification';

const { t } = i18n;
const DONT_CLOSE_AUTO_TIME = 0;

const notify = (
  type: string,
  message: string | React.ReactFragment,
  description?: string | React.ReactFragment,
  duration = 2,
  maxCount = null,
  icon?: React.ReactFragment,
  closeIcon?: React.ReactFragment,
  placement: NotificationPlacement = 'topRight',
) => {
  notification[ type ]({
    message:  message,
    description:  description,
    duration: duration,
    maxCount: maxCount,
    icon: icon,
    closeIcon: closeIcon,
    placement
  });
};

export const notifySuccess = (message: string, description?: string) => {
  notify('success', message, description);
};

export const notifyError = (message: string, description?: string) => {
  notify('error', message, description);
};

export const notifyInfo = (message: string, description?: string) => {
  notify('info', message, description);
};

export const notifyUnexpectedError = (e: Error) => {
  console.error(e);
  notify('error', t('notifications.unexpected-error.message', { ns: 'common' }), );
  throw e;
};

export const notifyOrganizationChanged = () => {
  notify(
    'warn',
    t('common:notifications.organization-changed-warning.title'),
    t('common:notifications.organization-changed-warning.description'),
    DONT_CLOSE_AUTO_TIME,
    null,
    null,
    null,
    'top'
  );
};

export const notifyBudgetGenerationErrors = () => {
  notify(
    'warn',
    t('common:notifications.budget-generation-error.message'),
    t('common:notifications.budget-generation-error.description'),
    DONT_CLOSE_AUTO_TIME,
    1,
  );
};

export const notifyBackendValidationErrors = (e: AxiosError) => {
  for (const msg of Object.values(e.response.data)) {
    notifyError(
      msg as string
    );
  }
};
