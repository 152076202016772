import React from 'react';
import styles from './Switcher.module.scss';
import Tooltip from '../tooltip/Tooltip';

interface Options {
  value: SwitchValue;
  label: React.ReactNode;
  tooltip?: string;
}

type SwitchValue = string | number;

interface Props {
  options: Options[];
  value: SwitchValue;
  onChange: (value: SwitchValue) => void;
  className?: string;
}

const Switcher = ({ options, value, onChange, className = '' }: Props) => {
  return (
    <div className={ `${ styles.switcher } ${ className }` }>
      {
        options.map((option) => (
          <Tooltip title={ option.tooltip } key={ `switcher__${ option.value }` }>
            <button
              type='button'
              onClick={ () => {
                if (option.value !== value) onChange(option.value);
              } }
              className={ value === option.value ? styles.active : '' }

            >
              <span className={ styles.label }>
                { option.label }
              </span>
            </button>
          </Tooltip>
        ))
      }
    </div>
  );
};

export default Switcher;
