import React, { PropsWithChildren, useState } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import {
  Active, DndContext, DragOverlay, Over, PointerSensor, useSensor, useSensors
} from '@dnd-kit/core';

interface ReorderFieldsProps {
  visibleFields: string[];
  setSavedFields: (fields: string[]) => void;
}

const ReorderFields = (
  { visibleFields, children, setSavedFields }: PropsWithChildren<ReorderFieldsProps>
) => {

  const [ activeId, setActiveId ] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    })
  );

  const handleDragEnd = async ( { active, over }: { active: Active | null; over: Over | null } ) =>{
    if (!(over && active )) return;
    if ( active.id !== over.id) {

      const oldIndex = visibleFields.findIndex((item) => item === active.id);
      const newIndex = visibleFields.findIndex((item) => item === over.id);
      const orderedTemplateList = arrayMove(visibleFields, oldIndex, newIndex);

      setSavedFields(orderedTemplateList);
    }
  };

  return (
    <DndContext
      modifiers={ [ restrictToParentElement ] }
      sensors={ sensors }
      onDragEnd={ handleDragEnd }
      onDragStart={ (e) => setActiveId(e.active.id) }
      onDragOver={ () => setActiveId(null) }
      onDragCancel={ () => setActiveId(null) }
    >
      { children }
      <DragOverlay
        dropAnimation={ {
          duration: 1,
          easing: 'linear',
        } }
      >
        {
          activeId && (
            <div style={ { opacity: 0 } } >
              <div>overlay</div>
            </div>
          )
        }
      </DragOverlay>
    </DndContext>
  );
};

export default ReorderFields;