import React from 'react';

import styles from './HighlightedText.module.scss';

type Props = {
  text: string;
  highlight: string;
  className?: string;
};

const escapeRegExp = (str: string): string => {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
};

const HighlightedText = ({ text, highlight, className='' }: Props) => {
  if (!highlight?.trim()) {
    return <span className={ `${ styles.highlightedText } ${ className }` }>{ text }</span>;
  }
  const regex = new RegExp(`(${ escapeRegExp(highlight) })`, 'gi');
  const parts = text?.toString().split(regex);

  return (
    <span
      className={ `${ styles.highlightedText } ${ className }` }>
      { parts?.filter(String).map((part, i) => {
        return regex.test(part) ? (
          <mark key={ `part__${ i }` }>{ part }</mark>
        ) : (
          <span key={ `part__${ i }` }>{ part }</span>
        );
      }) }
    </span>
  );
};

export default HighlightedText;
