import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

import styles from './EmptySearchResult.module.scss';

const EmptySearchResult = () => {
  return (
    <div className={ styles.searchResults }>
      <SearchIcon className={ styles.icon } />
      <p>No results to show.</p>
      <p>Try typing different text.</p>
    </div>
  );
};

export default EmptySearchResult;