import HighlightedText from 'components/elements/highlightedText/HighlightedText';
import { useSearchable } from 'context/SearchableContext';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import {
  PlanSettings,
  GeneralSettings,
} from 'types/planning.types';
import IncrementalButton from
  'components/financials/panels/planDriverPanel/incrementalButton/IncrementalButton';
import styles from
  'components/financials/panels/planDriverPanel/driversGroup/DriversGroup.module.scss';
import HighlightedParagraph from 'components/elements/highlightedParagraph/HighlightedParagraph';
import dayjs from 'dayjs';
import InputCalendar from 'components/elements/inputCalendar/InputCalendar';
import { InputNumber } from 'antd';
import { DriversCategory } from 'types/financials.types';

type Props = {
  group: keyof PlanSettings;
  showGroupName: boolean;
  groupName: string;
};

type KeysOfPlanSettings =
  | keyof GeneralSettings;

const DriversGroup = ({ group, showGroupName, groupName }: Props) => {
  const { control } = useFormContext<PlanSettings>();
  const values = useWatch({ control });
  const [ infoRow, setInfoRow ] = useState(null);
  const { state: { search } } = useSearchable();
  const groupValues = useMemo(() => values[ group ], [ values, group ]);

  const inputFormatter = useCallback((key: KeysOfPlanSettings) => {
    if (key.includes('MonthDay')) {
      return (val, { userTyping }) => userTyping ? val : `${ val }.`;
    }
    if (key.includes('MonthOffset')) {
      return (val, { userTyping }) => !userTyping && val > 0 ? `+${ val }` : val;
    }
    if (key.includes('Percent')) {
      return (val, { userTyping }) => userTyping ? val : `${ val }%`;
    }
    return null;
  }, []);

  const onIncrement = useCallback((
    value: number,
    key: KeysOfPlanSettings,
    onChange: (val) => void
  ) => {
    const currentVal = Number(value);
    if (key === 'planStart')
      onChange(dayjs(value).subtract(1,'month'));
    else if (currentVal >= 1)
      onChange(+currentVal - 1);
  }, []);

  const onDecrement = useCallback((
    value: number,
    key: KeysOfPlanSettings,
    onChange: (val) => void
  ) => {
    if (key === 'planStart') {
      onChange(dayjs(value).add(1,'month'));
    } else {
      const currentVal = Number(value);
      onChange(+currentVal + 1);
    }
  }, []);

  const renderInput = (
    onChange: (val) => void,
    value,
    key: KeysOfPlanSettings,
    isError: boolean
  ) => {
    return <div className={ `${ styles.inputs }` }>
      <IncrementalButton
        onClick={ () => onIncrement(value, key, onChange) }
        className={ styles.incrementalButton }
        isIncremental={ false }
      />
      {
        key !== 'planStart' ? (
          <InputNumber
            value={ value }
            onChange={ onChange }
            className={ styles.input }
            formatter={ inputFormatter(key) }
          />
        ) : (
          <InputCalendar
            valueDate={ dayjs(value) }
            className={ styles.input }
            control={ control }
          />
        )
      }
      {
        isError ? <WarningIcon className={ styles.warningIcon } /> : null
      }
      <IncrementalButton
        onClick={ () => onDecrement(value, key, onChange) }
        className={ styles.incrementalButton }
        isIncremental={ true }
      />
    </div>;
  };

  const getFilteredGroupValues = () => {
    if (search === '') {
      return Object.entries(groupValues);
    }

    return Object.entries(groupValues).filter(([ , val ]) => {
      return val.name.toLowerCase().includes(search.toLowerCase()) ||
        val.description?.toLowerCase().includes(search.toLowerCase());
    });
  };

  const changeDisplayedInfo = (name: string) => {
    if (infoRow === name) {
      setInfoRow(null);
    } else {
      setInfoRow(name);
    }
  };

  const getControllerName = useCallback((key: KeysOfPlanSettings) => {
    return {
      [ DriversCategory.GENERAL ]: `general.${ key as keyof GeneralSettings }.value` as const,
    }[ group ];
  }, [ group ]);

  return (
    <div className={ styles.driverContainer }>
      {
        showGroupName ?
          <div className={ styles.groupName }>
            { groupName }
          </div> : null
      }
      {
        getFilteredGroupValues().map(([ key, val ]) => {
          return <React.Fragment key={ key }>
            {
              val.displayGroupTitle ? <div className={ styles.rowGroup }>
                { val.group }
              </div> : null
            }
            <Controller
              name={ getControllerName(key as KeysOfPlanSettings) }
              control={ control }
              render={ ({ field: { onChange, value }, formState: { errors } }) => {
                const error = errors[ group ] ? errors[ group ][ key ] : null;
                return <>
                  <div className={ `${ styles.driverRow }
                  ${ val.group ? styles.driverRowGrouped : null }
                  ${ error ? styles.error : null }
                  ` }>
                    <div className={ styles.driverNameRow }>
                      <HighlightedText
                        className={ styles.driverName }
                        text={ val.name }
                        highlight={ search }
                      />
                      {
                        val.description ?
                          <button
                            type='button'
                            className={ styles.infoButton }
                            onClick={ () => changeDisplayedInfo(val.name) }
                          >
                            <InfoIcon />
                          </button> : null
                      }
                    </div>
                    <div>
                      {
                        renderInput(
                          onChange,
                          value,
                          key as KeysOfPlanSettings,
                          error != null
                        )
                      }
                    </div>
                  </div>
                  {
                    error != null ? <div className={ styles.errorMessage }>
                      { error?.value?.message }
                    </div> : null
                  }
                </>;
              } }
            />
            {
              infoRow === val.name ?
                <HighlightedParagraph
                  className={ styles.infoRow }
                  text={ val.description }
                  highlight={ search }/> : null
            }
          </React.Fragment>;
        })
      }
      {
        showGroupName ? <div className={ styles.groupSeparator } /> : null
      }
    </div>
  );
};

export default DriversGroup;
