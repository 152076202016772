import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useRevenueGrid from 'hooks/useRevenueGrid';
import { useRevenueRecognition } from 'context/RevenueRecognitionContext';
import { AgGridReact } from 'ag-grid-react';
import GrayCircle from 'assets/icons/gray-circle.svg';
import GrayLine from 'assets/icons/gray-line.svg';
import GreenCircle from 'assets/icons/green-border-circle.svg';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import dayjs from 'dayjs';
import { RootState } from 'store/store';
import TopBar from 'components/revenueRecognition/topBar/TopBar';
import Loader from 'components/elements/loader/Loader';
import Card from 'components/elements/card/Card';
import { notifyError } from 'utils/notifications.utils';
import { autoGroupColumnDef, columnTypes, defaultColDef } from 'utils/gridUtils.utils';
import { CounterpartyType, Customer, FilterTypeCustomer } from 'types/revenueRecognition.types';

import styles from './CustomersTable.module.scss';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils/config.utils';
import { fetchAndSetProductList } from 'store/revenueRecognition.slice';
import CounterpartyLogo from 'components/elements/counterpartyLogo/CounterpartyLogo';
import organizationsService from '../../../services/organizations.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { useSearchable } from 'context/SearchableContext';
import { debounce } from 'lodash';

const CustomersTable = () => {
  const [ t ] = useTranslation('revenueRecognition');
  const gridRef = useRef(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const useGrid = useRevenueGrid(styles, false, gridRef);
  const period = useAppSelector( (sliceState: RootState) =>
    sliceState.revenueRecognition.table.period);
  const dispatch = useAppDispatch();
  const {
    state: { sort, filterCustomer },
  } = useRevenueRecognition();
  const { state: { search } } = useSearchable();

  const { dispatch: contextDispatch } = useRevenueRecognition();

  const navigate = useNavigate();

  useEffect(() => {
    gridRef.current?.columnApi?.applyColumnState({
      state: [ { colId: sort.field, sort: sort.direction } ],
      defaultState: { sort: null },
    });
  },[ sort ]);

  useEffect(() =>{
    dispatch(fetchAndSetProductList());
  },[]);

  const filterChange = useCallback(() => {
    gridRef?.current?.api?.onFilterChanged();
  }, [ gridRef ]);

  const debounceFilterChange = useCallback(debounce(filterChange, 300), [ gridRef ]);

  useEffect(() => {
    debounceFilterChange();
  }, [ search, filterCustomer ]);

  useEffect(() => {
    setIsLoading(true);
    organizationsService.getCounterpartiesRecognitionList(
      {
        startDate: dayjs.unix(period.startDate).format(),
        endDate: dayjs.unix(period.endDate).format(),
        counterpartyType: CounterpartyType.CUSTOMER,
        cadence: period.cadence,
        limit: 9999
      }
    ).then(res => {
      const data = [];
      const completeRow = [];
      for (const cp of res.data.results) {
        data.push({
          ...cp,
          ...cp.recognitionData
        });
      }

      data
        .filter(el => el.recognitionData)
        .map(el => Object.values(el.recognitionData))
        .forEach(e => e.map(x => completeRow.push(x)));

      contextDispatch({ type: 'COMPLETE', payload: {
        complete: completeRow.filter(el => el).length,
        max: completeRow.length
      } });

      gridRef?.current?.api?.setRowData(data);
    }).catch((e) => {
      notifyError(
        t('notifications.unexpected-error.message',
          { ns: 'common' })
      );
      throw e;
    }).finally(() => {
      setIsLoading(false);
    });
  }, [ period ]);

  const onGridReady = () => {
    gridRef.current?.columnApi?.applyColumnState({
      state: [ { colId: sort.field, sort: sort.direction } ],
      defaultState: { sort: null },
    });
  };

  const columnDefs = useMemo(() => {
    return useGrid.getColumnDefs().columnDefs;
  }, [ period ]);

  const rowTitleRenderer = (params: { value: string; node: IRowNode; data: Customer}) => {
    return <div className={ styles.rowTitle }>
      <span className={ styles.rowTitleLogo }>
        <CounterpartyLogo
          name={ params?.data?.name }
          type={ params?.data?.type }
          size={ 40 }
          url={ params.data.pictureUrl }
          className={ styles.defaultLogo }
        />
      </span>
      <span className={ styles.rowTitleText }>
        { params.data.name }
      </span>
    </div>;
  };

  const doesExternalFilterPass = (node: IRowNode) => {
    const name = node.data.name.toLowerCase();
    const valueInput = search.toLowerCase();

    const resultFilter = (resolved?: boolean) => {
      if (!node.data.recognitionData) return false;

      const resultArray = Object.keys(node.data.recognitionData).map((key) =>
        node.data.recognitionData[ key ]);
      return resolved
        ? !resultArray.every(el => el === resolved) && name.includes(valueInput)
        : !resultArray.some(el => el === resolved) && name.includes(valueInput);
    };

    if (filterCustomer.isFilter && filterCustomer.index === FilterTypeCustomer.RESOLVED) {
      return resultFilter(false);
    }

    if (filterCustomer.isFilter && filterCustomer.index === FilterTypeCustomer.UNRESOLVED) {
      return resultFilter(true);
    }

    return name.includes(valueInput);
  };

  const recognitionCompletionCellRenderer = (params: ICellRendererParams): ReactElement => {
    const iconSrc = () => {
      if (params.getValue()) {
        return GreenCircle;
      } else if (params.getValue() === false) return GrayCircle;
      else return GrayLine;
    };
    return <div className={ styles.gridIconContainer }>
      <img src={ iconSrc() } alt='recognition-icon'/>
    </div>;
  };

  return (
    <Card className={ styles.customersTable }>
      <TopBar
        header={ t('titles.customers') }
        description={ t('descriptions.customers') }/>
      <div className={ `ag-theme-alpine ${ styles.table }` }>
        <Loader isActive={ isLoading }/>
        <AgGridReact
          onRowClicked={
            (params) => navigate(`${ routes.manage.revenueRecognition }${ params.data.id }`)
          }
          ref={ gridRef }
          onGridReady={ onGridReady }
          animateRows={ true }
          columnDefs={ columnDefs }
          suppressMovableColumns={ true }
          treeData={ true }
          getDataPath={ row => [ row.id ] }
          getRowId={ (params) => params.data.id }
          defaultColDef={ { ...defaultColDef(styles),
            cellRenderer: recognitionCompletionCellRenderer } }
          columnTypes={ columnTypes() }
          rowHeight={ 60 }
          autoGroupColumnDef={ autoGroupColumnDef(rowTitleRenderer, styles) }
          isExternalFilterPresent={ () => search !== '' || filterCustomer.isFilter }
          doesExternalFilterPass={ doesExternalFilterPass }
          groupDisplayType='singleColumn'
          suppressHorizontalScroll={ false }
        />
      </div>
    </Card>
  );
};

export default CustomersTable;
