import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';

export type ValidationRules = {
  required?: boolean;
  maxLength?: number;
  isEmail?: boolean;
  isPhone?: boolean;
};

export type ErrorMessage = {
  message: string;
  description?: string;
  type?: 'info' | 'error' | 'warning' | 'success';
};

export enum Cadence {
  // t('common:time.weeks')
  // t('common:time.quarters')

  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
}

export type CadenceType = keyof typeof Cadence;

export enum StatementsForm {
  startDateActuals = 'startDateActuals',
  endDateActuals = 'endDateActuals',

  startDateActualsInput = 'startDateActualsInput',
  endDateActualsInput = 'endDateActualsInput',
  actualsOpen = 'actualsOpen',

  startDatePlan = 'startDatePlan',
  endDatePlan = 'endDatePlan',

  endDatePlanInput = 'endDatePlanInput',
  startDatePlanInput = 'startDatePlanInput',
  planOpen = 'planOpen',
}

export type StatementsRequestForm = {
  startDateActuals: Dayjs;
  endDateActuals: Dayjs;

  startDateActualsInput: string;
  endDateActualsInput: string;
  actualsOpen: boolean;

  startDatePlan: Dayjs;
  endDatePlan: Dayjs;

  endDatePlanInput: string;
  startDatePlanInput: string;
  planOpen: boolean;
};

export type SelectValueType = {
  value: string | number;
  label: string;
  icon?: ReactNode;
};
