import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account, Association } from 'types/statutory.types';
import { AppDispatch } from './store';
import { arrayToMap } from '../utils/mapping.utils';
import statutoryService from '../services/statutory.service';
import { TreeNode } from '../components/accountsMapping/treeLeaf/TreeLeaf';

type AccountMappingStore = {
  nodes: NodesMapping;
  associations: NodeAssociationMapping;
  unassigned: Account[];
  unassignedLoading: boolean;
  showDetected: boolean;
};

const initialState: AccountMappingStore = {
  nodes: [],
  associations: {},
  unassigned: [],
  unassignedLoading: false,
  showDetected: true,
};

type NodesMapping = {
  [ id: number ]: TreeNode;
};

type NodeAssociationMapping = {[nodeId: number]: Association[]};

export const accountMappingSlice = createSlice({
  name: 'accountMapping',
  initialState,
  reducers: {
    setNodes: (state, action: PayloadAction<NodesMapping>) => {
      state.nodes = action.payload;
    },
    setUnassignedLoading: (state, action: PayloadAction<boolean>) => {
      state.unassignedLoading = action.payload;
    },
    setAssociations: (state, action: PayloadAction<Association[]>) => {
      state.associations = arrayToMap<Association>(
        action.payload, assoc => assoc.templateRow, true);
    },
    setUnassigned: (state, action: PayloadAction<Account[]>) => {
      state.unassigned = action.payload;
    },
    addAssociation: (state, action: PayloadAction<Association>) => {
      const assoc = action.payload;
      const current = state.associations[ assoc.templateRow ] || [];
      const reordered = current.map(a => {
        if (a.order >= assoc.order) {
          a.order ++;
        }
        return a;
      });
      reordered.push(assoc);
      state.associations[ assoc.templateRow ] = reordered;
    },
    removeAssociation: (state, action: PayloadAction<Association>) => {
      const assoc = action.payload;
      state.associations[ assoc.templateRow ] = state.associations[ assoc.templateRow ].filter(
        a => a.account.id != assoc.account.id);
    },
    addUnassigned: (state, action: PayloadAction<Account>) => {
      const biggerNumberIndex = state.unassigned.findIndex(ua => ua.number > action.payload.number);
      const index = biggerNumberIndex >= 0 ? biggerNumberIndex : state.unassigned.length;
      state.unassigned.splice(index, 0, action.payload);
    },
    removeUnassigned: (state, action: PayloadAction<Account>) => {
      state.unassigned = state.unassigned.filter(ua => ua.number != action.payload.number);
    },
    setShowDetected: (state, action: PayloadAction<boolean>) => {
      state.showDetected = action.payload;
    },
    reorderAssociations: (state, action: PayloadAction<[ Association, Association ]>) => {
      const [ dragged, over ] = action.payload;
      const associations = state.associations[ dragged.templateRow ];
      const orderedArray = associations.sort((a, b) => a.order - b.order).map(a => a.account.id);
      orderedArray.splice(dragged.order, 1);
      orderedArray.splice(over.order, 0, dragged.account.id);
      state.associations[ dragged.templateRow ] = associations.map(a => {
        a.order = orderedArray.findIndex(ord => ord === a.account.id);
        return a;
      });
    },
  }
});

export const accountMappingReducer = accountMappingSlice.reducer;
const actions = accountMappingSlice.actions;

export const setNodes = (nodes: TreeNode[]) => (dispatch: AppDispatch) => {
  const mapping = {} as NodesMapping;
  for (const node of nodes) {
    mapping[ node.id ] = node;
  }
  dispatch(actions.setNodes(mapping));
};

export const nodeSelector = (nodeId) => createSelector(
  [ state => state.accountsMapping.nodes ],
  n => n[ nodeId ]
);

export const nodeAssociationsSelector = (nodeId) => createSelector(
  [ state => state.accountsMapping.associations ],
  a => a[ nodeId ] || []
);

export function saveAssociation(association: Association) {
  return async dispatch => {
    await statutoryService.postAssociation(association);
    dispatch(actions.addAssociation(association));
    dispatch(actions.removeUnassigned(association.account));
  };
}

export function removeAssociation(association: Association) {
  return async dispatch => {
    await statutoryService.deleteAssociation(association.account.id, association.templateRow);
    dispatch(actions.removeAssociation(association));
    dispatch(actions.addUnassigned(association.account));
  };
}

export function setAssociations() {
  return async dispatch => {
    const { data } = await statutoryService.getAssociations();
    dispatch(actions.setAssociations(data));
  };
}

export function setUnassigned() {
  return async dispatch => {
    dispatch(actions.setUnassignedLoading(true));
    const { data } = await statutoryService.getUnassignedAccounts();
    dispatch(actions.setUnassigned(data));
    dispatch(actions.setUnassignedLoading(false));
  };
}

export function setShowDetected(show: boolean) {
  return dispatch => dispatch(actions.setShowDetected(show));
}
