import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './ComingSoonView.module.scss';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as Default } from '../../assets/image/DefaultTemplate.svg';
import { ReactComponent as DefaultLeft } from '../../assets/image/DefaultTemplateLeft.svg';
import { ReactComponent as DefaultRight } from '../../assets/image/DefaultTemplateRight.svg';
import { useAppDispatch } from 'store/hooks/hooks';
import { topBarSlice } from 'store/topBar.slice';

const ComingSoonView = () =>{
  const { view } = useParams();
  const header = view.replace('-',' ');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(topBarSlice.actions.setTitle(header));

    return () => {
      dispatch(topBarSlice.actions.clear());
    };
  }, []);

  return (
    <div className={ styles.container }>
      <div className={ styles.section }>
        <div className={ styles.information } >
          <div>
            Coming soon 
            <Clock className={ styles.iconClock }/> 
          </div>
          <span>We&apos;re working hard on developing this feature for you</span>
        </div>
        <div className={ styles.imgContainer }>
          <div className={ styles.wrapperLeft }>
            <DefaultLeft />
          </div>
          <div className={ styles.wrapper }>
            <Default />
          </div>
          <div className={ styles.wrapperRight }>
            <DefaultRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonView;
