import { createApi } from '@reduxjs/toolkit/query/react';
import { httpBaseQuery } from './api.utils';
import { TemplateResponse } from '../../types/templates.types';
import { reportsService } from 'services/reports.services';

export const templatesApi = createApi({
  reducerPath: 'templatesApi',
  baseQuery: httpBaseQuery(),
  tagTypes: [ 'Templates' ],
  endpoints: (builder) => ({
    getTemplate: builder.query<TemplateResponse, { templateId?: number }>({
      queryFn: async ({ templateId }) => {
        if (!templateId) {
          return {
            error: {
              status: 400,
              statusText: 'Bad Request',
              data: 'Template ID is required'
            }
          };
        }

        const { data } = await reportsService.getUserTemplate(templateId);

        return {
          data
        };
      },
      providesTags: (data) => [ { type: 'Templates', id: data?.id } ],
    }),
  }),
});

export const {
  useGetTemplateQuery
} = templatesApi;
