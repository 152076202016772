import React, { useMemo } from 'react';
import { Dropdown, MenuProps } from 'antd';
import styles from './CtaButton.module.scss';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-down.svg';

interface Props {
  items: MenuItemType[];
}

const CtaButton = ({ items }: Props) => {
  const mainButton = items[ 0 ];

  const menu: MenuProps = useMemo(() => ({
    items: items.slice(1).map(option => ({
      children: null,
      ...option
    })),
    className: styles.menu,
  }), [ items ]);

  const onClick = () => {
    if (!mainButton?.disabled) {
      mainButton?.onClick(null);
    }
  };

  return <Dropdown.Button
    type='primary'
    className={ `${ styles.button } ${ mainButton?.disabled ? styles.disabled : '' }` }
    onClick={ onClick }
    menu={ menu }
    icon={ <ArrowIcon className={ styles.arrow } /> }
  >
    { items[ 0 ]?.label }
  </Dropdown.Button>;
};

export default CtaButton;
