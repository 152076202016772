import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FlagIcon } from 'assets/icons/flag.svg';
import { clearLeftPanel, setLeftPanel } from 'store/financials.slice';
import { useFinancialTable } from 'context/FinancialTableContext';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

const PlanDriversButton = () => {
  const { state: { templateId, customSettings: { panelSettings } } } = useFinancialTable();
  const table = useMemo(() => templateId, [ templateId ]);
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();
  const isPlanningEnabled = useAppSelector(state => state.financials.isPlanningEnabled);
  const panel = useAppSelector(state => state.app.leftPanel);
  const activeTable = useAppSelector(state => state.financials.active?.templateId);

  if (!isPlanningEnabled || panelSettings.plan.disabled) {
    return null;
  }

  const isActive = panel === 'budget' && table === activeTable;

  const onClick = () => {
    if (isActive) {
      dispatch(clearLeftPanel());
    } else {
      dispatch(setLeftPanel({
        panelType: 'budget',
        type: 'table',
        templateId: table,
      }));
    }
  };

  return (
    <GhostIconButton
      tooltip={ t('plan-drivers.tooltip') }
      active={ isActive }
      onClick={ onClick }
    >
      <FlagIcon />
    </GhostIconButton>
  );
};

export default PlanDriversButton;
