import React from 'react';
import { useDrop } from 'react-dnd';

import { Account, Association, DnDStatutoryTypes, } from 'types/statutory.types';
import styles from './TreeLeaf.module.scss';
import AccountDrag from '../accountDrag/AccountDrag';
import {
  nodeAssociationsSelector,
  nodeSelector,
  removeAssociation,
  saveAssociation
} from 'store/accountMapping.slice';
import { getDisplayName } from '../../../utils/common.utils';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseToastIcon } from '../../../assets/icons/toast-close.svg';
import { FinancialsRowData, RowType } from '../../../types/financials.types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';

export type TreeNode = {
  id: number;
  children: number[];
  type: RowType;
  rowData: FinancialsRowData;
};

type Props = {
  nodeId: number;
  depth: number;
};

const TreeLeaf = ({ nodeId, depth }: Props) => {

  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('accountMapping');
  const node: TreeNode = useAppSelector(nodeSelector(nodeId));
  const nodeAssociations: Association[] = useAppSelector(nodeAssociationsSelector(nodeId));
  const showDetected = useAppSelector(state=> state.accountsMapping.showDetected);

  const [ { isOver }, drop ] = useDrop(() => ({
    accept: DnDStatutoryTypes.unassigned,
    canDrop: () => node.children.length === 0 && node.type === RowType.FINANCIALS,
    drop: async (item: Account) => {
      await dispatch(saveAssociation(
        {
          account: item,
          templateRow: nodeId,
          detected: false,
          order: null
        }));
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
  }));

  const showRemovedNotification = (account: Account) => {
    notification[ 'info' ]({
      message: t('assignment-removed-notification-title'),
      description:
        `“${ getDisplayName(account.name) }” ${ t('account-removed-notification-text') }`,
      closeIcon: <CloseToastIcon />
    });
  };

  const onRemove = async (account: Account) => {
    await dispatch(removeAssociation({
      templateRow: nodeId,
      account: account,
      detected: false,
      order: null,
    }));
    showRemovedNotification(account);
  };

  const getAccountDisplay = () => {
    let display = nodeAssociations.slice();
    if (!showDetected) {
      display = display.filter(a => a.detected === false);
    }
    display = display.sort((a, b) => {
      return a.account.id - b.account.id;
    });
    return display.map(assoc =>
      <AccountDrag
        onRemove={ onRemove }
        key={ 'acc' + assoc.account.id }
        assoc={ assoc }
        level={ depth + 3 }
      />
    );
  };

  const getLeafDisplay = () => {
    return <div
      ref={ drop }
      className={ `${ styles.row }` }
    >

      <div className={ styles.mainRowSection }>
        <div className={ styles.nameContainer }>
          <span
            className={ `${ styles.name } ${ node.rowData.bold ? styles.bold : '' }` }
            style={ { marginLeft: `${ (40 * (depth) + 40) }px` } }>
            { getDisplayName(node.rowData.name) }
          </span>

          <span className={ styles.accountRange }>
            { node.rowData.accountRange }
          </span>

        </div>
      </div>
    </div>;
  };

  return (
    <div className={ `${ styles.wrapperBox } ${ isOver ? styles.isOver : '' }` }>
      { getLeafDisplay() }
      {
        node.children?.map(childNodeId =>
          <TreeLeaf
            key={ `node__${ childNodeId }` }
            nodeId={ childNodeId }
            depth={ depth + 1 }/>
        )
      }
      { getAccountDisplay() }

    </div>
  );
};

export default TreeLeaf;
