import React, { useState } from 'react';
import { Dropdown, DropdownProps } from 'antd';
import {
  CommandBarCustom,
  CommandBarDropdown as TCommandBarDropdown,
  CommandBarSelectUtil
} from 'components/commandBar/types/commandBar.types';
import styles from './CommandBarDropdown.module.scss';
import CommandBarDropdownOption from
  'components/commandBar/components/common/dropdown/option/CommandBarDropdownOption';

type AllowedButtonType = TCommandBarDropdown | CommandBarSelectUtil | CommandBarCustom;
interface Props extends DropdownProps {
  children: React.ReactNode;
  button: AllowedButtonType;
}

const isCustomDropdown = (btn: AllowedButtonType): btn is CommandBarCustom => {
  return (btn as CommandBarCustom).render !== undefined;
};

const CommandBarDropdown = ({ children, button, ...props }: Props) => {
  const [ open, setOpen ] = useState(false);

  const getDropdownRender = () => {
    if (isCustomDropdown(button)) {
      return <div className={ styles.dropdown }>{ button.render() }</div>;
    }
    return <div className={ styles.dropdown }>
      {
        button.title ? <h3>{ button.title }</h3> : null
      }
      {
        button.options.map(option => {
          return <CommandBarDropdownOption
            key={ option.label }
            option={ option }
            onClose={ () => setOpen(false) }
          />;
        })
      }
    </div>;
  };

  return <Dropdown
    placement='bottomLeft'
    open={ open }
    onOpenChange={ setOpen }
    overlayClassName={ styles.dropdownContainer }
    trigger={ [ 'click' ] }
    destroyPopupOnHide={ true }
    dropdownRender={ getDropdownRender }
    className={ open ? styles.activeDropdownButton : '' }
    disabled={ button.disabled }
    { ...props }
  >
    { children }
  </Dropdown>;
};

export default CommandBarDropdown;