import useOutsideClickListener from 'hooks/useOutsideClickListener';
import { RefObject, useCallback } from 'react';

const useClickOnAnotherTable = (
  ref: RefObject<HTMLElement>,
  templateId: string,
  isTableActive: boolean,
  clearSelection: () => void) => {

  const onClickOutsideTable = useCallback((event: MouseEvent) => {
    if (isTableActive) {
      let target = event.target as HTMLElement;
      while (target) {
        const tableId = target.getAttributeNode('data-view-item-id');
        if (tableId?.value && tableId.value !== templateId) {
          clearSelection();
          return;
        }
        target = target.parentElement;
      }
    }
  }, [ clearSelection, isTableActive, templateId ]);

  useOutsideClickListener({ ref, cb: onClickOutsideTable });
};

export default useClickOnAnotherTable;
