import React, { useCallback } from 'react';
import { DashboardElementType } from 'types/dashboard.types';
import styles from './GridDroppableItem.module.scss';

interface Props {
  children: React.ReactNode;
  type: DashboardElementType;
  className?: string;
}

const GridDroppableItem = ({ children, type, className = '' }: Props) => {
  const onDragStart = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('text/plain', type);
  }, [ type ]);

  return (
    <div
      draggable={ true }
      onDragStart={ onDragStart }
      className={ `${ styles.item } ${ className }` }
    >
      { children }
    </div>
  );
};

export default GridDroppableItem;