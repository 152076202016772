import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DashboardElementMapping,
  DashboardElementType,
  DashboardModeType,
} from 'types/dashboard.types';
import { separateThousands } from 'utils/financials.utils';
import styles from './BigNumberRenderer.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { saveElement } from 'store/dashboard.slice';
import LoadingWrapper from 'components/wrappers/loadingWrapper/LoadingWrapper';
import { setLeftPanel } from 'store/financials.slice';
import { FinancialTableContextProvider } from 'context/FinancialTableContext';
import { ReactComponent as FilterIcon } from 'assets/icons/filterv2.svg';
import FilterLabelButton from 'components/financials/filterLabelButton/FilterLabelButton';
import { Tooltip } from 'antd';
import DynamicTableWrapper from 'components/wrappers/dynamicTableWrapper/DynamicTableWrapper';
import { inRange } from 'lodash';
import { selectViewPeriod } from 'store/topBar.slice';

interface Props {
  item: DashboardElementMapping<DashboardElementType.BIG_NUMBER>;
}

const BigNumberRenderer = ({ item }: Props) => {
  const templateId = useMemo(() => item.element.template, [ item ]);
  const period = useAppSelector(selectViewPeriod);
  const mode = useAppSelector(state => state.dashboard.mode);
  const dispatch = useAppDispatch();
  const [ saving, setSaving ] = useState(false);

  const updateElement = useCallback(
    (element: DashboardElementMapping<DashboardElementType.BIG_NUMBER>) => {
      if (!period) return;
      dispatch(saveElement(element));
      setSaving(false);
    }, [ period ]);

  useEffect(() => {
    if (mode === DashboardModeType.EDIT || !templateId) return;
    setSaving(true);
    updateElement(item);
  }, [ item.id, period ]);

  return (
    <FinancialTableContextProvider templateId={ templateId }>
      <DynamicTableWrapper templateId={ templateId }>
        <BigNumberView
          item={ item }
          saving={ saving }
        />
      </DynamicTableWrapper>
    </FinancialTableContextProvider>

  );
};

interface ViewProps {
  item: DashboardElementMapping<DashboardElementType.BIG_NUMBER>;
  saving: boolean;
}

const BigNumberView = ({ item, saving }: ViewProps) => {
  const [ t ] = useTranslation('dashboard');
  const dispatch = useAppDispatch();
  const suffix = `[${ t('financials:actual-short') }]`;

  const value = useMemo(() => {
    return !item.value && item.value !== 0 ? 123 : item.value;
  }, [ item ]);

  const formattedValue = useMemo(() => {
    if (inRange(value, -999_999, 999_999)) {
      return separateThousands(Math.round(value));
    }
    const negative = value < 0;
    return `${ negative ? '-': '' }${ (Math.abs(value) / 1_000_000).toFixed(1) }`;
  }, [ value ]);

  const showFilterTableHandler = () => {
    dispatch(setLeftPanel({
      templateId: item.element.template,
      panelType: 'filter',
      type: 'big_number'
    }));
  };

  return (
    <LoadingWrapper loading={ saving }>
      <div className={ `${ styles.renderer }` }>
        <div className={ styles.titleWrapper }>
          {
            item.name.length ?
              <Tooltip
                title={ item.name }
                mouseEnterDelay={ .4 }
              >
                <h4>
                  { item.name }
                  { ' ' }
                  { suffix }
                </h4>
              </Tooltip> :
              <h4>
                { t('panel.card.placeholder.name') }
              </h4>
          }
          {
            (item?.value || item?.name) && (
              <span onClick={ showFilterTableHandler } className={ styles.span }>
                <FilterLabelButton
                  activeCloseButton={ false }
                  section='filter'
                  icon={ <FilterIcon/> }
                  text={ null }
                  suppressHover
                />
              </span>
            )
          }
        </div>
        <h2>
          { formattedValue }
          { ' ' }
          <span>
            {
              value > 999_999 || value < -999_999 ? 'M' : ''
            }
            &euro;
          </span>
        </h2>
      </div>
    </LoadingWrapper>
  );
};

export default BigNumberRenderer;
