import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';

import SearchBar from 'components/elements/searchBar/SearchBar';
import Drivers from 'components/financials/panels/planDriverPanel/drivers/Drivers';
import {
  defaultSettings,
  validationSchema
} from 'components/financials/panels/planDriverPanel/drivers/driversSettings';
import TitleBar from 'components/financials/panels/planDriverPanel/titleBar/TitleBar';
import { GeneralSettings, PlanSettings, PlanSettingsValues } from 'types/planning.types';
import { planningService } from 'services/planning.service';
import { notifyError, notifyUnexpectedError } from 'utils/notifications.utils';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { budgetSlice, selectBudgetSettings } from 'store/budget.slice';
import { useFinancialTable } from 'context/FinancialTableContext';
import PanelWrapper from 'components/panels/elements/wrappers/PanelWrapper';

import styles from './PlanDriverPanel.module.scss';
import panelStyles from 'components/panels/Panels.module.scss';

const PlanDriverPanel = () => {
  const { state: { templateId } } = useFinancialTable();
  const budgetSettings = useAppSelector(selectBudgetSettings);
  const methods = useForm<PlanSettings>({
    defaultValues: defaultSettings,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const [ isSubmitSuccessful, setIsSubmitSuccessful ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const panel = useAppSelector(store => store.app.leftPanel);
  const dispatch = useAppDispatch();
  const isPlanningEnabled = useAppSelector(s => s.financials.isPlanningEnabled);
  const activeTable = useAppSelector(state => state.financials.active?.templateId);
  const isActive = useMemo(() => {
    return isPlanningEnabled && panel === 'budget' && templateId === activeTable;
  }, [ templateId, panel, isPlanningEnabled, activeTable ]);

  const mapDataFromObjectToNums = useCallback((data: PlanSettings) => {
    const mappedData = Object.entries(data).map(([ key ]) => {
      return { [ key ]: Object.assign({},
        ...Object.entries(data[ key as keyof PlanSettings ]).map(([ k, v ]) => {
          if (k === 'planStart') {
            return { [ k ]: dayjs(v.value).format('YYYY-MM-DD') };
          } else return { [ k ]: v.value };
        })) };
    });
    return mappedData.reduce((acc, curr) => Object.assign(acc, curr), {}) as PlanSettingsValues;
  }, []);

  useEffect(() => {
    if (!budgetSettings) {
      return;
    }
    const updatedSettings = cloneDeep(defaultSettings);
    Object.keys(budgetSettings).forEach((groupKey) => {
      const group = groupKey as keyof PlanSettings;
      Object.entries(budgetSettings[ group ]).forEach(([ settingKey, val ]) => {
        const setting = settingKey as keyof GeneralSettings;
        updatedSettings[ group ][ setting ].value = val;
      });
    });
    methods.reset(updatedSettings);
  }, [ budgetSettings ]);

  const onSubmit = useCallback(async (data: PlanSettings) => {
    setIsSubmitting(true);
    const mappedData = mapDataFromObjectToNums(data);
    planningService.setPlanning({ categories: mappedData })
      .then((res) => {
        setIsSubmitSuccessful(true);
        dispatch(budgetSlice.actions.setBudgetSettings(res.data.categories));
      })
      .catch(e => {
        if (e.response.data.detail) {
          notifyError(e.response.data.detail);
          throw e;
        } else {
          notifyUnexpectedError(e);
        }
      }).finally(() => {
        setIsSubmitting(false);
      });
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      methods.reset(methods.getValues());
    }
  }, [ isSubmitSuccessful ]);

  useEffect(() => {
    if (isSubmitSuccessful && methods.formState.isDirty) {
      setIsSubmitSuccessful(false);
    }
  }, [ methods.formState.isDirty ]);

  const onReset = useCallback(() => {
    methods.reset(defaultSettings);
    setIsSubmitSuccessful(false);
  }, [ methods.reset ]);

  return (
    <PanelWrapper isActive={ isActive } type='left'>
      <FormProvider { ...methods }>
        <form className={ styles.form } onSubmit={ methods.handleSubmit(onSubmit) }>
          <TitleBar
            isSubmitSuccessful={ isSubmitSuccessful }
            isSubmitting={ isSubmitting }
            onReset={ onReset }
          />
          <SearchBar className={ panelStyles.searchBox } showSeparator={ false } />
          <Drivers />
        </form>
      </FormProvider>
    </PanelWrapper>
  );
};

export default PlanDriverPanel;
