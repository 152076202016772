import { sectionKeys } from 'components/financials/detailedView/utils/detailedView.utils';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { updateDetailedViewSettings } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DetailedViewType, DriversCategory, Period } from 'types/financials.types';
import { Cadence } from 'types/form.types';
import { scrollViewItemIntoView } from 'utils/financials.utils';

interface Props {
  templateId: number;
  type: DetailedViewType;
}

const useSelectDetailedView = ({ templateId, type }: Props) => {
  const dispatch = useAppDispatch();
  const detailedView = useAppSelector(state => state.financials.detailedView);
  const budgetItemTypes = useAppSelector(state => state.budget.budgetItemTypesMap);
  const budgetSettings = useAppSelector(state => state.budget.budgetSettings);

  const onSelect = useCallback((item: number) => {
    const budgetItemType = item ? budgetItemTypes[ item ] : null;
    const settings = budgetSettings[ DriversCategory.GENERAL ];
    const startDate = dayjs(settings.planStart);
    const period: Period = {
      cadence: Cadence.month,
      startDate: startDate.unix(),
      endDate: startDate.add(settings.planDurationMonths, 'month')
        .subtract(1, 'month')
        .endOf('month')
        .unix(),
      actualsOpen: true,
      isManuallySet: false,
    };
    dispatch(updateDetailedViewSettings({
      budgetItemType,
      templateId,
      type,
      period,
      sectionKey: sectionKeys.budgeting
    }));

    scrollViewItemIntoView('table', templateId);
  }, [ budgetItemTypes, detailedView.type, templateId, budgetSettings, type ]);

  return { onSelect };
};

export default useSelectDetailedView;
