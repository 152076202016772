import React, { useCallback, useEffect } from 'react';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { useContractsContext } from 'context/ContractsContext';
import CounterpartyLogo from 'components/elements/counterpartyLogo/CounterpartyLogo';
import HighlightedText from 'components/elements/highlightedText/HighlightedText';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ContractType, GroupByStatusList } from 'types/contracts.types';
import { useSearchable } from '../../../context/SearchableContext';

const useContractsGrid = (
  styles,
  gridRef
) =>{

  const [ t ] = useTranslation('contracts');

  const {
    state: { groupById, parameterGroupBy, sortById }
  } = useContractsContext();

  const { state: { search } } = useSearchable();

  const isExternalFilterPresent = useCallback(() => {
    return parameterGroupBy !== GroupByStatusList.ALL || search !== '';
  }, [ parameterGroupBy, search ]);

  useEffect(() => {
    gridRef?.current?.api?.onFilterChanged();
  }, [ parameterGroupBy, search ]);

  const doesExternalFilterPass = useCallback((node: IRowNode):boolean => {
    if (groupById === 0 && parameterGroupBy !== GroupByStatusList.ALL) {
      return parameterGroupBy === node.data.name.charAt(0)
      && node.data.name.toLowerCase().includes(search.toLowerCase());
    } else if (groupById === 1 && parameterGroupBy !== GroupByStatusList.ALL) {
      return parameterGroupBy.toLowerCase() === node.data.contractStatus
      && node.data.name.toLowerCase().includes(search.toLowerCase());
    }
    return node.data.name.toLowerCase().includes(search.toLowerCase());
  },[ parameterGroupBy, search ]);

  const cellRenderer = (params) => {
    if (params.node.field === 'firstLetter' || params.node.field === 'status')
      return null;
    else {
      return <div className={ `${ styles.rowTitle }
      ${ styles.title }` }>
        <span className={ styles.rowTitleLogo }>
          <CounterpartyLogo
            name={ params?.data?.name }
            type={ params?.data?.type }
            size={ 40 }
            url={ params.data.pictureUrl }
            className={ styles.defaultLogo }
          />
        </span>
        <span className={ styles.rowTitleText }>
          <HighlightedText
            text={ params.data.name }
            highlight={ search }
          />
        </span>
      </div>;
    }
  };

  const autoGroupColumnDef = (innerRenderer) => ({
    cellClass: styles.rowTitle,
    suppressColumnFilter: true,
    lockPosition: true,
    autoHeight: true,
    suppressMenu: true,
    suppressSizeToFit:true,
    cellRendererParams: {
      suppressCount: true,
      suppressMenu: true,
      innerRenderer: innerRenderer,
    },
    headerComponent: () => null,
  });

  const TTMCellRenderer = (params: ICellRendererParams) => {
    return Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(params.value);
  };

  const statusCellRenderer = (params: ICellRendererParams) => {
    return (
      <div className={ styles.statusCell }>
        <span>
          <i className={ styles.statusIcon + ' ' + styles[ params.value ] }></i>
          { params.value }
        </span>
      </div>
    );
  };

  const getContractsValue = (contract: string) =>{
    if (contract === ContractType.ACTIVE)
      return 0;
    else if (contract === ContractType.TRIAL)
      return 1;
    else if (contract === ContractType.PILOT)
      return 2;
    else if (contract === ContractType.BOOKED)
      return 3;
    else if (contract === ContractType.PAUSED)
      return 4;
    else if (contract === ContractType.PLANNED)
      return 5;
    else if (contract === ContractType.CHURNED)
      return 7;
    else return 6;
  };

  const getColumnDefs = (groupId: number) => {
    return (
      [
        {
          field: 'name',
          type: 'valueColumn',
          headerName: t('table.column.name'),
          suppressMenu: true,
          flex: 0.8,
          cellRenderer: cellRenderer,
          cellClass: 'no-border',
        },
        {
          field: 'ttmRevenue',
          headerName: t('table.column.ttm'),
          flex: 0.1,
          suppressMenu: true,
          cellStyle: {
            'alignItems': 'center',
            'justifyContent': 'flex-end',
            'display': 'flex',
          },
          cellRenderer: TTMCellRenderer,
        },
        {
          field: 'contractStatus',
          headerName: t('table.column.status'),
          rowGroup: groupId === 1,
          suppressMenu: true,
          sort: 'asc' as const,
          cellRenderer: statusCellRenderer,
          comparator:(valueA, valueB, nodeA, nodeB) =>{

            if (groupById === 0 || groupById === 1) {
              if ((sortById === 0 || sortById === 1)
              && nodeA?.key && nodeB?.key) {
                const dimensionSort = sortById ? -1 : 1;
                const compareValue = nodeA?.key.localeCompare(nodeB?.key);
                return compareValue * dimensionSort;
              }
              if ((sortById === 4 && groupById === 1) && nodeA?.key && nodeB?.key) {
                return getContractsValue(nodeA?.key)
                - getContractsValue(nodeB?.key);
              }

            }

            if ((sortById === 2 || sortById === 3)
            && nodeA?.data?.contractStartedDate
            && nodeA?.data?.contractStartedDate ) {
              const firstDate = dayjs.unix(nodeA?.data?.contractStartedDate).format();
              const secondDate = dayjs.unix(nodeB?.data?.contractStartedDate).format();

              const dimensionSort = () => {
                if (sortById === 2) return -1;
                else if (sortById === 3) return 1;
              };

              return (dayjs(firstDate).isAfter(dayjs(secondDate)) ? 1 : -1) * dimensionSort();

            } else if (sortById === 4 && nodeA?.data?.contractStatus
              && nodeB?.data?.contractStatus) {
              return getContractsValue(nodeA?.data?.contractStatus )
                - getContractsValue(nodeB?.data?.contractStatus );
            }

            return 0;
          }

        },
        {
          field: 'firstLetter',
          hide: true,
          rowGroup: groupId === 0,
          sort: 'asc' as const,
        },
      ]
    );
  };
  return {
    getColumnDefs,
    autoGroupColumnDef,
    isExternalFilterPresent,
    doesExternalFilterPass
  };
};

export default useContractsGrid;
