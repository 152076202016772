/* eslint-disable camelcase */
import {
  Account,
  AccountRequest,
  Association,
  StatutoryTemplate,
  Transaction
} from 'types/statutory.types';
import { Cadence } from 'types/form.types';
import { getUrlParams } from 'utils/common.utils';
import { endpoints } from 'utils/config.utils';
import http from 'utils/http.utils';
import { StatutoryRowCategory, TemplateSections } from 'types/templates.types';

type AssociationsFilters = {
  templateRow: number;
};

const getAssociations = (filters?: AssociationsFilters) =>
  http.get<Association[]>(endpoints.statutory.template.associations.base,
    { params: filters || {} });

const getTemplates = () =>
  http.get<StatutoryTemplate[]>(endpoints.statutory.template.base);

const getTemplateByType = (type: TemplateSections) =>
  http.get<StatutoryTemplate>(endpoints.statutory.template.byType
    + '?' + getUrlParams({ statement_type: type }));

const getUnassignedAccounts = () =>
  http.get<Account[]>(endpoints.statutory.template.associations.unassigned);

const postAssociation = (association: Association) => {
  const payload = {
    account: association.account.id,
    templateRow: association.templateRow,
    order: association.order
  };
  return http.post<Association>(
    endpoints.statutory.template.associations.base, payload as AccountRequest);
};

const deleteAssociation = (accountId: number, templateRowId: number | string) =>
  http.post<AccountRequest>(endpoints.statutory.template.associations.remove, {
    account: accountId,
    templateRow: templateRowId
  } as AccountRequest);

export type TransactionLineRequestParams = {
  template_row_id?: string;
  dimension_item_ids?: string[];
  transaction_lines?: number[];
  unassigned_dimension_ids?: number[];
  contract_id?: number;
  start_date?: string;
  end_date?: string;
  plan_start_date?: string;
  plan_end_date?: string;
  cadence?: Cadence;
  user_template_id?: number;
  template_category?: StatutoryRowCategory;
  breakdown_ids?: number[];
  excluded_unassigned_dimension_ids?: number[];
  excluded_dimension_item_ids?: number[];
  formulaIds?: number[];
};

export type TransactionLineFilterParams = {
  template_row_id?: string; // For statutory (financial) template filtering
  user_template_id?: number; // For filtering by filters applied to the user template

  dimension_item_ids?: string[];
  excluded_dimension_item_ids?: number[];

  dimension_ids?: string[];
  unassigned_dimension_ids?: number[];
  excluded_unassigned_dimension_ids?: number[];

  transaction_lines?: number[];

  start_date?: string;
  end_date?: string;
  plan_start_date?: string;
  plan_end_date?: string;
  cadence?: Cadence;

  // For filtering transactions that are used as data source for formula elements
  formulaIds?: number[];
};

export type TransactionLinePayloadParams = {
  transactionLines?: number[];
  accountId?: number | null;
  productId?: number;
  counterpartyId?: number | null;
  templateRowId?: number;
  dimensionItemIds?: number[];
  unassignedDimensionIds?: number[];
  startDate?: string;
  endDate?: string;
  planStartDate?: string;
  planEndDate?: string;
  cadence?: Cadence;
  split?: {
    dimensionItem: number;
    percentage: number;
  }[];
};

const getTransactionLines = (
  params: TransactionLineRequestParams,
  signal: AbortSignal
) => http.get<Transaction[]>(
  endpoints.organizations.transactions.table, {
    params: params,
    signal
  }
);

const statutoryService = {
  getAssociations, postAssociation, deleteAssociation,
  getTemplates, getTemplateByType, getUnassignedAccounts,
  getTransactionLines
};

export default statutoryService;
