import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useFinancialTable } from 'context/FinancialTableContext';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

const CloseButton = () => {
  const { state: { onClose }, dispatch } = useFinancialTable();

  return (
    <GhostIconButton
      onClick={ () => {
        dispatch({ type: 'setChartVisible', payload: false });
        onClose && onClose();
      } }
    >
      <CloseIcon/>
    </GhostIconButton>
  );
};

export default CloseButton;
