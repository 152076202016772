import React, { useMemo } from 'react';
import GridDroppableItem from 'components/dashboard/wrappers/gridDroppableItem/GridDroppableItem';
import { useTranslation } from 'react-i18next';
import { DashboardElementType } from 'types/dashboard.types';
import { ReactComponent as ChartIcon } from 'assets/icons/chart-placeholder.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import styles from './AddCardsPage.module.scss';

const AddCardsPage = () => {
  const [ t ] = useTranslation('dashboard');

  const options = useMemo(() => {
    return [
      {
        title: t('panel.add-cards-page.number'),
        cards: [
          {
            type: DashboardElementType.BIG_NUMBER,
            placeholder: <div className={ styles.bigNumber }>123</div>
          }
        ]
      },
      {
        title: t('panel.add-cards-page.charts'),
        cards: [
          {
            type: DashboardElementType.CHART,
            placeholder: <div className={ styles.chart }>
              <ChartIcon />
            </div>
          }
        ]
      },
      {
        title: t('panel.add-cards-page.table'),
        cards: [
          {
            type: DashboardElementType.TABLE,
            placeholder: <div className={ styles.table }>
              Table
            </div>
          }
        ]
      },
      {
        title: t('panel.add-cards-page.title'),
        cards: [
          {
            type: DashboardElementType.TITLE,
            placeholder: <div className={ styles.title }>
              { t('panel.add-cards-page.placeholders.title') }
            </div>
          },
          {
            type: DashboardElementType.SUBTITLE,
            placeholder: <div className={ styles.subtitle }>
              { t('panel.add-cards-page.placeholders.subtitle') }
            </div>
          }
        ]
      }
    ];
  }, []);

  return (
    <>
      {
        options.map((option) => (
          <div className={ styles.cardContainer } key={ option.title }>
            <h4>{ option.title }</h4>
            {
              option.cards.map((card) => (
                <GridDroppableItem
                  key={ card.type }
                  type={ card.type }
                  className={ `${ styles.droppableItem } ${ styles[ card.type ] }` }
                >
                  <DragIcon className={ styles.drag } />
                  { card.placeholder }
                </GridDroppableItem>
              ))
            }
          </div>
        ))
      }
    </>
  );
};

export default AddCardsPage;
