import { useContractsContext } from 'context/ContractsContext';
import React, { useEffect } from 'react';
import { GroupByStatusList } from 'types/contracts.types';
import styles from './TableBottomBar.module.scss';

const TableBottomBar = () => {

  const {
    dispatch: dispatchContext,
    state: { groupById, parameterGroupBy }
  } = useContractsContext();

  useEffect(() =>
    dispatchContext({ type: 'selectParameterGroupBy', payload: GroupByStatusList.ALL })
  ,[ groupById ]);

  const mappingList = () =>{
    if (groupById === 0) {
      const alpha = Array.from(Array(26)).map((e, i) => i + 65);
      const alphabet = alpha.map((x) => String.fromCharCode(x));
      return [ 'All', ...alphabet ];
    } else if (groupById === 1) {
      return (Object.keys(GroupByStatusList) as (keyof typeof GroupByStatusList)[]).map(
        (key) => {
          return GroupByStatusList[ key ];
        },
      );

    }
  };

  const isActive = (el) => parameterGroupBy === el;

  return groupById !== 2 && ( <div className={ styles.tableBottomBar }>
    {
      mappingList().map(item =>
        <div
          onClick={ () => dispatchContext({ type: 'selectParameterGroupBy', payload: item } ) }
          className={ `
          ${ styles.itemList }
          ${ groupById ? styles.itemListStatus : styles.itemListAlphabet }
          ${ isActive(item) ? styles.active : '' }
          ` }
          key={ item }
        >
          <span>
            { item }
          </span>

        </div>)
    }
  </div>);
};

export default TableBottomBar;
