import { defaults, useCommandBar } from 'components/commandBar/context/CommandBarContext';
import { UtilKey } from 'components/commandBar/types/commandBar.types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  keys: UtilKey[];
  mode: 'hide' | 'show';
  showCapsulesFilter?: boolean;
}

const useCommandBarDefaults = ({ keys, mode, showCapsulesFilter = false }: Props) => {
  const { dispatch, state: { utils } } = useCommandBar();
  const [ t, i18n ] = useTranslation('common');

  useEffect(() => {
    dispatch({
      type: 'SET_UTILS',
      payload: utils.map(button => {
        const defaultButton = defaults.find(_button => _button.key === button.key);

        return {
          ...defaultButton,
          tooltip: t(`command-bar.tooltip.${ button.key }`),
          hidden: mode === 'hide' ? keys.includes(button.key) : !keys.includes(button.key),
        };
      }),
      defaults: true,
    });

    dispatch({ type: 'SET_HIDDEN', payload: false });

    dispatch({ type: 'SET_FILTER_CAPSULES_HIDDEN', payload: showCapsulesFilter });

    return () => {
      dispatch({ type: 'CLEAR_SETTINGS' });
    };
  }, [ i18n.language ]);

  return undefined;
};

export default useCommandBarDefaults;