import React, { ReactNode } from 'react';

import { Account, Association, DnDStatutoryTypes } from 'types/statutory.types';
import styles from './AccountDrag.module.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Tooltip from '../../elements/tooltip/Tooltip';
import { Trans, useTranslation } from 'react-i18next';
import { useDrop } from 'react-dnd';
import { saveAssociation } from '../../../store/accountMapping.slice';
import { useAppDispatch } from '../../../store/hooks/hooks';
import { getDisplayName } from '../../../utils/common.utils';

type Props = {
  assoc: Association;
  level: number;
  onRemove: CallableFunction;
};

const AccountDrag = ({ assoc, level, onRemove }: Props) => {
  const [ t ] = useTranslation('accountMapping');
  const dispatch = useAppDispatch();

  const [ { isOver }, drop ] = useDrop(() => ({
    accept: DnDStatutoryTypes.unassigned,
    drop: async (item: Account) => {
      await dispatch(saveAssociation(
        {
          account: item,
          templateRow: assoc.templateRow,
          detected: false,
          order: null
        }));
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  }));

  const style = {
    paddingLeft: `${ (40 * level) - 35 }px`,
  };

  const getTooltipTranslation = (): ReactNode => {
    return <Trans
      t={ t }
      i18nKey='remove-association-tooltip'
    />;
  };

  return (
    <div
      ref={ drop }
      className={ `${ styles.dragContainer } ${ isOver ? styles.isOver : '' }` }
    >
      <div
        className={ styles.row }
        style={ style }
      >
        <div className={ styles.accountData }>
          <div className={ styles.number }>
            <span>
              { assoc.account.number }
            </span>
          </div>

          <div className={ styles.name }>
            <span className={ styles.name }>{ getDisplayName(assoc.account.name) }</span>
          </div>
          <div className={ styles.inlineIcons }>
            <Tooltip title={ getTooltipTranslation() }>
              <CloseIcon
                onClick={ () => onRemove(assoc.account) }
                className={ `${ styles.icon } ${ styles.remove }` }
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDrag;
