import * as yup from 'yup';
import { t } from 'i18next';
export const getOutOfRangeMessage = (min: number, max: number) =>
  t('common:validation.outOfRange', { min, max });
const REQUIRED_MESSAGE = t('common:validation.required');

export const getRangeValidator = (min: number, max: number) => yup.number()
  .typeError(REQUIRED_MESSAGE)
  .required(REQUIRED_MESSAGE)
  .min(min, getOutOfRangeMessage(min, max))
  .max(max, getOutOfRangeMessage(min, max));

export const PERCENT_VALIDATOR = getRangeValidator(0, 100);

export const MONTH_DAY_VALIDATOR = getRangeValidator(1, 31);

export const MONTH_OFFSET_VALIDATOR = getRangeValidator(1, 12);

export const REQUIRED_VALIDATOR = yup.string()
  .typeError(REQUIRED_MESSAGE)
  .required(REQUIRED_MESSAGE);
