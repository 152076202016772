import { selectViewPeriod } from './../../../store/topBar.slice';
import { addNewTables, financialsSlice, setPeriod } from './../../../store/financials.slice';
import { useEffect, useState } from 'react';
import { setFiltersTable } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { isEmpty } from 'lodash';
import { FilterList } from 'types/filterTable.types';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import { UUID } from 'types/templates.types';

interface Props {
  templateId: number;
  isDashboard: boolean;
  shouldFetch?: boolean;
  templateNodeUuids?: UUID[];
}

const useFinancialReport = ({ templateId, isDashboard, shouldFetch, templateNodeUuids }: Props) => {
  const dispatch = useAppDispatch();
  const period = useAppSelector(selectViewPeriod);
  const { data: report, refetch, isFetching } =
    useReportQuery({ templateId, period, shouldFetch, templateNodeUuids });

  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    dispatch(addNewTables([ templateId ]));
  }, [ templateId ]);

  useEffect(() => {
    if (!report) return;

    setLoading(true);
    dispatch(setPeriod({
      templateId,
      period,
      isDashboard,
    }));
    if (isEmpty(report.filters)) {
      const filters: FilterList = [];
      dispatch(setFiltersTable(templateId, filters));
    } else {
      const filters: FilterList = report.filters;
      dispatch(setFiltersTable(templateId, filters));
    }
    setLoading(false);
    dispatch(financialsSlice.actions.setReportLoaded({ id: templateId, loaded: true }));
  }, [ report ]);

  return {
    loading: isFetching || loading,
    getReport: refetch
  };
};

export default useFinancialReport;
