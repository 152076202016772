import { useCallback, useState } from 'react';
import { Transaction } from 'types/statutory.types';
import { FilterList } from 'types/filterTable.types';
import statutoryService, {
  TransactionLineRequestParams
} from '../../../../../services/statutory.service';
import { uniqBy } from 'lodash';
import { Period } from 'types/financials.types';
import { addPeriod } from 'utils/period.utils';

export interface IFetchTransactions {
  params?: TransactionLineRequestParams[];
  filter?: FilterList;
}

interface Props {
  period: Period;
}

const useTransactions = ({ period }: Props) => {
  const [ loading, setLoading ] = useState(false);

  const getFilterParams = useCallback((filter: FilterList) => {
    return {
      'excluded_dimension_item_ids': filter.flatMap((f) => f.excludedItems),
      'excluded_unassigned_dimension_ids': filter.filter((f) => f.excludeUnassigned)
        .map((f) => f.dimension),
    };
  }, []);

  const fetchTransactions = useCallback(({ params, filter }: IFetchTransactions) => {
    setLoading(true);
    const filterParams = filter ? getFilterParams(filter) : {};

    const paramsWithFilter = params?.map(param => ({
      ...param,
      ...filterParams
    }));
    const paramsWithPeriod = addPeriod(paramsWithFilter, period);

    const requests = paramsWithPeriod?.map(
      param => statutoryService.getTransactionLines(param, null)
    );

    return Promise.all(requests).then((res) => {
      const allTransactions: Transaction[] = res.reduce((acc, { data }) => {
        return [ ...acc, ...data ];
      }, []);

      const uniqueTransactions = uniqBy(allTransactions, 'id');
      return uniqueTransactions;
    }).finally(() => {
      setLoading(false);
    });
  }, [ period ]);

  return {
    fetchTransactions,
    loading,
  };
};

export default useTransactions;
