import DimensionCapsule from 'components/elements/dimensionCapsule/DimensionCapsule';
import InlineStatement from 'components/elements/inlineStatement/InlineStatement';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DimensionToLabel } from 'types/labeling.types';
import { Transaction } from 'types/statutory.types';
import { getDisplayName } from 'utils/common.utils';
import { separateThousands, sumTransactions } from 'utils/financials.utils';
import CounterpartyGroup from './CounterpartyGroup';
import styles from './LabelPage.module.scss';

interface Props {
  counterparties: {
    [key: string]: Transaction[];
  };
  onRemove: (id: number) => void;
  dimensions: DimensionToLabel[];
}

const LabelPage = ({ counterparties, onRemove, dimensions }: Props) => {
  const counterpartyKeys = useMemo(() => Object.keys(counterparties), [ counterparties ]);
  const [ t ] = useTranslation('labeling');

  const isMultipleCounterparties = useMemo(() => counterpartyKeys.length > 1, [ counterpartyKeys ]);

  const grandTotal = useMemo(() => {
    if (!isMultipleCounterparties) return 0;
    const transactions = Object.values(counterparties).flat();
    return sumTransactions(transactions);
  }, [ counterparties, isMultipleCounterparties ]);

  return <div className={ styles.labelPage }>
    <div className={ styles.labelerBody }>
      <div className={ styles.connector }/>
      <div className={ styles.labeler }>
        <div className={ styles.source }>{ t('create-rule.source') }</div>
        <div className={ styles.label }>
          {
            counterpartyKeys.map(counterparty => (
              <div className={ styles.counterpartyGroup } key={ `counterparty__${ counterparty }` }>
                <CounterpartyGroup
                  counterpartyName={ counterparty }
                  nodes={ counterparties[ counterparty ] }
                  onRemove={ onRemove }
                />
              </div>
            ))
          }
        </div>
        {
          isMultipleCounterparties && <div className={ styles.grandTotal }>
            { t('label.grand-total') }
            { ' ' }
            <b>
              { separateThousands(grandTotal) }
              &euro;
            </b>
          </div>
        }
        <div className={ styles.dimensionTarget }>
          <div className={ styles.target }>{ t('create-rule.target') }</div>
          <div className={ styles.targetCapsules }>
            {
              dimensions.map(
                dimension =>
                  <DimensionCapsule
                    key={ dimension.id }
                    dimension={ getDisplayName(dimension.dimension) }
                    dimensionItem={ getDisplayName(dimension.dimensionItem) }
                  />
              )
            }
          </div>

        </div>

      </div>
    </div>

    <InlineStatement type='info' className={ styles.labelInfo }>
      <div>
        <span className={ styles.infoText }>
          { t('label.info') }
        </span>
        {
          dimensions.map(dimension => (
            <p key={ dimension.id }>
              <b>{ getDisplayName(dimension.dimension) }</b>
              { getDisplayName(dimension.dimensionItem) }
            </p>
          ))
        }

      </div>
    </InlineStatement>
  </div>;
};

export default LabelPage;
