import Modal from 'components/elements/modal/Modal';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Modals.module.scss';
import Input from 'components/elements/input/Input';
import { useForm } from 'react-hook-form';

type Props = {
  onConfirm: (name: string) => void;
  onClose: () => void;
  isVisible: boolean;
  title: React.ReactNode;
  label: string;
  okText: string;
  defaultValue?: string;
  confirmDisabled?: (name: string) => boolean;
};

const AddEditDimensionModal = ({
  onConfirm,
  onClose,
  isVisible,
  label,
  title,
  okText,
  defaultValue,
  confirmDisabled
}: Props) => {
  const { control, watch, reset, formState: { errors } } = useForm();
  const [ t ] = useTranslation('financials');
  const name = watch('name');

  useEffect(() => {
    reset({ name: defaultValue });
  }, [ defaultValue ]);

  const resetForm = () => {
    reset({ name: defaultValue });
  };

  return (
    <Modal
      onConfirm={ () => {
        onConfirm(name);
        onClose();
        setTimeout(() => {
          resetForm();
        }, 200);
      } }
      closeOnConfirm={ false }
      onClose={ () => {
        resetForm();
        onClose();
      } }
      isVisible={ isVisible }
      okText={ okText }
      closeText={ t('left-panel.add-dimension.close-text') }
      title={ title }
      confirmDisabled={ !name || name?.length > 100 || (confirmDisabled && confirmDisabled(name)) }
      destroyOnClose={ true }
    >
      <div className={ styles.content }>
        <Input
          name='name'
          errors={ errors }
          control={ control }
          placeholder={ defaultValue }
          label={ label }
        />
      </div>
    </Modal>
  );
};

export default AddEditDimensionModal;
