import Capsule from 'components/elements/capsule/Capsule';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelingPage } from 'types/labeling.types';
import styles from './PageSwitcher.module.scss';

interface Props {
  page: LabelingPage;
  setPage: (page: LabelingPage) => void;
  rulesDisabled: boolean;
}

const PageSwitcher = ({ page, setPage, rulesDisabled }: Props) => {
  const [ t ] = useTranslation('labeling');

  const pages = useMemo(() => ([
    {
      name: LabelingPage.LABEL,
      disabled: false,
    },
    {
      name: LabelingPage.CREATE_RULE,
      disabled: rulesDisabled,
    },
  ]), [ rulesDisabled ]);

  const getPageName = useCallback((name: LabelingPage) => {
    return {
      [ LabelingPage.LABEL ]: t('modal.labeling'),
      [ LabelingPage.CREATE_RULE ]: t('modal.create-rule'),
    }[ name ];
  }, []);

  return (
    <div className={ styles.pageSwitcher }>
      {
        pages.map((p, i) => (
          <Capsule
            key={ `${ p.name }__${ i }` }
            isActive={ page === p.name }
            className={ `${ styles.page }` }
            type='button'
            disabled={ p.disabled }
            onClick={ () => setPage(p.name) }
          >
            { getPageName(p.name) }
          </Capsule>
        ))
      }
    </div>
  );
};

export default PageSwitcher;
