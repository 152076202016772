import EmptySearchResult
  from 'components/templates/templateTagSection/emptySearchResult/EmptySearchResult';
import { useSearchable } from 'context/SearchableContext';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import { TemplateSections, TreeTags } from 'types/templates.types';
import { deepTagComparator } from 'utils/templates.utils';
import BottomBar
  from '../../elements/dimensionLeftPanel/listView/dimensionDetails/bottomBar/BottomBar';
import DetailsList
  from '../../elements/dimensionLeftPanel/listView/dimensionDetails/detailsList/DetailsList';
import TopBar from '../../elements/dimensionLeftPanel/listView/dimensionDetails/topBar/TopBar';
import DragTagsSection from './dragTagSection/DragTagSection';
import styles from './TemplateBuilderDimensionList.module.scss';
import useDimension from '../../elements/dimensionLeftPanel/hooks/useDimension';
import LoadingWrapper from 'components/wrappers/loadingWrapper/LoadingWrapper';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { FILTERS_LIST_VIEW } from 'utils/storage.utils';
import DimensionListSettings
  from '../../elements/dimensionLeftPanel/settings/DimensionListSettings';
import { Switch } from 'antd';
import { Dimension } from 'types/filterTable.types';
import DimensionsListView
  from '../../elements/dimensionLeftPanel/listView/dimensionsListView/DimensionsListView';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';
import ScrollDown from 'components/elements/scrollDown/ScrollDown';
import { getDimensionDisplayName } from '../../../utils/financials.utils';

interface Props {
  isLoading: boolean;
  activeSection: TemplateSections;
  setActiveSection: React.Dispatch<React.SetStateAction<TemplateSections>>;
  treeTags: TreeTags;
}

const TemplateBuilderDimensionList = ({
  isLoading,
  activeSection,
  treeTags,
  setActiveSection,
}: Props) => {
  const initialLoad = useRef(true);
  const dimensionMap = useAppSelector(state => state.breakdowns.dimensionMap);
  const [ isSearching, setIsSearching ] = useState(true);
  const [ dimensionDetails, setDimensionDetails ] = useState<number | null>(null);
  const [ isListView, setIsListView ] = useLocalStorage(FILTERS_LIST_VIEW, false);

  useEffect(() => setDimensionDetails(null), [ activeSection ]);
  const [ t ] = useTranslation('financials');

  const { state: { results, search }, dispatch: dispatchSearchable } = useSearchable();

  const sectionIsAll = useMemo(() => activeSection === TemplateSections.ALL, [ activeSection ]);
  const responseIsEmpty = useMemo(() => results === 0 && !isSearching, [ results, isSearching ]);
  const findDimension = dimensionDetails ? dimensionMap[ dimensionDetails ] : null;

  const searchedItems = useMemo(() => {
    if (!findDimension) return [];
    if (!search) return findDimension.items;
    return findDimension.items.filter(
      dimensionItem =>
        getDimensionDisplayName(dimensionItem).toLowerCase().includes(search.toLowerCase()));
  }, [ findDimension, search ]);

  const {
    onDimensionEdit,
    onDimensionDelete,
    onDimensionItemAdd
  } = useDimension({ dimension: findDimension });

  const onAddDimensionItem = useCallback(async (name: string) => {
    await onDimensionItemAdd({ name });
  }, [ findDimension ]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }
    if (search !== '' && activeSection !== TemplateSections.ALL) {
      dispatchSearchable({ type: 'results', payload: 0 });
      setActiveSection(TemplateSections.ALL);
    }
    if (search === '') {
      setActiveSection(TemplateSections.ALL);
    }
    setIsSearching(true);
    debounceSearchResults(search.toLowerCase());

    setDimensionDetails(null);
  }, [ search ]);

  const getSearchResults = useCallback((searchValue: string) => {
    let count = 0;
    Object.values(treeTags).forEach(tags => {
      tags.forEach(tag => {
        count += deepTagComparator(tag, searchValue);
      });
    });
    return count;
  }, [ search, treeTags ]);

  const debounceSearchResults = useCallback(debounce((searchValue: string) => {
    dispatchSearchable({ type: 'results', payload: getSearchResults(searchValue) });
    setIsSearching(false);
  }, 300), [ treeTags ]);

  const renderAll = useCallback(() => {
    if (responseIsEmpty) {
      return (
        <div className={ styles.emptyResult }>
          <EmptySearchResult/>
        </div>
      );
    }

    if (sectionIsAll) {
      return Object.values(TemplateSections)
        .filter(section => section !== TemplateSections.ALL)
        .map((section) => (
          renderSection(section)
        ));
    }

    return renderSection(activeSection);
    
  }, [ responseIsEmpty, treeTags, activeSection, onDimensionEdit ]);

  const renderSection = (section: string) => {
    if (section === 'dimensions') {
      return <DimensionsListView
        key={ section }
        className={ styles.dimensions }
        dimensions={ treeTags[ 'dimensions' ].map(i => i.rowData) as unknown as Dimension[] }
        mode='label'
        isTemplateBuilder={ true }
        templateId={ null }
        setDimensionDetails={ setDimensionDetails }
      />;
    }

    return <DragTagsSection
      key={ section }
      formulaBuilderIsOpen={ false }
      isCombinedSections={ false }
      treeTags={ treeTags }
      section={ section as TemplateSections }
      setDimensionDetails={ setDimensionDetails }
    />;
  };

  const renderDimensionDetails = useCallback(() => {
    return (
      <>
        <TopBar
          dimension={ findDimension }
          onBack={ () => setDimensionDetails(null) }
        />
        <BottomBar
          dimensionType={ findDimension?.type }
          dimension={ findDimension }
          isTemplateBuilder={ true }
          onAddDimensionItem={ onAddDimensionItem }
          onDimensionDelete={ async () => {
            await onDimensionDelete();
            setDimensionDetails(null);
          } }
          onDimensionEdit={ onDimensionEdit }
          isLabeling={ false }
        />
        <DynamicScrollableContainer>
          <DetailsList
            templateId={ null }
            dimension={ findDimension }
            items={ searchedItems }
            activeUnassigned={ true }
            isListView={ isListView }
            showUnassignedButton={ true }
          />
        </DynamicScrollableContainer>
        <DimensionListSettings>
          <DimensionListSettings.Label>
            <Switch
              checked={ !!isListView }
              onChange={ ((value) => setIsListView(value)) }
              title={ t('left-panel.list-view') }
            />
            <span>{ t('left-panel.list-view') }</span>
          </DimensionListSettings.Label>
        </DimensionListSettings>
      </>

    );
  }, [ findDimension, isListView ]);

  const scrollableContainer = useRef<HTMLDivElement>(null);

  return <DynamicScrollableContainer className={ styles.scrollableContainer } >
    <div ref={ scrollableContainer }
      className={ `${ styles.container } ${ dimensionDetails ? styles.details : '' }` }>
      <LoadingWrapper loading={ isLoading }>
        <div className={ `
        ${ styles.searchResults }
        ${ search === '' || isSearching ? styles.searchResultsHidden : '' }` }
        >
          { `${ results } ${ t('search.results', { ns: 'common' }) }` }
        </div>
        {
          dimensionDetails ? renderDimensionDetails() : renderAll()
        }
      </LoadingWrapper>
    </div>
    <ScrollDown scrollableElementRef={ scrollableContainer } />
  </DynamicScrollableContainer>;
};

export default TemplateBuilderDimensionList;
