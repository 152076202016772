import Capsule from 'components/elements/capsule/Capsule';
import React, { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DimensionFilter, DimensionItem } from 'types/filterTable.types';
import { getDisplayName } from 'utils/common.utils';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import styles from './FilterCapsule.module.scss';
import {
  clearLeftPanel,
  removeDimensionFromFilters,
  selectPeriod,
  setLeftPanel
} from 'store/financials.slice';
import useReportQuery from '../financialTable/hooks/useReportQuery';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { clearDimensionFilter } from '../../../store/inputs.slice';
import { getFilterCount }
  from 'components/elements/dimensionLeftPanel/listView/dimensionDetails/dimensionDetails.util';

interface Props {
  dimensionFilters: DimensionFilter;
}

export const FilterCapsule = ({ dimensionFilters }: Props) => {
  const dimension = useAppSelector((state) =>
    state.breakdowns.dimensionMap[ dimensionFilters.dimension ]);
  const dimensions = useAppSelector((state) => state.breakdowns.dimensionMap);
  const filteredDimension = dimensions[ dimensionFilters.dimension ];

  const includedItems = useMemo((): DimensionItem[] => {
    const allItems = filteredDimension?.items ?? [];
    return allItems.filter((item) => {
      return dimensionFilters.excludedItems?.indexOf(item.id) === -1;
    });

  }, [ dimensionFilters ]);

  const activeCard = useAppSelector(state => state.financials.active);
  const activeTemplate = activeCard?.templateId;
  const filters = useAppSelector((state) => state.financials.tables[ activeTemplate ]?.filter);
  const dispatch = useAppDispatch();
  const period = useAppSelector(selectPeriod(activeTemplate));
  const { refetch } = useReportQuery({ templateId: activeTemplate, period });
  const [ t ] = useTranslation('financials');

  const [ searchParams, setSearchParams ] = useSearchParams();

  const openFilterPanel = (filterId: number) => {
    dispatch(clearLeftPanel());
    dispatch(setLeftPanel({ ...activeCard, panelType: 'filter' }));

    searchParams.set('filter', filterId.toString());
    setSearchParams(searchParams, { replace: true });
  };

  const clearDimensionFilters = async () => {
    if (!activeCard) return;
    if (activeCard.type === 'inputs') {
      dispatch(clearDimensionFilter(dimension.id));
      return;
    }
    if (!filters) return;
    await dispatch(removeDimensionFromFilters(
      { templateId: activeTemplate, dimensionId: dimension.id }));
    refetch();
  };

  /**
   * Show label if only one dimension item (or unassigned) is selected
   */
  const labelName = useMemo(() => {
    const isUnassignedIncluded = !dimensionFilters?.excludeUnassigned;

    if (includedItems.length === 1 && !isUnassignedIncluded) {
      return getDisplayName(includedItems[ 0 ].name);
    }
    if (includedItems.length === 0 && isUnassignedIncluded) {
      return t('left-panel.unassigned');
    }
    return null;
  }, [ includedItems ]);
  const [ , currentFilterCount ] = getFilterCount(dimension, dimensionFilters);

  return (
    <Capsule
      isActive={ true }
      onClick={ () => openFilterPanel(dimensionFilters.dimension) }
      className={ styles.capsuleContainer }
      padding='0'
    >
      <div className={ styles.nameContent }>
        <div className={ styles.name }>
          { getDisplayName(dimension?.name) }
          { ' ' }
          <span className={ styles.label }>
            { labelName ? labelName : currentFilterCount }
          </span>
        </div>
      </div>
      <span className={ styles.closeButton } onClick={ async (e) => {
        e.stopPropagation();
        await clearDimensionFilters();
      } }>
        <CloseIcon />
      </span>
    </Capsule>
  );
};
