import React, { useMemo } from 'react';
import { Message, MessageSource, MessageType } from 'types/chat.types';
import MessageBubble from
  'components/collaboration/chat/messages/history/message/messageBubble/MessageBubble';
import { ReactComponent as ChatbotIcon } from 'assets/icons/chatbot/chatbot.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/chatbot/error.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/chatbot/success.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/chatbot/question.svg';
import LoaderMessage from
  'components/collaboration/chat/messages/history/message/loaderMessage/LoaderMessage';
import styles from '../Message.module.scss';

interface Props {
  message: Message & {source: MessageSource.SYSTEM};
}

const SystemMessage = ({ message }: Props) => {

  const statusIcon = useMemo(() => {
    return {
      [ MessageType.ERROR ]: <ErrorIcon className={ styles.icon } />,
      [ MessageType.SUCCESS ]: <SuccessIcon className={ styles.icon } />,
      [ MessageType.QUESTION ]: <QuestionIcon className={ styles.icon } />,
    }[ message.type ];
  }, [ message ]);

  return <>
    <ChatbotIcon className={ styles.icon } />
    {
      message.type === MessageType.LOADER ?
        <LoaderMessage message={ message } /> :
        <MessageBubble message={ message } />
    }
    { statusIcon }
  </>;
};

export default SystemMessage;