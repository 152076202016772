import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TempBuilderHeaderTitle.module.scss';

const TempBuilderHeaderTitle = () => {

  const [ t ] = useTranslation('financials');

  return (
    <div className={ styles.headerWrapper }>
      <h2 className={ styles.title }>
        { t('links.report.template-builder', { ns: 'navigation' }) }
      </h2>
      <span className={ styles.text }>
        { t('descriptions.templates') }
      </span>
    </div>  
  );
};

export default TempBuilderHeaderTitle;