import Button from 'components/elements/button/Button';
import SearchableSelect from 'components/elements/searchableSelect/SearchableSelect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  continueNetvisorIntegration,
  continueProcountorIntegration,
  continueXeroIntegration
} from 'services/integration.service';
import organizationsService from 'services/organizations.service';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { Integration, IntegrationEnum, SyncState } from 'types/settings.types';
import { RequiredRole, RequiredRoleAccount } from 'types/statutory.types';
import { notifyError, notifySuccess } from 'utils/notifications.utils';
import styles from './AccountRolesSection.module.scss';
import { useTranslation } from 'react-i18next';
import { fetchAndSetDimensions } from '../../../../store/breakdowns.slice';
import InlineStatement from '../../../elements/inlineStatement/InlineStatement';
import SpinningLoader from '../../../elements/spinning-loader/SpinningLoader';
import { appSlice } from '../../../../store/app.slice';
import { getDisplayName } from '../../../../utils/common.utils';

interface Props {
  integration: Integration;
}

export const AccountRolesSection = ({ integration }: Props) => {
  const [ t ] = useTranslation('settings');

  const { control, handleSubmit, watch, reset } = useForm<RequiredRoleAccount>();
  const values = watch();

  const dispatch = useAppDispatch();

  const accounts = useAppSelector((state) => state.breakdowns.accounts);

  const [ requiredRoles, setRequiredRoles ] = useState<RequiredRoleAccount[]>();
  const [ isLoading, setIsLoading ] = useState(false);
  const syncState = integration?.initialSyncState || SyncState.NOT_STARTED;

  useEffect(() => {
    // todo: this causes multiple requests. Better use rtk query
    organizationsService.getRequiredRoles().then((res) => {
      setRequiredRoles(res.data);
      reset(res.data);
    });
  }, [ accounts ]);

  const getOptions = useCallback(() => {
    return accounts.map((ac) => ({
      label: `${ ac.number } ${ getDisplayName(ac.name) }`,
      value: ac.id
    }));
  }, [ accounts ]);

  const allFieldsFilled = useMemo(() => {
    return !Object.values(values).some(value => !value);
  }, [ values ]);

  useEffect(() => {
    if (syncState !== SyncState.NOT_STARTED) {
      dispatch(fetchAndSetDimensions());
    }
  }, [ syncState ]);

  const saveRoles = async (val: RequiredRoleAccount) => {
    setIsLoading(true);
    await organizationsService.setRequiredRoles(val);
    setIsLoading(false);
  };

  const continueSync = async () => {
    const actionMap = {
      [ IntegrationEnum.procountor ]: continueProcountorIntegration,
      [ IntegrationEnum.xero ]: continueXeroIntegration,
      [ IntegrationEnum.netvisor ]: continueNetvisorIntegration
    };
    const continueIntegration = actionMap[ integration.type ];
    try {
      await continueIntegration(integration.id);
      notifySuccess(
        t('notifications.message'),
        t('notifications.description', { system: integration.type })
      );
    } catch (e) {
      notifyError(
        t('notifications.unexpected-error.message')
      );
    }
  };

  const saveAndContinueSync = async (currentValues: RequiredRoleAccount) => {
    await saveRoles(currentValues);
    if (shouldContinueSync) {
      dispatch(appSlice.actions.setIntegrationInitialSyncState(SyncState.IN_PROGRESS));
      continueSync();
    }
  };

  const shouldContinueSync = useMemo(() => {
    const allFieldsNull = !Object.values(values).some(value => value !== null);
    return integration?.initialSyncState == SyncState.AWAITING_ACCOUNTS && !allFieldsNull;
  }, [ integration, values ]);

  const isDropdownAvailable = useMemo(() => {
    return requiredRoles &&
      integration &&
      syncState !== SyncState.NOT_STARTED &&
      syncState !== SyncState.ERROR &&

      accounts.length > 0;

  }, [ requiredRoles, accounts, syncState ]);

  const renderDropdowns = () => {
    return (
      <div className={ styles.accounts }>
        <InlineStatement type='info' className={ styles.labelInfo }>
          <div>
            <span className={ styles.infoText }>
              {
                syncState === SyncState.AWAITING_ACCOUNTS ?
                  t('account-roles.info') :
                  t('account-roles.change-info')
              }
            </span>
          </div>
        </InlineStatement>
        {
          Object.entries(requiredRoles)
            .sort(([ roleA ], [ roleB ]) => roleA.localeCompare(roleB))
            .map(([ role ], idx) => {
              const usedAccounts = Object.entries(values)
                .filter(([ k ]) => k !== role)
                .map((a) => a[ 1 ]);

              const options = getOptions()
                .filter((opt) => !usedAccounts.includes(opt.value));
              return (
                <div className={ styles.role } key={ idx }>
                  <span>{ t(`account-roles.names.${ role }`) }</span>
                  <Controller
                    control={ control }
                    name={ role as RequiredRole }
                    render={ ({ field }) => {
                      return (
                        <SearchableSelect
                          className={ styles.select }
                          autoFocus={ true }
                          showArrow={ true }
                          options={ options }
                          popupClassName='ag-custom-component-popup'
                          { ...field }
                        />
                      );
                    } }
                  />
                </div>
              );
            })
        }
        <Button
          loading={ isLoading }
          className={ styles.saveButton }
          onClick={ handleSubmit(saveAndContinueSync) }
          disabled={ !allFieldsFilled || syncState === SyncState.IN_PROGRESS }
        >
          {
            shouldContinueSync ? t('account-roles.button.save-and-continue') :
              t('account-roles.button.save')
          }
        </Button>
      </div>
    );
  };

  const renderWaitingForAccounts = () => {
    return (
      <div className={ styles.waitingForAccounts }>
        <span className={ styles.infoText }>
          { t('account-roles.waiting-for-accounts') }
        </span>
        <SpinningLoader/>
      </div>
    );
  };

  return (
    <div className={ styles.accountsContainer }>
      {
        isDropdownAvailable && renderDropdowns()
      }
      {
        (
          integration &&
          !isDropdownAvailable &&
          syncState === SyncState.NOT_STARTED
        ) && renderWaitingForAccounts()
      }
    </div>
  );
};
