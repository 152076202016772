import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from 'assets/icons/logo.svg';

import styles from './AuthCard.module.scss';

type Props = {
  children: ReactNode;
  containerClassName?: string;
};

const AuthCard = ({ children, containerClassName='' }: Props) => {
  const [ t ] = useTranslation();
  
  const getMadeIn = () => {
    return <>{ t('footer:footer.text') }</>;
  };

  const getCountry = () => {
    return <>{ t('footer:footer.country') }</>;
  };

  const getFooterText2 = () => {
    return t('footer:footer.text2', { year: new Date().getFullYear() });
  };

  return (
    <div className={ styles.authCard }>
      <div className={ styles.logoContainer }>
        <Logo className={ styles.logo }/>
      </div>
      <div className={ styles.card }>
        <div className={ `${ styles.cardContent } ${ containerClassName }` }>
          { children }
        </div>
        <span className={ styles.span } >
          { getMadeIn() }
          &hearts;
          { getCountry() }
          <span>{ getFooterText2() }</span>
        </span>

      </div>
    </div>
  );
};

export default AuthCard;