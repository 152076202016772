import React from 'react';
import Header from 'components/collaboration/chat/header/Header';
import styles from './Chat.module.scss';
import MessageHistory from 'components/collaboration/chat/messages/history/MessageHistory';
import PromptInput from 'components/collaboration/chat/prompt/PromptInput';
import { useAppSelector } from 'store/hooks/hooks';

interface Props {
  onClose: () => void;
}

const Chat = ({ onClose }: Props) => {
  const history = useAppSelector(state => state.chat.history);

  return <div className={ styles.chat }>
    <Header onClose={ onClose } />
    <MessageHistory messages={ history } />
    <PromptInput />
  </div>;
};

export default Chat;