import React, { useMemo } from 'react';
import { ReactComponent as ChartsIcon } from 'assets/icons/chartIcon.svg';
import { useFinancialTable } from 'context/FinancialTableContext';
import { useAppSelector } from 'store/hooks/hooks';
import { isReportEmpty } from 'utils/financials.utils';
import ChartModal from 'components/modals/charts/ChartModal';
import { selectPeriod } from 'store/financials.slice';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { useTranslation } from 'react-i18next';
import { getDefaultChartSettings } from '../../../utils/chart.utils';

const ChartsButton = () =>{
  const {
    state: {
      templateId,
      customSettings: { panelSettings },
      chartVisible,
      chartSettings
    },
    dispatch
  } = useFinancialTable();
  const [ t ] = useTranslation('financials');
  const period = useAppSelector(selectPeriod(templateId));
  const { data: report } = useReportQuery({ templateId, period });
  const firstNode = report?.nodes[ 0 ];
  const isChartDisabled = useMemo(() => {
    return isReportEmpty(report?.nodes);
  }, [ report?.nodes ]);

  if (panelSettings.chart.disabled || isChartDisabled) {
    return null;
  }

  const onShowChart = () => {
    dispatch({ type: 'setChartVisible', payload: true });
    dispatch({ type: 'setChartSettings', payload: getDefaultChartSettings({
      primary: firstNode.uuid,
    }) });
  };

  return (
    <>
      <GhostIconButton
        active={ chartVisible }
        tooltip={ t('charts.tooltip.button') }
        onClick={ onShowChart }
      >
        <ChartsIcon />
      </GhostIconButton>
      <ChartModal
        onClose={ () => dispatch({ type: 'setChartVisible', payload: false }) }
        isVisible={ chartVisible }
        defaultSettings={ chartSettings }
      />
    </>
  );
};

export default ChartsButton;
