import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styles from './PromptInput.module.scss';
import { AutoComplete, Input } from 'antd';
import Button from 'components/elements/button/Button';
import { ReactComponent as SendIcon } from 'assets/icons/chatbot/send.svg';
import FlatSelect from 'components/elements/flatSelect/FlatSelect';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { chatSlice } from 'store/chat.slice';
import { ChatTaskType, Message, MessageSource, MessageType } from 'types/chat.types';
import examples from './examples';
import { planningService } from 'services/planning.service';
import useChatbotResponse from 'components/collaboration/chat/hooks/useChatbotResponse';
import { getUUID } from 'utils/templates.utils';
import useSelectDetailedView from 'components/financials/detailedView/hooks/useSelectDetailedView';
import { DetailedViewType } from 'types/financials.types';
import { selectActive } from 'store/financials.slice';
import useKeyPressListener from '../../../../hooks/useKeyPressListener';

const { TextArea } = Input;

interface PromptForm {
  message: string;
}

type CounterpartyOption = {
  value: string;
};

const PromptInput = () => {
  const dispatch = useAppDispatch();
  const { getErrorMessage } = useChatbotResponse();
  const [ t ] = useTranslation('collaboration');
  const templateId = useAppSelector(selectActive).templateId;
  const detailedView = useAppSelector(state => state.financials.detailedView);
  const { onSelect } = useSelectDetailedView({ type: DetailedViewType.FLOATING_PANEL, templateId });
  const isLastMessageGenerating = useAppSelector(state => {
    const history = state.chat.history;
    const lastMessage = history[ history.length - 1 ];
    return lastMessage?.type === MessageType.LOADER;
  });
  const sessionId = useAppSelector(state => state.chat.sessionId);
  const hasConsent = useAppSelector(state => state.chat.isConsentGiven);

  // Below things are added for filling counterparty with @ prefix. Autocomplete field simply
  // replaces entire input with the selected value. We need to keep separate textAreaState to not
  // lose the rest of the message and he cursor position to know where to replace the word.
  const [ cursorPosition, setCursorPosition ] = useState(0);
  const [ textAreaState, setTextAreaState ] = useState<string>('');

  const handleCursorChange = (event) => {
    setCursorPosition(event.target.selectionStart);
  };
  const [ counterpartyOptions, setCounterpartyOptions ] = useState<CounterpartyOption[]>([]);
  const uniqueCounterpartyNames = useAppSelector(state =>
    Array.from(new Set(state.breakdowns.counterparties.map(cp => cp.name))));

  const { control, handleSubmit, reset, watch, setValue } = useForm<PromptForm>({
    defaultValues: {
      message: ''
    },
  });

  const isSendDisabled = !watch('message').trim() || isLastMessageGenerating || !hasConsent;

  useKeyPressListener({
    code: 'Enter',
    cb: () => {
      // If counterparty options are available,
      // do not submit the form, because enter will select of them.
      if (isLastMessageGenerating || counterpartyOptions.length > 0) {
        return;
      }
      handleSubmit(onSubmit)();
    },
    enabled: !isSendDisabled

  });

  const keepDetailedViewOpen = () => {
    if (detailedView.templateId !== templateId) {
      onSelect(null);
    }
  };

  const handleSendMessage = async (message: string) => {
    const loaderMessage: Message = {
      id: getUUID(),
      text: null,
      source: MessageSource.SYSTEM,
      type: MessageType.LOADER
    };
    dispatch(chatSlice.actions.addMessage(loaderMessage));
    keepDetailedViewOpen();
    try {
      const { data } = await planningService.promptAiCompletion(message, sessionId);
      const type =
        data.budgetItemData.metadata.status === 'missing_data' ? MessageType.QUESTION :
          MessageType.MESSAGE;

      dispatch(chatSlice.actions.setMessage(
        {
          id: loaderMessage.id,
          text: data.budgetItemData.metadata.message,
          source: MessageSource.SYSTEM,
          type
        }
      ));

      dispatch(chatSlice.actions.addTask({
        id: getUUID(),
        type: ChatTaskType.BUDGET_ITEM_COMPLETION,
        payload: data,
        canProceed: data.budgetItemData.metadata.status === 'complete'
      }));
    } catch {
      const errorMessage = getErrorMessage();
      dispatch(chatSlice.actions.setMessage({
        ...errorMessage,
        id: loaderMessage.id
      }));
    }
  };

  const onSubmit = async ({ message }: PromptForm) => {
    if (!message || isSendDisabled) {
      return;
    }
    dispatch(chatSlice.actions.addMessage({
      id: getUUID(),
      text: message,
      type: MessageType.PROMPT,
      source: MessageSource.USER
    }));
    reset({ message: '' });
    setTextAreaState('');
    handleSendMessage(message);
  };

  const examplePromptsOptions = useMemo(() => examples.map((prompt) => ({
    value: prompt,
    label: prompt
  })), []);

  const onAutocompleteSearch = (value: string) => {
    const currentWord = value.slice(0, cursorPosition + 1).split(' ').pop();
    if (currentWord.startsWith('@')) {
      const counterpartySearchPhrase = currentWord.slice(1);
      setCounterpartyOptions(
        uniqueCounterpartyNames.filter((counterpartyName) =>
          counterpartyName.toLowerCase().includes(counterpartySearchPhrase))
          .map((counterpartyName) => ({
            value: `${ counterpartyName }`
          })) as CounterpartyOption[]);
    } else {
      setCounterpartyOptions([]);
    }
    setTextAreaState(value);
  };

  const onAutocompleteSelected = (value: string) => {
    const fullMessage = textAreaState;
    const wordToReplace = fullMessage.slice(0, cursorPosition).split(' ').pop();
    const replaced = fullMessage.replace(wordToReplace, value);
    setTextAreaState(replaced);
    setValue('message', replaced);
  };
  return <form onSubmit={ handleSubmit(onSubmit) } className={ styles.prompt }>
    <Controller
      control={ control }
      name='message'
      render={ ({ field }) => (
        <>
          <AutoComplete
            defaultActiveFirstOption={ true }
            dropdownAlign={ {
              points: [ 'bl', 'tl' ], // align dropdown bottom-left to top-left of input element
              offset: [ 0, -4 ], // align offset
              overflow: {
                adjustX: 0,
                adjustY: 0, // do not auto flip in y-axis
              },
            } }
            options={ counterpartyOptions }
            onSearch={ onAutocompleteSearch }
            onSelect={ onAutocompleteSelected }
            { ...field }
          >
            <TextArea
              onKeyDown={ handleCursorChange }
              onClick={ handleCursorChange }
              onChange={ handleCursorChange }
              placeholder={ t('chat.prompt.placeholder') }
              className={ styles.input }
            />
          </AutoComplete>

          <div className={ styles.buttons }>
            <FlatSelect
              options={ examplePromptsOptions }
              value={ t('chat.prompt.examples.title') }
              size='large'
              onSelect={ (value) => reset({ message: value }) }
              dropdownStyle={ { maxWidth: '500px' } }
            />
            <Button
              type='primary'
              htmlType='submit'
              className={ styles.send }
              disabled={ isSendDisabled }
            >
              <SendIcon/>
            </Button>
          </div>
        </>
      ) }
    />
  </form>;
};

export default PromptInput;
