import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CounterpartyBreadcrumbs from
  'components/elements/counterpartyBreadcrumbs/CounterpartyBreadcrumbs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { routes } from 'utils/config.utils';
import styles from './ContractDetails.module.scss';
import ContractInfo from './contractInfo/ContractInfo';
import {
  CustomSettings,
  defaultCustomSettings,
  FinancialTableContextProvider
} from 'context/FinancialTableContext';
import { financialsSlice, setCapsuleList } from 'store/financials.slice';
import contractsService from 'services/contracts.service';
import { ContractTab } from 'types/contracts.types';
import Capsule from 'components/elements/capsule/Capsule';
import ContractsData from './contractsData/ContractsData';
import dayjs from 'dayjs';
import CreateUpdateModals from 'components/elements/createUpdateModals/CreateUpdateModals';
import { ModalContextProvider, useModalContext } from 'context/ModalContext';
import { Customer } from '../../../types/revenueRecognition.types';
import organizationsService from '../../../services/organizations.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { breakdownsActions } from '../../../store/breakdowns.slice';
import OrganizationSelect from '../../elements/organizationSelect/OrganizationSelect';

const CONTRACT_TEMPLATE_ID = -1;

const ContractDetails = () => {
  const [ t ] = useTranslation('financials');
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const dimensions = useAppSelector(state => state.breakdowns.dimensions);
  const contractDimension = dimensions.filter(item => item.relation === 'CONTRACT')[ 0 ];
  const table = useAppSelector(
    state => state.financials.tables[ CONTRACT_TEMPLATE_ID ] || null
  );
  const [ activeTabs, setActiveTabs ] = useState<ContractTab[]>([ ContractTab.INFO ]);
  const [ customer, setCustomer ] = useState<Customer>(null);
  const {
    state: {
      editModalItem
    }, dispatch: dispatchContext
  } = useModalContext();

  useEffect(() => {
    dispatch(financialsSlice.actions.clearTables());
    dispatch(financialsSlice.actions.addTables([ CONTRACT_TEMPLATE_ID ]));
    dispatch(setCapsuleList([
      { templateId: CONTRACT_TEMPLATE_ID, type: 'table', isShown: true }
    ]));
    if (id) {
      organizationsService.getCounterparty(+id).then(({ data }) => setCustomer(data));
    }
    dispatch(financialsSlice.actions.setCustomer({
      templateId: CONTRACT_TEMPLATE_ID,
      value: +id,
    }));

    return () => {
      dispatch(financialsSlice.actions.clearTables());
    };
  }, [ id ]);

  const onGoBack = useCallback(() => {
    navigate(routes.manage.contracts);
  }, []);

  const tableSettings = useMemo((): CustomSettings => {
    return {
      getReport: (period, signal) => contractsService.getContractsReport(+id, period, signal),
      ...defaultCustomSettings,
      panelSettings: {
        ...defaultCustomSettings.panelSettings,
        filterLabel: {
          ...defaultCustomSettings.panelSettings.filterLabel,
          disabled: false,
          defaultOpen: true,
          isCloseable: false,
        },
        onlyDimensionIds: [ contractDimension?.id ],
        customerId: +id,
      },
      isDynamicColumn: false,
    };
  }, [ activeTabs, dimensions, id, contractDimension ]);

  const toggleTab = useCallback((tab: ContractTab) => {
    setActiveTabs(prev => {
      if (prev.includes(tab)) {
        return prev.filter(el => el !== tab);
      }
      return [ ...prev, tab ];
    });
  }, [ activeTabs ]);

  const getTabName = useCallback((tab: ContractTab) => {
    return {
      [ ContractTab.CONTRACTS ]: t('tabs.contracts', { ns: 'contracts' }),
      [ ContractTab.INFO ]: t('tabs.info', { ns: 'contracts' }),
    }[ tab ];
  }, []);

  const showInfoTab = useMemo(() => activeTabs.includes(ContractTab.INFO), [ activeTabs ]);
  const showDataTab = useMemo(() => activeTabs.includes(ContractTab.CONTRACTS), [ activeTabs ]);

  const onEdit = (name: string) => {
    if (!editModalItem) {
      throw new Error('Edit modal item is not defined');
    }

    organizationsService.editDimensionsItem(editModalItem?.id, { name }).then(({ data }) => {
      dispatch(breakdownsActions.editDimensionItem(data));
    });

    dispatchContext({ type: 'HIDE_EDIT_MODAL' });
  };

  const onAdd = (name: string) => {
    organizationsService.addDimensionItem(
      contractDimension?.id,
      {
        name,
        contract: {
          name,
          customer: +id,
          startDate:
            dayjs.unix(table?.period?.startDate || table?.period?.startDatePlan)
              .utcOffset(0, true).format(),
          endDate:
            dayjs.unix(table?.period?.endDate || table?.period?.endDatePlan)
              .utcOffset(0, true).format(),

        }
      }
    ).then(({ data }) => {
      dispatchContext({ type: 'HIDE_ADD_MODAL' });
      dispatch(breakdownsActions.addDimensionItem( data ));
    });
  };

  const formattedContractName = useMemo(() => {
    return t('table.title', { ns: 'contracts' })
      .toLowerCase()
      .slice(0, -1);
  }, []);

  return (
    <>
      <div className={ styles.contractDetails }>
        <h1 className={ styles.header }>
          <OrganizationSelect />
          { t('links.manage.contracts', { ns: 'navigation' }) }
        </h1>
        <CounterpartyBreadcrumbs counterpartyId={ +id } onGoBack={ onGoBack }/>
        <div className={ styles.tabs }>
          {
            Object.values(ContractTab).map(tab => (
              <Capsule
                key={ `tab__${ tab }` }
                isActive={ activeTabs.includes(tab) }
                onClick={ () => toggleTab(tab) }
                className={ styles.tab }
              >
                { getTabName(tab) }
              </Capsule>
            ))
          }
        </div>
        { contractDimension && <FinancialTableContextProvider
          templateId={ CONTRACT_TEMPLATE_ID }
          customSettings={ tableSettings }
        >
          <div className={ styles.cardsContainer }>
            {
              showDataTab && <ContractsData/>
            }
            {
              customer && showInfoTab && (
                <ContractInfo/>
              )
            }
          </div>

        </FinancialTableContextProvider> }
      </div>
      <CreateUpdateModals onAdd={ onAdd } onEdit={ onEdit } modalName={ formattedContractName }/>
    </>
  );
};

const wrapper = () => {
  return <ModalContextProvider>
    <ContractDetails/>
  </ModalContextProvider>;
};

export default wrapper;
