import React, { useCallback } from 'react';
import { Pagination as AntdPagination, PaginationProps } from 'antd';
import styles from './Pagination.module.scss';

const Pagination = (props: PaginationProps) => {
  const itemRender: PaginationProps['itemRender'] = useCallback((_, type, original) => {
    if (type === 'prev' || type === 'next') {
      return null;
    }
    return original;
  }, []);

  return <AntdPagination
    { ...props }
    showSizeChanger={ false }
    itemRender={ itemRender }
    className={ styles.pagination }
  />;
};

export default Pagination;