import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ColGroupDef,
  ColumnEverythingChangedEvent,
  ColumnMovedEvent,
  FirstDataRenderedEvent,
  GetRowIdParams,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  RowClassRules,
  RowClickedEvent,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { SearchableContextProvider, useSearchable } from 'context/SearchableContext';
import styles from './Table.module.scss';
import Header from '../header/Header';
import { Redirect, TableColDef, TableProps } from '../types/table.types';
import ResizableContainer from '../../resizableContainer/ResizableContainer';
import useGridFocusEffect from '../../../financials/financialTable/hooks/useGridFocusEffect';
import Loader from '../../loader/Loader';
import useTableColumns from '../hooks/useTableColumns';
import Sections from '../sections/Sections';
import useTableSectionProps from '../hooks/useTableSectionProps';
import { debounce } from 'lodash';
import { HEADER_HEIGHT, ROW_HEIGHT } from '../utils/table.utils';
import dayjs from 'dayjs';
import { separateThousands } from 'utils/financials.utils';
import {
  TablePanelProps
} from '../../../financials/detailedView/transactionsTable/panels/types/panel.types';
import useAutoHeight from 'components/elements/tableWrapper/hooks/useAutoHeight';
import useTableEditableProps from 'components/elements/tableWrapper/hooks/useTableEditableProps';
import useColumnStorage from 'components/elements/tableWrapper/hooks/useColumnStorage';

const Table = <T, >({
  sections,
  activeSection,
  changeSection,
  ...providedTableProps

}: TableProps<T>) => {
  const [ firstDataRendered, setFirstDataRendered ] = useState(false);
  const isSectionFocused = useRef(false);
  const [ gridReady, setGridReady ] = useState(false);
  const [ isGridDestroyed, setIsGridDestroyed ] = useState(false);
  const [ currentRowModel, setCurrentRowModel ] = useState('clientSide');

  const {
    columnDefs,
    tableStyle,
    gridRef,
    onRowClicked,
    actions,
    groupByOptions,
    name,
    blockSearch,
    period,
    onPeriodChange,
    showSearch,
    isLoading,
    onSearch,
    panels,
    defaultSort = [],
    redirect,
    headerTools,
    defaultColDef: sectionDefaultColDef,
    isFloatingPanel,
    disableCadence,
    periodSections,
    headerHeight,
    isRowValid,
    tableKey,
    disabledTools,
    isAutoSaved,
    masterDetail,
    onGridReady: providedOnGridReady,
    ...tableProps
  } = useTableSectionProps({
    ...providedTableProps,
    activeSection
  });

  const rowData = tableProps.rowData;

  useEffect(() => {
    if (tableProps.rowModelType !== currentRowModel) {
      recreateGrid();
    }
  }, [ tableProps ]);

  const recreateGrid = () => {
    const recreate = () => {
      setIsGridDestroyed(false);
    };
    setIsGridDestroyed(true);
    setCurrentRowModel(tableProps.rowModelType);
    setTimeout(recreate, 0);
  };

  const isAutoHeightEnabled = useMemo(() => {
    return tableProps[ 'domLayout' ] === 'autoHeight';
  }, [ tableProps ]);

  const { height, getTableHeight } = useAutoHeight({
    gridRef,
    enabled: isAutoHeightEnabled
  });
  const { save, getAndApply } = useColumnStorage({ key: tableKey + (activeSection?.key || '') });

  const onSectionChange = useCallback((key: string) => {
    changeSection(key);
    isSectionFocused.current = false;
    setTimeout(
      () => {
        gridRef.current?.columnApi.autoSizeAllColumns(false);
      },
      0
    );
  }, []);

  useEffect(() => {
    if (!redirect) {
      return;
    }
    onSectionChange(redirect.sectionKey);
    recreateGrid();
  }, [ redirect ]);

  const { state: { search } } = useSearchable();

  const tableRef = useRef<HTMLDivElement>();
  const { defaultColDef } = useTableColumns({ search });
  const {
    checkboxColDef,
    rowClassRules: editableRowClassRules
  } = useTableEditableProps();
  const { focusOnFirstCell } = useGridFocusEffect({ gridRef, triggerCondition: false });

  const [ activePanelKey, setActivePanelKey ] = useState<string>(null);
  const [ selectedRows, setSelectedRows ] = useState<IRowNode[]>([]);
  const [ tableWidth, setTableWidth ] = useState(0);

  const showGroupFooters = useMemo(() => {
    const subtotalColumns = columnDefs
      .filter((col: TableColDef) => col.showSubtotal)
      .map((col: TableColDef) => col.field);
    if (rowData?.length) {
      return subtotalColumns.some((field) => field in rowData[ 0 ]);
    }
    return false;
  }, [ rowData, columnDefs ]);

  const panelsWithActivation = useMemo(() => {
    return panels?.map((panel) => {
      return {
        ...panel,
        onActivate: () => {
          if (panel.onActivate) {
            panel.onActivate();
          }
          setActivePanelKey(panel.key);
        },
        onDeactivate: () => {
          if (panel.onDeactivate) {
            panel.onDeactivate();
          }
          setActivePanelKey(null);
        },
      };
    });
  }, [ panels ]);

  useEffect(() => {
    if (showSearch && !blockSearch) {
      gridRef.current?.api?.setQuickFilter(search);
    }
  }, [ search ]);

  const debouncedOnSearch = useCallback(
    debounce(onSearch ? onSearch : () => null, 100), [ onSearch ]
  );

  useEffect(() => {
    if (onSearch) {
      debouncedOnSearch(search);
    }
  }, [ search ]);

  useEffect(() => {
    if (isLoading) {
      gridRef?.current?.api?.showLoadingOverlay();
    } else {
      gridRef?.current?.api?.hideOverlay();
    }
  }, [ isLoading ]);

  useEffect(() => {
    gridRef?.current?.api?.onFilterChanged();
  }, [ search, activeSection ]);

  const groupRendererParams = useMemo(() => {
    return {
      suppressCount: true,
      innerRenderer: (params: ICellRendererParams) => {
        const dateRegex = /date/i;
        const applied = /lastApplied/i;

        if (dateRegex.test(params.node.field) || applied.test(params.node.field)) {
          const date = dayjs(params.value);
          return (
            <div className={ styles.groupCell }>
              { date.isValid() ? date.format('YYYY-MM-DD') : null }
            </div>
          );
        }
        return (
          <div className={ styles.groupCell }>
            { params.valueFormatted }
          </div>
        );
      }
    };
  }, []);

  const onFirstDataRendered = useCallback((params: FirstDataRenderedEvent) => {
    setFirstDataRendered(true);
    setTimeout(() => {
      params.columnApi.autoSizeAllColumns();
    }, 200);
    setTableWidth(tableRef.current.offsetWidth/2 - 20);
    if (tableProps?.onFirstDataRendered) {
      tableProps.onFirstDataRendered(params);
    }
  }, [ activeSection ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridReady(true);
    providedOnGridReady?.(params);
    setTimeout(() => {
      getAndApply(gridRef.current?.columnApi);
    }, 0);
    gridRef.current?.columnApi.applyColumnState({ state: defaultSort });
    if (activeSection?.tableProps?.onGridReady) {
      activeSection.tableProps.onGridReady(params);
    }
  }, [ activeSection ]);

  const onSelectionChanged = useCallback((params: SelectionChangedEvent) => {
    tableProps.onSelectionChanged && tableProps.onSelectionChanged(params);
    if (tableProps.rowModelType === 'clientSide') {
      // This method is not available in server side
      setSelectedRows(params.api.getSelectedNodes());
    } else {
      const serverSideSelectionState = params.api.getServerSideSelectionState();
      // We can only tell which elements are toggled if we are not selecting all
      //  (select all select even nodes that are not loaded)
      if (!serverSideSelectionState[ 'selectAll' ]) {
        const toggledRows = serverSideSelectionState[ 'toggledNodes' ].map(
          nodeId => params.api.getRowNode(nodeId)
        );
        setSelectedRows(toggledRows);
      }
    }
  }, [ tableProps ]);

  const focusOnCell = useCallback((rowIndex: number) => {
    // Move focus on the end of the event loop of ag-grid events
    // to avoid losing focus when columns changes
    setTimeout(() => {
      focusOnFirstCell(rowIndex);
      isSectionFocused.current = true;
    }, 200);
  }, []);

  const redirectToRow = useCallback((_redirect: Redirect) => {
    const nodes = _redirect.rowNodeIds
      .map((id) => gridRef.current.api.getRowNode(id.toString()))
      .filter((node) => node);
    if (!nodes.length) {
      return;
    }
    const sortedNodesByDisplay = nodes.sort((a, b) => a.rowIndex - b.rowIndex);
    focusOnCell(sortedNodesByDisplay[ 0 ].rowIndex);
    nodes.forEach((node) => {
      node.setSelected(true);
    });
    _redirect.clear();
  }, []);

  useEffect(() => {
    if (firstDataRendered && !isLoading && rowData?.length && !isSectionFocused.current) {
      focusOnCell(0);
    }
  }, [ rowData, isLoading, activeSection, firstDataRendered ]);

  const onColumnEverythingChanged = useCallback((params: ColumnEverythingChangedEvent) => {
    if (tableProps?.onColumnEverythingChanged) {
      tableProps.onColumnEverythingChanged(params);
    }
  }, [ tableProps ]);

  const onColumnMoved = useCallback((params: ColumnMovedEvent) => {
    if (firstDataRendered && params.source === 'uiColumnMoved') {
      save(params.columnApi);
    }
  }, [ save ]);

  const onRowDataUpdated = useCallback((params: RowDataUpdatedEvent) => {
    getTableHeight();
    if (redirect) {
      redirectToRow(redirect);
    }
    if (tableProps?.onRowDataUpdated) {
      tableProps.onRowDataUpdated(params);
    }
  }, [ redirect, getTableHeight, tableProps ]);

  const groupOptions = useMemo(() => {
    if (groupByOptions || (rowData?.length && !rowData[ 0 ])) return groupByOptions;

    return columnDefs.map((column: TableColDef) => {
      if (rowData?.length && column?.field in rowData[ 0 ]) {
        if (!column.headerName) {
          return null;
        }
        if (column?.cellDataType !== 'text') {
          return {
            name: column?.headerName,
            field: column?.field,
          };
        }
        return {
          name: column?.headerName,
          field: column?.field,
          availableChoices: null,
        };
      }
    }).filter((el) => el);
  }, [ rowData ]);

  const addAsterisk = useCallback((colDef: TableColDef) => {
    const headerName = colDef.headerName;
    return colDef.compulsory ? `${ headerName }*` : headerName;
  }, []);

  const colDefs = useMemo(() => {
    return columnDefs
      .map((def: TableColDef) => {
        if (def.showSubtotal) {
          return {
            ...def,
            aggFunc: 'sum',
            valueFormatter: (params: ValueFormatterParams<T>) =>
              params.value ? separateThousands(Number(params.value).toFixed(2)) : null,
          };
        }
        return def;
      })
      .filter((el) => el)
      .map((def: ColGroupDef) => {
        return {
          ...def,
          headerName: addAsterisk(def),
          children: def[ 'children' ]?.map((child: TableColDef) => {
            return {
              ...child,
              headerName: addAsterisk(child),
            };
          }),
        };
      });
  }, [ columnDefs ]);

  const disableGroupSelect = useCallback((params: RowClickedEvent) => {
    if (params.node.group) {
      params.node.setSelected(false);
    }
  }, []);

  const rowClassRules: RowClassRules = useMemo(() => {
    return {
      ...editableRowClassRules,
      [ styles.invalidRow ]: (params) => {
        if (isRowValid instanceof Function && params.data && !isRowValid(params.data)) {
          return true;
        }
      }
    };
  }, [ isRowValid ]);

  const getRowId = useCallback((params: GetRowIdParams) => {
    if (params.data?.internalId) {
      return params.data.internalId;
    }
    if (params.data?.id) {
      return params.data.id;
    }
    return params.data;
  }, []);

  const defaultColumDefinitions = useMemo(() => {
    return {
      ...defaultColDef,
      ...sectionDefaultColDef ? sectionDefaultColDef : {},
    };
  }, [ sectionDefaultColDef, defaultColDef ]);

  const tableColDefs = useMemo(() => {
    return [
      {
        field: 'id',
        headerName: ' ',
        sortable: false,
        flex: 0,
        width: 30,
        suppressHide: true,
        hide: !masterDetail,
        suppressMovable: true,
        lockPosition: 'left' as const,
        resizable: false,
        cellClass: styles.masterDetailCell,
        cellDataType: 'text',
        cellRenderer: 'agGroupCellRenderer',
        valueGetter: () => '',
      },
      checkboxColDef,
      ...colDefs ];
  }, [ colDefs, checkboxColDef, masterDetail ]);

  useEffect(() => {
    setTimeout(() => {
      getAndApply(gridRef.current?.columnApi);
    }, 0);
  }, [ tableKey, activeSection ]);

  const table = useMemo(() => {
    if (tableProps.rowModelType !== currentRowModel) return null;
    return (
      !isGridDestroyed ? <AgGridReact
        ref={ gridRef }
        groupRowRendererParams={ groupRendererParams }
        animateRows={ true }
        suppressRowHoverHighlight={ true }
        getRowId={ getRowId }
        onRowClicked={ onRowClicked }
        groupDisplayType='groupRows'
        groupDefaultExpanded={ 2 }
        rowSelection='multiple'
        suppressDragLeaveHidesColumns={ true }
        defaultColDef={ defaultColumDefinitions }
        suppressPropertyNamesCheck={ true }
        rowClassRules={ rowClassRules }
        { ...tableProps }
        onSelectionChanged={ onSelectionChanged }
        masterDetail={ masterDetail }
        onColumnEverythingChanged={ onColumnEverythingChanged }
        onColumnMoved={ onColumnMoved }
        onRowDataUpdated={ onRowDataUpdated }

        onGridReady={ onGridReady }
        onFirstDataRendered={ onFirstDataRendered }
        columnDefs={ tableColDefs }
        rowHeight={ ROW_HEIGHT }
        headerHeight={ headerHeight ?? HEADER_HEIGHT }
        groupIncludeFooter={ showGroupFooters }
        suppressAggFuncInHeader={ true }
        onRowSelected={ disableGroupSelect }
        stopEditingWhenCellsLoseFocus={ true }
        rowModelType={ tableProps.rowModelType }
      /> : null
    );
  }, [
    isGridDestroyed,
    tableProps,
    tableColDefs,
    search,
    activeSection,
    sectionDefaultColDef,
    rowClassRules,
    redirect,
    rowClassRules,
    masterDetail,
    onFirstDataRendered,
    onGridReady,
    onRowDataUpdated,
    onColumnEverythingChanged,
    onColumnMoved,
    getTableHeight,
  ]);
  const additionalPanelProps: TablePanelProps<T> = useMemo(() => {
    return {
      selectedRows,
      onPanelChange: (key: string) => setActivePanelKey(key),
      maxWidth: tableWidth,
      activePanelKey: activePanelKey
    };
  }, [ selectedRows, activePanelKey ]);

  const panelElement = useMemo(() => {
    const activePanel = panelsWithActivation?.find(p => p.key === activePanelKey);
    if (!activePanel) {
      return null;
    }

    return activePanel ?
      <ResizableContainer
        dimensionToResize='width'
        minSize={ 370 }
        maxSize={ 1000 }
        defaultSize={ activePanel.defaultWidth ?? 1000 }
        onSizeChange={ setTableWidth }
      >
        { activePanel.render(additionalPanelProps) }
      </ResizableContainer> : null;
  }, [ activePanelKey, additionalPanelProps, panelsWithActivation ]);

  return (
    <>
      <Loader isActive={ isLoading } />
      <Header
        name={ name }
        onClose={ providedTableProps.onClose }
        tableKey={ tableKey }
        gridRef={ gridRef }
        quantityOfElements={ rowData?.length }
        showSearch={ showSearch }
        groupByOptions={ groupOptions }
        columnDefs={ columnDefs }
        actions={ actions }
        period={ period }
        onPeriodChange={ onPeriodChange }
        panels={ panelsWithActivation }
        activePanelKey={ activePanelKey }
        headerTools={ headerTools }
        sectionKey={ activeSection?.key }
        disableCadence={ disableCadence }
        periodSections={ periodSections }
        gridReady={ gridReady }
        firstDataRendered={ firstDataRendered || !isLoading }
        disabledTools={ disabledTools }
        isAutoSaved={ isAutoSaved }
      />
      <Sections
        items={ sections }
        activeKey={ activeSection?.key }
        onChange={ onSectionChange }
      />
      <div
        ref={ tableRef }
        className={ styles.tableContainer }
        style={ { height: `${ isFloatingPanel ? 'calc(100% - 145px)' :
          isAutoHeightEnabled ? `${ height }px` : 'calc(100vh - 215px)' }` } }
      >
        { panelElement }
        <div className={ `ag-theme-alpine ${ styles.table } ${ tableStyle }` }>
          { table }
        </div>
      </div>
    </>
  );
};

const TableWrapper = <T, >(props: TableProps<T>) => {
  const ref = props.gridRef || useRef(null);

  return (
    <SearchableContextProvider>
      <Table
        { ...props }
        gridRef={ ref }
      />
    </SearchableContextProvider>
  );
};

export default TableWrapper;
