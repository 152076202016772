import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BreakdownCategoryType,
  Dimension, DimensionItem,
  DimensionLabelingProgress
} from '../types/filterTable.types';
import { Account, Counterparty } from '../types/statutory.types';
import { arrayToMap, ItemToMap } from '../utils/mapping.utils';
import organizationsService from '../services/organizations.service';
import { AppDispatch, RootState } from './store';
import { StatutoryRowCategory } from '../types/templates.types';
import { t } from 'i18next';

export interface BreakdownsState {
  dimensions: Dimension[];
  dimensionMap: ItemToMap<Dimension>;
  dimensionItemMap: ItemToMap<DimensionItem>;
  dimensionsLoaded: boolean;
  accounts: Account[];
  accountMap: ItemToMap<Account>;

  counterparties: Counterparty[];
  counterpartiesMap: ItemToMap<Counterparty>;
  countTransactionLines: number;
}

const initialState: BreakdownsState = {
  dimensions: [],
  dimensionMap: {},
  dimensionsLoaded: false,
  dimensionItemMap: {},
  accounts: [],
  accountMap: {},
  counterparties: [],
  counterpartiesMap: {},
  countTransactionLines: 0
};

export const breakdownsSlice = createSlice({
  name: 'breakdowns',
  initialState,
  reducers: {
    setDimensions: (state, action: PayloadAction<Dimension[]>) => {
      state.dimensions = action.payload.map(fillCategory);

      let dimensionItemMap = {};
      state.dimensionMap = arrayToMap<Dimension>(state.dimensions, dimension => dimension.id);
      for (const dimension of state.dimensions) {
        dimensionItemMap = {
          ...dimensionItemMap,
          ...arrayToMap<DimensionItem>(dimension.items, dimensionItem => dimensionItem.id)
        };
        if (dimension.relation === 'ACCOUNT') {
          state.accounts = dimension.items.map(item => item.account);
          state.accountMap = arrayToMap<Account>(state.accounts, account => account.id);
        }
        if (dimension.relation === 'COUNTERPARTY') {
          state.counterparties = dimension.items.map(item => item.counterparty);
          state.counterpartiesMap = arrayToMap<Counterparty>(
            state.counterparties,
            counterparty => counterparty.id
          );
        }
      }
      state.dimensionItemMap = dimensionItemMap;
      state.dimensionsLoaded = true;
    },
    setLabelingProgress(state, action: PayloadAction<DimensionLabelingProgress[]>) {
      const progressMap = arrayToMap<DimensionLabelingProgress>(action.payload, p => p.id);
      state.dimensions = state.dimensions.map(dimension => {
        if (progressMap[ dimension.id ]) {
          dimension.transactionLines = progressMap[ dimension.id ].transactionLines;
        }
        return dimension;
      });
      state.dimensionMap = arrayToMap<Dimension>(state.dimensions, dimension => dimension.id);
    },
    addDimension: (state, action: PayloadAction<Dimension>) => {
      state.dimensions.push(action.payload);
      state.dimensionMap[ action.payload.id ] = action.payload;
    },
    addDimensionItem: (state, action: PayloadAction<DimensionItem>) => {
      const dimension = state.dimensionMap[ action.payload.dimensionId ];
      dimension.items.push(action.payload);
      state.dimensions = state.dimensions.map(oldDimension =>
        oldDimension.id === action.payload.dimensionId ? dimension : oldDimension);
      state.dimensionMap[ dimension.id ] = dimension;

      state.dimensionItemMap[ action.payload.id ] = action.payload;
    },
    editDimensionItem: (state, action: PayloadAction<DimensionItem>) => {
      const dimension = state.dimensionMap[ action.payload.dimensionId ];
      dimension.items = dimension.items.map(item =>
        item.id === action.payload.id ? action.payload : item);
      state.dimensions = state.dimensions.map(oldDimension =>
        oldDimension.id === action.payload.dimensionId ? dimension : oldDimension);
      state.dimensionItemMap[ action.payload.id ] = action.payload;
    },
    deleteDimensionItem(state, action: PayloadAction<{ itemId: number }>) {
      const item = state.dimensionItemMap[ action.payload.itemId ];
      state.dimensions = state.dimensions.map(dimension => {
        if (dimension.id === item.dimensionId) {
          dimension.items = dimension.items.filter(i => i.id !== item.id);
        }
        return dimension;
      });
      delete state.dimensionItemMap[ action.payload.itemId ];
    },
    setCountTransactionLines: (state, action: PayloadAction<number>) => {
      state.countTransactionLines = action.payload;

    },
  }
});
export const breakdownsReducer = breakdownsSlice.reducer;

export const breakdownsActions = breakdownsSlice.actions;

export function fetchAndSetDimensions() {
  return async dispatch => {
    const { data } = await organizationsService.getDimensions();
    dispatch(breakdownsActions.setDimensions(data));
    const { data: labelingProgress } = await organizationsService.getDimensionsLabelingProgress();
    dispatch(breakdownsActions.setLabelingProgress(labelingProgress));
  };
}

export const updateDimension = (dimension: Dimension) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState().breakdowns;
    dispatch(breakdownsActions.setDimensions(
      state.dimensions.map(d => d.id === dimension.id ? dimension : d)
    ));
  };

export const dimensionToggleFavourite = ({ dimensionId, favorite }: {
  dimensionId: number;
  favorite: boolean;
}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (favorite) {
      await organizationsService.markDimensionAsFavourite(dimensionId);
    } else {
      await organizationsService.unmarkDimensionAsFavourite(dimensionId);
    }

    const state = getState().breakdowns;
    const dimension = state.dimensionMap[ dimensionId ];
    dispatch(breakdownsActions.setDimensions(
      state.dimensions.map(d => d.id === dimensionId ? { ...dimension, favorite } : d)
    ));
  };

export const removeDimension = (dimension: Dimension) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState().breakdowns;
    dispatch(breakdownsActions.setDimensions(
      state.dimensions.filter(d => d.id !== dimension.id)
    ));
  };

export function fetchAndSetCountTransactionLines() {
  return async dispatch => {
    const { data } = await organizationsService.getCountTransactionLines(
      // eslint-disable-next-line camelcase
      { template_category: StatutoryRowCategory.PROFIT_AND_LOSS });
    dispatch(breakdownsActions.setCountTransactionLines(data.count));
  };
}

function fillCategory(dimension: Dimension): Dimension {
  const externalCategories = [
    BreakdownCategoryType.NETVISOR,
    BreakdownCategoryType.XERO,
    BreakdownCategoryType.PROCOUNTOR
  ];

  if (externalCategories.includes(dimension.type)) {
    return {
      ...dimension,
      category: {
        en: t('financials:left-panel.external', { lng: 'en' }),
        fi: t('financials:left-panel.external', { lng: 'fi' }),
        sv: t('financials:left-panel.external', { lng: 'sv' })
      }
    };
  }

  if (dimension.type === BreakdownCategoryType.USER) {
    return {
      ...dimension,
      category: {
        en: t('financials:left-panel.custom', { lng: 'en' }),
        fi: t('financials:left-panel.custom', { lng: 'fi' }),
        sv: t('financials:left-panel.custom', { lng: 'sv' })
      }
    };
  }

  return dimension;
}

export const selectBreakdowns = (state: RootState) => state.breakdowns;
