import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { noop } from 'lodash';
import { Dropdown, Form } from 'antd';
import { useFieldArray, useForm } from 'react-hook-form';

import type { Transaction } from 'types/statutory.types';
import { numberFormatter } from 'utils/common.utils';
import Button from 'components/elements/button/Button';
import organizationsService from 'services/organizations.service';
import Modal from 'components/elements/modal/Modal';
  
import { ReactComponent as SplitIcon } from 'assets/icons/arrow-split.svg';
import { ReactComponent as AddIcon } from 'assets/icons/plus.svg';

import styles from './DimensionSplitModal.module.scss';
import DimensionSplit from './dimensionSplit/DimensionSplit';
import { notifyBackendValidationErrors } from 'utils/notifications.utils';
import validationSchema from './validation';
import type { FormType } from './dimensionSplit.types';
import type { BudgetItem } from 'types/budget.types';
import {
  emptyDimension,
  getLabel,
  isTransaction,
  getExistingSplits,
  groupSplitItemByDimension,
  isAnyNaN,
  areTransactions,
  getDimensionSplits,
  getUnassignableTransactionSplitsForDelete,
  getUnassignableBudgetItemSplitsForDelete
} from './split.utils';
import SplitItemLabel from './splitItemLabel/SplitItemLabel';
import { useAppSelector } from 'store/hooks/hooks';
import useSplitItemDefault from './useSplitDefault';
import axios from 'axios';
import InfoBox from 'components/elements/infoBox/InfoBox';

interface DimensionSplitModalProps {
  splitItems: (Transaction | BudgetItem)[];
  onClose: (message?: 'split:created' | 'split:deleted', data?: { updated: BudgetItem[] }) => void;
}

const ModalContent = ({ splitItems, onClose }: DimensionSplitModalProps) => {
  const [ t ] = useTranslation('financials');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isDeleting, setIsDeleting ] = useState(false);

  const dimensionMap = useAppSelector(state => state.breakdowns.dimensionMap);
  const dimensionItemMap = useAppSelector(state => state.breakdowns.dimensionItemMap);

  const saveSplits = async (splits: { dimensionItem: number; percentage: number }[]) => {
    try {
      if (areTransactions(splitItems)) {
        await organizationsService.addDimensionToTransaction({
          transactionLines: splitItems.map(tl => tl.id),
          split: splits
        });

        onClose('split:created');
      } else {
        const fullDimensionItems = (splitItems as BudgetItem[])
          .map(item => item.dimensionSplit?.filter(d => {
            const isFull = Number(d.percentage) === 100;
            // If we are creating new split and there is already full dimension item assignment,
            // we should remove it.
            const isOverridden = splits.some(s =>
              dimensionItemMap[ s.dimensionItem ].dimensionId ===
              dimensionItemMap[ d.dimensionItem ].dimensionId);
            return isFull && !isOverridden;
          }));

        const oldSplits = (splitItems as BudgetItem[])
          .map(item => item.dimensionSplit?.filter(d => Number(d.percentage) !== 100));

        const updated = (splitItems as BudgetItem[]).map((b, index) => ({
          ...b,
          dimensionSplit: [
            ...splits,
            ...(oldSplits?.[ index ] ?? []),
            ...(fullDimensionItems?.[ index ] ?? [])
          ]
            .filter((split, i, self) => {
              return self.findIndex(s => s.dimensionItem === split.dimensionItem) === i;
            })
        }));

        updated.forEach(item => {
          item.dimensionItemIds = [
            ...item.dimensionItemIds,
            ...(item.dimensionSplit?.map(d => d.dimensionItem) ?? [])
          ].filter((id, index, self) => self.indexOf(id) === index);
        });

        updated.forEach(item => {
          // TODO: Type verification needed
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          item.dimensionItems = item.dimensionItemIds.map(id => dimensionItemMap[ id ]) as any;
        });

        onClose('split:created', { updated });
      }

    } catch (e) {
      if (axios.isAxiosError(e) && e?.response?.status === 400) {
        notifyBackendValidationErrors(e);
      }
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const createSplit = async (data: FormType) => {
    const split: { dimensionItem: number; percentage: number; draft: boolean }[] = [];
    data.dimensions
      .filter(dimension => dimension.dimension !== null)
      .filter(
        dimension => !dimension.labels.every(label => typeof label.dimensionItemId === 'string')
      )
      .forEach(dimension => {
        dimension.labels
          .filter(label => label.dimensionItemId != null)
          .forEach(label => {
            split.push({
              dimensionItem: label.dimensionItemId as number,
              percentage: label.percentage,
              draft: label.draft
            });
          });
      });

    setIsLoading(true);

    await saveSplits(split);
  };

  const onDelete = () => {
    const existingSplits = getExistingSplits(splitItems);

    if (existingSplits.length === 0) {
      onClose();
    } else {
      deleteSplits(splitItems);
    }
  };

  const deleteSplits = async (splits: (BudgetItem | Transaction)[]) => {
    if (isTransaction(splits.at(0))) {

      const requests = [];

      const payload = getUnassignableTransactionSplitsForDelete(
        splits as Transaction[], dimensionMap
      );

      requests.push(
        payload.map(item => organizationsService.addDimensionToTransaction(item))
      );

      const groupedTransactions = groupSplitItemByDimension(splits, dimensionItemMap);

      requests.push(Object.entries(groupedTransactions)
        .filter(([ dimension ]) => {
          return !!dimension && dimensionMap[ Number(dimension) ].canBeUnassigned;
        })
        .map(([ dimension, items ]) => {
          return organizationsService.clearDimensions({
            transactionLines: items.map(item => item.splitItemId),
          }, Number(dimension));
        }));

      try {
        setIsDeleting(true);
        await Promise.all(requests.flat());

        onClose('split:deleted');
  
      } catch (e) {
        if (axios.isAxiosError(e) && e?.response?.status === 400) {
          notifyBackendValidationErrors(e);
        }
        throw e;
      } finally {
        setIsDeleting(false);
      }
    } else {
      const fullDimensionItems = (splitItems as BudgetItem[])
        .map(item => item.dimensionSplit?.filter(d => Number(d.percentage) === 100));

      const updated = (splitItems as BudgetItem[]).map((b, index) => ({
        ...b,
        dimensionSplit: [
          ...(fullDimensionItems?.[ index ] ?? []),
          ...(getUnassignableBudgetItemSplitsForDelete(b, dimensionItemMap, dimensionMap) ?? [])
        ]
      }));

      onClose('split:deleted', { updated });
    }
  };

  const getDefaultValues = useSplitItemDefault();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors },
    trigger: triggerValidation
  } = useForm<FormType>({
    resolver: async(...args) => await yupResolver(validationSchema())(...args),
    mode: 'all',
    defaultValues: getDefaultValues(splitItems, getNominalValue(splitItems))
  });

  const { fields, append, remove } = useFieldArray({
    name: 'dimensions',
    control
  });

  const removeDimension = (index: number) => {
    remove(index);
    triggerValidation();
  };

  const addDimension = () => {
    append(emptyDimension);
    triggerValidation();
  };

  const availableItems = splitItems.map((item) => ({
    label: <SplitItemLabel splitItem={ item } />,
    key: item.id
  }));

  const getSelectedItemsLabel = (items: (Transaction | BudgetItem)[]) => {
    if (items.length === 1) {
      return getLabel(items[ 0 ]);
    } else {
      return t('dimension-split.modal.multiple');
    }
  };

  const nominalValue = getNominalValue(splitItems);

  useEffect(() => {
    const values = getValues();
    if (values.dimensions.length === 1 && values.dimensions.at(0).dimension == null) {
      (document.querySelector('input#dimension_0') as HTMLInputElement)?.focus();
    }
  }, []);

  const total = getSplitItemsSum(splitItems);

  const splitsWillBeDeleted = getDimensionSplits(splitItems).length;

  return <Form className={ styles.form } onFinish={ handleSubmit(createSplit) }>

    <div className={ styles.formContent }>
      <div>
        <label className={ styles.label }>
          { t('dimension-split.modal.selected') }
        </label>

        <div>
          <Dropdown
            className={ styles.tlsDropdown }
            disabled={ availableItems.length < 2 }
            overlayClassName={ styles.tlsDropdownOverlay }
            getPopupContainer={ (trigger) => trigger }
            menu={ { items: availableItems } }>
            <div className={ styles.selectedTLsLabel }>
              
              <span className={ styles.description }>
                <div className={ styles.tlsBadge }>
                  {
                    isTransaction(splitItems.at(0)) ?
                      t('dimension-split.modal.actual-badge') :
                      t('dimension-split.modal.budget-badge')
                  }
                </div>
                { getSelectedItemsLabel(splitItems) }
              </span>
              { total ?
                <span className={ styles.sum }>
                  { t('dimension-split.modal.total') }
                  { '  ' }
                  <span>
                    { total }
                  </span>
                </span> : 
                <span></span>
              }
              
            </div>
          </Dropdown>
        </div>
      </div>

      <div className={ styles.dimensions }>
        {
          fields.map((field, index) => {
            return <DimensionSplit
              key={ field.id }
              index={ index }
              errors={ errors }
              isDraft={ field.draft }
              nominalValue={ nominalValue }
              control={ control }
              reset={ reset }
              getValues={ getValues }
              removeDimension={ removeDimension }
              setValue={ setValue }
            />;
          })
        }

        <div>
          <Button
            onClick={ addDimension }
            type='default'
            size='large'
            className={ styles.addDimension }
            icon={ <AddIcon /> }
          >
            { t('dimension-split.modal.add-dimension') }
          </Button>
        </div>
      </div>

      { (errors?.dimensions as unknown as Record<string, string>)?.message && (
        <InfoBox type='warning'>
          { (errors?.dimensions as unknown as Record<string, string>)?.message }
        </InfoBox>
      ) }
    </div>

    <div className={ styles.footerButtons }>
      { splitsWillBeDeleted > 0 ? <Button
        onClick={ onDelete }
        disabled={ isLoading }
        loading={ isDeleting }
        danger
        type='link'
      >
        { t('dimension-split.modal.deleteSplit') }
        { '  ' }
        (
        { splitsWillBeDeleted }
        )
      </Button> : <span></span>
      }
      <Button
        className={ styles.submitButton }
        type='primary'
        htmlType='submit'
        disabled={ isDeleting }
        loading={ isLoading }
        size='large'
      >
        { t('common:form.apply') }
      </Button>
    </div>
  </Form>;
};

interface Props extends DimensionSplitModalProps {
  isOpen: boolean;
}

const DimensionSplitModal = ({ isOpen, onClose, splitItems }: Props) => {
  const [ t ] = useTranslation('financials');

  return <Modal
    isVisible={ isOpen }
    onConfirm={ noop }
    disableButtons
    destroyOnClose
    confirmDisabled
    className={ styles.modal }
    title={ <>
      <SplitIcon />
      <p>
        { t('dimension-split.modal.title') }
        { '  ' }
        <span className={ styles.tlsCount }>{ splitItems.length }</span>
      </p>
    </> }
    onClose={ () => onClose() }
  >
    <>
      { isOpen ?
        <ModalContent splitItems={ splitItems } onClose={ onClose } /> :
        null
      }
    </>
  </Modal>;
};

export default DimensionSplitModal;

function getAmount(splitItem: Transaction | BudgetItem) {
  return isTransaction(splitItem) ? Number(splitItem.amount) : splitItem.amountFormula;
}

function getNominalValue (splitItems: (Transaction | BudgetItem)[]): number | null {
  if (splitItems.length === 1) {
    const amount = Number(getAmount(splitItems.at(0)));
    return isNaN(amount) ? null : amount;
  } else {
    return null;
  }
}

function getSplitItemsSum(splitItems: (Transaction | BudgetItem)[]): string | null {
  if (isAnyNaN(splitItems.map(getAmount))) return null;

  const sum = numberFormatter(
    splitItems.reduce((acc, item) => acc + (getAmount(item) as number), 0),
    true
  );

  if (sum.includes('NaN')) {
    return null;
  }

  return sum;
}
