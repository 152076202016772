import Capsule from 'components/elements/capsule/Capsule';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ColumnType,
  DynamicColumnType,
  DynamicSettingsConfiguration,
} from 'types/financials.types';
import { Cadence } from 'types/form.types';
import { isElementDisabled } from '../../dynamicColumns.utils';
import styles from './DynamicColumnGroup.module.scss';
import OverlappingColumnsInfo
  from 'components/financials/dynamicColumns/overlappingColumnsInfo/OverlappingColumnsInfo';
import { BVA_COLUMNS } from 'utils/grid.utils';
import { Tooltip } from 'antd';

interface Props {
  type: ColumnType;
  values: DynamicSettingsConfiguration[];
  onClick: (element: DynamicSettingsConfiguration) => void;
  cadence: Cadence;
  disableOptions: boolean;
}

const DynamicColumnGroup = ({ type, onClick, values, cadence, disableOptions }: Props) => {
  const [ t ] = useTranslation('dynamicColumns');

  const getButtonDisplayValue = useCallback((value: DynamicColumnType) => {
    /**
     * t('dropdown.GRAND')
     * t('dropdown.CY')
     * t('dropdown.YTD')
     * t('dropdown.TTM')
     * t('dropdown.Q')
     * t('dropdown.H')
     * t('dropdown.ACTUAL_TOTAL')
     * t('dropdown.BUDGET_TOTAL')
     * t('dropdown.REVENUE%_BASE')
     * t('dropdown.REVENUE%_TOTALS')
     * t('dropdown.POP')
     * t('dropdown.POP%')
     * t('dropdown.YOY')
     * t('dropdown.YOY%')
     * t('dropdown.BVA')
     * t('dropdown.BVA%')
     * t('dropdown.BVA_TOTALS')
     */
    return t(`dropdown.${ value }`);
  }, []);

  const isBvaSelected = useMemo(() => {
    return values.some((element) => element.active &&
      BVA_COLUMNS.includes(element.value)
    );
  }, [ values ]);

  return (
    <div className={ styles.group }>
      <div className={ styles.title }>
        { t(`columnType.${ type }`) }
      </div>
      <div className={ styles.settings }>
        {
          values.map((element) => (
            <Tooltip
              key={ `dynamic__column__${ type }__${ element.id }` }
              title={ element.subtitle }
            >
              <div>
                <Capsule
                  className={ `
                  ${ styles.capsule }
                  ${ isElementDisabled(cadence, element) ? styles.disabled : '' }
                ` }
                  isActive={ element.active }
                  disabled={ disableOptions }
                  onClick={ () => onClick(element) }
                >
                  { getButtonDisplayValue(element.value) }
                </Capsule>
              </div>
            </Tooltip>
          ))
        }
      </div>
      <OverlappingColumnsInfo
        display={ type === ColumnType.BUDGET_VARIANCE && isBvaSelected }
      />
    </div>
  );
};

export default DynamicColumnGroup;