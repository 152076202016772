import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { IRowNode } from 'ag-grid-community';
import styles from './BudgetItemPopover.module.scss';
import GhostIconButton from '../../button/ghostIcon/GhostIconButton';
import Button from '../../button/Button';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { useForm, useWatch } from 'react-hook-form';
import { Form } from 'antd';
import useKeyPressListener from 'hooks/useKeyPressListener';
import { AgGridReact } from 'ag-grid-react';
import AddFieldDropdown from '../addFieldDropdown/AddFieldDropdown';
import FieldProxy from './editors/FieldProxy';
import useFieldsDefinitions from './useFieldDefinitions';
import { DimensionItem } from 'types/filterTable.types';
import { ReactComponent as FullScreenIcon } from 'assets/icons/fullScreen.svg';
import dayjs from 'dayjs';
import { updateDetailedViewSettings } from 'store/financials.slice';
import { DetailedViewType, FinancialNode } from 'types/financials.types';
import { useTranslation, } from 'react-i18next';
import { sectionKeys } from 'components/financials/detailedView/utils/detailedView.utils';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { refocusCell, getRowDataForDetailedView } from 'components/financials/utils/addRow.utils';

import { ReactComponent as StartPointIcon } from 'assets/icons/start-point.svg';
import { ReactComponent as EndPointIcon } from 'assets/icons/end-point.svg';
import { ReactComponent as RecurringIcon } from 'assets/icons/recurring.svg';
import { ReactComponent as ClockOnwardIcon } from 'assets/icons/clock-onward.svg';

import { useGetInputsQuery } from 'store/api/inputs.api';
import { Cadence } from 'types/form.types';
import {
  createUpdates,
  COLUMN_DATE_FORMAT,
  defaultFields,
  getDefaults,
  getDefaultsForMultiple,
  getEntries,
  setPlaceholdersForDimensions,
} from './budgetOverview.utils';
import ReorderFields from './ReorderFields';
import SelectEntries from './SelectEntries';

const fieldToIconMap = {
  'startDate': <StartPointIcon />,
  'endDate': <EndPointIcon />,
  'duration': <ClockOnwardIcon />,
  'invoicingFrequency': <RecurringIcon />
};

interface Props {
  gridApi: AgGridReact['api'];
  nodes: IRowNode[];
  columns?: string[];
  popoverRef?: React.RefObject<HTMLDivElement>;
  cadence: Cadence;
  onClose: (message?: string) => void;
  onRowDelete?: (nodes: FinancialNode[]) => void;
}

const BudgetItemPopover = ({
  gridApi,
  nodes,
  columns,
  popoverRef,
  onClose,
  onRowDelete,
  cadence = Cadence.month
}: Props) => {
  const { t } = useTranslation('financials');

  const entriesList = getEntries(nodes, columns);

  if (entriesList.length > 1) {
    entriesList.unshift({ key: null, memo: '', startDate: '' });
  }

  const placeholderMap = useRef(new Map<string, string>());

  const appDispatch = useAppDispatch();
  const dimensionsMap = useAppSelector(state => state.breakdowns.dimensionMap);
  const dimensionsItemsMap = useAppSelector(state => state.breakdowns.dimensionItemMap);
  const activeTemplateId = useAppSelector(state => state.financials.active?.templateId);

  const [ selectedEntry, setSelectedEntry ] = useState<string | null>(
    entriesList.length < 2 ? entriesList.at(0)?.key : null
  );

  const nodesForUpdate = useMemo(() => {
    if (selectedEntry == null) return nodes;

    const [ column, nodeId ] = selectedEntry.split('__');

    return nodes.filter((node) => {
      return node.id === nodeId && Object.keys(node.data.rowData.entries).includes(column);
    });
  }, [ nodes, selectedEntry ]);

  const { data: inputsResponseData } = useGetInputsQuery({});
  const inputs = inputsResponseData?.results || [];

  const { fieldsMap, groups } = useFieldsDefinitions();

  const [ savedFields, setSavedFields ] = useLocalStorage<string[]>('edit-budget-item-fields', []);

  const salesBudgetItem = useAppSelector(state => state.budget.budgetItemTypes)
    .find(item => item.name?.[ 'en' ] === 'Sales');

  const { control, reset, getValues, handleSubmit } = useForm({
    defaultValues: selectedEntry == null ? getDefaultsForMultiple(
      nodes,
      salesBudgetItem,
      placeholderMap.current,
      columns
    ) : getDefaults(
      nodes.find(node => node.id === selectedEntry.split('__').at(1)),
      `plan__${ selectedEntry.split('__').at(0) }`,
      salesBudgetItem
    )
  });

  const visibleFields = useMemo(() => {
    if (savedFields.length) {
      setPlaceholdersForDimensions(
        placeholderMap.current,
        nodes,
        savedFields.filter(f => f.startsWith('dimension.'))
      );
      return savedFields;
    }

    const dimensions = Array.from(
      new Set (
        nodes.flatMap(node => node?.data?.rowData?.dimensionItems
          .map((dimensionItem: DimensionItem) => dimensionsMap[ dimensionItem.dimensionId ])
          .filter(dimension => dimension != null && dimension.customName !== 'Counterparty')
          .map((dimension) => `dimension.${ dimension.id }`)
        )
      )
    );

    const extendedFieldsSet = [
      ...defaultFields,
      ...dimensions
    ];

    setPlaceholdersForDimensions(placeholderMap.current, nodes, dimensions);

    return extendedFieldsSet;
  }, [ savedFields, nodes ]);

  const onFieldSelected = useCallback((id: string) => {
    if (visibleFields.includes(id)) return;
    setSavedFields([ ...visibleFields, id ]);

    const values = getValues();
    placeholderMap.current.clear();
    const defaults = getDefaultsForMultiple(
      nodes, salesBudgetItem, placeholderMap.current, columns
    );
    reset({ ...values, [ id ]: defaults[ id ] });
  }, [ visibleFields ]);

  const onFieldRemove = useCallback((id: string) => {
    setSavedFields(visibleFields.filter(f => f !== id));
  } , [ visibleFields ]);

  useKeyPressListener({ code: 'Escape', cb: () => onClose() });

  useLayoutEffect(() => {
    setTimeout(() => {
      const firstInput = popoverRef?.current?.querySelector('input');
      firstInput?.focus();
    });
  }, []);

  const submit = (data) => {
    // ? Don't apply undefined in multiple entries edit as it can override multiple values fields
    if (selectedEntry == null) {
      for (const item in data) {
        if (data[ item ] === undefined) {
          delete data[ item ];
        }
      }
    }

    // ? for column placement
    const startDate = dayjs(data.startDate ?? null).startOf(cadence).format(COLUMN_DATE_FORMAT);

    const updates = createUpdates(
      data,
      nodesForUpdate,
      dimensionsItemsMap,
      dimensionsMap,
      columns,
      selectedEntry,
      startDate
    );

    gridApi.applyTransaction({ update: updates });

    onClose('budgetItem:update');
    setTimeout(() => {
      refocusCell(gridApi, nodes.at(0).rowIndex, `plan__${ startDate }`);
    });
  };

  const removeRow = () => {
    onRowDelete?.(nodesForUpdate.map(node => node.data) as FinancialNode[]);
    onClose('budgetItem:remove');
  };

  const openDetailedViewAsModal = () => {
    if (!nodesForUpdate.length) return;

    const budgetItems = nodesForUpdate
      .flatMap((node) => getRowDataForDetailedView(node, Object.keys(node.data.rowData.entries)))
      .filter((bi) => bi.amountFormula != null);

    if (activeTemplateId) {
      appDispatch(updateDetailedViewSettings({
        type: DetailedViewType.MODAL,
        templateId: activeTemplateId,
        sectionKey: sectionKeys.budgeting,
        data: { budgetItems }
      }));

      onClose();
    }
  };

  const endDate = useWatch({ control, name: 'endDate' });

  const formConfig = useMemo(() => {
    if (endDate) {
      return {
        duration: {
          disabled: true,
          message: t('inline-edit.warnings.duration-disclaimer'),
          messageType: 'warning'
        } as const
      };
    } else {
      return {};
    }

  }, [ endDate ]);

  const onEntrySelect = (key: string) => {
    if (key === selectedEntry) return;
    setSelectedEntry(key);

    if (key != null) {
      const [ column, nodeId ] = key.split('__');
      const selectedNode = nodes.find(node => node.id === nodeId);
      placeholderMap.current.clear();
      reset(getDefaults(selectedNode, `plan__${ column }`, salesBudgetItem));
    } else {
      placeholderMap.current.clear();
      reset(getDefaultsForMultiple(nodes, salesBudgetItem, placeholderMap.current, columns));
    }
  };

  return (
    <div ref={ popoverRef } className={ styles.popover }>
      <div className={ styles.header }>
        <div className={ styles.title }>{ t('inline-edit.budgetItem') }</div>
        <div className={ styles.headerActions }>
          <GhostIconButton onClick={ openDetailedViewAsModal }>
            <FullScreenIcon className={ styles.actionIcon } />
          </GhostIconButton>
          <GhostIconButton onClick={ () => onClose() }>
            <CloseIcon className={ styles.actionIcon } />
          </GhostIconButton>
        </div>
      </div>

      <SelectEntries
        entriesList={ entriesList }
        selectedEntry={ selectedEntry }
        onEntrySelect={ onEntrySelect }
      />

      <div className={ styles.divider } />  

      <Form onFinish={ handleSubmit(submit) }>
        <div className={ styles.container }>

          <ReorderFields visibleFields={ visibleFields } setSavedFields={ setSavedFields }>
            <SortableContext
              strategy={ rectSortingStrategy }
              items={ visibleFields }>
              { visibleFields.map(field => {
                return <FieldProxy
                  key={ field }
                  formConfig={ formConfig }
                  inputs= { inputs }
                  prefixIcon={ fieldToIconMap[ field ] }
                  control={ control }
                  remove={ () => onFieldRemove(field) }
                  fieldName={ field }
                  field={ fieldsMap.get(field) }
                  placeholder={ placeholderMap.current.get(field) }
                />;
              }) }
            </SortableContext>
          </ReorderFields>

          <AddFieldDropdown fields={ groups } onSelect={ onFieldSelected } />
        </div>

        <div className={ styles.footer }>
          <Button onClick={ removeRow } type='link' danger>{ t('common:delete') }</Button>
          <Button
            type='primary'
            className={ styles.submitButton }
            size='large'
            htmlType='submit'>
            { t('common:form.apply') }
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default BudgetItemPopover;
