import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store/store';
import { LoginResponse, Organization, User, UserStore } from 'types/auth.types';
import {
  getActiveOrganizationIdCookie,
  handleLogin,
  setActiveOrganizationIdCookie
} from 'utils/auth.utils';
import { chatSlice } from './chat.slice';

const initialState: UserStore = {
  user: null,
  organization: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        user: action.payload
      };
    },
    setOrganization: (state, action: PayloadAction<Organization>) => {
      return {
        ...state,
        organization: action.payload
      };
    }
  }
});

export const loginUser = (data: LoginResponse, saveToken: boolean) => (dispatch: AppDispatch) => {
  handleLogin(data.accessToken, data.refreshToken, saveToken);
  dispatch(authSlice.actions.saveUser(data.user));
  dispatch(setUserOrganization(data.user));
};

export const setUserOrganization = (user: User) => (dispatch: AppDispatch) => {
  const activeOrganization = getActiveOrganizationIdCookie() || user.organizations[ 0 ]?.id;
  setActiveOrganizationIdCookie(activeOrganization);
  const organization = user.organizations?.find(org => org.id === +activeOrganization);
  dispatch(authSlice.actions.setOrganization(organization));
  dispatch(chatSlice.actions.setConsent(organization.chatbotConsent));
};

export const { saveUser } = authSlice.actions;
export const selectAuthentication = (state: RootState) => state.auth;
export const authenticationReducer = authSlice.reducer;
