import {
  isBreakdownNode,
  isDimensionItemNode,
  isFinancialsNode,
  Template,
  TemplateNodeMapping,
  TemplateNode,
  TemplateType,
  isCounterpartyTypeNode,
  isGroupTypeNode,
  isTotalNode,
  isFormulaNode,
  isSubtotalNode,
} from 'types/templates.types';
import {
  isDimensionItem,
  isDimensionType,
  isFinancialType,
  isGroup,
  isGroupType,
  isLastChild,
  isLastChildInBranch,
  isLastChildShallow,
  isSpacer,
  isSumUpRow,
  isTitleRow,
  templateHelpers
} from 'utils/template.utils';
import { isOtherTag } from 'utils/templates.utils';
import { RowType } from 'types/financials.types';

export type PlacementNode = {
  nodeBefore: number | string;
  parent: number | string;
  isRoot: boolean;
  moveChildren?: boolean;
  fillMissingNodes?: boolean;
  isTop?: boolean;
  replacingDimension?: boolean;
};

export type DroplinePosition = 'top' | 'bottom' | 'none';

export const TemplatePlacementService = (
  item: TemplateNode,
  overNode: TemplateNode,
  template: Template,
  dropline: DroplinePosition,
  utils: {
    financialNodes: TemplateNodeMapping<RowType.FINANCIALS>;
    dimensionNodes: TemplateNodeMapping<RowType.BREAKDOWN>;
    node?: TemplateNode;
  }
) => {
  const { financialNodes, dimensionNodes, node } = utils;

  const getPlacement = (): PlacementNode => {
    if (isFinancialsNode(item) || isSumUpRow(item)) {
      return getFinancialPlacement();
    }
    if (isDimensionType(item)) {
      return getDimensionPlacement();
    }
    if (isDimensionItem(item)) {
      return getDimensionItemPlacement();
    }
    if (isFormulaNode(item) || isSubtotalNode(item) || isTotalNode(item)) {
      return getFormulaPlacement();
    }
    if (isOtherTag(item?.type)) {
      return getOtherPlacement();
    }
    throw Error(`Unknown template type ${ item?.type }`);
  };

  const getDefaultPlacement = (): PlacementNode => {
    return {
      nodeBefore: null,
      parent: null,
      isRoot: true,
      moveChildren: false,
      isTop: false,
    };
  };

  const getFinancialNodeBefore = (nodeOver: number | string) => {
    const over = template.nodes[ nodeOver ];
    if (isLastChild(template, over)) {
      return over.parent;
    }
    return nodeOver;
  };

  const getFinancialPlacement = (): PlacementNode => {
    const nodeOver = template.nodes[ overNode?.id ];
    if (!nodeOver) {
      return getDefaultPlacement();
    }

    if (isGroupType(nodeOver) && (
      dropline === 'none' || (dropline === 'bottom' && nodeOver.children.length)
    )) {
      return {
        nodeBefore: null,
        parent: nodeOver.id,
        isRoot: false,
        moveChildren: false,
        isTop: false,
      };
    }

    const helpers = templateHelpers(
      template,
      financialNodes,
      dimensionNodes
    );
    const greatParent = helpers.getGreatParent(nodeOver);
    const nodeBefore = getFinancialNodeBefore(nodeOver.id);
    let parent: number | string = null;
    if (
      isFinancialsNode(item) &&
      isFinancialsNode(nodeOver) &&
      helpers.isAncestor(item, nodeOver)
    ) {
      const commonParent = helpers.getCommonParent(item, nodeOver, greatParent.id);
      const parentNode = helpers.getCommonParentNode(greatParent.id, commonParent);
      parent = dropline === 'top' ? null : parentNode?.id;
      const fillMissingNodes = nodeOver.rowData.id === item.id &&
          dropline === 'none' &&
          item.childrenNodes != null &&
          item.childrenNodes.length > 0;
      return {
        nodeBefore: fillMissingNodes ? nodeOver.id : nodeBefore,
        parent,
        isRoot: parent == null,
        fillMissingNodes,
        isTop: dropline === 'top' ||
            nodeOver?.children.length > 0 ||
            (nodeOver?.children?.includes(node?.id) && dropline === 'bottom'),
      };
    }

    return {
      nodeBefore,
      parent,
      isRoot: true,
      isTop: dropline === 'top' || nodeOver?.children.length > 0,
    };
  };

  const getDimensionPlacement = (): PlacementNode => {
    const nodeOver = template.nodes[ overNode?.id ];
    let parent = isFinancialType(nodeOver) ||
      isGroupType(nodeOver) ||
      isDimensionItem(nodeOver) ||
      isDimensionType(nodeOver) ? nodeOver.id : null;
    let nodeBefore = null;
    const isDroppingCounterpartyTypeOnSibling =
      nodeOver && isCounterpartyTypeNode(nodeOver) && isCounterpartyTypeNode(item);

    const isDroppingCounterpartyTypeOnParentWith = nodeOver?.children?.some((child) => {
      const childNode = template.nodes[ child ];
      return isCounterpartyTypeNode(childNode);
    }) && isCounterpartyTypeNode(item);
    if (isDroppingCounterpartyTypeOnSibling) {
      parent = nodeOver.parent;
    }
    if (isDroppingCounterpartyTypeOnParentWith) {
      parent = nodeOver.id;
    }
    const dontMoveChildren =
      isDroppingCounterpartyTypeOnSibling || isDroppingCounterpartyTypeOnParentWith;

    if (dropline === 'bottom' &&
      isLastChildInBranch(template, nodeOver) &&
      !nodeOver?.children.length &&
      !isDimensionType(nodeOver)
    ) {
      const helpers = templateHelpers(template, financialNodes, dimensionNodes);
      parent = null;
      nodeBefore = helpers.getGreatParent(nodeOver).id;
    }

    const isTop = dropline === 'top';
    if (isTop) {
      nodeBefore = null;
      parent = null;
    }
    const droppingRootOnRoot = isDimensionType(nodeOver) &&
      isDimensionType(item) &&
      !nodeOver.parent && dropline !== 'none';
    if (droppingRootOnRoot) {
      parent = null;
      nodeBefore = nodeOver.id;
    }

    return {
      nodeBefore,
      parent,
      isRoot: parent == null,
      moveChildren: dontMoveChildren ? false : parent != null && !isTop,
      isTop,
      replacingDimension: !dontMoveChildren && !droppingRootOnRoot
    };
  };

  const getDimensionItemPlacement = (): PlacementNode => {
    const helpers = templateHelpers(template, financialNodes, dimensionNodes);
    const nodeOver = template.nodes[ overNode?.id ];

    if (nodeOver && isDimensionItemNode(nodeOver) && isDimensionItemNode(item)) {
      const isSibling = helpers.areDimensionsSibling(nodeOver, item);
      const isSiblingInBranch = helpers.checkDimensionItemSiblingRelation(nodeOver, item);

      const placeOnRoot = !isSiblingInBranch && nodeOver.parent == null;
      if (isSiblingInBranch && dropline !== 'top') {
        return {
          nodeBefore: null,
          parent: nodeOver?.id,
          isRoot: placeOnRoot,
          moveChildren: false,
          isTop: false,
        };
      }

      if (isSibling && dropline !== 'top') {
        return {
          nodeBefore: nodeOver.id,
          parent: nodeOver.parent,
          isRoot: placeOnRoot,
          moveChildren: false,
          isTop: false,
        };
      }
    }

    const getRootPlacement = (
      placeInBranch: boolean,
      isPlacingOnDimension: boolean,
      moveChildren = false
    ): PlacementNode => {
      if (dropline === 'bottom' &&
      isLastChildInBranch(template, nodeOver) &&
      !nodeOver?.children.length
      ) {
        return {
          nodeBefore: helpers.getGreatParent(nodeOver).id,
          parent: null,
          isRoot: true,
          moveChildren,
          isTop: false,
        };
      }
      if (placeInBranch && dropline !== 'top') {
        return {
          nodeBefore: null,
          parent: nodeOver?.id,
          isRoot: false,
          moveChildren,
          isTop: false,
          replacingDimension: isPlacingOnDimension
        };
      }
    };

    if (nodeOver && isGroupTypeNode(nodeOver) && isDimensionItemNode(item)) {
      const isSiblingInBranch = helpers.checkDimensionItemSiblingRelation(nodeOver, item);
      const placement = getRootPlacement(isSiblingInBranch, false);
      if (placement) return placement;
    }
    if (nodeOver && isBreakdownNode(nodeOver) && isDimensionItemNode(item)) {
      const isChild = helpers.isDimensionChild(nodeOver, item);
      const isSiblingInBranch = helpers.checkDimensionItemSiblingRelation(nodeOver, item);
      const isOtherDimensionInBranch = helpers.checkDimensionItemSiblingRelation(
        nodeOver,
        item,
        false
      );
      const placingChildWithoutSibling = isChild && !isSiblingInBranch;
      const placement = getRootPlacement(
        (isChild && !isOtherDimensionInBranch) || isSiblingInBranch,
        placingChildWithoutSibling,
        placingChildWithoutSibling,
      );

      if (placement) return placement;
    }
    if (nodeOver &&
      isFinancialsNode(nodeOver) &&
      isDimensionItemNode(item) &&
      helpers.checkDimensionItemSiblingRelation(nodeOver, item)
    ) {
      const placement = getRootPlacement(true, false);
      if (placement) return placement;
    }

    let parent = (isFinancialType(nodeOver) ||
      isGroupType(nodeOver) ||
      isDimensionItem(nodeOver) ||
      isDimensionType(nodeOver)) && dropline !== 'top' ? nodeOver.id : null;
    let nodeBefore = dropline === 'top' ? null : nodeOver?.id;

    if (dropline === 'bottom' &&
      isLastChildInBranch(template, nodeOver) &&
      !nodeOver?.children.length
    ) {
      parent = null;
      nodeBefore = helpers.getGreatParent(nodeOver).id;
    }
    return {
      nodeBefore,
      parent,
      isRoot: parent == null,
      moveChildren: parent != null,
      isTop: dropline === 'top',
      replacingDimension: !isGroup(nodeOver),
    };
  };

  const getFormulaPlacement = (): PlacementNode => {
    if (isTotalNode(item)) {
      return getDefaultPlacement();
    }
    let overNodeId = overNode?.id;
    const nodeOver = template.nodes[ overNode?.id ];
    let isRoot = false;
    if (!nodeOver?.children?.length && nodeOver?.parent) {
      overNodeId = nodeOver.parent;
    }
    if (
      (!nodeOver?.children?.length && !nodeOver?.parent) ||
      (isLastChildInBranch(template, nodeOver) && dropline !== 'none')
    ) {
      isRoot = true;
    }
    if (isGroup(nodeOver) && nodeOver.children.length) {
      isRoot = false;
      overNodeId = nodeOver.id;
    }
    const helpers = templateHelpers(template, financialNodes, dimensionNodes);
    return {
      nodeBefore: isRoot ? helpers.getGreatParent(template.nodes[ overNodeId ])?.id : overNodeId,
      parent: !isRoot ? overNodeId : null,
      isRoot,
      isTop: dropline === 'top',
    };
  };

  const getOtherPlacement = (): PlacementNode => {
    const nodeOver = template.nodes[ overNode?.id ];
    let parent: number | string = nodeOver?.parent;
    const helpers = templateHelpers(template, financialNodes, dimensionNodes);
    let nodeBefore = !parent ? helpers.getGreatParent(nodeOver)?.id : nodeOver?.id;

    if (isLastChildShallow(template, nodeOver) && dropline === 'bottom') {
      parent = template.nodes[ parent ]?.parent;
      nodeBefore = nodeOver.parent;
    }
    if (isLastChildInBranch(template, nodeOver) &&
      !nodeOver?.children.length &&
      dropline === 'bottom'
    ) {
      parent = null;
      nodeBefore = helpers.getGreatParent(nodeOver)?.id;
    }
    if (isGroup(nodeOver) || nodeOver?.children.length || (isGroup(item) && dropline === 'none')) {
      const canNodeNestGroup =
        isFinancialType(nodeOver) ||
        isDimensionType(nodeOver) ||
        isSumUpRow(nodeOver);
      parent = canNodeNestGroup ? nodeOver.id : null;
    }
    if (isGroup(nodeOver) &&
      (isSpacer(TemplateType[ item.type ]) || isTitleRow(TemplateType[ item.type ]))
    ) {
      const canNestInBranch = nodeOver.children.length;
      parent = canNestInBranch ? nodeOver.id : nodeOver.parent;
      nodeBefore = nodeOver.id;
    }
    if (isGroup(nodeOver) && isGroup(item)) {
      parent = nodeOver.id;
    }
    if (dropline === 'top') {
      parent = null;
      nodeBefore = null;
    }
    return {
      nodeBefore,
      parent,
      isRoot: parent == null,
      isTop: dropline === 'top' || nodeOver?.children?.length > 0,
    };
  };

  return {
    getPlacement
  };
};
