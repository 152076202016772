import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DetailedViewTable from '../table/DetailedViewTable';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  clearDetailedViewSettings,
  updateDetailedViewSettings,
} from 'store/financials.slice';
import { ReactComponent as FullScreenIcon } from 'assets/icons/fullScreen.svg';
import { DetailedViewType } from 'types/financials.types';
import styles from './DetailedViewFloatingPanel.module.scss';
import { useTranslation } from 'react-i18next';
import { FinancialTableContextProvider } from 'context/FinancialTableContext';
import ResizableContainer from 'components/elements/resizableContainer/ResizableContainer';
import Portal from 'components/elements/portal/Portal';
import { FLOATING_PANEL_PORTAL_ID } from
  'components/financials/detailedView/utils/detailedView.utils';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

const TOP_BAR = 60;
const COMMAND_BAR = 57;
const LAYOUT_TOP_BAR = 60;
const TABLE_TOP_BAR = 70;

interface Props {
  templateId: number;
  onSizeChange?: (newSize: number) => void;
  onTableRefresh?: () => void;
}

const DetailedViewFloatingPanel = ({ templateId, onSizeChange, onTableRefresh }: Props) => {
  const [ t ] = useTranslation('common');
  const dispatch = useAppDispatch();
  const [ viewportHeight, setViewportHeight ] = useState(window.innerHeight);

  const [ openedTimestamp ] = useState(Date.now());

  const labelingTimestamp = useAppSelector(
    state => state.financials.tables[ templateId ]?.labelingTimestamp);

  useEffect(() => {
    function handleResize() {
      setViewportHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onClose = useCallback(() => {
    if (labelingTimestamp > openedTimestamp) {
      onTableRefresh();
    }
    dispatch(clearDetailedViewSettings());
  }, [ labelingTimestamp, openedTimestamp, onTableRefresh ]);

  const openDetailedViewAsModal = useCallback(() => {
    dispatch(updateDetailedViewSettings({
      type: DetailedViewType.MODAL,
    }));
  }, [ templateId ]);

  const headerTools = useMemo(() => ([ {
    key: 'open-modal',
    button: <GhostIconButton
      tooltip={ t('redirect.show-as-modal') }
      onClick={ openDetailedViewAsModal }
    >
      <FullScreenIcon/>
    </GhostIconButton>,
    buttonPosition: 2,
  } ]), [ openDetailedViewAsModal ]);

  return <Portal portalId={ FLOATING_PANEL_PORTAL_ID }>
    <div className={ styles.floatingPanel } id='floating-panel'>
      <FinancialTableContextProvider templateId={ templateId }>
        <ResizableContainer
          className={ styles.resizableContainer }
          dimensionToResize='height'
          defaultSize={ 550 }
          minSize={ 320 }
          maxSize={ viewportHeight - TOP_BAR - COMMAND_BAR - LAYOUT_TOP_BAR - TABLE_TOP_BAR }
          linePlacement='top'
          onSizeChange={ onSizeChange }
        >
          <DetailedViewTable
            onClose={ onClose } headerTools={ headerTools } />
        </ResizableContainer>
      </FinancialTableContextProvider>
    </div>
  </Portal>;
};

export default DetailedViewFloatingPanel;
