import React, { createContext, useContext, useReducer } from 'react';
import { ContextType } from 'types/app.types';
import { UUID } from 'types/templates.types';

interface ChartContext {
  settingsPanel: boolean;
  hoverValue: number;
  disableClose?: boolean;
  tooltipText: string;
  templateNodeUuids?: UUID[];
}

const defaultState: ChartContext = {
  settingsPanel: true,
  hoverValue: null,
  tooltipText: null,
};

type DispatchTypes =
  | ActionTogglePanel
  | ActionUpdateHoverValue
  | ActionUpdateTooltip;

const ChartContext = createContext<ContextType<ChartContext, DispatchTypes>>(undefined);

interface ActionTogglePanel {
  type: 'SHOW_SETTINGS_PANEL' |'CLOSE_SETTINGS_PANEL' | 'TOGGLE_SETTINGS_PANEL';
}

interface ActionUpdateHoverValue {
  type: 'UPDATE_HOVER_VALUE';
  payload: number;
}

interface ActionUpdateTooltip {
  type: 'UPDATE_TOOLTIP';
  payload: string;
}

const chartReducer = (state: ChartContext, action: DispatchTypes) => {
  switch (action.type) {
    case 'SHOW_SETTINGS_PANEL': {
      return { ...state , settingsPanel: true };
    }
    case 'CLOSE_SETTINGS_PANEL': {
      return { ...state , settingsPanel: false };
    }
    case 'TOGGLE_SETTINGS_PANEL': {
      return { ...state , settingsPanel: !state.settingsPanel };
    }
    case 'UPDATE_HOVER_VALUE': {
      return { ...state , hoverValue: action.payload };
    }
    case 'UPDATE_TOOLTIP': {
      return { ...state , tooltipText: action.payload };
    }
  }
};

interface ProviderProps {
  children: React.ReactNode;
  disableClose?: boolean;
  templateNodeUuids?: UUID[];
}

const ChartContextProvider = ({
  children,
  disableClose=false,
  templateNodeUuids,
}: ProviderProps) => {
  const [ state, dispatch ] = useReducer(chartReducer, {
    ...{ ...defaultState, templateNodeUuids },
    disableClose,
  });
  const value = { state, dispatch };

  return <ChartContext.Provider value={ value }>
    { children }
  </ChartContext.Provider>;
};

const useChartContext = () => {
  const context = useContext(ChartContext);
  if (context === undefined) {
    throw new Error('useChartContext must be used within Provider');
  }
  return context;
};

export { ChartContextProvider, useChartContext };
