import React from 'react';
import CommandBarButton from 'components/commandBar/components/button/CommandBarButton';
import { CommandBarTextButton as TextButtonT } from 'components/commandBar/types/commandBar.types';
import styles from './CommandBarTextButton.module.scss';

interface Props {
  button: TextButtonT;
}

const CommandBarTextButton = ({ button }: Props) => {
  return <CommandBarButton
    button={ { ...button, tooltip: undefined } }
    onClick={ button.disabled ? undefined : button.onClick }
    className={ styles.button }
    type={ button.buttonType ?? 'primary' }
  >
    { button.name }
  </CommandBarButton>;
};

export default CommandBarTextButton;