import { Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './SortDropdown.module.scss';
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import Capsule from '../../elements/capsule/Capsule';

interface SortOption {
  name: string;
  type: string;
}

interface SortDropdownProps {
  options: SortOption[];
  title: string;
  onSelect: (option: SortOption) => void;
}

const SortDropdown = ({ options, title, onSelect }: SortDropdownProps) => {
  const [ activeDropdown, setActiveDropdown ] = useState(false);
  const [ selectedOption, setSelectedOption ] = useState<SortOption>(null);
  const isActiveElement = (option: SortOption) => {
    return selectedOption?.name === option.name;
  };

  useEffect(() => {
    setSelectedOption(options[ 0 ]);
    onSelect(options[ 0 ]);
  }, []);

  const onClick = useCallback((option: SortOption) => {
    setSelectedOption(option);
    onSelect(option);
  }, []);

  return (
    <Dropdown
      placement='bottomRight'
      trigger={ [ 'click' ] }
      open={ activeDropdown }
      onOpenChange={ (isVisible) => setActiveDropdown(isVisible) }
      dropdownRender={ () =>
        <div className={ styles.listContainer }>
          {
            options.map(item =>
              <div
                className={ `
                ${ styles.listItem }
                ${ isActiveElement(item) ? styles.activeElement : '' }`
                }
                onClick={ () => onClick(item) }
                key={ item.name }>
                { item.name }
                {
                  isActiveElement(item) && (
                    <CheckIcon className={ styles.checkIcon } width={ 16 } height={ 16 }/>
                  )
                }
              </div>
            )
          }
        </div>
      }>
      <Capsule isActive={ activeDropdown }>
        <div className={ styles.capsule }>
          <span>{ `${ title }: ${ selectedOption?.type }` }</span>
          <Chevron
            width={ 16 } height={ 16 }
            className={ `${ styles.arrowIcon } ${ activeDropdown ? styles.activeDropdown : '' }` }
          />
        </div>
      </Capsule>
    </Dropdown>
  );
};

export default SortDropdown;
