export const UTIL_KEYS = [
  'filter', 
  'label',
  'sort',
  'group',
  'layout',
  'columns',
  'divider.1', // <- for multiple dividers add numbers
  'detailed-view',
  'pdf',
  'export',
  'settings',
  'addRow',
  'removeRow',
  'overviewDetails',
  'chat',
  'cta',
  'save',
  'mode'
] as const;

export const RIGHT_SIDE_KEYS: (typeof UTIL_KEYS[number])[] = [ 'cta', 'save' ];
