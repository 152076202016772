import React, { createContext, useContext, useReducer } from 'react';

type FormulaRowAction =
  | { type: 'CLOSE' }
  | { type: 'OPEN' }
  | { type: 'SAVE'; payload: string };

type FormulaRowContextValues = {
  state: FormulaRowContextType;
  dispatch: React.Dispatch<FormulaRowAction>;
};

type FormulaRowContextType = {
  open: boolean;
  formula: string;
};

const defaultState: FormulaRowContextType = {
  open: false,
  formula: '',
};

const FormulaRowContext = createContext<FormulaRowContextValues>(undefined);

const FormulaRowReducer = (
  state: FormulaRowContextType,
  action: FormulaRowAction
): FormulaRowContextType => {
  switch (action.type) {
    case 'CLOSE': {
      return defaultState;
    }
    case 'OPEN': {
      return {
        ...state,
        open: true,
      };
    }
    case 'SAVE': {
      return {
        ...state,
        formula: action.payload,
      };
    }
    default: {
      throw new Error('Unhandled action type in FormulaRowContext');
    }
  }
};

type Props = {
  children: React.ReactNode;
};

const FormulaRowContextProvider = ({ children }: Props) => {
  const [ state, dispatch ] = useReducer(FormulaRowReducer, defaultState);
  const value = { state, dispatch };

  return <FormulaRowContext.Provider value={ value }>
    { children }
  </FormulaRowContext.Provider>;
};

const useFormulaRow = () => {
  const context = useContext(FormulaRowContext);
  if (context === undefined) {
    throw new Error('useFormulaRow must be used within Provider');
  }
  return context;
};

export { FormulaRowContextProvider, useFormulaRow, FormulaRowContext };
