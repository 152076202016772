const keyIsString = (keySelector: Function | string): keySelector is string => {
  return typeof keySelector === 'string';
};

export type ItemToMap<T> = { [ id: number ]: T };

export function arrayToMap<T>(
  array: T[], keySelector: (item: T) => string | number, many: boolean): { [ key: string ]: T[] };
export function arrayToMap<T>(
  array: T[], keySelector: (item: T) => string | number): { [ key: string ]: T };
export function arrayToMap<T>(
  array: T[], keySelector: string): { [ key: string ]: T };
export function arrayToMap<T>(
  array: T[], keySelector: string, many: boolean): { [ key: string ]: T[] };
export function arrayToMap<T>(
  array: T[], keySelector: ((item: T) => string | number) | string, many = false):
  { [ key: string ]: T[] } {
  return array?.reduce((result, item) => {
    let key: string | number;
    if (keyIsString(keySelector)) {
      key = item[ keySelector ];
    } else {
      key = keySelector(item);
    }

    if (many) {
      result[ key ] ? result[ key ].push(item) : result[ key ] = [ item ];
    } else {
      result[ key ] = item;
    }
    return result;
  }, {});
}
