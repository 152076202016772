import React from 'react';
import styles from './SpinningLoader.module.scss';

type Props = {
  className?: string;
  size?: number;
};

const SpinningLoader = ({ className, size=16 }: Props) => {
  return (
    <div className={ `${ styles.loader } ${ className ?? '' }` }>
      <div style={ { width: `${ size }px`, height: `${ size }px` } }/>
      <div style={ { width: `${ size }px`, height: `${ size }px` } }/>
      <div style={ { width: `${ size }px`, height: `${ size }px` } }/>
      <div style={ { width: `${ size }px`, height: `${ size }px` } }/>
    </div>
  );
};

export default SpinningLoader;
