import React, { RefObject, useMemo, useRef, useState, MouseEvent } from 'react';
import {
  BreakdownCategoryType,
  Dimension,
  FilterList
} from 'types/filterTable.types';
import styles from './DimensionsListView.module.scss';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';
import { useSearchable } from 'context/SearchableContext';
import DimensionItem from '../dimensionDetails/detailsList/DimensionItem/DimensionItem';
import { AgGridReact } from 'ag-grid-react';
import AddEditDimensionModal
  from 'components/elements/dimensionLeftPanel/addEditDimensionModal/AddEditDimensionModal';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { getDimensionDisplayName } from 'utils/financials.utils';
import useDimension from '../../hooks/useDimension';
import { AssignFunction } from '../../labelingTypes';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { groupBy } from 'lodash';  
import { getDisplayName } from 'utils/common.utils';

import DimensionRow from './dimensionRow/DimensionRow';
import ScrollDown from 'components/elements/scrollDown/ScrollDown';
import clsx from 'clsx';

interface Props {
  dimensions: Dimension[];
  mode: 'label' | 'filter';
  setDimensionDetails: (value: number) => void;
  templateId: number | null;
  gridRef?: RefObject<AgGridReact>;
  isTemplateBuilder?: boolean;
  outerFilters?: FilterList;
  stagingFilter?: FilterList;
  setStagingFilter?: (value: FilterList) => void;
  assignLabels?: AssignFunction;
  className?: string;
  onClearFilters?: (event: MouseEvent, dimensionId?: number) => void;
}

const DimensionsListView = ({
  dimensions,
  mode,
  setDimensionDetails,
  templateId,
  outerFilters,
  stagingFilter,
  gridRef,
  setStagingFilter,
  assignLabels,
  isTemplateBuilder = false,
  onClearFilters = () => null,
  className = ''
}: Props) => {
  const [ t, i18n ] = useTranslation('financials');

  const [ modalAddDimension, setModalAddDimension ] = useState(false);

  const { state: { search } } = useSearchable();

  const { onAddDimension } = useDimension({ dimension: null });
  const templateFilters = useAppSelector(state => state.financials.tables[ templateId ]?.filter);
  const filters = outerFilters || templateFilters;

  const scrollableElement = useRef<HTMLDivElement>(null);

  const isContractView = false;

  const categories = useMemo(() => {
    const favourites = dimensions.filter(dimension => dimension.favorite);

    const groupedDimensions = {
      ...(
        favourites.length > 0 ?
          { [ t('left-panel.favourites') ]: dimensions.filter(dimension => dimension.favorite) }
          : {}
      ),
      ...groupBy(
        dimensions, (item) => getDisplayName(item.category, '')
      )
    };

    if (groupedDimensions[ t('financials:left-panel.custom') ] == null) {
      groupedDimensions[ t('financials:left-panel.custom') ] = [];
    }

    return groupedDimensions;
  }, [ dimensions, i18n.language, t ]);

  const isCustomCategory = (category: string) => category === t('financials:left-panel.custom');

  return (
    <div className={ clsx(styles.container, className) }
      style={ isTemplateBuilder ? { height: 'fit-content', overflow: 'initial' } : { } }
      ref={ scrollableElement }>
      <AddEditDimensionModal
        isVisible={ modalAddDimension }
        onClose={ () => setModalAddDimension(false) }
        onConfirm={ async (name) => {
          await onAddDimension(name);
          setModalAddDimension(false);
        } }
        title={ <div>
          <EditIcon/>
          { t('left-panel.add-dimension.create-dimension-label') }
        </div> }
        label={ t('left-panel.add-dimension.custom-dimension-name') }
        okText={ t('left-panel.add-dimension.ok-text') }
        defaultValue={ t('left-panel.add-dimension.new-label') }
      />

      {
        Object.entries(categories).map(
          ( [ category, items ] ) => {
            const results = searchInDimension(items, search);

            return (items.length > 0 || isCustomCategory(category)) && (
              <div className={ styles.categorySection } key={ category }>
                {
                  category && <div className={ styles.dimensionCustom }>
                    <span className={ styles.title }>{ category || '' }</span>
                    {
                      isCustomCategory(category) &&
                        <GhostIconButton onClick={ () => setModalAddDimension(true) }>
                          <Plus />
                        </GhostIconButton>
                    }
                  </div>
                }
                <div className={ styles.wrapper }>
                  {
                    results
                      .map(dimension => {
                        return (
                          <React.Fragment key={ dimension.id }>
                            <DimensionRow 
                              dimension={ dimension }
                              setDimensionDetails={ setDimensionDetails }
                              onClearFilters={ onClearFilters }
                              search={ search }
                              templateId={ templateId }
                              filters={ filters?.find((d) => d.dimension === dimension.id) }
                              isContractView={ isContractView }
                              isLabeling={ mode === 'label' }
                              isTemplateBuilder={ isTemplateBuilder }
                              category={ category }
                            />
                            <div className={ styles.dimensionItems }>
                              {
                                searchInDimensionItem(dimension, search)
                                  .map(dimensionItem => {
                                    return (
                                      <DimensionItem
                                        item={ dimensionItem }
                                        key={ `${ dimension.id }__${ dimensionItem.id }` }
                                        stagingFilter={ stagingFilter }
                                        gridRef={ gridRef }
                                        setStagingFilter={ setStagingFilter }
                                        canDelete={ dimension.canAddItems &&
                                dimensionItem.type === BreakdownCategoryType.USER
                                        }
                                        canEdit={ dimension.canEditName }
                                        isTemplateBuilder={ isTemplateBuilder }
                                        isLabeling={ mode === 'label' }
                                        assignLabels={ assignLabels }
                                      />
                                    );
                                  })
                              }
                            </div>
                          </React.Fragment>
                        );
                      }
                      )
                  }
                </div>
              </div>
            );
          })
      }
      <ScrollDown scrollableElementRef={ scrollableElement } />
    </div>
  );
};

export default DimensionsListView;

function searchInDimension(dimensions: Dimension[], search = '') {
  return dimensions?.filter(dimension => dimension?.items?.filter(el =>
    getDimensionDisplayName(el)?.toLowerCase().includes(search?.toLowerCase())).length > 0 ||
    getDimensionDisplayName(dimension)?.toLowerCase().includes(search?.toLowerCase()));
}

function searchInDimensionItem(dimension: Dimension, search: string) {
  if (search === '') return [];

  return dimension.items
    ?.filter(item =>
      getDimensionDisplayName(item)?.toLowerCase()
        .includes((search ?? '').toLowerCase())
    );
}
