import React, { useEffect, useMemo } from 'react';
import { Progress } from 'antd';
import styles from './Messages.module.scss';
import { useAppDispatch } from 'store/hooks/hooks';
import { endBudgetGeneration } from 'store/budget.slice';

interface Props {
  step: number;
  totalSteps: number;
}

const BudgetGenerationInProgress = ({ step, totalSteps }: Props) => {
  const percent = useMemo(() => {
    return Math.round((step / totalSteps) * 100);
  }, [ step, totalSteps ]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(endBudgetGeneration());
    };
  }, []);

  return (
    <div className={ `${ styles.message } ${ styles.messageInProgress }` }>
      <Progress
        strokeColor='#4CAF50'
        percent={ percent }
        showInfo={ false }
      />
      <span className={ styles.percent }>
        { percent }
        { ' ' }
        %
      </span>
    </div>
  );
};

export default BudgetGenerationInProgress;
