import React from 'react';
import styles from '../styles/Badge.module.scss';
import { roundTransactionAmount, separateThousands } from 'utils/financials.utils';

interface Props {
  amount: number;
  className?: string;
}

const AmountBadge = ({ amount, className = '' }: Props) => {
  const amountOrZero = +amount || 0;

  const isPositive = amountOrZero >= 0;
  const formatAmount = (num: number) => {
    return separateThousands(roundTransactionAmount(num));
  };

  return (
    <div className={ `
    ${ styles.badge }
    ${ isPositive ? styles.positive: styles.negative }
    ${ className }` }
    >
      { formatAmount(amountOrZero) }
      { ' ' }
      &euro;
    </div>
  );
};

export default AmountBadge;
