import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import styles from './Capsule.module.scss';

type Props = {
  isActive?: boolean;
  onClick?: () => void;
  onDoubleClick?: () => void;
  children: React.ReactNode;
  className?: string;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  padding?: string;
} & React.HTMLProps<HTMLButtonElement>;

const Capsule = ({
  isActive,
  onClick,
  children,
  id='',
  className='',
  type='button',
  disabled=false,
  padding,
  ...props
}: Props) => {
  const [ outlineAnimation, setOutlineAnimation ] = useState(false);

  const handleClick = useCallback(() => {
    setOutlineAnimation(true);
    onClick?.();
    setTimeout(() => {
      setOutlineAnimation(false);
    }, 300);
  }, [ onClick ]);

  return (
    <button
      type={ type }
      className={ clsx(
        styles.pill,
        {
          [ styles.outlineAnimation ]: outlineAnimation,
          [ styles.active ]: isActive
        },
        className
      ) }
      onClick={ handleClick }
      style={ { padding } }
      id={ id }
      disabled={ disabled }
      { ...props }
    >
      <span>{ children }</span>
    </button>
  );
};

export default Capsule;
