import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useTranslation } from 'react-i18next';
import { Cadence, StatementsForm, StatementsRequestForm } from 'types/form.types';
import styles from './DatePickersSection.module.scss';
import DatePickerToggle from '../datePickerToggle/DatePickerToggle';
import Section from './Section';
import { useLocation } from 'react-router-dom';
import useConfigOptions from 'components/financials/financialTable/hooks/useConfigOptions';
import CadenceSwitcher from '../../../financials/cadenceSwitcher/CadenceSwitcher';
import { PeriodSectionsMode } from 'types/financials.types';

dayjs.extend(advancedFormat);

interface Props {
  cadence: Cadence;
  setCadence: React.Dispatch<React.SetStateAction<Cadence>>;
  sections: PeriodSectionsMode;
  getDefaultValues: () => StatementsRequestForm;
  templateId?: number;
  disableCadence?: boolean;
}

const DatePickersSection = ({
  cadence,
  setCadence,
  sections,
  getDefaultValues,
  templateId,
  disableCadence,
}: Props) => {
  const [ t ] = useTranslation('financials');
  const { control, setValue } = useFormContext<StatementsRequestForm>();
  const values = useWatch({ control });
  const { actualsOpen, planOpen } = values;

  const { radioListActuals, radioListPlans } = useConfigOptions();

  const location = useLocation();

  const onSwitcherChange = useCallback((value: Cadence) => {
    setCadence(value);
  }, []);

  const toggleActuals = useCallback((value: boolean) => {
    setValue('actualsOpen', value);
  }, [ actualsOpen ]);

  const togglePlan = useCallback((value: boolean) => {
    setValue('planOpen', value);
  }, [ planOpen ]);

  const disableOtherSection = useCallback((otherOpened: boolean) => {
    if (sections === 'multiple') {
      return false;
    }
    return otherOpened;
  }, []);

  const isRevenueRecognition = location.pathname.includes('revenue-recognition');
  return (
    <div className={ styles.rangeDatePicker }>
      <div className={ styles.top }>
        <div className={ styles.leftSide }>
          <span className={ styles.title }>
            { t('datePicker.date-range') }
          </span>
        </div>
        <div className={ styles.rightSide }>
          {
            !disableCadence ? <CadenceSwitcher
              value={ cadence }
              onChange={ onSwitcherChange }
              excludedCadences={ isRevenueRecognition ? [ Cadence.week, Cadence.quarter ] : [] }
            /> : null
          }
        </div>
      </div>
      <div className={ styles.section }>
        <div>
          <DatePickerToggle
            label={ t('datePicker.sections.actuals') }
            value={ actualsOpen }
            onChange={ toggleActuals }
            disabled={ !planOpen || disableOtherSection(planOpen) }
          />
          {
            actualsOpen && (
              <Section
                nameStart={ StatementsForm.startDateActuals }
                nameEnd={ StatementsForm.endDateActuals }
                listOptions={ radioListActuals }
                cadence={ cadence }
                countEndOf={ false }
                templateId={ templateId }
                getDefaultValues={ getDefaultValues }
                sectionType='actuals'
              />
            )
          }
        </div>
        <div>
          {
            !isRevenueRecognition && (
              <DatePickerToggle
                label={ t('datePicker.sections.budget') }
                value={ planOpen }
                onChange={ togglePlan }
                disabled={ !actualsOpen || disableOtherSection(actualsOpen) }
              />
            )
          }
          {
            planOpen && (
              <div className={ styles.calendarSection }>
                {
                  planOpen && (
                    <Section
                      nameStart={ StatementsForm.startDatePlan }
                      nameEnd={ StatementsForm.endDatePlan }
                      listOptions={ radioListPlans }
                      cadence={ cadence }
                      countEndOf={ true }
                      getDefaultValues={ getDefaultValues }
                      actualsOpen={ actualsOpen }
                      sectionType='plan'
                    />
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default DatePickersSection;
