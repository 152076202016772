import { selectPanelTable, selectViewId } from 'store/financials.slice';
import { debounce } from 'lodash';
import { useFinancialTable } from 'context/FinancialTableContext';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks/hooks';
import { isStickyHeaderActive } from 'utils/financials.utils';
import { useResizeDetector } from 'react-resize-detector';

interface Props {
  tableRef: React.RefObject<HTMLDivElement>;
  tableWidth: number;
}

const COMMAND_BAR = 50;
const TOP = 72 + COMMAND_BAR;
const TOP_STICK = 72 + COMMAND_BAR;
const TABLE_PADDING = 36;
const STICKY_START = 0;

enum HeightUpdate {
  UP = 1,
  NONE = 0,
  DOWN = -1,
}

const useStickyHeader = ({ tableRef, tableWidth }: Props) => {
  const { state: { templateId, customSettings: { disableStickyHeader } } } = useFinancialTable();
  const tablePosition = useAppSelector(state => {
    const capsules = state.financials.capsuleList;
    return capsules.findIndex(capsule => capsule.templateId === templateId);
  });

  const [ layoutHeaderHeight, setLayoutHeaderHeight ] = useState(null);

  const scrollContainer = document.querySelector('#scrollContainer');

  const isGridReady = useAppSelector(state => state.financials.tables[ templateId ]?.gridReady);
  const panelTable = useAppSelector(selectPanelTable);
  const viewId = useAppSelector(selectViewId);
  const isStickyHeader = useMemo(() => isStickyHeaderActive(viewId), [ viewId ]);
  const [ isStickyState, setIsStickyState ] = useState(false);
  if (!isStickyHeader) return { isSticky: false, active: false };

  const layoutHeader = useRef<HTMLDivElement | null>(document.querySelector('#layout-header'));
  const innerContentRef = useRef<HTMLDivElement | null>(document.querySelector('#inner-content'));
  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const floatingTopRef = useRef(null);
  const stickyRef = useRef(null);
  const topBarRef = useRef(null);
  const lastScrollPos = useRef(0);
  const tableHeightUpdate = useRef<HeightUpdate>(HeightUpdate.NONE);
  const originalStyles= useMemo(() => ({
    position: '',
    top: '',
  }), []);

  const updateTableHeight = useCallback((multiplier: HeightUpdate) => {
    const topBarHeight = topBarRef.current.getBoundingClientRect().height;
    const table = tableRef.current;
    const change = multiplier * topBarHeight;
    const tableHeight = table.getBoundingClientRect().height;
    if (tableHeightUpdate.current === multiplier) {
      return tableHeight;
    }
    tableHeightUpdate.current = multiplier;
    table.style.height = `${ tableHeight + change }px`;

    return tableHeight + change;
  }, []);

  const {
    height: innerHeight,
    width: innerWidth
  } = useResizeDetector({ targetRef: innerContentRef });

  useLayoutEffect(() => {
    layoutHeader.current = document.querySelector('#layout-header');
    // 16 is the padding of the layout header
    setLayoutHeaderHeight(layoutHeader.current?.getBoundingClientRect().height + innerHeight + 16);
  }, [ innerHeight ]);

  useEffect(() => {
    if (!tableRef.current || !isGridReady || disableStickyHeader) {
      topBarRef.current = null;
      headerRef.current = null;
      bodyRef.current = null;
      floatingTopRef.current = null;
      return;
    }
    topBarRef.current = tableRef.current.parentElement.querySelector('.ag-top-bar');
    headerRef.current = tableRef.current.querySelector('.ag-header');
    bodyRef.current = tableRef.current.querySelector('.ag-body-viewport');
    floatingTopRef.current = tableRef.current.querySelector('.ag-floating-top');
  }, [ tableRef.current, isGridReady, disableStickyHeader ]);

  const updateContainerWidth = useCallback(() => {
    if (!headerRef.current || !topBarRef.current || !tableWidth) return;

    headerRef.current.style.width = `${ tableWidth - 2 }px`;
    topBarRef.current.style.maxWidth = `${ tableWidth + TABLE_PADDING }px`;
  }, [ stickyRef.current, headerRef.current, topBarRef.current, tableWidth ]);

  useEffect(() => {
    scrollContainer.addEventListener('resize', updateContainerWidth);
    return () => scrollContainer.removeEventListener('resize', updateContainerWidth);
  }, [ updateContainerWidth ]);

  useEffect(() => {
    updateContainerWidth();
  }, [ panelTable, updateContainerWidth ]);

  const onScroll = useCallback(() => {
    const header = headerRef.current;
    const body = bodyRef.current;
    const floatingTop = floatingTopRef.current;
    const topBar = topBarRef.current;
    if (!header || !body || !floatingTop || !topBar) return;

    let shouldStick = false;
    let shouldUnstick = false;

    const scrollPos = scrollContainer.scrollTop;
    const isScrollingDown = scrollPos > lastScrollPos.current;

    const headerHeight = header.getBoundingClientRect().height;
    const topBarHeight = topBar.getBoundingClientRect().height;
    const topHeaderHeight = headerHeight + topBarHeight + (layoutHeaderHeight ?? TOP);
    const isScrollAfterTable = body.getBoundingClientRect().bottom < topHeaderHeight;
    if (!stickyRef.current) {
      shouldStick = !isScrollAfterTable && scrollPos >= STICKY_START;
      if (shouldStick) stickyRef.current = true;
    }
    if (stickyRef.current) {
      const topTablePosition = body.getBoundingClientRect().top - headerHeight - topBarHeight;

      shouldUnstick = topTablePosition > (layoutHeaderHeight ?? TOP_STICK) ||
        (isScrollAfterTable && isScrollingDown);
      if (shouldUnstick) stickyRef.current = false;
    }
    if (shouldStick) {
      updateTableHeight(HeightUpdate.UP);
      floatingTop.style.height = `${ headerHeight + topBarHeight }px`;
      floatingTop.style.minHeight = `${ headerHeight + topBarHeight }px`;
      floatingTop.style.display = 'block';
      topBar.style.position = 'fixed';
      topBar.style.top = `${ layoutHeaderHeight ?? TOP }px`;
      topBar.style.width = `${ tableWidth + TABLE_PADDING }px`;
      header.style.position = 'fixed';
      header.style.top = `${ (layoutHeaderHeight ?? TOP) + topBarHeight }px`;
      header.style.width = `${ tableWidth }px`;
      topBar.style.zIndex = 500 - tablePosition;
    }
    if (shouldUnstick && isScrollAfterTable) {
      const bodyHeight = body.getBoundingClientRect().height;

      topBar.style.position = 'absolute';
      topBar.style.top = `${ bodyHeight }px`;
      topBar.style.width = 'inherit';
      topBar.style.zIndex = 100 - tablePosition;

      header.style.position = 'absolute';
      header.style.top = `${ bodyHeight + topBarHeight }px`;
      header.style.width = `${ tableWidth }px`;
    } else if (shouldUnstick) {
      resetSettings();
    }
    lastScrollPos.current = scrollPos;
  }, [ originalStyles, tablePosition, layoutHeaderHeight ]);

  const resetSettings = useCallback(() => {
    if (!headerRef.current || !floatingTopRef.current || !topBarRef.current) return;

    const header = headerRef.current;
    const floatingTop = floatingTopRef.current;
    const topBar = topBarRef.current;

    updateTableHeight(HeightUpdate.DOWN);
    topBar.style.position = originalStyles.position;
    topBar.style.top = originalStyles.top;
    topBar.style.width = 'inherit';
    topBar.style.zIndex = 100 - tablePosition;
    header.style.position = originalStyles.position;
    header.style.top = originalStyles.top;
    header.style.width = `${ tableWidth }px`;
    floatingTop.style.height = '0px';
    floatingTop.style.minHeight = '0px';
    floatingTop.style.display = 'none';

    stickyRef.current = false;
  }, [ tablePosition ]);

  const resetScroll = useCallback(debounce(() => {
    if (scrollContainer.scrollTop === 0) {
      resetSettings();
    }
  }, 200), [ resetSettings ]);

  useEffect(() => {
    if (!isGridReady || disableStickyHeader) return;

    scrollContainer.addEventListener('scroll', onScroll);
    scrollContainer.addEventListener('scroll', resetScroll);
    return () => {
      scrollContainer.removeEventListener('scroll', onScroll);
      scrollContainer.removeEventListener('scroll', resetScroll);
    };
  }, [ onScroll, isGridReady, disableStickyHeader ]);

  useEffect(() => {
    if (isGridReady) {
      resetSettings();
    }
  }, [ isGridReady, tablePosition, layoutHeaderHeight, innerWidth ]);

  // Update sticky header on layout header height change
  useEffect(() => {
    if (!isGridReady || disableStickyHeader) return;

    onScroll();
  }, [ layoutHeaderHeight, onScroll, isGridReady, disableStickyHeader ]);

  useEffect(() => {
    if (!disableStickyHeader) {
      setIsStickyState(stickyRef.current);
    }
  }, [ stickyRef.current, disableStickyHeader ]);

  return {
    isSticky: isStickyState
  };
};

export default useStickyHeader;
