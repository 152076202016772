import React, { useCallback, useMemo } from 'react';
import InlineStatement from 'components/elements/inlineStatement/InlineStatement';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'types/statutory.types';
import styles from './CreateRulePage.module.scss';
import InlineDatePicker from 'components/elements/inlineDatePicker/InlineDatePicker';
import { DimensionToLabel } from 'types/labeling.types';
import DimensionCapsule from 'components/elements/dimensionCapsule/DimensionCapsule';
import { getDisplayName } from 'utils/common.utils';
import CounterpartyLogo from 'components/elements/counterpartyLogo/CounterpartyLogo';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

interface Props {
  counterparties: {
    [key: string]: Transaction[];
  };
  startDate: Dayjs;
  setStartDate: (date: Dayjs) => void;
  dimensions: DimensionToLabel[];
  isStartDateValid: boolean;
  transactionsWithoutCounterparty: number;
}

const CreateRulePage = ({
  counterparties,
  dimensions,
  startDate,
  setStartDate,
  isStartDateValid,
  transactionsWithoutCounterparty
}: Props) => {
  const [ t ] = useTranslation('labeling');
  const getCounterparty = useCallback((counterparty: string) => {
    return counterparties[ counterparty ][ 0 ].counterparty;
  }, [ counterparties ]);

  // It is not possible to create rule for "Unassigned"
  const dimensionsWithoutUnassigned = useMemo(
    () => dimensions.filter(d => d.dimensionItem !== 'Unassigned'),
    [ dimensions ]);

  const renderCounterparties = useCallback(() => {
    return Object.keys(counterparties)
      .filter(c => c !== t('cost-labeler.unassigned', { ns: 'financials' }))
      .map(c => {
        const counterparty = getCounterparty(c);

        return <DimensionCapsule
          className={ `${ styles.counterparty } ${ styles.dimension }` }
          key={ `counterparty__${ counterparty.id }` }
          icon={
            <CounterpartyLogo
              className={ styles.logo }
              size={ 24 }
              type={ counterparty.type }
              name={ counterparty.name }
              url={ counterparty.pictureUrl }
            />
          }
          dimension={ counterparty.type }
          dimensionItem={ counterparty.name }
        />;
      });
  }, [ counterparties, getCounterparty ]);

  const datePickerFits = useMemo(() => {
    if (!counterparties) {
      return false;
    }
    return Object.keys(counterparties).length <= 2;
  }, []);

  return (
    <div className={ styles.createRule }>
      <div className={ styles.ruleRelations }>
        <div className={ styles.rows }>
          <div className={ styles.row }>
            <div className={ styles.header }>{ t('create-rule.source') }</div>
            <div className={ styles.connector } />
            <div className={ styles.header }>{ t('create-rule.target') }</div>
          </div>
          <div className={ styles.row }>
            <div className={ styles.source }>
              {
                renderCounterparties()
              }
            </div>
          </div>
        </div>
        <div className={ styles.target }>
          {
            dimensionsWithoutUnassigned.map(
              dimension => <DimensionCapsule
                key={ dimension.id }
                className={ styles.dimension }
                dimension={ getDisplayName(dimension.dimension) }
                dimensionItem={ getDisplayName(dimension.dimensionItem) }
              />
            )
          }

        </div>
      </div>
      <div className={ styles.datePicker }>
        <div className={ styles.label }>
          { t('create-rule.start-date') }
        </div>
        <InlineDatePicker
          value={ startDate }
          onChange={ setStartDate }
          disabled={ false }
          prefixIcon={ <CalendarIcon/> }
          status={ isStartDateValid ? '' : 'error' }
          popupClassName={ `${ datePickerFits ? styles.popup : '' }` }
        />
        <span />
      </div>
      {
        !isStartDateValid ?
          <InlineStatement type='error' className={ styles.error }>
            { t('modal.date-error') }
          </InlineStatement> : null
      }
      {
        transactionsWithoutCounterparty > 0 ?
          <InlineStatement className={ styles.secondInfo } type='warning'>
            { t('create-rule.warning-counterparties', { amount: transactionsWithoutCounterparty }) }
          </InlineStatement> : <InlineStatement type='info'>
            <div className={ styles.info }>
              { t('create-rule.label-info') }
              { dimensionsWithoutUnassigned.map(dimension => (
                <p key={ dimension.id }>
                  <b>{ getDisplayName(dimension.dimension) }</b>
                  { ' ' }
                  { getDisplayName(dimension.dimensionItem) }
                </p>
              )
              ) }

            </div>
            <div>{ t('create-rule.date-info') }</div>
          </InlineStatement>
      }
    </div>
  );
};

export default CreateRulePage;
