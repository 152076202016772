import React, { useEffect } from 'react';
import { topBarSlice } from 'store/topBar.slice';
import { useAppDispatch } from 'store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import InputsTable from 'components/inputs/table/InputsTable';

const InputsPage = () => {
  const [ t ] = useTranslation('inputs');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(topBarSlice.actions.setTitle(t('page.title')));

    return () => {
      dispatch(topBarSlice.actions.clear());
    };
  }, []);

  return <InputsTable isActive />;
};

export default InputsPage;
