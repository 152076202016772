/* eslint-disable camelcase */
import { DealType } from 'types/revenueRecognition.types';
import { endpoints } from 'utils/config.utils';
import http from 'utils/http.utils';

const assignDeal = (id: number, dealType: DealType) =>
  http.post(endpoints.revenueRecognition.transactions.assignDeal.replace('{id}', id.toString()), {
    dealType
  });

type RevenueRecognitionData = {
  date: string;
  amount: number;
};

const recognizeRevenue = (transactionId: number, data: RevenueRecognitionData[]) =>
  http.post(
    endpoints.revenueRecognition.recognizeRevenue.replace(
      '{id}', transactionId.toString()),
    data);

const deleteRevenueRecognition = (transactionId: number) =>
  http.delete(
    endpoints.revenueRecognition.recognizeRevenue.replace(
      '{id}', transactionId.toString()),
  );

export { recognizeRevenue, deleteRevenueRecognition, assignDeal };
