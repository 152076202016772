import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks/hooks';
import { DimensionFilter, FilterList } from 'types/filterTable.types';
import { getDisplayName } from 'utils/common.utils';
import { FilterCapsule } from '../FilterCapsule';
import styles from './FilterCapsuleList.module.scss';
import { ClearFiltersIcon } from '../../clearFiltersIcon/ClearFiltersIcon';

interface Props {
  filterCapsules: FilterList;
}

export const FilterCapsuleList = ({ filterCapsules }: Props) => {
  const dimensions = useAppSelector((state) => state.breakdowns.dimensionMap);
  const activeCard = useAppSelector(state => state.financials.active?.templateId);

  const capsulesToShow = useMemo(() => {
    return filterCapsules.filter((f) => f?.excludedItems && f.excludedItems?.length !== 0 
      || f.excludeUnassigned);
  }, [ filterCapsules ]);

  return (
    <div className={ styles.capsuleContainer }>
      {
        capsulesToShow
          .sort((a, b) => {
            const aName = getDisplayName(dimensions[ a.dimension ]?.name) ?? '';
            const bName = getDisplayName(dimensions[ b.dimension ]?.name) ?? '';
            return aName.localeCompare(bName);
          })
          .map((f: DimensionFilter) => {
            return <FilterCapsule key={ f.dimension } dimensionFilters={ f }/>;
          })
      }
      {
        !!capsulesToShow?.length && 
        <ClearFiltersIcon templateId={ activeCard } defaultToClearState={ true } />
      }
    </div>
  );
};
