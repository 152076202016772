import { ReportType } from 'types/templates.types';
import { ReportNode } from './templates.types';
import { RowType } from './financials.types';
import { DatasetSettings } from './dashboard.types';
import { DataFormat } from '../components/charts/types/chart.types';

export interface ChartValues {
  date: string;
  value: number;
  type: string;
  reportType: ReportType;
  hideValue?: boolean;
  formatting?: 'NOMINAL' | 'PERCENTAGE';
}

export interface DateValue {
  date: string;
  value: number;
}

export type ChartNode = Pick<ReportNode, 'rowData' | 'data'> & {type: RowType} ;

// export interface ChartReport {
//   [SettingsType.PRIMARY]: ChartValues[];
//   [SettingsType.SECONDARY]: ChartValues[];
// }

export type ChartReport = ChartValues[][];

export enum ChartStyle {
  BAR = 'bar',
  STACKED = 'stacked',
  GROUPED = 'grouped',
  PERCENTAGE = 'percentage',
  LINE = 'line',
  LINE_WITH_BACKGROUND = 'line_with_background',
}

export type ChartAxis = 'left' | 'right';

// t(`financials:charts.settings.source.both`)
// t(`financials:charts.settings.source.actuals`)
// t(`financials:charts.settings.source.budget`)
export enum ChartSource {
  BOTH = 'both',
  ACTUALS = 'actuals',
  BUDGET = 'budget'
}

export interface DataLabelsSetting {
  bar: boolean;
  stacked: boolean;
}

export interface OtherSettings {
  dataLabels: DataLabelsSetting;
  legend: boolean;
  leftAxisFormat: DataFormat;
  rightAxisFormat: DataFormat;
  cumulativeValues: boolean;
}

export enum SettingsType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OTHER = 'other'
}

export type ChartSettings = {
  datasets: DatasetSettings[];
  // 'primary': DatasetSettings;
  // 'secondary': DatasetSettings;
  'other': OtherSettings;
  'name'?: string;
};

interface EChartTooltip {
  seriesIndex: number;
  dataIndex: number;
  name: string;
  seriesId: string;
  seriesName: string;
  seriesType: string;
  value: number;
  axisValue: string;
  axisValueLabel: string;
  data: {
    value: number;
    itemStyle: unknown;
  };
  color: string;
  borderColor: string;
}

export type EChartTooltipParams = EChartTooltip[];
