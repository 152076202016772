import React from 'react';
import styles from './Pill.module.scss';

interface PillProps {
  children: React.ReactNode;
  className?: string;
}

const Pill = ({ children, className='' }: PillProps) => {
  return (<div className={ styles.pill + ' ' + className }>
    { children }
  </div>);
};

export default Pill;
