import { SyncState } from '../../types/settings.types';

export enum WSBudgetEvent {
  GENERATION_START = 'generation_start',
  GENERATION_STEP = 'generation_step',
  GENERATION_STEP_FAILED = 'generation_step_failed',
  GENERATION_END = 'generation_end',
}

export enum WSTypes {
  BUDGET = 'budget',
  SYNCHRONIZATION_PROGRESS = 'synchronization_progress',
}

type IWSMessage = {
  type: WSTypes;
  event: WSBudgetEvent | SyncState;
};

type IWSBudgetMessage = IWSMessage & {
  type: WSTypes.BUDGET;
  budget_id: number;
  invoker_id: number;
};

type IWSSynchronizationMessage = IWSMessage & {
  type: WSTypes.SYNCHRONIZATION_PROGRESS;
};

export interface WSGenerationStartMessage extends IWSBudgetMessage {
  event: WSBudgetEvent.GENERATION_START;
  total_steps: number;
}

export interface WSGenerationEndMessage extends IWSBudgetMessage {
  event: WSBudgetEvent.GENERATION_END;
}

export interface WSGenerationStepMessage extends IWSBudgetMessage {
  event: WSBudgetEvent.GENERATION_STEP;
  total_steps: number;
  step: number;
}

export interface WSGenerationStepFailedMessage extends IWSBudgetMessage {
  event: WSBudgetEvent.GENERATION_STEP_FAILED;
  total_steps: number;
  budget_item_id: number;
  step: number;
}

export type WSAccountsSynchronizationMessage = IWSSynchronizationMessage & {
  event: SyncState;
};

export type WSMessage =
  | WSGenerationStartMessage
  | WSGenerationEndMessage
  | WSGenerationStepMessage
  | WSGenerationStepFailedMessage
  | WSAccountsSynchronizationMessage;
