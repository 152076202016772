import React, { createContext, useContext, useReducer } from 'react';
import {
  UtilKey,
  CommandBarUtil,
} from 'components/commandBar/types/commandBar.types';
import { getDefaultUtilsSetting } from 'components/commandBar/utils/commandBar.utils';
import { FilterList } from 'types/filterTable.types';

type CommandBarAction =
  | { type: 'CLEAR_SETTINGS' }
  | { type: 'SET_UTILS'; payload: CommandBarUtil[]; defaults?: boolean }
  | { type: 'CLEAR_UTILS'; payload: UtilKey[] }
  | { type: 'SET_HIDDEN'; payload: boolean }
  | { type: 'ADD_CAPSULES_FILTER'; payload: FilterList }
  | { type: 'SET_FILTER_CAPSULES_HIDDEN'; payload: boolean };

type CommandBarContextValues = {
  state: CommandBarContextType;
  dispatch: React.Dispatch<CommandBarAction>;
};

type CommandBarContextType = {
  utils: CommandBarUtil[];
  _defaults: {
    utils: CommandBarUtil[];
  };
  hidden: boolean;
  filterCapsules: FilterList;
  filterCapsulesHidden: boolean;
};

export const defaults = getDefaultUtilsSetting();
const defaultState: CommandBarContextType = {
  utils: defaults,
  _defaults: {
    utils: defaults,
  },
  hidden: true,
  filterCapsules: [],
  filterCapsulesHidden: true,
};

const CommandBarContext = createContext<CommandBarContextValues>(undefined);

const CommandBarReducer = (
  state: CommandBarContextType,
  action: CommandBarAction
): CommandBarContextType => {
  switch (action.type) {
    case 'CLEAR_SETTINGS': {
      return defaultState;
    }
    case 'SET_UTILS': {
      const utils = state.utils.map(element => {
        const found = action.payload.find(value => value.key === element.key);
        if (found) {
          return {
            ...element,
            ...found
          };
        }
        return element;
      });

      return {
        ...state,
        utils,
        _defaults: {
          ...state._defaults,
          ...action.defaults ? { utils } : {}
        }
      };
    }
    case 'CLEAR_UTILS': {
      const keys = action.payload;
      return {
        ...state,
        utils: state.utils.map(element => {
          const found = keys.find(key => key === element.key);
          if (found) {
            const defaultEl = state._defaults.utils.find(value => value.key === found);
            return {
              ...element,
              ...defaultEl
            };
          }
          return element;
        })
      };
    }
    case 'SET_HIDDEN': {
      return {
        ...state,
        hidden: action.payload
      };
    }
    case 'ADD_CAPSULES_FILTER': {
      return {
        ...state,
        filterCapsules: action.payload,
      };
    }
    case 'SET_FILTER_CAPSULES_HIDDEN': {
      return {
        ...state, 
        filterCapsulesHidden: action.payload,
      };
    }
    default: {
      throw new Error('Unhandled action type in CommandBarContext');
    }
  }
};

type Props = {
  children: React.ReactNode;
};

const CommandBarContextProvider = ({ children }: Props) => {
  const [ state, dispatch ] = useReducer(CommandBarReducer, defaultState);
  const value = { state, dispatch };

  return <CommandBarContext.Provider value={ value }>
    { children }
  </CommandBarContext.Provider>;
};

const useCommandBar = () => {
  const context = useContext(CommandBarContext);
  if (context === undefined) {
    throw new Error('useCommandBar must be used within Provider');
  }
  return context;
};

export { CommandBarContextProvider, useCommandBar, CommandBarContext };
