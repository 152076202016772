import { useCallback, useMemo } from 'react';
import {
  ExcelStyle,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
  IRowNode,
  ShouldRowBeSkippedParams
} from 'ag-grid-community';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { RowType, TableTitle } from 'types/financials.types';
import { getDisplayName } from 'utils/common.utils';
import { isTransactionRow } from 'utils/financials.utils';
import { isFormulaNode } from 'types/templates.types';

type Props = {
  title: TableTitle;
};

const useGridExport = ({ title }: Props) => {
  const excelStyles: ExcelStyle[] = useMemo(() => ([
    {
      id: 'header',
      alignment: {
        horizontal: 'Right'
      },
    },
    {
      id: 'valueCell',
      alignment: {
        horizontal: 'Right'
      },
      dataType: 'Number',
      numberFormat: {
        format: '# ###'
      },
    }
  ]), []);

  const getFileName = useCallback((templateName: string) => {
    const today = dayjs();
    const date = today.format('YYYY-MM-DD');
    const time = today.format('HH-mm');
    // keep only letters and numbers. The rest will be underscores.
    const replacedTemplateName =templateName
      .replace(/[^a-zA-Z0-9]/g, '_');
    return `${ replacedTemplateName } - ${ date } - ${ time }`;
  }, []);

  const shouldRowBeSkipped = useCallback((params: ShouldRowBeSkippedParams) => {
    const isProxyNode = !params.node.data?.type;
    return isTransactionRow(params.node) || isProxyNode;
  }, []);

  const capitalizeEveryWord = useCallback((sentence: string, onlyFirstLetter: boolean) => {
    const splitSentence = sentence?.split('_');
    const capitalized = splitSentence?.map(word => {
      return onlyFirstLetter ? capitalize(word) : word.toUpperCase();
    });
    return capitalized?.join(' ');
  }, []);

  const addIndentation = useCallback((str: string, indentation: number) => {
    return Array(indentation * 3 + 1).join(' ') + str;
  }, []);

  const rowHeaderName = useCallback(
    (name: string, isAllCapitalized: boolean, indentation: number) => {
      const capitalizedName = capitalizeEveryWord(name, !isAllCapitalized);
      return addIndentation(capitalizedName, indentation);
    }, []);

  const isHeaderRowCell = useCallback((colId: string) => {
    return colId.includes('ag-Grid-AutoColumn');
  }, []);

  const getName = useCallback((node: IRowNode) => {
    const nodeType = node.data.type;
    const name = node.data.rowData?.name;
    if (typeof name === 'string') {
      return name;
    }
    if (name) {
      return getDisplayName(name);
    }
    if (nodeType === RowType.TITLE || nodeType === RowType.GROUP || isFormulaNode(node.data)) {
      return node.data.rowData.name;
    }
    if (nodeType === RowType.SPACER || nodeType === RowType.HALF_SPACER) {
      return '';
    }
    return '';
  }, []);

  const processCellCallback = (params: ProcessCellForExportParams, toAddIndentation = true) => {
    const value = params.value;
    if (!value) return null;
    const rowData = params.node.data.rowData;
    const isHeaderRow = isHeaderRowCell(params.column.getColId());
    return isHeaderRow ? rowHeaderName(
      getName(params.node),
      rowData?.capitalized,
      toAddIndentation ? params.node.level : 0
    ) : value.toString();
  };

  const processHeaderCallback = (params: ProcessHeaderForExportParams) => {
    const headerName = params.column.getColDef().headerName;
    if (headerName === 'Group') {
      return '';
    }
    return headerName;
  };

  return {
    defaultExcelExportParams: {
      fileName: getFileName(title?.title),
      sheetName: title?.title,
      shouldRowBeSkipped: shouldRowBeSkipped,
      processCellCallback: processCellCallback,
      processHeaderCallback: processHeaderCallback
    },
    excelStyles,
    defaultCsvExportParams: {
      fileName: getFileName(title?.title),
      shouldRowBeSkipped: shouldRowBeSkipped,
      processCellCallback: (params) => processCellCallback(params, false),
      processHeaderCallback: processHeaderCallback
    },
  };
};

export default useGridExport;
