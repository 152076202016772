import React from 'react';
import Card from 'components/elements/card/Card';
import { useChartContext } from '../context/ChartContext';
import styles from './ChartSettings.module.scss';
import DatasetSettings from './datasetSettings/DatasetSettings';
import TopBar from './topBar/TopBar';
import OtherSettings from './otherSettings/OtherSettings';
import { useTranslation } from 'react-i18next';
import { useFinancialTable } from 'context/FinancialTableContext';
import useTemplateNode from 'hooks/useTemplateNode';
import { useFormContext, useWatch } from 'react-hook-form';
import { ChartSettings as ChartSettingsType } from 'types/chart.types';

const ChartSettings = () => {
  const { state: { settingsPanel } } = useChartContext();
  const { control } = useFormContext<ChartSettingsType>();
  const settings = useWatch({ control }) as ChartSettingsType;
  const [ t ] = useTranslation('financials');
  const { state: { templateId } } = useFinancialTable();
  const { uniqueRoots } = useTemplateNode({ templateId, type: 'chart' });

  const datasetSettingsTitles = {
    0: t('financials:charts.settings.primary'),
    1: t('financials:charts.settings.secondary'),
    2: t('financials:charts.settings.third'),
    3: t('financials:charts.settings.fourth'),
  };

  if (!settingsPanel) return null;
  return <Card className={ styles.container }>
    <TopBar/>
    {
      uniqueRoots.length > 0 ? <>
        {
          settings.datasets.map(
            (dataset, index) => {
              return <DatasetSettings
                index={ index }
                key={ index }
                title={ datasetSettingsTitles[ index ] }
                templateId={ templateId }
              />;
            }
          )
        }

        <OtherSettings/>
      </> : null
    }
  </Card>;
};

export default ChartSettings;
