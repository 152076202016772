import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/elements/modal/Modal';
import styles from './InvoiceModal.module.scss';
import { formatDateWithTimezone } from 'utils/financials.utils';
import PdfViewer from 'components/elements/PdfViewer/PdfViewer';
import { Transaction } from 'types/statutory.types';
import organizationsService from '../../../services/organizations.service';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  transaction: Transaction;
};

const InvoiceModal = ({ isVisible, onClose, transaction }: Props) => {
  const [ t ] = useTranslation('financials');
  const [ pdfFile, setPdfFile ] = useState(null);
  const id = transaction?.id;
  const invoiceLink = transaction?.invoiceLink;
  const date = transaction?.transactionDate;

  useEffect(() => {
    if (invoiceLink !== '' || !transaction) {
      return setPdfFile({ link: invoiceLink, name: null });
    }
    const getPdfFile = async () => {
      const response = await organizationsService.getInvoice(id);
      response && setPdfFile({ data: response?.data, name: null });
    };

    transaction && getPdfFile();

    return () => setPdfFile(null);
  },[ transaction ]);

  return (
    <Modal
      isVisible={ isVisible }
      onConfirm={ onClose }
      onClose={ onClose }
      destroyOnClose={ true }
      title={ <span className={ styles.title }>
        <span className={ styles.titlePdf }>PDF</span>
        <span>{ t('invoice.title') }</span>
        <span className={ styles.titleDate }>
          <span className={ styles.titleDateSeparator }>&bull;</span>
          { formatDateWithTimezone(date) }
        </span>
      </span> }
      width={ 950 }
      disableButtons={ true }>
      {
        (pdfFile?.data || invoiceLink) && 
        <PdfViewer
          pdfs={ [ pdfFile ] }
          isManagable={ false }
          canBeZoomed={ false }  
        />
      }
    </Modal>
  );
};

export default InvoiceModal;
