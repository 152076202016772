import React, { createContext, useContext, useReducer } from 'react';

type SearchableState = {
  search: string;
  results: number;
  clear?: object | null;
};

type SearchAction = {
  type: 'search';
  payload: string;
};

type ClearAction = {
  type: 'clear';
};

type ResultsAction = {
  type: 'results';
  payload: number;
};

type SearchableAction = SearchAction | ClearAction | ResultsAction;

const defaultState: SearchableState = {
  search: '',
  results: 0,
  clear: null
};

const SearchableContext = createContext<{
  state: SearchableState; dispatch: React.Dispatch<SearchableAction>;
}>(undefined);

const searchableReducer = (state: SearchableState, action: SearchableAction) => {
  switch (action.type) {
    case 'search': {
      return { ...state , search: action.payload };
    }
    case 'clear': {
      return { ...state , search: '', clear: {} };
    }
    case 'results': {
      return { ...state, results: action.payload };
    }
    default: {
      const unexpectedType = action[ 'type' ];
      throw new Error(`Unhandled action type: ${ unexpectedType }`);
    }
  }
};

const SearchableContextProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(searchableReducer, defaultState);
  const value = { state, dispatch };

  return <SearchableContext.Provider value={ value }>
    { children }
  </SearchableContext.Provider>;
};

const useSearchable = () => {
  const context = useContext(SearchableContext);
  if (context === undefined) {
    throw new Error('useSearchableContext must be used within Provider');
  }
  return context;
};

export { SearchableContextProvider, useSearchable };
