import React, { useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/toast-close.svg';
import { Button, Dropdown } from 'antd';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-down.svg';
import styles from './LeftPanelHeader.module.scss';
import clsx from 'clsx';
import { ReactComponent as FilterIcon } from 'assets/icons/filterv2.svg';
import { ReactComponent as LabelIcon } from 'assets/icons/label.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sorting.svg';
import { ReactComponent as ColumnsIcon } from 'assets/icons/column.svg';
import { ReactComponent as LayoutIcon } from 'assets/icons/layout.svg';
import { useTranslation } from 'react-i18next';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { useCommandBar } from 'components/commandBar/context/CommandBarContext';
import type { LeftPanelType } from 'types/app.types';

export interface LeftPanelTab {
  id: number;
  title: string;
  name: LeftPanelType[];
  icon: React.ReactNode;
}

interface Props {
  onChange: (section: LeftPanelType | null) => void;
  onClose: () => void;
  activeKey: LeftPanelType;
  availableTabs: LeftPanelType[];
  syncWithCommandBar?: boolean;
  utilsToShow?: LeftPanelType[];
}

const LeftPanelHeader = (
  {
    onChange, onClose, activeKey, syncWithCommandBar = true, utilsToShow
  }: Props) => {
  const [ t ] = useTranslation('common');
  const [ isOpen, setIsOpen ] = useState(false);

  const { state: { utils } } = useCommandBar();

  const allTabs = [
    {
      id: 1,
      title: t('left-panel.navigation.filter'),
      name: [ 'filter' ] as LeftPanelType[],
      icon: <FilterIcon height={ 24 } width={ 24 } className={ styles.icon }/>
    },
    {
      id: 2,
      title: t('left-panel.navigation.label'),
      name: [ 'label' ] as LeftPanelType[],
      icon: <LabelIcon height={ 24 } width={ 24 } className={ styles.icon }/>
    },
    {
      id: 3,
      title: t('left-panel.navigation.sort-and-group'),
      name: [ 'sort', 'group' ] as LeftPanelType[],
      icon: <SortIcon height={ 24 } width={ 24 } className={ styles.icon }/>
    },
    {
      id: 4,
      title: t('left-panel.navigation.columns'),
      name: [ 'columns' ] as LeftPanelType[],
      icon: <ColumnsIcon height={ 24 } width={ 24 } className={ styles.icon }/>
    },
    {
      id: 5,
      title: t('financials:left-panel.layout-control.title'),
      name: [ 'layout' ] as LeftPanelType[],
      icon: <LayoutIcon height={ 24 } width={ 24 } className={ styles.icon }/>
    }
  ];

  const commandBarTabs = utils.filter(u => !u.hidden && !u.disabled).map((u) => u.key);
  let tabs = allTabs;
  if (syncWithCommandBar) {
    tabs = tabs.filter(el => (commandBarTabs as unknown[]).includes(el.name.at(0)));
  }
  if (utilsToShow) {
    tabs = tabs.filter(el => utilsToShow.includes(el.name.at(0)));
  }
  const onLabelClick = (key: LeftPanelType | undefined) => {
    setIsOpen(false);
    onChange(key ?? null);
  };

  const items = tabs.map(el => ({
    key: el.name.at(0) as LeftPanelType,
    label: <div onClick={ () => onLabelClick(el.name.at(0)) } className={ styles.navigationItem }>
      { el.icon }
      <span>{ el.title }</span>
    </div>
  }));

  const activeTab = tabs.find(el => el.name.includes(activeKey));
  return (
    <div className={ styles.headerWrapper }>
      <Dropdown
        open={ isOpen }
        trigger={ [ 'click' ] }
        className={ styles.dropdown }
        onOpenChange={ setIsOpen }
        overlayClassName={ styles.dropdownOverlay }
        menu={ { items } }>
        <Button type='link' className={ styles.dropdownTrigger }>
          { activeTab?.icon }
          { activeTab?.title }
          { ' ' }
          <ChevronIcon
            className={ clsx(styles.arrowIcon, { [ styles.activeDropdown ]: isOpen }) }
          />
          { ' ' }
        </Button>
      </Dropdown>
      <GhostIconButton
        aria-label='close'
        onClick={ onClose }
        className={ styles.closeButton }
      >
        <CloseIcon width={ 24 } height={ 24 }/>
      </GhostIconButton>
    </div>
  );
};

export default LeftPanelHeader;
