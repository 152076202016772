import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse, Switch } from 'antd';
import Card from 'components/elements/card/Card';
import UnassignedAccountDrag
  from 'components/accountsMapping/unassignedAccountDrag/UnassignedAccountDrag';
import TreeLeaf from 'components/accountsMapping/treeLeaf/TreeLeaf';
import { StatutoryTemplate } from 'types/statutory.types';
import styles from './AccountsMapping.module.scss';

import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { setAssociations, setNodes, setShowDetected, } from 'store/accountMapping.slice';
import Loader from '../elements/loader/Loader';
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import statutoryService from '../../services/statutory.service';
import { useAppDispatch, useAppSelector } from '../../store/hooks/hooks';
import { getDisplayName } from 'utils/common.utils';
import { topBarSlice } from 'store/topBar.slice';

const { Panel } = Collapse;

const AccountsMapping = () => {
  const [ t ] = useTranslation('accountMapping');
  const [ templates, setTemplates ] = useState<StatutoryTemplate[]>([]);
  const [ templatesLoading, setTemplatesLoading ] = useState<boolean>(true);
  const [ activeTemplateId, setActiveTemplateId ] = useState<string | string[]>(undefined);

  const dispatch = useAppDispatch();
  const unassignedList = useAppSelector(state => state.accountsMapping.unassigned);
  const unassignedLoading = useAppSelector(state => state.accountsMapping.unassignedLoading);
  const assignedCount = useAppSelector(state => {
    let counter = 0;
    for (const assoc of Object.values(state.accountsMapping.associations)) {
      counter += assoc.length;
    }
    return counter;
  });

  const showDetected = useAppSelector(state => state.accountsMapping.showDetected);

  useEffect(() => {

    dispatch(setAssociations());

    statutoryService.getTemplates().then(({ data }) => {
      const joinedNodes = Array.prototype.concat(...data.map(templ => templ.nodes));
      dispatch(setNodes(joinedNodes));
      setTemplates(data);
      setTemplatesLoading(false);
      setActiveTemplateId(data[ 0 ].id.toString());
    });

    dispatch(topBarSlice.actions.setTitle(
      t('links.settings.account-mapping', { ns: 'navigation' }))
    );

    return () => {
      dispatch(topBarSlice.actions.clear());
    };
  }, []);

  const onAccountVisibilitySwitch = (checked) => {
    dispatch(setShowDetected(checked));
    if (!activeTemplateId) {
      setActiveTemplateId(templates[ 0 ].id.toString());
    }
  };

  const getFinishedInfo = () => {
    return <div className={ styles.emptyListBox }>
      <p className={ styles.emptyListMessage }>{ t('unassigned-list-empty') }</p>
      <div className={ styles.checkIconContainer }>
        <CheckCircle/>
      </div>

    </div>;
  };

  return (
    <div className={ styles.accountsMapping }>
      <Loader isActive={ unassignedLoading || templatesLoading }/>
      <div className={ styles.content }>
        <Card className={ styles.unassignedTableWrapper }>
          <div className={ styles.unassignedTable }>
            <h2 className={ styles.title }>
              { t('unassigned') }
              <span className={ styles.amount }>
                { unassignedList.length }
              </span>
            </h2>
            <div className={ styles.list }>
              { unassignedList.length ? unassignedList.map(
                (row) => <UnassignedAccountDrag key={ row.id } account={ row }/>)
                : unassignedLoading ? null : getFinishedInfo()
              }
            </div>
          </div>
        </Card>

        <Card className={ styles.rightContainer }>
          <div className={ styles.upperTreeRow }>
            <div
              className={ `${ styles.switchContainer } ${ showDetected ? styles.switchOn : '' }` }>
              <Switch
                onClick={ onAccountVisibilitySwitch }
                size='small'
                checked={ showDetected }
                className={ `${ styles.switch }` }
                id='showHiddenSwitch'
              />
              <label htmlFor='showHiddenSwitch'>
                { t('show-hidden-label') }
              </label>
            </div>
            <div className={ styles.assignedCounterContainer }>
              <span className={ styles.assignedCounter }>
                <span>
                  { t('total') }
                </span>
                <span>
                  { assignedCount }
                  /
                  { assignedCount + unassignedList.length }
                </span>

              </span>
              <CheckIcon />
            </div>
          </div>

          {
            templates.length ?
              <Collapse
                accordion
                defaultActiveKey={ activeTemplateId }
                activeKey={ activeTemplateId }
                className={ styles.tree }
                ghost={ true }
                onChange={ (id) => setActiveTemplateId(id) }
                expandIcon={ (props) => <Chevron
                  className={ `${ styles.expandIcon } ${ props.isActive ? styles.isExpand : '' }` }
                /> }
              >
                { templates.map(template => (
                  <Panel
                    header={ getDisplayName(template.name) }
                    key={ template.id }
                    className={ styles.collapsePanel }
                  >
                    { template.roots.map(rootNodeId =>
                      <TreeLeaf
                        depth={ 0 }
                        key={ rootNodeId }
                        nodeId={ rootNodeId }/>) }
                  </Panel>
                )) }
              </Collapse> :
              null
          }
        </Card>
      </div>
    </div>
  );
};

export default AccountsMapping;
