import { useCallback } from 'react';
import { ValueFormatterParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';
import { DATE_FORMATS, getTimeWithUnit } from 'utils/date.utils';
import { Counterparty } from 'types/statutory.types';
import { InvoicingFrequency } from 'types/contracts.types';
import { useTranslation } from 'react-i18next';
import { Cadence } from 'types/form.types';
import { DimensionItem } from '../../../../types/filterTable.types';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { getDisplayName } from '../../../../utils/common.utils';

const useTableFormatter = () => {
  const [ t ] = useTranslation('contracts');
  const { dimensionItemMap } = useAppSelector(state => state.breakdowns);

  const dateFormatter = useCallback((params: ValueFormatterParams) => {
    if (!params.value || !dayjs(params.value).isValid()) {
      return '';
    }

    return dayjs(params.value).format(DATE_FORMATS[ 0 ]);
  }, []);

  const percentageFormatter = useCallback((params: ValueFormatterParams) => {
    if (!params.value && params.value !== 0) {
      return '';
    }
    if (isNumber(+params.value)) {
      return `${ params.value }%`;
    }
    return '';
  }, []);

  const accountFormatter = useCallback((params: ValueFormatterParams<DimensionItem>) => {
    if (params.node.rowPinned === 'bottom') {
      return undefined;
    }
    const accountDimensionItemId = params.value;
    const accountDimensionItem = dimensionItemMap[ +accountDimensionItemId ];
    const accountNumber = accountDimensionItem?.account?.number;
    if (!accountNumber) return t('common:unassigned');

    return `${ accountNumber } ${ getDisplayName(accountDimensionItem.account.name) }`;
  }, []);

  const counterpartyFormatter = useCallback((params: ValueFormatterParams<Counterparty>) => {
    if (params.node.rowPinned === 'bottom') {
      return undefined;
    }
    const counterpartyDimensionItemId = params.value;
    const counterpartyDimensionItem = dimensionItemMap[ +counterpartyDimensionItemId ];
    return counterpartyDimensionItem?.counterparty?.name || t('common:unassigned');
  }, []);

  const timeDistanceFormatter = useCallback((params: ValueFormatterParams) => {
    if (!params.value) {
      return '';
    }

    return getTimeWithUnit(dayjs().diff(params.value, 'second'));
  }, []);

  const frequencyValueFormatter = useCallback((
    params: ValueFormatterParams<InvoicingFrequency>
  ) => {
    return frequencyFormatter(params.value);
  }, []);

  const frequencyFormatter = useCallback((value: InvoicingFrequency) => {
    if (!value) return '';

    return t(`frequency.${ value }`);
  }, []);

  const cadenceFormatter = useCallback((cadence: Cadence) => {
    return t(`common:cadence.${ cadence }`);
  }, []);

  return {
    dateFormatter,
    percentageFormatter,
    accountFormatter,
    timeDistanceFormatter,
    frequencyValueFormatter,
    frequencyFormatter,
    counterpartyFormatter,
    cadenceFormatter
  };
};

export default useTableFormatter;
