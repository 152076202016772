import React, { useCallback, useEffect, useState } from 'react';

import organizationsService from '../../../../../services/organizations.service';
import { Transaction } from '../../../../../types/statutory.types';
import { PdfI } from '../../../../elements/PdfViewer/pdf.types';
import PdfViewer from '../../../../elements/PdfViewer/PdfViewer';
import { TablePanelProps } from './types/panel.types';

const PdfViewerPanel = ({
  selectedRows,
  onPanelChange,
}: TablePanelProps<Transaction>) => {
  const [ pdfs, setPdfs ] = useState<PdfI[]>();

  const getPdfName = useCallback((rowData: Transaction) => {
    if (rowData.counterparty) {
      return `${ rowData.counterparty.name.replace(/ /g, '_') }_${ rowData.transaction }`;
    }
    return `${ rowData.transaction }`;
  }, []);

  const getPdfFile = useCallback(async (rowData: Transaction) => {
    if (!rowData) return;
    const response = await organizationsService.getInvoice(rowData.id);
    return [ { data: response?.data, name: getPdfName(rowData) } ];
  }, []);

  useEffect(() => {
    setPdfs([]);
    if (selectedRows && selectedRows[ 0 ]?.data.invoiceLink) {
      const row = selectedRows[ 0 ].data;
      setPdfs([ { link: row.invoiceLink, name: getPdfName(row) } ]);
    } else {
      try {
        getPdfFile(selectedRows[ 0 ]?.data).then(setPdfs);
      } catch {
        console.error('Error while fetching pdf file');
      }
    }
  }, [ selectedRows ]);

  return (
    <PdfViewer
      onClose={ () => onPanelChange(null) }
      pdfs={ pdfs }
      canBeZoomed={ true }
      isManagable={ true }
    />
  );

};

export default PdfViewerPanel;
