import React from 'react';
import Layout from 'components/layout/Layout';
import { Outlet } from 'react-router-dom';
import { selectAuthentication } from 'store/auth.slice';
import { useAppSelector } from 'store/hooks/hooks';
import Redirect from '../redirect/Redirect';

const ProtectAnonymous = () => {
  const { user } = useAppSelector(selectAuthentication);

  if (user) {
    return <Layout>
      <Redirect />
    </Layout>;
  }

  return <Outlet />;
};

export default ProtectAnonymous;