import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { Cadence } from 'types/form.types';
import { DataType, Period, RangeType } from 'types/financials.types';
import { getPickerEndDate, getPickerStartDate } from './date.utils';
import { TransactionLineRequestParams } from 'services/statutory.service';
import { DurationUnitType } from 'dayjs/plugin/duration';

dayjs.extend(weekday);
const today = dayjs();

export const getPeriodWithOffset = (
  cadence: Cadence,
  monthToSubtractPlans: number | null,
  monthToSubtractActuals: number | null,
): Period => ({
  cadence: cadence,
  startDate: getPickerStartDate(today.subtract(12, 'month'), cadence).unix(),
  endDate: getPickerEndDate(
    today.add(monthToSubtractActuals - 12, cadence as DurationUnitType), cadence
  ).unix(),
  startDatePlan: monthToSubtractPlans !== null && dayjs(today).startOf('year').unix(),
  endDatePlan: monthToSubtractPlans !== null && dayjs(today).endOf('year').unix(),
  actualsOpen: true,
  planOpen: monthToSubtractPlans !== null,
  isManuallySet: false,
});

const periodPrefix = 'period';

export const getPeriodKey = (templateId: number, dataType: DataType): string => {
  return `${ periodPrefix }__${ dataType }__${ templateId }`;
};

export const setDefaultPeriod = (templateId: number, period: Period, isDefaultRange: boolean) => {
  const periodKeyPlans = getPeriodKey(templateId, 'Plan');
  const periodKeyActuals = getPeriodKey(templateId, 'Actuals');

  const isDefault = isDefaultRange != null ? isDefaultRange : isRangeDefault(templateId);

  if (isDefault) {
    if (period.startDate && period.startDate) {
      const timeSpan = dayjs.unix(period.endDate).diff(dayjs.unix(period.startDate), 'month');
      const periodToSet = [ period.cadence, timeSpan ].join(',');
      localStorage.setItem(periodKeyActuals, periodToSet);
    } else {
      localStorage.removeItem(periodKeyActuals);
    }

    if (period.startDatePlan && period.endDatePlan) {
      const timeSpan = dayjs.unix(period.endDatePlan)
        .diff(dayjs.unix(period.startDatePlan), 'month');
      const periodToSet = [ period.cadence, timeSpan ].join(',');
      localStorage.setItem(periodKeyPlans, periodToSet);
    } else {
      localStorage.removeItem(periodKeyPlans);
    }

  } else {
    if (period.actualsOpen) {
      localStorage.setItem(periodKeyActuals,
        Object.values([ period.cadence, period.startDate, period.endDate ]).join(','));
    } else {
      localStorage.removeItem(periodKeyActuals);
    }
    if (period.planOpen) {
      localStorage.setItem(periodKeyPlans,
        Object.values([ period.cadence, period.startDatePlan, period.endDatePlan ]).join(','));
    } else {
      localStorage.removeItem(periodKeyPlans);
    }
  }
};

const isDateInvalid = (cadence: string, startDate: string, endDate: string) => {
  return cadence == null || startDate == null || endDate == null;
};

const getPeriodByDefault = (arrayPeriodPlan: string[], arrayPeriodActuals: string[]) => {
  const [ cadencePlan, timeSpanPlan ] = arrayPeriodPlan;
  const [ cadenceActuals, timeSpanActuals ] = arrayPeriodActuals;

  const getMonthToSubtract = (timeSpan: string) => Number(timeSpan) > 60 ? 60 : Number(timeSpan);
  const cadence = cadencePlan || cadenceActuals || Cadence.month;
  return getPeriodWithOffset(Cadence[ cadence ],
    !timeSpanPlan ? 12 : getMonthToSubtract(timeSpanPlan),
    !timeSpanActuals ? 12 : getMonthToSubtract(timeSpanActuals)
  );
};

const getPeriodByCustom = (arrayPeriodPlan: string[], arrayPeriodActuals: string[]) => {
  const [ cadencePlan, startDatePlan, endDatePlan ] = arrayPeriodPlan;
  const [ cadenceActuals, startDateActuals, endDateActuals ] = arrayPeriodActuals;

  if (isDateInvalid(cadencePlan, startDatePlan, endDatePlan)
   && isDateInvalid(cadenceActuals, startDateActuals, endDateActuals)) {
    return getPeriodWithOffset(Cadence.month, 2, 2);
  } else {
    return {
      cadence:  Cadence[ cadenceActuals || cadencePlan ],
      startDate: startDateActuals ? Number(startDateActuals) : null,
      endDate: endDateActuals ? Number(endDateActuals): null,
      startDatePlan: startDatePlan ? Number(startDatePlan): null,
      endDatePlan: endDatePlan ? Number(endDatePlan): null,
      actualsOpen: !!(startDateActuals && endDateActuals),
      planOpen: !!(startDatePlan && endDatePlan),
      isManuallySet: false,
    };
  }
};

export const getDefaultPeriod = (templateId: number): Period => {
  const stringPeriodPlan = localStorage.getItem(getPeriodKey(templateId, 'Plan')) || '';
  const stringPeriodActuals = localStorage.getItem(getPeriodKey(templateId, 'Actuals')) || '';

  if (!stringPeriodPlan && !stringPeriodActuals) {
    setRangeType(templateId, true);
    return getPeriodWithOffset(Cadence.month, null, 11);
  }

  const arrayPeriodPlan = stringPeriodPlan.split(',');
  const arrayPeriodActuals = stringPeriodActuals.split(',');
  const isDefault = isRangeDefault(templateId);

  if (isDefault) {
    return getPeriodByDefault(arrayPeriodPlan, arrayPeriodActuals);
  } else {
    return getPeriodByCustom(arrayPeriodPlan, arrayPeriodActuals);
  }
};

const rangeTypePrefix = 'periodType__';

export const getReportLockKey = (templateId: number) => {
  return `report__${ templateId }__locked`;
};

const getRangeKey = (templateId: number) => {
  return `${ rangeTypePrefix }${ templateId }`;
};

export const setReportLocked = (templateId: number, isReportLocked: boolean) => {
  const reportLockedKey = getReportLockKey(templateId);
  localStorage.setItem(reportLockedKey, JSON.stringify(isReportLocked));
};

export const isReportPeriodLocked = (templateId: number) => {
  const reportLockedKey = getReportLockKey(templateId);
  const isReportLocked = localStorage.getItem(reportLockedKey);

  return JSON.parse(isReportLocked || 'false');
};

export const setRangeType = (templateId: number, isRangeDefault: boolean) => {
  const rangeType = isRangeDefault ? RangeType.DEFAULT : RangeType.CUSTOM;
  localStorage.setItem(getRangeKey(templateId), rangeType);
};

export const isRangeDefault = (templateId: number) => {
  const rangeType = localStorage.getItem(getRangeKey(templateId));
  return rangeType === RangeType.DEFAULT || rangeType == null;
};

export const isPeriodEqual = (first: Period, second: Period) => {
  return !(
    first.cadence !== second.cadence ||
    first.startDate !== second.startDate ||
    first.endDate !== second.endDate ||
    first.startDatePlan !== second.startDatePlan ||
    first.endDatePlan !== second.endDatePlan
  );
};

interface IPeriodRange {
  startDate: number;
  endDate: number;
  fullFormat?: string;
}

export const periodRangeString = ({
  startDate,
  endDate,
  fullFormat = 'MMMM YYYY',
}: IPeriodRange) => {

  const startString = dayjs.unix(startDate).format(fullFormat);
  const endString = dayjs.unix(endDate).format(fullFormat);

  return `${ startString } – ${ endString }`;
};

export const formatUnixDate = (date: number) => {
  return dayjs.unix(date).utcOffset(0, true).format();
};

export interface PeriodRange {
  start: number;
  end: number;
}

export const getOverlappingRange = (period: Period): PeriodRange => {
  const { startDate, endDate, startDatePlan, endDatePlan } = period;

  if (!startDate || !endDate || !startDatePlan || !endDatePlan) {
    return null;
  }
  const isOverlapping =
    dayjs.unix(startDatePlan).isBetween(dayjs.unix(startDate), dayjs.unix(endDate), 'day', '[]') ||
    dayjs.unix(endDatePlan).isBetween(dayjs.unix(startDate), dayjs.unix(endDate), 'day', '[]') ||
    dayjs.unix(startDate)
      .isBetween(dayjs.unix(startDatePlan), dayjs.unix(endDatePlan), 'day', '[]') ||
    dayjs.unix(endDate).isBetween(dayjs.unix(startDatePlan), dayjs.unix(endDatePlan), 'day', '[]');

  if (!isOverlapping) {
    return null;
  }

  const start = dayjs.unix(startDatePlan).isBefore(dayjs.unix(startDate)) ?
    startDate : startDatePlan;
  const end = dayjs.unix(endDatePlan).isAfter(dayjs.unix(endDate)) ? endDate : endDatePlan;
  return { start, end };
};

export const getPeriodRange = (
  start: number,
  end: number,
  overlappingRange: PeriodRange
): PeriodRange => {
  if (!overlappingRange) {
    return { start, end };
  }
  return null;
};

export const getActualPeriodRange = (
  period: Period,
  overlappingRange: PeriodRange
): PeriodRange => {
  return getPeriodRange(
    period.startDate,
    period.endDate,
    overlappingRange
  );
};

export const getBudgetPeriodRange = (
  period: Period,
  overlappingRange: PeriodRange
): PeriodRange => {
  return getPeriodRange(
    period.startDatePlan,
    period.endDatePlan,
    overlappingRange
  );
};
export const addPeriod = (
  params: TransactionLineRequestParams[],
  period: Period
): TransactionLineRequestParams[] => {
  if (!params) {
    return [];
  }
  if (period.fullRange) {
    return params;
  }

  const actualDates = period.actualsOpen ? {
    'start_date': dayjs.unix(period?.startDate).utcOffset(0, true).format(),
    'end_date': dayjs.unix(period?.endDate).utcOffset(0, true).format(),
    'cadence': period?.cadence
  } : {};
  const budgetDates = period.planOpen ? {
    'plan_start_date': dayjs.unix(period?.startDatePlan).utcOffset(0, true).format(),
    'plan_end_date': dayjs.unix(period?.endDatePlan).utcOffset(0, true).format(),
    'cadence': period?.cadence
  }: {};
  return params.map(param => ({
    ...param,
    ...actualDates,
    ...budgetDates
  }));
};
