import React, { useCallback } from 'react';
import { formatDateWithTimezone } from 'utils/financials.utils';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Transaction } from 'types/statutory.types';

import styles from './LabelPage.module.scss';
import AmountBadge from 'components/elements/badge/amount/AmountBadge';

type Props = {
  node: Transaction;
  onRemove: () => void;
};

const ModalTransactionRow = ({ node, onRemove }: Props) => {
  const replaceSpacesWithComma = useCallback((str: string) => {
    return str?.replace(/,(?=[^\s])/g, ', ');
  }, []);

  return (
    <div className={ styles.transactionsRow }>
      <div>{ formatDateWithTimezone(node.transactionDate) }</div>
      <div className={ styles.transactionsRowDescription }>
        { replaceSpacesWithComma(node.memo) }
      </div>
      <AmountBadge amount={ node.amount } />
      <CloseIcon onClick={ onRemove } className={ styles.transactionsCloseIcon }/>
    </div>
  );
};

export default ModalTransactionRow;
