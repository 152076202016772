import React from 'react';
import styles from './HeaderBar.module.scss';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils/config.utils';
import CounterpartyBreadcrumbs from
  'components/elements/counterpartyBreadcrumbs/CounterpartyBreadcrumbs';

interface Props{
  id: string;
}

const HeaderBar = ({ id }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={ styles.content }>
      <CounterpartyBreadcrumbs
        counterpartyId={ +id }
        onGoBack={ () => navigate(routes.manage.revenueRecognition) }
      />
    </div>
  );
};

export default HeaderBar;
