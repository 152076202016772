import { useCallback, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useAppSelector } from 'store/hooks/hooks';
import { DetailedViewType } from 'types/financials.types';

type SetterFn = (el: HTMLElement, size: number) => void;

const setReportsFloatingPanel: SetterFn = (el, size) => {
  el.style.width = `${ size }px`;
};

const setWorkspacesFloatingPanel: SetterFn = (el, size) => {
  const WORKSPACE_PADDING = 16;
  el.style.width = `${ size - WORKSPACE_PADDING * 2 }px`;
  el.style.transform = `translateX(${ WORKSPACE_PADDING }px)`;
};

const setFloatingPanelSize = (callback: SetterFn) => (size: number) => {
  // Move this operation to be executed after React will sync the DOM
  setTimeout(() => {
    const floatingPanel = document.getElementById('floating-panel');
    if (floatingPanel) {
      callback(floatingPanel, size);
    }
  });
};

interface Props {
  type: 'reports' | 'workspaces';
}

const useFloatingDetailedViewResizer = ({ type }: Props) => {
  const detailedView = useAppSelector((state) => state.financials.detailedView);
  const setter = useCallback((width: number) => {
    if (type === 'workspaces') {
      return setFloatingPanelSize(setWorkspacesFloatingPanel)(width);
    }
    return setFloatingPanelSize(setReportsFloatingPanel)(width);
  },[ type ]);

  const { ref, width } = useResizeDetector({
    handleHeight: false,
    onResize: setter,
  });

  useEffect(() => {
    if (detailedView?.type === DetailedViewType.FLOATING_PANEL) {
      setter(width);
    }
  }, [ detailedView?.type ]);

  return { ref };
};

export default useFloatingDetailedViewResizer;