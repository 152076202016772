import React from 'react';
import { ChartSettings } from 'types/chart.types';
import { useFormContext, useWatch } from 'react-hook-form';
import BigNumberIndicator from './bigNumberIndicator/BigNumberIndicator';
import styles from './ChartUpperArea.module.scss';
import DatasetDropdown from './datasetDropdown/DatasetDropdown';

interface Props {
  templateId: number;
}

const ChartUpperArea = ({ templateId }: Props) => {
  const { control } = useFormContext<ChartSettings>();
  const settings = useWatch({ control });

  return (
    <div className={ `${ styles.upperArea } chart-upper-area` }>
      <div className={ styles.datasets } >
        { settings.datasets.filter(dataset => dataset.active).slice(0, 2).map((dataset, index) => (
          <DatasetDropdown
            key={ index }
            index={ index }
            templateId={ templateId }
          />))
        }

      </div>
      <BigNumberIndicator />
    </div>
  );
};

export default ChartUpperArea;
