import React from 'react';
import { Switch } from 'antd';
import styles from './DatePickerToggle.module.scss';

interface Props{
  label: string;
  value: boolean;
  onChange: (v: boolean) => void;
  disabled: boolean;
}

const DatePickerToggle = ( { label, value, onChange, disabled }: Props) => {
  return (
    <div className={ styles.container }>
      <span className={ styles.label }>{ label }</span>
      <Switch 
        disabled={ disabled } 
        checked={ value } 
        onChange={ () => onChange(!value) } 
        aria-label={ label }/>
    </div>
  );
};

export default DatePickerToggle;