import React from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-rounded.svg';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'context/ModalContext';
import AddEditDimensionModal
  from '../dimensionLeftPanel/addEditDimensionModal/AddEditDimensionModal';
import { getDisplayName } from '../../../utils/common.utils';

interface Props {
  onAdd: (name: string) => void;
  onEdit: (name: string) => void;
  modalName: string;
}

const CreateUpdateModals = ({ onAdd, onEdit, modalName }: Props) => {
  const {
    state: {
      isAddModalVisible,
      isEditModalVisible,
      editModalItem: item
    }, dispatch
  } = useModalContext();
  const [ t ] = useTranslation('financials');

  return (
    <>
      <AddEditDimensionModal
        onConfirm={ onEdit }
        onClose={ () => dispatch({ type: 'HIDE_EDIT_MODAL' }) }
        isVisible={ isEditModalVisible }
        title={
          <span>
            <EditIcon/>
            <span>
              { t('left-panel.add-dimension.rename-title',
                { name: getDisplayName(item?.name) }) }
            </span>
          </span>
        }
        defaultValue={ getDisplayName(item?.name) }
        label={ t('left-panel.add-dimension.rename-label') }
        okText={ t('left-panel.add-dimension.rename-button') }
      />
      <AddEditDimensionModal
        onConfirm={ onAdd }
        onClose={ () => dispatch({ type: 'HIDE_ADD_MODAL' }) }
        isVisible={ isAddModalVisible }
        okText={ t('left-panel.add-dimension.ok-text') }
        label={
          t('left-panel.add-dimension.input-label', {
            name: modalName
          })
        }
        title={
          <span>
            <PlusIcon/>
            <span>
              {
                t('left-panel.add-dimension.title', { name: modalName })
              }
            </span>
          </span>
        }
        defaultValue=''
      />
    </>
  );
};

export default CreateUpdateModals;
