import React, { useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { routes } from 'utils/config.utils';
import { handleLogout } from 'utils/auth.utils';
import { logoutUser } from 'services/auth.service';

import { ReactComponent as Logo } from 'assets/icons/logo-small.svg';
import { ReactComponent as LogoText } from 'assets/icons/logo-text.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Close } from 'assets/icons/short-arrow.svg';

import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right.svg';

import styles from './Navbar.module.scss';
import { selectViewId } from 'store/financials.slice';
import organizationService from '../../../services/organizations.service';
import { getDisplayName } from '../../../utils/common.utils';
import { CustomViewNavigate } from '../../../types/app.types';
import Tooltip from '../../elements/tooltip/Tooltip';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { appSlice, loadNavigationList } from '../../../store/app.slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { isStickyHeaderActive } from 'utils/financials.utils';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import Button from 'components/elements/button/Button';

const Navbar = () => {
  const [ openKeys, setOpenKeys ] = useState<string[]>([]);

  const [ selectedKeys, setSelectedKeys ] = useState([]);

  const isOpen = useAppSelector(state => state.app.navbarOpen);
  const setIsOpen = (_isOpen: boolean) => {
    dispatch(appSlice.actions.setNavbarOpen(_isOpen));
  };

  const viewId = useAppSelector(selectViewId);
  const isStickyHeader = useMemo(() => isStickyHeaderActive(viewId), [ viewId ]);
  const sectionList = useAppSelector(state => state.app.navigationList);

  const navigationList = useAppSelector(state => state.app.navigationList);

  const unassignedList = useAppSelector(
    state => state.accountsMapping.unassigned);

  const [ navigationSections, setNavigationSections ] = useState([]);

  const [ t, i18n ] = useTranslation('navigation');
  const location = useLocation();
  const dispatch = useAppDispatch();

  const navigationListWithWarnings = useMemo(() => navigationList.map(section => {
    if (section.name[ 'en' ] === 'Settings') {
      return {
        ...section,
        warning: unassignedList.length > 0,
        warningTooltip: t('common:notifications.unassigned-account.subtitle_other',
          { value: unassignedList.length }),
      };
    }
    return { ...section };
  }), [ navigationList, unassignedList ]);

  const createViewURL = (
    id: number,
  ) => `/view/${ id }`;

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overscrollBehavior = isStickyHeader ? 'none' : 'auto';
  }, [ isStickyHeader ]);

  const getItemContent = (subItem: CustomViewNavigate, url: string) => {

    return !subItem.url?.includes('coming-soon') ? (
      <NavLink to={ url }>
        { getDisplayName(subItem.name) }
      </NavLink>
    ) : (
      <NavLink
        to={ url }
        className={ styles.disableItem }>
        { getDisplayName(subItem.name) }
        <Tooltip title={ t('tooltip-coming-later') } mouseEnterDelay={ .4 }>
          <span className={ styles.iconWrapper }>
            <Clock className={ styles.iconClock }/>
          </span>
        </Tooltip>
      </NavLink>
    );
  };

  useEffect(() => {
    sectionList.forEach(section => {
      section.views.forEach(
        view => {
          if (location.pathname.includes(view.url || createViewURL(view.id))) {
            setSelectedKeys([ view.id.toString() ]);
            setOpenKeys([ getDisplayName(section.name) ]);
          }
        }
      );
    });
  }, [ location ]);

  useEffect(() => {
    organizationService.getCustomViewsSection().then(({ data }) => {
      dispatch(loadNavigationList(data));
    });

  }, []);

  useEffect(() => {
    const sections = navigationListWithWarnings.map(el => ({
      label: getDisplayName(el.name),
      key: getDisplayName(el.name),
      icon: <div className={ styles.navbarIconWrapper }>
        <img
          src={ el.icon }
          alt='icon'
        />
        {
          (el.warning &&
            <Tooltip title={ el.warningTooltip } mouseEnterDelay={ .2 } placement='right'>
              <WarningIcon className={ styles.navbarIconWarning }/>
            </Tooltip> )
        }
      </div>,
      children: el.views.map(item => {
        const url = item.url || createViewURL(item.id);
        if (location.pathname.includes(url)) {
          setSelectedKeys([ item.id.toString() ]);
          setOpenKeys([ getDisplayName(el.name) ]);
        }
        return {
          id: item.id,
          key: item.id,
          label: getItemContent(item, url),
          default: item.default,
        };
      })
    }));
    setNavigationSections(sections);
  }, [ navigationListWithWarnings, i18n.language ]);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const onOpenChange = (keys: string[]) => {
    setIsOpen(true);
    setOpenKeys([ keys.at(-1) ]);
  };

  const logout = () => {
    logoutUser()
      .finally(() => {
        handleLogout();
      });
  };

  return (
    <>
      <div
        className={ styles.navbar + (!isOpen ? ` ${ styles.collapsed }` : '') }
      >
        <div className={ styles.navbarContent }>
          <div className={ styles.navTop }>
            <Link to={ routes.home } className={ styles.logo } onClick={ onClose }>
              <Logo/>
              { isOpen ? <div className={ styles.name }>
                <LogoText/>
              </div> : null }
            </Link>
            { isOpen ? <Close onClick={ onClose } className={ styles.close }/> : null }
          </div>
          <Menu
            mode='inline'
            inlineIndent={ 24 }
            openKeys={ isOpen ? openKeys : [] }
            onOpenChange={ onOpenChange }
            defaultSelectedKeys={ selectedKeys }
            className={ styles.menu }
            onClick={ onOpen }
            items={
              navigationSections
            }
            expandIcon={ isOpen ? <ArrowRight/> : <span></span> }
          />
          <div className={ styles.bottomSection }>
            <Button type='link' className={ styles.logoutButton } onClick={ logout }>
              <Logout/>
              <span className={ !isOpen ? styles.collapsed : '' }>{ t('buttons.logout') }</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
