import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICellEditorParams, IRowNode } from 'ag-grid-community';
import SearchableSelect from 'components/elements/searchableSelect/SearchableSelect';
import { DimensionItem } from 'types/filterTable.types';
import { getDimensionViewName } from 'utils/financials.utils';
import { useTranslation } from 'react-i18next';
import {
  UNASSIGNED_ROW_ID
} from 'components/singleRevenueRecognition/invoicesTable/invoicesTable.utils';

interface ValuesProps {
  values?: DimensionItem[];
}

interface Props {
  props: ICellEditorParams & ValuesProps;
  onChangeDimensionItem: (node: IRowNode, id: number) => Promise<void>;
  canBeUnassigned: boolean;
}

export const DimensionCellEditor = ({ props, onChangeDimensionItem, canBeUnassigned }: Props) => {
  const [ t ] = useTranslation('common');

  const [ activeChoice, setActiveChoice ] = useState<number>(props.value);
  const refContainer = useRef(null);

  useEffect(() => {
    const handleSideArrowKeydown = (event: KeyboardEvent) => {
      if ([ 'ArrowLeft', 'ArrowRight' ].includes(event.key)) {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleSideArrowKeydown);

    return () => {
      document.removeEventListener('keydown', handleSideArrowKeydown);
    };
  }, []);

  const stopEditing = useCallback(() => {
    props.api.stopEditing(true);
    props.api.setFocusedCell(props.rowIndex, props.column.getColId());
  }, [ props ]);

  const handleChoiceChange = useCallback(async (id: number) => {
    setActiveChoice(id);
    await onChangeDimensionItem(props.node, id);
    stopEditing();
  }, [ props, onChangeDimensionItem ]);

  const handleEscapeDropdown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      stopEditing();
    }
  }, []);

  const options = useMemo(() => {
    const UNASSIGNED_OPTION = {
      label: t('common:unassigned'),
      value: UNASSIGNED_ROW_ID,
    };
    const commonOptions = props.values.map((item: DimensionItem) => ({
      label: getDimensionViewName(item),
      value: item.id,
    }));

    if (!canBeUnassigned) return commonOptions;
    return [ ...commonOptions, UNASSIGNED_OPTION ];
  }, [ props.values, canBeUnassigned ]);

  return (
    <div ref={ refContainer } style={ { height: '100%' } }>
      <SearchableSelect
        open={ true }
        autoFocus={ true }
        callback={ handleEscapeDropdown }
        options={ options }
        defaultValue={ activeChoice }
        popupClassName='ag-custom-component-popup'
        onChange={ handleChoiceChange }
      />
    </div>
  );
};
