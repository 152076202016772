export enum ContractType {
  // t('contracts:type.active')
  // t('contracts:type.churned')
  // t('contracts:type.booked')
  // t('contracts:type.planned')
  // t('contracts:type.paused')
  // t('contracts:type.trial')
  // t('contracts:type.pilot')
  ACTIVE = 'active',
  CHURNED = 'churned',
  BOOKED = 'booked',
  PLANNED = 'planned',
  PAUSED = 'paused',
  TRIAL = 'trial',
  PILOT = 'pilot',
}

export enum InvoicingFrequency {
  // t('contracts:frequency.one_time')
  // t('contracts:frequency.weekly')
  // t('contracts:frequency.bi_monthly')
  // t('contracts:frequency.monthly')
  // t('contracts:frequency.every_2_months')
  // t('contracts:frequency.every_3_months')
  // t('contracts:frequency.every_4_months')
  // t('contracts:frequency.every_6_months')
  // t('contracts:frequency.every_12_months')
  // t('contracts:frequency.quarterly')
  ONE_TIME = 'one_time',
  WEEKLY = 'weekly',
  BI_MONTHLY = 'bi_monthly',
  MONTHLY = 'monthly',
  EVERY_2_MONTHS = 'every_2_months',
  EVERY_3_MONTHS = 'every_3_months',
  EVERY_4_MONTHS = 'every_4_months',
  EVERY_6_MONTHS = 'every_6_months',
  EVERY_12_MONTHS = 'every_12_months',
  QUARTERLY = 'quarterly',
}

export enum Duration {
  // t('financials:duration.1_month')
  // t('financials:duration.3_months')
  // t('financials:duration.6_months')
  // t('financials:duration.12_months')
  // t('financials:duration.18_months')
  // t('financials:duration.24_months')
  FOR_1_MONTH = '1_month',
  FOR_3_MONTHS = '3_months',
  FOR_6_MONTHS = '6_months',
  FOR_12_MONTHS = '12_months',
  FOR_18_MONTHS = '18_months',
  FOR_24_MONTHS = '24_months',
}

export interface Contract {
  id: number;
  customer: number;
  name: string;
  status: ContractType;
  startDate?: string;
  endDate?: string;
  invoicingFrequency: InvoicingFrequency;
}

export enum ContractTab {
  INFO = 'info',
  CONTRACTS = 'contracts',
}

export interface SelectList{
  id: number;
  name: string;
  type: string;
}

export enum GroupByStatusList{
  ALL = 'All',
  ACTIVE = 'Active',
  TRIAL = 'Trial',
  BOOKED = 'Booked',
  PLANNED = 'Planned',
  CHURNED = 'Churned',
  ENDED = 'Ended',
  EMPTY = 'Empty'
}

export type GroupByStatusItem = keyof typeof GroupByStatusList;

export interface ContractWizard {
  customers: number[];
  planStart?: string;
  invoicingFrequency: InvoicingFrequency;
}
