import {
  ColumnType,
  DynamicColumnSettings,
  DynamicColumnType,
  DynamicSettingsConfiguration,
} from 'types/financials.types';
import { Cadence } from 'types/form.types';
import { t } from 'i18next';

type BaseElement = Omit<DynamicSettingsConfiguration, 'active' | 'options' | 'id'>;

const actualTotalElement = (): BaseElement => ({
  title: t('dynamicColumns:columns.actual-total'),
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.ACTUAL_TOTAL,
  subtitle: t('dynamicColumns:subtitles.actual-total'),
  disabledCadences: [],
});

const budgetTotalElement = (): BaseElement => ({
  title: t('dynamicColumns:columns.budget-total'),
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.BUDGET_TOTAL,
  subtitle: t('dynamicColumns:subtitles.budget-total'),
  disabledCadences: [],
});

const grandTotalElement = (): BaseElement => ({
  title: t('dynamicColumns:columns.grand-total'),
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.GRAND_TOTAL,
  subtitle: t('dynamicColumns:subtitles.grand-total'),
  disabledCadences: [],
});

const yearToDateElement = (): BaseElement => ({
  title: 'YTD',
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.YEAR_TO_DATE,
  subtitle: t('dynamicColumns:subtitles.year_to_date'),
  disabledCadences: [],
});

const calendarYearElement = (): BaseElement => ({
  title: 'CY',
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.CALENDAR_YEAR,
  subtitle: t('dynamicColumns:subtitles.calendar_year'),
  disabledCadences: [ Cadence.year ],
});

const ttmElement = (): BaseElement => ({
  title: 'TTM',
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.TRAILING_12_MONTHS,
  subtitle: t('dynamicColumns:subtitles.trailing_12_months'),
  disabledCadences: [],
});

const t6mElement = (): BaseElement => ({
  title: 'T6M',
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.TRAILING_6_MONTHS,
  subtitle: t('dynamicColumns:subtitles.trailing_6_months'),
  disabledCadences: [ Cadence.year ],
});

const t3mElement = (): BaseElement => ({
  title: 'T3M',
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.TRAILING_3_MONTHS,
  subtitle: t('dynamicColumns:subtitles.trailing_3_months'),
  disabledCadences: [ Cadence.year ],
});
const halfYearElement = (): BaseElement => ({
  title: 'H',
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.HALF_YEAR,
  subtitle: t('dynamicColumns:subtitles.half_year'),
  optionsList: [ 'H1', 'H2' ],
  disabledCadences: [ Cadence.year ],
});

const quarterElement = (): BaseElement => ({
  title: 'Q',
  columnType: ColumnType.TOTALS,
  value: DynamicColumnType.QUARTERLY_TOTAL,
  subtitle: t('dynamicColumns:subtitles.quarterly_total'),
  optionsList: [ 'Q1', 'Q2', 'Q3', 'Q4' ],
  disabledCadences: [ Cadence.year, Cadence.quarter ],
});
const percentageOfRevenueBaseElement = (): BaseElement => ({
  title: t('dynamicColumns:columns.revenue-percentage-base'),
  columnType: ColumnType.PERCENTAGE_OF_REVENUE,
  value: DynamicColumnType.PERCENTAGE_OF_REVENUE_BASE,
  subtitle: t('dynamicColumns:subtitles.revenue_percentage'),
  disabledCadences: [],
});

const percentageOfRevenueTotalElement = (): BaseElement => ({
  ...percentageOfRevenueBaseElement(),
  value: DynamicColumnType.PERCENTAGE_OF_REVENUE_TOTALS,
  title: t('dynamicColumns:columns.revenue-percentage-totals'),
});

const periodNominalElement = (cadence: Cadence): BaseElement => ({
  /**
     * t(`dynamicColumns:columns.week_over_week_nominal`)
     * t(`dynamicColumns:columns.month_over_month_nominal`)
     * t(`dynamicColumns:columns.quarter_over_quarter_nominal`)
     * t(`dynamicColumns:columns.year_over_year_nominal`)
     */
  title: t(`dynamicColumns:columns.${ cadence }_over_${ cadence }_nominal`),
  columnType: ColumnType.CHANGE,
  value: DynamicColumnType.PERIOD_NOMINAL,
  subtitle: t('dynamicColumns:subtitles.period_over_period_nominal'),
  disabledCadences: [ Cadence.year ],
});

const periodPercentElement = (cadence: Cadence): BaseElement => ({
  /**
   * t(`dynamicColumns:columns.week_over_week_percent`)
   * t(`dynamicColumns:columns.month_over_month_percent`)
   * t(`dynamicColumns:columns.quarter_over_quarter_percent`)
   * t(`dynamicColumns:columns.year_over_year_percent`)
   */
  title: t(`dynamicColumns:columns.${ cadence }_over_${ cadence }_percent`),
  columnType: ColumnType.CHANGE,
  value: DynamicColumnType.PERIOD_PERCENT,
  subtitle: t('dynamicColumns:subtitles.period_over_period_percent'),
  disabledCadences: [ Cadence.year ],
});

const yearNominalElement = (): BaseElement => ({
  title: t('dynamicColumns:columns.year_over_year_nominal'),
  columnType: ColumnType.CHANGE,
  value: DynamicColumnType.YEAR_NOMINAL,
  subtitle: t('dynamicColumns:subtitles.year_over_year_nominal'),
  disabledCadences: [],
});

const yearPercentElement = (): BaseElement => ({
  title: t('dynamicColumns:columns.year_over_year_percent'),
  columnType: ColumnType.CHANGE,
  value: DynamicColumnType.YEAR_PERCENT,
  subtitle: t('dynamicColumns:subtitles.year_over_year_percent'),
  disabledCadences: [],
});

const budgetVarianceNominalElement = (): BaseElement => ({
  title: t('dynamicColumns:columns.budget_vs_actual_nominal'),
  columnType: ColumnType.BUDGET_VARIANCE,
  value: DynamicColumnType.BUDGET_VS_ACTUAL,
  subtitle: t('dynamicColumns:subtitles.budget_vs_actual_nominal'),
  disabledCadences: [],
});

const budgetVariancePercentElement = (): BaseElement => ({
  title: t('dynamicColumns:columns.budget_vs_actual_percent'),
  columnType: ColumnType.BUDGET_VARIANCE,
  value: DynamicColumnType.BUDGET_VS_ACTUAL_PERCENT,
  subtitle: t('dynamicColumns:subtitles.budget_vs_actual_percent'),
  disabledCadences: [],
});

const budgetVarianceTotalsElement = (): BaseElement => ({
  title: t('dynamicColumns:columns.budget_vs_actual_totals'),
  columnType: ColumnType.BUDGET_VARIANCE,
  value: DynamicColumnType.BUDGET_VS_ACTUAL_TOTALS,
  subtitle: t('dynamicColumns:subtitles.budget_vs_actual_totals'),
  disabledCadences: [],
});

const mapElementToSetting = (
  element: BaseElement,
  index: number
): DynamicSettingsConfiguration => ({
  ...element,
  id: index + 1,
  active: false,
  options: []
});

export const configModalElements = (cadence: Cadence): DynamicSettingsConfiguration[] => ([
  actualTotalElement(),
  budgetTotalElement(),
  grandTotalElement(),
  yearToDateElement(),
  calendarYearElement(),
  ttmElement(),
  t6mElement(),
  t3mElement(),
  halfYearElement(),
  quarterElement(),
  percentageOfRevenueBaseElement(),
  percentageOfRevenueTotalElement(),
  periodNominalElement(cadence),
  periodPercentElement(cadence),
  yearNominalElement(),
  yearPercentElement(),
  budgetVarianceNominalElement(),
  budgetVariancePercentElement(),
  budgetVarianceTotalsElement()
].map(mapElementToSetting));

export const configDropdownElements = (cadence: Cadence): DynamicSettingsConfiguration[] => ([
  actualTotalElement(),
  budgetTotalElement(),
  grandTotalElement(),
  yearToDateElement(),
  ttmElement(),
  calendarYearElement(),
  halfYearElement(),
  quarterElement(),
  percentageOfRevenueBaseElement(),
  percentageOfRevenueTotalElement(),
  periodNominalElement(cadence),
  periodPercentElement(cadence),
  yearNominalElement(),
  yearPercentElement(),
  budgetVarianceNominalElement(),
  budgetVariancePercentElement(),
  budgetVarianceTotalsElement()
].map(mapElementToSetting));

export const optionListCadence: DynamicColumnSettings[] = [
  {
    id: 1,
    name: t('common:cadence.all'),
    value: 'all',
  },
  {
    id: 2,
    name: t('common:cadence.week'),
    value: Cadence.week,
  },
  {
    id: 3,
    name: t('common:cadence.month'),
    value: Cadence.month,
  },
  {
    id: 4,
    name: t('common:cadence.quarter'),
    value: Cadence.quarter,
  },
  {
    id: 5,
    name: t('common:cadence.year'),
    value: Cadence.year,
  }
];
