import { cloneDeep } from 'lodash';
import {
  isBreakdownNode,
  isFinancialsNode,
  isFormulaNode,
  StatutoryRowCategory,
  Template,
  TemplateNode,
  TemplateNodeType,
  TemplateNodeMapping,
  isDimensionItemNode,
  isSubtotalNode,
  isTotalNode,
} from 'types/templates.types';
import { insertAfter, moveItemAtEnd } from 'utils/array.utils';
import {
  compareNodes,
  isDimensionItem,
  isDimensionType,
  isFinancialType, isGroupType,
  templateHelpers,
} from 'utils/template.utils';
import { isOtherTag } from 'utils/templates.utils';
import { TemplateCreationService } from './TemplateCreationService';
import { TemplateRemoveService } from './TemplateRemoveService';
import { RowType } from '../../types/financials.types';
import { getDisplayName } from 'utils/common.utils';

export const TemplateRulesService = (
  nodeId: number | string,
  template: Template,
  financialNodes: TemplateNodeMapping<RowType.FINANCIALS>
) => {
  const newNode = template.nodes[ nodeId ];

  const noDuplicatesInBranch = (validatedTemplate: Template) => {
    if (!newNode?.parent) {
      return validatedTemplate;
    }
    const newNodeParent = validatedTemplate.nodes[ newNode?.parent ];
    const { removeRaw } = TemplateRemoveService(validatedTemplate, financialNodes);
    if (!newNodeParent) {
      return validatedTemplate;
    }
    for (const childId of newNodeParent.children) {
      const childNode = validatedTemplate.nodes[ childId ];
      if (compareNodes(childNode, newNode) &&
        childNode.id !== newNode?.id &&
        !isFormulaNode(newNode) &&
        !isOtherTag(newNode.type)
      ) {
        const isChildBigger = childNode.children.length >= newNode?.children.length;
        if (isChildBigger) {
          return removeRaw(template, newNode?.id, newNode?.parent);
        } else {
          return removeRaw(template, childId, newNode?.parent);
        }
      }
    }
    return validatedTemplate;
  };

  const getFormulasAfterTotal = () => {
    const roots = cloneDeep(template.roots);
    const totalIndex = roots.findIndex((root) => isTotalNode(template.nodes[ root ]));
    if (totalIndex === -1) {
      return [];
    }
    return roots.filter(
      (root) => isFormulaNode(template.nodes[ root ]) && roots.indexOf(root) > totalIndex,
    );
  };

  const totalAndCustomFormulaToBeLast = (validatedTemplate: Template) => {
    const updatedTemplate = cloneDeep(validatedTemplate);
    const nodes = Object.values(updatedTemplate.nodes);
    const total = nodes.find(isTotalNode);
    if (total) {
      let roots = cloneDeep(updatedTemplate.roots);
      moveItemAtEnd(roots, total.id);
      const formulasAfterTotal = getFormulasAfterTotal();
      if (formulasAfterTotal.length) {
        formulasAfterTotal.forEach((formulaId) => {
          roots = insertAfter(roots, formulaId, total.id);
        });
      }
      updatedTemplate.roots = roots;
    }
    return updatedTemplate;
  };

  const subtotalAndCustomFormulaToBeLastInBranch = (validatedTemplate: Template) => {
    const updatedTemplate = cloneDeep(validatedTemplate);
    const formulas = Object.values(updatedTemplate.nodes).filter(node => {
      return node.parent &&
        (node.type === RowType.SUBTOTAL || isFormulaNode(node));
    });
    formulas.forEach((node) => {
      const children = cloneDeep(updatedTemplate.nodes[ node.parent ].children);
      moveItemAtEnd(children, node.id);
      updatedTemplate.nodes[ node.parent ].children = children;
    });
    return updatedTemplate;
  };

  const oneTotalPerTemplate = (validatedTemplate: Template) => {
    if (!isTotalNode(newNode)) {
      return validatedTemplate;
    }
    let updatedTemplate = cloneDeep(validatedTemplate);
    const { removeRaw } = TemplateRemoveService(updatedTemplate, financialNodes);
    const isTotalInTemplate = updatedTemplate.roots.some(root => {
      const rootNode = updatedTemplate.nodes[ root ];
      return isTotalNode(rootNode) && rootNode.id !== newNode?.id;
    });
    if (isTotalInTemplate) {
      updatedTemplate = removeRaw(template, newNode?.id, null);
    }
    return updatedTemplate;
  };

  const oneSubtotalPerBranch = (validatedTemplate: Template) => {
    if (!isSubtotalNode(newNode)) {
      return validatedTemplate;
    }
    let updatedTemplate = cloneDeep(validatedTemplate);
    const { removeRaw } = TemplateRemoveService(validatedTemplate, financialNodes);
    if (newNode?.parent) {
      const parent = template.nodes[ newNode?.parent ];
      const isSubtotalInBranch = parent.children.find((child) => {
        const childNode = template.nodes[ child ];
        return isSubtotalNode(childNode) && childNode.id !== newNode?.id;
      });
      if (isSubtotalInBranch) {
        updatedTemplate = removeRaw(template, newNode?.id, newNode?.parent);
      }
    }
    return updatedTemplate;
  };

  const validateFormulasNotExistingNodes = (validatedTemplate: Template) => {
    const updatedTemplate = cloneDeep(validatedTemplate);
    const formulaNodes = Object.values(template.nodes).filter(isFormulaNode);

    formulaNodes.forEach((node) => {
      const formulaTmp = cloneDeep(node);
      const formulaElements = formulaTmp.rowData.formulaElements;
      const filteredNodes = formulaElements?.filter(element => {
        if (!element.templateNode) {
          return true;
        }
        const isInTemplate =
          Object.values(template.nodes)
            .find(nodeToCheck => nodeToCheck.id === element.templateNode);
        return isInTemplate;
      });

      if (formulaElements.length !== filteredNodes.length) {
        formulaTmp.rowData.formulaElements = filteredNodes;
        updatedTemplate.nodes[ node.id ] = formulaTmp;
      }
    });
    return updatedTemplate;
  };

  const isSameDimensionNode = (node: TemplateNode) => {
    return node?.type === newNode?.type &&
      getDisplayName(node?.rowData?.name) === getDisplayName(newNode?.rowData?.name) &&
      node?.id !== newNode?.id;
  };

  const findDimensionRepeatOnBranch = (
    node: TemplateNode,
    searchInParent = true,
    searchInChildren = true,
  ): TemplateNode => {
    if (isSameDimensionNode(node)) {
      return node;
    }

    if (searchInChildren && node?.children) {
      for (const child of node.children) {
        const duplicate = findDimensionRepeatOnBranch(template.nodes[ child ], false, true);
        if (duplicate) {
          return duplicate;
        }
      }
    }
    if (searchInParent && node?.parent) {
      const duplicate = findDimensionRepeatOnBranch(template.nodes[ node.parent ], true, false);
      if (duplicate) {
        return duplicate;
      }
    }

    return null;
  };

  const oneDimensionPerBranch = (validatedTemplate: Template) => {
    if (!isDimensionItem(newNode) && !isDimensionType(newNode)) {
      return validatedTemplate;
    }
    let updatedTemplate = cloneDeep(validatedTemplate);
    const { remove } = TemplateRemoveService(updatedTemplate, financialNodes);
    const duplicate = findDimensionRepeatOnBranch(template.nodes[ newNode.parent ]);
    if (duplicate) {
      updatedTemplate = remove(newNode?.id);
    }
    return updatedTemplate;
  };

  const isTagInBranch = (tagId: number | string) => {
    const branchParent = template.nodes[ newNode?.parent ];
    if (isFinancialsNode(branchParent)) {
      if (branchParent.rowData.id === tagId) {
        return true;
      }
    }
    if (!branchParent) {
      return false;
    }
    return branchParent.children.some((child) => {
      const childNode = template.nodes[ child ];
      if (isFinancialsNode(childNode)) {
        return childNode.rowData.id === tagId;
      }
    });
  };

  const fillMissedParents =
    (validatedTemplate: Template, children: TemplateNodeType<RowType.FINANCIALS>[]) => {
      let updatedTemplate = cloneDeep(validatedTemplate);
      const parents = new Set<number | string>();
      const childrenToDelete: TemplateNode[] = [];
      if (children.length === 1) {
        return validatedTemplate;
      }

      children.forEach((child) => {
        if (isFinancialType(child)) {
          const tag = financialNodes[ child.rowData.id ];
          const parentTag = tag?.rowData.nodesPath.at(-2);
          if (parentTag) {
            parents.add(+parentTag);
          }
        }
      });

      const parentsArray = Array.from(parents);
      if (parentsArray.length <= 1) {
        return validatedTemplate;
      }

      children.forEach((child) => {
        const tag = financialNodes[ child.rowData.id ];
        if (!isTagInBranch(+tag.rowData.nodesPath.at(-2))) {
          childrenToDelete.push(child);
        }
      });

      const { removeRaw } = TemplateRemoveService(updatedTemplate, financialNodes);
      childrenToDelete.forEach((child) => {
        updatedTemplate = removeRaw(updatedTemplate, child.id, child.parent);
      });

      parentsArray.forEach((parentId) => {
        if (!isTagInBranch(parentId)) {
          const tag = cloneDeep(financialNodes[ parentId ]);
          const childrenInTag = children.filter((child) => {
            const childTag = financialNodes[ child.rowData.id ];
            return tag.children.includes(childTag.id);
          });
          tag.childrenNodes = childrenInTag.map(child => {
            return financialNodes[ child.rowData.id ];
          });
          tag.children = childrenInTag.map(child => child.rowData.id);

          const { addNode } = TemplateCreationService(
            tag,
            updatedTemplate,
            {
              nodeBefore: null,
              parent: newNode.parent,
              isRoot: newNode.parent == null
            }
          );
          const { updatedTemplate: temp } = addNode();
          updatedTemplate = temp;
        }
      });

      return updatedTemplate;
    };

  const sameFinancialDepthOnBranch = (validatedTemplate: Template) => {
    const parent = newNode?.parent;
    if (!parent ||
      !isFinancialType(newNode) ||
      isGroupType(validatedTemplate.nodes[ parent ])
    ) {
      return validatedTemplate;
    }
    let updatedTemplate = cloneDeep(validatedTemplate);
    const parentNode = updatedTemplate.nodes[ parent ];
    const children: TemplateNodeType<RowType.FINANCIALS>[] = parentNode.children
      .map(child => updatedTemplate.nodes[ child ])
      .filter(isFinancialsNode);

    updatedTemplate = fillMissedParents(updatedTemplate, children);

    return updatedTemplate;
  };
  const sameFinancialFamilyOnBranch = (validatedTemplate: Template) => {
    const parent = newNode?.parent;
    const parentNode = template.nodes[ parent ];
    if (!parent || !isFinancialType(newNode) || !isFinancialType(parentNode)) {
      return validatedTemplate;
    }
    let updatedTemplate = cloneDeep(validatedTemplate);
    const helpers = templateHelpers(updatedTemplate, financialNodes, {});
    const item = newNode;
    const parentTag = parentNode;
    if (isFinancialsNode(item) && isFinancialsNode(parentTag) &&
      !helpers.isAncestor(item, parentTag)) {
      const { removeRaw } = TemplateRemoveService(updatedTemplate, financialNodes);
      updatedTemplate = removeRaw(updatedTemplate, newNode.id, parent);
    }
    return updatedTemplate;
  };

  const _getFirstFinancialParent = (node: TemplateNode, validatedTemplate: Template) => {
    let parent = validatedTemplate.nodes[ newNode.parent ];
    while (parent && parent.type !== RowType.FINANCIALS) {
      parent = validatedTemplate.nodes[ parent.parent ];
    }
    return parent;
  };

  const _preventNodeForBalanceSheet = (node: TemplateNode, validatedTemplate: Template) => {
    const parent = _getFirstFinancialParent(newNode, validatedTemplate);
    const isDroppingOnBalanceSheetFinancialItem = parent?.type === RowType.FINANCIALS &&
      parent?.rowData.category !== StatutoryRowCategory.PROFIT_AND_LOSS;
    if (isDroppingOnBalanceSheetFinancialItem) {
      const updatedTemplate = cloneDeep(validatedTemplate);
      const { remove } = TemplateRemoveService(updatedTemplate, financialNodes);
      return remove(newNode.id);
    }
    return validatedTemplate;
  };

  const balanceSheetDimensionItem = (validatedTemplate: Template) => {
    if (newNode && newNode.type === RowType.DIMENSION_ITEM) {
      return _preventNodeForBalanceSheet(newNode, validatedTemplate);
    }
    return validatedTemplate;
  };

  const droppingBalanceSheetOnBreakdown = (validatedTemplate: Template) => {
    const isNewNodeBalanceSheet = newNode?.type === RowType.FINANCIALS &&
      newNode?.rowData.category !== StatutoryRowCategory.PROFIT_AND_LOSS;
    if (!isNewNodeBalanceSheet) {
      return validatedTemplate;
    }
    const parent = validatedTemplate.nodes[ newNode.parent ];
    const isDroppingOntoBreakdown = parent && isBreakdownNode(parent);
    if (isDroppingOntoBreakdown) {
      const isAccount = parent.rowData.relation === 'ACCOUNT';
      if (isAccount) {
        return validatedTemplate;
      }
      const updatedTemplate = cloneDeep(validatedTemplate);
      const { removeRaw } = TemplateRemoveService(updatedTemplate, financialNodes);
      return removeRaw(updatedTemplate, newNode.id, parent.id);
    }
    return validatedTemplate;
  };

  const droppingBalanceSheetOnDimensionItem = (validatedTemplate: Template) => {
    const isNewNodeBalanceSheet = newNode?.type === RowType.FINANCIALS &&
      newNode?.rowData.category !== StatutoryRowCategory.PROFIT_AND_LOSS;
    if (!isNewNodeBalanceSheet) {
      return validatedTemplate;
    }
    const parent = validatedTemplate.nodes[ newNode.parent ];
    const isDroppingOntoDimensionItem = parent && isDimensionItemNode(parent);
    if (isDroppingOntoDimensionItem) {
      const updatedTemplate = cloneDeep(validatedTemplate);
      const { removeRaw } = TemplateRemoveService(updatedTemplate, financialNodes);
      return removeRaw(updatedTemplate, newNode.id, parent.id);
    }
    return validatedTemplate;
  };

  const validate = (): Template => {
    let validatedTemplate = cloneDeep(template);

    validatedTemplate = sameFinancialDepthOnBranch(validatedTemplate);
    validatedTemplate = noDuplicatesInBranch(validatedTemplate);
    validatedTemplate = sameFinancialFamilyOnBranch(validatedTemplate);
    validatedTemplate = oneTotalPerTemplate(validatedTemplate);
    validatedTemplate = oneSubtotalPerBranch(validatedTemplate);
    validatedTemplate = totalAndCustomFormulaToBeLast(validatedTemplate);
    validatedTemplate = subtotalAndCustomFormulaToBeLastInBranch(validatedTemplate);
    validatedTemplate = validateFormulasNotExistingNodes(validatedTemplate);
    validatedTemplate = oneDimensionPerBranch(validatedTemplate);
    validatedTemplate = balanceSheetDimensionItem(validatedTemplate);
    validatedTemplate = droppingBalanceSheetOnBreakdown(validatedTemplate);
    validatedTemplate = droppingBalanceSheetOnDimensionItem(validatedTemplate);

    return validatedTemplate;
  };

  return {
    validate
  };
};
