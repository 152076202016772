import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSettings from './language/LanguageSettings';
import OrganizationSelect from '../../elements/organizationSelect/OrganizationSelect';
import useFeatureFlag from '../../../hooks/useFeatureFlag';
import styles from './Global.module.scss';
import { FeatureFlag } from '../../../types/auth.types';

const Global = () => {
  const { t } = useTranslation('settings');
  const { isFeatureEnabled } = useFeatureFlag();

  return (
    <>
      <LanguageSettings/>

      { isFeatureEnabled(FeatureFlag.HIDE_ORGANIZATION_DROPDOWN) &&
        <div className={ styles.settingsSection }>
          <h2>{ t('global.organization') }</h2>
          <div className={ styles.organizationSelectContainer }>
            <OrganizationSelect/>
          </div>
        </div>
      }

    </>
  );
};

export default Global;
