import React from 'react';
import ConfirmButtons from
  'components/dashboard/panel/cardSettingsPage/forms/buttons/ConfirmButtons';
import styles from '../Forms.module.scss';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';

interface Props {
  onSubmit: () => void;
  children: React.ReactNode;
  loading?: boolean;
}

const FormWrapper = ({ onSubmit, children, loading=false }: Props) => {
  return <form className={ styles.form } onSubmit={ onSubmit }>
    <DynamicScrollableContainer>
      <div className={ styles.formScroll }>
        { children }
      </div>
    </DynamicScrollableContainer>
    <ConfirmButtons loading={ loading } />
  </form>;
};

export default FormWrapper;