import React, { useCallback, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import SpinningLoader from '../spinning-loader/SpinningLoader';
import styles from './PdfViewer.module.scss';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Print } from 'assets/icons/print.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as EmptyPdf } from 'assets/image/EmptyPdf.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/chevron-right-dark.svg';
import { useTranslation } from 'react-i18next';
import { InvoicePdfData, PdfI } from './pdf.types';
import Pagination from '../pagination/Pagination';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

pdfjs.GlobalWorkerOptions.workerSrc =
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${ pdfjs.version }/pdf.worker.js`;

type Props = {
  pdfs?: PdfI[];
  canBeZoomed?: boolean;
  isManagable?: boolean;
  onClose?: () => void;
  pdfName?: string;
  maxWidth?: number;
};

type PDFDocument = {
  numPages: number;
};

const PdfViewer = ({
  pdfs = [],
  canBeZoomed = true,
  isManagable = true,
  onClose,
  maxWidth,
}: Props) => {

  const [ numPages, setNumPages ] = useState<number>();
  const [ scale, setScale ] = useState<number>(1);
  const [ currentPdfIndex, setCurrentPdfIndex ] = useState(1);
  const hasManyFiles = useMemo(() => Array.isArray(pdfs) && pdfs.length > 1, [ pdfs ]);
  const [ t ] = useTranslation('financials');
  const currentPdf = useMemo(() => {
    return pdfs[ 0 ];
  }, [ pdfs, currentPdfIndex ]);

  const handlePrint = useCallback(async () => {
    if (!currentPdf) return;

    let blob = null;
    let pdfURL = null;

    if (currentPdf.data) {
      blob = new Blob([ currentPdf.data ], { type: 'application/pdf' });
    } else {
      ({ blob } = await getInvoice(currentPdf));
    }
    pdfURL = URL.createObjectURL(blob);

    const tempFrame = document.createElement('iframe');
    tempFrame.style.display = 'none';
    tempFrame.src = pdfURL;

    tempFrame.onload = () => {
      tempFrame.contentWindow.print();
    };
    document.body.appendChild(tempFrame);
  }, [ currentPdf ]);

  const onDocumentLoadSuccess = useCallback((pdf: PDFDocument) => {
    setNumPages(pdf.numPages);
  }, []);

  const getInvoice = useCallback(async (pdf: PdfI): Promise<InvoicePdfData> => {
    const response = await fetch(pdf.link);
    const blob = await response.blob();
    const urlParts = pdf.link.split('/');
    const filenamePart = urlParts[ urlParts.length - 1 ];
    const filename = filenamePart.split('?')[ 0 ];
    return { filename, blob };
  }, []);

  const downloadHandler = useCallback(async () => {
    if (!currentPdf) return;

    const downloadLink = document.createElement('a');
    let filename = '';
    let blob = null;

    const download = (href: string, name: string) => {
      downloadLink.href = href;
      downloadLink.download = name;
      downloadLink.click();
    };

    if (currentPdf.link) {
      ({ filename, blob } = await getInvoice(currentPdf));
    } else {
      blob = new Blob([ currentPdf.data ], { type: 'application/pdf' });
      filename = currentPdf?.name || 'test.pdf';
    }
    download(URL.createObjectURL(blob), filename);
  }, [ currentPdf ]);

  const handlePageChange = useCallback((n: number) => {
    setCurrentPdfIndex((prev) => prev + n);
  }, []);

  const pdfFileElement = useMemo(() => {
    if (!pdfs.length) return null;
    return <div className={ styles.documentWrapper }>
      <Document
        file={ currentPdf?.data ? { data: currentPdf?.data } : currentPdf.link }
        className={ styles.document }
        onLoadSuccess={ onDocumentLoadSuccess }
        loading={ <div className={ styles.pdfLoader }>
          <SpinningLoader/>
        </div> }
      >
        {
          Array.from(new Array(numPages), (_, index) => (
            <Page
              key={ `page_${ index + 1 }` }
              pageNumber={ index + 1 }
              scale={ scale }
            />
          ))
        }
      </Document>
    </div>;
  }, [ currentPdf, scale ]);

  return (
    <div
      className={ styles.pdf }
      style={ { width: maxWidth } }
    >
      {
        isManagable && <div className={ styles.pdfTopBar }>
          <div className={ styles.pdfNavigation }>
            <span className={ styles.pdfTitle }>
              PDF
            </span>
            <div className={ styles.files }>
              {
                hasManyFiles && (
                  <Pagination
                    total={ pdfs.length }
                    current={ currentPdfIndex }
                    onChange={ (page) => {
                      setCurrentPdfIndex(page);
                    } }
                  />
                )
              }
            </div>
          </div>
          <div className={ `${ styles.pdfControl } ${ !pdfs && styles.inactive }` }>
            <GhostIconButton onClick={ downloadHandler }>
              <Download />
            </GhostIconButton>
            <GhostIconButton onClick={ handlePrint }>
              <Print />
            </GhostIconButton>
            <GhostIconButton onClick={ onClose }>
              <Close />
            </GhostIconButton>
          </div>
        </div>
      }
      { pdfFileElement }
      { ' ' }
      {
        pdfs.length === 0 && isManagable && <div className={ styles.pdfEmpty }>
          <EmptyPdf />
          { t('pdf-viewer') }
        </div>
      }
      { !!pdfs.length && canBeZoomed && <div className={ styles.scaleButtons }>
        <button onClick={ () => setScale(scale*1.5) }>
          <Plus />
        </button>
        <button onClick={ () => setScale(scale/1.5) }>
          <Minus />
        </button>
      </div>
      }
      <div className={ styles.paginationButtons }>
        {
          currentPdfIndex > 1 && hasManyFiles && (
            <button
              onClick={ () => handlePageChange(-1) }
              className={ styles.arrowLeft }
            >
              <ArrowRight />
            </button>
          )
        }
        <div className={ styles.filler }/>
        {
          hasManyFiles && currentPdfIndex < pdfs.length - 1 && (
            <button
              className={ styles.arrow }
              onClick={ () => handlePageChange(1) }
            >
              <ArrowRight />
            </button>
          )
        }
      </div>
    </div>
  );
};

export default PdfViewer;
