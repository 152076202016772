import { RefObject, useCallback } from 'react';
import { CellRange, IRowNode } from 'ag-grid-community';
import { AG_GRID_AUTO_COLUMN, getFieldType, trimReportType } from 'utils/grid.utils';
import { ReportType } from 'types/templates.types';
import dayjs, { OpUnitType } from 'dayjs';
import { ParamsByReportType, Period, RowType } from 'types/financials.types';
import { TransactionLineRequestParams } from 'services/statutory.service';
import {
  UNASSIGNED_ROW_NAME,
} from '../../../singleRevenueRecognition/invoicesTable/invoicesTable.utils';
import { getTransactionQuery } from 'utils/financials.utils';
import { groupBy } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { range } from 'utils/array.utils';
import { isRangeSelection } from 'components/financials/utils/selection.utils';

const FORBIDDEN_ROW_TYPES: RowType[] = [
  RowType.TOTAL,
  RowType.SUBTOTAL,
  RowType.GROUP,
  RowType.SPACER,
  RowType.HALF_SPACER,
  RowType.TITLE
];

type PeriodByReportType = {
  [key in ReportType]: Period | null;
};

interface Props {
  templateId: number;
  period: Period;
  gridRef: RefObject<AgGridReact>;
}

const useTransactionRequestParams = ({ templateId, period, gridRef }: Props) => {
  const isNodeIncluded = useCallback((node: IRowNode, nodes: IRowNode[]): boolean => {
    if (nodes.includes(node)) {
      return true;
    }
    if (node.level === 0) {
      return false;
    }
    const parentNode = node.parent;
    if (!parentNode) {
      return false;
    }
    return isNodeIncluded(parentNode, nodes);
  }, []);

  const filterNodesChildren = useCallback((rows: IRowNode[]): IRowNode[] => {
    const rootNodes: IRowNode[] = [];
    const sortedNodes = rows.sort((a, b) => a.level - b.level);
    sortedNodes.forEach(node => {
      if (!isNodeIncluded(node, rootNodes)) {
        rootNodes.push(node);
      }
    });

    return rootNodes;
  }, []);

  const getPeriodFromFields = useCallback((fields: string[]): PeriodByReportType => {
    const actualFields = [];
    const planFields = [];
    fields.forEach((field) => {
      if (getFieldType(field) === ReportType.ACTUAL) {
        actualFields.push(trimReportType(field, ReportType.ACTUAL));
      } else if (getFieldType(field) === ReportType.PLAN) {
        planFields.push(trimReportType(field, ReportType.PLAN));
      }
    });

    return {
      [ ReportType.ACTUAL ]: {
        cadence: period.cadence,
        ...(actualFields.length > 0 ? {
          startDate: dayjs(actualFields.at(0)).unix(),
          endDate: dayjs(actualFields.at(-1)).endOf(period.cadence as OpUnitType).unix(),
        }: {}),
        ...planFields.length > 0 ? {
          startDatePlan: dayjs(planFields.at(0)).unix(),
          endDatePlan: dayjs(planFields.at(-1)).endOf(period.cadence as OpUnitType).unix(),
        } : {},
        isManuallySet: period.isManuallySet,
      },
      [ ReportType.PLAN ]: planFields.length > 0 ? {
        cadence: period.cadence,
        startDatePlan: dayjs(planFields.at(0)).unix(),
        endDatePlan: dayjs(planFields.at(-1)).endOf(period.cadence as OpUnitType).unix(),
        isManuallySet: period.isManuallySet,
      } : null,
    };
  }, [ period ]);

  const getSelectedPeriod = useCallback(
    (selection: CellRange[] | IRowNode[]): PeriodByReportType => {
      if (isRangeSelection(selection)) {
        // TODO: For now just take first selection area
        const columns = selection.at(0).columns.filter(column => {
          const colDef = column.getUserProvidedColDef();
          return colDef?.headerComponentParams && !colDef.headerComponentParams.isDynamicColumn;
        });
        if (!columns.length) {
          return {
            [ ReportType.ACTUAL ]: null,
            [ ReportType.PLAN ]: null,
          };
        }
  
        const fields = columns.map(column => column.getColDef().field);
        return getPeriodFromFields(fields);
      } else {
        const fields = gridRef.current.columnApi.getColumns().map(column => column.getColId());
        return getPeriodFromFields(fields);
      }
    }, [ period, getPeriodFromFields ]);

  const getGroupedTransactionQuery = useCallback((
    group: IRowNode[],
    queryPeriod: Period
  ): TransactionLineRequestParams[] => {
    let nodes = [ ...group ];
    const query: TransactionLineRequestParams[] = [];
    const unassignedRow = nodes.find(row => row.key === UNASSIGNED_ROW_NAME);
    if (unassignedRow) {
      nodes = nodes.filter(row => row.key !== UNASSIGNED_ROW_NAME);
      query.push(getTransactionQuery(
        [ unassignedRow ],
        templateId,
        queryPeriod
      ));
    }
    if (nodes.length) {
      query.push(getTransactionQuery(nodes, templateId, queryPeriod));
    }
    return query;
  }, []);

  const getParamsByType = useCallback((rows: IRowNode[], selectedPeriod: Period) => {
    const _params: TransactionLineRequestParams[] = [];
    const rowsByType = groupBy(rows, 'data.type');
    const types = Object.keys(rowsByType);
    types.forEach(type => {
      const typeRows = rowsByType[ type ];
      const query = getGroupedTransactionQuery(typeRows, selectedPeriod);
      _params.push(...query);
    });

    return _params;
  }, []);

  const getParamsByRowLevel = useCallback((rows: IRowNode[], selectedPeriod: Period) => {
    if (!selectedPeriod) {
      return [];
    }
    const rowsByLevel = groupBy(rows, 'level');
    const levels = Object.keys(rowsByLevel);
    const _params: TransactionLineRequestParams[] = [];

    levels.forEach(level => {
      const paramsRows = rowsByLevel[ level ];

      // TODO: For now just take the first parent because params may conflict
      const paramsRowsByParent = groupBy(paramsRows, 'parent.id');
      const rowsByParents = Object.values(paramsRowsByParent);

      if (level === '0') {
        const query = getParamsByType(rowsByParents.at(0), selectedPeriod);
        _params.push(...query);
      } else {
        const query = getGroupedTransactionQuery(rowsByParents.at(0), selectedPeriod);
        _params.push(...query);
      }
    });

    return _params;
  }, []);

  const isProperCellRangeSelected = useCallback((selection: CellRange[] | IRowNode[]) => {
    if (!selection) {
      return false;
    }

    if (!isRangeSelection(selection)) {
      return true;
    }

    return selection.some(cellRange => {
      const columns = cellRange.columns.map(column => column.getColId());
      if (columns.length > 0) return true;

      const isAutoColumnSelected = columns.includes(AG_GRID_AUTO_COLUMN);
      return !isAutoColumnSelected;
    });
  }, []);

  const getSelectedRows = useCallback((selection: CellRange[] | IRowNode[]): IRowNode[] => {
    if (!isRangeSelection(selection)) {
      return selection;
    }
    const rowIndexes = selection
      .map((cellRange) => range(cellRange?.startRow?.rowIndex, cellRange?.endRow?.rowIndex))
      .flat();

    return rowIndexes.map(
      rowIndex => gridRef?.current?.api?.getDisplayedRowAtIndex(rowIndex)
    );
  }, []);

  const getRequestParams = useCallback((
    selection: CellRange[] | IRowNode[]
  ): ParamsByReportType => {
    const rows = getSelectedRows(selection);
    if (!isProperCellRangeSelected(selection) || !rows.length) {
      return null;
    }
    const nodes = filterNodesChildren(rows);
    const filteredRows = nodes.filter(row => !FORBIDDEN_ROW_TYPES.includes(row.data?.type));
    const selectedPeriod = getSelectedPeriod(selection);

    return {
      [ ReportType.ACTUAL ]: getParamsByRowLevel(filteredRows, selectedPeriod[ ReportType.ACTUAL ]),
      [ ReportType.PLAN ]: getParamsByRowLevel(filteredRows, selectedPeriod[ ReportType.PLAN ]),
    };
  }, [
    getSelectedRows,
    getSelectedPeriod,
    isProperCellRangeSelected,
    templateId
  ]);

  return { getRequestParams };
};

export default useTransactionRequestParams;
