import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormulaItem, FormulaItemType, TemplateNode, TemplateNodeId } from 'types/templates.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { selectTemplate } from 'store/template.slice';
import { useTemplatesContext } from 'context/TemplatesContext';
import { useTranslation } from 'react-i18next';
import {
  canDropOnFormulaFromBottomPanel,
  canDropOnFormulaFromCanvas,
  DECIMAL_SEPARATOR,
  FORMULA_PANEL_MIN_HEIGHT,
  FormulaBuilderProps,
  isTemplateNodeFromCanvas,
  operators,
  OperatorsType,
  SAFE_KEYS,
} from './utils/formulaBuilder.utils';
import { useDrop } from 'react-dnd';
import { getTemplateTagFormulaType } from 'utils/template.utils';
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { cloneDeep, debounce } from 'lodash';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import FormulaCursor from './formulaCursor/FormulaCursor';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './FormulaBuilder.module.scss';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import ResizableContainer from 'components/elements/resizableContainer/ResizableContainer';
import Operator
  from 'components/templates/customTemplate/formulaBuilder/operator/Operator';
import DropdownFormat
  from 'components/templates/customTemplate/formulaBuilder/dropdownFormat/DropdownFormat';
import { ReactComponent as EqualIcon } from 'assets/icons/equal.svg';
import { ReactComponent as FxIcon } from 'assets/icons/fx-icon.svg';
import useFormula
  from 'components/templates/customTemplate/formulaBuilder/hooks/useFormula';
import useValidation
  from 'components/templates/customTemplate/formulaBuilder/hooks/useValidation';
import useNodeToTag
  from 'components/templates/customTemplate/formulaBuilder/hooks/useNodeToTag';
import FormulaTag
  from 'components/templates/customTemplate/formulaBuilder/formulaTag/FormulaTag';
import {
  selectCustomFormula,
  selectCustomFormulaId,
  updateCustomFormula
} from 'store/formula.slice';

const FormulaBuilder = ({
  formulaValidation,
  setFormulaValidation,
  setHeightPanel,
}: FormulaBuilderProps) => {
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('financials');

  const customFormula = useAppSelector(selectCustomFormula);
  const customFormulaId = useAppSelector(selectCustomFormulaId);
  const template = useAppSelector(selectTemplate);

  const { state:{ contexMenuFormulaElement, editMode } } = useTemplatesContext();
  const { addElement, addOperator, addConstant, remove } = useFormula();
  const { validate } = useValidation();
  const { getTemplateTagFromNode } = useNodeToTag();

  const [ cursor, setCursor ] = useState<number>(customFormula.length);
  const [ activeId, setActiveId ] = useState(null);
  const [ selectedTag, setSelectedTag ] = useState<TemplateNodeId>(null);

  useEffect(() => {
    validateFormula(customFormula);
    if (cursor == null) {
      setCursor(customFormula.length);
    }
  }, [ customFormula ]);

  useEffect(() => {
    if (cursor != null) {
      window.addEventListener('keydown', onKeyDown);
    } else {
      window.removeEventListener('keydown', onKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [ cursor, customFormula ]);

  const getCursorPosition = useCallback((position: number, formula: FormulaItem[]) => {
    let cursorPosition = position;
    if (cursorPosition < 0) {
      cursorPosition = 0;
    }
    if (cursorPosition > formula.length + 1) {
      cursorPosition = formula.length;
    }
    return cursorPosition;
  }, []);

  const moveCursor = useCallback((offset: number) => {
    setCursor((prev) => {
      const cursorPosition = prev + offset;
      return getCursorPosition(cursorPosition, customFormula);
    });
  }, [ customFormula ]);

  const changeCursor = useCallback((position: number) => {
    setCursor(getCursorPosition(position, customFormula));
  }, [ customFormula ]);

  const onKeyDown = useCallback((e) => {
    const key = e.key;
    const number = !isNaN(Number(key)) && key !== ' ';
    if (key === 'Backspace' && customFormula.length >= cursor) {
      const elementToDel = cursor === customFormula.length ?
        customFormula.at(-1) : customFormula[ cursor - 1 ];

      const { formula, position } = remove(customFormula, elementToDel);
      debounceUpdateFormula(formula);
      if (position != undefined) {
        changeCursor(position);
      }
      return;
    }
    if ((!SAFE_KEYS.includes(key) && !number) || editMode) {
      return;
    }
    if (key === 'ArrowLeft') {
      moveCursor(-1);
    } else if (key === 'ArrowRight') {
      moveCursor(1);
    }
    if (number || (
      key === DECIMAL_SEPARATOR &&
      customFormula[ cursor - 1 ]?.type === FormulaItemType.CONSTANT &&
      customFormula[ cursor - 1 ]?.value.split('').every((item) => item !== DECIMAL_SEPARATOR)
    )) {
      const { formula, position } = addConstant(key, customFormula, cursor);
      debounceUpdateFormula(formula);
      changeCursor(position);
      return;
    }
    const operator = operators.find((item) => item.value === key);
    if (!operator) {
      return;
    }
    const { formula, position } = addOperator(operator, customFormula, cursor);
    debounceUpdateFormula(formula);
    changeCursor(position);
  }, [ customFormula, addOperator, addConstant, moveCursor, changeCursor, cursor ]);

  const [ { isOver: isOverTag, isOverCurrent: isOverCurrentTag }, dropElement ] = useDrop({
    accept: [ 'tag', 'node' ],
    drop: (item: TemplateNode) => {
      if (isOverCurrentTag) {
        const type = isTemplateNodeFromCanvas(item) ?
          FormulaItemType.TEMPLATE : getTemplateTagFormulaType(item);
        const {
          formula,
          position
        } = addElement(getTemplateTagFromNode(item), type, customFormula);
        debounceUpdateFormula(formula);
        changeCursor(position);
      }
    },
    canDrop:(item: TemplateNode) => {
      return isTemplateNodeFromCanvas(item) ?
        canDropOnFormulaFromCanvas(item, customFormulaId, template) :
        canDropOnFormulaFromBottomPanel(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const [ { isOver: isOverOperator, isOverCurrent: isOverCurrentOperator }, dropOperator ] =
    useDrop({
      accept: [
        FormulaItemType.OPERATOR,
        FormulaItemType.BRACKET,
        FormulaItemType.LOGICAL_OPERATOR
      ],
      drop: (item: OperatorsType) => {
        if (isOverCurrentOperator) {
          const { formula, position } = addOperator(item, customFormula);
          debounceUpdateFormula(formula);
          changeCursor(position);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
      }),
    });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const handleDragOver = useCallback(( { active, over } ) => {
    if (!(over && active )) return;
    if (active.id !== over.id) {
      const oldIndex = customFormula.findIndex((item) => item.key === active.id);
      const newIndex = customFormula.findIndex((item) => item.key === over.id);
      const changePositionFormula = arrayMove(customFormula, oldIndex, newIndex);

      debounceUpdateFormula(changePositionFormula);
    }
  }, [ customFormula ]);

  const debounceUpdateFormula = useCallback(debounce((formula: FormulaItem[]) => {
    dispatch(updateCustomFormula(formula));
    setActiveId(null);
  }, 20), []);

  const handleDragEnd = useCallback(() => {
    const updateFormula = customFormula.map((el, index) => {
      return {
        ...el,
        prev: index === 0 ? null : customFormula[ index - 1 ].type,
        next: customFormula.length === index + 1 ? null : customFormula[ index + 1 ].type,
      };
    });

    debounceUpdateFormula(updateFormula);
  }, [ customFormula ]);

  const sortableItems = useMemo(() => {
    return cloneDeep(customFormula).map(el => el.key );
  }, [ customFormula ]);

  const validateFormula = useCallback(debounce((formula: FormulaItem[]) => {
    setFormulaValidation(validate(formula));
  }, 200), []);

  const getTagPositionInFormula = useCallback((tag: FormulaItem) => {
    const index = customFormula.findIndex((el) => el.key === tag.key);
    if (index < -1) {
      return customFormula.length - 1;
    }
    return index;
  }, [ customFormula ]);

  const renderFormulaTags = useCallback((tags: FormulaItem[]) => {
    return tags.map((el, index) =>
      <FormulaTag
        key={ index }
        element={ el }
        setCursor={ setCursor }
        updateFormula={ debounceUpdateFormula }
        isSelected={ selectedTag === el.id }
        setSelected={ () => setSelectedTag(el.id) }
      />
    );
  }, [ getTagPositionInFormula, debounceUpdateFormula, selectedTag ]);

  const renderFormula = useCallback(() => {
    if (cursor === null) {
      return renderFormulaTags(customFormula);
    }
    const customFormulaBeforeCursor = customFormula.slice(0, cursor);
    const customFormulaAfterCursor = customFormula.slice(cursor);
    return <>
      { renderFormulaTags(customFormulaBeforeCursor) }
      <FormulaCursor disabled={ cursor >= customFormula.length } />
      { renderFormulaTags(customFormulaAfterCursor) }
      <FormulaCursor disabled={ cursor < customFormula.length } />
    </>;
  }, [ customFormula, cursor ]);

  const onContainerSizeChange = useCallback((size: number) => {
    setHeightPanel(`calc(100dvh - 60px + ${ size }px - ${ FORMULA_PANEL_MIN_HEIGHT }px)`);
  }, []);

  return (
    <OutsideClickHandler
      disabled={ contexMenuFormulaElement }
      onOutsideClick={ () => setCursor(null) }
    >
      <div className={ styles.formulaHeader }>
        <span className={ styles.formulaTitle }>{ t('templates.formula') }</span>
        {
          !formulaValidation &&
            <span className={ styles.formulaWarning }>{ t('templates.invalid-formula') }</span>
        }
      </div>
      <DndContext
        modifiers={ [ restrictToParentElement ] }
        sensors={ sensors }
        onDragOver={ handleDragOver }
        onDragStart={ (e) => setActiveId(e.active.id) }
        onDragEnd={ handleDragEnd }
        onDragCancel={ () => setActiveId(null) }
        collisionDetection={ pointerWithin }
      >
        <SortableContext
          strategy={ rectSortingStrategy }
          items={ sortableItems }
        >
          <div
            className={ ` ${ styles.container } ${ !formulaValidation ? styles.warning : '' } ` }
          >
            <ResizableContainer
              defaultSize={ FORMULA_PANEL_MIN_HEIGHT }
              maxSize={ 1000 }
              minSize={ FORMULA_PANEL_MIN_HEIGHT }
              dimensionToResize='height'
              onSizeChange={ onContainerSizeChange }
            >
              <div
                ref={ (el) => {
                  dropElement(el);
                  dropOperator(el);
                } }
                className={ `${ styles.formulaContainer } ` }
              >
                <div className={ styles.formula }>
                  <FxIcon className={ styles.fxIcon }/>
                  <EqualIcon className={ styles.equalIcon }/>
                  {
                    renderFormula()
                  }
                  {
                    (
                      ( isOverTag && isOverCurrentTag ) ||
                      ( isOverOperator && isOverCurrentOperator )
                    ) && <div className={ styles.goalDrop }></div>
                  }
                </div>
              </div>
            </ResizableContainer>
            <div className={ styles.bottomContainer }>
              <div className={ styles.operatorsContainer }>
                {
                  operators.map(el => <Operator
                    key={ el.id }
                    element={ el }
                    position={ cursor }
                    moveCursor={ () => changeCursor(cursor + 1) }
                  />)
                }
              </div>
              <div className={ styles.formatContainer }>
                <DropdownFormat/>
              </div>
            </div>
          </div>
        </SortableContext>
        <DragOverlay dropAnimation={ { duration: 1, easing: 'linear', } }>
          { activeId ? <div style={ { opacity: 0 } } >overlay</div> : null }
        </DragOverlay>
      </DndContext>
    </OutsideClickHandler>
  );
};

export default FormulaBuilder;
