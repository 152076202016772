import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Cadence } from 'types/form.types';
import { revenueRecognitionSlice } from '../../../../store/revenueRecognition.slice';
import { useAppDispatch } from '../../../../store/hooks/hooks';

const useLocationDate = () => {
  const [ searchParams ] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const date = searchParams.get('date');
    if (!date) return;

    dispatch(revenueRecognitionSlice.actions.setPeriod({
      cadence: Cadence.month,
      startDate: dayjs(date).add(-3, 'month').unix(),
      endDate: dayjs(date).add(12, 'month').unix(),
      isManuallySet: false,
    }));
  }, []);

  return {};
};

export default useLocationDate;
