import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IntegrationEnum } from 'types/settings.types';
import styles from './Integrations.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';
import { topBarSlice } from 'store/topBar.slice';
import ProcountorIntegration
  from 'components/settings/integrations/procountor/ProcountorIntegration';
import NetvisorIntegration from 'components/settings/integrations/netvisor/NetvisorIntegration';
import { AccountRolesSection } from './accountRoles/AccountRolesSection';
import XeroIntegration from './xero/XeroIntegration';

const Integrations = () => {
  const [ t ] = useTranslation('settings');
  const { integrations, initialIntegrationsFetched } = useAppSelector(state => state.app);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(topBarSlice.actions.setTitle());
    return () => {
      dispatch(topBarSlice.actions.clear());
    };
  }, []);

  const isIntegrationDisabled = (type: IntegrationEnum) => {
    return !initialIntegrationsFetched || integrations?.some(item => item.type !== type);
  };

  const getIntegration = (type: IntegrationEnum) => {
    return integrations?.find(item => item.type === type);
  };

  return (
    <>
      <div className={ styles.integrations }>
        <h2 className={ styles.title }>
          { t('accounting') }
        </h2>
        <ul className={ styles.list }>
          <ProcountorIntegration
            integration={ getIntegration(IntegrationEnum.procountor) }
            disabled={ isIntegrationDisabled(IntegrationEnum.procountor) }
          />
          <NetvisorIntegration
            integration={ getIntegration(IntegrationEnum.netvisor) }
            disabled={ isIntegrationDisabled(IntegrationEnum.netvisor) }
          />
          <XeroIntegration
            integration={ getIntegration(IntegrationEnum.xero) }
            disabled={ isIntegrationDisabled(IntegrationEnum.xero) }
          />
        </ul>
      </div>
      <AccountRolesSection integration={ integrations[ 0 ] }/>
    </>
  );
};

export default Integrations;
