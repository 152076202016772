import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';

import styles from './EditText.module.scss';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { addError, errorSelector, removeError } from 'store/templates.slice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';

type Props = {
  editMode?: boolean;
  setEditMode?: (a: boolean) => void;
  defaultTitle: string;
  name: string;
  className?: string;
  errorClassName?: string;
  onSave: (val: string) => void;
  unSetError?: boolean;
  saveOnEnter?: boolean;
  disable?: boolean;
};

const EditText = ({
  editMode = true,
  setEditMode,
  defaultTitle,
  name,
  onSave,
  className = '',
  errorClassName = '',
  unSetError,
  saveOnEnter = false,
  disable = false
}: Props) => {
  const inputRef = useRef(null);
  const dispatch = useAppDispatch();
  const error = useAppSelector(errorSelector(name));

  const [ value, setValue ] = useState<string>(defaultTitle);
  const [ t ] = useTranslation('financials');

  useEffect(() => {
    if (editMode)
      inputRef.current?.focus({ cursor: 'end' });
  }, [ editMode ]);

  useEffect(() => {
    if (value !== defaultTitle) {
      setValue(defaultTitle);
      handleSave(defaultTitle);
    }
  }, [ defaultTitle ]);

  const handleSave = (val) => {
    if (val.trim() === '') {
      dispatch(addError(name));
    } else {
      dispatch(removeError(name));
      onSave(val);
    }
  };

  const debounceSetTitle = useCallback(debounce(handleSave, 300), []);
  return (
    <>
      <Input
        ref={ inputRef }
        name={ name }
        value={ value }
        onChange={ (e) => {
          e.stopPropagation();
          setValue(e.target.value);
          !saveOnEnter && debounceSetTitle(e.target.value);
        } }
        className={ `${ styles.input } ${ className }` }
        disabled={ !editMode || disable }
        bordered={ false }
        onPressEnter={ () => {
          setEditMode && setEditMode(false);
          saveOnEnter && debounceSetTitle(value);
        } }
        maxLength={ 500 }
      />
      { error && !unSetError && (
        <span className={ `${ styles.errorMessage } ${ errorClassName }` }>
          { '* ' + t('templates.title-required') }
        </span>
      ) }
    </>
  );
};

export default EditText;
