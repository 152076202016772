import React, { PropsWithChildren } from 'react';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';

import styles from './DimensionListSettings.module.scss';
import clsx from 'clsx';

interface Props {
  className?: string;
}

const DimensionListSettings = ({ children, className = '' } : PropsWithChildren<Props>) => {
  const [ t ] = useTranslation('financials');
  
  return <Collapse
    accordion
    ghost={ true }
    className={ clsx(styles.settings, className) }
    defaultActiveKey=''
    expandIcon={ (props) => <Chevron
      className={ clsx(styles.expandIcon, { [ styles.isExpand ]: props.isActive }) }
    /> }
  >

    <Collapse.Panel
      header={ t('left-panel.settings') } 
      key='settings'
      className={ styles.collapsePanel }
    >
      { children }
    </Collapse.Panel>

  </Collapse>;
};

const SettingsLabel = ({ children, className = '' } : PropsWithChildren<Props>) => {
  return <label className={ clsx(styles.settingsLabel, className) }>
    { children }
  </label>;
};

DimensionListSettings.Label = SettingsLabel;

export default DimensionListSettings;
