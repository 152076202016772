import { Input, Radio } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FieldErrors, useFormContext, useWatch, } from 'react-hook-form';
import { Cadence, StatementsForm, StatementsRequestForm } from 'types/form.types';
import styles from './DatePickersSection.module.scss';
import { ReactComponent as Minus } from 'assets/icons/minus.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import DatePicker from 'components/elements/datePicker/DatePicker';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { OptionRadioList, Options } from 'types/datePicker.types';
import useEscapeListener from 'hooks/useEscapeListener';
import {
  DATE_FORMATS,
  getDatePickerInputName,
  getPickerEndDate,
  getPickerStartDate
} from 'utils/date.utils';
import { isRangeDefault } from 'utils/period.utils';

type NameType =
  'startDateActuals' |
  'startDatePlan' |
  'endDateActuals' |
  'endDatePlan';

interface Props {
  cadence: Cadence;
  listOptions: Options[];
  nameStart: NameType;
  nameEnd: NameType;
  countEndOf: boolean;
  getDefaultValues?: () => StatementsRequestForm;
  actualsOpen?: boolean;
  templateId?: number;
  sectionType: 'plan' | 'actuals';
}
const FORMAT = DATE_FORMATS[ 0 ];

const Section = ({
  cadence,
  listOptions,
  nameStart,
  nameEnd,
  countEndOf,
  actualsOpen,
  templateId,
  sectionType,
  getDefaultValues,
}: Props) => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext<StatementsRequestForm>();
  const formErrors = errors as FieldErrors;
  const values = useWatch({ control });
  const [ counter, setCounter ] = useState(0);
  const [ active, setActive ] = useState('');

  const [ t ] = useTranslation('financials');
  const datePickerRef = useRef();

  const fromDatePicker = values[ nameStart ] as Dayjs;
  const toDatePicker = values[ nameEnd ] as Dayjs;

  const [ valueRadioGroup, setValueRadioGroup ] = useState(undefined);

  const changeDate = (name: NameType, value: dayjs.Dayjs) => {
    setValue(name, value);
    setValue(getDatePickerInputName(name), value.format(FORMAT));
  };

  useEffect(() => {
    trigger(getDatePickerInputName(nameEnd));
  },[ values[ getDatePickerInputName(nameStart) ] ]);

  useEffect(() => {
    trigger(getDatePickerInputName(nameStart));
  },[ values[ getDatePickerInputName(nameEnd) ] ]);

  useEffect(() => {
    changeDate(nameStart, getPickerStartDate(fromDatePicker, cadence));
    changeDate(nameEnd, getPickerEndDate(toDatePicker, cadence));
  }, [ cadence ]);

  useEffect(() => {
    if (valueRadioGroup) {
      changeDate(nameStart, valueRadioGroup.from);
      changeDate(nameEnd, valueRadioGroup.to);
    }
  },[ valueRadioGroup ] );

  useEscapeListener(datePickerRef, () => setCounter(0));

  useEffect(() => {
    if (isRangeDefault(templateId) && templateId) {
      const trailing12id =
        listOptions
          ?.find((section) => section?.key === 'common')
          ?.radioList
          ?.find((option) => option?.key === '12-trailing-months')?.id;

      setActive(`${ trailing12id }`);
    } else {
      setActive('');
    }
    setValue(
      `${ nameStart }Input`, `${ getDefaultValues()[ `${ nameStart }` ].format(DATE_FORMATS[ 0 ]) }`
    );
    setValue(
      `${ nameEnd }Input`, `${ getDefaultValues()[ `${ nameEnd }` ].format(DATE_FORMATS[ 0 ]) }`
    );

  }, []);

  const renderList = (list) => (
    <Radio.Group
      className={ styles.radioGroup }
      optionType='button'
      value={ valueRadioGroup }
      onChange={ (e) => {
        setValueRadioGroup(e.target.value);
        if (e.target.id === '1' && sectionType === 'plan') {
          setValueRadioGroup(
            { from: values.startDateActuals, to: values.endDateActuals }
          );
        }
        setActive(e.target.id);
      } }
      buttonStyle='solid'>
      {
        list.map(item => (
          <React.Fragment key={ item.title }>
            <div className={ item.key === 'common-plans' && !actualsOpen ? styles.inactive : '' }>
              <span className={ styles.title } >{ item.title }</span>
              {
                item.radioList.map((radio: OptionRadioList) => {
                  return (
                    <Radio
                      disabled={ item.key === 'common-plans' && !actualsOpen }
                      className={
                        active === `${ radio.id }` ?
                          styles.radioElementActive : styles.radioElement
                      }
                      key={ radio.id }
                      id={ `${ radio.id }` }
                      value={ radio.value }
                    >
                      <span>{ radio.name }</span>
                      {
                        active === `${ radio.id }`
                          && <CheckIcon className={ styles.checkIcon } height={ 18 } width={ 18 }/>
                      }
                    </Radio>
                  );
                })
              }

            </div>
          </React.Fragment>
        ))
      }
    </Radio.Group>

  );

  const swapDate = (date1: dayjs.Dayjs, date2: dayjs.Dayjs) => {
    changeDate(nameStart, date2);
    changeDate(nameEnd, date1);
  };

  const validateDate = (value: string) => {
    return dayjs(value, FORMAT).isValid() && value !== '';
  };

  const validStartDate = (value: string) => {

    return dayjs(value,FORMAT).isBefore(dayjs(values[ `${ nameEnd }Input` ], FORMAT));
  };

  const validEndDate = (value: string) =>
    dayjs(value,FORMAT).isAfter(dayjs(values[ `${ nameStart }Input` ], FORMAT));

  const onInputChange = useCallback((e, name, onChange) => {
    const validFormat =
      DATE_FORMATS.find((f) => dayjs(e.target.value, f, true).isValid());
    onChange(e.target.value);
    setActive('');
    if (validFormat) {
      setValue(name, dayjs(e.target.value, FORMAT));
    }
  }, []);

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.actualsContainer }>
        {
          renderList(listOptions)
        }
      </div>
      <div className={ styles.calendarSection }>
        <div className={ styles.inputsContainer }>
          <Controller
            control={ control }
            rules={ {
              validate: {
                validDate: (v) => validateDate(v as string)
                  || `${ t('datePicker.errors.valid-date') }`,
                validStartDate: v => validStartDate(v as string)
                  || `${ t('datePicker.errors.start-date-is-later') }`
              }
            } }
            name={ `${ nameStart }Input` as StatementsForm }
            render={ ({ field: { onChange, value, name } }) => {
              return (
                <div className={ styles.inputWrapper }>
                  <Input
                    type='text'
                    className={ styles.input }
                    size='small'
                    value={ value as string }

                    onChange={ (e) => onInputChange(e, nameStart, onChange) }
                    prefix={ <CalendarIcon width={ 18 } height={ 18 }/> }
                  />
                  <span className={ styles.error }>
                    { formErrors[ name ]?.message }
                  </span>
                </div>
              );
            } }
          />
          <span><Minus/></span>
          <Controller
            control={ control }
            rules={ {
              validate:{
                validDate:
                  v => validateDate(v as string) || `${ t('datePicker.errors.valid-date') }`,
                validEndDate:
                  v => validEndDate(v as string) || t('datePicker.errors.end-date-is-before')
              }
            } }
            name={ `${ nameEnd }Input` as StatementsForm }
            render={ ({ field: { onChange, value, name } }) => {
              return (
                <div className={ styles.inputWrapper }>
                  <Input
                    type='text'
                    className={ styles.input }
                    size='small'
                    value={ value as string }
                    onChange={ (e) => onInputChange(e, nameEnd, onChange) }
                    prefix={ <CalendarIcon width={ 18 } height={ 18 }/> }
                  />
                  <span className={ styles.error }>
                    { formErrors[ name ]?.message }
                  </span>
                </div>

              );

            } }
          />
        </div>
        <div className={ styles.datePickers } ref={ datePickerRef }>
          <DatePicker
            countEndOf={ countEndOf }
            setValue={ setValue }
            onChangeValue={ (value) => {
              if (counter === 0)
                setCounter((prev) => prev + 1);
              else if (counter === 2)
                setCounter(0);
              else if (counter === 3) {
                const isBefore = dayjs(toDatePicker).isBefore(dayjs(value));
                isBefore && swapDate(value, toDatePicker as dayjs.Dayjs);
                setCounter(0);
              }
              setActive('');
            } }
            name={ nameStart }
            cadence={ cadence }
            control={ control }
            className={ styles.datePickerLeft }
            defaultValue={ fromDatePicker.format(FORMAT) }
          />
          <DatePicker
            countEndOf={ countEndOf }
            setValue={ setValue }
            onChangeValue={ (value) => {
              if (counter === 0)
                setCounter((prev) => prev + 3);
              else if (counter === 1 ) {
                const isAfter = dayjs(fromDatePicker).isAfter(dayjs(value));
                isAfter && swapDate(fromDatePicker as dayjs.Dayjs, value);
                setCounter(0);
              }
              setActive('');
            } }
            name={ nameEnd }
            cadence={ cadence }
            control={ control }
            className={ styles.datePickerRight }
            defaultValue={ toDatePicker.format(FORMAT) }
            isEndDate={ true }
          />
        </div>
      </div>
    </div>
  );
};

export default Section;
