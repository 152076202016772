import React, { useMemo } from 'react';
import { Action } from '../types/table.types';
import CtaButton from 'components/elements/ctaButton/CtaButton';

interface Props{
  actions: Action[];
}

const ActionButton = ({ actions }: Props) => {
  const options = useMemo (() => {
    return actions.map((action) => {
      return {
        key: action.label,
        ...action
      };
    });
  }, [ actions ]);

  return <CtaButton items={ options } />;
};

export default ActionButton;
