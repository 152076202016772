import React from 'react';
import { Message as IMessage, MessageSource } from 'types/chat.types';
import styles from './Message.module.scss';
import SystemMessage from
  'components/collaboration/chat/messages/history/message/systemMessage/SystemMessage';
import UserMessage from
  'components/collaboration/chat/messages/history/message/userMessage/UserMessage';

interface Props {
  message: IMessage;
}

const Message = ({ message }: Props) => {
  return <div className={ `${ styles.row } ${ styles[ message.source ] }` }>
    {
      message.source === MessageSource.SYSTEM ?
        <SystemMessage message={ message } /> :
        <UserMessage message={ message } />
    }
  </div>;
};

export default Message;