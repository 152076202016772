import { Select, Switch } from 'antd';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChartSettings } from 'types/chart.types';
import styles from './OtherSettings.module.scss';
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import { DataFormat } from '../../types/chart.types';

const OtherSettings = () => {
  const { control, watch } = useFormContext<ChartSettings>();
  const [ t ] = useTranslation('financials');
  // t(`charts.settings.axis-settings.left-axis`) 
  // t(`charts.settings.axis-settings.right-axis`) 
  const values = watch();

  const isAnyStackedBarActive = useMemo(() => {
    return values.datasets[ 0 ].active && values.datasets[ 0 ].style === 'stacked' ||
      values.datasets[ 1 ].axisPosition && values.datasets[ 1 ].style === 'stacked';
  }, [ values ]);

  const axisFormatOptions: { value: DataFormat; label: string }[] = useMemo(() => {
    return [ 
      { value: 'currency', label: t('charts.settings.formatting.currency') },
      { value: 'nominal', label: t('charts.settings.formatting.nominal') },
      { value: 'percentage', label: t('charts.settings.formatting.percentage') }
    ];
  }, []);

  return (
    <div>
      <div className={ styles.title }>
        { t('charts.settings.axis-settings.title') }
      </div>
      {
        ([ 'left', 'right' ]).map((axis) => {
          return (
            <div className={ styles.inputSelectContainer } key={ axis }>
              <div className={ styles.inputLabel }>
                { t(`charts.settings.axis-settings.${ axis }-axis`) }
              </div>
              <Controller
                control={ control }
                name={ `other.${ axis as 'left' | 'right' }AxisFormat` }
                render={ ({ field }) => {
                  return <Select
                    suffixIcon={ <Chevron className={ styles.chevron }/> }
                    className={ styles.select }
                    style={ { textTransform: 'capitalize' } }
                    value={ field.value }
                    onChange={ (e) => {
                      field.onChange(e);
                    } }
                    options={ axisFormatOptions }
                  />;
                } }
              />
            </div>

          );
        })
      }
      <div className={ styles.title }>
        { t('charts.settings.other.data-labels') }
      </div>
      <div className={ styles.inputContainer }>
        <div className={ styles.inputLabel }>
          { t('charts.settings.other.data-labels-bar') }
        </div>
        <Controller
          control={ control }
          name='other.dataLabels.bar'
          render={ ({ field }) => (
            <Switch
              size='small'
              checked={ field.value }
              onChange={ field.onChange }
            />
          ) }
        />
      </div>
      <div className={ styles.inputContainer }>
        <div className={ styles.inputLabel }>
          { t('charts.settings.other.data-labels-stacked') }
        </div>
        <Controller
          control={ control }
          name='other.dataLabels.stacked'
          render={ ({ field }) => (
            <Switch
              size='small'
              checked={ field.value }
              onChange={ field.onChange }
              disabled={ !isAnyStackedBarActive }
            />
          ) }
        />
      </div>
      <div className={ `${ styles.inputContainer } ${ styles.inputTitle }` }>
        <div className={ styles.inputLabel }>
          { t('charts.settings.other.legend') }
        </div>
        <Controller
          control={ control }
          name='other.legend'
          render={ ({ field }) => (
            <Switch
              size='small'
              checked={ field.value }
              onChange={ field.onChange }
            />
          ) }
        />
      </div>
      <div className={ styles.title }>
        { t('charts.settings.other.values') }
      </div>
      <div className={ `${ styles.inputContainer }` }>
        <div className={ styles.inputLabel }>
          { t('charts.settings.other.cumulative-values') }
        </div>
        <Controller
          control={ control }
          name='other.cumulativeValues'
          render={ ({ field }) => (
            <Switch
              size='small'
              checked={ field.value }
              onChange={ field.onChange }
            />
          ) }
        />
      </div>
    </div>
  );
};

export default OtherSettings;
