import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { CommandBarTextButton, CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import { useMemo } from 'react';
import { dashboardSlice } from 'store/dashboard.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DashboardModeType } from 'types/dashboard.types';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import { useTranslation } from 'react-i18next';

const modeButton = defaults.find((button) => button.key === 'mode');
const saveButton = defaults.find((button) => button.key === 'save') as CommandBarTextButton;

const useDashboardCommandBar = () => {
  const [ t ] = useTranslation('common');
  const mode = useAppSelector(state => state.dashboard.mode);
  const dispatch = useAppDispatch();

  const utils: CommandBarUtil[] = useMemo(() => [
    {
      ...modeButton,
      key: 'mode',
      type: 'button',
      hidden: false,
      disabled: false,
      icon: modeButton.icon,
      tooltip: t('command-bar.tooltip.mode'),
      active: mode === DashboardModeType.EDIT,
      onClick: () => {
        dispatch(dashboardSlice.actions.setMode(DashboardModeType.EDIT));
      }
    }
    , {
      ...saveButton,
      key: 'save',
      type: 'button-text',
      hidden: false,
      disabled: mode !== DashboardModeType.EDIT,
      icon: saveButton.icon,
      active: false,
      name: saveButton.name,
      onClick: () => {
        dispatch(dashboardSlice.actions.setMode(DashboardModeType.VIEW));
      }
    } ],
  [ mode, t ]);

  return useCommandBarEffect({ utils });
};

export default useDashboardCommandBar;