import React, { useMemo } from 'react';
import { CommandBarSelectUtil } from 'components/commandBar/types/commandBar.types';
import CtaButton from 'components/elements/ctaButton/CtaButton';

interface Props {
  select: CommandBarSelectUtil;
}

const CommandBarSelect = ({ select }: Props) => {
  const items = useMemo(() => {
    return select.options.map(option => ({
      key: option.label,
      ...option
    }));
  }, [ select ]);

  return <CtaButton
    items={ items }
  />;
};

export default CommandBarSelect;