import React, { useEffect, useState } from 'react';
import isStrongPassword from 'validator/lib/isStrongPassword';
import { useTranslation } from 'react-i18next';

import styles from './PasswordStrengthBar.module.scss';

type Props = {
  password: string;
  onChange: (isValid: boolean) => void;
};

type Rule = {
  name: string;
  passed: boolean;
};

const PasswordStrengthBar = ({ password, onChange }: Props) => {
  const [ rules, setRules ] = useState<Rule[]>();
  const [ passed, setPassed ] = useState<number>();
  const [ t ] = useTranslation('auth');

  useEffect(()=> {
    const score = isStrongPassword(password, {
      minLength: 8, minSymbols: 0, returnScore: true,
      pointsPerUnique: 0, pointsPerRepeat: 0,
      pointsForContainingLower: 0.25, pointsForContainingUpper: 0.25,
      pointsForContainingNumber: 1, pointsForContainingSymbol: 0
    }) as unknown as number;

    const array = [
      {
        name: t('password-validation.characters'),
        passed: password.length >= 8 ? true : false
      },
      {
        name: t('password-validation.case'),
        passed: score % 1 === 0.5
      },
      {
        name:  t('password-validation.number'),
        passed: score >= 1
      },
    ];

    const number = array?.filter(item => item.passed)?.length;

    onChange(number === 3);
    setRules(array);
    setPassed(number);
  }, [ password ]);

  const strength = passed === 0 ? 'weak' :  
    passed === 1 ? 'medium' : 'good';

  return (
    <div className={ `${ styles.passwordStrengthBar } ${
      styles[ strength ]
    }` }>

      <div className={ styles.text }>
        { rules && rules?.map((item, index) => (
          <React.Fragment key={ index }>
            <span className={ item.passed ? styles.crossed : '' }>
              { item.name }
            </span>
            { rules[ index + 1 ] && <span>, </span> } 
          </React.Fragment>
        )) }
      </div>
      <div className={ styles.bars }>
        <div className={ styles.bar }></div>
        <div className={ styles.bar }></div>
        <div className={ styles.bar }></div>
        <div className={ styles.bar }></div>
      </div>
      <span className={ styles.strength }>
        { t(`password-validation.${ strength }`) }
      </span>
    </div>
  );
};

export default PasswordStrengthBar;
