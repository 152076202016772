import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk, RootState } from 'store/store';
import cloneDeep from 'lodash/cloneDeep';
import {
  Template,
  TemplateNode,
  TemplateNodeMapping,
  TemplateRequest,
} from 'types/templates.types';
import {
  createUnassignedDimensionItem,
  isNewTemplate,
  setLastTemplateId,
  templateHelpers,
} from 'utils/template.utils';
import { getUUID, newTemplate } from 'utils/templates.utils';
import {
  DroplinePosition,
  TemplatePlacementService
} from 'store/TemplateServices/TemplatePlacementService';
import { TemplateCreationService } from 'store/TemplateServices/TemplateCreationService';
import { TemplateRulesService } from 'store/TemplateServices/TemplateRulesService';
import { TemplateReplaceService } from 'store/TemplateServices/TemplateReplaceService';
import { TemplateRemoveService } from 'store/TemplateServices/TemplateRemoveService';
import { arrayToMap } from 'utils/mapping.utils';
import { RowType } from 'types/financials.types';
import { Dimension } from '../types/filterTable.types';

type FinancialTagsExpanded = {
  roots: number[];
  nodes: TemplateNodeMapping<RowType.FINANCIALS>;
  expandedNodeId: number;
};

type TemplatesStore = {
  template: Template;
  dimensionTags: FinancialTagsExpanded;
};

const defaultTemplate = newTemplate;

const initialState: TemplatesStore = {
  template: cloneDeep(defaultTemplate),
  dimensionTags: {
    roots: [],
    nodes: {},
    expandedNodeId: 0
  },
};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTemplate: (state, action: PayloadAction<Template>) => {
      state.template = action.payload;
    },
    setTemplateTitle: (state, action: PayloadAction<string>) => {
      state.template.title = action.payload;
    },
    setTemplateSubtitle: (state, action: PayloadAction<string>) => {
      state.template.subtitle = action.payload;
    },
    reset: (state) => {
      state.template = initialState.template;
    },
  },
});

export const setTemplateNodes = (data: Template) => (dispatch: AppDispatch) => {
  dispatch(actions.setTemplate(data));
};

/**
 * Unassigned node is stored as a separate type in the backend, but for our use case it behaves
 * as a dimension item. This function converts unassigned node to dimension item node.
 */
const unassignedDimensionToDimensionItemNode = (node: TemplateNode) => {
  if (node.type === RowType.UNASSIGNED) {
    return {
      ...node,
      type: RowType.DIMENSION_ITEM as const,
      rowData: createUnassignedDimensionItem(node.rowData as Dimension)
    };
  }
  return node;
};

export const setMappedTemplate = (template: TemplateRequest) => (dispatch: AppDispatch) => {
  const nodesWithUnassignedMapped = template.nodes.map(unassignedDimensionToDimensionItemNode);
  const mapping = arrayToMap(nodesWithUnassignedMapped, n => n.id);
  for (const item of Object.values(mapping)) {
    item.children.forEach((childId) => {
      mapping[ childId ].parent = item.id;
    });
  }

  dispatch(
    actions.setTemplate({
      ...template,
      nodes: mapping,
    }),
  );
  setLastTemplateId(template.id);
};

export const clearTemplate = () => (dispatch: AppDispatch) => {
  dispatch(actions.setTemplate(defaultTemplate));
};

export const createNewTemplate = (name: string) => (dispatch: AppDispatch) => {
  dispatch(actions.setTemplate({ ...defaultTemplate, title: name }));
};

export const duplicateTemplate = (template: TemplateRequest) => (dispatch: AppDispatch) => {
  const name = `Copy of ${ template.title }`;
  const nodes = template.nodes.map(node => ({ ...node, uuid: getUUID() }));
  dispatch(actions.setTemplate({
    title: name,
    subtitle: template.subtitle,
    roots: template.roots,
    nodes: arrayToMap(nodes, n => n.id),
  }));
};

export const createNode = (
  item: TemplateNode,
  overNode: TemplateNode,
  dropline: DroplinePosition
) => (dispatch: AppDispatch, getState: () => RootState) => {
  const {
    templates: {
      financialTags: { nodes: financialNodes },
      dimensions: { nodes: dimensionNodes },
    },
    template: {
      present: { template: currentTemplate },
    },
  } = getState();
  const { getPlacement } = TemplatePlacementService(
    item,
    overNode,
    currentTemplate,
    dropline,
    {
      financialNodes,
      dimensionNodes,
    }
  );
  const placement = getPlacement();
  const { addNode } = TemplateCreationService(
    item,
    currentTemplate,
    placement,
  );
  const { updatedTemplate, node } = addNode();
  const { validate } = TemplateRulesService(node.id, updatedTemplate, financialNodes);
  const validatedTemplate = validate();
  dispatch(setTemplate(validatedTemplate));
};

export const batchCreateNode =
  (item: TemplateNode, overNode: TemplateNode, dropline: DroplinePosition) =>
    (dispatch: AppDispatch) => {
      item.childrenNodes.forEach((child) => {
        dispatch(createNode(child, overNode, dropline));
      });
    };

export const moveNode =
  (node: TemplateNode, overNode: TemplateNode, dropline: DroplinePosition) =>
    (dispatch: AppDispatch, getState: () => RootState) => {
      const {
        templates: {
          financialTags: { nodes: financialNodes },
          dimensions: { nodes: dimensionNodes },
        },
        template: {
          present: { template: currentTemplate },
        },
      } = getState();
      const helpers = templateHelpers(
        currentTemplate,
        financialNodes,
        dimensionNodes
      );
      if (helpers.isDroppingOnSameBranch(node, overNode) ||
        (node.parent === overNode?.id && dropline === 'none')
      ) {
        return;
      }
      const { getPlacement } = TemplatePlacementService(
        node,
        overNode,
        currentTemplate,
        dropline,
        {
          financialNodes,
          dimensionNodes,
          node
        }
      );

      const placement = getPlacement();

      const { replaceNode } = TemplateReplaceService(
        node,
        currentTemplate,
        placement
      );
      const { updatedTemplate, node: updatedNode } = replaceNode();
      const { validate } =
        TemplateRulesService(updatedNode.id, updatedTemplate, financialNodes);
      const validatedTemplate = validate();
      dispatch(setTemplate(validatedTemplate));
    };

export const editTitleTemplateNode =
  (nodeId: number | string, title: string): AppThunk =>
    (dispatch: AppDispatch, getState: () => RootState) => {
      const {
        template: {
          present: { template },
        },
      } = getState();
      const templateTmp = cloneDeep(template);

      if (templateTmp.nodes[ nodeId ]) {
        templateTmp.nodes[ nodeId ].rowData.name = title;
      }

      dispatch(setTemplate(templateTmp));
    };

export const removeNode = (nodeId: number | string) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      templates: {
        financialTags: { nodes: financialNodes },
      },
      template: {
        present: { template: currentTemplate },
      },
    } = getState();
    const { remove } = TemplateRemoveService(currentTemplate, financialNodes);
    const updatedTemplate = remove(nodeId);
    const { validate } = TemplateRulesService(null, updatedTemplate, financialNodes);
    const validatedTemplate = validate();
    dispatch(setTemplate(validatedTemplate));
  };

export const removeBatchNodes = (nodeIds: number[]) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      template: {
        present: { template: currentTemplate },
      },
      templates: {
        financialTags: { nodes: financialNodes },
      },
    } = getState();
    let templateUpdated = cloneDeep(currentTemplate);
    nodeIds.forEach(nodeId => {
      const { removeRaw } = TemplateRemoveService(templateUpdated, financialNodes);
      const parentId = templateUpdated.nodes[ nodeId ]?.parent;
      templateUpdated = removeRaw(templateUpdated, nodeId, parentId);
    });
    dispatch(setTemplate(templateUpdated));
  };

export const canSaveTemplate = createSelector(
  (state: RootState) => state,
  (state) =>
    state.templates.errors.length === 0 &&
    (state.template.past.length > 0 || isNewTemplate(state.template.present.template)),
);

export const nodeSelector = (nodeId: number | string): (state: RootState) => TemplateNode =>
  createSelector([
    (state: RootState) => state.template.present.template.nodes
  ], (n) => n[ nodeId ]);

export const templateReducer = templateSlice.reducer;
const actions = templateSlice.actions;
export const { setTemplate, setTemplateTitle, setTemplateSubtitle, reset } = templateSlice.actions;

export const selectTemplate = (state: RootState) => state.template.present.template;
export const selectTemplateUndo = (state: RootState) => state.template;
