import { Switch } from 'antd';
import { isChartStacked } from 'components/charts/chart/utils/chart.utils';
import FlatSelect from 'components/elements/flatSelect/FlatSelect';
import NodeSelect from 'components/elements/nodeSelect/NodeSelect';
import useTemplateNode, { NodeOption } from 'hooks/useTemplateNode';
import React, { useCallback, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChartSettings, ChartSource, ChartStyle, } from 'types/chart.types';
import { ALL_STACKS } from 'types/dashboard.types';
import { getChartBreakdown, getDimensionViewName } from 'utils/financials.utils';
import styles from './DatasetSettings.module.scss';

interface Props {
  index: number;
  title: string;
  templateId: number;
  onTemplateNodeChange?: (node: NodeOption) => void;
}

const DatasetSettings = (
  {
    index,
    title,
    templateId,
    onTemplateNodeChange,
  }: Props) => {
  const { control, setValue } = useFormContext<ChartSettings>();
  const settings = useWatch({ control }) as ChartSettings;
  const [ t ] = useTranslation('financials');
  const { getNodeOptions, findOption, report } = useTemplateNode({ templateId, type: 'chart' });
  const options = useMemo(() => getNodeOptions(), [ getNodeOptions ]);

  const dataset = settings.datasets[ index ];

  const isDisabled = useMemo(() => {
    return !dataset?.active;
  }, [ settings ]);

  const breakdown = useMemo(() => {
    if (report) {
      return getChartBreakdown(report, dataset?.templateNode);
    }
    return null;

  }, [ settings, report ]);

  const getGraphStyleOption = useCallback((value: ChartStyle) => {
    // { t(`charts.settings.style.bar`) }
    // { t(`charts.settings.style.stacked`) }
    // { t('charts.settings.style.grouped') }
    // { t('charts.settings.style.percentage') }
    // { t(`charts.settings.style.line`) }
    // { t('charts.settings.style.line_with_background') }
    return {
      label: <div>
        <span className={ styles.graphStyle }>
          { t(`charts.settings.style.${ value }`) }
        </span>
        {
          value !== 'bar' && !value.startsWith('line') ? <span className={ styles.breakdown }>
            { t('charts.settings.style.by') }
            { ' ' }
            { getDimensionViewName(breakdown.rowData) }
          </span> : null
        }
      </div>,
      value
    };
  }, [ breakdown ]);

  const graphStyleOptions = useMemo(() => {
    const _options = [ getGraphStyleOption(ChartStyle.BAR) ];
    if (breakdown) {
      _options.push(
        getGraphStyleOption(ChartStyle.STACKED),
        getGraphStyleOption(ChartStyle.GROUPED),
        getGraphStyleOption(ChartStyle.PERCENTAGE)
      );
    }
    _options.push(
      getGraphStyleOption(ChartStyle.LINE),
      getGraphStyleOption(ChartStyle.LINE_WITH_BACKGROUND)
    );
    return _options;
  }, [ breakdown, getGraphStyleOption ]);

  const activeDatasetsAmount = settings.datasets.filter((set) => set.active).length;

  const axisPositionsOptions = useMemo(() => {
    return [
      { value: 'left', label: t('charts.settings.axis.left') },
      { value: 'right', label: t('charts.settings.axis.right') },
    ];
  }, []);

  const chartSourceOptions = useMemo(() => {
    return Object.values(ChartSource).map((source) => ({
      value: source,
      label: t(`charts.settings.source.${ source }`)
    }));
  }, []);

  const stacksOptions = useMemo(() => {
    const _options = [ 3, 5, 7, 10 ];
    return _options.map((stack) => ({
      value: stack,
      label: stack.toString(),
    })).concat([ {
      value: ALL_STACKS,
      label: t('charts.settings.stacks.all')
    } ]);
  }, []);

  return (
    <div className={ styles.settings }>
      <div className={ styles.title }>
        <h4>{ title }</h4>
        <Controller
          control={ control }
          name={ `datasets.${ index }.active` }
          render={ ({ field }) => {
            return <Switch
              checked={ field.value }
              disabled={ activeDatasetsAmount === 1 && field.value }
              onChange={ field.onChange }
              size='small'
            />;
          } }
        />
      </div>
      { isDisabled && <div className={ styles.disabledOverlay }/> }
      <Controller
        control={ control }
        name={ `datasets.${ index }.templateNode` }
        render={ ({ field }) => {
          return <div className={ styles.inputContainer }>
            <div className={ styles.inputLabel }>
              { t('charts.settings.row') }
            </div>
            <NodeSelect
              className={ styles.select }
              value={ field.value }
              nodeOptions={ options }
              onChange={ (e) => {
                field.onChange(e);
                onTemplateNodeChange && onTemplateNodeChange(
                  findOption(options, settings.datasets[ index ].templateNode));
                // Restore default chart style
                setValue(`datasets.${ index }.style`, ChartStyle.BAR);
              } }
            />
          </div>;
        } }
      />
      <Controller
        control={ control }
        name={ `datasets.${ index }.datePickerSource` }
        render={ ({ field }) => {
          return <div className={ styles.inputContainer }>
            <div className={ styles.inputLabel }>
              { t('charts.settings.source.label') }
            </div>
            <FlatSelect
              className={ styles.select }
              onChange={ field.onChange }
              value={ field.value }
              options={ chartSourceOptions }
            />
          </div>;
        } }
      />
      <div className={ styles.inputContainer }>
        <div className={ styles.inputLabel }>
          { t('charts.settings.style.title') }
        </div>
        <Controller
          control={ control }
          name={ `datasets.${ index }.style` }
          render={ ({ field }) => {
            return <FlatSelect
              className={ styles.select }
              value={ field.value }
              onChange={ field.onChange }
              options={ graphStyleOptions }
            />;
          } }
        />
      </div>
      <div className={ styles.inputContainer }>
        <div className={ styles.inputLabel }>
          { t('charts.settings.axis.title') }
        </div>
        <Controller
          control={ control }
          name={ `datasets.${ index }.axisPosition` }
          render={ ({ field }) => {
            return <FlatSelect
              className={ styles.select }
              style={ { textTransform: 'capitalize' } }
              defaultValue={ field.value || 'left' }
              value={ field.value }
              onChange={ field.onChange }
              options={ axisPositionsOptions }
            />;
          } }
        />
      </div>
      <div className={ styles.inputContainer }>
        <div className={ styles.inputLabel }>
          { t('charts.settings.stacks.title') }
        </div>
        <Controller
          control={ control }
          name={ `datasets.${ index }.stacks` }
          render={ ({ field }) => {
            return <FlatSelect
              disabled={ !isChartStacked(settings.datasets[ index ].style) }
              className={ styles.select }
              value={ field.value }
              onChange={ field.onChange }
              options={ stacksOptions }
            />;
          } }
        />
      </div>
      <div className={ `${ styles.inputContainer } ${ styles.inputTitle }` }>
        <div className={ styles.inputLabel }>
          { t('charts.settings.axis.reversed-values') }
        </div>
        <Controller
          control={ control }
          name={ `datasets.${ index }.reverseValues` }
          render={ ({ field }) => (
            <Switch
              size='small'
              checked={ field.value }
              onChange={ field.onChange }
            />
          ) }
        />
      </div>
    </div>
  );
};

export default DatasetSettings;
