import React, { useCallback, useMemo, useRef } from 'react';
import TableSelect from './tableSelect/TableSelect';
import styles from './LayoutControl.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import TemplateDropdown from '../../../elements/templateDropdown/TemplateDropdown';
import {
  addViewItem,
  clearDetailedViewSettings,
  financialsSlice,
  removeViewItem,
  selectCapsuleList
} from '../../../../store/financials.slice';
import { ReactComponent as VerticalMenu } from 'assets/icons/plus-rounded.svg';
import { ReactComponent as NewBrowser } from 'assets/icons/newBrowser.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { routes } from '../../../../utils/config.utils';
import { RowHoverAction } from '../../../elements/sectionDropdown/types/sectionDropdown.types';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';

interface Props {
  className?: string;
}

const LayoutControl = ({ className = '' }: Props) => {
  const [ t ] = useTranslation('financials');
  const containerRef = useRef(null);

  const viewItems = useAppSelector(selectCapsuleList);
  const viewTemplates = useAppSelector(state => state.financials.templates);
  const dispatch = useAppDispatch();

  const isElementDisabled = useCallback((id: number) => {
    return viewTemplates && viewTemplates.some(({ templateId }) => templateId === id);
  }, [ viewTemplates ]);

  const onElementSelect = useCallback((id: number) => {
    const selectedItem = viewItems.find(({ templateId }) => templateId === id);

    if (selectedItem) {
      if (!isElementDisabled(id)) {
        dispatch(removeViewItem(selectedItem));
        dispatch(financialsSlice.actions.removeTables([ id ]));
        dispatch(financialsSlice.actions.setActive(null));
      }
    } else {
      let newViewItem;

      if (id == null) {
        newViewItem = { templateId: null, type: 'inputs', isShown: true };
      } else {
        newViewItem = {
          templateId: id,
          type: 'table',
          isShown: true
        };
      }

      dispatch(addViewItem(newViewItem));
      dispatch(financialsSlice.actions.addTables([ id ]));
      dispatch(clearDetailedViewSettings());
    }
  }, [ viewItems, viewTemplates, isElementDisabled ]);

  const isElementSelected = useCallback((id: number) => {
    return !!viewItems.find(item => item.templateId === id);
  }, [ viewItems ]);

  const elementActions: RowHoverAction[] = useMemo(() => ([
    {
      getIcon: () => <NewBrowser />,
      onClick: (id: number) => {
        // ? Only show this template and nothing more
        window.open(`${ routes.financialView }?template=${ id }`, '_blank');
      }
    },
    {
      getIcon: () => <EditIcon />,
      onClick: (id: number) => {
        window.open(`${ routes.report.templateBuilder }/${ id }`, '_blank');
      }
    }
  ]), []);

  return (
    <div className={ styles.container }>
      <div className={ styles.header }>
        <span className={ styles.title }>
          { t('left-panel.layout-control.subtitle') }
        </span>
        <div className={ styles.dropdown }>
          <TemplateDropdown
            isSelected={ isElementSelected }
            placement='bottomRight'
            onSelect={ onElementSelect }
            rowHoverActions={ elementActions }
            multiSelect
            isDisabled={ isElementDisabled }
          >
            <GhostIconButton
              tooltip={ t('template-dropdown.button') }
              active
              className={ styles.button }
            >
              <VerticalMenu className={ styles.dropdownIcon } />
            </GhostIconButton>
          </TemplateDropdown>
        </div>
      </div>
      <DynamicScrollableContainer>
        <div className={ clsx(styles.items, className) } ref={ containerRef }>
          <TableSelect />
        </div>
      </DynamicScrollableContainer>
      
    </div>
  );
};

export default LayoutControl;
