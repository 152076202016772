import React, { useEffect } from 'react';
import { useFinancialTable } from 'context/FinancialTableContext';
import FinancialTable from 'components/financials/financialTable/FinancialTable';
import { useTranslation } from 'react-i18next';
import { setLeftPanel } from 'store/financials.slice';
import { useAppDispatch } from '../../../../store/hooks/hooks';

const ContractInfo = () => {
  const dispatch = useAppDispatch();
  const { dispatch: dispatchContext, state: { templateId } } = useFinancialTable();
  const [ t ] = useTranslation('contracts');

  useEffect(() => {
    dispatchContext({
      type: 'setTitle',
      payload: {
        title: t('table.title'),
        subtitle: '',
      } });
    dispatch(setLeftPanel({
      templateId: templateId,
      panelType: 'label'
    }));
  }, []);

  return (
    <FinancialTable />
  );
};

export default ContractInfo;
