import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Financials.module.scss';
import FinancialTable from './financialTable/FinancialTable';
import {
  clearCapsuleList,
  clearDetailedViewSettings,
  clearPanels,
  clearViewId,
  financialsSlice,
  selectCapsuleList,
  selectTables,
  setCapsuleList,
  setOneVisible,
} from 'store/financials.slice';
import { FinancialTableContextProvider } from 'context/FinancialTableContext';
import { ReactComponent as EmptyBoxIcon } from 'assets/icons/box.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Button from 'components/elements/button/Button';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { routes } from 'utils/config.utils';
import { fetchAndSetSystemTemplate, fetchAndSetTemplateList } from 'store/templates.slice';
import { getDisplayName } from 'utils/common.utils';
import organizationsService from '../../services/organizations.service';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DetailedViewType, type ViewItem } from 'types/financials.types';
import { topBarSlice } from 'store/topBar.slice';
import { fetchAndSetInputs } from 'store/budget.slice';
import useCommandBarDefaults from 'components/commandBar/hooks/useCommandBarDefaults';
import { arrayToMap } from '../../utils/mapping.utils';
import useFloatingDetailedViewResizer from
  'components/financials/detailedView/hooks/useFloatingDetailedViewResizer';
import { FLOATING_PANEL_PORTAL_ID } from
  'components/financials/detailedView/utils/detailedView.utils';
import ItemsLayoutPanel from './panels/itemsLayoutPanel/ItemsLayoutPanel';
import clsx from 'clsx';
import InputsTable from 'components/inputs/table/InputsTable';
import useLayoutCommandBar from './hooks/useLayoutCommandBar';
import { UtilKey } from 'components/commandBar/types/commandBar.types';
import { INPUT_TEMPLATE_ID } from 'components/inputs/utils/inputs.utils';
import DataLossPrevention from './dataLossPrevention/DataLossPrevention';

const Financials = () => {
  const [ t ] = useTranslation('navigation');
  const dispatch = useAppDispatch();
  const { _viewId } = useParams();
  const viewId: number = useMemo(() => parseInt(_viewId), [ _viewId ]);
  const isBreakdownDataLoaded = useAppSelector((state) => state.breakdowns.dimensionsLoaded);

  const tables = useAppSelector(selectTables);
  const viewItems = useAppSelector(selectCapsuleList);
  const templates = useAppSelector((state) => state.templates.templateList);
  const systemTemplates = useAppSelector((state) => state.templates.systemTemplateList);
  const navList = useAppSelector((state) => state.app.navigationList);
  const detailedView = useAppSelector((state) => state.financials.detailedView);
  const anyTableHaveNewCells = useAppSelector((state) => {
    return Object.values(state.financials?.tables)?.some((table) => table?.hasNewCells);
  });

  const [ viewName, setViewName ] = useState(null);
  const [ detailPanelSize, setDetailPanelSize ] = useState(0);
  const { ref } = useFloatingDetailedViewResizer({ type: 'reports' });

  const [ params, setParams ] = useSearchParams();

  // ? show only this template
  const displayOneItem = +params.get('active');

  // ? show only display this template
  // ? viewId shouldn't be available so changes to this template will be not saved
  const noViewTemplate = params.get('template');

  useLayoutCommandBar({ disabled: false });

  useEffect(() => {
    dispatch(fetchAndSetTemplateList());
    dispatch(fetchAndSetSystemTemplate());
  }, []);

  useEffect(() => {
    if (!viewId) {
      // ? if viewId is not available set fake one to show only one template
      dispatch(topBarSlice.actions.setViewId(-1));
    } else {
      dispatch(topBarSlice.actions.setViewId(viewId));
    }
    dispatch(financialsSlice.actions.clearActiveTable());
    dispatch(clearPanels());
    dispatch(clearCapsuleList());

    return () => {
      dispatch(clearViewId());
      dispatch(financialsSlice.actions.clearActiveTable());
      dispatch(clearCapsuleList());
      dispatch(financialsSlice.actions.clearTables());
    };
  }, [ viewId ]);

  const noViewFound = useCallback((id: number) => {
    if (!navList) return false;
    return navList.some((item) => item.id === id);
  }, [ navList ]);

  useEffect(() => {
    if (viewId) {
      dispatch(financialsSlice.actions.setActive(null));

      organizationsService.getCustomView(viewId).then(({ data }) => {
        const customView = data;

        setViewName(data.name);
        dispatch(financialsSlice.actions.setIsPlanningEnabled(customView.planning));
        dispatch(financialsSlice.actions.setCloseOtherPanelsOnOpen(false));
        dispatch(financialsSlice.actions.setTemplates(customView.templates));
        const selectedTemplates = customView.visibleTemplates.length
          ? customView.visibleTemplates
          : customView.templates;

        // ? tables are templates, temporary we call templates tables only on frontend
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selectedItems = selectedTemplates.map((i: any) => {
          if (i.type === 'template') return { ...i, type: 'table', };
          return i;
        });
        dispatch(setCapsuleList(selectedItems));
        dispatch(financialsSlice.actions.addTables(
          selectedItems.filter((i) => i.type === 'table').map((i) => i.templateId)
        ));
      });
    } else if (noViewTemplate) {
      const parsedTemplateId = parseInt(noViewTemplate);

      if (parsedTemplateId === INPUT_TEMPLATE_ID) {
        dispatch(setCapsuleList([
          { templateId: null, type: 'inputs', isShown: true }
        ]));
        dispatch(financialsSlice.actions.setActive({ templateId: null, type: 'inputs' }));
      } else {
        dispatch(financialsSlice.actions.addTables([ parsedTemplateId ]));
        dispatch(setCapsuleList([
          { templateId: parsedTemplateId, type: 'table', isShown: true }
        ]));
        dispatch(financialsSlice.actions.setActive({
          templateId: parsedTemplateId, type: 'table'
        }));
      }

    }
  }, [ viewId, noViewTemplate ]);

  const getReportName = useCallback(() => {
    if (viewName) return getDisplayName(viewName);
    else if (!viewName && (!viewId || noViewFound(viewId))) return t('links.report.default-name');
    return t('links.report.name-loading');
  }, [ viewName, viewId, noViewFound ]);

  useEffect(() => {
    dispatch(topBarSlice.actions.setTitle(getReportName()));
  }, [ viewId, viewName ]);

  useEffect(() => {
    dispatch(topBarSlice.actions.setAreCapsulesVisible(true));
    dispatch(fetchAndSetInputs());
    return () => {
      dispatch(financialsSlice.actions.clear());
      dispatch(topBarSlice.actions.clear());
    };
  }, []);

  useEffect(() => {
    dispatch(financialsSlice.actions.setViewId(viewId));
    dispatch(clearDetailedViewSettings());

    return () => {
      dispatch(clearDetailedViewSettings());
    };
  }, [ viewId ]);

  useEffect(() => {
    if (viewItems.length > 0 && displayOneItem) {
      if (displayOneItem === INPUT_TEMPLATE_ID) {
        dispatch(setOneVisible({ templateId: null, type: 'inputs', isShown: true }));
      } else {
        dispatch(setOneVisible({ templateId: displayOneItem, type: 'table', isShown: true }));
      }

      params.delete('active');
      setParams(params);
    }
  }, [ displayOneItem, viewItems ]);

  const existingViewItems = useMemo(() => {
    // For removing capsules that are no longer in the template list

    const allMappedTemplates = {
      ...arrayToMap(templates, (item) => item.id),
      ...arrayToMap(systemTemplates, (item) => item.id)
    };
    return viewItems
      .filter((item) => {
        if (item.type === 'table') {
          if (!allMappedTemplates[ item.templateId ]) return false;
          return tables[ item.templateId ];
        }

        return true;
      })
      .filter(item => item.isShown);
  }, [ viewItems, tables, templates, systemTemplates ]);

  return (
    <div className={ styles.itemsWrapper }>
      <div
        ref={ ref }
        className={ clsx(styles.items, {
          [ styles.noItems ]: existingViewItems.length === 0
        }) }
        id={ FLOATING_PANEL_PORTAL_ID }
      >
        {
          isBreakdownDataLoaded && existingViewItems.map((viewItem) =>
            <ViewItemProxy
              key={ `${ viewItem.type }__${ viewItem.templateId }` }
              viewItem={ viewItem }
              setDetailPanelSize={ setDetailPanelSize }
            />
          )
        }
        { detailedView.type === DetailedViewType.FLOATING_PANEL && (
          <div style={ { flex: `0 0 ${ detailPanelSize }px` } } />
        ) }
      </div>
      {
        existingViewItems.length === 0 && <EmptyPage />
      }
      <ItemsLayoutPanel />
      <DataLossPrevention willLooseData={ anyTableHaveNewCells }/>
    </div>
  );
};

export default Financials;

const EmptyPage = () => {
  const [ t ] = useTranslation('navigation');
  const navigate = useNavigate();

  return <div className={ styles.noCustomTemplates }>
    <EmptyFinancialsCommandBar />
    <div className={ styles.emptyTemplateIcon }>
      <EmptyBoxIcon/>
    </div>
    <div className={ styles.emptyTemplateText }>
      { t('financials:no-templates.description') }
    </div>
    <Button
      size='large'
      className={ styles.emptyTemplateButton }
      onClick={ () => {
        navigate(routes.report.templateBuilder);
      } }
    >
      <PlusIcon/>
      { t('financials:no-templates.button') }
    </Button>
  </div>;
};

interface ViewItemProxyProps {
  viewItem: ViewItem;
  setDetailPanelSize: (value: number) => void;
}

const ViewItemProxy = ({ viewItem, setDetailPanelSize }: ViewItemProxyProps) => {
  const dispatch = useAppDispatch();
  const active = useAppSelector(state => state.financials.active);

  const isTableActive = (templateId: number | null, type: ViewItem['type']) => {
    return active?.templateId === templateId && active?.type === type;
  };

  if (viewItem.type === 'table') {
    return (
      <FinancialTableContextProvider
        templateId={ viewItem.templateId }
        key={ `${ viewItem.type }__${ viewItem.templateId }` }
      >
        <FinancialTable setPanelSize={ setDetailPanelSize } />
      </FinancialTableContextProvider>
    );
  }

  if (viewItem.type === 'inputs') {
    const isActive = isTableActive(null, 'inputs');
    return (
      <span onClick={ () => dispatch(financialsSlice.actions.setActive({
        templateId: null,
        type: 'inputs'
      })) }>
        <InputsTable
          isActive={ isActive }
          className={ clsx(styles.inputsTable, { [ styles.activeCard ]: isActive }) }
        />
      </span>
      
    );
  }

  return <div></div>;
};

const EmptyFinancialsCommandBar = () => {
  useCommandBarDefaults((
    { keys: [ 'layout' ] as UtilKey[], mode: 'show' as const, showCapsulesFilter: false }
  ));
  useLayoutCommandBar({ disabled: false });

  return null;
};
