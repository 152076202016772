import React, { useCallback, useEffect } from 'react';
import { DashboardElementMapping, DashboardElementType } from 'types/dashboard.types';
import { Controller, useForm } from 'react-hook-form';
import { clearSelectedElements, saveElement } from 'store/dashboard.slice';
import { useAppDispatch } from 'store/hooks/hooks';
import ElementName from 'components/dashboard/panel/cardSettingsPage/forms/common/ElementName';
import FormWrapper from 'components/dashboard/panel/cardSettingsPage/forms/common/FormWrapper';

interface Props {
  item: DashboardElementMapping<DashboardElementType.TITLE | DashboardElementType.SUBTITLE>;
}

const TitleElementForm = ({ item }: Props) => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: item.name
    }
  });

  useEffect(() => {
    reset({ name: item.name });
  }, [ item ]);

  const onSubmit = useCallback((data: {name: string}) => {
    const element = {
      ...item,
      name: data.name,
    };
    dispatch(saveElement(element));
    dispatch(clearSelectedElements());
  }, [ item ]);

  return <FormWrapper onSubmit={ handleSubmit(onSubmit) }>
    <Controller
      control={ control }
      name='name'
      render={ ({ field }) => (
        <ElementName
          name={ field.value }
          onChangeName={ field.onChange }
          showCustomName={ true }
          setShowCustomName={ null }
        />
      ) }
    />
  </FormWrapper>;
};

export default TitleElementForm;