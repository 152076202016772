import React, { useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { TemplateNode } from 'types/templates.types';

import { ReactComponent as DragHere } from 'assets/icons/dragHere.svg';

import styles from './TemplateRow.module.scss';
import { batchCreateNode, createNode, moveNode, selectTemplate } from 'store/template.slice';
import { isDimensionGroup } from 'utils/template.utils';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { useTranslation } from 'react-i18next';
import { selectCustomFormula } from 'store/formula.slice';

const TemplateRowEmpty = () => {
  const dispatch = useAppDispatch();
  const customFormula = useAppSelector(selectCustomFormula);
  const template = useAppSelector(selectTemplate);
  const dimensions = useAppSelector(state => state.templates.dimensions);
  const [ t ] = useTranslation('financials');

  const customFormulaIsOpen = useMemo(() => customFormula !== null ,[ customFormula ]);

  const [ { isOver }, drop ] = useDrop(() => ({
    accept:  'tag',
    canDrop: () => !customFormulaIsOpen,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
      canDrop: monitor.canDrop()
    }),
    drop: async (item: TemplateNode) => {
      if (!customFormulaIsOpen) {
        if (isDimensionGroup(item)) {
          return dispatch(batchCreateNode(item, null, 'none'));
        }
        dispatch(createNode(item, null, 'none'));
      }
    }
  }),[ customFormulaIsOpen, dimensions ]);

  const [ { isOver: isOverNode }, dropNode ] =
    useDrop(() => ({
      accept: 'node',
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        handlerId: monitor.getHandlerId(),
        canDrop: monitor.canDrop(),
      }),
      drop: async (item: TemplateNode) => {
        !customFormulaIsOpen && dispatch(moveNode(item, null, 'none'));
      }
    }),[ customFormulaIsOpen ]);

  return <div
    ref={ (el) => {
      drop(el);
      dropNode(el);
    } }
    className={ `${ styles.emptyRow }
    ${ isOver || isOverNode
    ? styles.isOver : '' }
    ${ customFormula !== null ? styles.openFormula : '' }
    ${ template.roots.length === 0 ? styles.isEmpty : '' }
    ` }
  >
    {
      template.roots.length === 0 && (
        <div className={ styles.message }>
          <DragHere/>
          <span>{ t('templates.drag-here') }</span>
        </div>
      )
    }
  </div>;
};

export default TemplateRowEmpty;
