import Modal from 'components/elements/modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import styles from './DeleteProductModal.module.scss';
import { deleteProductFromList, selectProduct } from 'store/revenueRecognition.slice';
import { useRevenueRecognition } from 'context/RevenueRecognitionContext';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';

const DeleteProductModal = () => {
  const [ t ] = useTranslation();
  const selectProducts = useAppSelector(selectProduct);
  const {
    state: { deleteProduct },
  } = useRevenueRecognition();
  const product = selectProducts.find(el => el.id === deleteProduct);
  const { dispatch: dispatchContext } = useRevenueRecognition();

  const handleCloseModal = () =>{
    dispatchContext({ type: 'DELETE_PRODUCT', payload: -1 });
  };

  const dispatch = useAppDispatch();

  return <Modal
    title={
      <div className={ styles.title }>
        <BinIcon className={ styles.binIcon }/>
        <span>{ t('notifications.delete-product.title', { ns: 'common' }) }</span>
      </div>
    }
    isVisible={ deleteProduct === -1 ? false : true }
    onClose={ handleCloseModal }
    onConfirm={ () => dispatch(deleteProductFromList( product )) }
    width={ 650 }
    okText={ t('notifications.delete-product.okText', { ns: 'common' }) }
    closeText={ t('form.cancel',{ ns: 'common' }) }
    closeIcon={ <CloseIcon/> }>
    <div>
      { t('notifications.delete-product.message', {
        ns: 'common',
        name: product?.name || 'Field'
      }) }
    </div>
  </Modal>;
};

export default DeleteProductModal;
