import { DashboardDetail, DashboardElementType } from './../types/dashboard.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Layout } from 'react-grid-layout';
import { Dashboard, DashboardElement, DashboardModeType } from 'types/dashboard.types';
import { AppDispatch, RootState } from './store';

export interface DashboardStore{
  activeDashboardId: number;
  activeDashboard: DashboardDetail;
  elements: DashboardElement[];
  selectedElements: number[];
  mode: DashboardModeType;
  saving: boolean;
  refresh: number;
  dashboards: Dashboard[];
}

const initialState: DashboardStore = {
  activeDashboardId: undefined,
  activeDashboard: undefined,
  elements: [],
  selectedElements: [],
  mode: DashboardModeType.VIEW,
  saving: false,
  refresh: 0,
  dashboards: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setElements: (state, action: PayloadAction<DashboardElement[]>) => {
      state.elements = action.payload;
    },
    setMode: (state, action: PayloadAction<DashboardModeType>) => {
      const mode = action.payload;
      state.mode = mode;
      if (mode === DashboardModeType.VIEW) {
        state.selectedElements = [];
      }
    },
    setSelectedElements: (state, action: PayloadAction<number[]>) => {
      state.selectedElements = action.payload;
    },
    setSaving: (state, action: PayloadAction<boolean>) => {
      state.saving = action.payload;
    },
    setRefresh: (state, action: PayloadAction<number>) => {
      state.refresh = action.payload;
    },
    clear: (state) => {
      return {
        ...initialState,
        dashboards: state.dashboards,
      };
    },
    setDashboards: (state, action: PayloadAction<Dashboard[]>) => {
      state.dashboards = action.payload;
    },
    addDashboard: (state, action: PayloadAction<Dashboard>) => {
      state.dashboards.push(action.payload);
    },
    setActiveDashboard: (state, action: PayloadAction<DashboardDetail>) => {
      state.activeDashboardId = action.payload.id;
      state.activeDashboard = action.payload;
    },
    addElementToDashboard: (state, action: PayloadAction<DashboardElement[]>) => {
      if (state.activeDashboard) {
        state.activeDashboard.elements = action.payload;
      }
    },
    clearElements: (state) => {
      if (state.activeDashboard) {
        state.activeDashboard.elements = [];
      }
      state.elements = [];
    }
  }
});

const actions = dashboardSlice.actions;

export const removeElement = (id: number) => (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(clearSelectedElements());
  const elements = selectElements(getState());
  const filteredElements = elements.filter((e) => e.id !== id);
  dispatch(actions.setElements(filteredElements));
};

export const batchRemoveElements = (ids: number[]) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(clearSelectedElements());
    const elements = selectElements(getState());
    const filteredElements = elements.filter((e) => !ids.includes(e.id));
    dispatch(actions.setElements(filteredElements));
  };

export const addElement = (element: DashboardElement) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const elements = selectElements(getState());
    const newElements = [ ...elements, element ];
    dispatch(actions.setElements(newElements));
    dispatch(selectElement(element.id, false));
  };

export const saveElement = (element: DashboardElement) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const elements = selectElements(getState());
    const newElements = elements.map((e) => {
      if (e.id === element.id) {
        return element;
      }
      return e;
    });
    dispatch(actions.setElements(newElements));
    dispatch(actions.addElementToDashboard([ ...newElements ]));
  };

export const saveElements = (elements: DashboardElement[]) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const current = selectElements(getState());
    const uniqueIds = [ ...new Set([ ...current, ...elements ].map((e) => e.id)) ];
    const newElements = uniqueIds.map((id) => {
      const element = elements.find((el) => el.id === id);
      const currentElement = current.find((el) => el.id === id);
      if (!element || !currentElement) return element ?? currentElement;

      if (
        element.type === DashboardElementType.BIG_NUMBER &&
        currentElement.type === DashboardElementType.BIG_NUMBER
      ) {
        return {
          ...element,
          value: element.value ?? currentElement.value
        };
      }
      return element;
    });
    dispatch(actions.setElements(newElements));
    dispatch(actions.addElementToDashboard([ ...newElements ]));
  };

export const updateElementsLayout =
  (layout: Layout[]) => (dispatch: AppDispatch, getState: () => RootState) => {
    const elements = selectElements(getState());
    const newElements = elements.map((e) => {
      const layoutElement = layout.find((l) => l.i === e.id.toString());
      if (!layoutElement) return e;
      return {
        ...e,
        x: layoutElement.x,
        y: layoutElement.y,
        width: layoutElement.w,
        height: layoutElement.h,
      };
    });
    dispatch(actions.setElements(newElements));
  };

export const selectElement = (id: number, multiple: boolean) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const { selectedElements, mode } = getState().dashboard;
    if (mode === DashboardModeType.VIEW) return;
    if (!multiple) {
      dispatch(actions.setSelectedElements([ id ]));
      return;
    }

    if (selectedElements.includes(id)) {
      dispatch(actions.setSelectedElements(selectedElements.filter((e) => e !== id)));
    } else {
      dispatch(actions.setSelectedElements([ id, ...selectedElements, ]));
    }
  };

export const clearSelectedElements = () => (dispatch: AppDispatch) => {
  dispatch(actions.setSelectedElements([]));
};

export const forceRefresh = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const refresh = selectDashboardRefresh(getState());
  dispatch(actions.setRefresh(refresh + 1));
};

export const dashboardReducer = dashboardSlice.reducer;
export const selectElements = (state: { dashboard: DashboardStore }) => state.dashboard.elements;
export const selectMode = (state: { dashboard: DashboardStore }) => state.dashboard.mode;
export const selectSelectedElements =
  (state: { dashboard: DashboardStore }) => state.dashboard.selectedElements;
export const selectDashboardSaving =
  (state: { dashboard: DashboardStore }) => state.dashboard.saving;
export const selectDashboardRefresh =
  (state: { dashboard: DashboardStore }) => state.dashboard.refresh;
