import React from 'react';
import { useDrag } from 'react-dnd';
import styles from '../FormulaBuilder.module.scss';
import useFormula from 'hooks/useFormula';
import { useAppSelector } from 'store/hooks/hooks';
import { selectCustomFormula } from 'store/formula.slice';
import { OperatorsType } from '../utils/formulaBuilder.utils';

interface Props {
  element: OperatorsType;
  moveCursor: (moveOnEnd?: boolean) => void;
  position: number;
}

const Operator = ({ element, position, moveCursor }: Props) => {
  const { addOperator } = useFormula();
  const customFormula = useAppSelector(selectCustomFormula);
  const [ { isDragging }, drag ] = useDrag(() => ({
    type: element.type,
    item: element,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div
      ref={ drag }
      onClick={ () => {
        const newIndex = addOperator(element, position);
        moveCursor(newIndex >= customFormula.length);
      } }
      className={ `${ styles.operator } ${ isDragging ? styles.isDragging : '' }` }
    >
      { element.component }
    </div>
  );
};

export default Operator;
