import { DisplayName } from 'types/app.types';
import i18n from 'locales/i18n';
import { t } from 'i18next';

export const getDisplayName = ( displayName: DisplayName, defaultName?: string): string => {
  if (typeof displayName === 'string') {
    return displayName;
  }
  const name = displayName?.[ i18n.language ];
  if (name) {
    return name;
  }
  if (defaultName !== null && defaultName !== undefined) {
    return defaultName;
  }
  return displayName?.[ 'en' ] || '';
};

export const createDisplayName = (name: string): DisplayName => {
  return {
    en: t(name, { lng: 'en' }),
    fi: t(name, { lng: 'fi' }),
    sv: t(name, { lng: 'sv' })
  };
};

export const isDisplayNameEmpty = (displayName: DisplayName) => {
  return !displayName || !getDisplayName(displayName);
};

export const getUrlParams = (params: {}) => {
  const filtered = Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(params).filter(([ key, value ]) => !(value instanceof Array) || value.length > 0)
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new URLSearchParams(filtered as any).toString();
};

export const getUniqueNumber = (str: string) => {
  const hash = str.split('').reduce((prevHash, currVal) =>
    (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0
  , 0);
  return Math.abs(hash);
};

export const intersect = (a: string[], b: string[]): string[] => {
  return a.filter(Set.prototype.has, new Set(b));
};

export const filterNumbers = (value: string): string => {
  const valueWithoutNumbers = value.replace(/\d/g, '');
  const doubleDotSeparatorPlace = valueWithoutNumbers.indexOf(':');

  if (doubleDotSeparatorPlace === -1) {
    return valueWithoutNumbers;
  }

  return valueWithoutNumbers.slice(0, doubleDotSeparatorPlace);
};

export const trimCadenceSuffix = (value: string): string => {
  if (!value.includes('_')) {
    return value;
  }
  return value.slice(0, -2);
};

export const numberFormatter = (value: number, euroSign?: boolean) => {
  const isNegative = value < 0;
  const absValue = Math.abs(value);
  let returnValue: string;

  if (absValue < 1000) {
    returnValue = `${ absValue.toFixed(2) }`;
  } else if (absValue < 9_999) {
    returnValue = `${ (absValue / 1_000).toFixed(1) } K`;
  } else if (absValue < 999_999) {
    returnValue = `${ Math.round(absValue / 1_000) } K`;
  } else {
    returnValue = `${ (absValue / 1_000_000).toFixed(2) } M`;
  }
  return `${ isNegative ? `-${ returnValue }` : returnValue } ${ euroSign ? '€' : '' }`;

};

export const getUppercaseAlphabet = () => {
  return Array.from(Array(26)).map((_, i) =>
    String.fromCharCode(65 + i));
};
