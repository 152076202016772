import React from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import 'styles/index.scss';
import './locales/i18n';
import App from 'components/app/App';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { initSentry } from './plugins/sentry';
import { agGridLicence } from 'utils/config.utils';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import RootErrorBoundary
  from 'components/elements/errorBoundary/rootErrorBoundary/RootErrorBoundary';

initSentry();
LicenseManager.setLicenseKey(agGridLicence);

const root = createRoot(
  document.getElementById('root'),
);

root.render(
  <RootErrorBoundary>
    <HelmetProvider>
      <Provider store={ store }>
        <App/>
      </Provider>
    </HelmetProvider>
  </RootErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
