import { ColDef } from 'ag-grid-community';
import SearchBar from 'components/elements/searchBar/SearchBar';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import {
  Action,
  DefaultTool,
  GroupOption,
  HeaderTool,
  PanelSetting,
} from '../types/table.types';
import ActionButton from '../actionButton/ActionButton';
import styles from './Header.module.scss';
import RangePickerModal from 'components/elements/rangePicker/RangePickerModal';
import { Period, PeriodSectionsMode } from 'types/financials.types';
import { AgGridReact } from 'ag-grid-react';
import GroupFilterRow from './GroupFilterRow/GroupFilterRow';
import { ReactComponent as CloseIcon } from 'assets/icons/thick-close.svg';
import AutoSave from 'components/elements/autoSave/AutoSave';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

export interface Props<T> {
  name: string;
  quantityOfElements: number;
  showSearch: boolean;
  groupByOptions?: GroupOption[]; // First option is default
  columnDefs: ColDef[];
  actions: Action[];
  period?: Period;
  gridRef: React.MutableRefObject<AgGridReact>;
  onPeriodChange: (period: Period) => void;
  onClose?: () => void;
  panels: PanelSetting<T>[];
  activePanelKey: string;
  headerTools?: HeaderTool[];
  sectionKey: string;
  disableCadence?: boolean;
  disableBudgetPeriod?: boolean;
  gridReady: boolean;
  firstDataRendered: boolean;
  tableKey: string;
  disabledTools?: DefaultTool[];
  isAutoSaved?: boolean;
  periodSections?: PeriodSectionsMode;
}

const Header = <T,>({
  name,
  quantityOfElements,
  showSearch,
  groupByOptions,
  actions,
  period,
  onPeriodChange,
  gridRef,
  columnDefs,
  onClose,
  activePanelKey,
  headerTools,
  sectionKey,
  disableCadence,
  disableBudgetPeriod,
  gridReady,
  firstDataRendered,
  tableKey,
  isAutoSaved,
  panels = [],
  disabledTools = [],
  periodSections,
}: Props<T>) => {
  const onRangeChange = (newPeriod: Period) => {
    onPeriodChange(newPeriod);
  };

  const activeGroupSortId = gridRef?.current?.columnApi?.getRowGroupColumns().at(0)?.getId();
  const groupingInstance = activeGroupSortId && groupByOptions
    .find((option) => option.field === activeGroupSortId);

  const isToolDisabled = useCallback((tool: DefaultTool) => {
    return disabledTools?.includes(tool);
  }, [ disabledTools ]);

  const baseTools: ReactNode[] = useMemo(() => {
    if (!gridReady) {
      return [];
    }
    const tools = [];
    if (period && !isToolDisabled('period')) {
      tools.push(<RangePickerModal
        onSubmit={ onRangeChange }
        defaultPeriod={ period }
        cadence={ period.cadence }
        disableCadence={ disableCadence }
        actualsIsOpen={ period.actualsOpen }
        planIsOpen={ period.planOpen }
        sections={ periodSections }
      />);
    }
    if (actions) {
      tools.push(<ActionButton actions={ actions }/>);
    }
    if (onClose) {
      tools.push(
        <GhostIconButton className={ styles.close } onClick={ onClose } withSeparator>
          <CloseIcon/>
        </GhostIconButton>
      );
    }

    return tools;

  }, [
    groupByOptions,
    onClose,
    actions,
    columnDefs,
    period,
    sectionKey,
    disableBudgetPeriod,
    disableCadence,
    gridReady,
    tableKey
  ]);

  const tools = useMemo(() => {
    if (!(gridReady && firstDataRendered)) {
      return [];
    }

    const baseToolsCopy = [ ...baseTools ];

    const panelPositionMap = panels.reduce<Record<number, ReactNode>>((acc, panel) => {
      const isActive = panel.key === activePanelKey;
      acc[ panel.buttonPosition ] = <PanelButton panel={ panel } isActive={ isActive } />;
      return acc;
    } , {});

    const _headerTools = headerTools ?? [];
    const headerToolPositionMap = _headerTools.reduce<Record<number, ReactNode>>(
      (acc, headerTool) => {
        acc[ headerTool.buttonPosition ] = headerTool.button;
        return acc;
      } , {});

    return Array.from(
      { length: baseTools.length + panels.length + _headerTools.length },
      (_, i) => panelPositionMap[ i ] ?? headerToolPositionMap[ i ] ?? baseToolsCopy.shift()
    );
  }, [ baseTools, panels, activePanelKey, headerTools, gridReady, firstDataRendered ]);

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.api?.setFilterModel(null);
    }
  }, [ sectionKey ]);

  return (
    <>
      <div className={ styles.header }>
        <div className={ styles.tableTitle }>
          <span className={ styles.title }>{ name }</span>
          <span className={ styles.quantity }>{ quantityOfElements }</span>
          <AutoSave show={ isAutoSaved } />
        </div>
        <div className={ styles.wrapperTools }>
          {
            showSearch && gridReady && firstDataRendered && (
              <SearchBar
                showSeparator={ false }
                showResults={ false }
                className={ styles.searchBar }
              />
            )
          }
          {
            tools.map((tool, index) => (
              <React.Fragment key={ index }>
                { tool }
              </React.Fragment>
            ))
          }
        </div>
      </div>
      {
        activeGroupSortId && groupingInstance?.availableChoices != null &&
          <GroupFilterRow
            gridRef={ gridRef }
            selectedGrouping={ groupingInstance }
            availableChoices={ groupingInstance?.availableChoices }
            sectionKey={ sectionKey }
          />
      }
    </>

  );
};

export default Header;

function PanelButton<T>({ panel, isActive }: { panel: PanelSetting<T>; isActive: boolean }) {
  return <div className={ styles.buttonContainer }>
    {
      panel.additionalButton ? <div className={ styles.additionalButton }>
        { panel.additionalButton }
      </div> : null
    }
    <div
      key={ panel.buttonPosition }
      onClick={ () => isActive ? panel.onDeactivate() : panel.onActivate() }
      className={ `${ styles.buttonContainer }
  ${ isActive ? styles.buttonContainerActive : '' }` }
    >
      { panel.button }
    </div>
  </div>;
}
