import { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useAppSelector } from 'store/hooks/hooks';
import {
  isBreakdownNode,
  isDimensionItemNode,
  isUnassignedNode,
  TemplateNode,
  TemplateNodeId,
  UUID
} from 'types/templates.types';
import { getDisplayName } from 'utils/common.utils';
import { getDimensionViewName, getUnassignedNodeName } from 'utils/financials.utils';
import { IGNORE_NODES } from 'utils/chart.utils';
import { useGetTemplateQuery } from '../store/api/templates.api';

interface Props {
  templateId: number;
  type?: 'default' | 'chart';
}

export interface NodeOption {
  label: string;
  value: UUID;
  children: NodeOption[];
}

const useTemplateNode = ({ templateId, type='default' }: Props) => {
  const { data: report, isFetching } = useGetTemplateQuery({ templateId });

  const showEmptyRows = useAppSelector(
    state => state.financials.tables[ templateId ]?.state?.accounts
  );

  const getNodesByType = useCallback((nodes: TemplateNode[]) => {
    if (type === 'default') {
      return nodes;
    }
    return nodes.filter(node => !IGNORE_NODES.includes(node.type));
  }, [ type ]);

  const rootNodes = useMemo(() => {
    const nodes = report?.nodes || [];

    return getNodesByType(nodes).filter(el =>
      report.roots.includes(el.id)
    );
  }, [ report, showEmptyRows ]);

  const uniqueRoots = useMemo(() => {
    const uniqRoots: TemplateNode[] = [];
    rootNodes.forEach(el => {
      const isUnique = !uniqRoots.some(uniqueRoot => {
        const nodesEqual = isEqual(el.rowData, uniqueRoot.rowData) && el.type === uniqueRoot.type;
        if (!nodesEqual) return false;

        const childrenEqual =
          el.children?.length === uniqueRoot.children?.length &&
          el.children?.every((child, index) => {
            const childNode = report.nodes?.find(node => node.id === child);
            const uniqueChildNode =
              report.nodes?.find(node => node.id === uniqueRoot.children[ index ]);
            return isEqual(childNode?.rowData, uniqueChildNode?.rowData);
          });
        return childrenEqual;
      });

      if (isUnique) {
        uniqRoots.push(el);
      }
    });

    return uniqRoots;
  }, [ rootNodes ]);

  const childrenToNodes = useCallback((children: TemplateNodeId[]) => {
    return children.map(child => {
      return report.nodes.find(node => node.id === child);
    }).filter(el => el);
  }, [ report ]);

  const getNodeOptions = useCallback((nodes: TemplateNode[] = uniqueRoots): NodeOption[] => {

    const getLabel = (node: TemplateNode) => {
      if (isBreakdownNode(node) || isDimensionItemNode(node)) {
        return getDimensionViewName(node.rowData);
      } else if (isUnassignedNode(node)) {
        return getUnassignedNodeName(node.rowData);
      }
      return getDisplayName(node?.rowData?.name);
    };

    return getNodesByType(nodes).map(root => ({
      label: getLabel(root),
      value: root.uuid,
      children: root.children?.length ? getNodeOptions(childrenToNodes(root.children)): [],
    }));
  }, [ uniqueRoots, report ]);

  const findOption = useCallback((options: NodeOption[], id: UUID): NodeOption => {
    for (const option of options) {
      if (option.value === id) return option;
      const found = findOption(option.children, id);
      if (found) {
        return found;
      }
    }
    return null;
  }, []);

  return { getNodeOptions, uniqueRoots, findOption, report, isFetching };
};

export default useTemplateNode;
