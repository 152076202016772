import React, { useCallback, useMemo } from 'react';
import { Button } from 'antd';
import { Period } from '../../../types/financials.types';
import styles from './PeriodRangeButton.module.scss';
import { periodRangeString } from '../../../utils/period.utils';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locales/i18n';
import { DATE_FORMATS } from '../../../utils/date.utils';

type Props = {
  period: Period;
  onClick: () => void;
  disabled?: boolean;
};

const PeriodRangeButton = ({ period, onClick, disabled = false }: Props) => {
  const [ t ] = useTranslation('financials');
  const isFinnish = i18n.language === 'fi';

  const getPeriodRange = useCallback((startDate: number, endDate: number) => {
    if (isFinnish) {
      return periodRangeString({
        startDate,
        endDate,
        fullFormat: 'D[.]M[.]YYYY',
      });
    }
    return periodRangeString({
      startDate,
      endDate,
      fullFormat: DATE_FORMATS[ 0 ],
    });
  }, []);

  const getSectionIndicator = useCallback((section: string) => {
    return <span className={ styles.section }>
      { section }
    </span>;
  }, []);

  const range = useMemo(() => {
    if (!period) return '';
    if (period.fullRange) {
      return <span className={ styles.noRange }>{ t('period.full-range') }</span>;
    }
    if (period.actualsOpen && period.planOpen) {
      return <>
        { getSectionIndicator(t('actual-short')) }
        { getPeriodRange(period.startDate, period.endDate) }

        { getSectionIndicator(t('budget-short')) }
        { getPeriodRange(period.startDatePlan, period.endDatePlan) }
      </>;
    }
    if (period.planOpen) {
      return (
        <>
          { getSectionIndicator(t('budget-short')) }
          { getPeriodRange(period.startDatePlan, period.endDatePlan) }
        </>
      );
    }
    return (
      <>
        { getSectionIndicator(t('actual-short')) }
        { getPeriodRange(period.startDate, period.endDate) }
      </>
    );
  }, [ period ]);

  return <Button className={ styles.button } onClick={ onClick } disabled={ disabled }>
    <div className={ styles.text }>
      { range }
    </div>
  </Button>;
};
export default PeriodRangeButton;
