import React, { SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './FormulaBuilderSlider.module.scss';
import Card from 'components/elements/card/Card';
import Button from 'components/elements/button/Button';
import AcceptFormulaModal from '../templateRow/accpetFormulaModal/AcceptFormulaModal';
import { useTranslation } from 'react-i18next';
import {
  TemplateSections,
  TreeTags,
} from 'types/templates.types';
import { isEqual } from 'lodash';
import { selectTemplate } from 'store/template.slice';

import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import SearchBar from 'components/elements/searchBar/SearchBar';
import FinancialBottomHeader from '../bottomHeader/FinancialBottomHeader';
import TemplateBuilderDimensionList
  from '../templateBuilderDimensionList/TemplateBuilderDimensionList';
import { SearchableContextProvider } from 'context/SearchableContext';

import { getDisplayName } from 'utils/common.utils';
import FormulaBuilder from '../customTemplate/formulaBuilder/FormulaBuilder';
import {
  changeSelectCustomFormulaId,
  selectCalculateDynamicTotals,
  selectCalculateRowTotals,
  selectCustomFormula,
  selectCustomFormulaFormat,
  selectCustomFormulaId,
  selectReverseChangesFormatting,
  updateCustomFormula,
  updateFormula
} from 'store/formula.slice';

type Props = {
  isLoading: boolean;
  treeTags: TreeTags;
  widthLeftPanel: number;
  setHeightPanel: React.Dispatch<SetStateAction<string>>;
  heightPanel: string;
};

const FormulaBuilderSlider = ({
  isLoading,
  treeTags,
  widthLeftPanel,
  setHeightPanel,
  heightPanel
}: Props) => {
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();

  const template = useAppSelector(selectTemplate);
  const customFormula = useAppSelector(selectCustomFormula);
  const formatState = useAppSelector(selectCustomFormulaFormat);
  const calculateDynamicTotals = useAppSelector(selectCalculateDynamicTotals);
  const calculateRowTotals = useAppSelector(selectCalculateRowTotals);
  const reverseChangesFormatting = useAppSelector(selectReverseChangesFormatting);
  const nodeId = useAppSelector(selectCustomFormulaId);

  const containerRef = useRef(null);

  const formulaBuilderIsActive = useMemo(() =>{
    return customFormula !== null;
  } ,[ customFormula ]);

  const [ acceptFormulaModal, setAcceptFormulaModal ] = useState(false);
  const [ formulaName, setFormulaName ] =
    useState(template?.nodes[ nodeId ]?.rowData?.name || 'Formula');
  const [ formulaValidation, setFormulaValidation ] = useState(true);
  const prevCustomFormula = useRef(customFormula);
  const initRef = useRef(true);
  const [ customFormulaIsChanged, setCustomFormulaIsChanged ] = useState(false);
  const [ activeSection, setActiveSection ] = useState(TemplateSections.FINANCIALS);

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
      return;
    }
    if (!isEqual(prevCustomFormula.current, customFormula)) {
      const isInitialOpen = prevCustomFormula.current === null;

      setCustomFormulaIsChanged(!isInitialOpen);
      prevCustomFormula.current = customFormula;
    }
  }, [ customFormula ]);

  useEffect(() => {
    setFormulaName(template?.nodes[ nodeId ]?.rowData?.name);
  },[ template?.nodes[ nodeId ] ]);

  const closeFormulaBuilder = () => {
    dispatch(updateCustomFormula(null));
    dispatch(changeSelectCustomFormulaId(null));
  };

  const handleSendCustomFormula = useCallback(() => {
    dispatch(updateFormula(
      customFormula,
      nodeId,
      {
        name: getDisplayName(formulaName),
        ...formatState,
        calculateRowTotals,
        calculateDynamicTotals,
        reverseChangesFormatting
      }
    ));
    setCustomFormulaIsChanged(false);
  }, [
    customFormula,
    nodeId,
    formulaName,
    formatState,
    calculateDynamicTotals,
    calculateRowTotals,
    reverseChangesFormatting
  ]);

  return (
    <Card
      style={ { width: widthLeftPanel, minHeight: `calc(${ heightPanel } - 50px)` } }
      className={ `
    ${ styles.container }
    ${ formulaBuilderIsActive ? styles.openFormula : styles.closeFormula }`
      }>
      <AcceptFormulaModal
        isVisible={ acceptFormulaModal }
        onClose={ () => {
          setAcceptFormulaModal(false);
        } }
        onConfirm={ closeFormulaBuilder }
      />
      <div className={ styles.header } ref={ containerRef }>
        <div className={ styles.titleContainer }>
          <div className={ styles.headerTitle }>
            <Chevron className={ styles.arrow } onClick={ closeFormulaBuilder }/>
            <h2 className={ styles.title }>
              { t('templates.custom-formula-builder', { ns: 'financials' }) }
            </h2>
          </div>
          <div className={ styles.buttons }>
            {
              nodeId != null && (
                <>
                  <Button
                    name= { t('templates.cancel') }
                    type='text'
                    onClick={ () => {
                      if (customFormulaIsChanged)
                        setAcceptFormulaModal(true);
                      else
                        closeFormulaBuilder();
                    } }/>
                  <Button
                    disabled={ !formulaValidation }
                    name= { t('templates.save') }
                    onClick={ handleSendCustomFormula }
                  />
                </>
              )
            }
          </div>
        </div>
      </div>
      {
        nodeId !== null &&
        <>
          <SearchableContextProvider>
            <FormulaBuilder
              formulaValidation={ formulaValidation }
              setFormulaValidation={ setFormulaValidation }
              setHeightPanel={ setHeightPanel }
            />
            <SearchBar
              className={ styles.searchBox }
              showSeparator={ false }
              showResults={ false }
            />
            <FinancialBottomHeader
              activeSection={ activeSection }
              setActiveSection={ setActiveSection }
            />
            <TemplateBuilderDimensionList
              isLoading={ isLoading }
              activeSection={ activeSection }
              setActiveSection={ setActiveSection }
              treeTags={ treeTags }
            />
          </SearchableContextProvider>

        </>
      }
    </Card>
  );
};

export default FormulaBuilderSlider;
