import React, { memo, useEffect } from 'react';
import styles from './WindowChart.module.scss';
import Chart from '../chart/Chart';
import { useFinancialTable } from 'context/FinancialTableContext';
import { useAppSelector } from 'store/hooks/hooks';
import TopBar from '../topBar/TopBar';
import Card from 'components/elements/card/Card';
import { ChartContextProvider, useChartContext } from '../context/ChartContext';
import ChartSettings from '../chartSettings/ChartSettings';
import { ChartSettings as TChartSettings, } from 'types/chart.types';
import { isReportEmpty } from 'utils/financials.utils';
import ChartUpperArea from '../chartUpperArea/ChartUpperArea';
import { FormProvider, useForm } from 'react-hook-form';
import Loader from 'components/elements/loader/Loader';
import useTemplateNode from 'hooks/useTemplateNode';
import { getDefaultChartSettings } from 'utils/chart.utils';
import { selectPeriod } from 'store/financials.slice';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';

interface PropsWrapper {
  defaultSettings?: TChartSettings | null;
  className?: string;
  disableClose?: boolean;
}
type Props = {
  templateId: number;
} & PropsWrapper;

export const WindowChart = ({ templateId, className }: Props) => {
  const period = useAppSelector(selectPeriod(templateId));
  const { state: { templateNodeUuids } } = useChartContext();
  const { data: report, isLoading } = useReportQuery({ templateId, period, templateNodeUuids });
  const isVisible = isLoading || !isReportEmpty(report?.nodes);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={ `${ styles.chartContainer } ${ className }` }>
      {
        isLoading ? <Loader isActive={ isLoading } /> : <>
          <ChartSettings />
          <Card className={ `${ styles.chartCard }` }>
            <div className={ styles.container }>
              <TopBar />
              <ChartUpperArea templateId={ templateId } />
              <Chart period={ period } shouldAdjustNumberOfSplitLines={ false } />
            </div>
          </Card>
        </>
      }
    </div>
  );
};
const wrapper = ({
  className='',
  disableClose,
  defaultSettings
}: PropsWrapper) => {
  const { state: { templateId } } = useFinancialTable();
  const { uniqueRoots } = useTemplateNode({ templateId, type: 'chart' });
  const datasets = uniqueRoots.map(r => r.uuid);

  const methods = useForm<TChartSettings>({
    defaultValues: getDefaultChartSettings({
      primary: datasets[ 0 ],
      secondary: datasets[ 1 ]
    })
  });

  useEffect(() => {
    if (defaultSettings) {
      methods.reset(defaultSettings);
    } else {
      methods.reset(getDefaultChartSettings({
        primary: datasets[ 0 ],
        secondary: datasets[ 1 ]
      }));
    }
  }, [ defaultSettings ]);

  return <FormProvider { ...methods }>
    <ChartContextProvider disableClose={ disableClose }>
      <WindowChart templateId={ templateId } className={ className } />
    </ChartContextProvider>
  </FormProvider>;
};

export default memo(wrapper);
