import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { SortIndex, SortType } from 'components/elements/tableWrapper/types/table.types';
import { Control, Controller, useForm, useWatch } from 'react-hook-form';
import FlatSelect from 'components/elements/flatSelect/FlatSelect';
import { ColumnState } from 'ag-grid-community';
import { ReactComponent as SortDescendingIcon } from 'assets/icons/sorting.svg'; 
import { ReactComponent as SortAscendingIcon } from 'assets/icons/sort-ascending.svg'; 
import styles from './SortSelection.module.scss';
import clsx from 'clsx';
import Button from 'components/elements/button/Button';

type ValueType = string | number;

interface Props {
  gridRef: React.MutableRefObject<AgGridReact>;
  isDisabled?: boolean;
  defaultSortSettings?: Partial<FormType>;
  sortOptions?: { label: string; value: ValueType }[] | null;
  customSortHandler?: ((sortSetting: Partial<FormType>) => void) | null;
}

export interface ColumnSortSettings {
  colId?: string | number;
  sort?: SortType;
  sortIndex?: SortIndex;
}

export type FormType = {
  [SortIndex.PRIMARY]: ColumnSortSettings;
  [SortIndex.SECONDARY]: ColumnSortSettings;
};

const SortSelectionContent = ({
  gridRef,
  sortOptions,
  defaultSortSettings,
  isDisabled = false,
  customSortHandler
}: Props) => {
  const [ t ] = useTranslation('sorting');

  const emptySortSetting = {
    label: t('none-sorting'),
    value: ''
  };

  const getEmptySortSettings = (sortIndex: SortIndex): ColumnSortSettings => {
    return {
      colId: emptySortSetting.value,
      sort: 'asc',
      sortIndex
    };
  };

  const sortIndexToNumber = useCallback((sortIndex: SortIndex) => {
    return Object.values(SortIndex).indexOf(sortIndex);
  }, []);

  const getDefaultSortSettings = (sortIndex: SortIndex): ColumnSortSettings => {
    const state = gridRef.current?.columnApi?.getColumnState() ?? [];
    const setting = state.find((col) => col.sortIndex === sortIndexToNumber(sortIndex));
    if (setting) {
      return {
        colId: setting.colId,
        sort: setting.sort ?? 'desc',
        sortIndex
      };
    }
    return getEmptySortSettings(sortIndex);
  };

  const { control, reset } = useForm<FormType>({
    defaultValues: {
      [ SortIndex.PRIMARY ]: getDefaultSortSettings(SortIndex.PRIMARY),
      [ SortIndex.SECONDARY ]: getDefaultSortSettings(SortIndex.SECONDARY),
    }
  });
  const values = useWatch({ control });

  useEffect(() => {
    if (defaultSortSettings) {
      const newValues = {
        [ SortIndex.PRIMARY ]: defaultSortSettings[ SortIndex.PRIMARY ] ??
          getEmptySortSettings(SortIndex.PRIMARY),
        [ SortIndex.SECONDARY ]: defaultSortSettings[ SortIndex.SECONDARY ] ??
          getEmptySortSettings(SortIndex.SECONDARY)
      };

      reset(newValues);
    }
  }, [ defaultSortSettings, reset ]);

  const options = useMemo(() => {
    if (sortOptions && sortOptions.length > 0) {
      return sortOptions;
    }

    return gridRef?.current?.columnApi?.getColumns()
      ?.filter((col) => {
        const colDef = col.getColDef();
        return colDef.sortable && colDef.headerName;
      })
      .map((col) => ({
        label: col.getColDef().headerName,
        value: col.getColId()
      }));
  }, [ gridRef?.current?.columnApi, sortOptions ]);

  const sortSettingsToColumnState = useCallback((sortIndex: SortIndex) => {
    return {
      colId: values[ sortIndex ]?.colId as string,
      sort: values[ sortIndex ]?.sort,
      sortIndex: sortIndexToNumber(sortIndex)
    };
  }, [ values, sortIndexToNumber ]);

  const setSorting = useCallback(() => {
    const sortModel: ColumnState[] = [];
    if (values[ SortIndex.PRIMARY ]?.colId) {
      sortModel.push(sortSettingsToColumnState(SortIndex.PRIMARY));
    }
    if (values[ SortIndex.SECONDARY ]?.colId) {
      sortModel.push(sortSettingsToColumnState(SortIndex.SECONDARY));
    }
    gridRef.current.columnApi.applyColumnState({
      state: sortModel, defaultState: { sort: null, sortIndex: null }
    });
  }, [ values, sortSettingsToColumnState ]);

  const applySorting = useCallback(() => {
    if (customSortHandler) {
      customSortHandler(values);
    } else {
      setSorting();
    }
  }, [ values, setSorting, customSortHandler ]);

  return <div className={ clsx(styles.container, { [ styles.disabled ]: isDisabled }) }>
    <p className={ styles.title }>
      { t('title') }
    </p>
    <SortRow
      sortIndex={ SortIndex.PRIMARY }
      applySorting={ applySorting }
      title={ t('level.primary') }
      values={ values }
      options={ options }
      emptySortSetting={ emptySortSetting }
      control={ control }
    />
    <SortRow
      sortIndex={ SortIndex.SECONDARY }
      title={ t('level.secondary') }
      values={ values }
      options={ options }
      emptySortSetting={ emptySortSetting }
      control={ control }
      applySorting={ applySorting }
    />
  </div>;
};

export default SortSelectionContent;

interface SortRowProps {
  sortIndex: SortIndex;
  title: string;
  values: Partial<FormType>;
  options: { label: ReactNode; value: ValueType }[];
  emptySortSetting: { label: ReactNode; value: string };
  control: Control<FormType, object>;
  applySorting?: () => void;
}

const SortRow = ({
  values, sortIndex, title, options, emptySortSetting, control, applySorting
}: SortRowProps) => {
  const otherIndex = sortIndex === SortIndex.PRIMARY ? SortIndex.SECONDARY : SortIndex.PRIMARY;
  const otherCol = values[ otherIndex ]?.colId;
  const filteredOptions = [
    emptySortSetting,
    ...(options?.filter((option) => option.value !== otherCol) ?? [])
  ];

  return <>
    <p>{ title }</p>
    <div className={ styles.sortRow }>
      <Controller
        control={ control }
        name={ `${ sortIndex }.colId` }
        render={ ({ field: { onChange, value } }) => (<>
          <FlatSelect
            disabled={
              sortIndex === SortIndex.SECONDARY &&
              (values[ SortIndex.PRIMARY ]?.colId == null ||
                values[ SortIndex.PRIMARY ]?.colId === '')
            }
            onChange={ (...args) => {
              onChange(...args);
              applySorting?.(); 
            } }
            value={ value }
            className={ styles.select }
            options={ filteredOptions }
          />
        </>
        ) }
      />
      <Controller
        control={ control }
        name={ `${ sortIndex }.sort` }
        render={ ({ field: { onChange, value } }) => (
          <Button
            tooltip={ value === 'desc' ? 'A-Z' : 'Z-A' }
            disabled={ values[ sortIndex ]?.colId == null || values[ sortIndex ]?.colId === '' }
            className={ styles.sortButton }
            type='default'
            icon={ value === 'desc' ? <SortDescendingIcon /> : <SortAscendingIcon /> }
            onClick={ () => {
              onChange(value === 'desc' ? 'asc' : 'desc');
              applySorting?.();
            } }>
          </Button>
        ) }
     
      />
    </div>
  </>;
};
