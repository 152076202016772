import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/exclamation-circle.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/warning.svg';

import styles from './InfoBox.module.scss';

interface Props {
  type?: 'info' | 'warning' | 'error';
  className?: string;
}

const icons = {
  info: <InfoIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
};

const InfoBox = ({ children, type = 'info', className }: PropsWithChildren<Props>) => {
  return <div className={ clsx(styles.statement, styles[ type ], className) }>
    <div className={ styles.icon }>{ icons[ type ] }</div>
    <div className={ styles.text }>{ children }</div>
  </div>;
};

export default InfoBox;
