import React from 'react';
import styles from './HighlightedParagraph.module.scss';

type Props = {
  text: string;
  highlight: string;
  className?: string;
};

const escapeRegExp = (str: string): string => {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
};

const HighlightedParagraph = ({ text, highlight, className='' }: Props) => {
  if (!highlight?.trim()) {
    return <p className={ `${ styles.paragraph } ${ className }` }>{ text }</p>;
  }
  const regex = new RegExp(`(${ escapeRegExp(highlight) })`, 'gi');
  const parts = text.split(regex);

  return (
    <p className={ `${ styles.paragraph } ${ className }` }>
      { parts.filter(String).map((part, i) => {
        return <React.Fragment key={ `part__${ i }` }>
          {
            regex.test(part) ? (
              <mark>{ part }</mark>
            ) : (
              part
            )
          }
        </React.Fragment>;
      }) }
    </p>
  );
};

export default HighlightedParagraph;