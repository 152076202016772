import React, { useMemo } from 'react';
import { selectElements, selectSelectedElements } from 'store/dashboard.slice';
import { useAppSelector } from 'store/hooks/hooks';
import ChartElementForm from './forms/chartElementForm/ChartElementForm';
import TemplateElementForm from './forms/templateElementForm/TemplateElementForm';
import { DashboardElementType } from 'types/dashboard.types';
import TitleElementForm from './forms/titleElementForm/TitleElementForm';
import BasicTemplateForm from
  'components/dashboard/panel/cardSettingsPage/forms/basicTemplateForm/BasicTemplateForm';

const CardSettingsPage = () => {
  const selectedElements = useAppSelector(selectSelectedElements);
  const elements = useAppSelector(selectElements);
  const element = useMemo(() => {
    if (!selectedElements.length) {
      return null;
    }
    return elements.find((e) => e.id === selectedElements[ 0 ]);
  }, [ selectedElements, elements ]);

  switch (element?.type) {
    case DashboardElementType.BIG_NUMBER:
      return <TemplateElementForm item={ element } />;
    case DashboardElementType.CHART:
      return <ChartElementForm item={ element } />;
    case DashboardElementType.TITLE:
    case DashboardElementType.SUBTITLE:
      return <TitleElementForm item={ element } />;
    case DashboardElementType.TABLE:
      return <BasicTemplateForm item={ element } />;
    default:
      return null;
  }
};

export default CardSettingsPage;