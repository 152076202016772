import React from 'react';
import styles from './DimensionCapsule.module.scss';

interface Props {
  icon?: React.ReactNode;
  className?: string;
  dimension: string;
  dimensionItem: string;
}

const DimensionCapsule = ({ icon, className, dimension, dimensionItem }: Props) => {
  return <span className={ `${ styles.capsule } ${ icon ? styles.hasIcon : '' } ${ className }` }>
    <span className={ styles.icon }>{ icon }</span>
    <b>{ dimension?.toLowerCase() }</b>
    { dimensionItem }
  </span>;
};

export default DimensionCapsule;
