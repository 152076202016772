import { useCallback } from 'react';
import organizationsService from '../../../../services/organizations.service';
import { cloneDeep } from 'lodash';
import {
  breakdownsActions,
  removeDimension,
  updateDimension,
} from '../../../../store/breakdowns.slice';
import { notifyError, notifyUnexpectedError } from '../../../../utils/notifications.utils';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/hooks';
import { Dimension } from '../../../../types/filterTable.types';
import { useTranslation } from 'react-i18next';

interface Props {
  dimension?: Dimension | null;
}

const useDimension = ({ dimension }: Props) => {
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('labeling');
  const dimensions = useAppSelector(state => state.breakdowns.dimensions);

  const onAddDimension = useCallback(async (name: string) => {
    try {
      const res = await organizationsService.addDimensions({ name });
      dispatch(breakdownsActions.setDimensions([ ...cloneDeep(dimensions), res.data ]));
    } catch (e) {
      if (e.response.data.nonFieldErrors) {
        notifyError(t('labeling:dimension-unique-error'));
      } else {
        notifyUnexpectedError(e);
      }
      throw e;
    }
  }, [ dimensions ]);

  const onDimensionDelete = useCallback(async () => {
    if (!dimension) {
      return;
    }

    try {
      await organizationsService.deleteDimension(dimension?.id);
      dispatch(removeDimension(dimension));
    } catch (e) {
      notifyUnexpectedError(e);
    }
  }, [ dimensions, dimension ]);

  const onDimensionEdit = useCallback(async (name: string) => {
    if (!dimension) {
      return;
    }

    try {
      const editResponse = await organizationsService.editDimension(dimension.id, { name });
      // To keep properties added dynamically (like transactionLines count for labeling progress)
      const newDimensionData = { ...dimension, ...editResponse.data };
      dispatch(updateDimension(newDimensionData));
    } catch (e) {
      if (e.response?.data?.nonFieldErrors) {
        notifyError(t('dimension-unique-error'));
      } else {
        notifyUnexpectedError(e);
      }
    }
  }, [ dimensions, dimension ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDimensionItemAdd = useCallback(async (payload: any) => {
    if (!dimension) {
      return;
    }

    try {
      const response = await organizationsService.addDimensionItem(dimension.id, payload);
      const cloneDimension = cloneDeep(dimension);
      cloneDimension.items.push(response.data);
      dispatch(updateDimension(cloneDimension));
    } catch (e) {
      if (e.response.data.nonFieldErrors) {
        notifyError(t('labeling:dimension-item-unique-error'));
        throw e;
      } else {
        notifyUnexpectedError(e);
      }
    }
  }, [ dimension ]);
  
  return {
    onDimensionDelete,
    onDimensionEdit,
    onAddDimension,
    onDimensionItemAdd
  };
};

export default useDimension;
