import Modal from 'components/elements/modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../Templates.module.scss';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const AcceptFormulaModal = (
  { isVisible, onClose, onConfirm }: Props) => {
  const [ t ] = useTranslation();
  return <Modal
    title={ 
      <span className={ styles.modalHeader }>
        { t('templates.formulaModal.title', { ns: 'financials' }) }
      </span> 
    }
    description={ t('templates.formulaModal.text', { ns: 'financials' }) }
    width={ 650 }
    isVisible={ isVisible }
    onConfirm={ onConfirm }
    onClose={ onClose }
    className={ styles.modalFormula }
    closeText={ t('templates.formulaModal.backButton', { ns: 'financials' }) }
    okText={ t('templates.formulaModal.cancelButton', { ns: 'financials' }) }
  >
    { null }
  </Modal>;
};

export default AcceptFormulaModal;
