import { NetvisorIntegration } from
  'components/settings/integrations/netvisor/types/netvisor.types';
import { Integration, OAuthIntegrationRequest } from 'types/settings.types';
import { endpoints } from 'utils/config.utils';
import http from 'utils/http.utils';

const integrateProcountor = (code: string) =>
  http.post<OAuthIntegrationRequest>(endpoints.integrations.procountor, {
    authorizationCode: code
  } as OAuthIntegrationRequest);

const integrateXero = (code: string) =>
  http.post<OAuthIntegrationRequest>(endpoints.integrations.xero, {
    authorizationCode: code
  } as OAuthIntegrationRequest);

const integrateNetvisor = (data: NetvisorIntegration) =>
  http.post(endpoints.integrations.netvisor, data);

const continueProcountorIntegration = (id: number) => {
  return http.post(endpoints.integrations.trigger.procountor.replace('{id}', id.toString()));
};

const continueNetvisorIntegration = (id: number) => {
  return http.post(endpoints.integrations.trigger.netvisor.replace('{id}', id.toString()));
};

const continueXeroIntegration = (id: number) => {
  return http.post(endpoints.integrations.trigger.xero.replace('{id}', id.toString()));
};

const updateNetvisor = (id: number, data: NetvisorIntegration) =>
  http.patch(endpoints.integrations.netvisorUpdate.replace('{id}', id.toString()), data);

const getIntegrationByOrganization = (organizationId: number) =>
  http.get<Integration[]>(
    endpoints.organizations.integrations.replace('{id}', organizationId.toString())
  );

export {
  integrateProcountor,
  getIntegrationByOrganization,
  integrateNetvisor,
  integrateXero,
  updateNetvisor,
  continueNetvisorIntegration,
  continueProcountorIntegration,
  continueXeroIntegration,
};
