import { DimensionItem as DimensionItemT,
  FilterList } from 'types/filterTable.types';
import DimensionItem from '../DimensionItem/DimensionItem';
import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../DetailsList.module.scss';
import { AgGridReact } from 'ag-grid-react';
import { AssignFunction } from '../../../../labelingTypes';

interface Props {
  items: DimensionItemT[];
  isTemplateBuilder: boolean;
  onEdit: (name: string, item: DimensionItemT) => Promise<void>;
  onDelete: (item: DimensionItemT) => Promise<void>;
  setStagingFilter: React.Dispatch<React.SetStateAction<FilterList>>;
  stagingFilter: FilterList;
  isListView: boolean;
  gridRef: RefObject<AgGridReact>;
  group: string;
  canEdit: (d: DimensionItemT) => boolean;
  canDelete: (d: DimensionItemT) => boolean;
  isLabeling: boolean;
  assignLabels: AssignFunction;
}

const DimensionGroup = ({ items,
  isTemplateBuilder,
  onEdit,
  onDelete,
  setStagingFilter,
  stagingFilter,
  isListView,
  gridRef,
  group,
  canEdit,
  canDelete,
  isLabeling,
  assignLabels
}: Props) => {
  const [ t ] = useTranslation('common');

  return (
    <React.Fragment key={ group }>
      { group && 
      <div className={ styles.groupTitle }>
        { group === 'null' ? t('other') : group }
      </div> 
      }
      <div className={ `${ styles.dimensionList } ${ isListView ? styles.column : '' }` }>
        { 
          items.map((dim) => {
            return (
              <DimensionItem
                onEdit={ name => onEdit(name, dim) }
                onDelete={ () => onDelete(dim) }
                setStagingFilter={ setStagingFilter }
                stagingFilter={ stagingFilter }
                item={ dim }
                key={ dim.id }
                canEdit={ canEdit(dim) }
                canDelete={ canDelete(dim) }
                gridRef={ gridRef }
                isTemplateBuilder={ isTemplateBuilder }
                isLabeling={ isLabeling }
                assignLabels={ assignLabels }
              />
            );
          }) }
      </div>
    </React.Fragment>
  );
};

export default DimensionGroup;
