import Card from 'components/elements/card/Card';
import React from 'react';
import { useAppSelector } from 'store/hooks/hooks';
import Contract from './Contract';
import styles from './ContractsData.module.scss';
import { useFinancialTable } from '../../../../context/FinancialTableContext';

const ContractsData = () => {

  const {
    state: {
      customSettings: { panelSettings: { customerId } }
    },
  } = useFinancialTable();

  const contracts = useAppSelector(state => state.breakdowns.dimensions.filter(
    dimension => dimension.relation === 'CONTRACT')[ 0 ]?.items.filter(
    dimensionItem => dimensionItem.contract.customer === customerId));

  return (
    <Card className={ styles.card }>
      {
        contracts != null && contracts.map((contract, index) => (
          <Contract
            key={ contract.id }
            item={ contract }
            showAddButton={ index === 0 }
          />
        ))
      }
    </Card>
  );
};

export default ContractsData;
