import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { Product } from 'types/revenueRecognition.types';
import { Counterparty } from 'types/statutory.types';
import { notifyError } from 'utils/notifications.utils';
import { AppDispatch, RootState } from './store';
import { getPeriodWithOffset } from '../utils/period.utils';
import { Cadence } from '../types/form.types';
import { Period } from '../types/financials.types';
import organizationsService from '../services/organizations.service';

interface RevenueRecognitionTableState {
  period: Period;
}

const getDefaultTableState = (): RevenueRecognitionTableState => {
  return {
    period: getPeriodWithOffset(Cadence.month, 11, 11),
  };
};

export interface RevenueRecognitionStore {
  table: RevenueRecognitionTableState;
  products: {
    productsList: Product[];
    selectProductsList: Product[];
  };
  counterparty:{
    singleCounterparty: Counterparty;
  };
}

const initialState: RevenueRecognitionStore = {
  table: getDefaultTableState(),
  products: {
    productsList: [],
    selectProductsList: [],
  },
  counterparty:{
    singleCounterparty: undefined,
  },
};

export const revenueRecognitionSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products.productsList = action.payload;
    },
    setPeriod: (state, action: PayloadAction<Period>) => {
      state.table.period = action.payload;
    },
    setCounterparty: (state, action: PayloadAction<Counterparty>) => {
      state.counterparty.singleCounterparty = action.payload;
    },
    setSelectProduct: (state, action: PayloadAction<Product>) =>{
      state.products.selectProductsList.unshift(action.payload);
    },
    setSelectProducts: (state, action: PayloadAction<Product[]>) =>{
      state.products.selectProductsList = action.payload;
    },
    removeSelectedProduct: (sliceState, action: PayloadAction<Product>) => {
      const toRemove = action.payload;
      sliceState.products.selectProductsList = sliceState.products.selectProductsList.filter(
        st => {
          return !(st.id === toRemove.id);
        } );
    },
    editListProducts: (state, action: PayloadAction<Product>) =>{
      const editList = state.products.productsList.map(el => {
        if (el.id === action.payload.id) return { ...el, name: action.payload.name };
        return el ;
      });
      const selectList = state.products.selectProductsList.map(el => {
        if (el.id === action.payload.id) return { ...el, name: action.payload.name };
        return el;
      });

      state.products.productsList = editList;
      state.products.selectProductsList = selectList;
    },
    addProductToList: (state, action: PayloadAction<Product>) =>{
      state.products.productsList.push(action.payload);
    },
    removeProductFromList:(state, action: PayloadAction<Product>) =>{
      const filterList = state.products.productsList.filter(el => el.id !== action.payload.id);
      state.products.productsList = filterList;
    },
  }
});

export function fetchAndSetProductList(callback?: (data) => void) {
  return async dispatch => {
    const data = await organizationsService.getProducts();
    dispatch(actions.setProducts(data.data));
    if (callback) {
      callback(data.data);
    }
  };
}

export function deleteProductFromList( value ) {
  return async dispatch => {
    await organizationsService.removeProduct( value.id );
    dispatch(actions.removeProductFromList(value));
    dispatch(actions.removeSelectedProduct(value));
  };
}

export function fetchAndSetCounterparty( value ) {
  return async dispatch => {
    const data = await organizationsService.getCounterparty( value );
    dispatch(actions.setCounterparty(data.data));
  };
}

export function editListProduct(value) {
  return async dispatch => {
    organizationsService.editProduct(value).then(data => {
      dispatch(actions.editListProducts(data.data));
    }).catch(e => {
      if (e.response?.data.nonFieldErrors)
        notifyError(e.response.data.nonFieldErrors);
      else
        notifyError(
          t('notifications.unexpected-error.message',
            { ns: 'common' }
          )
        );
    });
  };
}

export function addProductList(name: string) {
  return async dispatch => {
    organizationsService.addProduct(name).then(data => {
      dispatch(actions.addProductToList(data.data));
    }).catch(e => {
      if (e.response?.data.nonFieldErrors)
        notifyError(e.response.data.nonFieldErrors);
      else
        notifyError(
          t('notifications.unexpected-error.message',
            { ns: 'common' }
          )
        );
    });
  };
}

export const resetCounterparty = () => (dispatch: AppDispatch) => {
  dispatch(actions.setCounterparty(undefined));
};

export const addSelectProduct = (data: Product) => (dispatch: AppDispatch) => {
  dispatch(actions.setSelectProduct(data));
};

export const selectAllProduct = (data: Product[]) => (dispatch: AppDispatch) => {
  dispatch(actions.setSelectProducts(data));
};

export const removeSelectProduct = (data: Product) => (dispatch: AppDispatch) => {
  dispatch(actions.removeSelectedProduct(data));
};

export const deleteProduct = (data: Product) => (dispatch: AppDispatch) => {
  dispatch(actions.removeProductFromList(data));
};

const actions = revenueRecognitionSlice.actions;
export const revenueRecognitionReducer = revenueRecognitionSlice.reducer;

export const selectProduct = (state: RootState) =>
  state.revenueRecognition.products.productsList;

export const selectProductsList = (state: RootState) =>
  state.revenueRecognition.products.selectProductsList;

export const selectSingleCounterparty = (state: RootState) =>
  state.revenueRecognition.counterparty.singleCounterparty;
