import { BudgetItemAiCompletion } from './planning.types';
import { UUID } from 'types/templates.types';
import { ButtonType } from 'antd/lib/button/button';

export enum MessageSource {
  USER = 'user',
  SYSTEM = 'system',
}

export enum MessageType {
  PROMPT = 'prompt',
  MESSAGE = 'message',
  QUESTION = 'question',
  ERROR = 'error',
  SUCCESS = 'success',
  LOADER = 'loader',
}

export interface UserMessage {
  source: MessageSource.USER;
  type: MessageType.PROMPT;
}

export interface SystemMessage {
  source: MessageSource.SYSTEM;
  type:
  | MessageType.MESSAGE
  | MessageType.ERROR
  | MessageType.SUCCESS
  | MessageType.QUESTION
  | MessageType.LOADER;
}

export type Message = {
  id: UUID;
  text: string;
} & (UserMessage | SystemMessage);

export type MessageActionKey = 'consent' | 'declineConsent';

export type MessageAction = {
  id: UUID;
  text: string;
  type: ButtonType;
  action: MessageActionKey;
};

export type InteractionMessage = {
  actions: MessageAction[];
} & Message;

export interface ChatStore {
  sessionId: UUID;
  history: Message[];
  tasks: ChatTask[];
  isConsentGiven: boolean;
}

export enum ChatTaskType {
  BUDGET_ITEM_COMPLETION = 'BUDGET_ITEM_COMPLETION',
}

type ChatTaskPayload<T extends ChatTaskType, P extends Object> = {
  type: T;
  payload: P;
};

export type BudgetItemCompletionTask =
  ChatTaskPayload<ChatTaskType.BUDGET_ITEM_COMPLETION, BudgetItemAiCompletion>;

export type ChatTask = {
  id: UUID;
  type: ChatTaskType;
  payload: unknown;
  canProceed?: boolean;
} & BudgetItemCompletionTask;
