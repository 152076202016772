/* eslint-disable camelcase */
import { Cadence } from 'types/form.types';
import { PaginatedResponse } from 'types/app.types';
import { endpoints } from 'utils/config.utils';
import { Dashboard, DashboardDetail, DashboardElement } from 'types/dashboard.types';
import http from 'utils/http.utils';

interface IDateParams {
  startDate: string;
  endDate: string;
  cadence: Cadence;
}

const getDashboardParams = (params: IDateParams) => ({
  start_date: params.startDate,
  end_date: params.endDate,
  cadence: params.cadence
});

const getDashboards = () =>
  http.get<PaginatedResponse<Dashboard>>(endpoints.dashboard.index);

const getDashboard = (id: number, params: IDateParams) =>
  http.get<DashboardDetail>(
    endpoints.dashboard.detail.replace('{id}', id.toString()),
    { params: getDashboardParams(params) }
  );

const updateDashboard = (
  id: number, elements: DashboardElement[], params: IDateParams, signal: AbortSignal
) =>
  http.put<DashboardDetail>(
    endpoints.dashboard.detail.replace('{id}', id.toString()),
    { elements },
    { params, signal },
  );

const changeDashboardName = (id: number, name: string) =>
  http.patch<DashboardDetail>(endpoints.dashboard.detail.replace('{id}', id.toString()),
    { name }
  );

const getElement = (id: number, params: IDateParams, signal: AbortSignal) =>
  http.get<DashboardElement>(
    endpoints.dashboard.elementDetail.replace('{id}', id.toString()),
    {
      params: getDashboardParams(params),
      signal
    }
  );

const getElementPreview = (data: DashboardElement, params: IDateParams & { dashboard: number }) =>
  http.post<DashboardElement>(endpoints.dashboard.preview, data, {
    params: getDashboardParams(params)
  });

const addDashboard = (name: string) =>
  http.post<Dashboard>(endpoints.dashboard.dashboards, { name });

const dashboardService = {
  getDashboards,
  getDashboard,
  updateDashboard,
  getElement,
  getElementPreview,
  addDashboard,
  changeDashboardName
};

export default dashboardService;
