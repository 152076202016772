import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Input as AntdInput } from 'antd';
import { ReactComponent as EyeOnIcon } from 'assets/icons/visibility.svg';
import { ReactComponent as EyeOffIcon } from 'assets/icons/visibility-off.svg';

import styles from './Input.module.scss';

type Props = {
  name: string;
  defaultValue?: string;
  placeholder?: string;
  label?: string;
  type?: string;
  rules?: {};
  errors: FieldErrors;
  control: Control;
  className?: string;
  allowClear?: boolean;
};

const Input = ({
  name,
  defaultValue = '',
  placeholder,
  label,
  type,
  rules,
  errors,
  control,
  className = '',
  allowClear = true
}: Props) => {
  const config = {
    allowClear,
    placeholder: placeholder,
    className: `${ styles.input } ${ className }`,
    size: 'large'
  };

  const getInput = ({ field }) => type === 'password' ? (
    <AntdInput.Password
      iconRender={ visible =>
        visible ? <EyeOnIcon className={ styles.icon } /> : <EyeOffIcon className={ styles.icon } />
      }
      { ...config }
      { ...field }
    />
  ) : (
    <AntdInput
      { ...config }
      { ...field }
    />
  );

  return (
    <div className={ `${ styles.inputWrapper } ${
      errors[ name ] ? styles.error : '' }`
    }>
      <label className={ styles.label } htmlFor={ name }>
        { label }
      </label>
      <Controller
        name={ name }
        control={ control }
        defaultValue={ defaultValue }
        rules={ rules }
        render={ getInput }
      />
    </div>
  );
};

export default Input;
