import React, { useState } from 'react';
import { Dropdown } from 'antd';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import DropdownContent from './DropdownContent';
import { useFinancialTable } from 'context/FinancialTableContext';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import { useAppSelector } from 'store/hooks/hooks';
import { selectPeriod } from 'store/financials.slice';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { useTranslation } from 'react-i18next';

const DynamicColumnDropdown = () => {
  const [ t ] = useTranslation('financials');
  const [ visible, setVisible ] = useState(false);
  const { state: { templateId } } = useFinancialTable();
  const period = useAppSelector(selectPeriod(templateId));
  const { data: report } = useReportQuery({ templateId, period });

  if (templateId < 0) return null;
  return (
    <Dropdown
      trigger={ [ 'click' ] }
      onOpenChange={ setVisible }
      dropdownRender={
        () => <DropdownContent
          currentSettings={ report?.columnSettings }
          visible={ visible }
          onClose={ () => setVisible(false) }
        />
      }
      disabled={ !report?.columnSettings }
      open={ visible }
    >
      <GhostIconButton tooltip={ t('table.column-settings') } active={ visible }>
        <ListIcon width={ 20 } height={ 20 }/>
      </GhostIconButton>
    </Dropdown>
  );
};

export default DynamicColumnDropdown;