import { Dropdown, Radio, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import styles from './DropdownFormat.module.scss';
import { ReactComponent as ArrowIcon } from 'assets/icons/chevron-down.svg';
import {
  changeCustomFormulaFormat,
  selectCalculateDynamicTotals,
  selectCalculateRowTotals,
  selectCustomFormulaFormat,
  selectReverseChangesFormatting,
  toggleCalculateDynamicTotals,
  toggleReverseChangesFormatting,
  toggleRowTotals
} from 'store/formula.slice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/hooks';
import CalculateDynamicTotalsToggle
  from '../calculateDynamicTotalsToggle/CalculateDynamicTotalsToggle';

const optionsFormat = [
  { label: 'Nominal', value: 'NOMINAL' },
  { label: '%', value: 'PERCENTAGE' },
];

const optionsDecimals = [
  { label: '0.', value: '0' },
  { label: '0.0', value: '1' },
  { label: '0.00', value: '2' },
];

const NOMINAL = 'NOMINAL';

const DropdownFormat = () => {
  const [ t ] = useTranslation('financials');
  const formatState = useAppSelector(selectCustomFormulaFormat);
  const dispatch = useAppDispatch();

  const dynamicTotalsValue = useAppSelector(selectCalculateDynamicTotals);
  const rowTotalsValue = useAppSelector(selectCalculateRowTotals);
  const reverseChangesFormatting = useAppSelector(selectReverseChangesFormatting);
  const onDynamicColumnsChange = useCallback(() => {
    dispatch(toggleCalculateDynamicTotals());
  }, []);

  const onRowTotalsChange = useCallback(() => {
    dispatch(toggleRowTotals());
  }, []);

  const onReverseChangesFormattingChange = useCallback(() => {
    dispatch(toggleReverseChangesFormatting());
  }, []);

  const menu = (
    <div className={ styles.menu }>
      <div className={ styles.option }>
        <span className={ styles.title }>{ t('templates.format') }</span>
        <Radio.Group
          options={ optionsFormat }
          optionType='button'
          buttonStyle='solid'
          onChange={ (e) =>
            dispatch(changeCustomFormulaFormat({ ...formatState, formatting: e.target.value }))
          }
          value={ formatState.formatting }
        />
      </div>
      <div className={ styles.option }>
        <span className={ styles.title }>{ t('templates.decimals') }</span>
        <Radio.Group
          options={ optionsDecimals }
          optionType='button'
          buttonStyle='solid'
          value={ formatState.decimals.toString() }
          onChange={ (e) =>
            dispatch(changeCustomFormulaFormat({ ...formatState, decimals: e.target.value }))
          }
        />
      </div>
      <div className={ styles.option }>
        <CalculateDynamicTotalsToggle
          value={ !dynamicTotalsValue }
          onChange={ onDynamicColumnsChange }
          labelText={ t('templates.formulas.calculateDynamicTotals') }
        />
      </div>
      <div className={ styles.option }>
        <CalculateDynamicTotalsToggle
          value={ rowTotalsValue }
          onChange={ onRowTotalsChange }
          labelText={ t('templates.formulas.calculateRowTotals') }
        />
      </div>
      <div className={ styles.option }>
        <CalculateDynamicTotalsToggle
          value={ reverseChangesFormatting }
          onChange={ onReverseChangesFormattingChange }
          labelText={ t('templates.formulas.reverseChangesFormatting') }
        />
      </div>
    </div>
  );

  const [ visibleMenu, setVisibleMenu ] = useState(false);

  return (

    <Dropdown
      placement='bottomRight'
      open={ visibleMenu }
      onOpenChange={ () => setVisibleMenu(prev => !prev) }
      trigger={ [ 'click' ] }
      dropdownRender={ () => menu }>
      <Space className={ styles.tag }>
        { `${ t('templates.format') }: ` }
        <span className={ styles.formatValue }>
          { ` ${ optionsDecimals[ formatState.decimals ].label }
        ${ formatState.formatting !== NOMINAL ? optionsFormat[ 1 ].label : '' } ` }
        </span>
        <ArrowIcon
          width={ 12 }
          height={ 13 }
          className={ `${ styles.arrowIcon } ${ visibleMenu ? styles.rotate : '' }` }/>
      </Space>
    </Dropdown>
  );
};

export default DropdownFormat;
