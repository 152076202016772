import { AgGridReact } from 'ag-grid-react';
import { useFinancialTable } from 'context/FinancialTableContext';
import { RefObject, useCallback, useMemo, useState } from 'react';
import { RowType } from 'types/financials.types';
import { Cadence } from 'types/form.types';
import { useAppSelector } from 'store/hooks/hooks';
import { IRowNode } from 'ag-grid-community';

const MAXIMUM_TRANSACTIONS_OPENED = 100;
const DEFAULT_TABLE_HEIGHT = 90;
const HEADER_ROW_HEIGHT = 34;
const EMPTY_TABLE_HEIGHT = 240;
const TABLE_BOTTOM_PADDING = 20;

interface Props {
  gridRef: RefObject<AgGridReact>;
  disabled: boolean;
}

const useAdjustHeight = ({ gridRef, disabled }: Props) => {
  const { state: { templateId } } = useFinancialTable();
  const table = useAppSelector(state => state.financials.tables[ templateId ]);
  if (disabled) {
    return {
      tableHeight: 0,
      getTableHeight: () => null
    };
  }

  const headerHeight = useMemo(() => {
    const cadenceIndex = Object.values(Cadence).reverse().indexOf(table?.period?.cadence);
    if (cadenceIndex === -1) {
      return DEFAULT_TABLE_HEIGHT;
    }
    const cadenceMultiplier = table.period.cadence === Cadence.year ? 1 : 2;

    return cadenceMultiplier * HEADER_ROW_HEIGHT;
  }, [ table.period ]);

  const [ tableHeight, setTableHeight ] = useState(EMPTY_TABLE_HEIGHT);

  const getTableHeight = useCallback((isSticky: boolean) => {
    let transactionRowCount = 0;
    let rowSumHeight = 0;
    const shownNodes: IRowNode[] = [];

    gridRef?.current?.api?.getModel().forEachNode((node) => {
      if (node.displayed) {
        shownNodes.push(node);
      }
    });

    for (const node of shownNodes) {
      const isTooManyTransactions = transactionRowCount > MAXIMUM_TRANSACTIONS_OPENED;
      const isTransaction = !node.data ||
        node.data.type === RowType.TRANSACTION ||
        node.data?.hasTransactions;

      isTransaction && transactionRowCount++;
      if (isTransaction && isTooManyTransactions) {
        break;
      }
      rowSumHeight += node.rowHeight | 0;
    }
    gridRef?.current?.api?.setDomLayout('normal');
    if (rowSumHeight <= 0) {
      return setTableHeight(EMPTY_TABLE_HEIGHT);
    }
    const stickyOffset = !isSticky ? 0 : headerHeight;
    setTableHeight(rowSumHeight + TABLE_BOTTOM_PADDING + headerHeight + stickyOffset);
  }, [ gridRef, headerHeight ]);

  return { tableHeight, getTableHeight };
};

export default useAdjustHeight;
