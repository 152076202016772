import React, { useState } from 'react';
import styles from '../Integrations.module.scss';
import NetvisorLogo from 'assets/logos/netvisor.png';
import Button from 'components/elements/button/Button';
import { useTranslation } from 'react-i18next';
import NetvisorIntegrationModal from
  'components/settings/integrations/netvisor/modal/NetvisorIntegrationModal';
import IntegrationItem from 'components/settings/integrations/item/IntegrationItem';
import { Integration } from 'types/settings.types';

interface Props {
  integration: Integration;
  disabled: boolean;
}

const NetvisorIntegration = ({ integration, disabled }: Props) => {
  const [ t ] = useTranslation('settings');
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const canIntegrate = (!integration || integration.lastParsedStatus === 'fail') && !disabled;

  return <>
    <IntegrationItem logo={ NetvisorLogo } integration={ integration } disabled={ disabled }>
      {
        canIntegrate ? <Button
          name={ t('integrate') }
          onClick={ () => setIsModalVisible(true) }
          className={ styles.button }
        /> : null
      }
    </IntegrationItem>
    <NetvisorIntegrationModal
      integration={ integration }
      isVisible={ isModalVisible }
      onClose={ () => setIsModalVisible(false) }
    />
  </>;
};

export default NetvisorIntegration;
