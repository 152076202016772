import React from 'react';
import { Integration, SyncState } from 'types/settings.types';
import { ReactComponent as CheckMark } from 'assets/icons/check-circle.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import styles from './IntegrationStatus.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  integration: Integration;
}

const IntegrationStatus = ({ integration }: Props) => {

  const [ t ] = useTranslation('settings');

  if (!integration) {
    return null;
  }
  if (integration.initialSyncState === SyncState.IN_PROGRESS) {
    return <div className={ styles.inProgress }>
      <span>{ t('integration-in-progress') }</span>
    </div>;
  }
  if (integration.lastParsedStatus === 'success') {
    return <CheckMark className={ styles.icon }/>;
  }
  if (integration.lastParsedStatus === 'fail') {
    return <Warning className={ styles.icon }/>;
  }

  return null;
};

export default IntegrationStatus;
