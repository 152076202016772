import { Switch } from 'antd';
import React from 'react';
import styles from './CalculateDynamicTotalsToggle.module.scss';

const CalculateDynamicTotalsToggle = ({ value, onChange, labelText }) => {
  
  return (
    <div className={ styles.container }>
      <span className={ styles.text }>
        { labelText }
      </span>
      <Switch
        size='small'
        checked={ value }
        onChange={ onChange }
      />
    </div>
  );
};

export default CalculateDynamicTotalsToggle;
