import React, { PropsWithChildren } from 'react';
import { SearchableContextProvider } from 'context/SearchableContext';
import Portal from 'components/elements/portal/Portal';
import {
  LEFT_PANEL_PORTAL_ID,
  RIGHT_PANEL_PORTAL_ID
} from 'components/panels/utils/panels.utils';

interface Props {
  isActive: boolean;
}

interface PropsWithType extends Props {
  isActive: boolean;
  type: 'left' | 'right';
  customPortalId?: never;
}
interface PropsWithCustomPortalId extends Props {
  isActive: boolean;
  customPortalId: string;
  type?: never;
}

const PanelWrapper = (props: PropsWithChildren<PropsWithType | PropsWithCustomPortalId>) => {
  if (!props.isActive) return null;
  let portalId = '';
  if (propsWithType(props)) {
    portalId = props.type === 'left' ? LEFT_PANEL_PORTAL_ID : RIGHT_PANEL_PORTAL_ID;
  } else {
    portalId = props.customPortalId;
  }

  return (
    <Portal portalId={ portalId }>
      <SearchableContextProvider>
        { props.children }
      </SearchableContextProvider>
    </Portal>
  );
};

export default PanelWrapper;

function propsWithType (props: Props): props is PropsWithType {
  return (props as PropsWithType).type !== undefined;
}
