import React, { HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import Tooltip from 'components/elements/tooltip/Tooltip';
import { useLongPress } from 'use-long-press';
import { getTemplateStyle, tagNamesComparator } from 'utils/templates.utils';
import { batchCreateNode, createNode, selectTemplate } from 'store/template.slice';
import {
  selectExpandedFinancialTag,
  setExpandedNode,
  setHoverDimension,
} from 'store/templates.slice';
import { maximumGroupTagLevel, maximumTagsExpanded } from 'utils/tags.utils';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './DragTag.module.scss';
import { getDisplayName, isDisplayNameEmpty } from 'utils/common.utils';
import {
  getNodeName,
  getTemplateTagFormulaType,
  isDimensionGroup,
  isDimensionType
} from 'utils/template.utils';
import { selectAltKey, toggleAltKey } from 'store/events.slice';
import HighlightedText from 'components/elements/highlightedText/HighlightedText';
import { RowType } from 'types/financials.types';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import useFormula from 'hooks/useFormula';
import { useSearchable } from 'context/SearchableContext';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { isFinancialsNode, TemplateNode } from 'types/templates.types';
import { canDropOnFormulaFromBottomPanel }
  from 'components/templates/customTemplate/formulaBuilder/utils/formulaBuilder.utils';
import { selectCustomFormula } from 'store/formula.slice';

type Props = HTMLAttributes<HTMLDivElement> & {
  item: TemplateNode;
  level?: number;
  isLastChild?: boolean;
  maxGroupTagLevel?: number;
  section: string;
};

const DragTag = ({
  item,
  level = 1,
  isLastChild = false,
  maxGroupTagLevel = maximumGroupTagLevel(item),
  className = '',
  section,
}: Props) => {
  const dispatch = useAppDispatch();
  const childrenRef = useRef(null);
  const expandedNodeId = useAppSelector(selectExpandedFinancialTag);
  const altKey = useAppSelector(selectAltKey);
  const customFormula = useAppSelector(selectCustomFormula);
  const template = useAppSelector(selectTemplate);
  const { state: { search } } = useSearchable();
  const { addElement } = useFormula();
  const dimensions = useAppSelector(state => state.breakdowns.dimensions);

  const childrenNodes = useMemo(() => item?.childrenNodes, [ item ]);

  const isDimensionsGroupSection = useMemo(() => {
    return item.type === RowType.BREAKDOWN || item.type === RowType.BREAKDOWN_GROUP;
  }, [ item ])
  ;

  const [ maxExpandedChildren, setMaxExpandedNodes ] = useState(
    isDimensionsGroupSection ? 25 : childrenNodes?.length);
  const [ areChildrenExpanded, setChildrenExpanded ] = useState(true);
  const [ isTagActive, setIsTagActive ] = useState(false);
  const longPressBind = useLongPress(() => setIsTagActive(true), {
    onCancel: () => setIsTagActive(false),
    onFinish: () => setIsTagActive(false),
    threshold: 75,
  });

  const customFormulaIsOpen = useMemo(() => customFormula !== null, [ customFormula ]);

  const getParentContainer = (triggerNode: HTMLElement) => {
    return triggerNode.parentElement.parentElement.parentElement;
  };

  useEffect(() => {
    if (isDimensionsGroupSection)
      setChildrenExpanded(childrenNodes?.length <= maxExpandedChildren);
    else setChildrenExpanded(expandedNodeId === item.id || level !== maxGroupTagLevel - 1);
  }, [ expandedNodeId ]);

  useEffect(() => {
    if (childrenRef && level === maxGroupTagLevel - 1) {
      setMaxExpandedNodes(maximumTagsExpanded(childrenRef?.current?.children));
    }
  }, [ childrenRef ]);

  const dragSingleItem = useMemo(
    () => !isDimensionGroup(item) && (altKey || (isDimensionType(item))),
    [ altKey, item ],
  );

  const isActiveTemplate = useMemo(() => {
    return template.title !== '';
  }, [ template ]);

  const [ { isDragging }, drag ] = useDrag(() => {
    return {
      type: 'tag',
      item: dragSingleItem ? { ...item, children: [], childrenNodes: [] } : item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    };
  }, [ dragSingleItem ]);

  const handleDoubleClick = () => {
    if (customFormulaIsOpen && canDropOnFormulaFromBottomPanel(item)) {
      addElement(
        item,
        getTemplateTagFormulaType(item)
      );
    } else {
      const mappedItem = dragSingleItem ?
        { ...item, children: [], childrenNodes: [] } : item;
      if (isActiveTemplate && !customFormulaIsOpen) {
        if (isDimensionGroup(item)) {
          dispatch(batchCreateNode(mappedItem, null, 'none'));
        }
        dispatch(createNode(mappedItem, null, 'none'));
      }
    }
  };

  const isChildHidden = (childIndex: number,) => {
    return !areChildrenExpanded && childIndex >= maxExpandedChildren;
  };

  const clearExpandedNode = () => {
    if (expandedNodeId > 0) {
      dispatch(setExpandedNode(0));
      if (isDimensionsGroupSection)
        setMaxExpandedNodes(25);
    }
  };

  const showExpandChildrenButton = () => {
    if (isDimensionsGroupSection) {
      return (
        childrenNodes?.length > maxExpandedChildren &&
        level === maxGroupTagLevel - 1
      );
    } else return (
      childrenNodes?.length > maxExpandedChildren &&
      level === maxGroupTagLevel - 1 &&
      expandedNodeId !== item?.id
    );
  };

  const displayName = useMemo(() => {
    if (!item) {
      return '';
    }
    return getNodeName(item, dimensions);
  }, [ item, dimensions ]);

  const isTooltipVisible = () => {
    if (isFinancialsNode(item) && !isDisplayNameEmpty(item.rowData?.shortName)) {
      return getDisplayName(
        item.rowData?.shortName).toLowerCase() !== getDisplayName(displayName).toLowerCase();
    }
    return false;

  };

  const onMouseEnterDragTag = useCallback((event) => {
    dispatch(toggleAltKey(event.altKey));

    if (section === 'dimensions' && level === 3 && !isDragging) {
      dispatch(setHoverDimension([ item?.id ]));
    } else if (isDragging)
      dispatch(setHoverDimension(null));

  }, []);

  const onMouseLeaveDragTag = () => {

    if ((section === 'dimensions' && level === 3) || isDragging) {
      dispatch(setHoverDimension(null));
    }
  };
  const disabledDragOnFormula = useMemo(() => {
    return customFormulaIsOpen && !canDropOnFormulaFromBottomPanel(item);
  }, [ item, customFormulaIsOpen ]);

  const refForDragElementGroup = () => {
    if (!dragSingleItem) {
      if (disabledDragOnFormula) {
        return null;
      }
      return drag;
    }
    return null;
  };

  const refForDragElementSingle = () => {
    if (dragSingleItem) {
      if (disabledDragOnFormula) {
        return null;
      }
      return drag;
    }
    return null;
  };

  return (
    <div
      className={ `${ childrenNodes?.length > 0 ? styles.dragContainer : styles.tagContainer }
    ${ isLastChild && childrenNodes?.length > 0 ? styles.lastChildGroup : '' }
    ${ section === 'dimensions' ? styles.dimension : '' }` }
      ref={ refForDragElementGroup() }
    >
      <Tooltip
        title={ isTooltipVisible() && displayName ? getDisplayName(displayName) : null }
        getPopupContainer={ getParentContainer }
        mouseEnterDelay={ 0.2 }
      >
        <div
          { ...longPressBind }
          role='tag'
          ref={ refForDragElementSingle() }
          onDoubleClick={ handleDoubleClick }
          onMouseEnter={ onMouseEnterDragTag }
          onMouseLeave={ onMouseLeaveDragTag }
          className={ `${ styles.tag }
          ${ section === 'dimensions' ? styles.dimension : '' }
        ${ !childrenNodes || childrenNodes?.length === 0 ? styles.leaf : '' }
        ${ level === 1 ? styles.root : '' }
        ${ isDragging ? styles.isDragging : '' }
        ${ isTagActive ? styles.tagActive : '' }
        ${ item?.type === RowType.BREAKDOWN ? styles.dimensionTag : '' }
        ${ (item?.type === RowType.BREAKDOWN &&
            section === 'dimensions') ? styles.dimensionTitle : '' }
        ${ search !== '' && tagNamesComparator(item, search) ? styles.tagSearched : '' }
        ${ className }
        ${ (disabledDragOnFormula ? styles.inactiveDrag : '') }
        ` }
          style={ getTemplateStyle(item) }
        >
          <HighlightedText
            text={ getDisplayName(displayName) }
            highlight={ search }
          />
          {
            section === 'dimensions' &&
              <Chevron width={ 12 } height={ 12 } className={ styles.arrow }/>
          }
        </div>
      </Tooltip>
      { childrenNodes?.length > 0 && (
        <div
          className={ `${ styles.children }
          ${ isDragging ? styles.isDragging : '' }` }
          style={ {
            paddingLeft: section !== 'dimensions'
              ? `${ level * 25 }px`
              : `${ level === 2 ? `${ level * 10 }px` : '' }`
          } }
          ref={ childrenRef }
        >
          {
            section === 'dimensions' && level === 2 && (
              <div className={ styles.dragIcons }>
                <DragIcon/>
              </div>
            )
          }
          {
            section !== 'dimensions' && childrenNodes?.map((child, index) => (
              <DragTag
                section={ section }
                key={ child.id || getDisplayName(child.rowData.name) }
                item={ child }
                level={ level + 1 }
                maxGroupTagLevel={ maxGroupTagLevel }
                isLastChild={ index === childrenNodes?.length - 1 }
                className={ isChildHidden(index)
                  ? styles.tagHidden : styles.tagShow }
              />
            ))
          }
          <OutsideClickHandler onOutsideClick={ clearExpandedNode }>
            { showExpandChildrenButton() && section !== 'dimensions' && (
              <div
                className={ `${ styles.tag } ${ styles.leaf }
                ${ section === 'dimensions' ? styles.dimension : '' }
            ${ isDragging ? styles.tagExpandDragging : styles.tagExpand }` }
                onClick={ () => {
                  dispatch(setExpandedNode(item?.id));
                  if (isDimensionsGroupSection) {
                    setMaxExpandedNodes(prev => prev + 25);
                  }
                } }
              >
                { `+${ isDimensionsGroupSection
                  ? childrenNodes.length - maxExpandedChildren > 25
                    ? 25 : childrenNodes.length - maxExpandedChildren
                  : childrenNodes.length - maxExpandedChildren }` }
              </div>
            ) }
          </OutsideClickHandler>
        </div>
      ) }
    </div>
  );
};

export default DragTag;
