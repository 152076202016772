import { UUID } from 'types/templates.types';
import { InvoicingFrequency } from './contracts.types';
import { DriversCategory } from './financials.types';

export type PlanSettings = {
  [DriversCategory.GENERAL]: GeneralSettings;
};
export const PLAN_SETTINGS_KEYS: Array<keyof PlanSettings> = [
  DriversCategory.GENERAL,
];

export type PlanSettingsValues = {
  [DriversCategory.GENERAL]: PlanSettingGeneral;
};

export type Parameter<T> = {
  value: T;
  name: string;
  description?: string;
  group?: string;
  displayGroupTitle?: boolean;
};

export type PlanSettingsResponse = {
  organization: number;
  categories: PlanSettingsValues;
};

export type PlanSettingRequest = Omit<PlanSettingsResponse, 'organization'>;

type ValuesToSettings<T> = {
  [K in keyof T]: Parameter<T[K]>;
};

export type PlanSettingGeneral = {
  planStart: string;
  planDurationMonths: number;
};

export type GeneralSettings =
  ValuesToSettings<Pick<PlanSettingGeneral, 'planStart' | 'planDurationMonths'>>;

export interface BudgetItemCompletionMetadata {
  status: 'missing_data' | 'complete';
  message: string;
}

export interface BudgetItemAiCompletion {
  sessionUuid: UUID;
  budgetItemData: {
    budgetItemType: number;
    amountFormula: string;
    counterparty: number;
    memo: string | null;
    startDate: string | null;
    endDate: string | null;
    invoicingDay: number | null;
    recurrence: boolean | null;
    frequency: InvoicingFrequency | null;
    paymentTerm: string | null;
    metadata: BudgetItemCompletionMetadata;
  };
}
