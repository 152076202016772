import { MutableRefObject, useCallback, useEffect } from 'react';

interface Args {
  ref: MutableRefObject<HTMLElement>;
  cb: (event: MouseEvent) => void;
  enabled?: boolean;
}

const useOutsideClickListener = ({ ref, cb, enabled = true }: Args) => {
  const onClickOutside = useCallback((e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      cb(e);
    } 
  }, [ cb ]);

  useEffect(() => {
    if (ref.current && enabled) {
      document.addEventListener('mousedown', onClickOutside);
    }

    return () =>{
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, [ enabled, onClickOutside ]);
};

export default useOutsideClickListener;
