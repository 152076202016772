import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomersTable from 'components/revenueRecognition/customersTable/CustomersTable';
import styles from './RevenueRecognition.module.scss';
import HeaderBar from './headerBar/HeaderBar';
import { RevenueRecognitionProvider } from 'context/RevenueRecognitionContext';
import ProgressBar from './progressBar/ProgressBar';
import { SearchableContextProvider } from 'context/SearchableContext';
import OrganizationSelect from '../elements/organizationSelect/OrganizationSelect';

const RevenueRecognition = () => {
  const [ t ] = useTranslation('revenueRecognition');

  const header = t('links.manage.revenue-recognition', { ns: 'navigation' });
  return (
    <RevenueRecognitionProvider>
      <SearchableContextProvider>
        <div className={ styles.revenueRecognition }>
          <h1 className={ styles.header }>
            <OrganizationSelect />
            { header }
          </h1>
          <HeaderBar />
          <ProgressBar />
          <CustomersTable />
        </div>
      </SearchableContextProvider>
    </RevenueRecognitionProvider>
  );
};

export default RevenueRecognition;
