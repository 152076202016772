import React, { PropsWithChildren, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';

import { TableColDef } from 'components/elements/tableWrapper/types/table.types';
import DatePickerCellEditor from './DatePickerCellEditor';
import { DimensionCellEditor } from './DimensionCellEditor';
import NumericCellEditor from './NumericCellEditor';
import TextCellEditor from './TextCellEditor';
import SelectCellEditor from './SelectCellEditor';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { CSS } from '@dnd-kit/utilities';

import styles from './Editors.module.scss';
import { useSortable } from '@dnd-kit/sortable';
import Button from 'components/elements/button/Button';
import clsx from 'clsx';
import AccountCellEditor from './AccountCellEditor';
import { Account } from '../../../../../types/statutory.types';
import { InputRow } from 'components/inputs/types/inputs.types';
import { getDisplayName } from '../../../../../utils/common.utils';

interface FormConfig {
  disabled?: boolean;
  message?: string;
  messageType?: 'warning' | 'error' | 'info';
}

interface Props {
  field: TableColDef;
  fieldName: string;
  inputs: InputRow[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  formConfig: Record<string, FormConfig>;
  remove: () => void;
  prefixIcon?: ReactNode;
  placeholder?: string;
}

const FieldProxy = ({
  field,
  fieldName,
  inputs,
  control,
  prefixIcon,
  remove,
  formConfig,
  placeholder
}: Props) => {
  const getEditor = () => {
    switch (field?.cellEditor?.displayName) {
      case 'SelectCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <SelectCellEditor
                field={ fieldName }
                value={ value }
                disabled={ formConfig[ fieldName ]?.disabled }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
                setValue={ onChange }
                values={ field?.cellEditorParams?.values ?? [] } />
            </InputGroupWithIcon> }
        />;
      case 'AccountCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <AccountCellEditor
                field={ fieldName }
                value={ value }
                disabled={ formConfig[ fieldName ]?.disabled }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
                setValue={ onChange }
                formatValue={ (account: Account) => {
                  return `${ account.number } ${ getDisplayName(account.name) }`;
                } }
                values={ field?.cellEditorParams?.values ?? [] } />
            </InputGroupWithIcon>
          }
        />;
      case 'DatePickerCellEditor':
        return <Controller
          name={ fieldName }
          control={ control }
          render={ ({ field: { value, onChange } }) =>
            <InputGroupWithIcon
              disabled={ formConfig[ fieldName ]?.disabled }
              hasValue={ value }
              prefixIcon={ prefixIcon }
            >
              <DatePickerCellEditor
                value={ value }
                disabled={ formConfig[ fieldName ]?.disabled }
                setValue={ onChange }
                placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
                comparison={ field.cellEditorParams?.comparison ?? '' } 
                type={ field.cellEditorParams?.type ?? '' } 
                comparisonValue=''
                comparisonFieldName={ field.cellEditorParams?.comparison ?? '' }
              />
            </InputGroupWithIcon>
          }
        />;
      case 'NumericCellEditor': {
        let defaultValuePlaceholder = field?.cellEditorParams?.placeholder;
      
        if (fieldName.startsWith('input')) {
          const [ , inputKey ] = fieldName.split('_');
          const fieldInput = inputs.find(input => input.id === +inputKey);
          if (fieldInput?.defaultValue !== undefined) {
            defaultValuePlaceholder = fieldInput?.defaultValue;
          }
        }

        return <Controller
          name={ fieldName }
          control={ control }
          render={ ({ field: { value, onChange } }) =>
            <NumericCellEditor
              field={ fieldName }
              setValue={ onChange }
              value={ +value }
              placeholder={ placeholder ?? defaultValuePlaceholder }
              disabled={ formConfig[ fieldName ]?.disabled }
              headerName=''
              min={ field.cellEditorParams.max }
              max={ field.cellEditorParams.min }
              optional={ field.cellEditorParams.optional }
            />
          }
        />;
      }
      case 'DimensionCellEditor':
      case 'TextCellEditor':
      default:
        if (field.group === 'Dimensions') {
          return <Controller
            name={ fieldName }
            control={ control }
            render={ ({ field: { value, onChange } }) =>
              <DimensionCellEditor
                props={ {
                  value,
                  disabled: formConfig[ fieldName ]?.disabled,
                  ...field.cellEditorParams(),
                  placeholder: placeholder ?? field?.cellEditorParams?.placeholder
                } }
                onChangeDimensionItem={ onChange }
                canBeUnassigned={ false }
              />
            }
          />;
        }

        return <Controller
          name={ fieldName }
          control={ control }
          render={ ({ field: { value, onChange } }) =>
            <TextCellEditor
              field={ fieldName }
              value={ value }
              placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
              disabled={ formConfig[ fieldName ]?.disabled }
              headerName={ field?.headerName } 
              setValue={ onChange }
              maxLength={ 0 } />
          }
        />;

    }
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: fieldName });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  if (!field) {
    return null;
  }

  return <div
    { ...attributes }
    { ...listeners }
    ref={ setNodeRef }
    style={ { ...style } }
    className={ styles.fieldRow }>
    <label className={ styles.label }>
      <span className={ styles.labelText }>{ field?.headerName }</span>
      { getEditor() }
      <FormMessage
        message={ formConfig[ fieldName ]?.message }
        messageType={ formConfig[ fieldName ]?.messageType }
      />
    </label>
    <Button className={ styles.removeFieldButton } type='link' tabIndex={ -1 } onClick={ remove }>
      <CloseIcon className={ styles.removeFieldIcon } />
    </Button>
  </div>;
};

export default FieldProxy;

interface InputGroupWithIconProps {
  prefixIcon: ReactNode;
  className?: string;
  hasValue: boolean;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const InputGroupWithIcon = ({
  prefixIcon,
  hasValue,
  children,
  className,
  disabled
}: PropsWithChildren<InputGroupWithIconProps>
) => {
  return <div className={ clsx(
    styles.inputWithIconWrapper,
    {
      [ styles.withPrefix ]: !!prefixIcon,
      [ styles.placeholder ]: !hasValue || disabled
    },
    className
  ) }>
    { prefixIcon && <span className={ styles.prefixIcon }>
      { prefixIcon }
    </span> }
    { children }        
  </div>;
};

const FormMessage = ({ message, messageType }: Pick<FormConfig, 'message' | 'messageType'>) => {
  if (!message) return null;
  return <span className={ clsx(styles.formMessage, styles[ messageType ]) }>{ message }</span>;
};
