import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SearchableContextProvider, useSearchable } from 'context/SearchableContext';
import { ContentProps, DropdownData } from './types/sectionDropdown.types';
import { Dropdown, DropdownProps as AntdDropdownProps } from 'antd';
import Card from '../card/Card';
import TopBar from './topBar/TopBar';
import styles from './SectionDropdown.module.scss';
import { displayNameComparator } from 'utils/sorting.utils';
import { GroupedVirtuoso } from 'react-virtuoso';
import DropdownElement from 'components/elements/sectionDropdown/dropdownList/DropdownElement';
import NotFoundWrapper from 'components/wrappers/notFoundWrapper/NotFoundWrapper';

type DropdownProps<T extends DropdownData> = ContentProps<T> & AntdDropdownProps & {
  children: React.ReactNode;
};

const SectionDropdownContent = <T extends DropdownData>(props: ContentProps<T>) => {
  const { state: { search } } = useSearchable();
  const isMounted = useRef(false);
  const initialGroupKey = useMemo(() => {
    return props.defaultGroupKey ?? props.groups[ 0 ].key;
  }, []);
  const defaultGroupKey = useMemo(() => {
    return props.groups[ 0 ].key;
  }, [ props.groups ]);
  const [ groupKey, setGroupKey ] = useState<string>(initialGroupKey);
  const [ prevGroupKey, setPrevGroupKey ] = useState<string>(initialGroupKey);
  const group = useMemo(() => {
    return props.groups.find(_group => _group.key === groupKey);
  }, [ groupKey, props.groups ]);

  const sections = useMemo(() => {
    return group.sections.filter(section => !section.hidden);
  }, [ group ]);

  const getFilteredItems = useCallback((items: T[]) => {
    return items
      .filter((item) => item.title.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) => displayNameComparator(a.title, b.title));
  }, [ search ]);

  const filteredSections = useMemo(() => {
    return sections.map((section) => ({
      ...section,
      items: getFilteredItems(section.items),
    }));
  }, [ sections, getFilteredItems ]);

  const sectionCounts = useMemo(() => {
    return filteredSections.map(section => section.items.length);
  }, [ filteredSections ]);

  const items = useMemo(() => {
    return filteredSections.map(section => section.items).flat();
  }, [ filteredSections ]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }
    if (search === '') {
      setGroupKey(prevGroupKey);
    } else {
      setGroupKey(defaultGroupKey);
    }
  }, [ search ]);

  const onGroupChange = useCallback((_groupKey: string) => {
    setGroupKey(_groupKey);
    setPrevGroupKey(_groupKey);
  }, []);

  const groupContent = useCallback((index: number) => {
    const section = sections[ index ];
    return <div className={ styles.titleBar }>
      <h4>
        { section?.label }
      </h4>
    </div>;
  }, [ sections ]);

  const itemContent = useCallback((index: number, sectionIndex: number) => {
    const item = items[ index ];
    const _section = filteredSections[ sectionIndex ];

    return <DropdownElement
      item={ item }
      selectedGroup={ _section.key === 'selected' }
      rowHoverActions={ props.rowHoverActions }
      active={ props.isSelected(item.id) }
      onClick={ () => props.onSelect(item.id) }
      disabled={ props.isDisabled(item.id) }
      itemRenderer={ props.itemRenderer }
    />;
  }, [
    items,
    filteredSections,
    props.rowHoverActions,
    props.isSelected,
    props.onSelect,
    props.isDisabled,
    props.itemRenderer
  ]);

  return <Card className={ `${ styles.dropdown } ${ props.dropdownClassName ?? '' }` }>
    <TopBar
      activeGroupKey={ groupKey }
      setGroup={ onGroupChange }
      groups={ props.groups }
      total={ props.total }
      title={ props.title }
    />
    <NotFoundWrapper found={ items.length === 0 }>
      <div className={ `${ styles.listContainer } ${ props.listClassName ?? '' }` }>
        <GroupedVirtuoso
          style={ { height: 400 } }
          groupCounts={ sectionCounts }
          groupContent={ groupContent }
          itemContent={ itemContent }
        />
      </div>
    </NotFoundWrapper>
  </Card>;
};

const SectionDropdown = <T extends DropdownData>({
  children,
  isSelected,
  onSelect,
  isDisabled,
  rowHoverActions,
  groups,
  itemRenderer,
  total,
  defaultGroupKey,
  dropdownClassName,
  title,
  listClassName,
  ...rest
}: DropdownProps<T>) => {
  return <Dropdown
    getPopupContainer={ (trigger) => trigger.parentElement }
    trigger={ [ 'click' ] }
    destroyPopupOnHide={ true }
    dropdownRender={ () => <SectionDropdownContent
      isSelected={ isSelected }
      onSelect={ onSelect }
      isDisabled={ isDisabled }
      rowHoverActions={ rowHoverActions }
      groups={ groups }
      itemRenderer={ itemRenderer }
      total={ total }
      defaultGroupKey={ defaultGroupKey }
      dropdownClassName={ dropdownClassName }
      title={ title }
      listClassName={ listClassName }
    /> }
    { ...rest }
  >
    { children }
  </Dropdown>;
};

const SectionDropdownWrapper = <T extends DropdownData>({
  children,
  ...rest
}: DropdownProps<T>) => {
  return <SearchableContextProvider>
    <SectionDropdown { ...rest }>
      { children }
    </SectionDropdown>
  </SearchableContextProvider>;
};

export default memo(SectionDropdownWrapper);
