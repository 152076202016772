import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SourceBadge.module.scss';
import Tooltip from '../tooltip/Tooltip';

interface Props {
  source: 'custom' | 'default';
  className?: string;
}

const SourceBadge = ({ source, className }: Props) => {
  const [ t ] = useTranslation('common');

  const tooltipTitle = useMemo(() => {
    return {
      custom: t('source.tooltip.custom'),
      default: t('source.tooltip.default')
    }[ source ];
  }, [ source ]);

  const badgeText = useMemo(() => {
    return {
      custom: t('source.custom'),
      default: t('source.default')
    }[ source ];
  }, [ source ]);

  return <Tooltip title={ tooltipTitle }>
    <div className={ `${ styles.badge } ${ styles[ source ] } ${ className }` }>
      { badgeText }
    </div>
  </Tooltip>;
};

export default SourceBadge;
