import { useMemo } from 'react';
import { UUID } from 'types/templates.types';
import { isEqual } from 'lodash';
import { useImmer } from 'use-immer';
import { castDraft } from 'immer';

interface Props<T> {
  items: T[];
  isValid: (item: T) => boolean;
}

interface BaseItemProps {
  id: number;
  internalId?: UUID;
}

const useTrackChanges = <T extends BaseItemProps,>({ items, isValid }: Props<T>) => {
  const [ changes, setChanges ] = useImmer<T[]>([]);

  const getChanges = (prev: T[], current: T[]) => {
    return current.filter(newItem => {
      const itemIndex = prev.findIndex(prevItem => {
        if (newItem.internalId) {
          return prevItem.internalId === newItem.internalId;
        }
        return prevItem.id === newItem.id;
      });

      if (itemIndex == -1) {
        return true;
      } else {
        return !isEqual(prev[ itemIndex ], newItem);
      }
    });
  };

  const updateChanges = (newItems: T[]) => {
    setChanges(draft => {
      newItems.forEach(newItem => {
        const newItemDraft = castDraft(newItem);
        const index = draft.findIndex(draftItem => {
          if (newItem.internalId) {
            return draftItem.internalId === newItem.internalId;
          }
          return draftItem.id === newItem.id;
        });

        if (!isValid(newItem)) {
          if (index !== -1) {
            draft.splice(index, 1);
          }
        } else if (index !== -1) {
          draft[ index ] = newItemDraft;
        } else {
          draft.push(newItemDraft);
        }
      });
    });
  };

  const trackChanges = (newItems: T[]) => {
    const _changes = getChanges(items, newItems);
    updateChanges(_changes);
  };

  const remove = (item: number | UUID) => {
    setChanges(draft => {
      const index = draft.findIndex(draftItem => {
        if (typeof item === 'number') {
          return draftItem.id === item;
        }
        return draftItem.internalId === item;
      });

      if (index !== -1) {
        draft.splice(index, 1);
      }
    });
  };

  return useMemo(() => ({
    changes,
    clear: () => setChanges([]),
    trackChanges,
    remove
  }), [ changes ]);
};

export default useTrackChanges;
