import React, { createContext, useContext, useReducer } from 'react';

type TemplatesContextType = {
  editMode: boolean;
  contexMenuFormulaElement: boolean;
  dimensionDetails: number | null;
};

const defaultState: TemplatesContextType = {
  editMode: false,
  contexMenuFormulaElement: false,
  dimensionDetails: null
};

const TemplatesContext = createContext(undefined);

const templatesReducer = (state, action) => {
  switch (action.type) {
    case 'editMode': {
      return { ...state , editMode: action.payload };
    }
    case 'contexMenuFormulaElement': {
      return { ...state , contexMenuFormulaElement: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${ action.type }`);
    }
  }
};

const TemplatesContextProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(templatesReducer, defaultState);
  const value = { state, dispatch };

  return <TemplatesContext.Provider value={ value }>
    { children }
  </TemplatesContext.Provider>;
};

const useTemplatesContext = () => {
  const context = useContext(TemplatesContext);
  if (context === undefined) {
    throw new Error('useTemplateContext must be used within Provider');
  }
  return context;
};

export { TemplatesContextProvider, useTemplatesContext };
