import { ChartStyle, ChartSource } from './chart.types';
import { UUID } from './templates.types';
import { DataFormat } from '../components/charts/types/chart.types';

export type Dashboard = {
  id: number;
  name: string;
};

export type DashboardDetail = {
  id: number;
  elements: DashboardElement[];
  name: string;
};

export type DashboardElement = {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  name: string;
} & DashboardElementData;

export enum DashboardElementType {
  BIG_NUMBER = 'big_number',
  CHART = 'chart',
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  TABLE = 'table',
}

export type DashboardElementData =
  | {type: DashboardElementType.BIG_NUMBER; element: TemplateElement; value: number }
  | {type: DashboardElementType.CHART; element: ChartElement }
  | {type: DashboardElementType.TABLE; element: TableElement }
  | {type: DashboardElementType.TITLE }
  | {type: DashboardElementType.SUBTITLE };

type DashboardMapType<TElementType extends DashboardElementType> =
  DashboardElement & { type: TElementType };

export type DashboardElementMapping<TElementType extends DashboardElementType = undefined> =
  TElementType extends undefined ? DashboardElement : DashboardMapType<TElementType>;
export interface TemplateElement {
  template: number;
  templateNode: string;
  valueType: TemplateElementType;
}

export const ALL_STACKS = 0;
export const DEFAULT_STACKS = 5;
export interface DatasetSettings {
  active: boolean;
  templateNode: UUID | undefined;
  style: ChartStyle;
  axisPosition?: 'left' | 'right';
  datePickerSource: ChartSource;
  reverseValues: boolean;
  stacks: number;
}

export interface ChartElement {
  template: number;
  datasets: DatasetSettings[];
  cumulativeValues: boolean;
  legend: boolean;
  barLabels: boolean;
  stackedLabels: boolean;
  leftAxisFormat: DataFormat;
  rightAxisFormat: DataFormat;
}

export interface TableElement {
  template: number;
}

export enum TemplateElementType {
  CUMULATIVE = 'cumulative',
  LAST_PERIOD = 'last_period',
}

export enum DashboardModeType {
  VIEW = 'view',
  EDIT = 'edit',
}
