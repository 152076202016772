import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Options } from 'types/datePicker.types';

const useConfigOptions = () => {

  const [ t ] = useTranslation('financials');

  const radioListActuals: Options[] = [
    {
      title: t('datePicker.date-option.title.common'),
      key: 'common',
      radioList: [
        {
          name: t('datePicker.date-option.year-to-date'),
          id: 1,
          key: 'year-to-date',
          value: {
            from: dayjs().startOf('year'),
            to: dayjs().endOf('month')
          }
        },
        {
          name: t('datePicker.date-option.trailing-m', { value: 12 }),
          id: 2,
          key: '12-trailing-months',
          value: {
            from: dayjs().subtract(12, 'M').startOf('month'),
            to: dayjs().subtract(1, 'M').endOf('month')
          }
        },
        {
          name: t('datePicker.date-option.trailing-m', { value: 6 }),
          id: 3,
          key: '6-trailing-months',
          value: {
            from: dayjs().subtract(6, 'M').startOf('month'),
            to: dayjs().subtract(1, 'M').endOf('month')
          }
        }
      ]
    },
    {
      title: t('datePicker.date-option.title.current'),
      key: 'current',
      radioList:[
        {
          name: t('datePicker.date-option.calendar-year'),
          id: 4,
          key: 'calendar-year',
          value: {
            from: dayjs().startOf('year'),
            to: dayjs().endOf('year')
          }
        },
        {
          name: t('datePicker.date-option.half-year'),
          id: 5,
          key: 'half-year',
          value: {
            from: dayjs().startOf('year'),
            to: dayjs().startOf('year').add(5,'M').endOf('month') 
          }
        },
        {
          name: t('datePicker.date-option.quarter'),
          id: 6,
          key: 'quarter',
          value: {
            from: dayjs().startOf('quarter'),
            to: dayjs().endOf('quarter')
          }
        },
        {
          name: t('datePicker.date-option.months'),
          id: 7,
          key: 'months',
          value: {
            from: dayjs().startOf('month'),
            to: dayjs().endOf('month')
          }
        }
      ],
    },
    {
      title: t('datePicker.date-option.title.previous'),
      key: 'previous',
      radioList:[
        {
          name: t('datePicker.date-option.calendar-year'),
          id: 8,
          key: 'calendar-year',
          value: {
            from: dayjs().subtract(1,'year').startOf('year'),
            to: dayjs().subtract(1,'year').endOf('year')
          }
        }
      ]
    }
  ];

  const radioListPlans: Options[] = [
    {
      title: t('datePicker.date-option.title.common'),
      key: 'common-plans',
      radioList: [
        {
          name: t('datePicker.date-option.same-as-actuals'),
          id: 1,
          key: 'same-as-actuals',
          value: {
            from: dayjs().startOf('year'),
            to: dayjs().endOf('month')
          }
        },
      ]
    },
    {
      title: t('datePicker.date-option.title.current'),
      key: 'current-plans',
      radioList:[
        {
          name: t('datePicker.date-option.calendar-year'),
          id: 4,
          key: 'calendar-year-plans',
          value: {
            from: dayjs().startOf('year'),
            to: dayjs().endOf('year')
          }
        },
        {
          name: t('datePicker.date-option.half-year'),
          id: 5,
          key: 'half-year-plans',
          value: {
            from: dayjs().startOf('year'),
            to: dayjs().startOf('year').add(5,'M').endOf('month') 
          }
        },
        {
          name: t('datePicker.date-option.quarter'),
          id: 6,
          key: 'quarter-plans',
          value: {
            from: dayjs().startOf('quarter'),
            to: dayjs().endOf('quarter')
          }
        },
        {
          name: t('datePicker.date-option.months'),
          id: 7,
          key: 'months-plans',
          value: {
            from: dayjs().startOf('month'),
            to: dayjs().endOf('month')
          }
        }
      ],
    },
    {
      title: t('datePicker.date-option.title.previous'),
      key: 'previous-plans',
      radioList:[
        {
          name: t('datePicker.date-option.calendar-year'),
          id: 10,
          key: 'calendar-year-plans',
          value: {
            from: dayjs().subtract(1,'year').startOf('year'),
            to: dayjs().subtract(1,'year').endOf('year')
          }
        }
      ]
    }
  ];

  return {
    radioListActuals,
    radioListPlans,
  };
};

export default useConfigOptions;
