import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Period } from 'types/financials.types';
import { getDefaultPeriod } from 'utils/period.utils';

export interface TopBarStore {
  title: string;
  capsulesList: boolean;
  periodAvailable: boolean;
  viewId: number;
  viewPeriod: Period;
}

const initialState: TopBarStore = {
  title: '',
  capsulesList: false,
  periodAvailable: false,
  viewId: undefined,
  viewPeriod: null,
};

export const topBarSlice = createSlice({
  name: 'topBar',
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setAreCapsulesVisible: (state, action: PayloadAction<boolean>) => {
      state.capsulesList = action.payload;
    },
    setViewPeriodVisible: (state, action: PayloadAction<boolean>) => {
      state.periodAvailable = action.payload;
    },
    setViewId: (state, action: PayloadAction<number>) => {
      const viewId = action.payload;
      state.viewId = viewId;
      if (viewId) {
        state.viewPeriod = getDefaultPeriod(viewId);
      }
    },
    setViewPeriod: (state, action: PayloadAction<Period>) => {
      state.viewPeriod = action.payload;
    },
    clear: () => initialState
  }
});

export const selectViewPeriod = (state: { topBar: TopBarStore }) => state.topBar.viewPeriod;
export const topBarReducer = topBarSlice.reducer;
