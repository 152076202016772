import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Switch } from 'antd';
import styles from 'components/dashboard/panel/cardSettingsPage/forms/Forms.module.scss';
interface Props {
  name: string;
  onChangeName: (value: string) => void;
  showCustomName: boolean;
  setShowCustomName: (value: boolean) => void;
  placeholderName?: string;
}
const ElementName = ({ name, onChangeName, showCustomName, setShowCustomName }: Props) => {
  const [ t ] = useTranslation('dashboard');

  return <div className={ styles.container }>
    <div className={ styles.label }>
      { t('panel.card-settings.name') }
      {
        setShowCustomName ? <Switch
          checked={ showCustomName }
          onChange={ setShowCustomName }
          size='small'
        /> : null
      }
    </div>
    <Input
      disabled={ !showCustomName }
      value={ name }
      onChange={ (e) => {
        onChangeName(e.target.value);
      } }
      className={ styles.input }
    />
  </div>;
};

export default ElementName;
