import React, { useMemo } from 'react';
import styles from './CommandBar.module.scss';
import CommandBarProxyButton from
  'components/commandBar/components/proxy/CommandBarProxy';
import { useCommandBar } from 'components/commandBar/context/CommandBarContext';
import { FilterCapsuleList } 
  from 'components/financials/filterCapsule/filterCapsuleList/FilterCapsuleList';
import { trimElementsOfType } from './utils/commandBar.utils';

const CommandBar = () => {
  const { state: { hidden, utils, filterCapsules, filterCapsulesHidden } } = useCommandBar();
  const shownUtils = useMemo(() => utils.filter(b => !b.hidden), [ utils ]);

  const left = useMemo(() => {
    return trimElementsOfType(
      shownUtils.filter(button => button.placement === 'left'),
      /^divider.*/
    );
  }, [ shownUtils ]);

  const right = useMemo(() => {
    return shownUtils.filter(button => button.placement === 'right');
  }, [ shownUtils ]);

  return <div className={ `${ styles.bar } ${ hidden ? styles.hidden : '' }` } id='commandBar'>
    <div className={ styles.left }>
      <div className={ styles.buttons }>
        {
          left.map((util) => {
            return <CommandBarProxyButton util={ util } key={ util.key } />;
          })
        }
      </div>
      {
        filterCapsulesHidden ?
          <div className={ styles.capsuleContainer }>
            <FilterCapsuleList filterCapsules={ filterCapsules } />
          </div> : null
      }
    </div>
    <div className={ styles.right }>
      {
        right.map((util) => {
          return <CommandBarProxyButton util={ util } key={ util.key } />;
        })
      }
    </div>
  </div>;
};

export default CommandBar;
