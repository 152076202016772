export const SHOW_LABEL_CONFIRMATION_MODAL = 'show_labelConfirmationModal';
export const DELAY_LABELING_REFRESH = 'delay_labeling_refresh';
export const FILTERS_LIST_VIEW = 'filters_listView';

const EXPAND_ROWS_KEY = 'expand-rows___';

export const getKeyExpandRows = (tableKey: number) => `${ EXPAND_ROWS_KEY }${ tableKey }`;

export const existingStorageExpandRows = (name: string) => 
  localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : null;

export const setExpandRowsStorage = (templateId: number, idRowList: string[]) => {
  localStorage.setItem(getKeyExpandRows(templateId), JSON.stringify(idRowList));
};

export const addExpandRowsToStorage = (templateId: number, idRow: string) => {
  const list = existingStorageExpandRows(getKeyExpandRows(templateId)) ?? [];
  localStorage.setItem(getKeyExpandRows(templateId), JSON.stringify([ ...list, idRow ]));
};

export const removeExpandRowsToStorage = (templateId: number, idRow: string) => {
  const list = existingStorageExpandRows(getKeyExpandRows(templateId)) ?? [];
  const filterList = list.filter(item => item !== idRow);
  localStorage.setItem(getKeyExpandRows(templateId), JSON.stringify(filterList));
};
