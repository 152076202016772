import React, { useMemo } from 'react';
import Switcher from '../../elements/switcher/Switcher';
import { Cadence } from '../../../types/form.types';
import { useTranslation } from 'react-i18next';

interface Props {
  value: Cadence;
  onChange: (value: Cadence) => void;
  excludedCadences?: Cadence[];
  className?: string;
}

const CadenceSwitcher = ({ value, onChange, excludedCadences=[], className='' }: Props) => {
  const [ t ] = useTranslation('financials');
  const baseOptions = useMemo(() =>( [
    { label: 'W', value: Cadence.week, tooltip: t('common:cadence.week') },
    { label: 'M', value: Cadence.month, tooltip: t('common:cadence.month') },
    { label: 'Q', value: Cadence.quarter, tooltip: t('common:cadence.quarter') },
    { label: 'Y', value: Cadence.year, tooltip: t('common:cadence.year') }
  ]), []);

  const switcherOptions = useMemo(() => {
    return baseOptions.filter(option => !excludedCadences.includes(option.value));
  }, [ excludedCadences ]);

  return <Switcher
    options={ switcherOptions }
    value={ value }
    onChange={ onChange }
    className={ className }
  />;
};

export default CadenceSwitcher;