import React, { MutableRefObject, useCallback } from 'react';
import DynamicScrollableContainer
  from 'components/elements/dynamicScrollableContainer/DynamicScrollableContainer';
import GroupSelection from '../groupSelection/GroupSelection';
import SortSelection, { FormType } from '../sortSelection/SortSelection';
import styles from './GroupAndSort.module.scss';
import { AgGridReact } from 'ag-grid-react';
import { onSelectGrouping } from 'components/elements/tableWrapper/utils/table.utils';
import type {
  GroupOption, GroupSettings
} from 'components/elements/tableWrapper/types/table.types';
import type { PanelType } from 'types/app.types';

interface Props {
  gridRef: MutableRefObject<AgGridReact>;
  availableTabs: PanelType[];
  groupOptions?: GroupOption[];
  defaultSortSettings?: Partial<FormType>;
  sortOptions?: { label: string; value: string | number }[] | null;
  customSortHandler?: ((sortSetting: Partial<FormType>) => void) | null;
}

const GroupAndSort = ({
  gridRef,
  groupOptions,
  sortOptions,
  defaultSortSettings,
  availableTabs,
  customSortHandler
}: Props) => {
  const onGroupSelect = useCallback((group: GroupSettings) => {
    onSelectGrouping(group, gridRef);
  }, [ gridRef ]);

  return (
    <DynamicScrollableContainer>
      <div className={ styles.sortAndGroup }>
        <SortSelection
          sortOptions={ sortOptions }
          defaultSortSettings={ defaultSortSettings }
          isDisabled={ availableTabs && !availableTabs.includes('sort') }
          customSortHandler={ customSortHandler }
          gridRef={ gridRef }
        />

        <div className={ styles.divider } />

        <GroupSelection
          isDisabled={ availableTabs && !availableTabs.includes('group') }
          gridRef={ gridRef }
          onSelect={ onGroupSelect }
          options={ groupOptions }
        />
      </div>
    </DynamicScrollableContainer>
  );
};

export default GroupAndSort;
