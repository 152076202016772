import { getPeriodKey, setDefaultPeriod, getDefaultPeriod, setRangeType } from 'utils/period.utils';
import { useEffect, useState } from 'react';
import { Period } from 'types/financials.types';

interface Props {
  tableId: number;
  defaultPeriod: Period;
  settings: {
    plan: boolean;
    actuals: boolean;
  };
}

const useTablePeriodWithStorage = ({ tableId, defaultPeriod, settings }: Props) => {
  const [ period, setPeriod ] = useState<Period | null>(null);

  useEffect(() => {
    const isSaved = localStorage.getItem(getPeriodKey(tableId, 'Actuals')) ||
      localStorage.getItem(getPeriodKey(tableId, 'Plan'));
    if (isSaved) {
      const savedPeriod = getDefaultPeriod(tableId);
      setPeriod({
        cadence: savedPeriod.cadence,
        actualsOpen: settings.actuals && savedPeriod.actualsOpen,
        planOpen: settings.plan && savedPeriod.planOpen,
        ...settings.actuals ? {
          startDate: savedPeriod.startDate, endDate: savedPeriod.endDate
        } : {},
        ...settings.plan ? {
          startDatePlan: savedPeriod.startDatePlan, endDatePlan: savedPeriod.endDatePlan
        } : {},
        isManuallySet: savedPeriod.isManuallySet,
      });
    } else {
      setPeriod(defaultPeriod);
    }
  }, [ tableId ]);

  useEffect(() => {
    if (period) {
      setRangeType(tableId, false);
      setDefaultPeriod(tableId, period, false);
    }
  }, [ period ]);

  return { period, setPeriod };
};

export default useTablePeriodWithStorage;
