import DimensionCapsule from 'components/elements/dimensionCapsule/DimensionCapsule';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { DimensionToLabel } from 'types/labeling.types';
import { getDisplayName } from 'utils/common.utils';
import { ReactComponent as ConfirmIcon } from 'assets/icons/check-circle.svg';
import styles from './ConfirmPage.module.scss';

interface Props {
  transactionAmount: number;
  dimensions: DimensionToLabel[];
  createRule: boolean;
}

const ConfirmPage = ({
  transactionAmount,
  dimensions,
  createRule
}: Props) => {
  const [ t ] = useTranslation('labeling');
  return (
    <>
      <div className={ styles.transactionsLabelled }>
        <Trans
          t={ t }
          i18nKey='confirm-page.transactions-labeled'
          values={ { amount: transactionAmount } }
          components={ { bold: <b /> } }
        />
        {
          dimensions.map(dimension => (
            <DimensionCapsule
              key={ dimension.id }
              dimension={ getDisplayName(dimension.dimension) }
              dimensionItem={ getDisplayName(dimension.dimensionItem) }
              className={ styles.capsule }
            />
          ))
        }
      </div>
      <div className={ styles.iconWrapper }>
        <ConfirmIcon className={ styles.icon } />
      </div>
      {
        createRule ? <div className={ styles.createRule }>
          <div>
            <Trans
              t={ t }
              i18nKey='confirm-page.create-rule-info'
              components={ { bold: <b /> } }
            />
          </div>
        </div> : null
      }
    </>
  );
};

export default ConfirmPage;
