import React, { useEffect, useMemo } from 'react';
import ContextMenu from 'components/elements/contextMenu/ContextMenu';
import { useState } from 'react';
import { FormulaItem, FormulaItemType, FormulaPeriod } from 'types/templates.types';
import { ContextMenuItem } from 'components/elements/contextMenu/contextMenu.types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { useTemplatesContext } from 'context/TemplatesContext';
import {
  assignPeriodToFormula,
  deleteCustomFormulaElement,
  selectCustomFormula,
  setFormulaItemRollingAverage,
  swapOperator
} from 'store/formula.slice';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock-repeat.svg';
import { operators } from '../formulaBuilder/utils/formulaBuilder.utils';
import IncrementalNumber from 'components/elements/incrementalNumber/IncrementalNumber';

interface Props {
  element: FormulaItem;
  children: React.ReactNode;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const ROLLING_AVERAGE_MIN = 1;
const ROLLING_AVERAGE_MAX = 24;

const FormulaItemContextMenu = ({ element, children, isVisible, setIsVisible }: Props) => {
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();
  const [ rollingAverage, setRollingAverage ] = useState(element.rollingAverage);
  const { dispatch: templateContextDispatch } = useTemplatesContext();
  const customFormula = useAppSelector(selectCustomFormula);
  const customFormulaElement = customFormula.find(el => el.key === element.key);
  const activeElementOffset = customFormulaElement?.offset;
  const findOperator = operators.find(el => el.value === element.value);

  useEffect(() => {
    setRollingAverage(element.rollingAverage);
  }, [ element.rollingAverage ]);

  const onVisibleChange = (_isVisible: boolean) => {
    if (!_isVisible) {
      applyRollingAverage();
    }
    setIsVisible(_isVisible);
    templateContextDispatch({ type: 'contexMenuFormulaElement', payload: _isVisible });
  };

  const periodOptions = useMemo(() => [
    {
      name: t('templates.contextMenu.periods.current-period'),
      value: null
    },
    {
      name: t('templates.contextMenu.periods.previous-period'),
      value: FormulaPeriod.PREVIOUS_PERIOD
    },
    {
      name: t('templates.contextMenu.periods.previous-year'),
      value: FormulaPeriod.PREVIOUS_YEAR
    },
  ], []);

  const items = useMemo(() => {
    const options: ContextMenuItem[] = [];

    options.push({
      name: t('common:delete'),
      icon: <CloseIcon />,
      action: () => {
        dispatch(deleteCustomFormulaElement(customFormula, element));
      },
    });

    if (
      element.type === FormulaItemType.STATUTORY ||
      element.type === FormulaItemType.TEMPLATE ||
      element.type === FormulaItemType.DIMENSION_ITEM
    ) {
      options.push({
        name: t('templates.contextMenu.assign-period'),
        icon: <ClockIcon />,
        subMenu: periodOptions.map((option) => ({
          name: option.name,
          active: activeElementOffset == option.value,
          action: () => {
            dispatch(assignPeriodToFormula(element.key, option.value));
          },
        }))
      });
    } else if (element.type === FormulaItemType.OPERATOR) {
      options.push({
        name: t('templates.contextMenu.operator'),
        icon: null,
        subMenu: operators.map((operator) => ({
          name: operator.menuValue ?? operator.value,
          active: findOperator.value === operator.value,
          action: () => {
            dispatch(swapOperator(customFormula, element, operator));
          },
        }))
      });
    } else if (
      element.type === FormulaItemType.FORMULA
    ) {
      options.push({
        name: t('templates.contextMenu.rolling-average'),
        icon: null,
        suppressHide: true,
        render: () => {
          return <IncrementalNumber
            value={ rollingAverage }
            onChange={ setRollingAverage }
            // Stop propagation to prevent changing formula elements by keyboard
            onKeyDown={ (e) => {
              e.stopPropagation();
              if (e.code === 'Enter') {
                onVisibleChange(false);
              }
            } }
          />;
        }
      });
    }

    return options;
  }, [ element, rollingAverage ]);

  const saveRollingAverage = (value: number) => {
    dispatch(setFormulaItemRollingAverage(element.key, value));
  };

  const applyRollingAverage = () => {
    if (!rollingAverage) {
      return saveRollingAverage(null);
    }
    if (rollingAverage >= ROLLING_AVERAGE_MIN && rollingAverage <= ROLLING_AVERAGE_MAX) {
      saveRollingAverage(rollingAverage);
    }
    if (rollingAverage < ROLLING_AVERAGE_MIN) {
      saveRollingAverage(ROLLING_AVERAGE_MIN);
    }
    if (rollingAverage > ROLLING_AVERAGE_MAX) {
      saveRollingAverage(ROLLING_AVERAGE_MAX);
    }
  };

  return <ContextMenu
    isVisible={ isVisible }
    onVisibleChange={ onVisibleChange }
    items={ items }
  >
    { children }
  </ContextMenu>;
};

export default FormulaItemContextMenu;
