import Select from 'components/elements/select/Select';
import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DealType } from 'types/revenueRecognition.types';
import { getDisplayName } from 'utils/common.utils';
import { notifyError } from 'utils/notifications.utils';
import organizationsService from '../../../services/organizations.service';
import { useAppSelector } from '../../../store/hooks/hooks';

const DealTypeSelect = ({ data, gridRef }) => {
  const [ t ] = useTranslation('common');
  const dimensions = useAppSelector(
    state => state.breakdowns.dimensions
  );
  const DEAL_TYPE_NAME = useMemo(() => 'Deal Type', []);
  const NO_DEAL_TYPE_NAME = useMemo(() => 'Type', []);
  const dealTypeDimension =
    useMemo(() => dimensions.find(dim => dim.name === DEAL_TYPE_NAME), [ dimensions ]);

  const typeOptions = useMemo(() => {
    return Object.values(DealType).map(type => ({
      value: dealTypeDimension?.items.find(item =>
        getDisplayName(item.name).toLocaleLowerCase() === type.toLocaleLowerCase())?.id,
      label: capitalize(type.toLocaleLowerCase())
    }));
  }, [ dealTypeDimension ]);

  const defaultDeal = useMemo(() => data?.dimensionItemIds?.find(
    item => dealTypeDimension?.items.some(type => type.id === item
    )
  ), [ data, dealTypeDimension ]);

  const defaultValue = useMemo(() => defaultDeal ?? NO_DEAL_TYPE_NAME, [ data, defaultDeal ]);

  const onSelect = (dealType: string) => {
    if (dealType === defaultDeal) {
      organizationsService.clearDimensions({ transactionLines: [ data?.id ] }, +dealType)
        .catch(() => {
          notifyError(
            t('notifications.unexpected-error.message',
              { ns: 'common' }
            ));
        }).finally(() => {
          const transaction = data;
          gridRef?.current?.api?.applyTransaction({
            update: [ { ...transaction, dealType: null } ]
          });
        });
      return;
    }
    organizationsService.addDimensionToTransaction({
      dimensionItem: +dealType,
      transactionLines: [ data?.id ],
    }).catch(() => {
      notifyError(
        t('notifications.unexpected-error.message',
          { ns: 'common' }
        ));
    }).finally(() => {
      const transaction = data;
      const dealTypeString = dealTypeDimension?.items.find(item => item.id === +dealType)?.name;
      gridRef?.current?.api?.applyTransaction({
        update: [ { ...transaction, dealType: dealTypeString } ]
      });
    });
  };

  return (
    <Select
      options={ typeOptions }
      defaultValue={ defaultValue }
      onSelect={ onSelect }
      isActive={ (val) => val !== NO_DEAL_TYPE_NAME }
      noValueIdentifier={ NO_DEAL_TYPE_NAME } />
  );
};

export default DealTypeSelect;
