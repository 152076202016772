import React from 'react';
import DocumentTitle from 'components/elements/documentTitle/DocumentTitle';
import { useTranslation } from 'react-i18next';
import Templates from './Templates';
import { useAppSelector } from 'store/hooks/hooks';
import { selectTemplate } from 'store/template.slice';

const TemplateView = () => {
  const [ t ] = useTranslation('common');

  const template = useAppSelector(selectTemplate);

  return (
    <DocumentTitle
      title={ t('common:document-title.template-builder', { name: template.title }) }
    >
      <Templates />
    </DocumentTitle>
  );
};

export default TemplateView;