import React from 'react';
import styles from './TopBar.module.scss';
import { ReactComponent as Chevron } from 'assets/icons/chevron-down.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks/hooks';

import { Dimension } from 'types/filterTable.types';
import { getFilterCount } from '../dimensionDetails.util';

interface Props{
  dimension?: Dimension | null;
  templateId?: number;
  onBack: () => void;
}

const TopBar = ({
  dimension,
  templateId,
  onBack,
}: Props) => {
  const [ t ] = useTranslation('financials');

  const goBack = () => {
    onBack();
  };

  return (
    <div className={ styles.container }>
      <button onClick={ goBack } className={ styles.backButton }>
        <Chevron className={ styles.arrow }/>
        <span className={ styles.back }>{ t('left-panel.back') }</span>
      </button>
      <div className={ styles.right }>
        { templateId != null && dimension &&
          <FiltersCount dimension={ dimension } templateId={ templateId } />
        }
      </div>
    </div>
  );
};

export default TopBar;

interface FiltersCountProps {
  dimension: Dimension;
  templateId: number;
}

const FiltersCount = ({ dimension, templateId }: FiltersCountProps) => {
  const filter = useAppSelector(
    state => state.financials.tables[ templateId ].filter
      .find((f) => f.dimension === dimension?.id)
  );
  if (!filter) return null;

  const [ allFilterCount, currentFilterCount ] = getFilterCount(dimension, filter);

  return (
    <div className={ styles.filterCount }>
      { currentFilterCount >= 0 ? currentFilterCount : 0 }
      /
      { allFilterCount }
    </div>
  );
};
