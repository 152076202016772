import React from 'react';
import TableRenderer from 'components/dashboard/grid/renderers/table/TableRenderer';
import { DashboardElement, DashboardElementType } from 'types/dashboard.types';
import BigNumberRenderer from './bigNumber/BigNumberRenderer';
import ChartRenderer from './chart/ChartRenderer';
import TitleRenderer from './title/TitleRenderer';

interface Props {
  item: DashboardElement;
}

const GridItemRenderer = ({ item }: Props) => {
  switch (item.type) {
    case DashboardElementType.BIG_NUMBER:
      return <BigNumberRenderer item={ item } />;
    case DashboardElementType.TABLE:
      return <TableRenderer item={ item } />;
    case DashboardElementType.CHART:
      return <ChartRenderer item={ item } />;
    case DashboardElementType.TITLE:
    case DashboardElementType.SUBTITLE:
      return <TitleRenderer item={ item } />;
    default:
      return null;
  }
};

export default GridItemRenderer;
