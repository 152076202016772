import Button from 'components/elements/button/Button';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { clearSelectedElements } from 'store/dashboard.slice';
import { useAppDispatch } from 'store/hooks/hooks';
import styles from './ConfirmButtons.module.scss';

interface Props {
  loading?: boolean;
}

const ConfirmButtons = ({ loading = false }: Props) => {
  const dispatch = useAppDispatch();
  const [ t ] = useTranslation('common');

  const onClose = useCallback(() => {
    dispatch(clearSelectedElements());
  }, []);

  return (
    <div className={ styles.buttons } >
      <Button type='default' onClick={ onClose }>
        { t('form.cancel') }
      </Button>
      <Button loading={ loading } htmlType='submit'>
        { t('modal.buttons.default-ok') }
      </Button>
    </div>
  );
};

export default ConfirmButtons;