import type { ViewItem } from 'types/financials.types';
import {
  ActionButtonOptions,
  DetailedViewSettings,
  ITemplateSettingSlice,
  LazyLoadingRowExpansionState,
  LoadingStatus,
  Period,
  PeriodSlice,
  TableState,
} from 'types/financials.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { getDefaultPeriod, } from 'utils/period.utils';
import { FilterList } from 'types/filterTable.types';
import { NodeSortingSetting } from 'utils/sorting.utils';
import { getValueFromStorage } from 'utils/actionButtonStorage';
import isEmpty from 'lodash/isEmpty';
import { CustomViewTemplates, PanelType } from 'types/app.types';
import { defaultFilter } from 'utils/financials.utils';
import { RootState } from 'store/store';

const MAX_QUEUE_SIZE = 5;

dayjs.extend(weekday);

export const defaultData = (templateId: number): FinancialTableState => {
  const defaultPeriod = {
    ...getDefaultPeriod(templateId),
    isManuallySet: false,
  };
  return {
    id: templateId,
    gridReady: false,
    columnFields: [],
    period: defaultPeriod,
    customer: null,
    state: {
      [ ActionButtonOptions.ACCOUNTS ]:
        getValueFromStorage(templateId, ActionButtonOptions.ACCOUNTS, false),
      [ ActionButtonOptions.DIMENSION_LABELS ]:
        getValueFromStorage(templateId, ActionButtonOptions.DIMENSION_LABELS, true),
      [ ActionButtonOptions.DIMENSION_NUMBERS ]:
        getValueFromStorage(templateId, ActionButtonOptions.DIMENSION_NUMBERS, false),
      [ ActionButtonOptions.COUNTERPARTY_LOGOS ]:
        getValueFromStorage(templateId, ActionButtonOptions.COUNTERPARTY_LOGOS, true),
      [ ActionButtonOptions.ACCOUNTS_NUMBER ]:
        getValueFromStorage(templateId, ActionButtonOptions.ACCOUNTS_NUMBER, true),
      [ ActionButtonOptions.DYNAMIC_COLUMNS ]:
        getValueFromStorage(templateId, ActionButtonOptions.DYNAMIC_COLUMNS, true),
    },
    sorting: {},
    expansionState: {},
    lazyLoadingStatus: {},
    lazyLoadingRowsToExpand: {},
    filter: defaultFilter,
    hasNewCells: false
  };
};

interface FocusCellAction {
  templateId: number;
  type: 'focus';
  payload: {
    colId: string;
    rowIndex: number;
  };
}

type SortingSettingPayload = {
  templateId: number;
  nodeId: string;
  settings: NodeSortingSetting;
};

type ExpansionSettingPayload = {
  templateId: number;
  nodeId: number;
  expanded: boolean;
};

type LazyLoadingNodeStatusPayload = {
  templateId: number;
  nodeId: string;
  status: LoadingStatus;
};

export type FinancialTableState = {
  id: number;
  gridReady: boolean;
  columnFields: string[];
  period: Period;
  state: TableState;
  sorting: { [ id: string ]: NodeSortingSetting };
  expansionState: { [ id: string ]: boolean };
  lazyLoadingStatus: { [ id: string ]: LoadingStatus };
  lazyLoadingRowsToExpand: { [ id: string ]: LazyLoadingRowExpansionState };
  filter: FilterList;
  customer?: number;
  hasNewCells: boolean;
  labelingTimestamp?: number;
};

type FinancialState = {
  tables: {
    [ table: number ]: FinancialTableState;
  };
  tablesLoadingState: {
    [ table: number ]: boolean;
  };
  tablesVersions: {
    [ table: number ]: number;
  };
  viewId: number;
  capsuleList: ViewItem[];
  templates: CustomViewTemplates[];
  closeOtherPanelsOnOpen: boolean;
  isPlanningEnabled: boolean;
  detailedView: DetailedViewSettings;
  rightPanel: PanelType;
  active: Omit<ViewItem, 'isShown'> | null;
  overviewDropdown: null | { columns: string[]; nodes: string[] };
  actionQueue: FocusCellAction[];
};

export const defaultDetailedViewSettings: DetailedViewSettings = {
  period: null,
  params: null,
  templateId: 0,
  filter: defaultFilter,
  budgetItemType: null,
  type: null,
  sectionKey: null,
  data: {
    budgetItems: [],
  }
};

const initialValues: FinancialState = {
  tables: {},
  tablesLoadingState: {},
  tablesVersions: {},
  viewId: null,
  capsuleList: [],
  templates: [],
  closeOtherPanelsOnOpen: true,
  isPlanningEnabled: false,
  detailedView: defaultDetailedViewSettings,
  rightPanel: undefined,
  active: null,
  overviewDropdown: null,
  actionQueue: []
};

export const financialsSlice = createSlice({
  name: 'financials',
  initialState: initialValues,
  reducers: {
    addTables: (sliceState, action: PayloadAction<number[]>) => {
      action.payload.forEach(templateId => {
        sliceState.tables[ templateId ] = defaultData(templateId);
        if (!sliceState.tablesLoadingState[ templateId ]) {
          sliceState.tablesLoadingState[ templateId ] = false;
        }
      });
      if (sliceState.active?.templateId == null && action.payload.length > 0) {
        sliceState.active = sliceState.capsuleList.filter(capsule => capsule.isShown).at(0);
      }
    },
    addTablesWithPeriod: (sliceState, action: PayloadAction<{
      templateIds: number[];
      period: Period;
    }>) => {
      action.payload.templateIds.forEach(templateId => {
        sliceState.tables[ templateId ] = {
          ...defaultData(templateId),
          period: action.payload.period
        };
        if (!sliceState.tablesLoadingState[ templateId ]) {
          sliceState.tablesLoadingState[ templateId ] = false;
        }
      });
      if (sliceState.active.templateId == null && action.payload.templateIds.length > 0) {
        sliceState.active = {
          templateId: action.payload.templateIds[ 0 ],
          type: 'table'
        };
      }
    },
    setReportVersion: (sliceState, action: PayloadAction<{ id: number; version: number }>) => {
      sliceState.tablesVersions[ action.payload.id ] = action.payload.version;
    },
    setReportLoaded: (sliceState, action: PayloadAction<{ id: number; loaded: boolean }>) => {
      sliceState.tablesLoadingState[ action.payload.id ] = action.payload.loaded;
    },
    setGridReady: (sliceState, action: PayloadAction<ITemplateSettingSlice<boolean>>) => {
      sliceState.tables[ action.payload.templateId ].gridReady = action.payload.value;
    },
    clearTables: (sliceState) => {
      sliceState.tables = initialValues.tables;
    },
    removeTables: (sliceState, action: PayloadAction<number[]>) => {
      action.payload.forEach(templateId => {
        if (sliceState.active?.templateId === templateId) {
          sliceState.active = null;
        }
        delete sliceState.tables[ templateId ];
      });
      if (Object.values(sliceState.tables).length === 0) {
        sliceState.rightPanel = initialValues.rightPanel;
      }
    },
    setHasNewCells: (sliceState, action: PayloadAction<ITemplateSettingSlice<boolean>>) => {
      if (sliceState.tables[ action.payload.templateId ]) {
        sliceState.tables[ action.payload.templateId ].hasNewCells = action.payload.value;
      }
    },
    setCapsuleList: (state, action: PayloadAction<ViewItem[]>) => {
      state.capsuleList = action.payload;
    },
    setTemplates: (state, action: PayloadAction<CustomViewTemplates[]>) => {
      state.templates = action.payload;
    },
    setState: (sliceState, action: PayloadAction<ITemplateSettingSlice<TableState>>) => {
      sliceState.tables[ action.payload.templateId ].state = action.payload.value;
    },
    setViewId: (sliceState, action: PayloadAction<number>) => {
      sliceState.viewId = action.payload;
    },
    setPeriod: (sliceState, action: PayloadAction<PeriodSlice>) => {
      if (sliceState.tables[ action.payload.templateId ]) {
        sliceState.tables[ action.payload.templateId ].period = action.payload.period;
      }
    },
    setActualsOpen: (sliceState, action: PayloadAction<ITemplateSettingSlice<boolean>>) => {
      sliceState.tables[ action.payload.templateId ].period.actualsOpen = action.payload.value;
    },
    setPlanOpen: (sliceState, action: PayloadAction<ITemplateSettingSlice<boolean>>) => {
      sliceState.tables[ action.payload.templateId ].period.planOpen = action.payload.value;
    },
    setSorting: (sliceState, action: PayloadAction<SortingSettingPayload>) => {
      sliceState
        .tables[ action.payload.templateId ]
        .sorting[ action.payload.nodeId ] = action.payload.settings;
    },
    setExpansionState: (sliceState, action: PayloadAction<ExpansionSettingPayload>) => {
      sliceState
        .tables[ action.payload.templateId ]
        .expansionState[ action.payload.nodeId ] = action.payload.expanded;
    },
    setLazyLoadingStatus: (sliceState, action: PayloadAction<LazyLoadingNodeStatusPayload>) => {
      sliceState
        .tables[ action.payload.templateId ]
        .lazyLoadingStatus[ action.payload.nodeId ] = action.payload.status;
    },
    setFilters: (sliceState, action: PayloadAction<FilterList>) => {
      const templateId = sliceState.active?.templateId;
      if (templateId) {
        sliceState.tables[ templateId ].filter = action.payload;
      }
    },
    setFiltersTable: (sliceState, action: PayloadAction<ITemplateSettingSlice<FilterList>>) => {
      if (action.payload?.templateId && sliceState.tables[ action.payload?.templateId ]) {
        sliceState.tables[ action.payload?.templateId ].filter = action.payload.value;
      }
    },
    clearFilters: (sliceState, action: PayloadAction<number>) => {
      sliceState.tables[ action.payload ].filter = [];
    },
    setPanelTable: (sliceState, action: PayloadAction<number | null>) => {
      if (action.payload && sliceState.closeOtherPanelsOnOpen) {
        for (const tableId of Object.keys(sliceState.tables)) {
          if (tableId !== action.payload.toString()) {
            delete sliceState.tables[ tableId ];
          }
        }
        if (isEmpty(sliceState.tables)) {
          sliceState.tables = { [ action.payload ]: defaultData(action.payload) };
        }
      }
    },
    setRightPanel: (sliceState, action: PayloadAction<PanelType>) => {
      sliceState.rightPanel = action.payload;
    },
    setIsPlanningEnabled: (sliceState, action: PayloadAction<boolean>) => {
      sliceState.isPlanningEnabled = action.payload;
    },
    setColumnDefs: (sliceState, action: PayloadAction<ITemplateSettingSlice<string[]>>) => {
      sliceState.tables[ action.payload.templateId ].columnFields = action.payload.value;
    },
    setCustomer: (sliceState, action: PayloadAction<ITemplateSettingSlice<number>>) => {
      sliceState.tables[ action.payload.templateId ].customer = action.payload.value;
    },
    setLabelingTimestamp: (sliceState, action: PayloadAction<ITemplateSettingSlice<number>>) => {
      sliceState.tables[ action.payload.templateId ].labelingTimestamp = action.payload.value;
    },
    setCloseOtherPanelsOnOpen: (sliceState, action: PayloadAction<boolean>) => {
      sliceState.closeOtherPanelsOnOpen = action.payload;
    },
    setLazyLoadingRowToExpand: (
      sliceState,
      action: PayloadAction<ITemplateSettingSlice<LazyLoadingRowExpansionState>>
    ) => {
      sliceState.tables[ action.payload.templateId ]
        .lazyLoadingRowsToExpand[ action.payload.value.nodeId ] = action.payload.value;
    },
    clearLazyLoadingRowToExpand: (
      sliceState,
      action: PayloadAction<ITemplateSettingSlice<string>>
    ) => {
      delete sliceState.tables[ action.payload.templateId ]
        .lazyLoadingRowsToExpand[ action.payload.value ];
    },
    setDetailedViewSettings: (sliceState, action: PayloadAction<Partial<DetailedViewSettings>>) => {
      sliceState.detailedView = {
        ...sliceState.detailedView,
        ...action.payload,
        data: {
          ...sliceState.detailedView.data,
          ...action.payload?.data ? action.payload.data : {}
        }
      };
    },
    setOverviewDropdown: (
      sliceState,
      action: PayloadAction<{ columns: string[]; nodes: string[] } | null>
    ) => {
      sliceState.overviewDropdown = action.payload;
    },
    clearDetailedViewSettings: (sliceState) => {
      sliceState.detailedView = defaultDetailedViewSettings;
    },
    setActive: (sliceState, action: PayloadAction<Omit<ViewItem, 'isShown'>> | null) => {
      sliceState.active = action.payload;
    },
    addActionToQueue: (sliceState, action: PayloadAction<FocusCellAction>) => {
      if (action.payload.payload.colId !== 'ag-Grid-AutoColumn') {
        if (sliceState.actionQueue.length >= MAX_QUEUE_SIZE) {
          sliceState.actionQueue.shift();
        }
        sliceState.actionQueue.push(action.payload);
      }
    },
    clearActiveTable: (sliceState) => {
      sliceState.active = initialValues.active;
    },
    clear: () => initialValues,
  }
});

export const selectTables = (state: RootState) => state.financials.tables;
export const selectViewId = (state: RootState) => state.financials.viewId;
export const selectFilteredTableReady = (state: RootState) =>
  state.financials.tables[ state.financials.active?.templateId ]?.gridReady;
export const selectCapsuleList = (state: RootState) => state.financials.capsuleList;
export const selectPanelTable = (state: RootState) => state.app.leftPanel != null ?
  state.financials.active?.templateId : null;
export const selectRightPanel = (state: RootState) => state.financials.rightPanel;
export const financialsReducer = financialsSlice.reducer;
export const selectActive = (state: RootState) => state.financials.active;
export const selectPeriod = (id: number) => (state: RootState) =>
  state.financials.tables[ id ]?.period;
