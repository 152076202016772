import { useEffect, useState } from 'react';

const useKeyListener = (key: string) => {
  const [ keyPressed, setKeyPressed ] = useState(false);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === key) {
        setKeyPressed(true);
      }
    };

    const onKeyUp = (e) => {
      if (e.key === key) {
        setKeyPressed(false);
      }
    };

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [ key ]);

  return { keyPressed };
};

export default useKeyListener;