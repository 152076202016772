import { createApi } from '@reduxjs/toolkit/query/react';
import {
  InputCategory,
  InputRow,
  InputRowWithValues
} from '../../components/inputs/types/inputs.types';
import { Period } from 'types/financials.types';
import { endpoints } from 'utils/config.utils';
import { httpBaseQuery } from './api.utils';
import dayjs from 'dayjs';
import { PaginatedResponse } from 'types/app.types';
import { Cadence } from 'types/form.types';

const formatDate = (date: number) => dayjs.unix(date).utcOffset(0, true).format('YYYY-MM-DD');
const DEFAULT_LIMIT = 9999;

interface BulkUpdate {
  startDate?: string;
  endDate?: string;
  planStartDate?: string;
  planEndDate?: string;
  cadence: Cadence;
  items: InputRowWithValues[];
}

export const inputsApi = createApi({
  reducerPath: 'inputsApi',
  baseQuery: httpBaseQuery(),
  tagTypes: [ 'Input', 'Categories' ],
  endpoints: (builder) => ({
    getInputs: builder.query<PaginatedResponse<InputRow>, { limit?: number }>({
      query: ({ limit = DEFAULT_LIMIT }) => ({
        url: endpoints.inputs.index,
        params: { limit },
        method: 'GET',
        providesTags: [ 'Input' ]
      }),
      providesTags: [ 'Input' ],
    }),
    getInputsWithValues: builder.query<InputRowWithValues[], { period: Period }>({
      query: ({ period }) => {
        return ({
          url: endpoints.inputs.values,
          params: {
            startDate: period.startDate ? formatDate(period.startDate) : undefined,
            endDate: period.endDate ? formatDate(period.endDate) : undefined,
            planStartDate: period.startDatePlan ? formatDate(period.startDatePlan) : undefined,
            planEndDate: period.endDatePlan ? formatDate(period.endDatePlan) : undefined,
          },
          method: 'GET',
          providesTags: [ 'Input' ]
        });
      },
      providesTags: (data) => data?.map((input) => ({ type: 'Input', id: input.id })),
    }),
    getCategories: builder.query<PaginatedResponse<InputCategory>, { limit?: number }>({
      query: ({ limit = DEFAULT_LIMIT }) => ({
        url: endpoints.inputs.categories,
        params: { limit },
        method: 'GET',
        providesTags: [ 'Categories' ]
      }),
      providesTags: [ 'Categories' ],
    }),
    bulkUpdate: builder.mutation<InputRowWithValues[], { data: BulkUpdate }>({
      query: ({ data }) => {
        return ({
          url: endpoints.inputs.bulk,
          method: 'POST',
          data: data,
          providesTags: [ 'Input' ]
        });
      },
      invalidatesTags: [ 'Input' ],
    }),
  }),
});

export const {
  useGetInputsQuery,
  useGetInputsWithValuesQuery,
  useGetCategoriesQuery,
  useBulkUpdateMutation } = inputsApi;
