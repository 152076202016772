import React from 'react';
import styles from './TableTopBar.module.scss';
import { useContractsContext } from 'context/ContractsContext';
import SortDropdown from '../sortDropdown/SortDropdown';
import { useTranslation } from 'react-i18next';

const TableTopBar = () => {
  const {
    state: { counter },
    dispatch
  } = useContractsContext();

  const [ t ] = useTranslation('contracts');

  const groupOptions = [
    {
      id: 0,
      name: t('group.name.name'),
      type: t('group.name.type'),
    },
    {
      id: 1,
      name: t('group.status.name'),
      type: t('group.status.type')
    },
    {
      id: 2,
      name: t('group.no-grouping.name'),
      type: t('group.no-grouping.type')
    }
  ];

  const sortOptions = [
    {
      id: 0,
      name: t('sort.name.asc.name'),
      type: t('sort.name.type'),
    },
    {
      id: 1,
      name: t('sort.name.desc.name'),
      type: t('sort.name.type'),
    },
    {
      id: 2,
      name: t('sort.date.desc.name'),
      type: t('sort.date.type'),
    },
    {
      id: 3,
      name: t('sort.date.asc.name'),
      type: t('sort.date.type'),
    },
    {
      id: 4,
      name: t('sort.status.name'),
      type: t('sort.status.type'),
    }
  ];

  const onGroupSelect = (option) => {
    dispatch({ type: 'setGroupBy', payload: option.id });
  };

  const onSortSelect = (option) => {
    dispatch({ type: 'setSortBy', payload: option.id });
  };

  return (
    <div className={ styles.tableTopBar }>
      <div className={ styles.title }>
        <span className={ styles.name }>
          { t('header') }
        </span>
        <span className={ styles.counter }>{ counter }</span>
      </div>
      <div className={ styles.configContainer }>
        <SortDropdown
          options={ groupOptions }
          title={ t('group.title') }
          onSelect={ onGroupSelect }/>
        <SortDropdown
          options={ sortOptions }
          title={ t('sort.title') }
          onSelect={ onSortSelect }/> 
      </div>
    </div>
  );
};

export default TableTopBar;
