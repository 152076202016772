import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { PanelType } from 'types/app.types';
import type {
  CommandBarSelectUtil,
  CommandBarUtil
} from 'components/commandBar/types/commandBar.types';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { appSlice } from 'store/app.slice';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { AgGridReact } from 'ag-grid-react';
import { ColumnState } from 'ag-grid-community';
import { onSelectGrouping } from 'components/elements/tableWrapper/utils/table.utils';
import { planningService } from 'services/planning.service';
import { UUID } from 'types/templates.types';
import { InputRowWithValues } from '../types/inputs.types';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import { ReactComponent as Duplicate } from 'assets/icons/duplicate.svg';
import { areFiltersActive } from '../../../utils/financials.utils';
import { ReactComponent as FilterActiveIcon } from 'assets/icons/filters-active.svg';

const sort = defaults.find(util => util.key === 'sort');
const columns = defaults.find(util => util.key === 'columns');
const ctaButton = defaults.find(({ key }) => key === 'cta') as CommandBarSelectUtil;
const useInputsCommandBar = (
  isActive: boolean,
  gridRef: React.MutableRefObject<AgGridReact>,
  duplicate: (input: InputRowWithValues & { internalId?: UUID }) => void,
  onAddInput: () => void
) => {
  const [ t, i18n ] = useTranslation('common');
  const [ utils, setUtils ] = useState<CommandBarUtil[]>([]);

  const dispatch = useAppDispatch();
  const activePanelType = useAppSelector(store => store.app.leftPanel);
  const filters = useAppSelector(store => store.inputs.filters);

  const onButtonClick = useCallback((key: PanelType) => {
    if (activePanelType === key) {
      return dispatch(appSlice.actions.setLeftPanel(null));
    } else {
      dispatch(appSlice.actions.setLeftPanel(key));
    }
  }, [ activePanelType, dispatch ]);

  const idSort: ColumnState[] = useMemo(() => [
    {
      colId: 'input_id',
      sort: 'desc',
      sortIndex: 0
    }
  ], []);

  const onDuplicate = useCallback(() => {
    const selectedRow = gridRef.current.api.getSelectedNodes()[ 0 ];
    if (!selectedRow) {
      return;
    }
    selectedRow.setSelected(false);
    duplicate(selectedRow.data);
  }, []);

  const defaultFilter = defaults.find(button => button.key === 'filter');

  useEffect(() => {
    if (isActive) {
      setUtils([ {
        ...ctaButton,
        hidden: false,
        disabled: false,
        placement: 'right',
        options: [
          {
            label: t('common:form.create-new'),
            type: 'default',
            icon: <Plus />,
            onClick: () => {
              onAddInput();
              const requiredColumns = gridRef.current.columnApi.getColumns()
                .filter(col => col.getColDef()[ 'compulsory' ])
                .map(col => col.getColId());
              gridRef.current.columnApi.setColumnsVisible(requiredColumns, true);
              onSelectGrouping({
                primary: null,
                secondary: null,
              }, gridRef);
              gridRef.current.columnApi.applyColumnState({
                state: idSort,
                defaultState: { sort: null, sortIndex: null }
              });
              gridRef.current.api.onSortChanged();
            }
          },
          {
            label: t('inputs:cta.update-budget'),
            type: 'default',
            icon: <Reload />,
            onClick: async () => {
              await planningService.refreshPlan();
            }
          },
          {
            label: t('common:form.duplicate'),
            type: 'default',
            icon: <Duplicate />,
            onClick: onDuplicate
          }
        ]
      },
      {
        key: 'sort',
        type: 'button',
        active: activePanelType === 'sort',
        hidden: false,
        disabled: false,
        placement: 'left',
        tooltip: t('command-bar.tooltip.sort'),
        icon: sort.icon,
        onClick: () => onButtonClick('sort')
      },
      {
        key: 'columns',
        type: 'button',
        active: activePanelType === 'columns',
        hidden: false,
        disabled: false,
        placement: 'left',
        tooltip: t('command-bar.tooltip.columns'),
        icon: columns.icon,
        onClick: () => onButtonClick('columns')
      },
      {
        key: 'filter',
        type: 'button',
        active: activePanelType === 'filter',
        hidden: false,
        disabled: false,
        placement: 'left',
        tooltip: t('command-bar.tooltip.filter'),
        icon: areFiltersActive(filters) ?
          <FilterActiveIcon/> : defaultFilter.icon,
        onClick: () => onButtonClick('filter')
      }
      ]);
    } else {
      setUtils([]);
    }

  }, [ i18n.language, activePanelType, onButtonClick, filters ]);

  useCommandBarEffect({ utils, filterCapsules: filters });
};

export default useInputsCommandBar;
