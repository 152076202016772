import React, { useEffect, useMemo } from 'react';
import FinancialTable from 'components/financials/financialTable/FinancialTable';
import {
  CustomSettings,
  defaultCustomSettings,
  FinancialTableContextProvider
} from 'context/FinancialTableContext';
import { addNewTables } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DashboardElementMapping, DashboardElementType } from 'types/dashboard.types';
import styles from './TableRenderer.module.scss';
import DashboardTable from 'components/dashboard/wrappers/dashboardTable/DashboardTable';
import DynamicTableWrapper from 'components/wrappers/dynamicTableWrapper/DynamicTableWrapper';
interface Props {
  item: DashboardElementMapping<DashboardElementType.TABLE>;
}

const TableRenderer = ({ item }: Props) => {
  const dispatch = useAppDispatch();
  const templateId = useMemo(() => item.element?.template, [ item.element ]);
  const table = useAppSelector(state => state.financials.tables[ templateId ]);

  useEffect(() => {
    if (!templateId) {
      return;
    }
    dispatch(addNewTables([ templateId ]));
  }, [ templateId ]);

  return <div className={ styles.container }>
    {
      table != null ? <FinancialTable useCommandBar={ false }/> : null
    }
  </div>;
};

const wrapper = ({ item }: Props) => {
  const tableSettings: CustomSettings = useMemo(() => {
    return {
      ...defaultCustomSettings,
      adjustHeight: false,
      disableStickyHeader: true,
      classNames: {
        card: styles.card
      },
      panelSettings: {
        ...defaultCustomSettings.panelSettings,
        chart: {
          ...defaultCustomSettings.panelSettings.chart,
          disabled: false,
        },
        plan: {
          ...defaultCustomSettings.panelSettings.plan,
          disabled: true,
          panelDisabled: true,
        },
        filterLabel: {
          ...defaultCustomSettings.panelSettings.filterLabel,
          disabled: true,
          panelDisabled: true,
        }
      },
      detailedViewSettings: {
        disabled: false,
        floatingPanelDisabled: false,
      }
    };
  }, []);

  return <FinancialTableContextProvider
    templateId={ item.element.template }
    customSettings={ tableSettings }
  >
    <DynamicTableWrapper templateId={ item.element.template }>
      <DashboardTable>
        <TableRenderer item={ item } />
      </DashboardTable>
    </DynamicTableWrapper>
  </FinancialTableContextProvider>;
};

export default wrapper;
