/* eslint-disable max-len */
const examples = [
  'Sales 10.000 eur per month, starting Jan 2024',
  'Sales 10.000 eur every three months, starting Jan 2024',
  'Sales 10.000 eur every three months, starting Jan \'24 and ending Jun \'24',
  'Sales 1.000 eur one-time to Mar \'24, memo "Consulting"',
  'External services -1000 eur monthly starting Feb \'24, memo "Servers"',
  'Operating costs -500 eur per month from March \'24',
  'Salary -5000 monthly between Mar \'24 and Jul \'24',
  'Debt funding 100.000 eur one-time Jun \'24',
  'Equity funding 100.000 eur one-time Jun \'24',
  'Depreciation -1000 eur per month from April',
  'Investment in machines 10.000 eur one-time May \'24',
  'Subsidy 50.000 eur one-time Feb \'24',
  'Sales 2.000 eur one-time to Mar \'24, memo "Project X"',
];

export default examples;
