import { BudgetItemType } from '../../../types/budget.types';
import { Tag } from 'antd';
import { getDisplayName } from '../../../utils/common.utils';
import React from 'react';
import styles from './ItemTypeBadge.module.scss';
import clsx from 'clsx';

const ItemTypeBadge = ({ itemType }: { itemType: BudgetItemType }) => {

  if (!itemType) return null;

  const badgeTypeToClass = {
    'income': styles.income,
    'expense': styles.expense,
    'other': styles.other,
  };

  return <div className={ styles.tagContainer }>
    <Tag
      className={ clsx(styles.tag, badgeTypeToClass[ itemType.badgeType ]) }>
      { getDisplayName(itemType.name) }
    </Tag>
  </div>;
};

export default ItemTypeBadge;
