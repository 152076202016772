import React, { useCallback } from 'react';
import { CellRange, IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import useTransactionRequestParams from
  'components/financials/detailedView/hooks/useTransactionRequestParams';
import dayjs from 'dayjs';
import { TransactionLineRequestParams } from 'services/statutory.service';
import { financialsSlice, updateDetailedViewSettings } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { DetailedViewType, Period } from 'types/financials.types';
import { ReportType } from 'types/templates.types';
import { sectionKeys } from 'components/financials/detailedView/utils/detailedView.utils';
import { areAllSelectedRowsNew, isRangeSelection } from '../../utils/selection.utils';
import { budgetItemsFromSelection } from 'components/financials/utils/addRow.utils';

interface Props {
  templateId: number;
  gridRef: React.MutableRefObject<AgGridReact>;
  setLastFocusedCell: (cell: { row: number; col: string }) => void;
}

const useDetailedViewModal = ({ templateId, gridRef, setLastFocusedCell }: Props) => {
  const period = useAppSelector(state => state.financials.tables[ templateId ]?.period);
  const dispatch = useAppDispatch();

  const { getRequestParams } = useTransactionRequestParams({
    templateId,
    period,
    gridRef
  });

  const getPeriodFromParams = useCallback((params: TransactionLineRequestParams): Period => {
    if (!params) return period;
    const actualsOpen = params[ 'start_date' ] != null;
    const planOpen = params[ 'plan_start_date' ] != null;

    if (!actualsOpen && !planOpen) return period;

    let startDate = dayjs(params[ 'start_date' ]);
    let startDatePlan = dayjs(params[ 'plan_start_date' ]);
    let endDate = dayjs(params[ 'end_date' ]);
    let endDatePlan = dayjs(params[ 'plan_end_date' ]);
    startDate = startDate.subtract(startDate.utcOffset(), 'minute');
    startDatePlan = startDatePlan.subtract(startDatePlan.utcOffset(), 'minute');
    endDate = endDate.subtract(endDate.utcOffset(), 'minute');
    endDatePlan = endDatePlan.subtract(endDatePlan.utcOffset(), 'minute');
    return {
      startDate: startDate.unix(),
      endDate: endDate.unix(),
      actualsOpen,
      cadence: params.cadence,
      startDatePlan: startDatePlan.unix(),
      endDatePlan: endDatePlan.unix(),
      planOpen,
      isManuallySet: false,
    };
  }, [ period ]);

  const setDetailedViewData = useCallback((selection: CellRange[] | IRowNode[]) => {
    if (selection) {
      dispatch(financialsSlice.actions.clearDetailedViewSettings());
      const params = getRequestParams(selection);
      const _period = getPeriodFromParams(params[ ReportType.ACTUAL ][ 0 ]);
      if (areAllSelectedRowsNew(selection, gridRef.current)) {
        const budgetItems = budgetItemsFromSelection(selection, gridRef.current);

        if (budgetItems.length) {
          dispatch(updateDetailedViewSettings({
            type: DetailedViewType.MODAL,
            templateId: templateId,
            sectionKey: sectionKeys.budgeting,
            data: {
              budgetItems
            }
          }));
        }
      } else {
        dispatch(updateDetailedViewSettings({
          params,
          type: DetailedViewType.MODAL,
          sectionKey: _period.actualsOpen ? sectionKeys.transactions : sectionKeys.budgeting,
          period: _period,
          templateId
        }));
      }

      if (selection?.length && isRangeSelection(selection)) {
        const range = selection.at(0);

        // Refocus only if a single cell is selected
        if (range.startRow.rowIndex === range.endRow.rowIndex && range.columns.length === 1) {
          const startRow = range.startRow.rowIndex;
          const startColumn = range.startColumn.getColId();
    
          setLastFocusedCell({
            row: startRow,
            col: startColumn
          });
        }

      }

    }
  }, [ period, templateId ]);

  return { setDetailedViewData };
};

export default useDetailedViewModal;
