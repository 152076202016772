import { t } from 'i18next';
import { store } from 'store/store';
import * as yup from 'yup';

const validationSchema = () => yup.object().shape({
  dimensions: yup.array().of(
    yup.object().shape({
      dimension: yup.number().nullable(),
      labels: yup.array().of(
        yup.object().shape({
          dimensionItemId: yup.mixed().nullable(),
          percentage: yup.number()
            .when('dimensionItemId', {
              is: (dimensionItemId: number | null) => {
                return dimensionItemId != null && typeof dimensionItemId !== 'string';
              },
              then: () => yup.number()
                .transform((value) => Number.isNaN(value) ? 0 : value)
                .required(t('financials:dimension-split.modal.errors.value-required') ?? '')
                .min(0.01, t('financials:dimension-split.modal.errors.greaterThan-0') ?? '')
                .max(100, t('financials:dimension-split.modal.errors.lessThan-100') ?? '')
                .test(
                  'is-decimal',
                  t('financials:dimension-split.modal.errors.precision') ?? '',
                  value => /^\d*(\.\d{0,2})?$/.test(value.toString()),
                ),
              otherwise: () => yup.number().notRequired()
            })
        })
      )
    })
  )
    .test(
      'at-least-one-item',
      t('financials:dimension-split.modal.errors.label-required') ?? '',
      (value) => {
        return value?.every((dimension) => {
          return dimension?.labels?.some(
            (label) => label.dimensionItemId != null || typeof label.dimensionItemId !== 'string'
          );
        });
      })
    .test(
      'sum-validation',
      t('financials:dimension-split.modal.errors.sum-validation') ?? '',
      (value) => {
        return value 
          // Don't count (Multiple) labels
          ?.filter(
            (dimensionSec) => !dimensionSec?.labels?.
              every(label => typeof label.dimensionItemId === 'string')
          )
          .filter((dimensionSec) => dimensionSec.dimension != null)
          .every((dimensionSec) => sumLabels(dimensionSec.labels) === 100);
      }
    )
    .test(
      'at-least-two-valid-labels',
      t('financials:dimension-split.modal.errors.min-2-labels') ?? '',
      (value) => {
        return value.every(dimension => {
          const dimensionMap = store.getState()?.breakdowns?.dimensionMap;
          // Ignore rule if dimension cannot be unassigned
          // so split can be deleted by assigning all to one dimension item
          if (
            dimensionMap && dimension.dimension &&
            dimensionMap[ dimension.dimension ] &&
            !dimensionMap[ dimension.dimension ].canBeUnassigned) {
            return true;
          }

          if (dimension.dimension == null) return true;

          return dimension.labels.filter((label) => label.dimensionItemId != null).length >= 2;
        }
        );
      }
    )
});

export function sumLabels (labels?: {
  dimensionItemId?: unknown; percentage?: number | string; }[]
) {
  const sum = labels
    ?.filter((label) => label.dimensionItemId != null)
    .reduce((acc, item) => acc + Number(item.percentage ?? 0), 0);

  return Number(sum.toFixed(2));
}

export default validationSchema;
