import { useMemo } from 'react';

const useCellValidators = () => {

  const percentValidator = useMemo(() => ({
    min: 0,
    max: 100
  }), []);

  const dateNumberValidator = useMemo(() => ({
    min: 1,
    max: 31,
  }), []);

  const monthOffsetValidator = useMemo(() => ({
    min: -500,
    max: 500,
  }), []);

  return {
    percentValidator,
    dateNumberValidator,
    monthOffsetValidator,
  };
};

export default useCellValidators;
