import React, { useMemo } from 'react';
import { DashboardElementMapping, DashboardElementType } from 'types/dashboard.types';
import styles from './TitleRenderer.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  item: DashboardElementMapping<DashboardElementType.TITLE | DashboardElementType.SUBTITLE>;
}

const TitleRenderer = ({ item }: Props) => {
  const [ t ] = useTranslation('dashboard');

  const placeholder = useMemo(() => {
    return t(`panel.add-cards-page.placeholders.${ item.type }`);
  }, []);
  const name = useMemo(() => {
    return item.name || placeholder;
  }, [ item.name ]);

  return (
    <div className={ `${ styles.item } ${ styles[ item.type ] }` }>
      <h4>{ name }</h4>
    </div>
  );
};

export default TitleRenderer;
