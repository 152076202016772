import React, { useEffect, useRef, useState } from 'react';
import Modal from 'components/elements/modal/Modal';
import { selectSystemTemplate, selectTemplateList } from 'store/templates.slice';
import { ActionCreators } from 'redux-undo';
import { useTranslation } from 'react-i18next';
import { Input, InputRef } from 'antd';
import styles from './NewTemplateModal.module.scss';
import { createNewTemplate } from 'store/template.slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks/hooks';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const NewTemplateModal = ({ isVisible, onClose }: Props) => {
  const templateList = useAppSelector(selectTemplateList);
  const systemTemplates = useAppSelector(selectSystemTemplate);
  const [ t ] = useTranslation();
  const dispatch = useAppDispatch();
  const inputRef = useRef<InputRef>(null);

  const createTemplate = () => {
    dispatch(createNewTemplate(valueInput));
    dispatch(ActionCreators.clearHistory());
  };

  const onPressEnter = () => {
    if (!( Boolean(valueInput.trim() === '') || error) ) {
      createTemplate();
      onClose();
    }
  };

  const [ valueInput, setValueInput ] = useState('');
  const [ error, setError ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [ isVisible ]);

  useEffect(() =>{
    const findTheSameName =
      [ ...templateList, ...systemTemplates ].find((el) => el.title === valueInput.trim());
    if (valueInput === '')
      setError(true);
    else if (findTheSameName)
      setError(true);
    else setError(false);

  } ,[ valueInput ]);

  return (
    <Modal
      title={ t('modals.new-template-modal.title', { ns: 'financials' }) }
      width={ 650 }
      isVisible={ isVisible }
      onConfirm={ createTemplate }
      onClose={ () => {
        setValueInput('');
        onClose();
      } }
      closeText={ t('modals.new-template-modal.cancel', { ns: 'financials' }) }
      okText={ t('modals.new-template-modal.accept', { ns: 'financials' }) }
      confirmDisabled={ Boolean(valueInput.trim() === '') || error }
    >
      <div className={ styles.container }>
        <span className={ `${ styles.errorMessage }
        ${ (error && valueInput !== '') ? styles.showErrorMessage : '' }` }>
          { t('modals.new-template-modal.error', { ns: 'financials' }) }
        </span>
        <Input
          value={ valueInput }
          ref={ inputRef }
          onChange={ (e) =>setValueInput(e.target.value) }
          className={ styles.inputTemplateName }
          placeholder={ t('modals.new-template-modal.placeholder', { ns: 'financials' }) }
          autoFocus={ true }
          onPressEnter={ onPressEnter }
        />
      </div>
    </Modal>
  );
};

export default NewTemplateModal;
