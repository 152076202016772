import React, { RefObject, useCallback, useContext, useMemo, useRef, useState, } from 'react';
import { AgGridReact } from 'ag-grid-react';
import Capsule from 'components/elements/capsule/Capsule';
import HighlightedText from 'components/elements/highlightedText/HighlightedText';
import {
  AssignFunction,
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import { useSearchable } from 'context/SearchableContext';
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  DimensionItem as DimensionItemType,
  EXTERNAL_BREAKDOWN_TYPES,
  FilterList,
} from 'types/filterTable.types';
import { RowType } from 'types/financials.types';
import { getDisplayName } from 'utils/common.utils';
import {
  canBeAssignedDimension,
  canDimensionItemBeChanged,
  getDimensionDisplayName
} from 'utils/financials.utils';
import styles from './DimensionItem.module.scss';
import { Dropdown, Tooltip } from 'antd';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as BinIcon } from 'assets/icons/bin.svg';
import { useTranslation } from 'react-i18next';
import Modal from 'components/elements/modal/Modal';
import AddEditDimensionModal
  from 'components/elements/dimensionLeftPanel/addEditDimensionModal/AddEditDimensionModal';
import { selectCustomFormula } from 'store/formula.slice';
import { toggleAltKey } from 'store/events.slice';
import { useDrag } from 'react-dnd';
import { createNode } from 'store/template.slice';
import { createDimensionItemNode } from 'utils/template.utils';
import { FinancialTableContext } from 'context/FinancialTableContext';
import { getUUID } from 'utils/templates.utils';
// eslint-disable-next-line max-len
import useDropZoneEffect from 'components/elements/dimensionLeftPanel/listView/dimensionDetails/detailsList/hooks/useDropZoneEffect';
import { IRowNode } from 'ag-grid-community';
import { setHoverDimension } from 'store/templates.slice';
import clsx from 'clsx';
import useFinancialTableDragSelection from 'hooks/useFinancialTableDragSelection';
import { findDimensionFilter, toggleFilteredElement } from '../../dimensionDetails.util';

interface Props{
  item: DimensionItemType;
  stagingFilter?: FilterList;
  setStagingFilter?: React.Dispatch<React.SetStateAction<FilterList>>;
  view?: number;
  gridRef?: RefObject<AgGridReact>;
  onDelete?: () => Promise<void>;
  onEdit?: (name: string) => Promise<void>;
  canDelete?: boolean;
  canEdit?: boolean;
  isTemplateBuilder: boolean;
  isLabeling?: boolean;
  assignLabels?: AssignFunction;
}

const DimensionItem = ({
  item,
  stagingFilter,
  setStagingFilter,
  view,
  gridRef,
  onDelete,
  onEdit,
  canDelete = false,
  canEdit = false,
  isTemplateBuilder,
  isLabeling = false,
  assignLabels
}: Props) => {
  const financialContext = useContext(FinancialTableContext);

  const { state: { search } } = useSearchable();
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();
  const customFormula = useAppSelector(selectCustomFormula);
  const customFormulaIsOpen = useMemo(() => customFormula !== null ,[ customFormula ]);
  const { getRowsToLabel } = useFinancialTableDragSelection({ gridRef });
  const [ contextMenu, setContextMenu ] = useState(false);
  const [ modalDeleteDimensionItem, setModalDeleteDimensionItem ] = useState(false);
  const [ modalEditDimensionItem, setModalEditDimensionItem ] = useState(false);

  const dimensionMap = useAppSelector(state => state.breakdowns.dimensionMap);

  const dropId = useRef(getUUID());

  let displayName = getDimensionDisplayName(item);

  const canDrop = useMemo(() => {
    const dimension = dimensionMap[ item.dimensionId ];
    if (dimension.relation === 'CONTRACT' &&
      (!financialContext || !financialContext.state.customSettings.panelSettings.customerId)) {
      return false;
    }
    if (EXTERNAL_BREAKDOWN_TYPES.includes(dimension.type)) {
      return true;
    }
    return dimension.canBeAssigned;
  }, [ dimensionMap, item ]);

  const [ { isDragging }, drag ] = useDrag(() => {
    return {
      type: 'tag',
      item:  { rowData: item, children: [], childrenNodes: [], type: RowType.DIMENSION_ITEM },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    };
  });
  const onMouseLeaveDragTag = () => {
    dispatch(setHoverDimension(null));
  };

  const onMouseEnterDragTag = useCallback((event: React.MouseEvent) => {
    dispatch(toggleAltKey(event.altKey));

    if (!isDragging) {
      dispatch(setHoverDimension([ item?.id ]));
    } else if (isDragging)
      dispatch(setHoverDimension(null));
  },[]);

  if (item.account) {
    displayName = `${ item.account.number } ${ displayName }`;
  }

  const onDoubleClick = useCallback(() => {
    if (!customFormulaIsOpen) {
      const newDimension = createDimensionItemNode(item);
      dispatch(createNode(newDimension, null, 'none'));
    }
  },[]);

  const assignNodesCallback = useCallback<AssignFunction>((nodes, breakdownItemData, showModal) => {
    if (nodes.length !== 0) {
      assignLabels(nodes, breakdownItemData, showModal);
    }
  }, [ item, assignLabels ]);

  const onAssign = useCallback((nodes: IRowNode[], showModal: boolean) => {
    const _nodes = getRowsToLabel(nodes);
    assignNodesCallback(_nodes, item, showModal);
  }, [ assignNodesCallback ]);

  useDropZoneEffect({
    dropId: dropId.current.toString(),
    gridRef,
    onAssign,
    canDrop,
    isDisabled: (nodes) => {
      return nodes
        .flatMap(node => !node.data ? node.childrenAfterFilter : node)
        .every(node => canBeAssignedDimension(node) && !canDimensionItemBeChanged(node, item));
    }
  });

  const isActive = useMemo(() => {
    if (isTemplateBuilder || isLabeling)
      return true;

    if (!stagingFilter)
      return false;

    const dimensionFilterIndex = findDimensionFilter(stagingFilter, item.dimensionId);
    if (dimensionFilterIndex === -1)
      return true;

    const isSelected =
      stagingFilter[ dimensionFilterIndex ].excludedItems.find((id) => id === item.id);
    return !isSelected;
  }, [ item.id, item.dimensionId, stagingFilter, setStagingFilter, isLabeling ]);

  const onClick = useCallback(() => {
    if (!setStagingFilter || isLabeling) return;

    setStagingFilter(prev => {
      const newFilters = cloneDeep(prev);
      const existingFilterIndex = findDimensionFilter(newFilters, item.dimensionId);
      if (existingFilterIndex > -1) {
        newFilters[ existingFilterIndex ] =
          toggleFilteredElement(newFilters[ existingFilterIndex ], item.id);
        return newFilters;
      } else {
        newFilters.push({
          dimension: item.dimensionId,
          excludedItems: [ item.id ],
          excludeUnassigned: false,
        });
      }
      return newFilters;
    });
  }, [ item, setStagingFilter, isLabeling ]);

  const getDropdownItems = useCallback(() => {
    const items = [];
    if (canDelete) items.push(
      <div
        className={ styles.dropdownElement }
        onClick={ () => {
          setModalDeleteDimensionItem(true);
          setContextMenu(false);
        } }
      >
        <BinIcon className={ styles.icon } height={ 15 } width={ 15 }/>
        { t('left-panel.delete-label') }
      </div>
    );
    if (canEdit) items.push(
      canEdit && <div
        className={ styles.dropdownElement }
        onClick={ () => {
          setModalEditDimensionItem(true);
          setContextMenu(false);
        } }
      >
        <EditIcon width={ 15 } height={ 15 } className={ styles.icon }/>
        { t('left-panel.edit-name') }
      </div>
    );
    return items;
  }, [ canDelete, canEdit ]);

  return (
    <>
      <Modal
        title={
          <div className={ styles.modalTitle }>
            <BinIcon className={ styles.icon }/>
            { t('left-panel.delete-dimension.delete-label') }
          </div>
        }
        okButtonProps={ { danger: true } }
        description={ t('left-panel.delete-dimension.description',
          { name: getDimensionDisplayName(item) }) }
        isVisible={ modalDeleteDimensionItem }
        className={ styles.modalDelete }
        closeText={ t('left-panel.delete-dimension.cancel') }
        okText={ t('left-panel.delete-dimension.delete') }
        onClose={ () => setModalDeleteDimensionItem(false) }
        onConfirm={ () => {
          onDelete().then(() => {
            setModalEditDimensionItem(false);
          });
        } }
      />
      <AddEditDimensionModal
        isVisible={ modalEditDimensionItem }
        onClose={ () => setModalEditDimensionItem(false) }
        onConfirm={ (name) => onEdit(name).then(() => {
          setModalEditDimensionItem(false);
        }) }
        title={ <div>
          <EditIcon/>
          { t('left-panel.edit-dimension-label') }
        </div> }
        label={ t('left-panel.add-dimension.custom-dimension-name') }
        okText={ t('left-panel.edit') }
        defaultValue={ getDisplayName(item.customName) }
      />
      <Tooltip
        mouseEnterDelay={ .4 }
        title={
          (!view && getDimensionDisplayName(item).length >
          (item.relation === 'ACCOUNT' ? 19 : 23) ) ? getDimensionDisplayName(item) : null
        }
      >
        <Dropdown
          open={ contextMenu }
          className={ `${ styles.dropdownWrapper }` }
          onOpenChange={ (e) => {
            setContextMenu(e);
          } }
          dropdownRender={
            () => (
              onEdit && onDelete && (canDelete || canEdit) ?
                <div className={ styles.contextMenu }>
                  { getDropdownItems() }
                </div>
                : <></>
            )
          }
          trigger={ [ 'contextMenu' ] }
        >
          <div
            onDoubleClick={ onDoubleClick }
            onMouseLeave={ isTemplateBuilder && onMouseLeaveDragTag }
            onMouseEnter={ isTemplateBuilder && onMouseEnterDragTag }
            ref={ isTemplateBuilder ? drag : null }
            onClick={ (e) => {
              if (e.type === 'contextmenu') {
                if (onEdit && onDelete) setContextMenu(true);
              }
            } }>
            <Capsule
              id={ dropId.current.toString() }
              isActive={ isActive }
              className={ clsx(styles.capsule, { [ styles.column ]: view }) }
              onClick={ onClick }
            >
              <HighlightedText
                text={ displayName }
                highlight={ search }
              />
            </Capsule>
          </div>

        </Dropdown>
      </Tooltip>
    </>
  );
};

export default DimensionItem;
