import { InvoicingFrequency } from './../../../types/contracts.types';
import { BudgetItem } from 'types/budget.types';
import * as yup from 'yup';

const accountsSchema = yup.object().shape({
  primary: yup.number().required(),
  counter: yup.number().nullable(),
});
const validationSchema = yup.object().shape({
  accounts: accountsSchema.required(),
  amountFormula: yup.string().required(),
  memo: yup.string().required(),
  invoicingFrequency: yup.string().required().oneOf(Object.values(InvoicingFrequency)),
  startDate: yup.string().required(),
  itemType: yup.number().required(),
});

export const isBudgetItemValid = (budgetItem: BudgetItem): boolean => {
  return validationSchema.isValidSync(budgetItem);
};
