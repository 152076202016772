import React from 'react';
import useWebsocket from '../hooks/useWebsocket';

interface Props {
  children: React.ReactNode;
}
const WebsocketProvider = ({ children }: Props) => {
  useWebsocket();

  return <>
    { children }
  </>;
};

export default WebsocketProvider;
