import React, { useState } from 'react';
import styles from 'components/dashboard/panel/cardSettingsPage/forms/Forms.module.scss';
import { Control, Controller, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TemplateDropdown from 'components/elements/templateDropdown/TemplateDropdown';
import FlatSelect from 'components/elements/flatSelect/FlatSelect';

interface Props<T> {
  control: Control<T, object>;
  name: Path<T>;
  value: string;
  onChange?: (id: number) => void;
}
const TemplateSelect = <T,>({ control, name, value, onChange }: Props<T>) => {
  const [ open, setOpen ] = useState(false);
  const [ t ] = useTranslation('dashboard');

  return <>
    <Controller
      control={ control }
      name={ name }
      render={ ({ field }) => {
        return <div className={ styles.container }>
          <div className={ styles.label }>
            { t('panel.card-settings.template') }
          </div>
          <TemplateDropdown
            isSelected={ () => false }
            open={ open }
            onOpenChange={ setOpen }
            onSelect={ (id) => {
              field.onChange(id);
              field.onBlur();
              onChange && onChange(id);
              setOpen(false);
            } }
            getPopupContainer={ () => document.body }
            isDisabled={ () => false }
            rowHoverActions={ [] }
            multiSelect={ false }
          >
            <FlatSelect open={ false } value={ value } className={ styles.input } />
          </TemplateDropdown>
        </div>;
      } }
    />
  </>;
};

export default TemplateSelect;
