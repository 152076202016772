import { useContractsContext } from 'context/ContractsContext';
import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import styles from './ContractsTable.module.scss';
import useContractsGrid from 'components/contracts/contractsTable/useContractsGrid';
import { Customer } from 'types/revenueRecognition.types';
import { notifyError } from 'utils/notifications.utils';
import { useTranslation } from 'react-i18next';
import Loader from 'components/elements/loader/Loader';
import { IRowNode } from 'ag-grid-community';
import { columnTypes, defaultColDef } from 'utils/gridUtils.utils';
import { useNavigate } from 'react-router';
import { routes } from 'utils/config.utils';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import contractsService from '../../../services/contracts.service';
import { useSearchable } from '../../../context/SearchableContext';
import { GroupByStatusList } from 'types/contracts.types';

const ContractsTable = () => {

  const [ t ] = useTranslation('revenueRecognition');
  const gridRef = useRef(null);
  const useGrid = useContractsGrid(styles, gridRef);
  const navigate = useNavigate();

  const [ isLoading, setIsLoading ] = useState(false);

  const {
    dispatch: dispatchContext,
    state: { sortById, groupById }
  } = useContractsContext();

  const { state: { search } } = useSearchable();

  const sortAlphabetical = () => {
    if (sortById === 0) return 'asc';
    else if (sortById === 1) return 'desc';
    else return null;
  };

  gridRef.current?.columnApi?.applyColumnState({
    state: [
      { colId: 'name', sort: sortAlphabetical() }
    ],
    defaultState: { sort: null },
  },[ sortById ]);

  useEffect(() => {
    setIsLoading(true);
    contractsService.getCustomers({ limit: 999999 }).then(res => {
      const data = [];
      for (const cp of res.data.results) {
        data.push({
          ...cp,
          firstLetter: cp.name[ 0 ],
          contractStatus: cp.contractStatus || 'empty'
        });
      }
      dispatchContext({ type: 'setCounter', payload: data.length });
      gridRef.current?.api.setRowData(data);

    }).catch((e) => {
      notifyError(
        t('notifications.unexpected-error.message',
          { ns: 'common' })
      );
      throw e;
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    gridRef?.current?.api?.onFilterChanged();
    dispatchContext({ type: 'selectParameterGroupBy', payload: GroupByStatusList.ALL });

    if (gridRef?.current?.api?.getRenderedNodes().length === 0) {
      gridRef.current.api.showNoRowsOverlay();
    } else gridRef?.current?.api?.hideOverlay();

  }, [ search ]);

  const columnDefs = () => useGrid.getColumnDefs(groupById);

  const rowTitleRenderer = (params: { value: string; node: IRowNode; data: Customer}) => {
    let text = params.value;
    if (groupById === 1) {
      text = t('status.' + params.value);
    }
    return (
      <div className={ styles.headerTitle }>{ text }</div>
    );
  };

  const noRowsOverlayComponent = () => {
    return (
      <div className={ styles.searchResults }>
        <SearchIcon className={ styles.icon } />
        <p>No results to show.</p>
      </div>
    );
  };

  return (
    <div className={ `ag-theme-alpine ${ styles.table }` } >
      <Loader isActive={ isLoading }/>
      <AgGridReact
        ref={ gridRef }
        animateRows={ true }
        columnDefs={ columnDefs() }
        getDataPath={ row => [ row.id ] }
        getRowId={ (params) => params.data.id }
        defaultColDef={ { ...defaultColDef(styles) } }
        columnTypes={ columnTypes() }
        rowHeight={ 60 }
        groupDefaultExpanded={ 1 }
        suppressContextMenu={ true }
        noRowsOverlayComponent={ noRowsOverlayComponent }
        noRowsOverlayComponentParams={ null }
        suppressNoRowsOverlay={ false }
        groupDisplayType='groupRows'
        isExternalFilterPresent={ useGrid.isExternalFilterPresent }
        doesExternalFilterPass={ useGrid.doesExternalFilterPass }
        groupRowRenderer={ rowTitleRenderer }
        onRowClicked={
          (params) => navigate(`${ routes.manage.contracts }/${ params.data.id }`)
        }
      />
    </div>
  );
};

export default ContractsTable;
