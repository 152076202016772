import DateRangeSignifier from 'components/elements/dateRangeSignifier/DateRangeSignifier';
import RangePickerModal from 'components/elements/rangePicker/RangePickerModal';
import { useFinancialTable } from 'context/FinancialTableContext';
import React, { useCallback, useMemo } from 'react';
import { selectPeriod, setPeriod } from 'store/financials.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { Period } from 'types/financials.types';
import { getStartAndEndDate } from 'utils/financials.utils';
import styles from './TopBar.module.scss';
import CloseButton from '../chartsButton/closeButton/CloseButton';
import { ReactComponent as ChartIcon } from 'assets/icons/chartIcon.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/filter.svg';
import { useChartContext } from '../context/ChartContext';
import Switcher from 'components/elements/switcher/Switcher';
import { Cadence } from 'types/form.types';
import { setRangeType } from 'utils/period.utils';
import useReportQuery from 'components/financials/financialTable/hooks/useReportQuery';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';

const TopBar = () => {
  const { state: { templateId } } = useFinancialTable();
  const period = useAppSelector(selectPeriod(templateId));
  const { state: { templateNodeUuids } } = useChartContext();
  const { data: report } = useReportQuery({ templateId, period, templateNodeUuids });
  const title = report.title;
  const {
    dispatch: dispatchChart,
    state: { settingsPanel, disableClose }
  } = useChartContext();
  const dispatch = useAppDispatch();
  const { actualsOpen, planOpen } = period;

  const onRangeChange = useCallback((newPeriod: Period) => {
    dispatch(setPeriod({
      templateId,
      period: newPeriod,
      isDefaultRange: false,
    }));
    setRangeType(templateId, false);
  }, [ templateId ]);

  const { start, end } = useMemo(() => getStartAndEndDate({
    startDate: actualsOpen ? period.startDate : null,
    endDate: actualsOpen ? period.endDate : null,
    startDatePlan: planOpen ? period.startDatePlan : null,
    endDatePlan: planOpen ? period.endDatePlan : null
  }), [ period, planOpen, actualsOpen ]);

  const toggleChartSettings = useCallback(() => {
    dispatchChart({ type: 'TOGGLE_SETTINGS_PANEL' });
  }, []);

  const switcherOptions = useMemo(() => [
    { label: 'W', value: Cadence.week },
    { label: 'M', value: Cadence.month },
    { label: 'Q', value: Cadence.quarter },
    { label: 'Y', value: Cadence.year }
  ], []);

  const switcherValue = useMemo(() => {
    return period.cadence;
  }, [ period ]);

  const onSwitcherChange = useCallback((value: Cadence) => {
    dispatch(setPeriod({
      templateId,
      period: { ...period, cadence: value }
    }));
  }, [ period ]);

  return (
    <div className={ styles.top }>
      <div className={ styles.leftSection }>
        <ChartIcon />
        <div className={ styles.title }>
          { title }
        </div>
        <DateRangeSignifier
          className={ styles.dateRangeSignifier }
          cadence={ period.cadence }
          startDate={ start }
          endDate={ end }
        />
      </div>
      <div className={ styles.rightSection }>
        <div className={ `${ styles.wrapper } ${ disableClose ? styles.noBorder : '' }` }>
          <Switcher
            className={ styles.cadenceSwitcher }
            value={ switcherValue }
            options={ switcherOptions }
            onChange={ onSwitcherChange }
          />
          <RangePickerModal
            onSubmit={ onRangeChange }
            cadence={ period.cadence }
            defaultPeriod={ period }
            planIsOpen={ planOpen }
            actualsIsOpen={ actualsOpen }
            templateId={ templateId }
          />
          <GhostIconButton active={ settingsPanel } onClick={ toggleChartSettings }>
            <SettingsIcon />
          </GhostIconButton>
        </div>
        {
          !disableClose ? <CloseButton /> : null
        }
      </div>
    </div>
  );
};

export default TopBar;
