import { FormulaItem, FormulaItemType } from 'types/templates.types';
import { isFormulaItemNumberProvider } from 'utils/template.utils';
import { useCallback } from 'react';
import { DECIMAL_SEPARATOR } from '../utils/formulaBuilder.utils';

const isOperator = (type: FormulaItemType) => {
  return type === FormulaItemType.OPERATOR || type === FormulaItemType.LOGICAL_OPERATOR;
};

const isLogicalOperator = (type: FormulaItemType) => {
  return type === FormulaItemType.LOGICAL_OPERATOR;
};

const useValidation = () => {
  const validate = useCallback((formula: FormulaItem[]) => {
    if (formula.length === 0) return true;

    return !(
      !areBracketsClosed(formula) ||
      isFormulaStartingWithIncorrectOperator(formula) ||
      areBracketsNotFollowedByOperator(formula) ||
      isAnyNumberWithoutOperator(formula) ||
      isDecimalNotClosed(formula) ||
      isNumberStartingWithZero(formula) ||
      areElementsJoinedWithOperators(formula) ||
      areLogicalOperatorsJoinedWithStatutory(formula)
    );
  }, []);

  const areBracketsClosed = useCallback((formula: FormulaItem[]) => {
    let count = 0;
    formula.forEach((el) => {
      if (el.value === '(') count++;
      else if (el.value === ')' && --count < 0) return false;
    });

    return count === 0;
  }, []);

  const isDecimalNotClosed = useCallback((formula: FormulaItem[]) => {
    return formula.some((el) =>
      el.type === FormulaItemType.CONSTANT &&
      el.value.includes('.') &&
      el.value.at(-1) === DECIMAL_SEPARATOR
    );
  }, []);

  const isNumberStartingWithZero = useCallback((formula: FormulaItem[]) => {
    return formula.some((el) =>
      el.type === FormulaItemType.CONSTANT &&
      el.value.startsWith('0') &&
      !el.value.startsWith(`0${ DECIMAL_SEPARATOR }`)
    );
  }, []);

  const isAnyNumberWithoutOperator = useCallback((formula: FormulaItem[]) => {
    return formula.filter(el => el.type === FormulaItemType.CONSTANT).some((el) =>
      (isFormulaItemNumberProvider(el.prev) || el.prev === FormulaItemType.CONSTANT) ||
        (isFormulaItemNumberProvider(el.next) || el.next === FormulaItemType.CONSTANT)
    );
  }, []);

  const isFormulaStartingWithIncorrectOperator = useCallback((formula: FormulaItem[]) => {
    return (isOperator(formula[ 0 ].type) && formula[ 0 ].value !== '-') ||
      isOperator(formula[ formula.length - 1 ].type) ||
      formula[ formula.length - 1 ].value === '(';
  }, []);

  const areBracketsNotFollowedByOperator = useCallback((formula: FormulaItem[]) => {
    return formula.some(el =>
      (el.value === '(' && isOperator(el.next)) ||
      (el.value === ')' && isOperator(el.prev))
    );
  }, []);

  const isBracket = useCallback((type: FormulaItemType) => {
    return type === FormulaItemType.BRACKET;
  }, []);

  const isOpenBracketNotPrecededByOperator = useCallback((item: FormulaItem) => {
    return item.value === '(' &&
      item.prev &&
      (!isOperator(item.prev) && !isBracket(item.prev));
  }, []);

  const isCloseBracketNotFollowedByOperator = useCallback((item: FormulaItem) => {
    return item.value === ')' &&
      item.next &&
      (!isOperator(item.next) && !isBracket(item.next));
  }, []);

  const areElementsJoinedWithOperators = useCallback((formula: FormulaItem[]) => {
    const isElementWithReverseSign = (element: FormulaItem, index: number, prev: boolean) => {
      const offsetIndex = prev ? index - 1 : index;
      return isOperator(element.type) &&
        formula[ offsetIndex + 1 ]?.value === '-' &&
        formula[ offsetIndex + 2 ]?.type === FormulaItemType.CONSTANT;
    };
    
    return formula.some((el, i) => {
      const type = el.type;

      return (!isBracket(type) && type === el.prev && !isElementWithReverseSign(el, i, true)) ||
        (!isBracket(type) && type === el.next && !isElementWithReverseSign(el, i, false)) ||
        (isFormulaItemNumberProvider(type) && isFormulaItemNumberProvider(el.next)) ||
        isOpenBracketNotPrecededByOperator(el) ||
        isCloseBracketNotFollowedByOperator(el);
    });
  }, []);

  const areLogicalOperatorsJoinedWithStatutory = useCallback((formula: FormulaItem[]) => {
    const logicalOperators = formula.filter(el => isLogicalOperator(el.type));
    const isLogicalOperatorsJoinedWithStatutoryOrBrackets = logicalOperators.some(el => {
      return (
        !(isFormulaItemNumberProvider(el.prev) || isBracket(el.prev)) ||
        !(isFormulaItemNumberProvider(el.next) || isBracket(el.next))
      );
    });
    const statutoryElementsWithLogicalOperator = formula.filter(
      el => isFormulaItemNumberProvider(el.type) && (
        isLogicalOperator(el.next) || isLogicalOperator(el.prev)
      )
    );
    const areElementsJoinedOnlyWithLogicalOperators = statutoryElementsWithLogicalOperator.some(
      el => !(
        (isLogicalOperator(el.next) && isLogicalOperator(el.prev)) ||
          (isLogicalOperator(el.next) && !isOperator(el.prev)) ||
          (isLogicalOperator(el.prev) && !isOperator(el.next))
      )
    );

    return isLogicalOperatorsJoinedWithStatutoryOrBrackets ||
      areElementsJoinedOnlyWithLogicalOperators;
  }, []);

  return {
    validate
  };
};

export default useValidation;
