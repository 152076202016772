import { Dimension, DimensionFilter, EXTERNAL_BREAKDOWN_TYPES, FilterList }
  from 'types/filterTable.types';

export const findDimensionFilter = (stagingFilters: FilterList, dimensionId: number) =>
  stagingFilters.findIndex((filter) => filter.dimension === dimensionId);

export const toggleFilteredElement = (filter: DimensionFilter, dimensionItemId: number) => {
  const itemIndex = filter.excludedItems.findIndex(id => id === dimensionItemId);
  if (itemIndex > -1) {
    filter.excludedItems.splice(itemIndex, 1);
  } else {
    filter.excludedItems.push(dimensionItemId);
  }
  return filter;
};

export const getFilterCount = (dimension: Dimension, filter: DimensionFilter) => {
  const canBeUnassigned = dimension?.canBeUnassigned ||
    EXTERNAL_BREAKDOWN_TYPES.includes(dimension?.type);

  const allItems = dimension?.items?.length + (canBeUnassigned ? 1 : 0);
  // Only count unassigned as excluded if the dimension can be unassigned
  const isUnassignedExcluded = canBeUnassigned && filter.excludeUnassigned ? 1 : 0;
  const excludedItems = filter.excludedItems.length + isUnassignedExcluded;
  const currentItems = allItems - excludedItems;

  return [ allItems, currentItems ];
};
