import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { selectTemplate } from 'store/template.slice';
import { getFormulaItemTypeFromNode } from 'utils/template.utils';
import {
  FormulaItem,
  isSubtotalNode,
  isTotalNode,
  TemplateNode,
  TemplateNodeId,
} from 'types/templates.types';
import useFormula
  from 'components/templates/customTemplate/formulaBuilder/hooks/useFormula';
import useNodeToTag
  from 'components/templates/customTemplate/formulaBuilder/hooks/useNodeToTag';
import { operators } from '../utils/formulaBuilder.utils';
import { RowType } from 'types/financials.types';
import { updateFormula } from 'store/formula.slice';

const PLUS_OPERATOR = operators.find((op) => op.value === '+');

const useFormulaTotalEffect = () => {
  const template = useAppSelector(selectTemplate);
  const dispatch = useAppDispatch();
  const { addElement, addOperator } = useFormula();
  const { getTemplateTagFromNode } = useNodeToTag();

  const canAddToFormula = useCallback((node: TemplateNode) => {
    const types = [ RowType.DIMENSION_ITEM, RowType.FINANCIALS ];
    return types.includes(node.type);
  }, []);

  const getNodesAbove = useCallback((nodes: TemplateNodeId[], nodeId: TemplateNodeId) => {
    const nodeIndex = nodes.indexOf(nodeId);
    return nodes.slice(0, nodeIndex).filter((n) => {
      const node = template.nodes[ n ];
      return canAddToFormula(node);
    });
  }, [ template ]);

  const getNodesForTotal = useCallback((node: TemplateNode): TemplateNodeId[] => {
    const nodeId = node.id;
    if (node.parent) {
      const parentNode = template.nodes[ node.parent ];
      return getNodesAbove(parentNode.children, nodeId);
    }
    return getNodesAbove(template.roots, nodeId);
  }, [ template, getNodesAbove ]);

  const createFormula = useCallback((nodes: TemplateNodeId[]): FormulaItem[] => {
    let formula: FormulaItem[] = [];
    let position = 0;
    nodes.forEach((nodeId, index) => {
      const node = template.nodes[ nodeId ];
      const tag = getTemplateTagFromNode(node);

      const {
        formula: _formula,
        position: _position
      } = addElement(tag, getFormulaItemTypeFromNode(node), formula, position);
      formula = _formula;
      position = _position + 1;
      if (index < nodes.length - 1) {
        const {
          formula: __formula,
          position: __position
        } = addOperator(PLUS_OPERATOR, formula, position);
        formula = __formula;
        position = __position + 1;
      }
    });
    return formula;
  }, [ template, getTemplateTagFromNode ]);

  useEffect(() => {
    const newTotal = Object.values(template.nodes).find(n => {
      return n.type === RowType.FORMULA && (isTotalNode(n) || isSubtotalNode(n));
    });
    if (!newTotal) {
      return;
    }
    const nodesForTotal = getNodesForTotal(newTotal);
    const formula = createFormula(nodesForTotal);

    dispatch(updateFormula(formula, newTotal.id));
  }, [ template ]);

  return null;
};

export default useFormulaTotalEffect;
