import {
  DECIMAL_SEPARATOR,
  OperatorsType,
} from 'components/templates/customTemplate/formulaBuilder/utils/formulaBuilder.utils';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { selectCustomFormula, updateCustomFormula } from 'store/formula.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import {
  FormulaItem,
  FormulaItemType,
  isDimensionItemNode,
  isFinancialsNode,
  TemplateNode,
} from 'types/templates.types';
import { getDisplayName, isDisplayNameEmpty } from 'utils/common.utils';
import { getUUID } from 'utils/templates.utils';

const useFormula = () => {
  const customFormula = useAppSelector(selectCustomFormula);
  const dispatch = useAppDispatch();

  const updateFormulaRelations = useCallback((list: FormulaItem[]) => {
    const copyList = cloneDeep(list);
    copyList.forEach((element, index) => {
      if (index === 0) {
        element.prev = null;
      } else {
        element.prev = copyList[ index - 1 ].type;
      }
      if (index === copyList.length - 1) {
        element.next = null;
      } else {
        element.next = copyList[ index + 1 ].type;
      }
    });
    return copyList;
  }, []);

  const getPreviousElement = useCallback((position: number) => {
    if (position === 0) {
      return null;
    }
    return customFormula[ position - 1 ]?.type;
  }, [ customFormula ]);

  const addElement = useCallback((
    item: TemplateNode,
    type: FormulaItemType,
    position: number = customFormula.length
  ) => {
    let copyList = cloneDeep(customFormula);
    const prev = getPreviousElement(position);
    const id = type === FormulaItemType.DIMENSION_ITEM &&
    isDimensionItemNode(item) ? item.rowData.id : item?.id;
    const name = isFinancialsNode(item) && !isDisplayNameEmpty(item?.rowData.shortName) ?
      item?.rowData.shortName : item?.rowData?.name;
    const newElement: FormulaItem = {
      id,
      value: getDisplayName(name),
      prev,
      next: null,
      type: type,
      key: getUUID(),
      offset: null,
      rollingAverage: 3,
    };

    copyList.splice(position, 0, newElement);
    copyList = updateFormulaRelations(copyList);
    dispatch(updateCustomFormula(copyList));
    return copyList;
  }, [ customFormula ]);

  const addOperator = useCallback((el: OperatorsType, position = customFormula.length) => {
    let copyList = cloneDeep(customFormula);
    const next = copyList[ position ]?.type;
    const prev = getPreviousElement(position);

    const newOperator: FormulaItem = {
      value: el.value,
      prev,
      next,
      type: el.type,
      key: getUUID()
    };

    const index = position ?? copyList.length;
    copyList.splice(index, 0, newOperator);
    copyList = updateFormulaRelations(copyList);
    dispatch(updateCustomFormula(copyList));
    return index;
  }, [ customFormula ]);

  const addConstant = useCallback((value: string, position = customFormula.length) => {
    let copyList = cloneDeep(customFormula);
    const next = copyList[ position ]?.type;
    const prev = getPreviousElement(position);

    if (prev === FormulaItemType.CONSTANT) {
      const isNumericValue = !isNaN(Number(value));
      const isComma = value === DECIMAL_SEPARATOR;
      const previousValue = copyList[ position - 1 ].value;
      const previousValueTrimmed = previousValue.at(-1) === DECIMAL_SEPARATOR ?
        previousValue.slice(0, -1) : previousValue;
      const isPreviousNumeric = !isNaN(Number(previousValueTrimmed));

      if ((isNumericValue || isComma) && isPreviousNumeric) {
        copyList[ position - 1 ].value += value;
        dispatch(updateCustomFormula(copyList));
        return copyList;
      }
    }

    const newElement: FormulaItem = {
      value,
      prev,
      next,
      type: FormulaItemType.CONSTANT,
      key: getUUID()
    };

    copyList.splice(position, 0, newElement);
    copyList = updateFormulaRelations(copyList);
    dispatch(updateCustomFormula(copyList));
    return copyList;
  }, [ customFormula ]);

  return {
    addElement,
    addOperator,
    addConstant
  };
};

export default useFormula;
