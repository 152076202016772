import React from 'react';
import styles from './Contracts.module.scss';
import { useTranslation } from 'react-i18next';
import TableTopBar from './tableTopBar/TableTopBar';
import { ContractsContextProvider } from 'context/ContractsContext';
import TableBottomBar from './tableBottomBar/TableBottomBar';
import ContractsTable from './contractsTable/ContractsTable';
import SearchBar from '../elements/searchBar/SearchBar';
import { SearchableContextProvider } from '../../context/SearchableContext';
import OrganizationSelect from '../elements/organizationSelect/OrganizationSelect';

const Contracts = () => {

  const [ t ] = useTranslation('navigation');

  return (
    <ContractsContextProvider>
      <SearchableContextProvider>
        <div className={ styles.contracts }>

          <div className={ styles.topBar }>
            <h1 className={ styles.header }>
              <OrganizationSelect />
              { t('links.manage.contracts', { ns: 'navigation' }) }
            </h1>
            <div className={ styles.searchContainer }>
              <div className={ styles.search }>
                <SearchBar showResults={ false }/>
              </div>

            </div>

          </div>

          <div className={ styles.main }>
            <div className={ styles.table }>
              <TableTopBar/>
              <TableBottomBar/>
              <ContractsTable/>
            </div>
          </div>
        </div>
      </SearchableContextProvider>
    </ContractsContextProvider>
  );
};

export default Contracts;
