import React from 'react';
import TemplateRow from 'components/templates/templateRow/TemplateRow';
import { selectTemplate } from 'store/template.slice';
import { useAppSelector } from '../../../store/hooks/hooks';

type Props = {
  items: (number | string)[];
  level?: number;
  selectedNodes: (number | string)[];
  indexedRows: (number | string)[];
  onSelectionChange: (a: number, b: boolean, c: boolean) => void;
  clearSelection: () => void;
};

const TemplateRows = ({
  items, level = 1, selectedNodes, indexedRows, onSelectionChange, clearSelection
}: Props) => {
  const activeTemplate = useAppSelector(selectTemplate);

  const isFirstRow = (id: number) => level === 1 && activeTemplate.roots[ 0 ] === id;

  return <>
    {
      items?.map((item: number) => (
        <TemplateRow
          key={ item }
          nodeId={ item }
          level={ level }
          indexedRows={ indexedRows }
          selectedNodes={ selectedNodes }
          onSelectionChange={ onSelectionChange }
          clearSelection={ clearSelection }
          isFirstRow={ isFirstRow(item) }
        />))
    }
  </>;
};

export default TemplateRows;
